import { Injectable } from '@angular/core';
import * as I from '@me-interfaces';
import { AuthService } from '@me-services/core/auth';
import { ErrorPageService } from '@me-services/ui/error-page';
import { PageSpinnerService } from '@me-services/ui/page-spinner';
import { UrlService } from '@me-services/ui/url';
import { FuncServiceBase } from './func.service.base';
import { UtilityService } from '../utility';

//
//  This class is generated by fan-action-shimmer.js which is called
//  during the build. DON'T CHANGE IT MANUALLY.
//
//  The 'start', 'build:dev', 'build:prd' and 'start:funcwatch' npm scripts
//  all delegate to 'func:' scripts that trigger the generation.
//

@Injectable({ providedIn: 'root' })
export class FuncService extends FuncServiceBase {

	constructor(auth: AuthService, errorPage: ErrorPageService, ps: PageSpinnerService, urlService: UrlService, util: UtilityService) {
		super(auth, errorPage, ps, urlService, util);
	}

	public admin = {
		contactsDb: {
			company: {
				addPosition: this._call<{ personId: number, companyId: number, title: string, isAdmin: boolean, isFounder: boolean, phoneTypeId?: number, phone?: string, extension?: string }, I.DbcCompanyProfile>('admin.contactsDb.company.addPosition'),
				add: this._call<{ companyName: string, companyTypeId: number }, I.Old_MinimalCompany>('admin.contactsDb.company.add'),
				getAudit: this._call<{ companyId: number }, I.CompanyAuditTrail>('admin.contactsDb.company.getAudit'),
				updatePosition: this._call<{ contactId: number, positionId: number, values: I.PositionDetailsNew, isDefault?: boolean }, I.DbcCompanyProfile>('admin.contactsDb.company.updatePosition'),
				updatePrimaryContact: this._call<{ companyId: number, positionId: number }, void>('admin.contactsDb.company.updatePrimaryContact', { noPageSpinner: true }),
			},
			contact: {
				addPersonNote: this._call<{ contactId: number, subject: string, noteCategoryId: number }, I.EntityNote[]>('admin.contactsDb.contact.addPersonNote'),
				addPosition: this._call<{ contactId: number, companyName: string, details: I.PositionDetailsNew }, I.DbsPerson>('admin.contactsDb.contact.addPosition'),
				addTag: this._call<{ personId: number, tagFullName: string }, boolean>('admin.contactsDb.contact.addTag'),
				add: this._call<I.NewContactDetails, number>('admin.contactsDb.contact.add'),
				getAllSessions: this._callNoPayload<I.AppSessionDetails[]>('admin.contactsDb.contact.getAllSessions'),
				getAssociations: this._call<{ contactId: number }, I.PersonAssociation[]>('admin.contactsDb.contact.getAssociations'),
				getAudit: this._call<{ contactId: number }, I.AuditTrail>('admin.contactsDb.contact.getAudit'),
				getFilteredNotes: this._call<{ createdByPersonIds: number[], noteCategoryIds: number[], updatedByPersonIds: number[], siteIds: number[] }, I.ExtendedEntityNote[]>('admin.contactsDb.contact.getFilteredNotes'),
				getNoteCategories: this._callNoPayload<I.Old_NoteCategory[]>('admin.contactsDb.contact.getNoteCategories'),
				getNotesFilters: this._callNoPayload<I.NoteFilterList>('admin.contactsDb.contact.getNotesFilters'),
				getPersonNotes: this._call<{ contactId: number }, I.EntityNote[]>('admin.contactsDb.contact.getPersonNotes'),
				getPersonSessions: this._call<{ personId: number }, I.AppSession[]>('admin.contactsDb.contact.getPersonSessions'),
				getPrefixesAndTags: this._callNoPayload<I.AllTags>('admin.contactsDb.contact.getPrefixesAndTags'),
				getTags: this._callNoPayload<I.AllTags>('admin.contactsDb.contact.getTags'),
				removePersonNote: this._call<{ contactId: number, noteId: number }, void>('admin.contactsDb.contact.removePersonNote'),
				searchNew: this._call<{ email: string, phone: string, firstName: string, lastName: string }, I.SearchContactResult[]>('admin.contactsDb.contact.searchNew'),
				setPersonNote: this._call<{ noteId: number, contactId: number, entityId: number, subject: string, note: string, siteId?: number }, I.EntityNote[]>('admin.contactsDb.contact.setPersonNote'),
				toggleTag: this._call<{ personId: number, tagFullName: string }, I.Old_Tag>('admin.contactsDb.contact.toggleTag'),
				updateDefaultPosition: this._call<{ personId: number, positionId: number, companyEntityId: number }, void>('admin.contactsDb.contact.updateDefaultPosition', { noPageSpinner: true }),
				updatePosition: this._call<{ contactId: number, positionId: number, values: I.PositionDetailsNew, isDefault?: boolean }, I.DbsPerson>('admin.contactsDb.contact.updatePosition'),
			},
			entity: {
				addNote: this._call<{ entityId: number, noteCategoryId: number, subject: string, note?: string, siteId?: number }, number>('admin.contactsDb.entity.addNote'),
				getNotes: this._call<{ entityId: number }, I.EntityNote[]>('admin.contactsDb.entity.getNotes'),
				getProfile: this._call<{ entityId: number }, I.EntityProfile>('admin.contactsDb.entity.getProfile'),
				removeNote: this._call<{ entityNoteId: number }, void>('admin.contactsDb.entity.removeNote'),
				updateNote: this._call<{ entityNoteId: number, noteCategoryId: number, subject: string, note?: string, siteId?: number }, void>('admin.contactsDb.entity.updateNote'),
			},
			position: {
				delete: this._call<{ positionId: number }, I.DbsPerson>('admin.contactsDb.position.delete'),
				get: this._call<{ positionId: number }, I.Position>('admin.contactsDb.position.get'),
				update: this._call<{ positionId: number, companyId: number, personId: number, values: I.PositionDetailsNew }, I.Position>('admin.contactsDb.position.update'),
			},
		},
		site: {
			acc: {
				applicant: {
					add: this._call<{ accId: number, companyId: number, personId: number }, number>('admin.site.acc.applicant.add'),
					checkNew: this._call<{ accId: number, personId: number }, I.AccApplicantMinimal>('admin.site.acc.applicant.checkNew'),
					getApplicantCompanyMenuLabel: this._call<{ accId: number, applicationId: number }, string>('admin.site.acc.applicant.getApplicantCompanyMenuLabel'),
					getRatings: this._call<{ accId: number, siteCode: string }, I.AccApplicantSelectionDetails[]>('admin.site.acc.applicant.getRatings'),
					get: this._call<{ accId: number, applicationId: number }, I.AccApplicant>('admin.site.acc.applicant.get'),
					remove: this._call<{ accId: number, applicationId: number, siteCode: string, companyId: number }, void>('admin.site.acc.applicant.remove'),
					setBulkStatuses: this._call<{ accId: number, applicationIds: number[], applicationStatusId: I.ApplicationStatusId, siteCode: string }, I.AccApplicantSelectionDetails[]>('admin.site.acc.applicant.setBulkStatuses'),
					setNotes: this._call<{ accId: number, applicationId: number, notes: string, siteCode: string }, void>('admin.site.acc.applicant.setNotes'),
					setStatus: this._call<{ accId: number, applicationId: number, applicationStatusId: I.ApplicationStatusId, siteCode: string }, void>('admin.site.acc.applicant.setStatus'),
					set: this._call<{ accId: number, applicationId: number, isUnemployed: boolean, answers: I.Answer[] }, void>('admin.site.acc.applicant.set'),
					transfer: this._call<{ currentAccId: number, transferAccId: number, applicationId: number, siteCode: string, personId: number, companyId: number }, void>('admin.site.acc.applicant.transfer'),
				},
				cohort: {
					addTeamMember: this._call<{ accId: number, accTeamId: number, contactId: number, role: 'E' | 'M', siteCode: string }, I.AccCohortTeam>('admin.site.acc.cohort.addTeamMember'),
					addToCohort: this._call<{ accId: number, applicationId: number, siteCode: string }, number>('admin.site.acc.cohort.addToCohort'),
					getTeam: this._call<{ accId: number, accTeamId: number }, I.AccCohortTeam>('admin.site.acc.cohort.getTeam'),
					get: this._call<{ accId: number, siteCode: string }, I.AccCohort>('admin.site.acc.cohort.get'),
					removeTeamMember: this._call<{ accId: number, accTeamId: number, contactId: number, siteCode: string }, I.AccCohortTeam>('admin.site.acc.cohort.removeTeamMember'),
					setDroppedOutWeek: this._call<{ accId: number, accTeamId: number, droppedOutWeek: number, siteCode: string, applicantPersonId: number }, I.AccCohortTeam>('admin.site.acc.cohort.setDroppedOutWeek', { noPageSpinner: true }),
					setMentorRole: this._call<{ accId: number, accTeamId: number, contactId: number, role: 'M' | 'C' | 'X' }, I.AccCohortTeam>('admin.site.acc.cohort.setMentorRole', { noPageSpinner: true }),
					setTeamMemberNote: this._call<{ contactId: number, accId: number, accTeamId: number, notes: string }, I.AccCohortTeam>('admin.site.acc.cohort.setTeamMemberNote'),
					setTeamNotes: this._call<{ accId: number, accTeamId: number, notes: string, siteCode: string }, I.AccCohortTeam>('admin.site.acc.cohort.setTeamNotes'),
				},
				interview: {
					getApplicant: this._call<{ accId: number, applicationId: number, interviewerId: number }, I.AccApplicantRating>('admin.site.acc.interview.getApplicant'),
					getInterviews: this._call<{ siteCode: string, accId: number, applicationId?: number }, I.RatingsData[]>('admin.site.acc.interview.getInterviews'),
					get: this._call<{ accId: number, siteCode: string }, I.AccInterviewingDetails>('admin.site.acc.interview.get'),
					setApplicantEvent: this._call<{ accId: number, applicationId: number, eventId: number, siteCode: string }, I.AccInterviewingDetails>('admin.site.acc.interview.setApplicantEvent', { noPageSpinner: true }),
					setAssessment: this._call<{ details: I.AccAssessmentDetails, interviewerId: number }, void>('admin.site.acc.interview.setAssessment'),
					setInterviewerEvents: this._call<{ accId: number, personId: number, eventIds: number[], siteCode: string }, I.AccInterviewingDetails>('admin.site.acc.interview.setInterviewerEvents', { noPageSpinner: true }),
					toggle: this._call<{ accId: number, personIds: number[], siteCode: string }, I.AccInterviewingDetails>('admin.site.acc.interview.toggle', { noPageSpinner: true }),
				},
				judge: {
					getJudgements: this._call<{ siteCode: string, accId: number }, I.RatingsData[]>('admin.site.acc.judge.getJudgements'),
					getTeam: this._call<{ accId: number, accTeamId: number, judgeId: number }, I.AccTeamJudgement>('admin.site.acc.judge.getTeam'),
					get: this._call<{ accId: number, siteCode: string }, I.SiteAdminAcceleratorEventsFinalPitch>('admin.site.acc.judge.get'),
					setJudgeEvent: this._call<{ accId: number, personId: number, eventIds: number[], siteCode: string }, I.SiteAdminAcceleratorEventsFinalPitch>('admin.site.acc.judge.setJudgeEvent', { noPageSpinner: true }),
					setTeamEvent: this._call<{ accId: number, accTeamId: number, eventId: number, siteCode: string }, I.SiteAdminAcceleratorEventsFinalPitch>('admin.site.acc.judge.setTeamEvent', { noPageSpinner: true }),
					setTeam: this._call<{ accId: number, accTeamId: number, answers: I.Answer[], judgeId: number }, void>('admin.site.acc.judge.setTeam'),
					toggle: this._call<{ accId: number, personIds: number[], siteCode: string }, I.SiteAdminAcceleratorEventsFinalPitch>('admin.site.acc.judge.toggle', { noPageSpinner: true }),
				},
				matching: {
					get: this._call<{ accId: number, siteCode: string }, I.AccMatchingMegaData>('admin.site.acc.matching.get'),
					setAssessment: this._call<{ accTeamId: number, personId: number, assessment: number, assessmentOn: 'm2t' | 't2m' }, I.AccMatchingMegaData>('admin.site.acc.matching.setAssessment'),
					setHints: this._call<{ matchingHints: string, accId: number, siteCode: string }, void>('admin.site.acc.matching.setHints'),
					setMentorEvent: this._call<{ personId: number, accId: number, eventId: number, siteCode: string }, I.AccMatchingMegaData>('admin.site.acc.matching.setMentorEvent', { noPageSpinner: true }),
					setTeams: this._call<{ accId: number, teams: I.TeamAssignment[], siteCode: string }, void>('admin.site.acc.matching.setTeams'),
				},
				mentor: {
					add: this._call<{ accId: number, contactId: number, siteCode: string }, { allMentors: I.DbsPerson[], mentors: I.AccMentor_old[] }>('admin.site.acc.mentor.add'),
					get: this._call<{ accId: number, siteCode: string }, { allMentors: I.DbsPerson[], mentors: I.AccMentor_old[] }>('admin.site.acc.mentor.get'),
					remove: this._call<{ accId: number, contactId: number, siteCode: string }, { allMentors: I.DbsPerson[], mentors: I.AccMentor_old[] }>('admin.site.acc.mentor.remove'),
				},
				quarterlies: {
					getEnabled: this._call<{ accId: number, siteCode: string }, [boolean, boolean, boolean, boolean]>('admin.site.acc.quarterlies.getEnabled'),
					setEnabled: this._call<{ accId: number, siteCode: string, enabledQuarters: [boolean, boolean, boolean, boolean] }, void>('admin.site.acc.quarterlies.setEnabled'),
				},
				read: {
					getApplicant: this._call<{ accId: number, applicationId: number, readerId: number }, I.AccApplicantRating>('admin.site.acc.read.getApplicant'),
					getReadings: this._call<{ siteCode: string, accId: number, applicationId?: number }, I.RatingsData[]>('admin.site.acc.read.getReadings'),
					get: this._call<{ accId: number, siteCode: string }, I.SiteAdminAcceleratorEventsReading>('admin.site.acc.read.get'),
					setAssessment: this._call<{ details: I.AccAssessmentDetails, readerId: number }, void>('admin.site.acc.read.setAssessment'),
					setMax: this._call<{ accId: number, accReaderId: number, maxReads: number, siteCode: string }, I.SiteAdminAcceleratorEventsReading>('admin.site.acc.read.setMax'),
					toggle: this._call<{ accId: number, personIds: number[], siteCode: string }, I.SiteAdminAcceleratorEventsReading>('admin.site.acc.read.toggle', { noPageSpinner: true }),
				},
				getDemographicCounts: this._call<{ accId: number, siteCode: string }, I.AccDemographicCounts>('admin.site.acc.getDemographicCounts'),
				getStageStatuses: this._call<{ accId: number, siteCode: string }, I.StageStatusItem[]>('admin.site.acc.getStageStatuses'),
				getTransferableAccs: this._call<{ accId: number, companyId: number, siteCode: string }, { accId: number, name: string }[]>('admin.site.acc.getTransferableAccs'),
				setStage: this._call<{ accId: number, accStageId: number, siteCode: string }, number>('admin.site.acc.setStage'),
			},
			alumReport: {
				getProgress: this._call<{ siteCode: string }, I.TeamProgressReport[]>('admin.site.alumReport.getProgress'),
			},
			event: {
				getList: this._call<{ siteCode: string, accId?: number, picId?: number }, I.ProgramEvents[]>('admin.site.event.getList'),
			},
			feedback: {
				getForAcc: this._call<{ accId: number, siteCode: string }, I.Feedback[]>('admin.site.feedback.getForAcc'),
				getForPic: this._call<{ picId: number, siteCode: string }, I.Feedback[]>('admin.site.feedback.getForPic'),
				setPolished: this._call<{ siteCode: string, table: string, applicationId: number, teamId?: number, reviewerId: number, polishedFeedback: string }, void>('admin.site.feedback.setPolished'),
				setSkippedPolished: this._call<{ siteCode: string, table: string, applicationId: number, teamId?: number, reviewerId: number, polishedFeedbackSkipped: boolean }, void>('admin.site.feedback.setSkippedPolished'),
			},
			hearReport: {
				getFilteredResponses: this._call<{ siteCode: string, accIds: number[], picIds: number[] }, I.ApplicantHearResponse[]>('admin.site.hearReport.getFilteredResponses'),
			},
			pic: {
				applicant: {
					addToParticipants: this._call<{ applicationId: number, picId: number }, number>('admin.site.pic.applicant.addToParticipants'),
					add: this._call<{ picId: number, contactId: number, companyId: number }, number>('admin.site.pic.applicant.add'),
					checkNew: this._call<{ picId: number, contactId: number }, I.PitchApplicantAddCheck>('admin.site.pic.applicant.checkNew'),
					getApplicantCompanyMenuLabel: this._call<{ picId: number, applicationId: number }, string>('admin.site.pic.applicant.getApplicantCompanyMenuLabel'),
					getRatings: this._call<{ picId: number, siteCode: string }, I.PicApplicantSelectionDetails[]>('admin.site.pic.applicant.getRatings'),
					getReading: this._call<{ picId: number, applicationId: number, readerId: number }, I.PitchContestApplicantForAssessment>('admin.site.pic.applicant.getReading'),
					getTransferrable: this._call<{ picId: number, companyId: number }, { picId: number, name: string }[]>('admin.site.pic.applicant.getTransferrable'),
					get: this._call<{ picId: number, applicationId: number }, I.PicApplicant>('admin.site.pic.applicant.get'),
					remove: this._call<{ picId: number, applicationId: number, companyId: number }, void>('admin.site.pic.applicant.remove'),
					setAnswers: this._call<{ details: I.PicApplicationAnswers, contactId: number }, void>('admin.site.pic.applicant.setAnswers'),
					setBulkStatuses: this._call<{ picId: number, applicationIds: number[], applicationStatusId: I.ApplicationStatusId, siteCode: string }, I.PicApplicantSelectionDetails[]>('admin.site.pic.applicant.setBulkStatuses'),
					setNotes: this._call<{ picId: number, applicationId: number, notes: string }, void>('admin.site.pic.applicant.setNotes'),
					setReading: this._call<{ picId: number, applicationId: number, readerId: number, assessment: I.Assessment }, void>('admin.site.pic.applicant.setReading'),
					setStatus: this._call<{ picId: number, applicationId: number, applicationStatusId: I.ApplicationStatusId }, void>('admin.site.pic.applicant.setStatus', { noPageSpinner: true }),
					transfer: this._call<{ currentPicId: number, transferPicId: number, applicationId: number, personId: number, companyId: number }, void>('admin.site.pic.applicant.transfer'),
				},
				contest: {
					getJudgements: this._call<{ picId: number, siteCode: string, picTeamId?: number }, I.RatingsData[]>('admin.site.pic.contest.getJudgements'),
					getTeam: this._call<{ picId: number, picTeamId: number, contactId: number }, I.PitchContestJudgeTeam>('admin.site.pic.contest.getTeam'),
					get: this._call<{ picId: number, siteCode: string }, I.PicContestDetails>('admin.site.pic.contest.get'),
					setAttended: this._call<{ picId: number, picTeamId: number, siteCode: string }, void>('admin.site.pic.contest.setAttended', { noPageSpinner: true }),
					setTeam: this._call<{ picId: number, picTeamId: number, answers: I.Answer[], contactId: number, siteCode: string }, void>('admin.site.pic.contest.setTeam'),
					toggleJudge: this._call<{ picId: number, personIds: number[], siteCode: string }, I.PicContestDetails>('admin.site.pic.contest.toggleJudge', { noPageSpinner: true }),
					unsetAttended: this._call<{ picId: number, picTeamId: number, siteCode: string }, void>('admin.site.pic.contest.unsetAttended', { noPageSpinner: true }),
				},
				participant: {
					getList: this._call<{ picId: number, siteCode: string }, I.PitchParticipants[]>('admin.site.pic.participant.getList'),
					getMinimalList: this._call<{ picId: number }, I.MinimalPitchTeam[]>('admin.site.pic.participant.getMinimalList'),
					setPresentType: this._call<{ picId: number, picTeamId: number, presentType: I.PicPresentType, siteCode: string }, void>('admin.site.pic.participant.setPresentType', { noPageSpinner: true }),
				},
				read: {
					getReadings: this._call<{ picId: number, siteCode: string, applicationId?: number }, I.RatingsData[]>('admin.site.pic.read.getReadings'),
					get: this._call<{ picId: number }, I.PicReadingDetails>('admin.site.pic.read.get'),
					setMax: this._call<{ picId: number, picReaderId: number, maxReads: number, siteCode: string }, I.PicReadingDetails>('admin.site.pic.read.setMax', { noPageSpinner: true }),
					toggleReader: this._call<{ picId: number, personIds: number[], siteCode: string }, I.PicReadingDetails>('admin.site.pic.read.toggleReader', { noPageSpinner: true }),
				},
				team: {
					addMember: this._call<{ picTeamId: number, contactId: number, siteCode: string }, I.SiteAdminPitchTeam>('admin.site.pic.team.addMember', { noPageSpinner: true }),
					get: this._call<{ picTeamId: number, siteCode: string }, I.SiteAdminPitchTeam>('admin.site.pic.team.get'),
					removeMember: this._call<{ picTeamId: number, contactId: number, siteCode: string }, I.SiteAdminPitchTeam>('admin.site.pic.team.removeMember', { noPageSpinner: true }),
					remove: this._call<{ picTeamId: number, siteCode: string }, void>('admin.site.pic.team.remove'),
					setMemberNote: this._call<{ picTeamId: number, notes: string, contactId: number, siteCode: string }, I.SiteAdminPitchTeam>('admin.site.pic.team.setMemberNote'),
					setNotes: this._call<{ picTeamId: number, notes: string, siteCode: string }, I.SiteAdminPitchTeam>('admin.site.pic.team.setNotes'),
				},
				getDemographicCounts: this._call<{ picId: number, siteCode: string }, I.PicDemographicCounts>('admin.site.pic.getDemographicCounts'),
				getStageStatuses: this._call<{ picId: number, siteCode: string }, I.StageStatusItem[]>('admin.site.pic.getStageStatuses'),
				setStage: this._call<{ picId: number, picStageId: I.PicStageId, siteCode: string }, number>('admin.site.pic.setStage'),
			},
			roles: {
				getPeople: this._call<{ siteCode: string, siteTagIds: number[] }, I.DbsPerson[]>('admin.site.roles.getPeople'),
				toggleTags: this._call<{ personId: number, siteCode: string, newTags: { [index: string]: boolean } }, any>('admin.site.roles.toggleTags'),
			},
			volunteer: {
				addOrRemoveTags: this._call<{ volunteerApplicationId: number, applicantPersonId: number, tags: I.TagApprovals, siteCode: string }, I.VolunteerApplication>('admin.site.volunteer.addOrRemoveTags'),
				closeApplication: this._call<{ applicant: I.DbcVolunteerApplication, tags: I.TagApprovals, siteCode: string }, void>('admin.site.volunteer.closeApplication'),
				getAll: this._call<{ siteCode: string }, I.VolunteerApplication[]>('admin.site.volunteer.getAll'),
				get: this._call<{ volunteerApplicationId: number, siteCode: string }, I.VolunteerApplication>('admin.site.volunteer.get'),
				transfer: this._call<{ volunteerApplicationId: number, transferSiteId: number, currentSiteId: number, currentSiteCode: string }, void>('admin.site.volunteer.transfer'),
				updateClosedBy: this._call<{ volunteerApplicationId: number, siteCode: string }, void>('admin.site.volunteer.updateClosedBy'),
			},
			getOverview: this._call<{ siteCode: string }, I.SiteAdminOverview>('admin.site.getOverview'),
			getPeopleWithTags: this._call<{ siteCode: string, tag?: string }, I.PersonWithSiteTags[]>('admin.site.getPeopleWithTags'),
		},
		tech: {
			companyChange: {
				extractAllForCompanies: this._call<{ companyId: number, entityId: number }[], I.ChangeResult[]>('admin.tech.companyChange.extractAllForCompanies', { noPageSpinner: true }),
			},
			ddCache: {
				update: this._callNoPayload<void>('admin.tech.ddCache.update', { noPageSpinner: true }),
			},
			industry: {
				getCompanies: this._callNoPayload<I.CompanyWithIndustry[]>('admin.tech.industry.getCompanies'),
				setIndustry: this._call<{ companyId: number, unknownIndustry: boolean, industryId?: number, offerings?: 'Products' | 'Services' | 'Both' }, void>('admin.tech.industry.setIndustry'),
			},
			mailchimp: {
				getDetails: this._callNoPayload<I.MailchimpExport[]>('admin.tech.mailchimp.getDetails'),
				setMailingListTags: this._callNoPayload<void>('admin.tech.mailchimp.setMailingListTags'),
				unsetListFlag: this._call<{ personIds: number[] }, void>('admin.tech.mailchimp.unsetListFlag'),
			},
			personChange: {
				bulkUpdate: this._call<{ bulkType: I.BulkChangeType, nextId: number }, { nextId: number, maxId: number, completed: number }>('admin.tech.personChange.bulkUpdate', { noPageSpinner: true }),
				extractAllForPeople: this._call<{ personId: number, entityId: number }[], I.ChangeResult[]>('admin.tech.personChange.extractAllForPeople', { noPageSpinner: true }),
				updateAutoTags: this._call<{ personId: number, entityId: number }[], I.ChangeResult[]>('admin.tech.personChange.updateAutoTags', { noPageSpinner: true }),
			},
			reports: {
				rebuildApplications: this._callNoPayload<number>('admin.tech.reports.rebuildApplications'),
				rebuild: this._call<{ report: string }, number>('admin.tech.reports.rebuild'),
			},
			test: {
				sendTestEmail: this._callNoPayload<string | undefined>('admin.tech.test.sendTestEmail'),
				sendUpcomingEventsEmail: this._callNoPayload<void>('admin.tech.test.sendUpcomingEventsEmail'),
				testIntl: this._callNoPayload<string[]>('admin.tech.test.testIntl'),
			},
			getAllCompanyAndEntityIds: this._callNoPayload<{ companyId: number, entityId: number }[]>('admin.tech.getAllCompanyAndEntityIds'),
			getAllPeople: this._callNoPayload<I.TechAllPeople>('admin.tech.getAllPeople'),
			getAllPersonAndEntityIds: this._callNoPayload<{ personId: number, entityId: number }[]>('admin.tech.getAllPersonAndEntityIds'),
		},
	};

	public application = {
		participant: {
			getFieldsForUser: this._call<{ applicationId: number }, { isApplicant: boolean, participantFields: I.FieldsAndValues, overview: I.ApplicationOverview }>('application.participant.getFieldsForUser'),
			remove: this._call<{ applicationId: number, participantId, tool: I.FieldTool }, I.ApplicationFieldsForTeam>('application.participant.remove'),
			sendReminderEmail: this._call<{ applicationId: number, participantId: number, tool: I.FieldTool }, string>('application.participant.sendReminderEmail'),
			update: this._call<{ applicationId: number, participantId: number, values: { title: string }, tool: I.FieldTool }, I.ApplicationFieldsForTeam>('application.participant.update'),
		},
		pendingParticipant: {
			accept: this._call<{ code: string }, { applicationId: number, languageId: number }>('application.pendingParticipant.accept'),
			getCodeInfo: this._call<{ code: string }, { applicantName: string, siteName: string, programInstanceName: string }>('application.pendingParticipant.getCodeInfo'),
			invite: this._call<{ applicationId: number, values: I.ApplicationParticipantPendingEditableFields, tool: I.FieldTool }, I.ApplicationFieldsForTeam>('application.pendingParticipant.invite'),
			removeByCode: this._call<{ code: string }, void>('application.pendingParticipant.removeByCode'),
			remove: this._call<{ applicationId: number, participantPendingId, tool: I.FieldTool }, I.ApplicationFieldsForTeam>('application.pendingParticipant.remove'),
			sendReminderEmail: this._call<{ applicationId: number, participantPendingId: number, tool: I.FieldTool }, string>('application.pendingParticipant.sendReminderEmail'),
			update: this._call<{ applicationId: number, participantPendingId: number, values: I.ApplicationParticipantPendingEditableFields, tool: I.FieldTool }, I.ApplicationFieldsForTeam>('application.pendingParticipant.update'),
		},
		addAsAdmin: this._call<{ accId: number, picId: number, positionId: number }, number>('application.addAsAdmin'),
		add: this._call<{ accId: number, picId: number, companyId: number, title: string }, number>('application.add'),
		determineIfUserCanApply: this._call<{ accId: number, picId: number }, { companies: I.CompanyForApplicationUser[] }>('application.determineIfUserCanApply'),
		getAllForProgramInstance: this._call<{ accId: number, picId: number }, I.ApplicationListDetails[]>('application.getAllForProgramInstance'),
		getFieldsForTeam: this._call<{ applicationId: number, tool: I.FieldTool }, I.ApplicationFieldsForTeam>('application.getFieldsForTeam'),
		getFields: this._call<{ applicationId: number, tool: I.FieldTool }, I.ApplicationFields>('application.getFields'),
		getMenuLabel: this._call<{ applicationId: number }, string>('application.getMenuLabel'),
		getOpenProgramInstances: this._call<{ languageId: number }, I.ApplicationProgramInstance[]>('application.getOpenProgramInstances'),
		getStatusLog: this._call<{ accId: number, picId: number, applicationId: number }, I.ApplicationStatusLog[]>('application.getStatusLog'),
		setStatus: this._call<{ applicationId: number, tool: I.FieldTool, applicationStatusId: I.ApplicationStatusId }, I.ApplicationFields>('application.setStatus'),
		submit: this._call<{ applicationId: number, tool: I.FieldTool }, { state: "no-access" | "missing-required" | "incomplete-participants" | "success", fields: I.ApplicationFields }>('application.submit'),
		withdraw: this._call<{ applicationId: number, applicationStatusId: I.ApplicationStatusId, tool: I.FieldTool }, I.ApplicationFields>('application.withdraw'),
	};

	public areas = {
		acc: {
			applications: {
				addToCohort: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { applicationIds: number[] }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.applications.addToCohort'),
				copyForward: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { applicationIds: number[] }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.applications.copyForward'),
				setBulkStatusForDeferredApplications: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { applicationIds: number[], status: 'Rejected' | 'Abandoned' | 'Withdrawn' }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.applications.setBulkStatusForDeferredApplications'),
				setBulkStatus: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { applicationIds: number[], applicationStatusId: I.ApplicationStatusId }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.applications.setBulkStatus'),
				setInterviewEventId: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { applicationId: number, eventId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.applications.setInterviewEventId'),
				setNotes: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { applicationId: number, notes: string }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.applications.setNotes'),
				setStatus: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { applicationId: number, applicationStatusId: I.ApplicationStatusId }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.applications.setStatus'),
				transfer: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { applicationId: number, accId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.applications.transfer'),
			},
			curriculum: {
				addSessions: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: undefined, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.curriculum.addSessions'),
				sendSessionSurveyEmail: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { accSessionId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.curriculum.sendSessionSurveyEmail'),
				setSessionAttendeesStatus: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { accSessionId: number, teamAndPersonIds: { accTeamId: number, personId: number }[], status: I.SessionAttendeeStatus }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.curriculum.setSessionAttendeesStatus'),
				setTopicSessionAndSpecialists: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { topicId: number, personIds: number[], accSessionId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.curriculum.setTopicSessionAndSpecialists'),
			},
			interviewing: {
				deleteInterviewer: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { accInterviewerId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.interviewing.deleteInterviewer'),
				setAssessment: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { accInterviewerId: number, applicationId: number, assessment: { rating: number, answers: I.Answer[], } }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.interviewing.setAssessment'),
				setEvents: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { accInterviewerId: number, eventIds: number[] }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.interviewing.setEvents'),
				setInterviewers: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { personIds: number[] }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.interviewing.setInterviewers'),
				setPolishedFeedbackSkipped: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { accInterviewerId: number, applicationId: number, polishedFeedbackSkipped: boolean }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.interviewing.setPolishedFeedbackSkipped'),
				setPolishedFeedback: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { accInterviewerId: number, applicationId: number, polishedFeedback: string }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.interviewing.setPolishedFeedback'),
			},
			judging: {
				deleteJudge: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { accJudgeId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.judging.deleteJudge'),
				setAssessment: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { accJudgeId: number, accTeamId: number, answers: I.Answer[] }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.judging.setAssessment'),
				setEvents: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { accJudgeId: number, eventIds: number[] }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.judging.setEvents'),
				setJudge: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { personIds: number[] }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.judging.setJudge'),
				setPolishedFeedbackSkipped: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { accJudgeId: number, accTeamId: number, polishedFeedbackSkipped: boolean }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.judging.setPolishedFeedbackSkipped'),
				setPolishedFeedback: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { accJudgeId: number, accTeamId: number, polishedFeedback: string }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.judging.setPolishedFeedback'),
			},
			mentorMatching: {
				deleteMentor: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { personId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.mentorMatching.deleteMentor'),
				setAssessment: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { accTeamId: number, personId: number, assessment: number, assessmentOn: 'm2t' | 't2m' }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.mentorMatching.setAssessment'),
				setConfig: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { personId: number, eventId: number, canCoach: boolean, useCount: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.mentorMatching.setConfig'),
				setHints: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { hints: string }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.mentorMatching.setHints'),
				setMentors: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { personIds: number[] }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.mentorMatching.setMentors'),
				setSchedule: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { tableCode: 'M' | 'T', slot: number, day: number, set: boolean, accTeamId?: number, personId?: number }, singletonsCacheUTC: number }, I.AccMatchingSchedule>('areas.acc.mentorMatching.setSchedule', { noPageSpinner: true }),
			},
			reading: {
				deleteReader: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { accReaderId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.reading.deleteReader'),
				setAssessment: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { accReaderId: number, applicationId: number, assessment: { rating: number, answers: I.Answer[], } }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.reading.setAssessment'),
				setMaxReads: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { accReaderId: number, maxReads: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.reading.setMaxReads'),
				setPolishedFeedbackSkipped: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { accReaderId: number, applicationId: number, polishedFeedbackSkipped: boolean }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.reading.setPolishedFeedbackSkipped'),
				setPolishedFeedback: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { accReaderId: number, applicationId: number, polishedFeedback: string }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.reading.setPolishedFeedback'),
				setReaders: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { personIds: number[] }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.reading.setReaders'),
			},
			teams: {
				addGoalComment: this._call<{ identifier: I.AppAreaIdentifierWithSiteId, parameters: { accTeamGoalId: number, comment: string }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.teams.addGoalComment'),
				addGoal: this._call<{ identifier: I.AppAreaIdentifierWithSiteId, parameters: { accTeamId: number, quarter: number, summary: string, details: string }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.teams.addGoal'),
				addMember: this._call<{ identifier: I.AppAreaIdentifierWithSiteId, parameters: { accTeamId: number, role: 'M' | 'C' | 'E', personId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.teams.addMember'),
				removeEntrepreneur: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { accTeamMemberId: number, accTeamId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.teams.removeEntrepreneur'),
				removeMentor: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { accTeamMemberId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.teams.removeMentor'),
				setDroppedOut: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { accTeamId: number, droppedOutWeek: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.teams.setDroppedOut'),
				setFinalEventId: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { accTeamId: number, eventId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.teams.setFinalEventId'),
				setGoal: this._call<{ identifier: I.AppAreaIdentifierWithSiteId, parameters: { accTeamGoalId: number, summary: string, details: string, progress: string }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.teams.setGoal'),
				setMentorRole: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { accTeamMemberId: number, accTeamId: number, role: 'C' | 'M' | 'X' }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.teams.setMentorRole'),
				setNotes: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, parameters: { accTeamId: number, notes: string }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.teams.setNotes'),
			},
			get: this._call<{ identifier: I.AppAreaIdentifierWithSiteId<number>, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.get'),
			setQuarterlyEnabled: this._call<{ identifier: I.AppAreaIdentifierWithSiteId, parameters: { enabledQuarters: [boolean, boolean, boolean, boolean] }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.setQuarterlyEnabled'),
			setStage: this._call<{ identifier: I.AppAreaIdentifierWithSiteId, parameters: { accStageId: I.AccStageId }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.acc.setStage'),
		},
		admin: {
			events: {
				addAward: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { award: I.DbsAward }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.admin.events.addAward'),
				addEvent: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { event: I.DbsEvent }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.admin.events.addEvent'),
				deleteAttendee: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { eventId: number, personId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.admin.events.deleteAttendee'),
				deleteAward: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { awardId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.admin.events.deleteAward'),
				deleteEvent: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { eventId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.admin.events.deleteEvent'),
				updateAward: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { award: I.DbsAward }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.admin.events.updateAward'),
				updateEvent: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { event: I.DbsEvent }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.admin.events.updateEvent'),
				upsertAttendee: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { eventAttendee: I.DbcEventAttendee }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.admin.events.upsertAttendee'),
			},
			get: this._call<{ identifier: I.AppAreaIdentifier<string>, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.admin.get'),
		},
		eforall: {
			volunteerApplication: {
				addSite: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { volunteerApplicationId: number, siteId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.eforall.volunteerApplication.addSite'),
			},
			addAccProgram: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { programId: number, siteId: number, year: number, accLanguageSeasonId: number, directorId: number, managerId: number, applStartUTC: number, applDuration: number, startUTC: number, accMeetingTimesId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.eforall.addAccProgram'),
			addPicProgram: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { programId: number, siteId: number, name: string, directorId: number, managerId: number, applStartUTC: number, applDuration: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.eforall.addPicProgram'),
			get: this._call<{ identifier: I.AppAreaIdentifier<string>, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.eforall.get'),
			setAccProgram: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { accId: number, siteId: number, directorId: number, managerId: number, applStartUTC: number, applDuration: number, startUTC: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.eforall.setAccProgram'),
			setPicProgram: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { picId: number, siteId: number, name: string, directorId: number, managerId: number, applStartUTC: number, applDuration: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.eforall.setPicProgram'),
		},
		pic: {
			awards: {
				add: this._call<{ identifier: I.AppAreaIdentifierWithSite, parameters: { picTeamId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.pic.awards.add'),
			},
			get: this._call<{ identifier: I.AppAreaIdentifierWithSite<number>, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.pic.get'),
		},
		region: {
			applications: {
				setNotes: this._call<{ identifier: I.AppAreaIdentifier<number>, parameters: { applicationId: number, notes: string }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.region.applications.setNotes'),
			},
			get: this._call<{ identifier: I.AppAreaIdentifier<number>, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.region.get', { noPageSpinner: true }),
		},
		site: {
			applications: {
				setNotes: this._call<{ identifier: I.AppAreaIdentifier<number>, parameters: { applicationId: number, notes: string }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.site.applications.setNotes'),
			},
			config: {
				addCouncilMember: this._call<{ identifier: I.AppAreaIdentifier<number>, parameters: { personId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.site.config.addCouncilMember'),
				addProgramIntern: this._call<{ identifier: I.AppAreaIdentifier<number>, parameters: { siteInternId: number, siteProgramId: number, personId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.site.config.addProgramIntern'),
				addSiteIntern: this._call<{ identifier: I.AppAreaIdentifier<number>, parameters: { personId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.site.config.addSiteIntern'),
				removeCouncilMember: this._call<{ identifier: I.AppAreaIdentifier<number>, parameters: { councilMemberId: number, personId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.site.config.removeCouncilMember'),
				removeProgramIntern: this._call<{ identifier: I.AppAreaIdentifier<number>, parameters: { siteInternId: number, siteProgramId: number, personId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.site.config.removeProgramIntern'),
				removeSiteIntern: this._call<{ identifier: I.AppAreaIdentifier<number>, parameters: { siteInternId: number, personId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.site.config.removeSiteIntern'),
				setAltExecutiveDirector: this._call<{ identifier: I.AppAreaIdentifier<number>, parameters: { altDirectorId: number, previousAltDirectorId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.site.config.setAltExecutiveDirector'),
				setAltProgramManager: this._call<{ identifier: I.AppAreaIdentifier<number>, parameters: { siteProgramId: number, altManagerId: number, previousAltManagerId?: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.site.config.setAltProgramManager'),
				setCouncilMember: this._call<{ identifier: I.AppAreaIdentifier<number>, parameters: { personId: number, endUTC: number, startUTC: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.site.config.setCouncilMember'),
				setExecutiveDirector: this._call<{ identifier: I.AppAreaIdentifier<number>, parameters: { directorId: number, previousDirectorId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.site.config.setExecutiveDirector'),
				setProgramIsOffered: this._call<{ identifier: I.AppAreaIdentifier<number>, parameters: { programId: number, enableProgram: boolean }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.site.config.setProgramIsOffered'),
				setProgramManager: this._call<{ identifier: I.AppAreaIdentifier<number>, parameters: { siteProgramId: number, managerId: number, previousManagerId?: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.site.config.setProgramManager'),
				setSiteInternFlags: this._call<{ identifier: I.AppAreaIdentifier<number>, parameters: { siteInternId: number, canViewContacts: boolean, canPrepareSiteContacts: boolean, internPersonId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.site.config.setSiteInternFlags'),
			},
			volunteer: {
				addOrRemoveTags: this._call<{ identifier: I.AppAreaIdentifier<number>, parameters: { applicantPersonId: number, tags: I.TagApprovals }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.site.volunteer.addOrRemoveTags'),
				closeApplication: this._call<{ identifier: I.AppAreaIdentifier<number>, parameters: { application: I.DbcVolunteerApplication, tags: I.TagApprovals, }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.site.volunteer.closeApplication'),
				removeSiteRoleTag: this._call<{ identifier: I.AppAreaIdentifier<number>, parameters: { personId: number, tagFullName: string }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.site.volunteer.removeSiteRoleTag'),
				removeSpecialist: this._call<{ identifier: I.AppAreaIdentifier<number>, parameters: { specialistId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.site.volunteer.removeSpecialist'),
				reopen: this._call<{ identifier: I.AppAreaIdentifier<number>, parameters: { volunteerApplicationId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.site.volunteer.reopen'),
				setSiteFollowerTag: this._call<{ identifier: I.AppAreaIdentifier<number>, parameters: { personId: number, tagFullName: string }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.site.volunteer.setSiteFollowerTag'),
				setSiteRoleTag: this._call<{ identifier: I.AppAreaIdentifier<number>, parameters: { personId: number, tagFullName: string }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.site.volunteer.setSiteRoleTag'),
				setSpecialist: this._call<{ identifier: I.AppAreaIdentifier<number>, parameters: { personId: number, topicId: number, languageId: number, status: I.SpecialistStatus, note: string, }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.site.volunteer.setSpecialist'),
				transfer: this._call<{ identifier: I.AppAreaIdentifier<number>, parameters: { volunteerApplicationId: number, transferSiteId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.site.volunteer.transfer'),
			},
			get: this._call<{ identifier: I.AppAreaIdentifier<number>, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.site.get'),
		},
		siteOld: {
			config: {
				addProgramIntern: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { siteInternId: number, siteProgramId: number, personId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.siteOld.config.addProgramIntern'),
				addSiteIntern: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { personId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.siteOld.config.addSiteIntern'),
				removeProgramIntern: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { siteInternId: number, siteProgramId: number, personId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.siteOld.config.removeProgramIntern'),
				removeSiteIntern: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { siteInternId: number, personId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.siteOld.config.removeSiteIntern'),
				setAltExecutiveDirector: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { altDirectorId: number, previousAltDirectorId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.siteOld.config.setAltExecutiveDirector'),
				setAltProgramManager: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { siteProgramId: number, altManagerId: number, previousAltManagerId?: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.siteOld.config.setAltProgramManager'),
				setExecutiveDirector: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { directorId: number, previousDirectorId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.siteOld.config.setExecutiveDirector'),
				setProgramIsOffered: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { programId: number, enableProgram: boolean }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.siteOld.config.setProgramIsOffered'),
				setProgramManager: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { siteProgramId: number, managerId: number, previousManagerId?: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.siteOld.config.setProgramManager'),
				setSiteInternFlags: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { siteInternId: number, canViewContacts: boolean, canPrepareSiteContacts: boolean, internPersonId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.siteOld.config.setSiteInternFlags'),
			},
			roles: {
				removeSpecialist: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { specialistId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.siteOld.roles.removeSpecialist'),
				setSiteFollowerTag: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { personId: number, tagFullName: string }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.siteOld.roles.setSiteFollowerTag'),
				setSiteRoleTag: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { personId: number, tagFullName: string }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.siteOld.roles.setSiteRoleTag'),
				setSpecialist: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { personId: number, topicId: number, languageId: number, status: I.SpecialistStatus, note: string, }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.siteOld.roles.setSpecialist'),
			},
			get: this._call<{ identifier: I.AppAreaIdentifier<string>, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.siteOld.get'),
		},
		sites: {
			get: this._call<{ identifier: I.AppAreaIdentifier<string>, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.sites.get'),
		},
		tech: {
			scheduleTemplate: {
				add: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { name: string, accSeasonId: number, accMeetingTimesId: number, year: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.tech.scheduleTemplate.add'),
				remove: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { scheduleTemplateId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.tech.scheduleTemplate.remove'),
				set: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { scheduleTemplateId: number, name: string, accSeasonId: number, accMeetingTimesId: number, year: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.tech.scheduleTemplate.set'),
			},
			scheduleTemplateSession: {
				add: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { scheduleTemplateId: number, startUTC: number, endUTC: number, topicIds: number[] }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.tech.scheduleTemplateSession.add'),
				set: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { scheduleTemplateSessionId: number, scheduleTemplateId: number, startUTC: number, endUTC: number, topicIds: number[] }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.tech.scheduleTemplateSession.set'),
			},
			addAccProgram: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { programId: number, siteId: number, year: number, accLanguageSeasonId: number, directorId: number, managerId: number, applStartUTC: number, applDuration: number, startUTC: number, accMeetingTimesId: number, scheduleTemplateId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.tech.addAccProgram'),
			addPicProgram: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { programId: number, siteId: number, name: string, directorId: number, managerId: number, applStartUTC: number, applDuration: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.tech.addPicProgram'),
			get: this._call<{ identifier: I.AppAreaIdentifier<string>, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.tech.get'),
			setAccProgram: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { accId: number, siteId: number, directorId: number, managerId: number, applStartUTC: number, applDuration: number, startUTC: number, scheduleTemplateId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.tech.setAccProgram'),
			setPicProgram: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { picId: number, siteId: number, name: string, directorId: number, managerId: number, applStartUTC: number, applDuration: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.tech.setPicProgram'),
		},
		user: {
			comment: {
				addMemberComment: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { accTeamGoalId: number, comment: string }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.user.comment.addMemberComment'),
				addMentorComment: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { accTeamGoalId: number, comment: string }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.user.comment.addMentorComment'),
			},
			company: {
				addApplication: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { accId: number, picId: number, companyId: number, title: string }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.user.company.addApplication'),
				addCompanyAndPosition: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { companyName: string, companyTypeId: number, industryId: number, title: string }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.user.company.addCompanyAndPosition'),
				setCompanyTypeAndPositionTitle: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { companyId: number, companyTypeId: number, industryId: number, title: string }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.user.company.setCompanyTypeAndPositionTitle'),
			},
			goals: {
				addGoal: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { accTeamId: number, quarter: number, summary: string, details: string }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.user.goals.addGoal'),
				setGoal: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { accTeamGoalId: number, summary: string, details: string, progress: string }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.user.goals.setGoal'),
			},
			profile: {
				setAddress: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { address: string, zipId: number, inUS: boolean }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.user.profile.setAddress'),
			},
			program: {
				addAgreementSignature: this._call<{ identifier: I.AppAreaIdentifier<string>, parameters: { agreementVersionId: number }, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.user.program.addAgreementSignature'),
			},
			get: this._call<{ identifier: I.AppAreaIdentifier<string>, singletonsCacheUTC: number }, I.AppAreaFuncResponse>('areas.user.get'),
		},
		getMissingSingletons: this._call<{ singletonName: string, missingIds: number[] }, I.Dbs[]>('areas.getMissingSingletons', { noPageSpinner: true }),
	};

	public award = {
		getProgramEventAwards: this._call<{ eventId: number }, I.ProgramEventAwards>('award.getProgramEventAwards'),
	};

	public company = {
		addCompanyAndPosition: this._call<{ companyName: string, companyTypeId: number, industryId: number, title: string }, number>('company.addCompanyAndPosition'),
		getProfile: this._call<{ companyId: number }, I.DbcCompanyProfile>('company.getProfile'),
		get: this._call<{ companyId: number }, I.DbsCompany>('company.get'),
		updateBasicInfo: this._call<I.CompanyBasicInfo, I.DbsCompany>('company.updateBasicInfo'),
		updateCompanyTypeAndIndustry: this._call<{ companyId: number, companyTypeId: number, industryId: number }, void>('company.updateCompanyTypeAndIndustry'),
		updateDescription: this._call<{ companyId: number, description: string }, I.DbcCompanyProfile>('company.updateDescription'),
	};

	public data = {
		createDomainCacheFiles: this._callNoPayload<void>('data.createDomainCacheFiles'),
		createSingletonCacheFiles: this._callNoPayload<void>('data.createSingletonCacheFiles'),
	};

	public entity = {
		address: {
			update: this._call<{ entityId: number, zipCode: string, inUS: boolean, addressLines: string }, I.DbsEntity>('entity.address.update'),
		},
		publicAddress: {
			update: this._call<{ entityId: number, zipCode: string, inUS: boolean, addressLines: string }, I.DbsEntity>('entity.publicAddress.update'),
		},
		webLinks: {
			add: this._call<{ entityId: number, url: string, webLinkTypeId: number }, I.DbsEntity>('entity.webLinks.add'),
			remove: this._call<{ entityId: number, webLinkId: number }, I.DbsEntity>('entity.webLinks.remove'),
			update: this._call<{ entityId: number, webLinkId: number, url: string, webLinkTypeId: number }, I.DbsEntity>('entity.webLinks.update'),
		},
	};

	public explorer = {
		getApplicationQuestions: this._call<{ applicationId: number }, { ideaAnswer: string, hearAnswer: string }>('explorer.getApplicationQuestions'),
	};

	public field = {
		update: this._call<{ field: I.FieldContext, value: I.FieldValueType, tool: I.FieldTool }, boolean>('field.update', { noPageSpinner: true }),
	};

	public person = {
		email: {
			add: this._call<{ entityId: number, personId: number, email: string }, I.DbsPerson>('person.email.add', { noPageSpinner: true }),
			remove: this._call<{ entityId: number, affectedPersonId: number, emailId: number }, I.DbsPerson>('person.email.remove', { noPageSpinner: true }),
			setPrimary: this._call<{ affectedPersonId: number, emailId: number }, I.DbsPerson>('person.email.setPrimary', { noPageSpinner: true }),
		},
		relationships: {
			add: this._call<{ entityId1: number, contactId1: number, contactId2: number, type: I.RelationshipType, notes: string }, I.DbsPerson>('person.relationships.add'),
			remove: this._call<{ entityId1: number, contactId1: number, contactId2: number }, I.DbsPerson>('person.relationships.remove'),
			update: this._call<{ entityId1: number, contactId1: number, contactId2: number, type: I.RelationshipType, notes: string }, I.DbsPerson>('person.relationships.update'),
		},
		tags: {
			add: this._call<{ personId: number, tagId: number }, I.DbsPerson>('person.tags.add'),
			remove: this._call<{ personId: number, tagId: number }, I.DbsPerson>('person.tags.remove'),
		},
		getDemographics: this._call<{ personId: number }, I.PersonDemographics>('person.getDemographics'),
		getLastLogin: this._call<{ personId: number }, number>('person.getLastLogin'),
		get: this._call<number, I.DbsPerson>('person.get'),
		updateBaseAccessFlags: this._call<{ personId: number, flags: I.PersonAccessBaseFlags }, I.DbsPerson>('person.updateBaseAccessFlags'),
		updateBasicInfo: this._call<I.PersonBasicInfo, I.DbsPerson>('person.updateBasicInfo'),
		updateBio: this._call<{ languageId: number, newBio: string, affectedPersonId: number }, I.DbsPerson>('person.updateBio'),
		updateDoNotContactNote: this._call<{ note: string, affectedPersonId: number }, I.DbsPerson>('person.updateDoNotContactNote'),
	};

	public position = {
		updateTitle: this._call<{ companyId: number, title: string }, void>('position.updateTitle'),
	};

	public public = {
		acc: {
			applicant: {
				get: this._call<{ accId: number, applicationId: number }, I.AccApplicant>('public.acc.applicant.get'),
				set: this._call<{ accId: number, isUnemployed: boolean, answers: I.Answer[] }, void>('public.acc.applicant.set'),
			},
			interview: {
				getApplicant: this._call<{ accId: number, applicationId: number }, I.AccApplicantRating>('public.acc.interview.getApplicant'),
				getAssessments: this._call<{ accId: number }, I.AccInterviewerAssessment[]>('public.acc.interview.getAssessments'),
				getInterviewerEvents: this._call<{ accId: number }, I.AccInterviewerEvents>('public.acc.interview.getInterviewerEvents'),
				setAssessment: this._call<I.AccAssessmentDetails, void>('public.acc.interview.setAssessment'),
			},
			judge: {
				getTeam: this._call<{ accId: number, accTeamId: number }, I.AccTeamJudgement>('public.acc.judge.getTeam'),
				getTeams: this._call<{ accId: number }, { events: I.AccJudgeEvent[] }>('public.acc.judge.getTeams'),
				setTeam: this._call<{ accId: number, accTeamId: number, answers: I.Answer[] }, void>('public.acc.judge.setTeam'),
			},
			matching: {
				v2: {
					getMeetings: this._call<{ accId: number }, I.AccMatchingMeetings>('public.acc.matching.v2.getMeetings'),
					setMeetingHour: this._call<{ day: I.AccMatchingDay | undefined, hour: I.AccMatchingHour | undefined, selected: boolean, accId: number, accTeamId?: number, mentorPersonId?: number }, I.AccMatchingMeetings>('public.acc.matching.v2.setMeetingHour', { noPageSpinner: true }),
					setMeetingsTimezone: this._call<{ timeZoneId: number, accId: number, accTeamId?: number, mentorPersonId?: number }, I.AccMatchingMeetings>('public.acc.matching.v2.setMeetingsTimezone'),
				},
				getAssessment: this._call<{ accId: number }, I.AccMatchingAssessment>('public.acc.matching.getAssessment'),
				getSchedule: this._call<{ tableCode: 'M' | 'T' | 'A', accId: number, accTeamId?: number, overridePersonId?: number }, I.AccMatchingSchedule>('public.acc.matching.getSchedule'),
				get: this._call<{ accId: number }, I.AccMatching>('public.acc.matching.get'),
				setEntrepreneurAssessment: this._call<{ accTeamId: number, assessment: number }, I.AccMatchingAssessment>('public.acc.matching.setEntrepreneurAssessment'),
				setMentorAssessment: this._call<{ accId: number, personId: number, assessment: number }, I.AccMatchingAssessment>('public.acc.matching.setMentorAssessment'),
				setMentorEvent: this._call<{ accId: number, eventId: number }, I.AccMatching>('public.acc.matching.setMentorEvent'),
				setMentorPitch: this._call<{ accId: number, pitchStr: string }, I.AccMatching>('public.acc.matching.setMentorPitch'),
				setSchedule: this._call<{ tableCode: 'M' | 'T', accId: number, slot: number, day: number, set: boolean, accTeamId?: number, overridePersonId?: number }, I.AccMatchingSchedule>('public.acc.matching.setSchedule'),
			},
			read: {
				getApplicantReads: this._call<{ accId: number }, I.AccReaderApplicantReads>('public.acc.read.getApplicantReads'),
				getApplicant: this._call<{ accId: number, applicationId: number }, I.AccApplicantRating>('public.acc.read.getApplicant'),
				setAssessment: this._call<I.AccAssessmentDetails, void>('public.acc.read.setAssessment'),
			},
		},
		alumReport: {
			addAdmin: this._call<{ email: string, companyId: number }, string>('public.alumReport.addAdmin'),
			getAccTeams: this._callNoPayload<{ accTeamId: number, companyName: string }[]>('public.alumReport.getAccTeams'),
			getBusinessDetails: this._call<{ accTeamId: number }, I.Old_CompanyDetails>('public.alumReport.getBusinessDetails'),
			getMenuLabel: this._call<{ accTeamId: number }, string>('public.alumReport.getMenuLabel'),
			getNps: this._call<{ accTeamId: number }, I.NetPromoterScore>('public.alumReport.getNps'),
			getPeople: this._call<{ accTeamId: number }, I.AccPeopleReport>('public.alumReport.getPeople'),
			getReport: this._call<{accTeamId: number}, I.AlumReport>('public.alumReport.getReport'),
			removeAdmin: this._call<{ personId: number, companyId: number }, string>('public.alumReport.removeAdmin'),
			setNps: this._call<{ accTeamId: number, value: number }, I.NetPromoterScore>('public.alumReport.setNps'),
			updateReport: this._call<{ lastYear: I.ReportYearDetails, thisYear: I.ReportYearDetails }, I.AlumReport>('public.alumReport.updateReport'),
		},
		appSession: {
			assignPerson: this._call<{ code: string }, I.AppSessionStatus>('public.appSession.assignPerson'),
			createPerson: this._call<I.AppSessionSearchDetails, I.AppSessionStatus>('public.appSession.createPerson'),
			findMatches: this._call<I.AppSessionSearchDetails, I.AppSessionStatus>('public.appSession.findMatches'),
			removeCode: this._callNoPayload<I.AppSessionStatus>('public.appSession.removeCode'),
			sendCode: this._call<{ assignedPersonId: number }, I.AppSessionStatus>('public.appSession.sendCode'),
			start: this._call<{ userAgent: I.UserAgent, languageId: I.LanguageId }, I.AppSessionStart>('public.appSession.start'),
		},
		application: {
			getAll: this._callNoPayload<I.ParticipantForApplication[]>('public.application.getAll'),
		},
		check: {
			getEmailUsage: this._call<{ email: string }, I.ValidEmail>('public.check.getEmailUsage'),
			getPhoneUsage: this._call<{ phone: string }, I.ValidPhone>('public.check.getPhoneUsage'),
			getZipData: this._call<{ zipcode: string }, I.ZipData>('public.check.getZipData'),
		},
		company: {
			addPosition: this._call<{ personId: number, companyId: number, title: string, isAdmin: boolean, isFounder: boolean, phone?: { number: string, extension: string } }, I.DbsCompany>('public.company.addPosition'),
			updateDetails: this._call<I.Old_CompanyDetails, void>('public.company.updateDetails'),
		},
		domainData: {
			reloadPeople: this._callNoPayload<{ people: I.SimplePerson[], directorOfPrograms: I.SimplePerson }>('public.domainData.reloadPeople'),
			reloadZips: this._callNoPayload<I.DbcZip[]>('public.domainData.reloadZips'),
		},
		feedback: {
			get: this._callNoPayload<I.UserFeedback[]>('public.feedback.get'),
		},
		i18n: {
			getLabelDetails: this._call<{ key: string, languageId: number }, I.LabelDetails>('public.i18n.getLabelDetails', { noPageSpinner: true }),
			getLabels: this._call<{ languageId: number }, I.LabelsData>('public.i18n.getLabels'),
		},
		pic: {
			applicant: {
				getReading: this._call<{ picId: number, applicationId: number }, I.PitchContestApplicantForAssessment>('public.pic.applicant.getReading'),
				getReadings: this._call<{ picId: number }, I.PitchContestApplicantReadings>('public.pic.applicant.getReadings'),
				setAnswers: this._call<{ details: I.PicApplicationAnswers }, void>('public.pic.applicant.setAnswers'),
				setReading: this._call<{ picId: number, applicationId: number, assessment: I.Assessment }, void>('public.pic.applicant.setReading'),
			},
			judge: {
				getTeam: this._call<{ picId: number, picTeamId: number }, I.PitchContestJudgeTeam>('public.pic.judge.getTeam'),
				getTeams: this._call<{ picId: number }, I.PitchContestJudgeTeams>('public.pic.judge.getTeams'),
				setTeam: this._call<{ picId: number, picTeamId: number, answers: I.Answer[] }, void>('public.pic.judge.setTeam'),
			},
		},
		survey: {
			accSession: {
				getSessionTopicsWithSpecialists: this._call<{ accSessionId: number }, { accSessionTopicWithSpecialists: I.AccSessionTopicWithSpecialists[], access: I.AccSessionSurveyAccess }>('public.survey.accSession.getSessionTopicsWithSpecialists'),
				setSessionSurveyResponse: this._call<I.AccSessionSurveyAnswers, number>('public.survey.accSession.setSessionSurveyResponse'),
			},
		},
		user: {
			addCompany: this._call<{ companyName: string, companyTypeId: number }, I.Old_MinimalCompany>('public.user.addCompany'),
			followSite: this._call<{ siteId: number, following: boolean }, void>('public.user.followSite'),
			getDashboardCards: this._callNoPayload<I.DashboardCard[]>('public.user.getDashboardCards'),
			setSiteMailList: this._call<{ tagId: number, siteId: number, toBeSubscribed: boolean }, void>('public.user.setSiteMailList'),
			start: this._callNoPayload<I.UserStart>('public.user.start'),
			toggleLanguageTag: this._call<{ tagName: 'english' | 'spanish' }, I.Old_Tag>('public.user.toggleLanguageTag'),
		},
		volunteer: {
			addApplication: this._call<I.MinimalVolunteerApplication, number>('public.volunteer.addApplication'),
			deleteApplication: this._call<{ volunteerApplicationId: number }, void>('public.volunteer.deleteApplication'),
			getApplication: this._call<{ volunteerApplicationId: number }, I.Old_VolunteerApplication>('public.volunteer.getApplication'),
			getApplicationsForUser: this._callNoPayload<I.VolunteerApplicationApplied[]>('public.volunteer.getApplicationsForUser', { noPageSpinner: false }),
			updateApplication: this._call<{ volunteerApplicationId: number, values: I.MinimalVolunteerApplication }, void>('public.volunteer.updateApplication'),
		},
	};

	public singletonEditors = {
		application: {
			setNotes: this._call<{ applicationId: number, notes: string }, { singletonsData: I.SingletonData, singletonsCacheUTC: number }>('singletonEditors.application.setNotes'),
		},
	};

	public user = {
		email: {
			remove: this._call<{ entityId: number, emailId: number }, I.DbsPerson>('user.email.remove', { noPageSpinner: true }),
			setPrimary: this._call<{ emailId: number }, I.DbsPerson>('user.email.setPrimary', { noPageSpinner: true }),
		},
		getDemographics: this._call<{ personId: number }, I.PersonDemographics>('user.getDemographics'),
		updateBasicInfo: this._call<{ values: I.UserBasicInfo }, I.DbsPerson>('user.updateBasicInfo'),
		updateBio: this._call<{ languageId: number, newBio: string }, I.DbsPerson>('user.updateBio'),
		updateDemographics: this._call<{ personId: number, values: I.PersonDemographics, triggers: I.LanguageTagTiggers }, I.PersonDemographics>('user.updateDemographics'),
	};

	public venue = {
		add: this._call<{ place: I.GooglePlace }, I.VenueAddResult>('venue.add'),
		delete: this._call<{ venueId: number }, boolean>('venue.delete'),
		getEvents: this._call<{ venueId: number }, I.DbsEvent[]>('venue.getEvents'),
		update: this._call<{ venueId: number, changes: I.VenueChanges }, boolean>('venue.update'),
	};

	public zip = {
		get: this._call<{ zipId: number }, I.DbcZip>('zip.get'),
	};

}