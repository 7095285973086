import { Component, Input, OnChanges } from '@angular/core';
import { Address } from '@me-interfaces';

@Component({
	selector: 'address-link',
	templateUrl: './SHR-UI_address-link.part.html',
	styleUrls: ['SHR-UI_address-link.part.scss'],
})
export class AddressLinkPart implements OnChanges {

	@Input() address: Address;
	@Input() translate = true;

	addressLink: string;


	ngOnChanges() {
		if (!this.address) this.addressLink = undefined;
		else this.buildAddressLink();
	}

	buildAddressLink() { 
		this.addressLink = '';
		if (this.address.zip && this.address.address) {
			this.addressLink = `${this.address.address}, ${this.address.zip.cityAndState}, ${this.address.zip.code}`;
		} else if (this.address.zip && !this.address.address) {
			this.addressLink = `${this.address.zip.cityAndState}, ${this.address.zip.code}`;
		} else if (!this.address.zip && this.address.address) {
			this.addressLink = `${this.address.address}`;
		}
	}

}