import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { GridColumn } from '@me-grid';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';
import { FilterExpression } from '@progress/kendo-angular-filter';
import { CompositeFilterDescriptor } from "@progress/kendo-data-query";
import { filterTypes } from '../filtering';


interface FilterDialogData {
	filters: CompositeFilterDescriptor,
	columns: GridColumn<unknown>[],
}


@Component({
	selector: 'grid-filter-dialog',
	templateUrl: './filter.dialog.html',
	styleUrls: ['./filter.dialog.scss']
})
export class GridFilterDialog extends DestroyablePart implements OnInit {

	originalFilters: CompositeFilterDescriptor;
	updatedFilters: CompositeFilterDescriptor;

	readonly filterExpressions: FilterExpression[];

	applyAction: DialogAction<CompositeFilterDescriptor> = {
		id: 'apply',
		cssClass: '',
		enabled: true,
		visible: true,
		label: 'Apply',
		linkType: "callback",
		callback: async () => this.updatedFilters,
		willCloseDialog: true,
	};

	clearAction: DialogAction = {
		id: 'clear',
		cssClass: 'btn-red',
		enabled: true,
		visible: true,
		label: 'Clear',
		linkType: "no-action",
		willCloseDialog: true,
	};

	actions: DialogAction<unknown>[] = [this.applyAction, this.clearAction];


	constructor(
		@Inject(MAT_DIALOG_DATA) dialog: DialogContext<FilterDialogData>,
		public dialogRef: MatDialogRef<GridFilterDialog>,
	) {
		super();

		const { filters, columns } = dialog.data;

		this.originalFilters = filters;
		this.updatedFilters = filters;
		this.filterExpressions = this.mapColumnsToFilterExpressions(columns);
	}


	ngOnInit() {
		super.initDestroyable();
	}


	mapColumnsToFilterExpressions(columns: GridColumn<unknown>[]): FilterExpression[] {

		const expressions: FilterExpression[] = columns
			.map(column => {
				const e: FilterExpression = {
					field: column.field,
					title: column.header as string, // Any LabelKey would have already been translated
					editor: this.getFilterEditor(column),
				};

				return e;
			})
			.filter(e => !!e.editor);	// Unfilterable columns will have editor set to undefined

		return expressions;
	}


	/**
	 * This function overcomes inconsistencies with Kendo filter types. The KendoColumn used filter
	 * types of 'text' and 'numeric' but the FilterExpression interface uses 'string' and 'number'
	 */
	getFilterEditor(column: GridColumn<unknown>) {

		const filterType = filterTypes[column.type];

		if (filterType == 'text') return 'string';
		if (filterType == 'numeric') return 'number';
		if (filterType == 'boolean') return 'boolean';
		if (filterType == 'date') return 'date';

		return undefined;
	}


	onFiltersChange(filters: CompositeFilterDescriptor) {
		this.updatedFilters = filters;
		if (this.applyAction.cssClass !== 'btn-green') this.applyAction.cssClass = 'btn-green';
	}

}