
import { DbsPerson, SimplePerson } from '@me-interfaces';
import { DdObject } from '../dd-object';
import { DdProgram } from '../dd-program/dd-program';
import { DdSite } from '../dd-site/dd-site';
import { OldDomainDataService } from '../domain-data.service';
import { DdSiteProgramRow } from './dd-site-program-row';

/**
 * @deprecated Use the DataService instead
 */
export class DdSiteProgram extends DdObject<DdSiteProgramRow> implements DdSiteProgramRow {

	constructor(private dd: OldDomainDataService, data: DdSiteProgramRow) {
		super(data);
	}

	public get siteProgramId(): number {
		return this._data.siteProgramId;
	}

	public get siteId(): number {
		return this._data.siteId;
	}

	public get site(): DdSite {
		return this.dd.sites.getById(this.siteId);
	}

	public get programId(): number {
		return this._data.programId;
	}

	public get program(): DdProgram {
		return this.dd.programs.getById(this.programId);
	}

	public get managerId(): number {
		return this._data.managerId;
	}

	public setManagerId(newManagerId: number) {
		this._data.managerId = newManagerId;
	}

	public getManager(): SimplePerson {
		return this.dd.getCachedPerson(this.managerId);
	}

	public get isActive(): boolean {
		return this._data.isActive;
	}

	public get altManagerId(): number {
		return this._data.altManagerId;
	}

	public setAltManagerId(newAltManagerId: number) {
		this._data.altManagerId = newAltManagerId;
	}

	public getAltManager(): SimplePerson {
		return this.dd.getCachedPerson(this.altManagerId);
	}
}