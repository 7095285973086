import { AdminAreaService, NationalDashboardPageService, SharedDashboardViewName, SharedDashboardsService, NationalDashboardViewId as ViewId } from '@ADMIN-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AppAreaIdentifier } from '@me-interfaces';
import { DroplistItem, Icon, getIconContext } from '@me-shared-parts/UI-common';
import { map } from 'rxjs';


@Component({
	selector: 'national-views-part',
	templateUrl: './national-views.part.html',
})
export class NationalViewsPart extends DestroyablePart implements OnInit {

	readonly: boolean;
	identifier: AppAreaIdentifier<string>;


	viewDroplistItems: DroplistItem<never, ViewId>[] = [

		//
		// National
		//
		{ uniqueId: ViewId.Indicators, groupName: 'National', iconContext: getIconContext(Icon.view_overview, undefined, undefined, 'Dashboard'), customSort: 1 },
		{ uniqueId: ViewId.Regions, groupName: 'National', iconContext: getIconContext(Icon.concept_region, undefined, undefined, 'Regions'), customSort: 2 },
		{ uniqueId: ViewId.Sites, groupName: 'National', iconContext: getIconContext(Icon.concept_site, undefined, undefined, 'Sites'), customSort: 3 },
		{ uniqueId: ViewId.Businesses, groupName: 'National', iconContext: getIconContext(Icon.concept_company, undefined, undefined, 'Businesses'), customSort: 4 },
		{ uniqueId: ViewId.Entrepreneurs, groupName: 'National', iconContext: getIconContext(Icon.concept_person, undefined, undefined, 'Entrepreneurs'), customSort: 5 },
		{ uniqueId: ViewId.Awards, groupName: 'National', iconContext: getIconContext(Icon.concept_award, undefined, undefined, 'Awards (under development)'), customSort: 6 },
		{ uniqueId: ViewId.Events, groupName: 'National', iconContext: getIconContext(Icon.view_events, undefined, undefined, 'Events (under development)'), customSort: 7 },

		//
		// Accelerator
		//
		{ uniqueId: ViewId.Accs, groupName: 'Accelerator', iconContext: getIconContext(Icon.concept_accelerator, undefined, undefined, 'Accelerators (under development)'), customSort: 1 },
		{ uniqueId: ViewId.AccApplications, groupName: 'Accelerator', iconContext: getIconContext(Icon.view_applications, undefined, undefined, 'Applications (under development)'), customSort: 2 },
		{ uniqueId: ViewId.AccTeams, groupName: 'Accelerator', iconContext: getIconContext(Icon.concept_company, undefined, undefined, 'Teams (under development)'), customSort: 3 },
		{ uniqueId: ViewId.AccMentors, groupName: 'Accelerator', iconContext: getIconContext(Icon.concept_person, undefined, undefined, 'Mentors (under development)'), customSort: 4 },
		{ uniqueId: ViewId.AccReaders, groupName: 'Accelerator', iconContext: getIconContext(Icon.concept_person, undefined, undefined, 'Readers (under development)'), customSort: 5 },
		{ uniqueId: ViewId.AccInterviewers, groupName: 'Accelerator', iconContext: getIconContext(Icon.concept_person, undefined, undefined, 'Interviewers (under development)'), customSort: 6 },
		{ uniqueId: ViewId.AccJudges, groupName: 'Accelerator', iconContext: getIconContext(Icon.concept_person, undefined, undefined, 'Judges (under development)'), customSort: 7 },

		//
		// Pitch Contest
		//
		{ uniqueId: ViewId.Pics, groupName: 'Pitch Contest', iconContext: getIconContext(Icon.concept_pitchContest, undefined, undefined, 'Pitch Contests (under development)'), customSort: 1 },
		{ uniqueId: ViewId.PicApplications, groupName: 'Pitch Contest', iconContext: getIconContext(Icon.view_applications, undefined, undefined, 'Applications (under development)'), customSort: 2 },
		{ uniqueId: ViewId.PicTeams, groupName: 'Pitch Contest', iconContext: getIconContext(Icon.concept_company, undefined, undefined, 'Teams (under development)'), customSort: 3 },
		{ uniqueId: ViewId.PicTeams, groupName: 'Pitch Contest', iconContext: getIconContext(Icon.concept_person, undefined, undefined, 'Readers (under development)'), customSort: 4 },
		{ uniqueId: ViewId.PicTeams, groupName: 'Pitch Contest', iconContext: getIconContext(Icon.concept_person, undefined, undefined, 'Judges (under development)'), customSort: 5 },

		//
		// Reporting
		//
		{ uniqueId: ViewId.ReportingLooker, groupName: 'Reporting', iconContext: getIconContext(Icon.view_report, undefined, undefined, 'Looker'), customSort: 1 },
		{ uniqueId: ViewId.ReportingCharts, groupName: 'Reporting', iconContext: getIconContext(Icon.view_report, undefined, undefined, 'Charts (under development)'), customSort: 2 },
	];

	selectedViewItem$ = this.pageService.selectedView$.pipe(
		map(viewId => {
			const selectedView = this.viewDroplistItems.find(item => item.uniqueId == viewId);
			this.sharedViewsService.view$.next(this.getSharedView(selectedView));
			return selectedView;
		}),
	);


	constructor(
		private pageService: NationalDashboardPageService,
		public adminAreaService: AdminAreaService,
		public sharedViewsService: SharedDashboardsService,
	) {
		super();
		this.identifier = adminAreaService.getId();
		sharedViewsService.identifier$.next(this.identifier);
	}


	ngOnInit(): void {

		super.initDestroyable();

		super.subscribe([this.adminAreaService.accessAndId$], async ([accessAndId]) => {
			this.readonly = accessAndId?.access?.root != 'Write';
			this.identifier = accessAndId?.id;
		});
	}


	onViewDroplistChange(selectedView: DroplistItem) {
		this.pageService.selectView(selectedView.uniqueId);
		this.sharedViewsService.view$.next(this.getSharedView(selectedView));
	}


	getSharedView(viewItem: DroplistItem<never, ViewId>): SharedDashboardViewName {

		if (!viewItem?.uniqueId) return undefined;

		const viewId = viewItem.uniqueId;

		if (viewId == ViewId.Indicators) return 'indicators';
		if (viewId == ViewId.Regions) return 'regions';
		if (viewId == ViewId.Sites) return 'sites';
		if (viewId == ViewId.Businesses) return 'businesses';
		if (viewId == ViewId.Entrepreneurs) return 'entrepreneurs';
		if (viewId == ViewId.Awards) return 'awards';
		if (viewId == ViewId.Events) return 'events';
		if (viewId == ViewId.Accs) return 'accs';
		if (viewId == ViewId.AccApplications) return 'acc-applications';
		if (viewId == ViewId.AccTeams) return 'acc-teams';
		if (viewId == ViewId.AccReaders) return 'acc-readers';
		if (viewId == ViewId.AccInterviewers) return 'acc-interviewers';
		if (viewId == ViewId.AccMentors) return 'acc-mentors';
		if (viewId == ViewId.AccJudges) return 'acc-judges';
		if (viewId == ViewId.Pics) return 'pics';
		if (viewId == ViewId.PicApplications) return 'pic-applications';
		if (viewId == ViewId.PicTeams) return 'pic-teams';
		if (viewId == ViewId.PicReaders) return 'pic-readers';
		if (viewId == ViewId.PicJudges) return 'pic-judges';

		return undefined;
	}


	showView(view: string, viewItem: DroplistItem<never, ViewId>) {

		if (!viewItem?.uniqueId) return false;

		const viewId = viewItem.uniqueId;

		//
		// Reporting
		//
		if (viewId == ViewId.ReportingLooker && view == 'reporting-looker') return true;
		if (viewId == ViewId.ReportingCharts && view == 'reporting-charts') return true;

		return false;
	}
}