import { Dba, DbConceptName, Immutable } from '@me-interfaces';


interface MutableDbaScheduleTemplate extends Dba {

	readonly _concept: DbConceptName.ScheduleTemplate,

	scheduleTemplateId: number,
	name: string,
	year: number,
	accSeasonId: number,
	accMeetingTimesId: number,
}


export interface DbaScheduleTemplate extends Immutable<MutableDbaScheduleTemplate> { }