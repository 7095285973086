<div class='q'>{{ answer.question.text }}</div>
<div class='a' [formGroup]='formGroup'>
	<mat-form-field *ngIf='answer.question.questionTypeId === 1'>
		<input #input1 matInput [placeholder]='inlineLabels["Text"]' [formControlName]='controlName' (blur)='trim(control)'>
		<mat-error *ngIf='control.errors && control.errors.required'><me-label key='Required field'></me-label></mat-error>
	</mat-form-field>

	<mat-form-field *ngIf='answer.question.questionTypeId === 2 || answer.question.questionTypeId === 8 || answer.question.questionTypeId === 9 || answer.question.questionTypeId === 10'>
		<textarea #input2 matInput mat-autosize [placeholder]='inlineLabels["Multiple line Text"]' [formControlName]='controlName' (blur)='trim(control)'
		 type="text"></textarea>
		<mat-error *ngIf='control.errors && control.errors.required'><me-label key='Required field'></me-label></mat-error>
	</mat-form-field>

	<mat-form-field *ngIf='answer.question.questionTypeId === 3'>
		<textarea #input3 matInput mat-autosize [placeholder]='inlineLabels["Multiple line Text"]' [formControlName]='controlName' (blur)='trim(control)'
		 type="text"></textarea>
		<mat-error *ngIf='control.errors && control.errors.required'><me-label key='Required field'></me-label></mat-error>
		<mat-error *ngIf='control.errors && control.errors.maxlength'><me-label [key]='tooManyCharacters(control)'></me-label></mat-error>
	</mat-form-field>

	<div *ngIf='answer.question.questionTypeId === 4'>
		<mat-radio-group class="radio-group" [formControlName]='controlName' (change)='ratingChanged.emit($event.value)'>
			<i class="far fa-thumbs-down"></i>
			<mat-radio-button *ngFor="let i of ['1','2','3','4','5']" [value]="i" class='{{"rating" + i}}'></mat-radio-button>
			<i class="far fa-thumbs-up"></i>
		</mat-radio-group>
	</div>
	<div *ngIf='answer.question.questionTypeId === 5'>
		<me-label key='Yes/No'></me-label>
	</div>
	<mat-form-field *ngIf='answer.question.questionTypeId === 6'>
		<input #input6 matInput [placeholder]='inlineLabels["Text"]' [formControlName]='controlName' (blur)='trim(control)'>
		<mat-error *ngIf='control.errors && control.errors.required'><me-label key='Required field'></me-label></mat-error>
	</mat-form-field>

	<mat-form-field *ngIf='answer.question.questionTypeId === 7'>
		<textarea #input7 matInput mat-autosize [placeholder]='inlineLabels["Multiple line Text"]' [formControlName]='controlName' (blur)='trim(control)'
		 type="text" maxlength="300"></textarea>
		<mat-error *ngIf='control.errors && control.errors.required'><me-label key='Required field'></me-label></mat-error>
		<mat-error *ngIf='control.errors && control.errors.maxlength'><me-label [key]='tooManyCharacters(control)'></me-label></mat-error>
	</mat-form-field>
</div>