import { FieldTarget } from "./field-target";

/**
 * Indirectly relates to the data the field value represents. When
 * the func.field.update() function is called, this context will be
 * used to determine if the user is authorized to perform the update
 * and what the update actually consists of. 
 */
export interface FieldContext {
	target: FieldTarget,
	id1: number,
	id2?: number,
}