import { Icon } from "./icon";

/**
 * Return the default text for an icon
 */
export function getIconDefaultText(icon: Icon) {

	if (icon == Icon.accStage_setup) return `Setup`;
	if (icon == Icon.accStage_accepting) return `Accepting Applications`;
	if (icon == Icon.accStage_reading) return `Reading Applications`;
	if (icon == Icon.accStage_interviewing) return `Interviewing Applicants`;
	if (icon == Icon.accStage_selectingCohort) return `Selecting Cohort`;
	if (icon == Icon.accStage_matchingMentors) return `Matching Mentors`;
	if (icon == Icon.accStage_curriculum) return `Curriculum`;
	if (icon == Icon.accStage_selectingWinners) return `Selecting Winners`;
	if (icon == Icon.accStage_quarterlies) return `Quarterlies`;
	if (icon == Icon.accStage_complete) return `Complete`;

	if (icon == Icon.action_add) return `Add`;
	if (icon == Icon.action_calendar) return `Calendar`;
	if (icon == Icon.action_call) return `Call`;
	if (icon == Icon.action_clipboard) return `Clipboard`;
	if (icon == Icon.action_delete) return `Delete`;
	if (icon == Icon.action_down) return `Down`;
	if (icon == Icon.action_edit) return `Edit`;
	if (icon == Icon.action_editNotes) return `Edit Notes`;
	if (icon == Icon.action_explore) return `Explore`;
	if (icon == Icon.action_email) return `Email`;
	if (icon == Icon.action_help) return `Help`;
	if (icon == Icon.action_map) return `Google Map`;
	if (icon == Icon.action_menu) return `Menu`;
	if (icon == Icon.action_more) return `More`;
	if (icon == Icon.action_open) return `Open`;
	if (icon == Icon.action_loading) return `Loading`;
	if (icon == Icon.action_next) return `Next`;
	if (icon == Icon.action_previous) return `Previous`;
	if (icon == Icon.action_search) return `Search`;
	if (icon == Icon.action_sms) return `Text`;
	if (icon == Icon.action_transfer) return `Transfer`;
	if (icon == Icon.action_up) return `Up`;
	if (icon == Icon.action_video) return `Video`;
	if (icon == Icon.action_view) return `View`;

	if (icon == Icon.concept_accelerator) return `Accelerator`;
	if (icon == Icon.concept_application) return `Application`;
	if (icon == Icon.concept_accTeam) return `Accelerator Team`;
	if (icon == Icon.concept_agreementType) return `Agreement Type`;
	if (icon == Icon.concept_agreementSignature) return `Agreement Signature`;
	if (icon == Icon.concept_award) return `Award`;
	if (icon == Icon.concept_company) return `Company`;
	if (icon == Icon.concept_event) return `Event`;
	if (icon == Icon.concept_national) return `National`;
	if (icon == Icon.concept_picTeam) return `Pitch Contest Team`;
	if (icon == Icon.concept_pitchContest) return `Pitch Contest`;
	if (icon == Icon.concept_person) return `Person`;
	if (icon == Icon.concept_people) return `People`;
	if (icon == Icon.concept_position) return `Position`;
	if (icon == Icon.concept_program) return `Program`;
	if (icon == Icon.concept_region) return `Region`;
	if (icon == Icon.concept_site) return `Site`;
	if (icon == Icon.concept_venue) return `Venue`;
	if (icon == Icon.concept_zip) return `Zip`;

	if (icon == Icon.droplist_defaultSelectAnItem) return `Select an Item...`;
	if (icon == Icon.droplist_pickOne) return `{{ Selection }}`;

	if (icon == Icon.dialog_explore) return `Explore`;

	if (icon == Icon.empty) return '';

	if (icon == Icon.eventType_accFinalPitch) return `Final Presentations`;
	if (icon == Icon.eventType_accInterviewing) return `Interviewing`;
	if (icon == Icon.eventType_accMentorMatching) return `Mentor Matching`;
	if (icon == Icon.eventType_accReading) return `Reading`;
	if (icon == Icon.eventType_picContest) return `Pitch Contest`;
	if (icon == Icon.eventType_picReading) return `Reading`;
	if (icon == Icon.eventType_accQuarterliesQ2) return `Quarterlies Q2`;
	if (icon == Icon.eventType_accQuarterliesQ3) return `Quarterlies Q3`;
	if (icon == Icon.eventType_accQuarterliesQ4) return `Quarterlies Q4`;
	if (icon == Icon.eventType_accShowcase) return `Showcase`;

	if (icon == Icon.industry_administrativeSupport) return `Administrative Support`;
	if (icon == Icon.industry_agriculture) return `Agriculture`;
	if (icon == Icon.industry_animals) return `Animals`;
	if (icon == Icon.industry_apparel_accessories) return `Apparel / Accessories`;
	if (icon == Icon.industry_arts_culture) return `Arts / Culture`;
	if (icon == Icon.industry_automotive) return `Automotive`;
	if (icon == Icon.industry_beauty_cosmetics) return `Beauty / Cosmetics`;
	if (icon == Icon.industry_childcare_familyServices) return `Childcare / Family Services`;
	if (icon == Icon.industry_cleaning) return `Cleaning`;
	if (icon == Icon.industry_coaching_mentoring) return `Coaching / Mentoring`;
	if (icon == Icon.industry_communitySupport) return `Community Support`;
	if (icon == Icon.industry_creativeServices_multimedia) return `Creative Services / Multimedia`;
	if (icon == Icon.industry_education) return `Education`;
	if (icon == Icon.industry_entertainment_recreation) return `Entertainment / Recreation`;
	if (icon == Icon.industry_environment) return `Environment`;
	if (icon == Icon.industry_eventPlanning) return `Event Planning`;
	if (icon == Icon.industry_finance_accounting) return `Finance / Accounting`;
	if (icon == Icon.industry_food_beverage) return `Food / Beverage`;
	if (icon == Icon.industry_health_wellness) return `Health / Wellness`;
	if (icon == Icon.industry_hospitality_accommodations) return `Hospitality / Accommodations`;
	if (icon == Icon.industry_lawncare) return `Lawncare`;
	if (icon == Icon.industry_legal) return `Legal`;
	if (icon == Icon.industry_manufacturing) return `Manufacturing`;
	if (icon == Icon.industry_marketing) return `Marketing`;
	if (icon == Icon.industry_medical) return `Medical`;
	if (icon == Icon.industry_other) return `Other`;
	if (icon == Icon.industry_personal_relationships) return `Personal / Relationships`;
	if (icon == Icon.industry_philanthropy) return `Philanthropy`;
	if (icon == Icon.industry_photography_media) return `Photography / Media`;
	if (icon == Icon.industry_propertyManagement) return `Property Management`;
	if (icon == Icon.industry_retail) return `Retail`;
	if (icon == Icon.industry_salon_spa_personalCare) return `Salon/ Spa / Personal Care`;
	if (icon == Icon.industry_technology) return `Technology`;
	if (icon == Icon.industry_trades_electrician_plumber) return `Trades / Electrician / Plumber`;
	if (icon == Icon.industry_travel_tourism) return `Travel / Tourism`;

	if (icon == Icon.language_en) return `English`;
	if (icon == Icon.language_es) return `Español`;

	if (icon == Icon.level_exact) return `Exact`;
	if (icon == Icon.level_high) return `High`;
	if (icon == Icon.level_medium) return `Medium`;
	if (icon == Icon.level_low) return `Low`;
	if (icon == Icon.level_related) return `Related`;

	if (icon == Icon.link_facebook) return `Facebook`;
	if (icon == Icon.link_linkedIn) return `LinkedIn`;
	if (icon == Icon.link_twitter) return `Twitter`;
	if (icon == Icon.link_website) return `Website`;

	if (icon == Icon.material_spreadsheet) return `Spreadsheet`;
	if (icon == Icon.material_slide) return `Slide`;
	if (icon == Icon.material_handout) return `Handout`;
	if (icon == Icon.material_pdf) return `PDF`;
	if (icon == Icon.material_doc) return `Document`;
	if (icon == Icon.material_video) return `Video`;
	if (icon == Icon.material_weblink) return `Weblink`;
	if (icon == Icon.material_blog) return `Blog`;

	if (icon == Icon.notes_none) return `No Notes`;
	if (icon == Icon.notes_redFlag) return `Has Red Flag`;
	if (icon == Icon.notes_noRedFlag) return `No Red Flag`;
	if (icon == Icon.notes_some) return `Has Notes`;

	if (icon == Icon.status_check) return `Success`;
	if (icon == Icon.status_deceased) return `Deceased`;
	if (icon == Icon.status_doNotContact) return `Do Not Contact`;
	if (icon == Icon.status_error) return `Error`;
	if (icon == Icon.status_missing) return `Missing Value`;
	if (icon == Icon.status_pending) return `Pending`;
	if (icon == Icon.status_selected) return `Selected`;
	if (icon == Icon.status_success) return `Success`;
	if (icon == Icon.status_warning) return `Warning`;
	if (icon == Icon.status_x) return `Error`;

	if (icon == Icon.tool_disabled) return `Tool Disabled`;
	if (icon == Icon.tool_enabled) return `Tool Enabled`;

	if (icon == Icon.view_applications) return `Applications`;
	if (icon == Icon.view_assessments) return `Assessments`;
	if (icon == Icon.view_bios) return `Languages & Bios`;
	if (icon == Icon.view_bulk_editor) return `Bulk Editor`;
	if (icon == Icon.view_config) return `Configure`;
	if (icon == Icon.view_demographics) return `Demographics`;
	if (icon == Icon.view_decide_awards) return `Decide Awards`;
	if (icon == Icon.view_editor) return `Editor`;
	if (icon == Icon.view_entrepreneurs) return `Entrepreneurs`;
	if (icon == Icon.view_event_past) return `Past Event`;
	if (icon == Icon.view_event_future) return `Future Event`;
	if (icon == Icon.view_event_current) return `Current Event`;
	if (icon == Icon.view_events) return `Events`;
	if (icon == Icon.view_feedback) return `Feedback`;
	if (icon == Icon.view_goals) return `Goals And Progress`;
	if (icon == Icon.view_hints) return `Hints`;
	if (icon == Icon.view_interviewers) return `Interviewers`;
	if (icon == Icon.view_judges) return `Judges`;
	if (icon == Icon.view_list) return `List`;
	if (icon == Icon.view_members) return `Members`;
	if (icon == Icon.view_mentors) return `Mentors`;
	if (icon == Icon.view_mm_heatmap) return `Schedule Heatmap`;
	if (icon == Icon.view_mm_matcher) return `Matching Tool`;
	if (icon == Icon.view_overview) return `Overview`;
	if (icon == Icon.view_programming) return `Programming`;
	if (icon == Icon.view_relationships) return `Relationships`;
	if (icon == Icon.view_readers) return `Readers`;
	if (icon == Icon.view_report) return `Report`;
	if (icon == Icon.view_sessions) return `Sessions`;
	if (icon == Icon.view_surveys) return `Surveys`;
	if (icon == Icon.view_tags) return `Tags`;
	if (icon == Icon.view_teams) return `Teams`;
	if (icon == Icon.view_topics) return `Topics`;
	if (icon == Icon.view_volunteers) return `Volunteers`;

}
