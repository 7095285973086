import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { NationalDashboardPageService } from '../../national-dashboard-page.service';

@Component({
	selector: 'national-news-tab-part',
	templateUrl: './national-news-tab.part.html',
	styleUrls: ['./national-news-tab.part.scss'],
})
export class NationalNewsTabPart extends DestroyablePart implements OnInit {

	height: number;

	constructor(
		public pageService: NationalDashboardPageService,
	) {
		super();
	}

	ngOnInit(): void {
		super.initDestroyable();

		this.pageService.layout$.subscribe(
			layout => {
				this.height = layout.tabContentHeight - 10;
			}
		);

	}

}
