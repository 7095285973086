<div class='acc-quarterly-controller'>
	<message-line *ngIf="disabled && accStageId < 50">Accelerator must be after the "Selecting Cohort" Stage to enable
		quarters.
	</message-line>
	<message-line *ngIf="disabled && accStageId > 80">Quarterlies are disabled because Accelerator is "Complete".
	</message-line>
	<div class='quarterly-grid' *ngFor='let i of [0,1,2,3]'>
		<div>
			<p>{{ quarterNames[i] }}</p>
		</div>
		<div class='switch'>
			<kendo-switch [checked]="enabledQuarters[i]" (valueChange)='setQuarter(i, $event)' offLabel='Off'
				onLabel='On' class="thin-switch" [disabled]='disabled'></kendo-switch>
		</div>
	</div>
</div>