import { Explorable } from "@me-services/core/data/explorable";
import { getIconContext } from "./get-icon-context";
import { getIconForConceptName } from "./get-icon-for-concept-name";
import { IconContext } from "./icon-context";
import { IconHideTextAt } from "./icon-hide-text-at";
import { IconStyle } from "./icon-style";
import { Accelerator, AccTeam, Application, Company, Event, Person, PicTeam, PitchContest, Site, Venue } from '@me-interfaces';


export function getIconContextForPackage(

	pkg: Explorable,

	/**
	 * A standard FontAwesome style.
	 * Note that this is a suggestion.
	 * Some icons ignore this.
	 */
	iconStyle: IconStyle = 'fa-regular',

	/**
	 * Define the breakpoint where the text changes from being right of the
	 * icon to being hidden but added as a tooltip.
	 */
	hideText: IconHideTextAt = 'never',

	/**
	 * Optionally display a different string that the name property
	 */
	nameOverride: string = undefined,
): IconContext {

	const name = nameOverride ?? pkg.name;
	const icon = getIconForConceptName(pkg._concept);

	return getIconContext(icon, iconStyle, hideText, name, pkg.hasRedFlag);
}


export function getIconContextForAccelerator(acc: Accelerator, iconStyle: IconStyle = 'fa-regular', hideText: IconHideTextAt = 'never', nameOverride: string = undefined) {
	return getIconContextForPackage(acc, iconStyle, hideText, nameOverride);
}

export function getIconContextForAccTeam(accTeam: AccTeam, iconStyle: IconStyle = 'fa-regular', hideText: IconHideTextAt = 'never', nameOverride: string = undefined) {
	return getIconContextForPackage(accTeam, iconStyle, hideText, nameOverride);
}

export function getIconContextForApplication(application: Application, iconStyle: IconStyle = 'fa-regular', hideText: IconHideTextAt = 'never', nameOverride: string = undefined) {
	return getIconContextForPackage(application, iconStyle, hideText, nameOverride);
}

export function getIconContextForCompany(company: Company, iconStyle: IconStyle = 'fa-regular', hideText: IconHideTextAt = 'never', nameOverride: string = undefined) {
	return getIconContextForPackage(company, iconStyle, hideText, nameOverride);
}

export function getIconContextForEvent(event: Event, iconStyle: IconStyle = 'fa-regular', hideText: IconHideTextAt = 'never', nameOverride: string = undefined) {
	return getIconContextForPackage(event, iconStyle, hideText, nameOverride);
}

export function getIconContextForPerson(person: Person, iconStyle: IconStyle = 'fa-regular', hideText: IconHideTextAt = 'never', nameOverride: string = undefined) {
	return getIconContextForPackage(person, iconStyle, hideText, nameOverride);
}

export function getIconContextForPicTeam(picTeam: PicTeam, iconStyle: IconStyle = 'fa-regular', hideText: IconHideTextAt = 'never', nameOverride: string = undefined) {
	return getIconContextForPackage(picTeam, iconStyle, hideText, nameOverride);
}

export function getIconContextForPitchContest(pic: PitchContest, iconStyle: IconStyle = 'fa-regular', hideText: IconHideTextAt = 'never', nameOverride: string = undefined) {
	return getIconContextForPackage(pic, iconStyle, hideText, nameOverride);
}

export function getIconContextForSite(site: Site, iconStyle: IconStyle = 'fa-regular', hideText: IconHideTextAt = 'never', nameOverride: string = undefined) {
	return getIconContextForPackage(site, iconStyle, hideText, nameOverride);
}

export function getIconContextForVenue(venue: Venue, iconStyle: IconStyle = 'fa-regular', hideText: IconHideTextAt = 'never', nameOverride: string = undefined) {
	return getIconContextForPackage(venue, iconStyle, hideText, nameOverride);
}
