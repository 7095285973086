import { DataStateChangeEvent, GridComponent, PageChangeEvent } from "@progress/kendo-angular-grid";
import { process, State } from "@progress/kendo-data-query";
import { BehaviorSubject } from "rxjs";
import { GridSetup } from "../interfaces";


const DEFAULT_STATE = { skip: 0, take: 50, group: [] };

export class BaseGridController<ROW> {

	public state: State;

	private _rows: ROW[] = [];
	public filteredRows$ = new BehaviorSubject<ROW[]>([]);
	public counts$ = new BehaviorSubject<string>('');

	protected gridComponent: GridComponent | undefined;
	public readonly idField: string;


	/**
	 * Base functionality for controlling a grid
	 * @param idField The name of the property that uniquely identifies a row object
	 * @param stateKey If present, the controller will retain the grid's state in session storage
	 */
	constructor(private gridSetup: GridSetup<ROW>) {

		this.idField = gridSetup.rowKey;

		//
		// Start off with the default, which includes skip and take
		//
		let state = DEFAULT_STATE;

		//
		// If we have already rendered this grid in this session, and there is a stateKey,
		// then we'll use the prior state so things like grouping, filtering, and sorting
		// are retained.
		//
		if (gridSetup.stateKey && sessionStorage.getItem(gridSetup.stateKey)) {
			const storedState = sessionStorage.getItem(gridSetup.stateKey);
			if (storedState) state = JSON.parse(storedState);
		}

		//
		// If this is the first rendering of this grid for this session, check if there is
		// an initial state defined in the setup.  If so, merge it into the default state.
		//
		else if (gridSetup.initialState) {
			state = { ...state, ...gridSetup.initialState };
		}

		this.state = state;
	}


	public setGridComponent(gridComponent: GridComponent | undefined) {
		this.gridComponent = gridComponent;
	}


	protected applyState() {

		//
		// Add a count calculation to each grouping
		//
		for (const group of this.state.group || []) {
			group.aggregates = [
				{ field: group.field, aggregate: 'count' },
			];
		}

		//
		// Get the filtered rows and emit them
		//
		const filteredRows = this.getFilteredRows();
		this.counts$.next(`${filteredRows.length.toLocaleString()} of ${this._rows.length.toLocaleString()}`);
		this.filteredRows$.next(filteredRows);

		//
		// Remember the state for this grid
		//
		if (sessionStorage && this.gridSetup.stateKey) sessionStorage.setItem(this.gridSetup.stateKey, JSON.stringify(this.state));
	}


	public setRows(rows: ROW[]) {
		this._rows = rows;
		this.applyState();
	}


	public getRows(): ROW[] {
		return [...this._rows];
	}


	public getFilteredRows(): ROW[] {
		const state: State = { filter: this.state.filter };
		const filteredRows = process(this._rows, state);
		return filteredRows.data;
	}


	public dataStateChange(state: DataStateChangeEvent): void {
		this.state = state;
		this.applyState();
	}


	public pageChange(event: PageChangeEvent): void {
		this.state.skip = event.skip;
		this.applyState();
	}


	/**
	* Return the set of rows that match the provided ids.
	*/
	protected getRowsById(rowIds: (string | number)[]): ROW[] {
		return rowIds
			.map(id => this._rows.find(row => row[this.idField] == id))
			.filter(row => !!row);
	}


	/**
	 * Return the one row that matches the provided id
	 * or returns undefined if there isn't one
	 */
	protected getRowById(rowId: (string | number)): ROW {
		return this._rows.find(row => row[this.idField] == rowId);
	}


	/**
	 * given a partial state object, the values provided will be merged into the current grid state.
	 * The state object is defined at https://www.telerik.com/kendo-angular-ui/components/data-query/api/State/
	 */
	public mergeState(state: State) {
		if (!state) return;
		this.state = { ...this.state, ...state };
	}

}