import { ExplorableConceptName } from "@me-interfaces";
import { DataService } from '@me-services/core/data';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { SearchMatch } from '../engine/interfaces';
import { SearchFilters } from '../engine/parse-search-text';
import { SearchEngine } from '../engine/search-engine';


/**
 * Service to find matching singletons.
 */
export class SearchService {

	constructor(private ds: DataService) {
	}

	private _engine = new SearchEngine(this.ds);

	//
	// The actual search results
	//
	private readonly _results$ = new BehaviorSubject<SearchMatch[]>([]);
	public readonly results$ = this._results$.asObservable();

	//
	// So we can show when there is activity
	//
	public readonly loading$ = new BehaviorSubject<string>(undefined);
	private readonly _searching$ = new BehaviorSubject(false);
	public readonly busy$ = combineLatest([this.loading$, this._searching$]).pipe(map(([loading, searching]) => !!loading || searching));

	//
	// The search text
	//
	private readonly _text$ = new BehaviorSubject<string>('');
	public readonly text$ = this._text$.asObservable();

	//
	// The search filters
	//
	private readonly _filters$ = new BehaviorSubject<SearchFilters>({ filtered: false });
	public readonly filters$ = this._filters$.asObservable();

	//
	// True when there are changes to text or filters that have not yet been searched
	//
	private readonly _dirty$ = new BehaviorSubject(false);

	//
	// Whether the search button should be enabled
	//
	public readonly canSearch$ = combineLatest([this.busy$, this.text$, this.filters$, this._dirty$]).pipe(map(([busy, text, filters, dirty]) => {
		if (busy) return false;
		if (!dirty) return false;
		if (text.length > 2) return true;

		//
		// These filter checks should match the filters on the SearchFilters interface
		//
		if (filters.awardee) return true;
		if (filters.contactAdmin) return true;
		if (filters.deceased) return true;
		if (filters.droppedOut) return true;
		if (filters.eforallAdmin) return true;
		if (filters.noContact) return true;
		if (filters.redFlag) return true;
		if (filters.techAdmin) return true;

		return false;
	}));


	/**
	 * Set the text that will be used when search() is called.
	 */
	setSearchText(text: string) {
		text = text.trim();
		this._text$.next(text);
		this._results$.next([]);
		this._dirty$.next(true);
	}


	/**
	 * Set the filter that will be used when search() is called.
	 */
	setSearchFilter(filters: SearchFilters) {
		this._filters$.next(filters);
		this._results$.next([]);
		this._dirty$.next(true);
	}


	/**
	 * Perform the search. The results will be available from results$
	 */
	async search(limitedToConcept?: ExplorableConceptName): Promise<SearchMatch[]> {

		this._searching$.next(true);
		await this.ds.util.setTimeout();

		const text = this._text$.value;
		const filters = this._filters$.value;

		const results = await this._engine.search(text, filters, limitedToConcept);

		this._dirty$.next(false);
		this._searching$.next(false);

		this._results$.next(results);
		return results;
	}


	/**
	 * The getAllPackagesAsArray() function is called for each of the explorable/searchable singletons.
	 * The functions cache the packages for fast lookup later. The caches stay intact until the next time
	 * an area function returns with updated singletons.
	 */
	preloadSearchableData() {
		//
		// Call async function without await.
		// It will communicate when done through the _preloading$ subject.
		//
		this._preloadSearchableData();
	}


	private async _preloadSearchableData(): Promise<void> {

		await this.ds.util.setTimeout();

		await this.setLoading('Loading People');
		await this.ds.admin.person.getAllPackagesAsArray();

		await this.setLoading('Loading Sites');
		await this.ds.admin.site.getAllPackagesAsArray();

		await this.setLoading('Loading Companies');
		await this.ds.admin.company.getAllPackagesAsArray();

		await this.setLoading('Loading Accelerators');
		await this.ds.admin.accelerator.getAllPackagesAsArray();

		await this.setLoading('Loading Pitch Contests');
		await this.ds.admin.pitchContest.getAllPackagesAsArray();

		await this.setLoading('Loading Venues');
		await this.ds.admin.venue.getAllPackagesAsArray();

		await this.setLoading('Loading Events');
		await this.ds.admin.event.getAllPackagesAsArray();

		await this.setLoading('Loading Applications');
		await this.ds.admin.application.getAllPackagesAsArray();

		await this.setLoading('Loading Acc Teams');
		await this.ds.admin.accTeam.getAllPackagesAsArray();

		await this.setLoading('Loading Pic Teams');
		await this.ds.admin.picTeam.getAllPackagesAsArray();

		await this.setLoading(undefined);
	}

	private async setLoading(msg: string) {
		await this.loading$.next(msg);
		await this.ds.util.setTimeout();
	}
}