import { AccStageId } from "@me-interfaces";

export const AccToolAccess = {
	AcceptTool: [AccStageId.Accepting],
	ReaderTool: [AccStageId.Reading],
	InterviewTool: [AccStageId.Interviewing],
	MentorMatchingTool: [
		AccStageId.Setup,
		AccStageId.Accepting,
		AccStageId.Reading,
		AccStageId.Interviewing,
		AccStageId.SelectingCohort,
		AccStageId.MatchingMentors,
	],
	FinalPresJudgeTool: [AccStageId.Curriculum],
};