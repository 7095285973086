import { AccAreaService } from '@ACC-area';
import { Component, Input, OnInit } from '@angular/core';
import { OldAccAreaService } from '@me-acc-area-old';
import { DestroyablePart } from '@me-access-parts';
import { AccStageId } from '@me-interfaces';


const validStage: AccStageId[] = [
	AccStageId.MatchingMentors,
	AccStageId.Curriculum,
	AccStageId.SelectingWinners,
	AccStageId.Quarterlies,
];


@Component({
	selector: 'acc-quarterly-controller',
	templateUrl: './SHR-PG_acc-quarterly-controller.part.html',
	styleUrls: ['SHR-PG_acc-quarterly-controller.part.scss'],
})
export class AccQuarterlyControllerPart extends DestroyablePart implements OnInit {
	@Input() areaService: OldAccAreaService | AccAreaService;
	enabledQuarters: [boolean, boolean, boolean, boolean] = [false, false, false, false];
	quarterNames = ['Curriculum (Q1)', 'Quarter 2', 'Quarter 3', 'Quarter 4'];
	accStageId: AccStageId;
	disabled = true;

	constructor(
	) { super(); }


	async ngOnInit() {

		super.initDestroyable();

		super.subscribe([this.areaService.accelerator.acc$], async ([acc]) => {
			if (!acc) return;
			this.accStageId = acc.accStageId;

			if (validStage.includes(this.accStageId)) {
				this.disabled = false;
				const enabledQuarterlies = acc.enabledQuarterlyGoals;

				//
				// Assign values to response
				//
				this.enabledQuarters = [
					(enabledQuarterlies & Math.pow(2, 0)) == Math.pow(2, 0),
					(enabledQuarterlies & Math.pow(2, 1)) == Math.pow(2, 1),
					(enabledQuarterlies & Math.pow(2, 2)) == Math.pow(2, 2),
					(enabledQuarterlies & Math.pow(2, 3)) == Math.pow(2, 3),
				];
			}
			else {
				this.disabled = true;
				this.enabledQuarters = [false, false, false, false];

			}
		});

	}


	async setQuarter(quarter: number, value: boolean) {
		this.enabledQuarters[quarter] = value;
		await this.areaService.accelerator.actions.setEnabledQuarterlyGoal({ enabledQuarters: this.enabledQuarters });
	}
}
