import { Icon } from "@me-shared-parts/UI-common";
import { IconBarIcon } from "@me-shared-parts/UI-common/icon-bar";

export const VIEW_DETAILS = 'details';
export const VIEW_PITCHCONTEST = 'pitchContest';
export const VIEW_MEMBERS = 'members';
export const VIEW_AWARDS = 'awards';
export const VIEW_RELATED_NOTES = 'related notes';

export const views: IconBarIcon[] = [
	{ key: VIEW_DETAILS, icon: Icon.action_more, tooltip: 'Team Details', selected: true },
	{ key: VIEW_MEMBERS, icon: Icon.view_members, tooltip: 'Members', selected: true },
	{ key: VIEW_RELATED_NOTES, icon: Icon.notes_some, tooltip: 'Related Notes', selected: true },
	{ key: VIEW_AWARDS, icon: Icon.concept_award, tooltip: 'Awards', selected: true },
	{ key: VIEW_PITCHCONTEST, icon: Icon.concept_pitchContest, tooltip: 'Pitch Contest', selected: true },
];