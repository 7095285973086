import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Component({
	selector: 'spinner-button',
	styleUrls: ['./SHR-CMN_spinner-button.part.scss'],
	templateUrl: './SHR-CMN_spinner-button.part.html'
})
export class SpinnerButtonPart {

	@Input() disabled = false;
	@Input() spinning = false;
	@Input() action = false;
	@Input() caution = false;
	@Input() green = false;
	@Output() safeClick = new EventEmitter();


	/**
	 * 
	 * @param e Couldn't find a way to disable the host click event, so wrapping it in an event that considers the disabled property
	 * 
	 */
	@HostListener('click', ['$event'])
	onClick(e) {
		if (!this.disabled) this.safeClick.next(e);
	}
}