import { Dba, DbConceptName, Immutable } from '@me-interfaces';


interface MutableDbaCourseSection extends Dba {

	readonly _concept: DbConceptName.CourseSection,

	courseSectionId: number,
	courseId: number,
	order: number,
	title: string,
	createdUTC: number,
	updatedUTC: number,
}


export interface DbaCourseSection extends Immutable<MutableDbaCourseSection> { }