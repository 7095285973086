import { DashboardCard, DbsPerson, DbcUser, SingletonName } from '@me-interfaces';

/**
 * When a user logs in, the call to func.public.user.start() returns this object which includes details about
 * the singleton cache files. If the user is not staff then this object will be gutted with no cache files.
 */
export interface SingletonsCacheConfig {

	/** The version of the cache files which should match the environment file. */
	version: number,
	
	/** The named singletons, each of which is in a file of the same name */
	singletonNames: SingletonName[],

	/** Base path to download each of the cache files */
	bucketPath: string,

	/** The date that each of the cache files was created as of */
	filesUTC: number,

	/** The secret passphrase used to decrypt the cache files */
	secretPassphrase: string | undefined,
}


export interface UserStart {
	user: DbcUser,
	cards: DashboardCard[],

	/**
	 * Person object for the current user to be added to the singletons.
	 * Normally a Dbc object should not be in the raw data. This is a sole
	 * exception because SingletonTimestamps do not include the current
	 * user's personId.
	 */
	// person: DbsPerson,


	/**
	 * Details about the currently available singletons cache file for the
	 * user, based on whether they have access to admin or not. If admin,
	 * it will also include a passphrase that can be used to decrypt the
	 * admin file.
	 */
	singletonsCacheConfig: SingletonsCacheConfig,
}