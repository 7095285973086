import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { DbdTopic, LanguageId } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';
import { IconContext, getIconContextForTopicMaterialType } from '@me-shared-parts/UI-common';


@Component({
	selector: 'topic-material-dialog',
	templateUrl: './material.dialog.html',
	styleUrls: ['./material.dialog.scss'],
})
export class TopicMaterialDialog extends DestroyablePart implements OnInit {
	//
	// The action buttons
	//

	cancelAction: DialogAction = {
		id: 'close',
		enabled: true,
		visible: true,
		label: 'Close',
		linkType: "no-action",
		willCloseDialog: true,
	};

	actions: DialogAction[] = [this.cancelAction];
	icon = 'fal fa-eye';
	header = 'Training Materials'
	data: {
		name: string,
		icon: IconContext,
		url: string,
	}[] = [];

	topic: DbdTopic;


	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ topicId: number, languageId: LanguageId }>,
		public dialogRef: MatDialogRef<TopicMaterialDialog>,
		private ds: DataService,
	) {
		super();
	}


	async ngOnInit() {
		super.initDestroyable();

		const { topicId, languageId } = this.dialog.data;
		this.topic = this.ds.domain.topic.getOne(topicId);

		this.header = `${this.topic.shortNameLabel} Training Materials`;

		const topicMaterials = this.ds.domain.topicMaterial.getAll().filter(topicMaterial => topicMaterial.topicId == topicId && topicMaterial.languageId == languageId);


		this.data = topicMaterials.sort((a, b) => a.displayOrder > b.displayOrder ? 1 : -1).map((topicMaterial, i) => {
			return {
				id: ++i,
				icon: getIconContextForTopicMaterialType(topicMaterial.topicMaterialTypeId, 'fa-regular', 'always'),
				name: topicMaterial.name,
				url: topicMaterial.url,
			}
		});


	}

}