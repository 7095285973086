<me-dialog-frame [header]='header' [actions]='actions' [dialogRef]='dialogRef' [icon]='icon' alertType='editor'>

	<div class='council-member-edit-dialog' *ngIf="newSelectedValue">
		<table style='width:100%' class='pro-table pro-table-no-header pro-table-with-labels pro-table-without-border'>
			<tr>
				<td>{{personName}}</td>
				<td> <button class='btn' (click)='explorePerson()'>
						<me-icon [icon]='personIcon'></me-icon>
					</button>
				</td>
			</tr>
			<tr>
				<td [style.width.px]='100'>Start Date</td>
				<td>
					<kendo-datepicker [value]="newSelectedValue.startDate"
						(valueChange)='dateChange("startDate", $event)'>
					</kendo-datepicker>
				</td>
			</tr>
			<tr>
				<td [style.width.px]='100'>End Date</td>
				<td>
					<div class='end-date-clear-btn'>
						<kendo-datepicker [value]="newSelectedValue.endDate"
							(valueChange)='dateChange("endDate", $event)'>
						</kendo-datepicker>
						<button class="btn" (click)="clear()" [disabled]="!newSelectedValue.endDate">Clear</button>
					</div>
				</td>
			</tr>


		</table>
	</div>
</me-dialog-frame>