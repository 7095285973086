import { AccReader, AppAreaIdentifier, LanguageId, ProgramTypeId } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { getAccs, getRegions, getSites } from "./data-getters";
import { getPics } from "./data-getters/get-pics";
import { DashboardData, DashboardDataReader, DashboardDataObjects } from "./interfaces";


/**
 * Gets all the ids that are used in the various dashboard grids.
 */
export async function getDashboardDataIds(
	ds: DataService,
	util: UtilityService,
	identifier: AppAreaIdentifier<number>,
	data: DashboardData,
): Promise<DashboardDataObjects> {

	//
	// Regions
	//
	const { regions, regionIds } = await getRegions(ds, identifier);
	data.overall.regionIds.push(...regionIds);



	//
	// Sites
	//
	const { sites, siteIds } = await getSites(ds, identifier);
	data.overall.siteIds.push(...siteIds);


	//
	// Site Programs
	//
	const sitePrograms = (await ds.admin.siteProgram.getAllPackagesAsArray()).filter(sp => siteIds.includes(sp.siteId));
	const siteProgramIds = sitePrograms.map(sp => sp.siteProgramId);

	for (const siteProgram of sitePrograms) {
		const program = siteProgram.program;
		if (program.programTypeId == ProgramTypeId.Accelerator && program.languageId == LanguageId.English) data.englishAccelerators = true;
		if (program.programTypeId == ProgramTypeId.Accelerator && program.languageId == LanguageId.Spanish) data.spanishAccelerators = true;
		if (program.programTypeId == ProgramTypeId.PitchContest && program.languageId == LanguageId.English) data.englishPitchContests = true;
		if (program.programTypeId == ProgramTypeId.PitchContest && program.languageId == LanguageId.Spanish) data.spanishPitchContests = true;
	}


	//
	// All Applications
	//
	const allApplications = await ds.admin.application.getAllPackagesAsArray();


	//
	// Accelerators
	//
	const { accs, accIds } = await getAccs(ds, siteProgramIds);
	data.accelerator.accIds.push(...accIds);

	const accApplications = allApplications.filter(a => accIds.includes(a.accId));
	data.accelerator.accApplicationIds = accApplications.map(a => a.applicationId);

	const accTeamsByAccId = await ds.admin.accTeam.getByAccIds(accIds);
	const accTeams = util.array.fromArrayMap(accTeamsByAccId, accIds);
	const accTeamIds = accTeams.map(t => t.accTeamId);
	data.accelerator.accTeamIds = accTeamIds;

	const accReadersByAccId = await ds.admin.accReader.getByAccIds(accIds);
	const accReaders = util.array.fromArrayMap(accReadersByAccId, accIds).filter(r => r.readApplicationIds.length > 0);
	data.accelerator.accReaders = util.array.groupBy<AccReader, DashboardDataReader>(accReaders, r => r.personId, (g, id) => { g.personId = id }, [
		{ method: 'CountUnique', getter: r => r.accId, setter: (g, count) => g.countOfLocalReaderRole = count },
		{ method: 'Sum', getter: r => r.readApplicationIds.length, setter: (g, sum) => g.countOfLocalReadings = sum }
	]);

	const accInterviewersByAccId = await ds.admin.accInterviewer.getByAccIds(accIds);
	const accInterviewers = util.array.fromArrayMap(accInterviewersByAccId, accIds);
	const accInterviewerIds = accInterviewers.map(i => i.accInterviewerId);
	data.accelerator.accInterviewerIds = accInterviewerIds;

	const accJudgesByAccId = await ds.admin.accJudge.getByAccIds(accIds);
	const accJudges = util.array.fromArrayMap(accJudgesByAccId, accIds);
	const accJudgeIds = accJudges.map(j => j.accJudgeId);
	data.accelerator.accJudgeIds = accJudgeIds;

	const accTeamMembersByAccTeamId = await ds.admin.accTeamMember.getArraysByForeignIds('accTeamId', accTeamIds);
	const accTeamMembers = util.array.fromArrayMap(accTeamMembersByAccTeamId, accTeamIds);
	const accEntrepreneurPersonIds = accTeamMembers.filter(t => t.role === 'E').map(t => t.personId);
	data.accelerator.accEntrepreneurPersonIds = accEntrepreneurPersonIds;

	const accMentorPersonIds = accTeamMembers.filter(t => t.role !== 'E').map(t => t.personId);
	data.accelerator.accMentorPersonIds = accMentorPersonIds;


	//
	// Pitch Contests
	//
	const { pics, picIds } = await getPics(ds, siteProgramIds);
	data.pitchContest.picIds.push(...picIds);

	const picApplications = allApplications.filter(p => picIds.includes(p.picId));
	data.pitchContest.picApplicationIds = picApplications.map(p => p.applicationId);

	const picTeamsByPicId = await ds.admin.picTeam.getByPicIds(picIds);
	const picTeams = util.array.fromArrayMap(picTeamsByPicId, picIds);
	const picTeamIds = picTeams.map(t => t.picTeamId);
	data.pitchContest.picTeamIds = picTeamIds;

	const picReadersByPicId = await ds.admin.picReader.getByPicIds(picIds);
	const picReaders = util.array.fromArrayMap(picReadersByPicId, picIds);
	const picReaderIds = picReaders.map(r => r.picReaderId);
	data.pitchContest.picReaderIds = picReaderIds;

	const picJudgesByPicId = await ds.admin.picJudge.getByPicIds(picIds);
	const picJudges = util.array.fromArrayMap(picJudgesByPicId, picIds);
	const picJudgeIds = picJudges.map(j => j.picJudgeId);
	data.pitchContest.picJudgeIds = picJudgeIds;

	const picTeamMembersByPicTeamId = await ds.admin.picTeamMember.getArraysByForeignIds('picTeamId', picTeamIds);
	const picTeamMembers = util.array.fromArrayMap(picTeamMembersByPicTeamId, picTeamIds);
	const picEntrepreneurPersonIds = picTeamMembers.map(t => t.personId);
	data.pitchContest.picEntrepreneurPersonIds = picEntrepreneurPersonIds;


	//
	// Companies
	//
	data.overall.companyIds = util.array.cleanNumericIds([
		...accApplications.map(a => a.companyId),
		...picApplications.map(p => p.companyId),
	], false);


	//
	// Application Participants
	//
	const applications = [...accApplications, ...picApplications];
	const applicationIds = applications.map(a => a.applicationId);
	const applicationParticipantsByApplicationId = await ds.admin.applicationParticipant.getArraysByForeignIds('applicationId', applicationIds);
	const applicationParticipants = util.array.fromArrayMap(applicationParticipantsByApplicationId, applicationIds);
	const applicationParticipantPersonIds = applicationParticipants.map(p => p.personId);


	//
	// Entrepreneurs (acc and pic, application participants and team members)
	//
	data.overall.entrepreneurPersonIds = util.array.cleanNumericIds([
		...applicationParticipantPersonIds,
		...accEntrepreneurPersonIds,
		...picEntrepreneurPersonIds,
	], false);


	//
	// Awards
	//
	const allAwards = await ds.admin.award.getAllPackagesAsArray();
	const awards = allAwards.filter(a => accTeamIds.includes(a.accTeamId) || picTeamIds.includes(a.picTeamId));
	const awardIds = awards.map(a => a.awardId);
	data.overall.awardIds = awardIds;


	//
	// Events
	//
	const allEvents = await ds.admin.event.getAllPackagesAsArray();
	const events = allEvents.filter(a => accIds.includes(a.accId) || picIds.includes(a.picId));
	const eventIds = events.map(e => e.eventId);
	data.overall.eventIds = eventIds;


	return { accs, data, pics };
}