<div class='acc-matching-tool-view-part'>

	<ng-container *ngIf="state$ | async; let state">

		<div class="no-teams" *ngIf="!state.hasTeams">
			Teams have not yet been selected. All of the Teams must be selected into the Cohort before matching mentors.
		</div>

		<ng-container *ngIf="state.hasTeams">

			<div class="has-mentors" *ngIf="state.hasMentors">
				Note: This list of teams shows the <i>current</i> assignment of mentors,
				not necessarily those that were matched with a prior use of the mentor matching tool.
				It is possible to manually change mentors on the team page.
				You may also <i>replace</i> all the existing mentors by pressing the Rematch button.
			</div>

			<button-container *ngIf="!state.hasMentors">
				<button class="btn" *ngIf="hintsLabel$ | async; let hintsLabel" (click)="openHints()">
					{{ hintsLabel }}
				</button>
				<button class="btn" (click)="match()">Match</button>
			</button-container>

			<acc-matching-teams-part [teams]="state.teams"></acc-matching-teams-part>

			<button-container *ngIf="state.hasMentors">
				<button class="btn" *ngIf="hintsLabel$ | async; let hintsLabel" (click)="openHints()">
					{{ hintsLabel }}
				</button>
				<button class="btn" (click)="match()">Rematch</button>
			</button-container>

		</ng-container>

	</ng-container>

</div>