<app-area-access [areaService]='accAreaService' accessKey="assessments" [showReadonlyMessage]="true">

	<div class='acc-mm-editor-view-part'>
		<div class="selector-row">
			<div>
				<div>Assesser (giving)</div>
				<kendo-dropdownlist [data]="assessers$ | async" [value]="assesserToEdit"
					[defaultItem]="defaultEditorData" textField="name" valueField="id"
					(valueChange)="handleAssesserToEdit($event)" [disabled]='readonly'>
					<ng-template kendoDropDownListValueTemplate let-dataItem>
						<captioned-layout *ngIf="dataItem && dataItem.captionedLayout"
							[layout]='dataItem.captionedLayout'>
						</captioned-layout>
					</ng-template>
					<ng-template kendoDropDownListItemTemplate let-dataItem>
						<captioned-layout *ngIf="dataItem && dataItem.captionedLayout"
							[layout]='dataItem.captionedLayout'>
						</captioned-layout>
					</ng-template>
				</kendo-dropdownlist>
			</div>

			<div>
				<div>Assessee (receiving)</div>
				<kendo-dropdownlist [disabled]="readonly || !assesserToEdit.type"
					[defaultItem]="defaultEditorData" [data]="assessees" [value]="assesseeToEdit" textField="name"
					valueField="id" (valueChange)="handleAssesseeToEdit($event)">
					<ng-template kendoDropDownListValueTemplate let-dataItem>
						<captioned-layout *ngIf="dataItem && dataItem.captionedLayout"
							[layout]='dataItem.captionedLayout'>
						</captioned-layout>
					</ng-template>
					<ng-template kendoDropDownListItemTemplate let-dataItem>
						<captioned-layout *ngIf="dataItem && dataItem.captionedLayout"
							[layout]='dataItem.captionedLayout'>
						</captioned-layout>
					</ng-template>
				</kendo-dropdownlist>
			</div>

			<div>
				<button class="btn" [disabled]='readonly || !assesserToEdit.type || !assesseeToEdit.type'
					(click)='load(assesserToEdit,assesseeToEdit)'>Add / Edit</button>
			</div>
		</div>
		<button-container *ngIf='showAssessment && !readonly'>
			<button *ngFor="let i of ['1','2','3','4']" [class]='getAssessmentClass(i, assessment)'
				(click)='setAssessment(assesserToEdit,assesseeToEdit, i);'>
				{{assessmentText(i)}}
				<span class='selected-arrow'></span>
			</button>
		</button-container>
	</div>
</app-area-access>