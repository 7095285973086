<div class='titled-person-grid-large' [class.no-padding]='smallGrid' [class.hide-header]='!showHeader'>
	<mat-table #table [dataSource]="rows">
		<ng-container matColumnDef="name">
			<mat-header-cell *matHeaderCellDef>Name
			</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<ng-container *ngIf='!displaySmallGrid && !smallGrid'>
					<me-avatar [avatar]='row.person.person' size='40px'></me-avatar>
					<span class='ml-1'>{{row.person.text}}</span>
				</ng-container>
				<ng-container *ngIf='displaySmallGrid || smallGrid'>
					<captioned-layout [layout]='row.person'></captioned-layout>
				</ng-container>
			</mat-cell>
		</ng-container>

		<ng-container matColumnDef="title">
			<mat-header-cell *matHeaderCellDef>
				Title
			</mat-header-cell>
			<mat-cell *matCellDef="let row">{{row.person.caption}}</mat-cell>
		</ng-container>

		<ng-container matColumnDef="roles">
			<mat-header-cell *matHeaderCellDef>
				Roles
			</mat-header-cell>
			<mat-cell *matCellDef="let row">
				<person-badge *ngFor='let badge of row.badges' [badge]='badge'></person-badge>
			</mat-cell>
		</ng-container>

		<mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
		<mat-row class='person-row' *matRowDef="let row; columns: displayedColumns;"
			(click)="navigateToPosition(row.routerLink)">
		</mat-row>
	</mat-table>

</div>