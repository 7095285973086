import { DbConceptName, PicTeam } from "@me-interfaces";
import { LinkOrExplorable } from "../../link-or-explorerable";

export function getButtons(picTeam: PicTeam): LinkOrExplorable[] {

	const buttons: LinkOrExplorable[] = [];

	//
	// Company
	//

	buttons.push({ explorer: { nameOverride: picTeam.name, explorableId: { conceptName: DbConceptName.Company, id: picTeam.application.companyId }, navTooltip: `Company: ${picTeam.name}` } });


	//
	// Application
	//
	buttons.push({ explorer: { nameOverride: 'Application', explorableId: { conceptName: DbConceptName.Application, id: picTeam.applicationId }, navTooltip: `Application` } });


	return buttons;
}