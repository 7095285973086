
import { DdPhoneTypeRow } from './dd-phone-type-row';
import { DdPhoneType } from './dd-phone-type';
import { DdObjects } from '../dd-objects';
import { OldDomainDataService } from '..';

/**
 * @deprecated Use the DataService instead
 */
export class DdPhoneTypes extends DdObjects<DdPhoneTypeRow, DdPhoneType> {

	constructor(private dd: OldDomainDataService) {
		super('phoneTypeId');
	}

	async loadData(hash: string, data: DdPhoneTypeRow[]) {
		const objects = data.map(d => new DdPhoneType(this.dd, d));
		this.loadObjects(hash, objects);
	}
}