<div class='event-dialog-editor-edit-part'>
	<message-line *ngIf="event.eventTypeId === sessionEventTypeId" [closeable]="false">
		Please reach out to the program team before making any changes to the event.
	</message-line>

	<table *ngIf='dates' style='width:100%'
		class='pro-table pro-table-no-header pro-table-with-labels pro-table-without-border'>

		<tr *ngFor='let row of eventContextRows'>
			<td>{{ row.label }} </td>
			<td>
				{{ row.value }}
				<small *ngIf='row.site' class='inline-row-text' style='top:0;'>{{ row.site }}</small>
			</td>
		</tr>

		<tr>
			<td [ngClass]='{"pro-table-label-error": missing.languageId}'>Language</td>
			<td>
				<kendo-dropdownlist style='max-width:250px; margin-right:3em;' [data]='languages' textField='name'
					valueField='languageId' [value]='event?.languageId' [valuePrimitive]='true'
					(valueChange)='languageChange($event)' [disabled]='event.siteProgramId'>
				</kendo-dropdownlist>
			</td>
		</tr>

		<tr>
			<td [ngClass]='{"pro-table-label-error": missing.eventDates}'>Event Time</td>
			<td>
				<kendo-datetimepicker [format]="dateFormat" [value]="dates.eventDates.start"
					(valueChange)='dateChange(dates.eventDates, "start", $event)'>
				</kendo-datetimepicker>
				<i class="inline-row-text fas fa-long-arrow-right"></i>
				<kendo-datetimepicker [format]="dateFormat" [value]="dates.eventDates.end"
					(valueChange)='dateChange(dates.eventDates, "end", $event)'></kendo-datetimepicker>
				<small class='inline-row-text'
					[class.invalid-duration]='dates.eventDates.duration === INVALID_EVENT_DURATION'>
					{{ dates.eventDates.duration }}
				</small>
			</td>
		</tr>

		<!------------- Tool start and end dates/times  --------------->

		<tr *ngIf='toolIconMessage'>
			<td [ngClass]='{"pro-table-label-error": missing.toolDates}' kendoTooltip>
				<i class='far fa-question-circle tool-info-icon' [title]='toolIconMessage'
					(click)='toolIconClick()'></i>
				Tool Enabled
			</td>
			<td>
				<kendo-dropdownlist style='max-width:250px;' [value]="toolDatesOption"
					(valueChange)='toolDatesOptionChange($event)' [textField]="'text'" [valueField]="'value'"
					[data]='toolDatesOptions'>
				</kendo-dropdownlist>
				<small class='inline-row-text' *ngIf='toolDatesOption?.value==="default"'
					[class.invalid-duration]='dates.toolDates.duration === INVALID_EVENT_DURATION'>{{
					dates.toolDates.duration
					}}</small>
				<br>
				<div *ngIf='toolDatesOption?.value==="custom"' style='margin-top:5px;'>
					<kendo-datetimepicker [format]="dateFormat" [value]="dates.toolDates.start"
						(valueChange)='dateChange(dates.toolDates, "start", $event)'>
					</kendo-datetimepicker>
					<i class="inline-row-text fas fa-long-arrow-right"></i>
					<kendo-datetimepicker [format]="dateFormat" [value]="dates.toolDates.end"
						(valueChange)='dateChange(dates.toolDates, "end", $event)'>
					</kendo-datetimepicker>
					<small class='inline-row-text'>{{ dates.toolDates.duration }}</small>
				</div>
			</td>
		</tr>


		<!------------- Host  --------------->

		<tr>
			<td>Host</td>
			<td>
				<kendo-dropdownlist style='max-width:250px; margin-right:3em;' [data]='hosts' textField='_name'
					valueField='personId' [defaultItem]="defaultHost" [value]='host' (valueChange)='hostChange($event)'>
				</kendo-dropdownlist>
				<person-extract-link *ngIf='host' [person]='host'></person-extract-link>
			</td>
		</tr>


		<!------------- Location  --------------->

		<tr>
			<td [ngClass]='{"pro-table-label-error": missing.venue}'>Location</td>
			<td>
				<kendo-dropdownlist style='max-width:110px; margin-right:3em;' [value]="meetingTypeOption"
					(valueChange)='meetingTypeOptionChange($event)' [textField]="'text'" [valueField]="'value'"
					[data]='meetingTypeOptions'>
				</kendo-dropdownlist>
				<venue-button *ngIf='meetingTypeOption?.value==="in-person"' [venue]="venue"
					(selection)='onVenueSelection($event)'>
				</venue-button>
			</td>
		</tr>


		<!------------- Link and Notes  --------------->

		<tr>
			<td [ngClass]='{"pro-table-label-error": missing.link}'>Link</td>
			<td>
				<kendo-textbox [value]='link' style='width:100%' placeholder='https://domain.com' [clearButton]='true'
					(valueChange)='linkChange($event)' (blur)='linkBlur()'>
				</kendo-textbox>
			</td>
		</tr>

		<tr>
			<td>Public Notes</td>
			<td>
				<textarea kendoTextArea [value]='publicNotes' style="resize:none;" [placeholder]="notesPlaceholder"
					(valueChange)='notesChange($event)' (blur)='notesBlur()'></textarea>
			</td>
		</tr>

	</table>

</div>