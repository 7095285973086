<me-dialog-frame [header]='headerText' [actions]='actions' [dialogRef]='dialogRef' [icon]='icon' alertType='editor'>

	<div class='acc-dropped-out-week-editor-dialog'>
		<div>
			<span>Dropped Out:</span>
			<span style="margin-left: 0.5em;">
				<kendo-dropdownlist style='width:60%' [data]='dataOptions' textField='text' valueField='value'
					[value]='orgDroppedOutWeek' [valuePrimitive]='true' (valueChange)='selectionChange($event)'>
				</kendo-dropdownlist>
			</span>
		</div>
	</div>
</me-dialog-frame>