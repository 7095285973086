/**
 * The enumerated set of email templates stored in the sendEmailTemplate table
 */
export const enum SendEmailTemplateId {
	InviteParticipant = 1,
	NudgeParticipant = 2,
	NudgeApplicant = 3,
	ApplicationSubmitted = 4,
	ApplicationWithdrawn = 5,
	UpcomingSession = 6,
	SessionSurveyEmail = 7,
	SessionSurveyNudge = 8,
}

export interface SendEmailTemplateId_InviteParticipant {
	templateId: SendEmailTemplateId.InviteParticipant,
	participant_first_name: string,
	applicant_full_name: string,
	program_name: string,
	invite_url: string,
	code: string,
	pm_full_name: string,
	pm_email_address: string,
}

export interface SendEmailTemplateId_NudgeParticipant {
	templateId: SendEmailTemplateId.NudgeParticipant,
	participant_first_name: string,
	program_name: string,
	participant_url: string,
	pm_full_name: string,
	pm_email_address: string,
}

export interface SendEmailTemplateId_NudgeApplicant {
	templateId: SendEmailTemplateId.NudgeApplicant,
	applicant_first_name: string,
	program_name: string,
	application_url: string,
	pm_full_name: string,
	pm_email_address: string,
}

export interface SendEmailTemplateId_ApplicationSubmitted {
	templateId: SendEmailTemplateId.ApplicationSubmitted,
	applicant_first_name: string,
	program_name: string,
	pm_full_name: string,
	pm_email_address: string,
	application_url: string,
}

export interface SendEmailTemplateId_ApplicationWithdrawn {
	templateId: SendEmailTemplateId.ApplicationWithdrawn,
	applicant_first_name: string,
	program_name: string,
	pm_full_name: string,
	pm_email_address: string,
	application_url: string,
}

export interface SendEmailTemplateId_UpcomingSession {
	templateId: SendEmailTemplateId.UpcomingSession
	start_date: string,
	start_date_and_time: string,
	link_url: string,
	first_name: string,
}

export interface SendEmailTemplateId_SessionSurveyEmail {
	templateId: SendEmailTemplateId.SessionSurveyEmail
	entrepreneur_full_name: string,
	session_name: string,
	link_url: string,
	session_goals: string,
	pm_full_name: string,
	pm_email_address: string,
}

export interface SendEmailTemplateId_SessionSurveyNudge {
	templateId: SendEmailTemplateId.SessionSurveyNudge,
	entrepreneur_full_name: string,
	session_name: string,
	link_url: string,
	pm_full_name: string,
	pm_email_address: string,
}


/**
 * A union of the typed fields of each template
 */
export type SendEmailTemplateId_Template =
	SendEmailTemplateId_InviteParticipant |
	SendEmailTemplateId_NudgeParticipant |
	SendEmailTemplateId_NudgeApplicant |
	SendEmailTemplateId_ApplicationSubmitted |
	SendEmailTemplateId_ApplicationWithdrawn |
	SendEmailTemplateId_UpcomingSession |
	SendEmailTemplateId_SessionSurveyEmail |
	SendEmailTemplateId_SessionSurveyNudge;
	