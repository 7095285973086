export interface PositionDetails {
	companyId: number,
	title: string,
	isAdmin: boolean,
	isFounder: boolean,
	phone: {
		number?: string,
		extension?: string,
	}
}
export interface PositionDetailsNew {
	companyId: number,
	title: string,
	isAdmin: boolean,
	isFounder: boolean,
	phoneTypeId?: number,
	phone?: string,
	extension?: string,
}