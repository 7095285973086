import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { Accelerator } from '@me-interfaces';
import { UtilityService } from '@me-services/core/utility';
import { UrlService } from '@me-services/ui/url';
import { getIconContext, Icon } from '@me-shared-parts/UI-common';
import { getIconBarSelections, IconBarIcon } from '@me-shared-parts/UI-common/icon-bar';
import { IconBarPart, IconBarSelections } from '@me-shared-parts/UI-common/icon-bar/SHR-CMN_icon-bar.part';
import { DataService } from '../../../data.service';
import { ExplorerBox } from '../../explorer-box/explorer-box';
import { LinkOrExplorable } from '../../link-or-explorerable';
import { getBoxesForApplications } from './boxes/get-boxes-for-applications';
import { getBoxesForAwards } from './boxes/get-boxes-for-awards';
import { getBoxesForCohort } from './boxes/get-boxes-for-cohort';
import { getBoxesForEvents } from './boxes/get-boxes-for-events';
import { getBoxesForMentors } from './boxes/get-boxes-for-mentors';
import { getButtons } from './get-buttons';
import { views, VIEW_APPLICATIONS, VIEW_AWARDS, VIEW_COHORT, VIEW_DETAILS, VIEW_EVENTS, VIEW_MENTORS } from './views';

const STORAGE_KEY = 'AcceleratorExplorerIconSelection';

@Component({
	selector: 'accelerator-explorer',
	templateUrl: './accelerator-explorer.part.html',
	styleUrls: ['./accelerator-explorer.part.scss'],
})
export class AcceleratorExplorerPart implements OnChanges {

	@Input() public accelerator: Accelerator;
	@ViewChild('iconBar') iconBar: IconBarPart;

	public views = views;
	public icons = {
		acc: getIconContext(Icon.concept_accelerator),
	};


	public selectedViews: IconBarSelections = undefined;
	public buttons: LinkOrExplorable[] = [];

	public cohortBoxes: ExplorerBox[] = [];
	public applicationBoxes: ExplorerBox[] = [];
	public eventBoxes: ExplorerBox[] = [];
	public awardBoxes: ExplorerBox[] = [];
	public mentorBoxes: ExplorerBox[] = [];

	constructor(
		public util: UtilityService,
		private ds: DataService,
		private urlService: UrlService
	) { }


	async ngOnChanges() {
		if (!this.accelerator) throw new Error(`Missing required attribute: accelerator`);
		this.icons.acc.text = this.accelerator.explorerName;
		await this.createContent();
		this.views = this.updateIconBar();
	}

	/**
	 * CreateContent is called once when a new accelerator is passed in.  All content is created but
	 * may be shown or hidden using css instead of rebuilding everything again.
	 */
	private async createContent() {

		this.buttons = getButtons(this.accelerator);
		this.cohortBoxes = await getBoxesForCohort(this.ds, this.accelerator);
		this.applicationBoxes = await getBoxesForApplications(this.ds, this.accelerator);
		this.eventBoxes = await getBoxesForEvents(this.util, this.ds, this.urlService, this.accelerator);
		this.awardBoxes = await getBoxesForAwards(this.ds, this.accelerator);
		this.mentorBoxes = await getBoxesForMentors(this.ds, this.accelerator);
	}

	private updateIconBar(): IconBarIcon[] {

		//
		// Get selections from last time this explorer was accessed. If there
		// isn't on,then build the selections from the initial views in views.ts.
		//
		const selectedViewsStr = sessionStorage.getItem(STORAGE_KEY);
		let selectedViews: IconBarSelections;

		if (selectedViewsStr) {
			this.selectedViews = selectedViews = JSON.parse(selectedViewsStr);
		}
		else {
			this.selectedViews = selectedViews = getIconBarSelections(views);
			sessionStorage.setItem(STORAGE_KEY, JSON.stringify(this.selectedViews));
		}

		//
		// Create a new array of icon views using the initial set but overriding
		// whether disabled or not and the tooltip text based on the created content.
		//
		return views.map(view => {
			if (view.key == VIEW_DETAILS) {
				view.disabled = false; // always has a value
				view.selected = this.selectedViews.map[VIEW_DETAILS];
				view.tooltip = `Accelerator Details`;
			}
			else if (view.key == VIEW_APPLICATIONS) {
				const count = this.applicationBoxes.length;
				view.disabled = !count;
				view.selected = this.selectedViews.map[VIEW_APPLICATIONS];
				view.tooltip = this.util.text.singularOrPluralWithCount(count, 'Application', 'Applications');
			}
			else if (view.key == VIEW_AWARDS) {
				const count = this.awardBoxes.length; // + this.pitchContestBoxes.length
				view.disabled = !count;
				view.selected = this.selectedViews.map[VIEW_AWARDS];
				view.tooltip = this.util.text.singularOrPluralWithCount(count, 'Award', 'Awards');
			}
			else if (view.key == VIEW_COHORT) {
				const count = this.cohortBoxes.length;
				view.disabled = !count;
				view.selected = this.selectedViews.map[VIEW_COHORT];
				view.tooltip = this.util.text.singularOrPluralWithCount(count, 'Cohort Team', 'Cohort Teams');
			}
			else if (view.key == VIEW_EVENTS) {
				const count = this.eventBoxes.length;
				view.disabled = !count;
				view.selected = this.selectedViews.map[VIEW_EVENTS];
				view.tooltip = this.util.text.singularOrPluralWithCount(count, 'Event', 'Events');
			}
			else if (view.key == VIEW_MENTORS) {
				const count = this.mentorBoxes.length;
				view.disabled = !count;
				view.selected = this.selectedViews.map[VIEW_MENTORS];
				view.tooltip = this.util.text.singularOrPluralWithCount(count, 'Mentor', 'Mentors');
			}

			return view;
		});


	}


	public async setViews(selectedViews: IconBarSelections) {
		if (!selectedViews) return;
		this.selectedViews = selectedViews;
		sessionStorage.setItem(STORAGE_KEY, JSON.stringify(selectedViews));

		for (const view of this.views) {
			view.selected = !!selectedViews.map[view.key];
		}
	}


	public getDisplay(key: string) {
		const view = this.views.find(view => view.key == key);
		if (view == undefined) return 'none';
		return !!view.disabled || !view.selected ? 'none' : 'block';
	}


	public tabClose(header: string) {

		if (header.endsWith('Details')) this.unselect(VIEW_DETAILS);
		if (header.endsWith('Team') || header.endsWith('Teams')) this.unselect(VIEW_COHORT);
		if (header.endsWith('Mentor') || header.endsWith('Mentors')) this.unselect(VIEW_MENTORS);
		if (header.endsWith('Application') || header.endsWith('Applications')) this.unselect(VIEW_APPLICATIONS);
		if (header.endsWith('Event') || header.endsWith('Events')) this.unselect(VIEW_EVENTS);
		if (header.endsWith('Award') || header.endsWith('Awards')) this.unselect(VIEW_AWARDS);

	}


	private unselect(key: string) {

		this.iconBar.unselect(key);

		const view = this.views.find(view => view.key == key);
		if (view) view.selected = false;
	}

}