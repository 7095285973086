<div class='whats-new-page'>
	<div *ngFor='let release of releases'>
		<h3>{{release.name}} - <small>{{release.date}}
				<a *ngIf='release.jiraLink' [href]='release.jiraLink' target="_blank"><i class="fab fa-jira"></i>
					Jira</a>
				<a *ngIf='release.videoLink' [href]='release.videoLink' target="_blank"><i class="fa fa-video"></i> Watch
					Video</a>
			</small>
		</h3>

		<ul *ngIf='release.changes.length' class="fa-ul">
			<li *ngFor='let change of release.changes'>
				<span class="fa-li"><i [class]='getIcon(change.type)'></i></span>
				{{change.description}}
				<a *ngIf='change.jiraLink' [href]='change.jiraLink' target="_blank"><i class="fab fa-jira"></i>
					Jira</a>
				<a *ngIf='change.videoLink' [href]='change.videoLink' target="_blank"><i class="fa fa-video"></i> Watch
					Video</a>
				<a *ngIf='change.routerLink' [routerLink]='change.routerLink'><i class="fa fa-mobile-alt"></i> Open
					in App</a>
			</li>
		</ul>
	</div>
</div>