<mat-expansion-panel *ngFor='let answer of answers; let i = index' [expanded]="stepper.step === (stepper.baseSteps + i)"
 (opened)="stepper.set(stepper.baseSteps + i)">
	<mat-expansion-panel-header>
		<mat-panel-title [class.noanswer]='!hasAnswer(answer.answer)'>
			<i *ngIf='checkboxes && hasAnswer(answer.answer)' class='fas fa-check-square'></i>
			<i *ngIf='checkboxes && !hasAnswer(answer.answer)' class='far fa-square'></i>
			{{ answer.question.internalLabel }}
		</mat-panel-title>
		<span class='internal-question-container'>
			<i *ngIf='answer.question.isInternal' class='far fa-lightbulb' title='Internal Question'></i>
		</span>
	</mat-expansion-panel-header>

	<readonly-question [answer]='answer'></readonly-question>

	<mat-action-row>
		<button *ngIf='stepper.step > 0' (click)="stepper.prev()"><me-label key='Previous'></me-label></button>
		<button *ngIf='stepper.step < stepper.steps - 1' (click)="stepper.next()"><me-label key='Next'></me-label></button>
		<button *ngIf='stepper.step === stepper.steps - 1' (click)="stepper.set(-1)"><me-label key='Done'></me-label></button>
	</mat-action-row>

</mat-expansion-panel>