<div class='performance-indicators-part' [style.max-height.px]="height$ | async" *ngIf="indicators$ | async; let indicators">

	<div *ngIf="indicators.length===0">NO INDICATORS!</div>

	<div class="performance-indicators-section" *ngFor="let kpiSet of indicators">

		<div class="performance-indicators-header">{{ kpiSet.header }}</div>

		<div class="performance-indicators">

			<button *ngFor="let kpi of kpiSet.indicators" class='performance-indicator' (click)="onClick(kpi)"
				kendoTooltip [title]="kpi.tooltip" [showAfter]="700" [offset]="20">
				<div class="performance-indicator-value">
					<div [class.performance-indicator-small-text]="kpi.smallText">{{ kpi.value }}</div>
				</div>
				<div class="performance-indicator-desc">
					<div>
						<div *ngFor="let desc of kpi.desc">{{ desc }}</div>
					</div>
				</div>
			</button>
		</div>
	</div>
</div>