<field-chrome [basePart]='basePart' (fieldClick)='textField.focus()'>

	<div class='input-wrapper'>

		<input #textField kendoTextBox [value]='currentValue' (focus)='setFocused(true)' (blur)='setFocused(false)'
			(input)='onInput($event)' [placeholder]='text.placeholder' [attr.maxlength]='field.maxLength' [attr.minLength]='field.minLength'
			[disabled]='status === "saving"' [class.has-error]='!!errorText' [style.max-width]="maxWidth + 'em'" />

	</div>

</field-chrome>