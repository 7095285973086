import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ACC_APPLICATION_STATUS, ApplicationStatusId } from '@me-interfaces';
import { DroplistItem, getIconContext, Icon, IconContext, IconHideTextAt } from '@me-shared-parts/UI-common';

@Component({
	selector: 'acc-application-status-selector',
	templateUrl: './SHR-PG_acc-application-status-selector.part.html',
	styleUrls: ['SHR-PG_acc-application-status-selector.part.scss'],
})
export class AccApplicationStatusSelectorPart implements OnChanges {

	@Input() applicationId: number;
	@Input() applicationStatusId: ApplicationStatusId;
	@Input() accTeamId: number;
	@Input() siteId: string;
	@Input() accId: number;
	@Input() readonly: boolean;
	@Input() hideText?: IconHideTextAt = 'never';
	@Output() statusChanged: EventEmitter<ApplicationStatusId> = new EventEmitter();

	statuses: DroplistItem[] = [
	];

	droplistSelectedItem: DroplistItem;

	accTeamIcon: IconContext = getIconContext(Icon.concept_accTeam, undefined, 'never', 'Open Team');

	constructor(
		private router: Router,
	) { }

	ngOnChanges() {
		if (!this.applicationId) throw new Error('Missing required attribute: applicationId');
		if (!this.applicationStatusId) throw new Error('Missing required attribute: applicationStatusId');
		if (!this.siteId) throw new Error('Missing required attribute: siteId');
		if (!this.accId) throw new Error('Missing required attribute: accId');
		if (this.readonly == undefined) throw new Error('Missing required attribute: readonly');

		this.droplistSelectedItem = this.statuses.find(status => status.uniqueId == this.applicationStatusId);
		this.accTeamIcon = getIconContext(Icon.concept_accTeam, undefined, this.hideText || 'never', 'Open Team');
	}

	public async valueChange(e: DroplistItem) {
		if (this.readonly) return;
		const selectedItem = e;
		this.statusChanged.emit(selectedItem.uniqueId);
	}

	openTeam() {
		if (this.accTeamId) this.router.navigate([`access/admin/national/sites/${this.siteId}/accelerators/${this.accId}/cohort/${this.accTeamId}`]);
		return;
	}
}
