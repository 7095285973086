import { Accelerator, DbConceptName } from "@me-interfaces";
import { DataService } from "../../../../data.service";
import { ExplorerBox, ExplorerBoxClass } from "../../../explorer-box/explorer-box";


export async function getBoxesForCohort(ds: DataService, accelerator: Accelerator): Promise<ExplorerBox[]> {

	const boxes: ExplorerBox[] = [];

	const accTeamsByAccId = await ds.admin.accTeam.getByAccIds([accelerator.accId]);
	const accTeams = accTeamsByAccId[accelerator.accId];

	for (const accTeam of accTeams) {
		const members = accTeam.members.filter(m => m.member.role == 'E').map(member => `${member.person.firstName} ${member.person.lastName.slice(0, 1)}.`);
		const memberText = members.sort((a, b) => a > b ? 1 : -1).join(', ');
		const application = accTeam.application;
		const notes = [];
		if (accTeam.droppedOutWeek) notes.push(`Dropped Out Week: ${accTeam.droppedOutWeek}`);
		if (accTeam.awardedValue > 0) notes.push(`Awarded: $${accTeam.awardedValue}`);

		const box: ExplorerBox = {
			explorerBoxClass: ExplorerBoxClass.Cohort,
			header: 'Team',
			buttons: [
				{ explorer: { nameOverride: `${memberText} & Team`, explorableId: { conceptName: DbConceptName.AccTeam, id: accTeam.accTeamId }, navTooltip: `Team: ${memberText} & Team` } },
				{ explorer: { nameOverride: accTeam.name, explorableId: { conceptName: DbConceptName.Company, id: application.companyId }, navTooltip: `Company: ${accTeam.name}` } },
				{ explorer: { nameOverride: 'Application', explorableId: { conceptName: DbConceptName.Application, id: application.applicationId }, navTooltip: `Application` } },
			],
			notes: notes.join(', '),
		};

		boxes.push(box);
	}

	boxes.sort((a, b) => a.buttons[0].explorer.nameOverride.toLowerCase() > b.buttons[0].explorer.nameOverride.toLowerCase() ? 1 : -1);
	return boxes;
}