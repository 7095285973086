/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
export const enum ApplicationStatusId {

	AcceptPending = 1,
	AcceptAbandoned = 2,
	AcceptWithdrawn = 3,
	AcceptRejected = 4,
	AcceptDeferred = 5,
	AcceptCopiedForward = 6,
	AcceptSubmitted = 7,

	ReadPending = 7,
	ReadAbandoned = 8,
	ReadWithdrawn = 9,
	ReadRejected = 10,
	ReadDeferred = 11,
	ReadCopiedForward = 12,
	ReadAccepted = 13,

	InterviewPending = 13,
	InterviewAbandoned = 14,
	InterviewWithdrawn = 15,
	InterviewRejected = 16,
	InterviewDeferred = 17,
	InterviewCopiedForward = 18,
	InterviewAccepted = 19,

	SelectPending = 19,
	SelectAbandoned = 20,
	SelectWithdrawn = 21,
	SelectRejected = 22,
	SelectDeferred = 23,
	SelectCopiedForward = 24,
	SelectSelected = 25,

	TeamPending = 25,
	TeamPromoted = 26,
}


/**
 * Application statuses that are considered "Submitted". This is used when determining an accepted-rate.
 * If the applicant withdrew or abandoned, even after a prior submission, then it is no longer considered
 * submitted.  Rejected applications are however still submitted.
 */
export const SubmittedApplicationStatusIds = [

	// ApplicationStatusId.AcceptRejected, // This is a legacy concept. Real rejection happens at least at reading

	ApplicationStatusId.ReadPending,
	ApplicationStatusId.ReadRejected,
	ApplicationStatusId.ReadDeferred,

	ApplicationStatusId.InterviewPending,
	ApplicationStatusId.InterviewRejected,
	ApplicationStatusId.InterviewDeferred,

	ApplicationStatusId.SelectPending,
	ApplicationStatusId.SelectRejected,
	ApplicationStatusId.SelectDeferred,

	ApplicationStatusId.TeamPending,
	ApplicationStatusId.TeamPromoted,
];


/**
 * Applications status not in a final state
 */
export const OpenApplicationStatusIds = [
	ApplicationStatusId.AcceptPending,
	ApplicationStatusId.ReadPending,
	ApplicationStatusId.InterviewPending,
	ApplicationStatusId.SelectPending,
	ApplicationStatusId.TeamPending,
];