import { AccSessionSurveyResponse, DbsAccSessionSurveyResponse } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { Observable } from "rxjs";
import { PackageManager } from "../package-manager";
import { SingletonsManager } from "../singletons-manager";
import { AcceleratorPackageManager } from "./accelerator";
import { EventPackageManager } from "./event";


export class AccSessionSurveyResponsePackageManager extends PackageManager<DbsAccSessionSurveyResponse, AccSessionSurveyResponse>{

	constructor(
		singletonsAsOfUTC$: Observable<number>,
		util: UtilityService,
		sm: SingletonsManager<DbsAccSessionSurveyResponse>,
		private accelerator: AcceleratorPackageManager,
		private event: EventPackageManager,
	) {
		super(singletonsAsOfUTC$, util, sm);
	}


	/**
	 * Convert singletons to packages
	 */
	protected async _createPackages(dbsAccSessionSurveyResponses: DbsAccSessionSurveyResponse[]): Promise<AccSessionSurveyResponse[]> {

		//
		// Get all the related objects
		//
		const accIds: number[] = [];
		const eventIds: number[] = [];

		for (const accSessionSurveyResponse of dbsAccSessionSurveyResponses) {
			accIds.push(accSessionSurveyResponse.accId);
			eventIds.push(accSessionSurveyResponse.eventId);
		}

		const acceleratorMap = await this.accelerator.getManyPackagesAsMap(accIds);
		const eventMap = await this.event.getManyPackagesAsMap(eventIds);


		//
		// Package 'em up
		//
		const accSessionSurveyResponses: AccSessionSurveyResponse[] = dbsAccSessionSurveyResponses.map(accSessionSurveyResponse => {

			return {
				...accSessionSurveyResponse,
				accelerator: acceleratorMap[accSessionSurveyResponse.accId],
				event: eventMap[accSessionSurveyResponse.eventId],
			};
		});

		return accSessionSurveyResponses;
	}

	/**
	 * Get all acc session survey responses for each accId provided
	 */
	public async getByAccIds(accIds: readonly number[]): Promise<Record<number, ReadonlyArray<AccSessionSurveyResponse>>> {

		return await this.getPackagesAsArraysByForeignIds('accId', accIds);
	}


	/**
	 * Get all acc session survey responses for each eventId provided
	 */
	public async getByEventIds(eventIds: readonly number[]): Promise<Record<number, ReadonlyArray<AccSessionSurveyResponse>>> {

		return await this.getPackagesAsArraysByForeignIds('eventId', eventIds);
	}

}