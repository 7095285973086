import { AccStageId, Accelerator, Event, EventTypeId, StageStatusItem, StageStatusProblem } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { UrlService } from "@me-services/ui/url";
import { convertEventToMutable } from "@me-shared-parts/ED-editors/shared-editors/event/convert-event-to-mutable";
import { DatesProcessor } from "@me-shared-parts/ED-editors/shared-editors/event/event-dialog/dates-processor";

const AccToolAccess = {
	AcceptTool: [AccStageId.Accepting],
	ReaderTool: [AccStageId.Reading],
	InterviewTool: [AccStageId.Interviewing],
	MentorMatchingTool: [
		AccStageId.Setup,
		AccStageId.Accepting,
		AccStageId.Reading,
		AccStageId.Interviewing,
		AccStageId.SelectingCohort,
		AccStageId.MatchingMentors,
	],
	FinalPresJudgeTool: [AccStageId.Curriculum],
	QuarterliesTool: [
		AccStageId.MatchingMentors,
		AccStageId.Curriculum,
		AccStageId.SelectingWinners,
		AccStageId.Quarterlies,
	],
};

export function getToolStatuses(accelerator: Accelerator, events: Event[], util: UtilityService, urlService: UrlService, eventCounts: { [index: number]: { volunteerCounts: number, applicationOrTeamCount: number } }) {

	const topLevelItems = ['Application Tool', 'Reading Tool', 'Interviewing Tool', 'Mentor Matching Tool', 'Final Presentations Judging Tool', 'Entrepreneur Quarterly Goals Tool'];
	const defaultStatusItem: StageStatusItem = {
		text: '',
		status: "DISABLED",
		problems: [],
		subItems: [],
		routerLink: undefined,
	};

	const response: StageStatusItem[] = [];

	for (const level of topLevelItems) {
		const item: StageStatusItem = Object.assign({}, defaultStatusItem, { text: level });
		response.push(item);
	}

	const accStageId = accelerator.accStageId;
	const siteCode = accelerator.siteProgram.site.code.toLowerCase();
	const siteId = accelerator.siteProgram.siteId;
	const accId = accelerator.accId;
	if (accStageId == AccStageId.Complete) return response;
	else {
		const baseURL = urlService.getBaseUrl(accelerator.siteProgram.program.languageId);
		if (AccToolAccess.AcceptTool.includes(accStageId)) {
			const item = response.find(r => r.text == 'Application Tool');
			item.status = "ENABLED";
			item.link = { linkType: 'tool', url: `${baseURL}/access/sites/${siteCode}/accelerators/${accId}/apply`, overrideText: `Apply`, enabled: true };
		}

		if (AccToolAccess.ReaderTool.includes(accStageId)) {
			let item = response.find(r => r.text == "Reading Tool");
			item = setStatusItem(item, accStageId, accId, siteId, events, util, urlService, eventCounts);
			item.link = { linkType: 'tool', url: `${baseURL}/access/sites/${siteCode}/accelerators/${accId}/read`, overrideText: `Tool`, enabled: true };
		}

		if (AccToolAccess.InterviewTool.includes(accStageId)) {
			let item = response.find(r => r.text == "Interviewing Tool");
			item = setStatusItem(item, accStageId, accId, siteId, events, util, urlService, eventCounts);
			item.link = { linkType: 'tool', url: `${baseURL}/access/sites/${siteCode}/accelerators/${accId}/interview`, overrideText: `Tool`, enabled: true };
		}

		if (AccToolAccess.MentorMatchingTool.includes(accStageId)) {
			let item = response.find(r => r.text == "Mentor Matching Tool");
			item = setMMStatusItem(item, accId, siteId, events, eventCounts);
			item.link = { linkType: 'tool', url: `${baseURL}/access/sites/${siteCode}/accelerators/${accId}/matching`, overrideText: `Tool`, enabled: true };
		}

		if (AccToolAccess.FinalPresJudgeTool.includes(accStageId)) {
			let item = response.find(r => r.text == "Final Presentations Judging Tool");
			item = setStatusItem(item, accStageId, accId, siteId, events, util, urlService, eventCounts);
			item.link = { linkType: 'tool', url: `${baseURL}/access/sites/${siteCode}/accelerators/${accId}/judge`, overrideText: `Tool`, enabled: true };
		}

		if (AccToolAccess.QuarterliesTool.includes(accStageId)) {
			const item = response.find(r => r.text == "Entrepreneur Quarterly Goals Tool");
			item.status = "ENABLED";
			item.link = { linkType: 'tool', url: `${baseURL}/access/my/programs/accelerator/quarterlies`, overrideText: `Tool`, enabled: true };
		}
	}

	return response.map(r => {
		if (r.status == 'DISABLED') r.problems = [];
		return r;
	});
}



function setStatusItem(item: StageStatusItem, accStageId: AccStageId, accId: number, siteId: number, accEvents: Event[], util: UtilityService, urlService: UrlService, eventCounts: { [index: number]: { volunteerCounts: number, applicationOrTeamCount: number } }): StageStatusItem {
	let extraLink = '';
	if (accStageId == AccStageId.Reading) extraLink = 'pre-accelerator?tab=1&view=3';
	if (accStageId == AccStageId.Interviewing) extraLink = 'pre-accelerator?tab=1&view=9';
	if (accStageId == AccStageId.Curriculum) extraLink = 'cohort?tab=1&view=7';

	const subItems = getEventItems(accStageId, accId, siteId, extraLink, accEvents, util, urlService, eventCounts) || [];
	const problems = item.problems;
	let status = "ENABLED";

	if (!subItems.length) {
		status = "CANT-ENABLE"
		problems.push({ text: "There are no Events", routerLink: `/access/admin/national/sites/${siteId}/accelerators/${accId}/${extraLink}` });
	}

	return Object.assign(item, { status, problems, subItems });
}



function getEventItems(accStageId: AccStageId, accId: number, siteId: number, extraLink: string, accEvents: Event[], util: UtilityService, urlService: UrlService, eventCounts: { [index: number]: { volunteerCounts: number, applicationOrTeamCount: number } }): StageStatusItem[] {
	const items: StageStatusItem[] = [];
	const baseLink = `/access/admin/national/sites/${siteId}/accelerators/${accId}`;
	let eventTypeId: EventTypeId;
	let events: {
		event: Event,
		volunteerCount: number,
		applicationOrTeamCount: number
	}[] = [];

	if (accStageId == AccStageId.Reading) {
		eventTypeId = EventTypeId.AccReading;
		const readEvent = accEvents.find(event => event.eventTypeId == eventTypeId);
		if (readEvent) events = [{
			event: readEvent,
			volunteerCount: eventCounts[readEvent.eventId]?.volunteerCounts || 0,
			applicationOrTeamCount: eventCounts[readEvent.eventId]?.applicationOrTeamCount || 0,
		}];

	}
	else if (accStageId == AccStageId.Interviewing) {

		eventTypeId = EventTypeId.AccInterviewing;
		events = accEvents.filter(event => event.eventTypeId == eventTypeId).map(event => ({
			event,
			volunteerCount: eventCounts[event.eventId]?.volunteerCounts || 0,
			applicationOrTeamCount: eventCounts[event.eventId]?.applicationOrTeamCount || 0,
		}));
	}
	else if (accStageId == AccStageId.Curriculum) {

		eventTypeId = EventTypeId.AccFinalPitch;
		events = accEvents.filter(event => event.eventTypeId == eventTypeId).map(event => ({
			event,
			volunteerCount: eventCounts[event.eventId]?.volunteerCounts || 0,
			applicationOrTeamCount: eventCounts[event.eventId]?.applicationOrTeamCount || 0,
		}));
	}
	else if (accStageId == AccStageId.Quarterlies) {
		const eventTypeIds = [EventTypeId.AccQuarterliesQ2, EventTypeId.AccQuarterliesQ3, EventTypeId.AccQuarterliesQ4];
		events = accEvents.filter(event => eventTypeIds.includes(event.eventTypeId)).map(event => ({
			event,
			volunteerCount: 0,
			applicationOrTeamCount: 0,
		}));
	}
	else return items;


	if (events.length) {

		const nowUTC = Date.now() / 1000;
		let dates: DatesProcessor;

		for (const eventAndCounts of events) {
			dates = new DatesProcessor(convertEventToMutable(eventAndCounts.event), util);

			const newItem: StageStatusItem = {
				text: dates.eventDates.description ? `${dates.eventDates.description}` : 'Date not provided',
				status: (eventAndCounts.event.toolStartUTC < nowUTC && nowUTC < eventAndCounts.event.toolEndUTC) ? "ENABLED" : "CANT-ENABLE",
				problems: [],
				subItems: [],
			};

			const toolUntil = nowUTC < eventAndCounts.event.toolStartUTC ? ` until ${util.date.formatUTC(eventAndCounts.event.toolStartUTC, 'MMM D, YYYY', 'H:MM AM EST', urlService.languageId)}` : '';
			if (eventAndCounts.event.toolStartUTC > nowUTC || nowUTC > eventAndCounts.event.toolEndUTC) newItem.problems.push({ text: `Status: ${util.event.getStatus(eventAndCounts.event)} - Tool is Off` + toolUntil, routerLink: `${baseLink}/${extraLink}` });
			if (accStageId != AccStageId.Quarterlies) {
				if (eventAndCounts.volunteerCount < 1) newItem.problems.push({ text: "No Volunteers Assigned", routerLink: `${baseLink}/${extraLink}` });
				if (eventAndCounts.applicationOrTeamCount < 1) newItem.problems.push({ text: "No Applicants/Teams", routerLink: `${baseLink}/${extraLink}` });
			}
			items.push(newItem);
		}
	}

	return items;
}


function setMMStatusItem(item: StageStatusItem, accId: number, siteId: number, accEvents: Event[], eventCounts: { [index: number]: { volunteerCounts: number, applicationOrTeamCount: number } }): StageStatusItem {
	const extraLink = 'cohort';

	const { allSubItems, problems } = getMMEventItems(accId, siteId, extraLink, accEvents, eventCounts);
	item.subItems = allSubItems;
	item.problems = problems;
	item.status = "ENABLED";

	return item;
}

function getMMEventItems(accId: number, siteId: number, extraLink: string, accEvents: Event[], eventCounts: { [index: number]: { volunteerCounts: number, applicationOrTeamCount: number } }): { allSubItems: StageStatusItem[], problems: StageStatusProblem[] } {
	const allSubItems: StageStatusItem[] = [];
	const problems: StageStatusProblem[] = [];
	const baseLink = `/access/admin/national/sites/${siteId}/accelerators/${accId}`;

	const MMEvents = accEvents.filter(event => event.eventTypeId == EventTypeId.AccMentorMatching);

	if (!MMEvents.length) problems.push({ text: "There are no Events", routerLink: `${baseLink}/${extraLink}` });

	const totalMentors = eventCounts[0]?.volunteerCounts || 0;
	const totalTeams = eventCounts[0]?.applicationOrTeamCount || 0;

	if (!totalMentors) problems.push({ text: "There are no Mentors", routerLink: `${baseLink}/cohort?tab=1&view=2` });
	if (!totalTeams) problems.push({ text: "There are no Entrepreneurs", routerLink: `${baseLink}/cohort?tab=1&view=1` });

	// Add other MM specific SubItems
	const MMItems = ['Event Selection', 'Mentor Pitch Editing', 'Schedule Selection', 'Assessments Entry'];
	const defaultStatusItem: StageStatusItem = {
		text: '',
		status: "ENABLED",
		problems: [],
		subItems: [],
		routerLink: undefined,
	};

	for (const MMSubItem of MMItems) {
		const item: StageStatusItem = Object.assign({}, defaultStatusItem, { text: MMSubItem });

		if (!totalMentors) item.status = "CANT-ENABLE";
		else if (MMSubItem == 'Assessment Entry' && !totalTeams) item.status = "CANT-ENABLE";
		else if (MMSubItem == 'Event Selection' && !MMEvents.length) item.status = "CANT-ENABLE";

		allSubItems.push(item);
	}

	return { allSubItems, problems };
}