import { Component, Input, ViewEncapsulation } from '@angular/core';
import { LinkOrExplorable } from '../link-or-explorerable';


@Component({
	selector: 'explorer-buttons',
	templateUrl: './explorer-buttons.part.html',
	styleUrls: ['./explorer-buttons.part.scss'],
	encapsulation: ViewEncapsulation.None,	// So ng-content projected buttons share the css styling
})
export class ExplorerButtonsPart {

	@Input() buttons: LinkOrExplorable[] = [];

}