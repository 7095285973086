import { CompanyStatus } from '@me-interfaces'

export interface SearchCompanyResult {
	companyId: number,
	companyType: string,
	logo: string,
	name: string,
	shortName: string,
	city: string | undefined,
	website: string | undefined,
	status: CompanyStatus,
	people: {
		name: string,
		title: string,
		isAdmin: boolean,
		isFounder: boolean,
	}[],
	strongMatch: boolean,
}