<div class='program-overview-view-part' *ngIf="viewDroplistItems.length">

	<ng-container *ngIf='selectedViewItem$ | async; let selectedViewItem'>

		<me-droplist class="views-droplist" [items]='viewDroplistItems' [selectedItem]='selectedViewItem'
			(selectionChange)='onViewDroplistChange($event)' [filterable]='false' [navigation]='true'
			sortBy='customSort' [groupSortValues]='{"Program":1, "Curriculum":2,"Problems":3, "Agreement":4 }'
			style='display:inline-block'>
		</me-droplist>


		<program-overview-view-part *ngIf="showView('ProgramOverview', selectedViewItem)">
		</program-overview-view-part>

		<program-agreement-types-view-part *ngIf="showView('AgreementTypes', selectedViewItem)">
		</program-agreement-types-view-part>

		<program-agreement-signatures-view-part *ngIf="showView('AgreementSignatures', selectedViewItem)">
		</program-agreement-signatures-view-part>

		<training-materials-by-language-part [languageId]='spanishlanguageId'
			*ngIf="showView('SpanishTrainingMaterials', selectedViewItem)">
		</training-materials-by-language-part>

		<training-materials-by-language-part [languageId]='englishlanguageId'
			*ngIf="showView('EnglishTrainingMaterials', selectedViewItem)">
		</training-materials-by-language-part>

		<program-acc-problems-view-part *ngIf="showView('AcceleratorProblems', selectedViewItem)">
		</program-acc-problems-view-part>

	</ng-container>

</div>
