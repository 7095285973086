import { Dba, DbConceptName, Immutable } from '@me-interfaces';


interface MutableDbaAccTopicSpecialist extends Dba {

	readonly _concept: DbConceptName.AccTopicSpecialist,

	accTopicSpecialistId: number,
	accTopicId: number,
	personId: number,
}


export interface DbaAccTopicSpecialist extends Immutable<MutableDbaAccTopicSpecialist> { }