import { Component } from '@angular/core';
import { ChangeType, Release } from './interfaces';
import { CHANGE_LOG } from './release-changelog';


@Component({
	selector: 'admin-whats-new-page',
	templateUrl: './whats-new.page.html',
	styleUrls: ['./whats-new.page.scss'],
})
export class WhatsNewPage {

	public releases: Release[] = CHANGE_LOG;

	getIcon(changeType: ChangeType): string {
		if (changeType == ChangeType.Feature) return 'fas fa-star';
		else if (changeType == ChangeType.Bug) return 'far fa-bug';
		else if (changeType == ChangeType.Infrastructure) return 'far fa-hammer';
		else if (changeType == ChangeType.UserInterface) return 'far fa-window-flip';
	}
}
