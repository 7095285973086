export interface PersonProfileAccessFlagsExtractForSite {
	isDirector: boolean,
	isManager: boolean,
	isSiteIntern: boolean,
	isSiteStaffOrBetter: boolean,
	isSiteAdmin: boolean,
}

/**
 * Extends the admin flags to include sites that can be administrated.
 */
export interface PersonProfileBiosExtract {
	english: string | undefined,
	spanish: string | undefined,
}


export interface PersonProfileAccessFlagsExtract {
	allSiteAccess: boolean,
	bySiteCode: { [index: string]: PersonProfileAccessFlagsExtractForSite },
	bySiteId: { [index: number]: PersonProfileAccessFlagsExtractForSite },
	canReadContactsDb: boolean,
}

export interface PersonProfileEmailExtract {
	emailId: number,
	email: string,
	isPrimary: boolean;
}

export interface PersonProfileShowToExtract {
	isMentor: boolean,
	isAlumni: boolean,
	hasFeedback: boolean,
}

export interface PersonProfileExtracts {
	readonly accessFlags: PersonProfileAccessFlagsExtract,
	readonly bios: PersonProfileBiosExtract,
	readonly emails: PersonProfileEmailExtract[],
	readonly hasFeedback: boolean,
	readonly showTos: PersonProfileShowToExtract,
}