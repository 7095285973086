import { SiteAreaService, SiteDashboardPageService } from '@SITE-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AppAreaIdentifier, LanguageId, SiteAreaAccess, ToolLink } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UrlService } from '@me-services/ui/url';
import { PageTabsLayout } from '@me-shared-parts/UI-common';


@Component({
	selector: 'site-overview',
	templateUrl: './site-overview.page.html',
	styleUrls: ['./site-overview.page.scss'],
})
export class SiteOverviewPage extends DestroyablePart implements OnInit {

	readonly: boolean;
	councilHandbookToolLink: ToolLink;


	public tabNames = ["Site", "Get Help"];
	constructor(
		public pageService: SiteDashboardPageService,
		public ds: DataService,
		public siteAreaService: SiteAreaService,
		public urlService: UrlService,

	) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();

		this.siteAreaService.subscribeAccess(this.destroyed$, this.accessChanged);

		this.siteAreaService.data$.subscribe(data => {
			if (data?.site) {
				this.tabNames = [data.site.name, 'Get Help'];
				this.councilHandbookToolLink = {
					linkType: 'tool',
					url: `${this.urlService.getBaseUrl(LanguageId.English)}/access/my/agreements/council-handbook`,
					enabled: true,
					overrideText: 'Page',
				};
			}
		});



	}


	accessChanged = async (value: { access: SiteAreaAccess, id: AppAreaIdentifier<number> }) => {
		this.readonly = value?.access?.root != 'Write';
	}

	onLayoutChange(layout: PageTabsLayout) {
		this.pageService.changeLayout(layout);
	}

}