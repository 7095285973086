import { ApplicationEditorService } from '../../../admin/editor-services/application/application-editor.service';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { IconContext, IconHideTextAt } from '@me-shared-parts/UI-common';


@Component({
	selector: 'application-notes-button',
	templateUrl: './SHR-PG_application-notes-button.part.html',
	styleUrls: ['./SHR-PG_application-notes-button.part.scss']
})
export class ApplicationNotesButtonPart extends DestroyablePart implements OnInit, OnChanges {
	
	@Input() applicationId: number;
	@Input() notes: string;
	@Input() hideText?: IconHideTextAt;
	@Input() readonly: boolean;

	@Output() notesChanged = new EventEmitter<string>();

	icon: IconContext = { text: 'Notes', iconClass: "far fa-sticky-note" };


	constructor(private applicationEditorService: ApplicationEditorService) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();
	}

	ngOnChanges() {
		if (this.notes) this.icon = { text: 'Notes', hideText: this.hideText || 'never', iconClass: "fas fa-sticky-note" }
		else this.icon = { text: 'Notes', hideText: this.hideText || 'never', iconClass: "far fa-sticky-note" };
	}


	async open() {
		await this.applicationEditorService.launchNoteDialog(this.applicationId, this.notes, 'Application Notes', this.readonly);
		// if (callbackResult) this.notesChanged.emit(callbackResult.text);
	}
}