<div class='captioned-layout-select'>
	<mat-form-field appearance="outline">
		<mat-select [placeholder]='placeholder' [disabled]='disabled' [value]='value'
			(selectionChange)='selectPerson($event.value)'>
			<mat-select-trigger class='alignment'>
				<captioned-layout [layout]='value'></captioned-layout>
			</mat-select-trigger>
			<mat-option *ngFor='let layout of list' [value]='layout' class='captioned-layout-select-option'>
				<captioned-layout [layout]='layout'></captioned-layout>
			</mat-option>
		</mat-select>
	</mat-form-field>
</div>