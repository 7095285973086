import { AccStageId } from "@me-interfaces";
import { ApplicationCols } from "@me-grid";
import { FilterableGridController } from "@me-shared-parts/UI-common/grid/controller/filterable-grid-controller";
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';

export type ApplicationGridApplicationStatus = 'All' | 'AcceptedOrBeyond';
export type ApplicationGridFilterField = 'status' | 'demographics' | 'location' | 'industry' | 'offerings' | 'orgType';


/**
 * Filter an application by a field's value.
 */
export interface ApplicationGridFilter {
	/** The accelerator stage where this filter was applied from. Only the grid of that stage will be updated. */
	accStageId: AccStageId,
	/** If AcceptedOrBeyond then the grid will be filtered by field=value AND status=Accepted+Beyond. This parameter is ignored if the field parameter = 'status' */
	applicationStatus: ApplicationGridApplicationStatus,
	field: ApplicationGridFilterField,
	value: string,
}


export function applyApplicationGridFilter(
	/** The Accelerator Stage of the provided grid */
	accStageId: AccStageId,

	/** A controller of the grid with the columns that will be filtered */
	grid: FilterableGridController<unknown>,

	/** The details about what is to be filtered */
	filter: ApplicationGridFilter,
) {

	if (grid == undefined || filter == undefined) return;
	if (accStageId !== filter.accStageId) return


	//
	// Simply filter by status (e.g. pending, submitted)
	//
	if (filter.field == 'status') {

		if (filter.value == 'All') grid.clearFilters();
		else grid.setFilters({ logic: 'and', filters: [{ field: 'status', operator: 'eq', value: filter.value }] });

		return;
	}




	//
	// One of the "By Something" buttons was clicked. So filter by that thing.
	//

	const filters: CompositeFilterDescriptor = {
		logic: 'and',
		filters: [],
	};


	let acceptedStatus = 'Accepted';
	if (accStageId == AccStageId.Accepting) acceptedStatus = 'Submitted';
	if (accStageId == AccStageId.SelectingCohort) acceptedStatus = 'Selected';

	if (filter.applicationStatus == 'AcceptedOrBeyond') {
		
		filters.filters.push(
			{
				logic: 'or',
				filters: [
					{ field: 'status', operator: 'eq', value: 'Beyond' },
					{ field: 'status', operator: 'eq', value: acceptedStatus },
				]
			}
		);
	}


	if (filter.value !== 'All') {
		let field: string = filter.field;

		if (field == 'offerings') {
			field = ApplicationCols.offerings;

			filters.filters.push(
				{
					logic: 'or',
					filters: [
						{ field, operator: 'eq', value: 'Both' },
						{ field, operator: 'eq', value: filter.value },
					]
				}
			);
		}
		else if (field == 'demographics') {


			if (filter.value == 'Female') field = ApplicationCols.female;
			if (filter.value == 'Not Male') field = ApplicationCols.notMale;
			if (filter.value == 'Bipoc') field = ApplicationCols.bipoc;
			if (filter.value == 'Asian') field = ApplicationCols.asian;
			if (filter.value == 'Black') field = ApplicationCols.black;
			if (filter.value == 'Indigenous') field = ApplicationCols.indigenous;
			if (filter.value == 'Pacific Islander') field = ApplicationCols.pacificIslander;
			if (filter.value == 'Other Race') field = ApplicationCols.otherRace;
			if (filter.value == 'White') field = ApplicationCols.white;
			if (filter.value == 'Hispanic') field = ApplicationCols.hispanic;
			if (filter.value == 'Immigrant') field = ApplicationCols.immigrant;
			if (filter.value == 'Veteran') field = ApplicationCols.veteran;
			if (filter.value == 'Spanish') field = ApplicationCols.spanish;
			if (filter.value == 'English') field = ApplicationCols.english;
			if (filter.value == 'Deceased') field = ApplicationCols.deceased;


			filters.filters.push({ field, operator: 'gte', value: 1 });
		}
		else {
			if (field == 'location') field = ApplicationCols.companyCityAndState;
			if (field == 'industry') field = ApplicationCols.industry;
			if (field == 'orgType') field = ApplicationCols.companyType;

			filters.filters.push({ field, operator: 'eq', value: filter.value });
		}

	}

	grid.setFilters(filters);

}