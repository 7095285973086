import { ApplicationStatusId } from "@me-interfaces";
import { getIconContext } from "./get-icon-context";
import { Icon } from "./icon";
import { IconContext } from "./icon-context";
import { IconHideTextAt } from "./icon-hide-text-at";
import { IconStyle } from "./icon-style";


/**
 * Return the Icon value for a given ApplicationStatusId
 */
export function getIconForApplicationStatus(applicationStatusId: ApplicationStatusId): Icon {

	if (applicationStatusId == ApplicationStatusId.AcceptPending) return Icon.action_edit;
	if (applicationStatusId == ApplicationStatusId.AcceptAbandoned) return Icon.status_x;
	if (applicationStatusId == ApplicationStatusId.AcceptWithdrawn) return Icon.status_x;
	if (applicationStatusId == ApplicationStatusId.AcceptRejected) return Icon.status_x;
	if (applicationStatusId == ApplicationStatusId.AcceptDeferred) return Icon.status_x;
	if (applicationStatusId == ApplicationStatusId.AcceptCopiedForward) return Icon.status_x;

	if (applicationStatusId == ApplicationStatusId.ReadPending) return Icon.status_pending;
	if (applicationStatusId == ApplicationStatusId.ReadAbandoned) return Icon.status_x;
	if (applicationStatusId == ApplicationStatusId.ReadWithdrawn) return Icon.status_x;
	if (applicationStatusId == ApplicationStatusId.ReadRejected ) return Icon.status_x;
	if (applicationStatusId == ApplicationStatusId.ReadDeferred ) return Icon.status_x;
	if (applicationStatusId == ApplicationStatusId.ReadCopiedForward ) return Icon.status_x;

	if (applicationStatusId == ApplicationStatusId.InterviewPending) return Icon.status_pending;
	if (applicationStatusId == ApplicationStatusId.InterviewAbandoned ) return Icon.status_x;
	if (applicationStatusId == ApplicationStatusId.InterviewWithdrawn ) return Icon.status_x;
	if (applicationStatusId == ApplicationStatusId.InterviewRejected ) return Icon.status_x;
	if (applicationStatusId == ApplicationStatusId.InterviewDeferred ) return Icon.status_x;
	if (applicationStatusId == ApplicationStatusId.InterviewCopiedForward ) return Icon.status_x;

	if (applicationStatusId == ApplicationStatusId.SelectPending) return Icon.status_pending;
	if (applicationStatusId == ApplicationStatusId.SelectAbandoned ) return Icon.status_x;
	if (applicationStatusId == ApplicationStatusId.SelectWithdrawn ) return Icon.status_x;
	if (applicationStatusId == ApplicationStatusId.SelectRejected ) return Icon.status_x;
	if (applicationStatusId == ApplicationStatusId.SelectDeferred ) return Icon.status_x;
	if (applicationStatusId == ApplicationStatusId.SelectCopiedForward ) return Icon.status_x;

	if (applicationStatusId == ApplicationStatusId.TeamPending) return Icon.status_pending;
	if (applicationStatusId == ApplicationStatusId.TeamPromoted ) return Icon.status_check;

	return Icon.status_missing;
}


export function getIconContextForApplicationStatus(applicationStatusId: ApplicationStatusId, iconStyle?: IconStyle, hideText?: IconHideTextAt, text?: string, hasRedFlag?: boolean): IconContext {
	return getIconContext(getIconForApplicationStatus(applicationStatusId), iconStyle, hideText, text, hasRedFlag);
}