import { Component, OnChanges, Input, OnInit } from '@angular/core';

@Component({
	selector: 'acc-stage-msg',
	templateUrl: './SHR-CMN_acc-stage-msg.part.html',
	styleUrls: ['SHR-CMN_acc-stage-msg.part.scss'],
})
export class AccStageMsgPart{

	@Input() currentStage: number;
	@Input() expectedStage: number;
	@Input() name: string;

}
