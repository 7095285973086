import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DownloadColumn } from '@me-interfaces/ui';

export function buildArrays(columns: DownloadColumn[], rowObjects: any[]): string[][] {

	if (columns == undefined || columns.length == 0) return [];

	const rows: string[][] = [];

	rows.push(columns.map(col => col.header));

	for (const rowObject of rowObjects) {
		rows.push(columns.map(col => {
			let cell = rowObject[col.property];
			if (col.type == 'string') cell = (cell || '').trim().replace(`\t`, ` `);
			if (col.type == 'number') cell = (cell || cell == 0) ? (+cell).toString() : '';
			if (col.type == 'boolean') cell = cell ? 'true' : 'false';
			if (col.type == 'number[]') cell = cell ? cell.join(',') : '';
			return cell;
		}));
	}

	return rows;
}

export function createHref(rows: string[][]): string {
	return rows.map(row => row.join('\t')).join('\n');
}

export function makeSafeHref(href: string, sanitizer: DomSanitizer): SafeUrl {
	return sanitizer.bypassSecurityTrustUrl('data:attachment/csv,' + encodeURIComponent(href));
}