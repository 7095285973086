import { Component, OnChanges, Input } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DbsPerson } from '@me-interfaces';
import { PersonExtractDialog } from '../SHR-PN_person-extract-dialog.dialog';

@Component({
	selector: 'person-extract-info',
	templateUrl: './SHR-PN_person-extract-info.part.html',
	styleUrls: ['./SHR-PN_person-extract-info.part.scss']
})
export class PersonExtractInfoPart implements OnChanges {
	@Input() person: DbsPerson;
	@Input() dialogRef: MatDialogRef<PersonExtractDialog>;


	ngOnChanges() {
		if(!this.person) throw new Error('Missing required attribute: person');
	}
}