import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DbcUser, DbsEntity, EntityNote, NoteCategoryId } from '@me-interfaces';
import { OldEntityService } from '@me-services/old-services-and-wrappers/entity';
import { EntityNotesWrapper } from '@me-services/old-services-and-wrappers/entity/notes-wrapper';
import { OldUserService } from '@me-services/old-services-and-wrappers/user';
import { OldDomainDataService } from '@me-services/core/old-dd';
import { DialogService } from '@me-services/ui/dialog';
import * as moment from 'moment';
import { ENTITY_NOTE_KEY_PREFIX } from '../SHR-ED_entity-notes-editor.part';


@Component({
	selector: 'entity-notes-edit-note',
	templateUrl: './SHR-ED_entity-notes-edit-note.part.html',
	styleUrls: ['SHR-ED_entity-notes-edit-note.part.scss'],
})
export class EntityNotesEditNotePart implements OnInit {

	@Input() entity: DbsEntity;
	@Input() notesWrapper: EntityNotesWrapper;
	@Input() note?: EntityNote;
	@Output() noteSelected = new EventEmitter<string>();
	@Output() notesChanged = new EventEmitter<EntityNote[]>();

	form: FormGroup;
	sites: { siteId: number, name: string }[] = [{ siteId: undefined, name: '' }];
	updating: boolean;
	user: DbcUser;
	noteCategories = this.dd.noteCategories.objects;


	public defaultSiteItem: { siteId: number, name: string } = { siteId: undefined, name: '' };

	constructor(
		public dd: OldDomainDataService,
		private dialogService: DialogService,
		public entityService: OldEntityService,
		private fb: FormBuilder,
		private userService: OldUserService,
	) { }


	public async ngOnInit(): Promise<void> {
		this.user = await this.userService.getUser();
		this.sites = this.dd.sites.objects
			.sort((a, b) => a.name < b.name ? -1 : 1)
			.map(site => {
				return { siteId: site.siteId, name: site.name }
			});

		if (this.note) {
			this.note['editedOn'] = moment(this.note.note.updatedUTC * 1000).locale('en').format('lll');
			this.note['createdOn'] = moment(this.note.note.createdUTC * 1000).locale('en').format('lll');
		}
		this.createForm();
	}


	private createForm() {
		this.form = this.fb.group({
			noteCategoryId: [undefined, [Validators.required]],
			subject: ['', [Validators.required, Validators.maxLength(60)]],
			note: [''],
			siteId: [undefined],
		});

		this.resetForm();
	}


	public resetForm() {
		const n = this.note;
		const values = {
			noteCategoryId: n && n.note.noteCategoryId ? n.note.noteCategoryId : undefined,
			subject: n && n.note.subject ? n.note.subject : '',
			note: n && n.note.note ? n.note.note : '',
			siteId: n && n.note.siteId ? n.note.siteId : undefined,
		};

		this.form.reset(values);
		this.form.updateValueAndValidity();
	}


	tooManyCharacters(ctrl: AbstractControl) {
		const e = ctrl.errors.maxlength;
		return 'Too many characters. ' + e.actualLength + ' > ' + e.requiredLength;
	}


	async save() {
		this.updating = true;
		const values = {
			noteCategoryId: this.form.value.noteCategoryId,
			subject: this.form.value.subject,
			note: this.form.value.note,
			siteId: this.form.value.siteId,
		};
		await this.notesWrapper.updateNote(
			this.note.noteId,
			this.form.value.noteCategoryId,
			this.form.value.subject,
			this.form.value.note,
			this.form.value.siteId);
		this.resetForm();
		this.updateNotes();
		this.updating = false;
	}


	async add() {
		this.updating = true;
		const noteId = await this.notesWrapper.addNote(
			this.form.value.noteCategoryId,
			this.form.value.subject,
			this.form.value.note,
			this.form.value.siteId);
		this.resetForm();
		this.updating = false;
		this.updateNotes();
		this.changeNote(noteId);
	}


	async delete() {
		const msg = `Do you want to remove Note: "${this.form.value.subject}" from ${this.entity._name}?`;
		const yes = await this.dialogService.confirm(msg);

		if (yes) {
			this.updating = true;
			await this.notesWrapper.removeNote(this.note.noteId);
			this.resetForm();
			this.updating = false;
			this.updateNotes();
			this.changeNote('overview');
		}
	}


	getNoteIcon(noteCategoryId: number) {
		if (noteCategoryId == NoteCategoryId.RedFlag) return 'red fas fa-flag fa-fw';
		else return 'far fa-sticky-note fa-fw';
	}


	changeNote(key) {
		this.noteSelected.emit(`${ENTITY_NOTE_KEY_PREFIX}${key}`);
	}


	updateNotes() {
		this.notesChanged.emit(this.notesWrapper.currentNotes);
	}
}