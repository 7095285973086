import { Component, Input, OnChanges } from '@angular/core';
import { PicTeam } from '@me-interfaces';
import { UtilityService } from '@me-services/core/utility';
import { UrlService } from '@me-services/ui/url';


@Component({
	selector: 'pic-team-explorer-details',
	templateUrl: './details.part.html',
	styleUrls: ['./details.part.scss'],
})
export class PicTeamExplorerDetailsPart implements OnChanges {
	@Input() picTeam: PicTeam;

	constructor(
		public util: UtilityService,
		public urlService: UrlService,
	) { }


	ngOnChanges() {
		if (!this.picTeam) throw new Error('Missing required attribute: picTeam');
	}

}