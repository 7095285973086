import { Location } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuItem } from '@me-interfaces';
import { BREAKPOINT_MD } from '@me-services/ui/layout/layout-constants';
import { MenuService } from '@me-services/ui/menu';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ideasAndBugsMenu } from './ideas-and-bugs-menu';

@Component({
	selector: 'me-menu',
	templateUrl: './ACS_menu.part.html',
	styleUrls: ['./ACS_menu.part.scss']
})

export class MenuPart implements OnInit {
	public menuItems$: Observable<MenuItem[]>;
	public menuHeight: number;
	public isMenuShouldCollapsed = false;
	public showHoverElem: boolean;
	public hoverElemHeight: number;
	public hoverElemTop: number;
	jiraMenu: MenuItem = ideasAndBugsMenu;

	constructor(
		private location: Location,
		private elementRef: ElementRef,
		public menu: MenuService,
		public router: Router,
	) {
		this.menuItems$ = menu.menuItems$;

		this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(this.syncMenu.bind(this));
	}


	public ngOnInit(): void {
		if (this.shouldMenuCollapse()) this.menu.setCollapsed(true);
		this.updateSidebarHeight();
		this.syncMenu();
	}


	syncMenu() {
		this.openMenuToLocation();
		const width = (window.innerWidth > 0) ? window.innerWidth : screen.width;
		if (width <= 768) this.menu.setCollapsed(true);
		window.scrollTo(0, 0);
	}


	@HostListener('window:resize')
	public onWindowResize(): void {
		const isMenuShouldCollapsed = this.shouldMenuCollapse();

		if (this.isMenuShouldCollapsed !== isMenuShouldCollapsed) {
			this.menu.setCollapsed(isMenuShouldCollapsed);
		}
		this.isMenuShouldCollapsed = isMenuShouldCollapsed;
		this.updateSidebarHeight();
	}


	private shouldMenuCollapse(): boolean {
		return window.innerWidth <= BREAKPOINT_MD;
	}


	public updateSidebarHeight(): void {
		this.menuHeight = this.elementRef.nativeElement.children[0].clientHeight - 84;
	}


	public hoverItem($event): void {
		this.showHoverElem = true;
		this.hoverElemHeight = $event.currentTarget.clientHeight;
		this.hoverElemTop = $event.currentTarget.getBoundingClientRect().top - 50;
	}


	public unHoverItem($event) {
		this.showHoverElem = false;
	}


	public collapseMenu($event): boolean {
		const link = jQuery($event.currentTarget);
		if (this.menu.collapsed$.value) {

			this.menu.setCollapsed(false);
			if (link.parent().hasClass('sidebar-item-expanded')) {
				return false;
			}
			else {
				link.parent().parent().find('li').removeClass('sidebar-item-expanded');
				link.parent().parent().find('li .sidebar-sublist').slideUp(250);
				link.parent().addClass('sidebar-item-expanded');
				setTimeout(function () {
					link.next().css('display', 'block');
				}, 250);
				link.next().slideDown(300);
			}
		}
		else {
			if (link.parent().hasClass('sidebar-item-expanded')) {
				link.parent().removeClass('sidebar-item-expanded');
				link.next().slideUp(250);
			} else {
				link.parent().parent().find('li').removeClass('sidebar-item-expanded');
				link.parent().parent().find('li .sidebar-sublist').slideUp(250);
				link.parent().addClass('sidebar-item-expanded');
				link.next().slideDown(300);
			}
		}
		return false;
	}


	public resetMenus(browserUrl: string) {

		// When we have a admin menu for a person, company, pic or acc, then the contact and site menus shouldnt be opened.
		// This check is needed to override the startsWith logic since they start with the same base url.
		const isMenuThatShouldntBeOpen = function (menuUrl: string, browserUrl: string) {

			const browserUrlParts = browserUrl.split('/');

			if (browserUrl.startsWith('/access/sites/') && browserUrlParts.length >= 5 && browserUrlParts[4] == 'admin') {
				if (menuUrl == '/access/sites' || menuUrl == `/access/sites/${browserUrlParts[3]}`) return true;
				if (browserUrlParts.length >= 8 && browserUrlParts[5] == 'programs' && browserUrlParts[7] !== 'add' && menuUrl.endsWith('/admin')) return true;
			}
			if (browserUrl.startsWith('/access/sites/') && browserUrlParts.length >= 3 && browserUrlParts[3] == 'all') {
				if (menuUrl == '/access/sites') return true;
			}
			if (browserUrl.startsWith('/access/contacts/companies/')) {
				if (menuUrl == '/access/contacts' || menuUrl == '/access/contacts/companies') return true;
			}
			if (browserUrl.startsWith('/access/contacts/people/')) {
				if (menuUrl == '/access/contacts' || menuUrl == '/access/contacts/people') return true;
			}
			if (menuUrl == '/access/tools') return true;
			return false;
		}

		//
		// Find all menus that are currently open
		//
		let menus = jQuery('.sidebar-item-expanded');

		//
		// Filter out those that start with the current location url
		//
		menus = menus.filter(function () {
			if (this.dataset && this.dataset.url) {
				if (isMenuThatShouldntBeOpen(this.dataset.url, browserUrl)) return true;
				return !(browserUrl == this.dataset.url || browserUrl.startsWith(this.dataset.url + '/'));
			}
			else return true;
		});

		//
		// Close those that remain
		//
		menus.removeClass("sidebar-item-expanded");
		let submenus = menus.children('ul');
		submenus.slideUp(250);

		//
		// Find openable menus that are not currently open
		//
		menus = jQuery('.sidebar-item-expandable');
		menus = menus.filter(':not(.sidebar-item-expanded)');

		//
		// Filter to those that should be open and open them
		//
		menus = menus.filter(function () {
			if (this.dataset && this.dataset.url) {
				if (isMenuThatShouldntBeOpen(this.dataset.url, browserUrl)) return false;
				return browserUrl == this.dataset.url || browserUrl.startsWith(this.dataset.url + '/');
			}
			else return false;
		});

		menus.addClass("sidebar-item-expanded");
		submenus = menus.children('ul');
		submenus.slideDown(250);
	}


	public openMenuToLocation(): void {
		const url = this.location.path();

		setTimeout(() => {
			this.resetMenus(url);

			window.scrollTo(0, 0);

			const subMenu = jQuery('a[href="' + url + '"]').closest("li").closest("ul");
			subMenu.closest("li").addClass("sidebar-item-expanded");
			subMenu.slideDown(250);

			const outerMenu = subMenu.closest("li").closest("ul");
			outerMenu.closest("li").addClass("sidebar-item-expanded");
			outerMenu.slideDown(250);

		});
	}
}
