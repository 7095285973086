import { Component, Input, OnChanges } from '@angular/core';
import { PersonBadge } from '@me-interfaces';


@Component({
	selector: 'person-badge',
	styleUrls: ['./SHR-CMN_person-badge.part.scss'],
	templateUrl: './SHR-CMN_person-badge.part.html',
})
export class PersonBadgePart implements OnChanges {

	@Input() badge: PersonBadge;
	public icon = '';
	public title = '';


	ngOnChanges() {
		if (this.badge == undefined) throw new Error('The person-badge part requires a badge attribute.');
		else if (this.badge == PersonBadge.PositionAdmin) {
			this.icon = 'fas fa-unlock';
			this.title = 'Admin';
		}
		else if (this.badge == PersonBadge.PositionFounder) {
			this.icon = 'fas fa-seedling';
			this.title = 'Founder';
		}
		else if (this.badge == PersonBadge.PositionDefault) {
			this.icon = 'fas fa-star';
			this.title = 'Default Position';
		}
		else throw new Error(`Unknown badge attribute: ${this.badge}`);
	}
}