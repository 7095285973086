import { AccSessionSpecialistSurveyResponse, DbsAccSessionSpecialistSurveyResponse } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { Observable } from "rxjs";
import { PackageManager } from "../package-manager";
import { SingletonsManager } from "../singletons-manager";
import { PersonPackageManager } from "./person";
import { AcceleratorPackageManager } from "./accelerator";
import { EventPackageManager } from "./event";
import { DomainDataManagers } from "../interfaces";


export class AccSessionSpecialistSurveyResponsePackageManager extends PackageManager<DbsAccSessionSpecialistSurveyResponse, AccSessionSpecialistSurveyResponse>{

	constructor(
		singletonsAsOfUTC$: Observable<number>,
		util: UtilityService,
		sm: SingletonsManager<DbsAccSessionSpecialistSurveyResponse>,
		private person: PersonPackageManager,
		private accelerator: AcceleratorPackageManager,
		private event: EventPackageManager,
		private domain: DomainDataManagers,
	) {
		super(singletonsAsOfUTC$, util, sm);
	}


	/**
	 * Convert an singletons to packages
	 */
	protected async _createPackages(dbsAccSessionSpecialistSurveyResponses: DbsAccSessionSpecialistSurveyResponse[]): Promise<AccSessionSpecialistSurveyResponse[]> {

		const personIds = dbsAccSessionSpecialistSurveyResponses.map(survey => survey.personId);
		const personMap = await this.person.getManyPackagesAsMap(personIds);

		const accIds = dbsAccSessionSpecialistSurveyResponses.map(survey => survey.accId);
		const accMap = await this.accelerator.getManyPackagesAsMap(accIds);

		const eventIds = dbsAccSessionSpecialistSurveyResponses.map(survey => survey.eventId);
		const eventMap = await this.event.getManyPackagesAsMap(eventIds);

		const topicMap = await this.domain.topic.getAllAsMap();


		//
		// Package 'em up
		//
		const accSessionSpecialistSurveyResponses: AccSessionSpecialistSurveyResponse[] = dbsAccSessionSpecialistSurveyResponses.map(accSessionSpecialistSurveyResponse => {

			return {
				...accSessionSpecialistSurveyResponse,
				person: personMap[accSessionSpecialistSurveyResponse.personId],
				accelerator: accMap[accSessionSpecialistSurveyResponse.accId],
				event: eventMap[accSessionSpecialistSurveyResponse.eventId],
				topic: topicMap[accSessionSpecialistSurveyResponse.topicId],
			};
		});

		return accSessionSpecialistSurveyResponses;
	}



	/**
	 * Get all acc session specialist survey responses for each accId provided
	 */
	public async getByAccIds(accIds: readonly number[]): Promise<Record<number, ReadonlyArray<AccSessionSpecialistSurveyResponse>>> {

		return await this.getPackagesAsArraysByForeignIds('accId', accIds);
	}

}