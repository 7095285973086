export interface AccMatchingRollups_old {
	availability: number, 	// Percentage of all possible meeting times this person is available
	overlaps: number,  		// The count of mentors or teams whose schedule overlaps with this person
	medianMeetings: number, // The median count of overlapping meetings per mentor or team
	assessmentsGiven: [number, number, number, number], // Counts of each of the four assessment values given
	assessmentsRecvd: [number, number, number, number], // Counts of each of the four assessment values received
	assessmentsGivenPercent: number,  // Percent of assessments Given compared to expected
	positivityGiven: number, // Percent of positivity
	positivityRecvd: number, // Percent of positivity
}

export interface AccMatchingRollups {
	availability: number, 	// Percentage of all possible meeting times this person is available
	overlaps: number,  		// The count of mentors or teams whose schedule overlaps with this person
	medianMeetings: number, // The median count of overlapping meetings per mentor or team
	assessmentsGiven: [number, number, number, number], // Counts of each of the four assessment values given
	assessmentsRecvd: [number, number, number, number], // Counts of each of the four assessment values received
	assessmentsGivenPercent: number,  // Percent of assessments Given compared to expected
	positivityGiven: number, // Percent of positivity
	positivityRecvd: number, // Percent of positivity
	totalMeetings: number,
	roleCountOfMeetings: number,
	hasDefaultSchedule: boolean,
}