import { Injectable } from '@angular/core';
import { DbsCompany } from '@me-interfaces';
import { DialogAction, DialogData, DialogService } from '@me-services/ui/dialog';
import { CompanyExtractDialog } from './SHR-CM_company-extract-dialog.dialog';


@Injectable({ providedIn: 'root' })
/**
 * Provides utilities for launching company extract dialog
 */
export class ShowCompanyExtractDialogService {

	constructor(private dialogService: DialogService) {

	}


	/**
	 * Launches the extract dialog for assigned DbsCompany
	 */
	public async show(company: DbsCompany): Promise<any | undefined> {

		const data: DialogData<any> = { data: { company } };
		const action: DialogAction<any> = await this.dialogService.showCustom(CompanyExtractDialog, data, 800, 500);

		return undefined;
	}

}