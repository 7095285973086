<div class='application-delete-dialog'>
	<div mat-dialog-title class='highlight-section'>
		<h6>Are you sure?</h6>
		<button class="alert-close" type="button" [attr.aria-label]="'alert_close'" (click)="onNoClick()">
			<span aria-hidden="true">
				X
			</span>
		</button>
	</div>

	<div mat-dialog-content>
		<p><strong>Do not delete an application</strong> unless you really understand what you are doing.  This cannot be undone!</p>
		<p>Applications that were started but not completed should be canceled, not deleted, so we maintain history by person.
		If you are not sure whether to cancel or delete, contact the Director of Programs for guidance.
		</p>
	</div>
	<div mat-dialog-actions class='highlight-section'>
		<button-container>
			<button class='btn btn-green' (click)='action("cancel")'>
				<div style='display: inline-block'>
					<div>Cancel Application</div>
					<small>Keep history</small>
				</div>
			</button>
			<button class='btn btn-red' (click)='action("remove")'>
				<div style='display: inline-block'>
					<div>Permanently Delete</div>
					<small>Can't be undone</small>
				</div>
			</button>
		</button-container>
	</div>
</div>