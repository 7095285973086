import { DbConceptName, DbdPicStage, Dbs, ExplorablePackage, Immutable, SiteProgram } from "@me-interfaces";


interface MutableDbsPitchContest extends Dbs {

	readonly _concept: DbConceptName.PitchContest,

	readonly picId: number,
	readonly picStageId: number,
	readonly siteProgramId: number,
	readonly directorId: number,
	readonly managerId: number,
	readonly name: string,
	readonly longName: string,
	readonly applicantsQuestionsId: number,
	readonly readerQuestionsId: number,
	readonly judgeQuestionsId: number,
	readonly createdUTC: number,
	readonly applStartUTC: number,
	readonly applDuration: number,
	readonly updatedUTC: number,
	readonly updatedByPersonId: number,

	/*
	* additional column on acc
	*/
	readonly siteId: number,
}


interface MutablePitchContest extends MutableDbsPitchContest {
	directorName: string,
	managerName: string,
	siteProgram: SiteProgram,
	updatedByPersonName: string,
	stage: DbdPicStage,
}


export interface DbsPitchContest extends Immutable<MutableDbsPitchContest> { }
export interface PitchContest extends ExplorablePackage<DbConceptName.PitchContest>, Immutable<MutablePitchContest> { }