<readonly-access *ngIf='readonly'></readonly-access>

<div class='open-volunteer-application' *ngIf='vm'>
	<div class="card-columns">
		<fieldset>
			<legend>Applicant</legend>
			<div class='applicant-rows'>
				<button class='btn' (click)='explorePerson()'>
					<me-icon [icon]='vm.icons.person'></me-icon>
				</button>

				<table class="table table-sm">
					<tbody>
						<tr *ngIf='vm.application.title'>
							<td>Title</td>
							<td>{{vm.application.title}}</td>
						</tr>
						<tr *ngIf='vm.application.company'>
							<td>Company</td>
							<td>{{vm.application.company}}</td>
						</tr>
						<tr>
							<td>Referred By</td>
							<td>{{vm.application.foundOutHow}}</td>
						</tr>
						<tr>
							<td>Applied On</td>
							<td>{{getDate(vm.application.createdUTC)}}
								({{getDateInDays(vm.application.createdUTC)}} ago)
							</td>
						</tr>
						<tr>
							<td>Applied For</td>
							<td>
								<ul>
									<li *ngIf='vm.application.applyMentor'>Mentor</li>
									<li *ngIf='vm.application.applyReader'>Reader</li>
									<li *ngIf='vm.application.applyInterviewer'>Interviewer</li>
									<li *ngIf='vm.application.applyEventhelper'>Event Volunteer</li>
									<li
										*ngIf='vm.application.applyClassSpeaker || vm.application.applyOfficeHours || vm.application.applyWorkshops'>
										Specialist
										<ul>
											<li *ngIf='vm.application.applyClassSpeaker'>Class
												Speaker</li>
											<li *ngIf='vm.application.applyOfficeHours'>Office Hours
											</li>
											<li *ngIf='vm.application.applyWorkshops'>Workshops</li>
										</ul>
									</li>
								</ul>
							</td>
						</tr>
						<tr>
							<td>Speaks</td>
							<td>{{vm.language ?? 'None'}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</fieldset>

		<fieldset>
			<legend>Bio</legend>
			<div *ngIf='vm.bioEN'><strong>English:</strong> {{vm.bioEN}}</div>
			<div *ngIf='vm.bioES'><strong>Spanish:</strong> {{vm.bioES}}</div>
			<div *ngIf='!vm.bioES && !vm.bioEN'><i>No bio provided.</i></div>
		</fieldset>

		<fieldset>
			<legend>Transfer</legend>
			<div *ngIf='!vm.language'>
				This application cannot be transfered as the applicant doesn't have any Language tags.
			</div>
			<ng-container *ngIf='vm.language'>
				This application can be transfered to a community that supports <b>{{vm.language}}</b>
				programming, which matches the language tags of the applicant.
				<mat-form-field class='transfer-to'>
					<mat-select placeholder="Transfer To" #selectTransferSiteId="matSelect"
						[disabled]='readonly || saving'>
						<mat-option *ngFor="let site of vm.sites" [value]="site.siteId">{{site.name}}</mat-option>
					</mat-select>
				</mat-form-field>

				<button-container>
					<spinner-button *ngIf='selectTransferSiteId.value' [disabled]='readonly || saving || transfering'
						[spinning]='transfering' (safeClick)='transfer(selectTransferSiteId.value)'>Transfer
					</spinner-button>
				</button-container>
			</ng-container>
		</fieldset>

		<fieldset>
			<legend>{{site.code}} Volunteer Tags</legend>
			<p>
				These fields are initialized with {{vm.applicant.firstName}}'s <i>current</i> tags.
			</p>
			<div class="assign-tags">
				<div class='tag-row'>
					<mat-form-field>
						<mat-select placeholder="Mentor" [(ngModel)]="vm.tags.mentor" [disabled]='readonly'>
							<mat-select-trigger *ngIf='vm.tags.mentor === "Approved"'>
								<me-tag [tag]='vm.displayTags.mentorApproved' [clickable]='false' [showPrefix]='false'>
								</me-tag>
							</mat-select-trigger>
							<mat-select-trigger *ngIf='vm.tags.mentor === "Consider"'>
								<me-tag [tag]='vm.displayTags.mentorConsider' [clickable]='false' [showPrefix]='false'>
								</me-tag>
							</mat-select-trigger>
							<mat-select-trigger *ngIf='vm.tags.mentor === "Not-Approved"'>
								<me-tag [tag]='vm.displayTags.mentorNotApproved' [clickable]='false'
									[showPrefix]='false'></me-tag>
							</mat-select-trigger>
							<mat-option value='Not-Approved'>
								<me-tag [tag]='vm.displayTags.mentorNotApproved' [clickable]='false'
									[showPrefix]='false'></me-tag>
							</mat-option>
							<mat-option value='Consider'>
								<me-tag [tag]='vm.displayTags.mentorConsider' [clickable]='false' [showPrefix]='false'>
								</me-tag>
							</mat-option>
							<mat-option value='Approved'>
								<me-tag [tag]='vm.displayTags.mentorApproved' [clickable]='false' [showPrefix]='false'>
								</me-tag>
							</mat-option>
						</mat-select>
					</mat-form-field>
					<button class="btn remove" [disabled]='!vm.tags.mentor' title='Remove Tag'
						(click)='removeTag(vm.tags, "mentor")'><i class="far fa-times"></i></button>
				</div>

				<div class='tag-row'>
					<mat-form-field>
						<mat-select placeholder="Reader" [(ngModel)]="vm.tags.reader" [disabled]='readonly'>
							<mat-select-trigger *ngIf='vm.tags.reader === "Approved"'>
								<me-tag [tag]='vm.displayTags.readerApproved' [clickable]='false' [showPrefix]='false'>
								</me-tag>
							</mat-select-trigger>
							<mat-select-trigger *ngIf='vm.tags.reader === "Consider"'>
								<me-tag [tag]='vm.displayTags.readerConsider' [clickable]='false' [showPrefix]='false'>
								</me-tag>
							</mat-select-trigger>
							<mat-select-trigger *ngIf='vm.tags.reader === "Not-Approved"'>
								<me-tag [tag]='vm.displayTags.readerNotApproved' [clickable]='false'
									[showPrefix]='false'></me-tag>
							</mat-select-trigger>
							<mat-option value='Not-Approved'>
								<me-tag [tag]='vm.displayTags.readerNotApproved' [clickable]='false'
									[showPrefix]='false'></me-tag>
							</mat-option>
							<mat-option value='Consider'>
								<me-tag [tag]='vm.displayTags.readerConsider' [clickable]='false' [showPrefix]='false'>
								</me-tag>
							</mat-option>
							<mat-option value='Approved'>
								<me-tag [tag]='vm.displayTags.readerApproved' [clickable]='false' [showPrefix]='false'>
								</me-tag>
							</mat-option>
						</mat-select>
					</mat-form-field>
					<button class="btn remove" [disabled]='!vm.tags.reader' title='Remove Tag'
						(click)='removeTag(vm.tags, "reader")'><i class="far fa-times"></i></button>
				</div>

				<div class='tag-row'>
					<mat-form-field>
						<mat-select placeholder="Interviewer" [(ngModel)]="vm.tags.interviewer" [disabled]='readonly'>
							<mat-select-trigger *ngIf='vm.tags.interviewer === "Approved"'>
								<me-tag [tag]='vm.displayTags.interviewerApproved' [clickable]='false'
									[showPrefix]='false'></me-tag>
							</mat-select-trigger>
							<mat-select-trigger *ngIf='vm.tags.interviewer === "Consider"'>
								<me-tag [tag]='vm.displayTags.interviewerConsider' [clickable]='false'
									[showPrefix]='false'></me-tag>
							</mat-select-trigger>
							<mat-select-trigger *ngIf='vm.tags.interviewer === "Not-Approved"'>
								<me-tag [tag]='vm.displayTags.interviewerNotApproved' [clickable]='false'
									[showPrefix]='false'></me-tag>
							</mat-select-trigger>
							<mat-option value='Not-Approved'>
								<me-tag [tag]='vm.displayTags.interviewerNotApproved' [clickable]='false'
									[showPrefix]='false'></me-tag>
							</mat-option>
							<mat-option value='Consider'>
								<me-tag [tag]='vm.displayTags.interviewerConsider' [clickable]='false'
									[showPrefix]='false'></me-tag>
							</mat-option>
							<mat-option value='Approved'>
								<me-tag [tag]='vm.displayTags.interviewerApproved' [clickable]='false'
									[showPrefix]='false'></me-tag>
							</mat-option>
						</mat-select>
					</mat-form-field>
					<button class="btn remove" [disabled]='!vm.tags.interviewer' title='Remove Tag'
						(click)='removeTag(vm.tags, "interviewer")'><i class="far fa-times"></i></button>
				</div>

				<div class='tag-row'>
					<mat-form-field>
						<mat-select placeholder="Specialist" [(ngModel)]="vm.tags.specialist" [disabled]='readonly'>
							<mat-select-trigger *ngIf='vm.tags.specialist === "Approved"'>
								<me-tag [tag]='vm.displayTags.specialistApproved' [clickable]='false'
									[showPrefix]='false'></me-tag>
							</mat-select-trigger>
							<mat-select-trigger *ngIf='vm.tags.specialist === "Consider"'>
								<me-tag [tag]='vm.displayTags.specialistConsider' [clickable]='false'
									[showPrefix]='false'></me-tag>
							</mat-select-trigger>
							<mat-select-trigger *ngIf='vm.tags.specialist === "Not-Approved"'>
								<me-tag [tag]='vm.displayTags.specialistNotApproved' [clickable]='false'
									[showPrefix]='false'></me-tag>
							</mat-select-trigger>
							<mat-option value='Not-Approved'>
								<me-tag [tag]='vm.displayTags.specialistNotApproved' [clickable]='false'
									[showPrefix]='false'></me-tag>
							</mat-option>
							<mat-option value='Consider'>
								<me-tag [tag]='vm.displayTags.specialistConsider' [clickable]='false'
									[showPrefix]='false'></me-tag>
							</mat-option>
							<mat-option value='Approved'>
								<me-tag [tag]='vm.displayTags.specialistApproved' [clickable]='false'
									[showPrefix]='false'></me-tag>
							</mat-option>
						</mat-select>
					</mat-form-field>
					<button class="btn remove" [disabled]='!vm.tags.specialist' title='Remove Tag'
						(click)='removeTag(vm.tags, "specialist")'><i class="far fa-times"></i></button>
				</div>

				<div class='tag-row'>
					<mat-form-field>
						<mat-select placeholder="Event Volunteer" [(ngModel)]="vm.tags.eventhelper"
							[disabled]='readonly'>
							<mat-select-trigger *ngIf='vm.tags.eventhelper === "Approved"'>
								<me-tag [tag]='vm.displayTags.eventhelperApproved' [clickable]='false'
									[showPrefix]='false'></me-tag>
							</mat-select-trigger>
							<mat-select-trigger *ngIf='vm.tags.eventhelper === "Consider"'>
								<me-tag [tag]='vm.displayTags.eventhelperConsider' [clickable]='false'
									[showPrefix]='false'></me-tag>
							</mat-select-trigger>
							<mat-select-trigger *ngIf='vm.tags.eventhelper === "Not-Approved"'>
								<me-tag [tag]='vm.displayTags.eventhelperNotApproved' [clickable]='false'
									[showPrefix]='false'></me-tag>
							</mat-select-trigger>
							<mat-option value='Not-Approved'>
								<me-tag [tag]='vm.displayTags.eventhelperNotApproved' [clickable]='false'
									[showPrefix]='false'></me-tag>
							</mat-option>
							<mat-option value='Consider'>
								<me-tag [tag]='vm.displayTags.eventhelperConsider' [clickable]='false'
									[showPrefix]='false'></me-tag>
							</mat-option>
							<mat-option value='Approved'>
								<me-tag [tag]='vm.displayTags.eventhelperApproved' [clickable]='false'
									[showPrefix]='false'></me-tag>
							</mat-option>
						</mat-select>
					</mat-form-field>
					<button class="btn remove" [disabled]='!vm.tags.eventhelper' title='Remove Tag'
						(click)='removeTag(vm.tags, "eventhelper")'><i class="far fa-times"></i></button>
				</div>

				<div class='tag-row'>
					<mat-form-field>
						<mat-select placeholder="Judge" [(ngModel)]="vm.tags.judge" [disabled]='readonly'>
							<mat-select-trigger *ngIf='vm.tags.judge === "Approved"'>
								<me-tag [tag]='vm.displayTags.judgeApproved' [clickable]='false' [showPrefix]='false'>
								</me-tag>
							</mat-select-trigger>
							<mat-select-trigger *ngIf='vm.tags.judge === "Consider"'>
								<me-tag [tag]='vm.displayTags.judgeConsider' [clickable]='false' [showPrefix]='false'>
								</me-tag>
							</mat-select-trigger>
							<mat-select-trigger *ngIf='vm.tags.judge === "Not-Approved"'>
								<me-tag [tag]='vm.displayTags.judgeNotApproved' [clickable]='false'
									[showPrefix]='false'></me-tag>
							</mat-select-trigger>
							<mat-option value='Not-Approved'>
								<me-tag [tag]='vm.displayTags.judgeNotApproved' [clickable]='false'
									[showPrefix]='false'></me-tag>
							</mat-option>
							<mat-option value='Consider'>
								<me-tag [tag]='vm.displayTags.judgeConsider' [clickable]='false' [showPrefix]='false'>
								</me-tag>
							</mat-option>
							<mat-option value='Approved'>
								<me-tag [tag]='vm.displayTags.judgeApproved' [clickable]='false' [showPrefix]='false'>
								</me-tag>
							</mat-option>
						</mat-select>
					</mat-form-field>
					<button class="btn remove" [disabled]='!vm.tags.judge' title='Remove Tag'
						(click)='removeTag(vm.tags, "judge")'><i class="far fa-times"></i></button>
				</div>
			</div>
			<button-container>
				<spinner-button [disabled]='readonly || saving || transfering ||isUnchanged()' (safeClick)='revert()'>
					Revert
				</spinner-button>
				<spinner-button [disabled]='readonly || saving || transfering ||isUnchanged()' [spinning]='saving'
					(safeClick)='save()'>Assign tags</spinner-button>
			</button-container>
		</fieldset>

	</div>
	<div class='volunteer-action-section'>
		<spinner-button (safeClick)='closeDialog.emit()'>Cancel</spinner-button>
		<spinner-button [disabled]='readonly || saving || transfering || !isUnchanged()' [spinning]='saving'
			(safeClick)='closeApplication()'>Close This Application</spinner-button>

	</div>
</div>