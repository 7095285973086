<div class='acc-teams-tab-part' *ngIf='accAreaService.accelerator.acc$ | async; let acc'>
	<!-- <acc-details-part [accelerator]="acc"></acc-details-part> -->

	<acc-cohort-counts-part></acc-cohort-counts-part>

	<!-- <div>
		Enabled Quarterly Goals:
		<ng-container *ngIf="accelerator.enabledQuarterlyGoals === 0"> No Quarterly Goals enabled </ng-container>
		<ng-container *ngIf="accelerator.enabledQuarterlyGoals !== 0">
			{{quarterlyEnabledStr}}
		</ng-container>

		<button class='btn' title='Edit Quarterly' (click)='openQuarterlyDialog()'>
			<i class='far fa-edit'></i>
		</button>
	</div> -->
</div>