
import { SimplePerson } from '@me-interfaces';
import { DdLanguage } from '../dd-language/dd-language';
import { DdObject } from '../dd-object';
import { DdProgramType } from '../dd-program-type/dd-program-type';
import { OldDomainDataService } from '../domain-data.service';
import { DdProgramRow } from './dd-program-row';

/**
 * @deprecated Use the DataService instead
 */
export class DdProgram extends DdObject<DdProgramRow> implements DdProgramRow {

	constructor(private dd: OldDomainDataService, data: DdProgramRow) {
		super(data);
	}

	public get programId(): number {
		return this._data.programId;
	}

	public get programTypeId(): number {
		return this._data.programTypeId;
	}

	public get programType(): DdProgramType {
		return this.dd.programTypes.getById(this.programTypeId);
	}

	public get name(): string {
		return this._data.name;
	}

	public get directorId(): number {
		return this._data.directorId;
	}

	public getDirector(): SimplePerson {
		return this.dd.getCachedPerson(this.directorId);
	}

	public get languageId(): number {
		return this._data.languageId;
	}

	public get language(): DdLanguage {
		return this.dd.languages.getById(this.languageId);
	}
}