import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DataService } from '@me-services/core/data';
import { DroplistItem, getIconContextForLanguage } from '@me-shared-parts/UI-common';

@Component({
	selector: 'site-program-language-selector',
	templateUrl: './SHR-PG_site-program-language-selector.part.html',
	styleUrls: ['./SHR-PG_site-program-language-selector.part.scss']
})
export class SiteProgramLanguageSelectorPart implements OnInit, OnChanges {

	@Input() siteId: number;
	@Input() programTypeId: number;
	@Input() selectedLanguageId: number;
	@Input() readonly: boolean;
	@Output() languageChanged: EventEmitter<{ id: number }> = new EventEmitter();

	selectedItem: DroplistItem;
	items: DroplistItem[];

	constructor(
		private ds: DataService,
	) {

	}

	public async ngOnInit() {
		if (!this.siteId) throw new Error('Missing required attribute: siteId');
		if (!this.programTypeId) throw new Error('Missing required attribute: programTypeId');
		this.initialize();
	}

	public ngOnChanges(changes: SimpleChanges) {
		const somethingChanged = ['siteId', 'programTypeId', 'selectedLanguageId']
			.map(p => changes[p] && !changes[p].isFirstChange())
			.some(p => p);

		if (somethingChanged) this.initialize()
	}

	/**
	 * Initialize the dropdownlist for the new input values
	 */
	private async initialize() {
		const { siteId, programTypeId, selectedLanguageId } = this;

		//
		// Build the language dropdown items by siteId and programTypeId
		//
		const allSitePrograms = await this.ds.admin.siteProgram.getAllPackagesAsArray();
		this.items = allSitePrograms
			.filter(sp => sp.siteId === this.siteId && sp.program.programTypeId === this.programTypeId)
			.map(sp => ({ uniqueId: sp.program.languageId, iconContext: getIconContextForLanguage(sp.program.languageId) }));

		if (selectedLanguageId) this.selectedItem = this.items.find(r => r.uniqueId === selectedLanguageId);
		else if (this.items.length === 1) {
			this.selectedItem = this.items[0]
		} else {
			this.selectedItem = this.items.find(r => r.iconContext.text === 'English')
		}
		this.languageChanged.emit({ id: this.selectedItem.uniqueId });
	}

	public valueChange(e: DroplistItem) {
		this.selectedItem = e;
		this.languageChanged.emit({ id: e.uniqueId });
	}
}
