import { LanguageId } from "@me-interfaces";
import { getIconContext } from "./get-icon-context";
import { Icon } from "./icon";
import { IconContext } from "./icon-context";
import { IconHideTextAt } from "./icon-hide-text-at";
import { IconStyle } from "./icon-style";


/**
 * Return the Icon value for a given languageId
 */
export function getIconForLanguage(languageId: LanguageId): Icon {

	if (languageId == LanguageId.English) return Icon.language_en;
	if (languageId == LanguageId.Spanish) return Icon.language_es;

	return Icon.status_missing;
}


export function getIconContextForLanguage(languageId: LanguageId, iconStyle?: IconStyle, hideText?: IconHideTextAt, text?: string, hasRedFlag?: boolean): IconContext {
	return getIconContext(getIconForLanguage(languageId), iconStyle, hideText, text, hasRedFlag);
}