import { AccApplicationContext, AccAreaService, AccOverviewPageService, ApplicationStatusFilter, AccOverviewPageTabId as TabId, AccOverviewPageViewId as ViewId } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AccStageId, AccTeam, Accelerator, Application, ApplicationStatusId, Event } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';


interface ApplicationsData {
	acc: Accelerator,
	applications: readonly Application[],
	accTeams: readonly AccTeam[],
	counts: { open: number, rejected: number, accepted: number },
}


@Component({
	selector: 'acc-applications-tab-part',
	templateUrl: './acc-applications-tab.part.html',
})
export class AccApplicationsTabPart extends DestroyablePart implements OnInit {

	public data$: Observable<ApplicationsData>;

	openStatuses = [ApplicationStatusId.AcceptPending, ApplicationStatusId.ReadPending, ApplicationStatusId.InterviewPending, ApplicationStatusId.SelectPending];

	accStageId = AccStageId.Accepting;

	constructor(
		public accAreaService: AccAreaService,
		public ds: DataService,
		public pageService: AccOverviewPageService,
	) {
		super();
	}

	ngOnInit(): void {
		super.initDestroyable();
		this.data$ = combineLatest([
			this.accAreaService.accelerator.acc$,
			this.accAreaService.applications.applications$,
			this.accAreaService.teams.teams$,
			this.accAreaService.accelerator.events$,
		]).pipe(map(data => this.buildRows(data[0], data[1], data[2], data[3])));
	}

	buildRows(
		acc: Accelerator,
		allApplications: readonly AccApplicationContext[],
		accTeams: readonly AccTeam[],
		events: readonly Event[],
	): ApplicationsData {

		if (!acc || !allApplications || !accTeams || !events) return undefined;
		const applications = allApplications.map(app => app.application);
		return {
			acc,
			accTeams,
			applications,
			counts: {
				open: applications.filter(app => this.openStatuses.includes(app.applicationStatusId)).length,
				rejected: applications.filter(app => (![...this.openStatuses, ApplicationStatusId.TeamPromoted].includes(app.applicationStatusId))).length,
				accepted: applications.filter(app => app.applicationStatusId == ApplicationStatusId.TeamPromoted).length,
			},
		};
	}

	changeGridFilter(filter: ApplicationStatusFilter) {
		this.pageService.selectTabAndView(TabId.Accelerator, ViewId.AllApplications);
		this.pageService.applicationsStatusFilter$.next(filter);
	}

}
