<div class="entity-notes-editor">
	<selectable-view *ngIf='notesSelectors' [selectorList]='notesSelectors' listTitle='Notes' [initialSelectorKey]='key'
		(selectorKeyChanged)='setSelectedKey($event)'>
		<div class='selector-content-section' *ngIf='key'>
			<ng-container>
				<div class='selector-editor-section'>
					<ng-container *ngFor='let note of notesSelectors'>
						<div *ngIf='key === note.key'>
							<selectable-view-header>
								<i *ngIf='note.icon' [class]='note.icon'></i>
								{{note.label}}
							</selectable-view-header>
							<entity-notes-overview *ngIf='note.key === "entity-notes-editor-key-overview"'
								[entityNotes]='entityNotes' (noteSelected)='setSelectedKey($event)'>
							</entity-notes-overview>
							<entity-notes-edit-note *ngIf='note.key !== "entity-notes-editor-key-overview"'
								[entity]='entity' [notesWrapper]='notesWrapper' [note]='note.entityNote'
								(notesChanged)='notesChanged($event)' (noteSelected)='setSelectedKey($event)'>
							</entity-notes-edit-note>
						</div>
					</ng-container>
				</div>
			</ng-container>
		</div>
	</selectable-view>
</div>