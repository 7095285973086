import { DdAccRow } from './dd-acc-row';
import { DdAcc } from './dd-acc';
import { DdObjects } from '../dd-objects';
import { OldDomainDataService } from '..';

/**
 * @deprecated Use Accelerator from the DataService instead
 */
export class DdAccs extends DdObjects<DdAccRow, DdAcc> {

	constructor(private dd: OldDomainDataService) {
		super('accId');
	}

	async loadData(hash: string, data: DdAccRow[]) {
		const objects = data.map(d => new DdAcc(this.dd, d));
		this.loadObjects(hash, objects);
	}
}