
/**
 * Flags that define levels of admin. These flags exist on the person table row.
 */
export interface Old_AdminFlags {
	isCrmUser: boolean,  //todo: Rename to canEditContactsDb
	isStaff: boolean,
	isEForAllAdmin: boolean,
	isTechAdmin: boolean,
	canEditDemographics: boolean,
}