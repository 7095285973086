export const enum Icon {

	/** Icon.undefined has numeric value 0 */
	undefined,

	accStage_setup,
	accStage_accepting,
	accStage_reading,
	accStage_interviewing,
	accStage_selectingCohort,
	accStage_matchingMentors,
	accStage_curriculum,
	accStage_selectingWinners,
	accStage_quarterlies,
	accStage_complete,

	action_add,
	action_calendar,
	action_call,
	action_clipboard,
	action_delete,
	action_down,
	action_edit,
	action_editNotes,
	/** @deprecated The action of exploring should use the concept icon of the thing being explored */
	action_explore,
	action_email,
	action_help,
	action_loading,
	action_open,
	action_map,
	action_menu,
	action_more,
	action_next,
	action_previous,
	action_search,
	action_sms,
	action_transfer,
	action_up,
	action_video,
	action_view,

	concept_accelerator,
	concept_application,
	concept_accTeam,
	concept_agreementType,
	concept_agreementSignature,
	concept_award,
	concept_company,
	concept_event,
	concept_national,
	concept_picTeam,
	concept_pitchContest,
	concept_person,
	concept_people,
	concept_position,
	concept_program,
	concept_region,
	concept_site,
	concept_venue,
	concept_zip,

	droplist_defaultSelectAnItem,
	droplist_pickOne,

	dialog_explore,

	empty,

	eventType_accFinalPitch,
	eventType_accInterviewing,
	eventType_accMentorMatching,
	eventType_accReading,
	eventType_picContest,
	eventType_picReading,
	eventType_accQuarterliesQ2,
	eventType_accQuarterliesQ3,
	eventType_accQuarterliesQ4,
	eventType_accShowcase,

	industry_administrativeSupport,
	industry_agriculture,
	industry_animals,
	industry_apparel_accessories,
	industry_arts_culture,
	industry_automotive,
	industry_beauty_cosmetics,
	industry_childcare_familyServices,
	industry_cleaning,
	industry_coaching_mentoring,
	industry_communitySupport,
	industry_creativeServices_multimedia,
	industry_education,
	industry_entertainment_recreation,
	industry_environment,
	industry_eventPlanning,
	industry_finance_accounting,
	industry_food_beverage,
	industry_health_wellness,
	industry_hospitality_accommodations,
	industry_lawncare,
	industry_legal,
	industry_manufacturing,
	industry_marketing,
	industry_medical,
	industry_other,
	industry_personal_relationships,
	industry_philanthropy,
	industry_photography_media,
	industry_propertyManagement,
	industry_retail,
	industry_salon_spa_personalCare,
	industry_technology,
	industry_trades_electrician_plumber,
	industry_travel_tourism,

	language_en,
	language_es,

	level_exact,
	level_high,
	level_medium,
	level_low,
	level_related,

	link_facebook,
	link_linkedIn,
	link_twitter,
	link_website,


	material_spreadsheet,
	material_slide,
	material_handout,
	material_pdf,
	material_doc,
	material_video,
	material_weblink,
	material_blog,

	notes_none,
	notes_redFlag,
	notes_noRedFlag,
	notes_some,

	status_check,
	status_deceased,
	status_doNotContact,
	status_error,
	status_missing,
	status_pending,
	status_selected,
	status_success,
	status_warning,
	status_x,

	tool_enabled,
	tool_disabled,

	view_applications,
	view_assessments,
	view_bios, //
	view_bulk_editor,
	view_config,
	view_demographics, //
	view_decide_awards,
	view_editor,
	view_entrepreneurs,
	view_event_past,
	view_event_future,
	view_event_current,
	view_events,
	view_feedback,
	view_goals,
	view_hints,
	view_interviewers,
	view_judges,
	view_list,
	view_members,
	view_mentors,
	view_mm_heatmap,
	view_mm_matcher,
	view_overview,
	view_programming, //
	view_readers,
	view_relationships, //
	view_report,
	view_sessions,
	view_surveys,
	view_tags, //
	view_teams,
	view_topics,
	view_volunteers,
}
