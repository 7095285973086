import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';


@Component({
	selector: 'national-reporting-looker-view-part',
	templateUrl: './national-reporting-looker-view.part.html',
	styleUrls: ['./national-reporting-looker-view.part.scss'],
})
export class NationalReportingLookerViewPart extends DestroyablePart implements OnInit {


	constructor(public util: UtilityService,
		public ds: DataService,
	) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();
	}

}