
import { DdAccMeetingTimesRow } from './dd-acc-meeting-times-row';
import { DdAccMeetingTimes } from './dd-acc-meeting-times';
import { DdObjects } from '../dd-objects';
import { OldDomainDataService } from '..';

/**
 * @deprecated Use AccMeetingTimes from the DataService instead
 */
export class DdAccMeetingTimess extends DdObjects<DdAccMeetingTimesRow, DdAccMeetingTimes> {

	constructor(private dd: OldDomainDataService) {
		super('accMeetingTimesId');
	}

	async loadData(hash: string, data: DdAccMeetingTimesRow[]) {
		const objects = data.map(d => new DdAccMeetingTimes(this.dd, d));
		this.loadObjects(hash, objects);
	}
}