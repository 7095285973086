
import { SimplePerson } from '@me-interfaces';
import { DdLanguage } from '../dd-language/dd-language';
import { DdObject } from '../dd-object';
import { DdSiteProgram } from '../dd-site_program/dd-site-program';
import { OldDomainDataService } from '../domain-data.service';
import { DdPicRow } from './dd-pic-row';

/**
 * @deprecated Use PitchContest from the DataService instead
 */
export class DdPic extends DdObject<DdPicRow> implements DdPicRow {

	constructor(private dd: OldDomainDataService, data: DdPicRow) {
		super(data);
	}

	public get picId(): number {
		return this._data.picId;
	}

	public get picStageId(): number {
		return this._data.picStageId;
	}

	public setPicStageId(stageId: number) {
		this._data.picStageId = stageId;
	}

	public get picStageName(): string {
		const picStage = this.dd.picStages.getById(this.picStageId);
		return picStage ? picStage.name : '';
	}

	public get siteProgramId(): number {
		return this._data.siteProgramId;
	}

	public get siteProgram(): DdSiteProgram {
		return this.dd.sitePrograms.getById(this.siteProgramId);
	}

	public get languageId(): number {
		return this.siteProgram.program.languageId;
	}

	public get language(): DdLanguage {
		return this.dd.languages.getById(this.siteProgram.program.languageId);
	}

	public get directorId(): number {
		return this._data.directorId;
	}

	public getDirector(): SimplePerson {
		return this.dd.getCachedPerson(this.directorId);
	}

	public get managerId(): number {
		return this._data.managerId;
	}

	public getManager(): SimplePerson {
		return this.dd.getCachedPerson(this.managerId);
	}

	public get name(): string {
		return this._data.name;
	}

	public get longName(): string {
		return this._data.longName;
	}

	public get createdUTC(): number {
		return this._data.createdUTC;
	}

	public get applStartUTC(): number {
		return this._data.applStartUTC;
	}

	public get applDuration(): number {
		return this._data.applDuration;
	}

}