import { EventEmitter } from "@angular/core";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { Icon } from "@me-shared-parts/UI-common/icon";
import { AccSurveyResponseSpecialistCols, buildColumn, GridColumnConfig } from '../columns';
import { GRID_EXPERIENCE, GridSetup } from "../interfaces";
import { GridExperience } from "./grid-experience";


/**
 * Standard experience for a grid of acc survey responses for specialist
 */
export class AccSurveyResponseSpecialistGridExperience<ROW> extends GridExperience<ROW> {

	public static readonly experience: GRID_EXPERIENCE = 'ACC_SURVEY_RESPONSE_SPECIALIST';

	constructor(
		private ds: DataService,
		private util: UtilityService,
		setup: GridSetup<ROW>,
		gridAction: EventEmitter<{ actionKey: string; rows: ROW[]; }>,
	) {
		super(
			util, setup,
			[
				buildColumn(AccSurveyResponseSpecialistCols.accSessionSpecialistSurveyResponseId, GridColumnConfig.id, { hidden: true, header: 'accSpecialistSurveyResponseId' }),
				buildColumn(AccSurveyResponseSpecialistCols.accSessionAttendeeId, GridColumnConfig.id, { hidden: true, header: 'accSessionAttendeeId' }),
				buildColumn(AccSurveyResponseSpecialistCols.specialistPersonId, GridColumnConfig.personId, { hidden: true, header: 'specialistPersonId', headerTooltip: 'The personId of the accelerator program manager' }),
				buildColumn(AccSurveyResponseSpecialistCols.topicId, GridColumnConfig.id, { hidden: true, header: 'topicId' }),
				buildColumn(AccSurveyResponseSpecialistCols.siteId, GridColumnConfig.siteId, { hidden: true }),
				buildColumn(AccSurveyResponseSpecialistCols.accId, GridColumnConfig.accId, { hidden: true }),
				buildColumn(AccSurveyResponseSpecialistCols.eventId, GridColumnConfig.eventId, { hidden: true }),
				buildColumn(AccSurveyResponseSpecialistCols.programManagerId, GridColumnConfig.personId, { hidden: true, header: 'programManagerId', headerTooltip: 'The personId of the accelerator program manager' }),
			],
			[
				buildColumn(AccSurveyResponseSpecialistCols.stateCode, GridColumnConfig.stateCode, { hidden: true }),
				buildColumn(AccSurveyResponseSpecialistCols.siteCode, GridColumnConfig.siteCode, { hidden: true }),
				buildColumn(AccSurveyResponseSpecialistCols.siteName, GridColumnConfig.siteName, { hidden: true }),
				buildColumn(AccSurveyResponseSpecialistCols.accYear, GridColumnConfig.year, { hidden: true }),
				buildColumn(AccSurveyResponseSpecialistCols.accSeason, GridColumnConfig.accSeason, { hidden: true }),
				buildColumn(AccSurveyResponseSpecialistCols.accName, GridColumnConfig.accName, { hidden: true }),
				buildColumn(AccSurveyResponseSpecialistCols.session, GridColumnConfig.text, { hidden: true, header: 'Session', width: 120 }),
				buildColumn(AccSurveyResponseSpecialistCols.venue, GridColumnConfig.venue, { hidden: true }),
				buildColumn(AccSurveyResponseSpecialistCols.sessionTopics, GridColumnConfig.text, { hidden: true, header: 'Session Topics', width: 120 }),
				buildColumn(AccSurveyResponseSpecialistCols.topic, GridColumnConfig.text, { header: 'Topic', width: 120 }),
				buildColumn(AccSurveyResponseSpecialistCols.specialistName, GridColumnConfig.personFullName, { header: 'Specialist', width: 120 }),
			],
			[
				buildColumn(AccSurveyResponseSpecialistCols.professionalismBucket, GridColumnConfig.text, { hidden: false, header: 'Professionalism', headerTooltip: 'Professionalism: "The specialist was professional."', width: 80 }),
				buildColumn(AccSurveyResponseSpecialistCols.professionalism, GridColumnConfig.number, { hidden: true, header: 'Professionalism Rating', headerTooltip: 'Professionalism: "TThe specialist was professional. (0..10)"', width: 80 }),
				buildColumn(AccSurveyResponseSpecialistCols.knowledgeBucket, GridColumnConfig.text, { hidden: false, header: 'Knowledge', headerTooltip: 'Knowledge: "The specialist was knowledgeable about this topic."', width: 80 }),
				buildColumn(AccSurveyResponseSpecialistCols.knowledge, GridColumnConfig.number, { hidden: true, header: 'Knowledge Rating', headerTooltip: 'Knowledge: "The specialist was knowledgeable about this topic. (0..10)"', width: 80 }),
				buildColumn(AccSurveyResponseSpecialistCols.effectivenessBucket, GridColumnConfig.text, { hidden: false, header: 'Effectiveness', headerTooltip: 'Effectiveness: "The specialist effectively taught the topic."', width: 80 }),
				buildColumn(AccSurveyResponseSpecialistCols.effectiveness, GridColumnConfig.number, { hidden: true, header: 'Effectiveness Rating', headerTooltip: 'Effectiveness: "The specialist effectively taught the topic. (0..10)"', width: 80 }),
				buildColumn(AccSurveyResponseSpecialistCols.other, GridColumnConfig.text, { hidden: true, header: 'Comment', headerTooltip: 'Other Feedback (optional)' }),
				buildColumn(AccSurveyResponseSpecialistCols.createdUTC, GridColumnConfig.createdUtc, { hidden: true }),
			],
			[
				// { key: EXPLORE_GRID_ACTION_KEY, icon: Icon.dialog_explore, label: `Explore ${setup.rowSingularName}` },
				// { key: OPEN_GRID_ACTION_KEY, icon: Icon.action_open, label: `Open ${setup.rowSingularName}` },
				// { key: NOTES_GRID_ACTION_KEY, icon: Icon.action_editNotes, label: `Edit Survey Notes` },
			],
			gridAction,
			Icon.view_surveys,
		);
	}


	public async applyBaseValues(rows: ROW[]) {

		const accSessionSpecialistSurveyResponseIds = rows.map(row => <number>row['accSessionSpecialistSurveyResponseId']);
		const accSessionSpecialistSurveyResponseMap = await this.ds.admin.accSessionSpecialistSurveyResponse.getManyPackagesAsMap(accSessionSpecialistSurveyResponseIds);

		for (const row of rows) {

			const accSessionSpecialistSurveyResponseId = <number>row['accSessionSpecialistSurveyResponseId'];
			const response = accSessionSpecialistSurveyResponseMap[accSessionSpecialistSurveyResponseId];


			row[AccSurveyResponseSpecialistCols.accSessionSpecialistSurveyResponseId] = accSessionSpecialistSurveyResponseId;
			row[AccSurveyResponseSpecialistCols.accSessionAttendeeId] = response.accSessionAttendeeId;
			row[AccSurveyResponseSpecialistCols.topicId] = response.topicId;
			row[AccSurveyResponseSpecialistCols.siteId] = response.accelerator.siteProgram.siteId;
			row[AccSurveyResponseSpecialistCols.accId] = response.accId;
			row[AccSurveyResponseSpecialistCols.eventId] = response.eventId;
			row[AccSurveyResponseSpecialistCols.programManagerId] = response.accelerator.managerId;
			row[AccSurveyResponseSpecialistCols.specialistPersonId] = response.personId;

			row[AccSurveyResponseSpecialistCols.session] = this.util.date.formatUTC(response.event.startUTC, 'MMM D, YYYY (DOW)', 'H:MM AM EST', this.ds.languageId);
			row[AccSurveyResponseSpecialistCols.venue] = response.event.venueId ? response.event.venue?.displayedName || 'Online' : 'Online';
			row[AccSurveyResponseSpecialistCols.sessionTopics] = response.event.name;
			row[AccSurveyResponseSpecialistCols.specialistName] = response.person.fullName;
			row[AccSurveyResponseSpecialistCols.topic] = response.topic.longNameLabel;
			row[AccSurveyResponseSpecialistCols.professionalismBucket] = this.util.nps.getBucket(response.professionalism);
			row[AccSurveyResponseSpecialistCols.professionalism] = response.professionalism;
			row[AccSurveyResponseSpecialistCols.knowledgeBucket] = this.util.nps.getBucket(response.knowledge);
			row[AccSurveyResponseSpecialistCols.knowledge] = response.knowledge;
			row[AccSurveyResponseSpecialistCols.effectivenessBucket] = this.util.nps.getBucket(response.effectiveness);
			row[AccSurveyResponseSpecialistCols.effectiveness] = response.effectiveness;
			row[AccSurveyResponseSpecialistCols.other] = response.other;

			row[AccSurveyResponseSpecialistCols.stateCode] = response.accelerator.siteProgram.site.stateCode;
			row[AccSurveyResponseSpecialistCols.siteCode] = response.accelerator.siteProgram.site.code6;
			row[AccSurveyResponseSpecialistCols.siteName] = response.accelerator.siteProgram.site.name;
			row[AccSurveyResponseSpecialistCols.accName] = response.accelerator.name;
			row[AccSurveyResponseSpecialistCols.accYear] = response.accelerator.year;
			row[AccSurveyResponseSpecialistCols.accSeason] = response.accelerator.season;
			row[AccSurveyResponseSpecialistCols.createdUTC] = response.createdUTC;

		}
	}


	public sortRows(rows: ROW[]) {
		// rows.sort((row1: ROW, row2: ROW) => {
		// 	const val1 = row1[AccTeamCols.companyName];
		// 	const val2 = row2[AccTeamCols.companyName];
		// 	return val1 < val2 ? -1 : 1;
		// });
		return 0;
	}


	public async explore(row: ROW) {
		// await this.ds.explorer.explore(DbConceptName.AccTeam, row[AccTeamCols.accTeamId]);
	}


	public async open(row: ROW) {
		// this.router.navigate([`access/admin/national/sites/${row['siteId']}/accelerators/${row['accId']}/cohort/${row[AccTeamCols.accTeamId]}`]);
	}


	public async editNotes(row: ROW) {
		// Notes dialog not implemented
	}
}