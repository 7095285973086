import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { ACC_APPLICATION_STATUS, Application, PIC_APPLICATION_STATUS } from '@me-interfaces';
import { UtilityService } from '@me-services/core/utility';
import { UrlService } from '@me-services/ui/url';
import { getIconContext, Icon } from '@me-shared-parts/UI-common';
import { getIconBarSelections, IconBarIcon } from '@me-shared-parts/UI-common/icon-bar';
import { IconBarPart, IconBarSelections } from '@me-shared-parts/UI-common/icon-bar/SHR-CMN_icon-bar.part';
import { DataService } from '../../../data.service';
import { ExplorerBox } from '../../explorer-box/explorer-box';
import { ExplorerNoteBox } from '../../explorer-note-box/explorer-note-box';
import { LinkOrExplorable } from '../../link-or-explorerable';
import { getBoxesForParticipants } from './boxes/get-boxes-for-participants';
import { getBoxesForRelatedApplications } from './boxes/get-boxes-for-related-applications';
import { getBoxesForRelatedNotes } from './boxes/get-boxes-for-related-notes';
import { getButtons } from './get-buttons';
import { views, VIEW_DETAILS, VIEW_PARTICIPANTS, VIEW_QUESTIONS, VIEW_RELATED_APPLICATIONS, VIEW_RELATED_NOTES } from './views';

const STORAGE_KEY = 'ApplicationExplorerIconSelection';

@Component({
	selector: 'application-explorer',
	templateUrl: './application-explorer.part.html',
	styleUrls: ['./application-explorer.part.scss'],
})
export class ApplicationExplorerPart implements OnChanges {

	@Input() public application: Application;
	@ViewChild('iconBar') iconBar: IconBarPart;

	public views = views;
	public icons = {
		redFlag: getIconContext(Icon.notes_redFlag),
		application: getIconContext(Icon.concept_application),
	};


	public selectedViews: IconBarSelections = undefined;
	public buttons: LinkOrExplorable[] = [];
	public applicationStatus: string;

	public participantBoxes: ExplorerBox[] = [];
	public relatedApplicationBoxes: ExplorerBox[] = [];
	public relatedNoteBoxes: ExplorerNoteBox[] = [];

	hasRelatedProgramAppl: boolean;

	subHeader: string;

	constructor(
		public util: UtilityService,
		private ds: DataService,
		private urlService: UrlService
	) { }


	async ngOnChanges() {
		if (!this.application.applicationStatusId) throw new Error(`Missing required attribute: application`);
		this.icons.application.text = this.application.name;
		await this.createContent();
		this.views = this.updateIconBar();
	}

	/**
	 * CreateContent is called once when a new accelerator is passed in.  All content is created but
	 * may be shown or hidden using css instead of rebuilding everything again.
	 */
	private async createContent() {
		if (this.application.accId) this.subHeader = 'Accelerator Application';
		else if (this.application.picId) this.subHeader = 'Pitch Contest Application';

		this.applicationStatus = this.getApplicationStatus(this.application);
		this.buttons = await getButtons(this.ds, this.application);
		this.participantBoxes = await getBoxesForParticipants(this.ds, this.application);
		const { boxes, hasRelatedProgramAppl } = await getBoxesForRelatedApplications(this.util, this.ds, this.application);
		this.relatedApplicationBoxes = boxes;
		this.hasRelatedProgramAppl = hasRelatedProgramAppl;
		this.relatedNoteBoxes = await getBoxesForRelatedNotes(this.util, this.ds, this.application);

		// showMessageLine = this.relatedApplicationBoxes.find()
	}

	private getApplicationStatus(application: Application): string {
		if (this.application.accId) return ACC_APPLICATION_STATUS[application.applicationStatusId];
		if (this.application.picId) return PIC_APPLICATION_STATUS[application.applicationStatusId];
		return '';
	}

	private updateIconBar(): IconBarIcon[] {

		//
		// Get selections from last time this explorer was accessed. If there
		// isn't on,then build the selections from the initial views in views.ts.
		//
		const selectedViewsStr = sessionStorage.getItem(STORAGE_KEY);

		if (selectedViewsStr) {
			this.selectedViews = JSON.parse(selectedViewsStr);
		}
		else {
			this.selectedViews = getIconBarSelections(views);
			sessionStorage.setItem(STORAGE_KEY, JSON.stringify(this.selectedViews));
		}

		//
		// Create a new array of icon views using the initial set but overriding
		// whether disabled or not and the tooltip text based on the created content.
		//
		return views.map(view => {

			if (view.key == VIEW_DETAILS) {
				view.disabled = false;
				view.selected = this.selectedViews.map[VIEW_DETAILS];
			}
			else if (view.key == VIEW_RELATED_NOTES) {
				const count = this.relatedNoteBoxes.length;
				view.disabled = !count;
				view.selected = this.selectedViews.map[VIEW_RELATED_NOTES];
				view.tooltip = this.util.text.singularOrPluralWithCount(count, 'Related Note', 'Related Notes');
			}
			else if (view.key == VIEW_QUESTIONS) {
				view.disabled = false;
				view.selected = this.selectedViews.map[VIEW_QUESTIONS];
			}
			else if (view.key == VIEW_PARTICIPANTS) {
				const count = this.participantBoxes.length;
				view.disabled = !count;
				view.selected = this.selectedViews.map[VIEW_PARTICIPANTS];
				view.tooltip = this.util.text.singularOrPluralWithCount(count, 'Participant', 'Participants');
			}
			else if (view.key == VIEW_RELATED_APPLICATIONS) {
				const count = this.relatedApplicationBoxes.length;
				view.disabled = !count;
				view.selected = this.selectedViews.map[VIEW_RELATED_APPLICATIONS];
				view.tooltip = this.util.text.singularOrPluralWithCount(count, 'Related Application', 'Related Applications');
			}

			return view;
		});


	}


	public async setViews(selectedViews: IconBarSelections) {
		if (!selectedViews) return;
		this.selectedViews = selectedViews;
		sessionStorage.setItem(STORAGE_KEY, JSON.stringify(selectedViews));

		for (const view of this.views) {
			view.selected = !!selectedViews.map[view.key];
		}
	}


	public getDisplay(key: string) {
		const view = this.views.find(view => view.key == key);
		if (view == undefined) return 'none';
		return !!view.disabled || !view.selected ? 'none' : 'block';
	}


	public click(action: 'red-flag') {
		if (this.iconBar) {
			if (action == 'red-flag') this.iconBar.selectOnlyOne(VIEW_RELATED_NOTES);
		}
	}


	public tabClose(header: string) {
		if (header.endsWith('Details')) this.unselect(VIEW_DETAILS);
		if (header.endsWith('Participants')) this.unselect(VIEW_PARTICIPANTS);
	}


	private unselect(key: string) {

		this.iconBar.unselect(key);

		const view = this.views.find(view => view.key == key);
		if (view) view.selected = false;
	}
}