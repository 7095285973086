import { AccAreaService, AccPreAcceleratorPageService, MatchingStatusFilter, AccPreAcceleratorTabId as TabId, AccPreAcceleratorViewId as ViewId } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AccMatchableMentor, Accelerator } from '@me-interfaces';
import { DataService } from '@me-services/core/data';


interface CountsData {
	uniqueMentorsCount: number,
	mentorPersonIds: number[],
	useCount: number,
	canCoachCount: number,
	canCoachPersonIds: number[],
	multiUseCount: number,
	multiUseMentorPersonIds: number[],
}


@Component({
	selector: 'acc-match-item-counts-part',
	templateUrl: './acc-match-item-counts.part.html',
	styleUrls: ['./acc-match-item-counts.part.scss'],
})
export class AccMatchItemCountsPart extends DestroyablePart implements OnInit {

	readonly: boolean;
	public countsData: CountsData;

	constructor(
		private accAreaService: AccAreaService,
		private ds: DataService,
		public pageService: AccPreAcceleratorPageService,
	) {
		super();
	}

	ngOnInit(): void {
		super.initDestroyable();

		super.subscribe([this.accAreaService.accessAndId$], async ([access]) => {
			this.readonly = access?.access?.root !== "Write";
		});

		super.subscribe([this.accAreaService.accelerator.acc$, this.accAreaService.mentorMatching.matchableMentors$], async ([acc, mentors]) => await this.buildData(acc, mentors));
	}

	setViewAndFilterGrid(filter: MatchingStatusFilter) {
		this.pageService.selectTabAndView(TabId.Manage, ViewId.MMMentors);
		this.pageService.matchingStatusFilter$.next(filter);
	}


	async buildData(acc: Accelerator, mentors: readonly AccMatchableMentor[]): Promise<void> {

		const siteId = acc?.siteProgram?.siteId;

		const mentorPersonIds: number[] = mentors?.map(m => m.personId);
		const multiUseMentorPersonIds: number[] = mentors?.reduce((a, m) => {
			if (m.useCount > 1) a.push(m.personId);
			return a;
		}, []);
		const canCoachPersonIds: number[] = mentors?.reduce((a, m) => {
			if (!!m.person.tags?.find(t => t.fullName.includes('coach-approved') && t.siteId == siteId)) a.push(m.personId);
			return a;
		}, []);

		this.countsData = {
			uniqueMentorsCount: mentors?.length,
			mentorPersonIds: mentorPersonIds,
			useCount: mentors?.reduce((a, m) => a += m.useCount, 0),
			canCoachCount: canCoachPersonIds?.length,
			canCoachPersonIds: canCoachPersonIds,
			multiUseCount: multiUseMentorPersonIds?.length,
			multiUseMentorPersonIds: multiUseMentorPersonIds,
		};

	}


	async openContactor(personIds: number[]) {
		await this.ds.contactor.show(
			this.readonly,
			personIds,
			AccMatchItemCountsPart.name,
		);
	}
}