<div class='application-participants-part'>

	<p>
		<me-label [key]='participantInstructionsKey'></me-label>
	</p>
	<me-label key='md:Application-Tell us about Your Team'></me-label>
	
	<div style='margin: 2em 0 0; max-width: 800px;'>

		<me-h2>
			<me-label key='Participants'></me-label>
		</me-h2>

		<me-grid [setup]='gridSetup' [rows]='rows' (gridAction)='gridActionHandler($event)'></me-grid>

		<div class="error-text" *ngIf='!allParticipantsComplete'>
			<i class="fas fa-exclamation-triangle"></i>
			<me-label key='All Participants must be Complete'></me-label>
		</div>
	</div>

	<div style='margin-top: 3em;'>
		<field-grid [fields]='application.team.fieldsAndValues.fields' [state]='teamState' [tool]='tool'
			[values]='application.team.fieldsAndValues.values' [forceValidation]='forceValidation'>
		</field-grid>
	</div>
</div>