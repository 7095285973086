import { EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { DbConceptName } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { Icon } from "@me-shared-parts/UI-common";
import { buildColumn, GridColumnConfig, RegionCols } from "../columns";
import { EXPLORE_GRID_ACTION_KEY, GridSetup, GRID_EXPERIENCE, OPEN_GRID_ACTION_KEY } from "../interfaces";
import { GridExperience } from "./grid-experience";
import { getSiteCounts, SiteCounts } from "./site-counts/get-site-counts";


/**
 * Standard experience for a grid of regions
 */
export class RegionGridExperience<ROW> extends GridExperience<ROW> {

	public static readonly experience: GRID_EXPERIENCE = 'REGION';

	constructor(
		private ds: DataService,
		private util: UtilityService,
		setup: GridSetup<ROW>,
		gridAction: EventEmitter<{ actionKey: string; rows: ROW[]; }>,
		private router: Router,
	) {
		super(
			util, setup,
			[
				buildColumn(RegionCols.regionId, GridColumnConfig.regionId, { hidden: true }),
			],
			[
				buildColumn(RegionCols.name, GridColumnConfig.regionName),
				buildColumn(RegionCols.director, GridColumnConfig.personFullName, { header: 'Director' }),
				buildColumn(RegionCols.directorEmail, GridColumnConfig.email, { hidden: true }),
				buildColumn(RegionCols.directorPhone, GridColumnConfig.phone, { hidden: true }),
			],
			[
				buildColumn(RegionCols.sites, GridColumnConfig.sitesCount),
				buildColumn(RegionCols.siteCodes, GridColumnConfig.siteCodes, { hidden: true }),
				buildColumn(RegionCols.staff, GridColumnConfig.staffCount, { hidden: true }),
				buildColumn(RegionCols.accs, GridColumnConfig.accsCount),
				buildColumn(RegionCols.accAlumni, GridColumnConfig.accAlumniCount),
				buildColumn(RegionCols.pics, GridColumnConfig.picsCount),
				buildColumn(RegionCols.picParticipants, GridColumnConfig.picParticipantsCount),
				buildColumn(RegionCols.awardees, GridColumnConfig.awardeesCount),
				buildColumn(RegionCols.awards, GridColumnConfig.awardsDollars),
				buildColumn(RegionCols.accAwardees, GridColumnConfig.accAwardeesCount, { hidden: true }),
				buildColumn(RegionCols.accAwards, GridColumnConfig.accAwardsDollars, { hidden: true }),
				buildColumn(RegionCols.picAwardees, GridColumnConfig.picAwardeesCount, { hidden: true }),
				buildColumn(RegionCols.picAwards, GridColumnConfig.picAwardsDollars, { hidden: true }),
			],
			[
				{ key: EXPLORE_GRID_ACTION_KEY, icon: Icon.dialog_explore, label: `Explore ${setup.rowSingularName}` },
				{ key: OPEN_GRID_ACTION_KEY, icon: Icon.action_open, label: `Open ${setup.rowSingularName}` },
				// { key: NOTES_GRID_ACTION_KEY, icon: Icon.action_editNotes, label: `Edit Region Notes` },
			],
			gridAction,
			Icon.concept_pitchContest,
		);
	}


	public async applyBaseValues(rows: ROW[]) {

		const regionIds: number[] = rows.map(row => row['regionId']);
		const directorIds: number[] = rows.map(row => row['directorId']);

		const regionMap = await this.ds.admin.region.getManyPackagesAsMap(regionIds);
		const directorMap = await this.ds.admin.person.getManyPackagesAsMap(directorIds);
		const countsBySiteId = await getSiteCounts(this.util, this.ds);
		const sitesByRegionId = await this.ds.admin.site.getByRegionIds(regionIds);

		for (const row of rows) {

			const regionId = row['regionId'];
			const region = regionMap[regionId];
			const director = directorMap[region.directorId];
			const sites = sitesByRegionId[region.regionId];
			const siteIds = sites.map(site => site.siteId);
			const counts = this.getRegionCounts(regionId, siteIds, countsBySiteId);

			row[RegionCols.regionId] = region.regionId;
			row[RegionCols.name] = region.name;
			row[RegionCols.director] = region.directorName;
			row[RegionCols.directorEmail] = director?.emails.find(e => e.isDefault)?.email;
			row[RegionCols.directorPhone] = director?.asSingleton.phone;
			row[RegionCols.sites] = sites.length;
			row[RegionCols.siteCodes] = sites.map(site => site.code6).join(' / ');
			row[RegionCols.staff] = counts.staff;
			row[RegionCols.accs] = counts.accs;
			row[RegionCols.accAlumni] = counts.accAlumni;
			row[RegionCols.pics] = counts.pics;
			row[RegionCols.picParticipants] = counts.picParticipants;
			row[RegionCols.awardees] = counts.accAwardees + counts.picAwardees;
			row[RegionCols.awards] = counts.accAwards + counts.picAwards;
			row[RegionCols.accAwardees] = counts.accAwardees;
			row[RegionCols.accAwards] = counts.accAwards;
			row[RegionCols.picAwardees] = counts.picAwardees;
			row[RegionCols.picAwards] = counts.picAwards;
		}
	}


	/**
	 * Sum up all the site count values into one SiteCounts object that represents the entire region
	 */
	getRegionCounts(
		regionId: number,
		siteIds: number[],
		countsBySiteId: Record<number, SiteCounts>,
	): SiteCounts {

		const counts: SiteCounts = {
			siteId: 0,	// This will stay as 0
			regionId,
			staff: 0,
			accs: 0,
			accAlumni: 0,
			pics: 0,
			picParticipants: 0,
			accAwardees: 0,
			accAwards: 0,
			picAwardees: 0,
			picAwards: 0,
		};

		for (const siteId of siteIds) {
			const c = countsBySiteId[siteId];
			if (!c) continue;
			counts.staff = counts.staff + c.staff;
			counts.accs = counts.accs + c.accs;
			counts.accAlumni = counts.accAlumni + c.accAlumni;
			counts.pics = counts.pics + c.pics;
			counts.picParticipants = counts.picParticipants + c.picParticipants;
			counts.accAwardees = counts.accAwardees + c.accAwardees;
			counts.accAwards = counts.accAwards + c.accAwards;
			counts.picAwardees = counts.picAwardees + c.picAwardees;
			counts.picAwards = counts.picAwards + c.picAwards;
		}

		return counts;
	}


	public sortRows(rows: ROW[]) {
		rows.sort((row1: ROW, row2: ROW) => {
			const val1 = row1[RegionCols.name];
			const val2 = row2[RegionCols.name];
			return val1 < val2 ? -1 : 1;
		});
	}


	public async explore(row: ROW) {
		await this.ds.explorer.explore(DbConceptName.Region, row[RegionCols.regionId]);
	}


	public async open(row: ROW) {
		await this.router.navigate([`access/admin/national/regions/${row[RegionCols.regionId]}`]);
	}


	public async editNotes(row: ROW) {
		// Notes dialog not implemented
	}
}