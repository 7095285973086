import { RegionAreaRawData } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { RegionAreaData } from "./region-area-data";


export async function mapToData(
	rawData: RegionAreaRawData,
	util: UtilityService,
	ds: DataService,
): Promise<RegionAreaData> {


	const regionId = rawData.regionId;

	const region = await ds.admin.region.getOnePackage(regionId);

	// const sitesByRegionId = await ds.admin.site.getByRegionIds([regionId]);
	// const sites = sitesByRegionId[regionId];
	// const siteIds = sites.map(site => site.siteId);

	// const accelerators = await ds.admin.accelerator.getAllPackagesAsArray();
	// const pitchContests = await ds.admin.pitchContest.getAllPackagesAsArray();
	// const events = await ds.admin.event.getAllPackagesAsArray();
	// const applications = (await ds.admin.application.getAllPackagesAsArray()).filter(appl => appl.accId || appl.picId);
	// const awards = (await ds.admin.award.getAllPackagesAsArray()).filter(award => (award.accTeam && siteIds.includes(award.accTeam.accelerator.siteProgram.siteId)) || (award.picTeam && siteIds.includes(award.picTeam.pitchContest.siteProgram.siteId)));



	return {
		region,
		// sites,
		// accelerators: accelerators.filter(acc => siteIds.includes(acc.siteId)),
		// pitchContests: pitchContests.filter(pic => siteIds.includes(pic.siteProgram.siteId)),
		// events: events.filter(e => e.siteId && siteIds.includes(e.siteId)),
		// applications: applications.filter(appl => appl.siteProgram.siteId && siteIds.includes(appl.siteProgram.siteId)),
		// awards,
	}
}