import { Injectable } from "@angular/core";
import { AppAreaIdentifier } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { UrlService } from "@me-services/ui/url";
import { PageTabsLayout } from "@me-shared-parts/UI-common";
import { BehaviorSubject, Observable, ReplaySubject, Subscription, combineLatest } from "rxjs";
import { getDashboardData } from "./data/get-dashboard-data";
import { DashboardData } from "./data/interfaces";
import { SharedDashboardViewName } from "./shared-dashboard-view-name";




@Injectable({ providedIn: 'root' })
export class SharedDashboardsService {

	public readonly view$ = new BehaviorSubject<SharedDashboardViewName>('indicators');

	/**
	 * Only include National, Region, and Site areas
	 */
	public readonly identifier$ = new BehaviorSubject<AppAreaIdentifier<string | number>>(undefined);

	public readonly layout$ = new ReplaySubject<PageTabsLayout>();
	private layoutSubscription: Subscription;

	public readonly dashboardData$ = new BehaviorSubject<DashboardData>(undefined);


	constructor(
		urlService: UrlService,
		private ds: DataService,
		private util: UtilityService,
	) {
		combineLatest([ds.admin.singletonsAsOfUTC$, urlService.appAreas.current$])
			.subscribe(async ([utc, identifier]) => {

				console.log(identifier);

				identifier = ['Admin', 'Region', 'Site'].includes(identifier?.areaName) ? identifier : undefined;

				this.identifier$.next(identifier);

				const data = await getDashboardData(this.ds, this.util, <AppAreaIdentifier<number>>identifier);

				this.dashboardData$.next(data);
			});
	}


	monitorLayout(layout$: Observable<PageTabsLayout>) {
		if (this.layoutSubscription) this.layoutSubscription.unsubscribe();
		this.layoutSubscription = layout$.subscribe(layout => { this.layout$.next(layout) });
	}

}