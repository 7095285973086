import { DbsPerson, DbsPitchContest, PitchContest, SiteProgram } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { Observable } from "rxjs";
import { DomainDataManagers } from "../interfaces/domain-data-managers";
import { PackageManager } from "../package-manager";
import { SingletonsManager } from "../singletons-manager";
import { SiteProgramPackageManager } from "./site-program";


export class PitchContestPackageManager extends PackageManager<DbsPitchContest, PitchContest> {

	constructor(
		singletonsAsOfUTC$: Observable<number>,
		util: UtilityService,
		sm: SingletonsManager<DbsPitchContest>,
		private domain: DomainDataManagers,
		private person: SingletonsManager<DbsPerson>,
		private siteProgram: SiteProgramPackageManager,
	) {
		super(singletonsAsOfUTC$, util, sm)
	}


	/**
	 * Convert an array of DbcPitch to an array of Pitch
	 */
	protected async _createPackages(dbsPitchContests: DbsPitchContest[]): Promise<PitchContest[]> {

		//
		// Get all the related objects
		//
		const personIds: number[] = [];
		const siteProgramIds: number[] = [];

		for (const pic of dbsPitchContests) {
			personIds.push(pic.directorId);
			personIds.push(pic.managerId);
			personIds.push(pic.updatedByPersonId);
			siteProgramIds.push(pic.siteProgramId);
		}

		const personMap = await this.person.getManyAsMap(personIds);
		const siteProgramMap = await this.siteProgram.getManyPackagesAsMap(siteProgramIds);


		//
		// Package 'em up
		//
		const pitchContests: PitchContest[] = dbsPitchContests.map(pic => {

			const director = personMap[pic.directorId];
			const manager = personMap[pic.managerId];
			const updatedByPerson = personMap[pic.updatedByPersonId];
			const siteProgram = siteProgramMap[pic.siteProgramId];

			return {
				...pic,
				id: pic.picId,
				explorerName: `${siteProgram.site.code6} ${pic.name}`,
				directorName: director?._name || `Person #${pic.directorId}`,
				managerName: manager?._name || `Person #${pic.managerId}`,
				updatedByPersonName: updatedByPerson?._name || `Person #${pic.updatedByPersonId}`,
				siteProgram,
				stage: this.domain.picStage.getOne(pic.picStageId),
				appUrl: this.createAppUrl(pic, siteProgram),
				hasRedFlag: false,
			};
		});

		return pitchContests;
	}


	private createAppUrl(pic: DbsPitchContest, sp: SiteProgram): string {
		const picId = pic.picId;
		const siteCode = sp.site.code;
		return `/access/admin/communities/${siteCode.toLowerCase()}/programs/pitch-contests/${picId}/overview`;
	}


	/**
	 * Get all pitch contests for each siteId provided
	**/
	public async getBySiteIds(siteIds: number[]): Promise<Readonly<Record<number, ReadonlyArray<PitchContest>>>> {

		return await this.getPackagesAsArraysByForeignIds('siteId', siteIds);
	}
}