import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { BaseField } from './base/base-field';


@Component({
	selector: 'field-chrome',
	templateUrl: './SHR-UI_field-chrome.part.html',
})

export class FieldChromePart implements OnInit {

	@Input() basePart: BaseField;
	@Input() highlightIfIncomplete = true;
	@Output() fieldClick = new EventEmitter<void>();

	public bp: BaseField;	// Short name for reference in the html

	ngOnInit() {
		this.bp = this.basePart;
	}
}