/**
 * IMPORTANT: The DexieService uses these names to cache area data into IndexedDb.
 * The name MUST be exactly the same as the area service class without AreaService.
 * For example, SitesAreaService should be included as Sites.
 * 
 * IMPORTANT: The AppAreaName type and the APP_AREA_NAMES constant MUST match.
 */
export type AppAreaName =
	`Acc` |
	`AccOld` |
	`Admin` |
	`My` |
	`National` |
	`Program` |
	`Region` |
	`Tech` |
	`EforAll` |
	`Events` |
	`Pic` |
	`Site` |
	`Sites` |
	`SiteOld` |
	`User`;


/**
 * IMPORTANT: The DexieService uses these names to cache area data into IndexedDb.
 * Its best to pick the correct name initially and not change it. If you are not
 * confident what a name should be then ask the team.
 * 
 * IMPORTANT: The AppAreaName type amd the APP_AREA_NAMES constant MUST match.
 */
export const APP_AREA_NAMES: AppAreaName[] = [
	`Acc`,
	`AccOld`,
	`Admin`,
	`National`,
	`Tech`,
	`EforAll`,
	`Events`,
	`Pic`,
	`Site`,
	`Sites`,
	`SiteOld`,
	`User`,
];