import { Injectable } from '@angular/core';
import { DbcUser, DbsPerson } from '@me-interfaces';
import { FuncService } from '@me-services/core/func';
import { OldDomainDataService } from '@me-services/core/old-dd';
import { take } from 'rxjs/operators';
import { UserAreaService } from '@me-user-area';
import { PersonWrapper } from '../entity/person-wrapper';
import { UserWrapper } from './user-wrapper';

/** @deprecated Use UserAreaService instead */
@Injectable({ providedIn: 'root' })
export class OldUserService {

	/** @deprecated Use UserAreaService instead */
	public get user$() { return this.userAreaService.user$; }

	/** @deprecated Use UserAreaService instead */
	public get initialCards$() { return this.userAreaService.initialCards$; }



	constructor(
		private func: FuncService,
		private dd: OldDomainDataService,
		private userAreaService: UserAreaService,
	) {
	}

	/**
	 * Return the currently signed in user or undefined if not signed in.
	 */
	public async getUser(): Promise<DbcUser | undefined> {
		return await this.userAreaService.user$.pipe(take(1)).toPromise();
	}


	public async getUserAsWrappedPerson(): Promise<PersonWrapper | undefined> {
		const person = await this.getUser();
		return person ? this.wrapPerson(person) : undefined;
	}


	private wrapPerson(person: DbsPerson): PersonWrapper {
		if (!person) return undefined;
		return new PersonWrapper(person, this.func, this.dd);
	}


	public async getUserAsWrappedUser(): Promise<UserWrapper | undefined> {
		const user = await this.getUser();
		return user ? this.wrapUser(user) : undefined;
	}


	public wrapUser(user: DbcUser): UserWrapper {
		if (!user) return undefined;
		return new UserWrapper(user, this.func, this.dd);
	}

}