<div class='explorer-header-part'>

	<div>
		<button kendoTooltip class="btn" [disabled]="!ds.explorer.navigator.canGoBack"
			(click)="ds.explorer.navigator.goBack()"
			[attr.title]="getTitle('Go Back', ds.explorer.navigator.canGoBack)"><i
				class="fas fa-arrow-left"></i></button>

		<button kendoTooltip class="btn" [disabled]="!ds.explorer.navigator.canGoForward"
			(click)="ds.explorer.navigator.goForward()"
			[attr.title]="getTitle('Go Forward', ds.explorer.navigator.canGoForward)"><i
				class="fas fa-arrow-right"></i></button>
	</div>

	<ng-container *ngIf="recents$ | async; let recents">

		<me-droplist [items]='recents' [selectedItem]='recents[0]' (selectionChange)='recentSelection($event)'
			[filterable]='true' [navigation]='true' [sortBy]="'customSort'">
		</me-droplist>

	</ng-container>

	<button kendoTooltip class="btn" (click)="search()" title="Open the Search Window"><i
			class="fas fa-magnifying-glass"></i></button>

	<button kendoTooltip class="btn" (click)="open()" title="Close the Explorer and Open the Related Page"><i
			class="fas fa-arrow-right-to-bracket"></i></button>

</div>