import { LanguageId, Old_MinimalContact, Old_SiteProgram } from '@me-interfaces';

/**
 * @deprecated Use PitchContest from DataService instead 
 */
export interface Old_PitchContest {
	picId: number,
	picStageId: number,
	picStage: string,
	siteProgramId: number,
	siteProgram: Old_SiteProgram,
	languageId: LanguageId,
	name: string,
	applStartUTC: number,
	applDuration: number,
	director: Old_MinimalContact,
	manager: Old_MinimalContact,
	createdUTC: number,
}
