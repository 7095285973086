import { Injectable } from '@angular/core';
import { PageTabsLayoutPageService } from '@me-shared-parts/UI-common';

/**
 * The list of tabs on this page
 */
export enum AccCohortPageTabId {
	Manage = 1,
	Teams = 2,
	Curriculum = 3,
	Final = 4,
	Quarterlies = 5,
	Entrepreneurs = 6,
}

/**
 * The list if main tab droplist views on this page
 */
export enum AccCohortPageViewId {

	Teams = 1,
	MatchedMentors = 2,
	GoalsAndProgress = 3,

	//
	// Curriculum
	//
	CurriculumTopics = 4,
	CurriculumSessions = 5,
	CurriculumTopicSpecialists = 6,
	CurriculumSurveysBySession = 7,
	CurriculumSurveysByTopic = 8,
	CurriculumSurveysBySpecialist = 9,

	FinalPitchEvents = 10,
	FinalPitchJudges = 11,
	FinalPitchTeams = 12,
	FinalPitchAssessments = 13,
	FinalPitchEditor = 14,
	FinalPitchFeedback = 15,

	TeamParticipation = 16,
	DecisionMatrix = 17,
	ShowcaseEvent = 18,

	Q2Event = 19,
	Q3Event = 20,
	Q4Event = 21,

	Entrepreneurs = 22,
}


@Injectable({ providedIn: 'root' })
export class AccCohortPageService extends PageTabsLayoutPageService<AccCohortPageTabId, AccCohortPageViewId> {

	constructor() {
		super(AccCohortPageService.name, AccCohortPageTabId.Manage, AccCohortPageViewId.Teams);
	}
}
