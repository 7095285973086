import { DbConceptName, DbsEntity, ExplorablePackage, Immutable } from '@me-interfaces';
import { PersonExtracts, PersonProfileExtracts } from "../../db-extracts";


/**
 * Describes whether a person is currently performing,
 * has performed, or never performed a particular role.
 */
export type RoleTiming = 'Current' | 'Past' | 'Never';

interface MutableDbsPerson extends DbsEntity {

	readonly _concept: DbConceptName.Person,

	readonly personId: number,
	readonly firstName: string,
	readonly middleInit: string,
	readonly lastName: string,
	readonly demographicsGiven: string,
	readonly _name: string,
	readonly phone: string,
	readonly phoneTypeId: number,
	readonly _email: string,
	readonly updatedUTC: number,
	readonly updatedByPersonId: number,

	readonly hasOptOutDemographics: boolean,
	readonly isDeceased: boolean,
	readonly isEForAllAdmin: boolean,
	readonly isTechAdmin: boolean,
	readonly isCrmUser: boolean,
	readonly isPermanent: boolean,
	readonly canEditDemographics: boolean,
	readonly _personExtracts: PersonExtracts,
	readonly doNotContactNote: string,

	//
	// The following properties were merged in from the old DbcPersonProfile
	//
	readonly prefixId: number,
	readonly suffixId: number,
	readonly preferredName: string,
	readonly formerName: string,
	readonly _profileExtracts: PersonProfileExtracts,

	readonly bioEN: string,
	readonly bioES: string,
}

export interface DbsPerson extends Immutable<MutableDbsPerson> { }


interface MutablePerson {

	_concept: DbConceptName.Person,
	asSingleton: DbsPerson,
	personId: number,
	updatedUTC: number,

	/** First name concatenated with last name. If available, the middle initial will also be added. */
	fullName: string,

	//
	// Upward facing references (singletons, domain data, or packages)
	//
	readonly emails: {
		emailId: number,
		email: string,
		isDefault: boolean,
	}[],

	readonly tags: {
		readonly tagId: number,
		readonly tagPrefixId: number,
		readonly isAutoTag: boolean,
		readonly fullName: string,
		readonly siteId: number,
	}[],

	readonly webLinks: {
		webLinkId: number,
		webLinkTypeId: number,
		url: string,
	}[],


	//
	// Downward or horizontal facing references (singletons or domain data only)
	//


	//
	// Flags
	//
	readonly doNotContact: boolean,
	readonly noteIds: number[],
	readonly accApplicationIds: number[],
	readonly picApplicationIds: number[],
	readonly activity: {
		readonly accelerator: RoleTiming,
		readonly pitchContest: RoleTiming,
		readonly mentor: RoleTiming,
		/** Reader, Interviewer, or Judge. Don't include coach/mentor */
		readonly otherVolunteer: RoleTiming,
	},

	/** Person has at least one site mentor approved tag */
	isApprovedMentor: boolean,

	speaksEnglish: boolean,
	speaksSpanish: boolean,

	/** The dollar amount of awarded value that teams have earned of which this person is a member */
	readonly awardedValue: number,

	readonly awards: {
		awardId: number,
		value: number,
	}[],
}

export interface Person extends ExplorablePackage<DbConceptName.Person>, Immutable<MutablePerson> { }


export interface SimplePerson {
	readonly personId: number,
	readonly _name: string,
	readonly _email: string,
	readonly isCrmUser: boolean,
}