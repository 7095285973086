import { Component, EventEmitter, Input, Output } from '@angular/core';
import { getIconClass, Icon } from '../icon';
import { MultiSelectItem } from './multiselect-item';



@Component({
	selector: 'me-multiselect',
	templateUrl: './SHR-CMN_multiselect.part.html',
	styleUrls: ['./SHR-CMN_multiselect.part.scss'],
})
export class MultiSelectPart {


	/** The set of items in the list. */
	@Input() public items: MultiSelectItem[] = [];

	/**
	 * If present, an action button will be available
	 * that when clicked will raise an itemClick event.
	 */
	@Input() public buttonIcon: Icon = undefined;

	/** If true, then list will be disabled.	 */
	@Input() readonly = false;

	/** Raised when an item is checked or unchecked, returns the item that changed */
	@Output() itemChange = new EventEmitter<MultiSelectItem>();

	/** Raised when an item is checked or unchecked, returns the list of selected items	 */
	@Output() selectionChange = new EventEmitter<MultiSelectItem[]>();

	/** Raised when the button is clicked */
	@Output() buttonClick = new EventEmitter<MultiSelectItem>();


	public itemDisabled(itemArgs: { dataItem: MultiSelectItem, index: number }) {
		return !!itemArgs.dataItem?.disabled;
	}


	public onCheckboxChange(event: Event, item: MultiSelectItem) {
		item.checked = !!event.target['checked'];
		this.itemChange.emit(item);
		this.selectionChange.emit(this.items.filter(item => item.checked));
	}

	public onbuttonClick(item: MultiSelectItem) {
		this.buttonClick.emit(item);
	}

	public getButtonIconClass() {
		return getIconClass(this.buttonIcon);
	}

}
