
export const ACC_APPLICATION_STATUS: { [index: number]: string } = {
	1: 'Application Pending',
	2: 'Application Abandoned',
	3: 'Application Withdrawn',
	4: 'Application Rejected',
	5: 'Application Deferred',
	6: 'Application CopiedForward',
	7: 'Read Pending',
	8: 'Read Abandoned',
	9: 'Read Withdrawn',
	10: 'Read Rejected',
	11: 'Read Deferred',
	12: 'Read CopiedForward',
	13: 'Interview Pending',
	14: 'Interview Abandoned',
	15: 'Interview Withdrawn',
	16: 'Interview Rejected',
	17: 'Interview Deferred',
	18: 'Interview CopiedForward',
	19: 'Selection Pending',
	20: 'Selection Abandoned',
	21: 'Selection Withdrawn',
	22: 'Selection Rejected',
	23: 'Selection Deferred',
	24: 'Selection CopiedForward',
	25: 'Team Pending',
	26: 'Team Accepted',
};


export const PIC_APPLICATION_STATUS: { [index: number]: string } = {
	1: 'Application Pending',
	2: 'Application Abandoned',
	3: 'Application Withdrawn',
	4: 'Application Rejected',
	5: 'Application Deferred',
	6: 'Application CopiedForward',
	7: 'Read Pending',
	8: 'Read Abandoned',
	9: 'Read Withdrawn',
	10: 'Read Rejected',
	11: 'Read Deferred',
	12: 'Read CopiedForward',
	13: '(Status 13 not used)',
	14: '(Status 14 not used)',
	15: '(Status 15 not used)',
	16: '(Status 16 not used)',
	17: '(Status 17 not used)',
	18: '(Status 18 not used)',
	19: 'Selection Pending',
	20: 'Selection Abandoned',
	21: 'Selection Withdrawn',
	22: 'Selection Rejected',
	23: 'Selection Deferred',
	24: 'Selection CopiedForward',
	25: 'Team Pending',
	26: 'Team Accepted',
};