import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DbsPerson, PersonBasicInfo } from '@me-interfaces';
import { OldEntityService } from '@me-services/old-services-and-wrappers/entity';
import { ConstantsService } from '@me-services/core/constants';
import { DdPhoneTypeRow, DdPrefixRow, DdSuffixRow, OldDomainDataService } from '@me-services/core/old-dd';
import { setPhoneTypeOnCountryChange } from '@me-shared-parts/UI-common';


@Component({
	selector: 'person-basic-info-editor',
	templateUrl: './SHR-ED_person-basic-info-editor.part.html',
	styleUrls: ['SHR-ED_person-basic-info-editor.part.scss'],
})
export class PersonBasicInfoEditorPart implements OnInit, OnDestroy {

	@Input() person: DbsPerson;
	@Output() updatePerson = new EventEmitter<DbsPerson>();

	form: FormGroup;
	telInput = undefined;
	updating = false;
	public defaultPrefixItem: DdPrefixRow = { prefixId: undefined, name: '' };
	public defaultSuffixItem: DdSuffixRow = { suffixId: undefined, name: '' };
	phoneTypes = this.dd.phoneTypes.objects;
	prefixes = this.dd.prefixes.objects;
	suffixes = this.dd.suffixes.objects;


	constructor(
		private cd: ChangeDetectorRef,
		private fb: FormBuilder,
		public constants: ConstantsService,
		private entityService: OldEntityService,
		public dd: OldDomainDataService) { }

	ngOnInit() {
		const input = document.querySelector("#phone");
		this.telInput = (<any>window).intlTelInput(input, {
			nationalMode: true,
			utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/11.1.0/js/utils.js"
		});
		this.createForm();
		setPhoneTypeOnCountryChange(input, this.form, this.telInput, 2);
	}

	ngOnDestroy() {
		if (this.telInput) this.telInput.destroy();
	}

	private createForm() {

		this.form = this.fb.group({
			prefixId: [undefined],
			firstName: ['', [Validators.required, Validators.maxLength(20)]],
			middleInit: ['', [Validators.maxLength(1)]],
			lastName: ['', [Validators.required, Validators.maxLength(20)]],
			suffixId: [undefined],
			preferredName: ['', [Validators.maxLength(35)]],
			formerName: ['', [Validators.maxLength(35)]],
			phone: ['', this.constants.validators.validatePhone(this.telInput),
				// this.constants.validators.validatePhoneNotInUse(this.telInput, this.func, this.cd, this.person.personId)
			],
			phoneTypeId: [undefined],
		});

		this.resetForm();
	}

	public resetForm() {
		const p = this.person;
		const values = {
			prefixId: p.prefixId,
			firstName: p.firstName,
			middleInit: p.middleInit,
			lastName: p.lastName,
			suffixId: p.suffixId,
			preferredName: p.preferredName,
			formerName: p.formerName,
			phoneTypeId: p.phoneTypeId,
			phone: p.phone || '',
		};

		this.telInput.setNumber(values.phone);
		this.form.reset(values);

		Object.keys(this.form.controls).forEach(key => {
			const ctrl: AbstractControl = this.form.get(key);
			ctrl.markAsTouched();
		});

		this.form.updateValueAndValidity();
	}

	public itemDisabled(itemArgs: { dataItem: DdPhoneTypeRow, index: number }) {
		return itemArgs.dataItem.phoneTypeId == 4;
	}

	toggleCheckbox(box: string) {
		let ctrl: AbstractControl = undefined;

		if (box == 'D') ctrl = this.form.controls.isDeceased;
		if (box == 'O') ctrl = this.form.controls.hasOptOutDemographics;

		const checked = !ctrl.value;

		ctrl.patchValue(checked);
		ctrl.markAsDirty();
	}

	tooManyCharacters(ctrl: AbstractControl) {
		const e = ctrl.errors.maxlength;
		return 'Too many characters. ' + e.actualLength + ' > ' + e.requiredLength;
	}

	async save() {
		this.updating = true;
		const values: PersonBasicInfo = {
			personId: this.person.personId,
			prefixId: this.form.value.prefixId,
			firstName: this.form.value.firstName,
			middleInit: this.form.value.middleInit,
			lastName: this.form.value.lastName,
			suffixId: this.form.value.suffixId,
			formerName: this.form.value.formerName,
			preferredName: this.form.value.preferredName,
			phone: this.form.value.phone,
			phoneTypeId: this.form.value.phone.length ? this.form.value.phoneTypeId : undefined,
		};
		await this.entityService.wrapPerson(this.person).updateBasicInfo(values);
		this.updatePerson.emit(this.person);
		this.resetForm();
		this.updating = false;
	}
}
