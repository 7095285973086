import { Component, Input, OnInit } from '@angular/core';
import { AppAreaAccess, AppAreaIdentifier } from '@me-interfaces';
import { AppAreaService } from '@me-services/core/area';
import { PageSpinnerService } from '@me-services/ui/page-spinner';


@Component({
	selector: 'app-area-reload-button',
	templateUrl: './app-area-reload-button.part.html',
})
export class AppAreaReloadButtonPart implements OnInit {

	@Input() areaService: AppAreaService<AppAreaIdentifier<number | string>, AppAreaAccess, unknown, unknown>;


	constructor(public ps: PageSpinnerService) {
	}


	ngOnInit() {
		if (!this.areaService) console.log(`The areaService attribute is required.`);
	}


	async reload() {
		if (this.areaService) await this.areaService.reload(false);
	}

}