import { Component, Input, OnChanges, QueryList, ViewChildren } from '@angular/core';
import { MatExpansionPanel } from '@angular/material/expansion';
import { RatingsData } from '@me-interfaces';
import { UtilityService } from "@me-services/core/utility";
import { AggregateBy, RatingLevel, SortBy, SortOrder, SortOrderName } from './SHR-CMN_rating-interfaces';
import { buildLevels, countAssessments, flattenData } from './SHR-CMN_rating-level';
import { gradeTheScore, setLevelScores } from './SHR-CMN_rating-scoring';


@Component({
	selector: 'rating-results',
	templateUrl: './SHR-CMN_rating-results.part.html',
	styleUrls: ['./SHR-CMN_rating-results.part.scss']
})

export class RatingResultsPart implements OnChanges {

	@Input() header = 'Results';
	@Input() data: RatingsData[] = [];
	@Input() order: SortOrder = 'cqp';
	@Input() defaultSummarize: 'assessments' | 'ratings' = 'assessments';
	@ViewChildren(MatExpansionPanel) panels: QueryList<MatExpansionPanel>;

	hasAssessments = false; // turns on if one or more ratings has assessments
	levels: RatingLevel[] = [];
	sortBy: SortBy = 'name';
	aggregateBy: AggregateBy = 'assessments';


	constructor(private util: UtilityService) {
	}


	ngOnChanges() {
		this.hasAssessments = this.checkForAssessments(this.data);
		if (!this.hasAssessments) {
			this.aggregateBy = 'questions';
			this.sortBy = 'name';
		}
		else {
			this.aggregateBy = 'assessments';
			this.sortBy = 'assessments';
		}
		this.sort(this.sortBy);
	}


	sort(sortBy: SortBy) {
		this.sortBy = sortBy;
		const flatData = flattenData(this.data)

		const orderNames = this.getOrderNames(this.order);
		this.levels = buildLevels(orderNames, flatData);

		countAssessments(this.levels, flatData.assessments);
		setLevelScores(this.util, this.levels, this.hasAssessments);

		if (this.sortBy == 'assessments') this.sortLevelsByAssessments();
		else if (this.sortBy == 'questions') this.sortLevelsByQuestions();
		else if (this.sortBy == 'scores') this.sortLevelsByScore();
		else this.sortLevelsByName();

		//
		// Open top level if only one
		//
		setTimeout(_ => {
			if (this.levels.length == 1 && this.panels) {
				const panel: MatExpansionPanel = this.panels.first;
				if (panel) panel.open();
			}
		}, 0);

	}

	grade(level: RatingLevel) {
		return gradeTheScore(level);
	}


	checkForAssessments(data: RatingsData[]) {
		for (const datum of data) {
			for (const rating of datum.ratings) {
				return rating.rating !== undefined;
			}
		}
		return false; // empty data
	}


	setClass(value: string) {
		return `ratings-five-${value}`;
	}


	private getOrderNames(order: SortOrder): [SortOrderName, SortOrderName, SortOrderName] {
		if (order == 'qcp') return ['question', 'company', 'person'];
		if (order == 'pqc') return ['person', 'question', 'company'];
		if (order == 'cqp') return ['company', 'question', 'person'];
		if (order == 'cpq') return ['company', 'person', 'question'];
		if (order == 'qpc') return ['question', 'person', 'company'];
		return ['person', 'company', 'question'];
	}


	compare(levels: [RatingLevel, RatingLevel], checks: [number, number][]): number {
		for (const check of checks) {
			if (check[0] !== check[1]) return check[1] - check[0];
		}

		return this.nameSorter(levels[0], levels[1]);
	}


	sortLevelsByAssessments() {
		const sorter = (a: RatingLevel, b: RatingLevel) => this.compare([a, b], [[a.aScore, b.aScore], [a.rScore, b.rScore]]);
		this.sortLevels(sorter);
	}


	sortLevelsByQuestions() {
		const sorter = (a: RatingLevel, b: RatingLevel) => this.compare([a, b], [[a.rScore, b.rScore], [a.aScore, b.aScore]]);
		this.sortLevels(sorter);
	}


	sortLevelsByScore() {
		const sorter = (a: RatingLevel, b: RatingLevel) => this.compare([a, b], [[a.score, b.score], [a.aScore, b.aScore]]);
		this.sortLevels(sorter);
	}


	nameSorter(a: RatingLevel, b: RatingLevel) {
		if (b.person && a.person) {
			if (a.person.firstName != b.person.firstName) return a.person.firstName > b.person.firstName ? 1 : -1
			if (a.person.lastName != b.person.lastName) return a.person.lastName > b.person.lastName ? 1 : -1;
		}
		if (a.company && b.company) {
			return a.company.name > b.company.name ? 1 : -1
		}
		if (a.question && b.question) {
			return a.question.internalLabel > b.question.internalLabel ? 1 : -1
		}
		else return 0;
	}


	sortLevelsByName() {
		this.sortLevels(this.nameSorter);
	}


	//
	// Sort the first and second level. If the aggregate is assessments and the level is questions, overide sort to name
	//
	sortLevels(sorter: (a: RatingLevel, b: RatingLevel) => number) {

		const showingAssessments = this.aggregateBy == 'assessments';
		const levels = this.levels;

		if (showingAssessments && levels.length && levels[0].question) levels.sort(this.nameSorter);
		else levels.sort(sorter);

		for (const level1 of levels) {
			if (showingAssessments && level1.children.length && level1.children[0].question) level1.children.sort(this.nameSorter);
			level1.children.sort(sorter);
		}
	}


	shouldShowRatingsFive(level1: RatingLevel, level2?: RatingLevel): boolean {
		if (level1.question && level1.question.questionTypeId !== 4) return false;
		if (level2 && level2.question && level2.question.questionTypeId !== 4) return false;
		return true;
	}


	getMenuButtonClass(prop: 'sortBy' | 'aggregateBy', val: string) {
		if (this[prop] == val) return 'mat-menu-item selected';
		else return 'mat-menu-item';
	}


	getSortClass(menu: string) {
		if (this.sortBy == menu) return 'far fa-dot-circle mr-1';
		else return 'far fa-circle mr-1';
	}


	getAggregateClass(menu: string) {
		if (this.aggregateBy == menu) return 'far fa-dot-circle mr-1';
		else return 'far fa-circle mr-1';
	}
}