import { AccApplicationContext, AccAreaService } from '@ACC-area';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AccStageId, Application } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { CountRow, CountsTableData } from '@me-shared-parts/UI-common';
import { ReplaySubject, combineLatest } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ApplicationGridApplicationStatus, ApplicationGridFilter, ApplicationGridFilterField } from '../../../acc-application-filter-helpers';
import { AccPreAcceleratorStageId, getStageStatuses } from '../../../acc-application-status-helpers';


@Component({
	selector: 'acc-application-other-field-counts-part',
	templateUrl: './acc-application-other-field-counts.part.html',
})
export class AccApplicationOtherFieldCountsPart implements OnChanges {

	@Input() accStageId: AccPreAcceleratorStageId = AccStageId.Accepting;
	@Input() applicationStatus: ApplicationGridApplicationStatus;
	@Input() field: ApplicationGridFilterField;
	@Output() filter = new EventEmitter<ApplicationGridFilter>();

	/** Bumped each time OnChanges is fired. */
	private changes$ = new ReplaySubject<SimpleChanges>(1);

	data$ = combineLatest([this.changes$, this.accAreaService.applications.applications$]).pipe(mergeMap(latest => this.buildRows(latest[1])));


	constructor(
		private accAreaService: AccAreaService,
		private ds: DataService,
	) {
	}


	ngOnChanges(changes: SimpleChanges) {
		this.changes$.next(changes);
	}



	async buildRows(applications: readonly AccApplicationContext[]): Promise<CountsTableData> {

		if (!this.field) return { header: '?', rows: [] };

		const header = this.getFieldHeader();
		if (!applications) return { header, rows: [] };

		const statuses = getStageStatuses(this.accStageId);

		const rows: CountRow[] = [];
		const rowsMap: Record<string, CountRow> = {};

		const all: CountRow = { label: 'All', count: 0, percent: 0, personIds: [] };

		//
		// Clean up the list of applications
		//
		const apps = applications
			.map(ctx => ctx.application)
			.filter(app => {
				if (this.applicationStatus == 'AcceptedOrBeyond') return app.applicationStatusId >= statuses.accepted.applicationStatusId;
				else return app.applicationStatusId >= statuses.pending.applicationStatusId;
			});


		//
		// Function to add app details to the appropriate row (adds row if missing)
		//
		function updateRow(value: string, personId: number) {
			let row: CountRow = rowsMap[value];
			if (!row) {
				row = { label: value, count: 0, percent: 0, personIds: [] };
				rowsMap[value] = row;
				rows.push(row);
			}

			row.count++;
			row.personIds.push(personId);
		}


		//
		// Count them by status
		//
		for (const app of apps) {

			all.count++;
			all.personIds.push(app.personId);

			const value = this.getValue(app);

			if (this.field == 'offerings' && value == 'Both') {
				updateRow('Products', app.personId);
				updateRow('Services', app.personId);
			}
			else {
				updateRow(value, app.personId);
			}

		}

		//
		// Remove duplicate personId 
		//
		all.personIds = this.ds.util.array.cleanNumericIds(all.personIds);
		for (const row of rows) {
			row.personIds = this.ds.util.array.cleanNumericIds(row.personIds);
		}

		//
		// Sort the rows (empty labels forced to the end)
		//
		rows.sort((row1, row2) => (row1.label ?? 'zzzzz') > (row2.label ?? 'zzzzz') ? 1 : -1);


		//
		// Set percents
		//
		const total = all.count;
		if (total > 0) {
			all.percent = 100;
			for (const row of rows) {
				row.percent = row.count / total * 100;
			}
		}


		return { header, rows: [all, ...rows], };

	}


	getFieldHeader() {
		const field = this.field;
		if (field == 'industry') return 'Industry';
		if (field == 'location') return 'Location';
		if (field == 'offerings') return 'Offerings';
		if (field == 'orgType') return 'Org Type';
	}


	getValue(app: Application) {
		const field = this.field;
		const company = app.company;
		const person = app.person;

		if (field == 'industry') return this.ds.getIndustryName(company.industryId);
		if (field == 'location') return this.ds.getCityAndState(company.zipId || person.zipId);
		if (field == 'offerings') return company.offerings;
		if (field == 'orgType') return this.ds.getCompanyTypeName(company.companyTypeId);

	}


	async openContactor(personIds: number[]) {
		await this.ds.contactor.show(
			false,
			personIds,
			'acc-application-other-field-counts-part',
		);
	}

	countClick(row: CountRow) {
		this.filter.emit({ accStageId: this.accStageId, applicationStatus: this.applicationStatus, field: this.field, value: row.label });
	}
}