import { Accelerator, DbConceptName, WebLinkTypeId } from "@me-interfaces";
import { LinkOrExplorable } from "../../link-or-explorerable";

export function getButtons(accelerator: Accelerator): LinkOrExplorable[] {

	const buttons: LinkOrExplorable[] = [];

	//
	// Site
	//
	buttons.push({ explorer: { explorableId: { conceptName: DbConceptName.Site, id: accelerator.siteProgram.siteId }, navTooltip: `Site: ${accelerator.siteProgram.site.name}`, } });

	//
	// Website
	//
	buttons.push({ link: { linkType: 'webLink', url: `https://eforall.org/business-accelerator/`, webLinkTypeId: WebLinkTypeId.Website } });

	//
	// ED and PM
	//
	buttons.push(
		{ explorer: { nameOverride: `ED: ${accelerator.directorName}`, explorableId: { conceptName: DbConceptName.Person, id: accelerator.directorId }, navTooltip: `ED: ${accelerator.directorName}`, } },
		{ explorer: { nameOverride: `PM: ${accelerator.managerName}`, explorableId: { conceptName: DbConceptName.Person, id: accelerator.managerId }, navTooltip: `PM: ${accelerator.managerName}`, } },
	);


	return buttons;
}