/**
 * Program results that are common across Acc and Pic
 */
export interface CommonProgramResults {
	pending: number,
	abandoned: number,
	withdrawn: number,
	rejected: number,
	deferred: number,
	copiedForward: number,
	promoted: number,
}


/**
 * For any given company or person, defined an object that counts
 * the ultimate results of each of their accelerator applications.
 */
export interface AccProgramResults extends CommonProgramResults {
	curriculum: number,
	curriculumDropout: number,
	alumni: number,
	alumniDropout: number,
}


export function createEmptyAccResults(): AccProgramResults {
	return {
		pending: 0,
		abandoned: 0,
		withdrawn: 0,
		rejected: 0,
		deferred: 0,
		copiedForward: 0,
		promoted: 0,
		curriculum: 0,
		curriculumDropout: 0,
		alumni: 0,
		alumniDropout: 0,
	}
}


/**
 * For any given company or person, defined an object that counts
 * the ultimate results of each of their pitch contest applications.
 */
export interface PicProgramResults extends CommonProgramResults {
	tablerOnly: number,
	presenter: number,
}


export function createEmptyPicResults(): PicProgramResults {
	return {
		pending: 0,
		abandoned: 0,
		withdrawn: 0,
		rejected: 0,
		deferred: 0,
		copiedForward: 0,
		promoted: 0,
		tablerOnly: 0,
		presenter: 0,
	}
}