<div class="container-fluid">
	<div class="row justify-content-center">
		<div class="col-12 col-sm-8 col-md-6 col-lg-4">
			<div class="card" style='margin: 15px 0;'>
				<div class="card-body">

					<p>
						<b><me-label [key]='welcomeMessage'></me-label></b>
					</p>

					<p class='bad-email'>{{(auth.me.firebaseUser$ | async)?.email}}</p>

					<p>
						<me-label key='md:Session Unknown Email'></me-label>
					</p>

					<form [formGroup]="form" novalidate>
						<!--
							<input pInputText placeholder="First Name" formControlName="firstName">
						-->
						<mat-form-field style="width:100%">
							<input matInput [placeholder]="inlineLabels['First Name']" formControlName="firstName" required (blur)='fixCaseOfValue(form.controls.firstName)'>
							<mat-error *ngIf='form.controls.firstName.errors && form.controls.firstName.errors.required'><me-label key='Required field'></me-label></mat-error>
							<mat-error *ngIf='form.controls.firstName.errors && form.controls.firstName.errors.maxlength'><me-label key='Too many characters'></me-label></mat-error>
						</mat-form-field>
						<mat-form-field style="width:100%">
							<input matInput [placeholder]="inlineLabels['Last Name']" formControlName="lastName" required (blur)='fixCaseOfValue(form.controls.lastName)'>
							<mat-error *ngIf='form.controls.lastName.errors && form.controls.lastName.errors.required'><me-label key='Required field'></me-label></mat-error>
							<mat-error *ngIf='form.controls.lastName.errors && form.controls.lastName.errors.maxlength'><me-label key='Too many characters'></me-label></mat-error>
						</mat-form-field>
						<mat-form-field style="width:100%">
							<input matInput [placeholder]="inlineLabels['Home: USA Zip Code']" formControlName="zipCode" required maxlength="5">
							<mat-error *ngIf='form.controls.zipCode.errors && form.controls.zipCode.errors.required'><me-label key='Required field'></me-label></mat-error>
							<mat-error *ngIf='form.controls.zipCode.errors && form.controls.zipCode.errors.pattern'>#####</mat-error>
							<mat-error *ngIf='form.controls.zipCode.errors && form.controls.zipCode.errors.badzip'><me-label key='Invalid ZIP'></me-label></mat-error>
							<mat-hint *ngIf='zipName'>{{zipName}}</mat-hint>
						</mat-form-field>
						<mat-form-field style="width:100%" class="intl-phone">
							<input matInput [placeholder]="inlineLabels['Personal Phone Number']" formControlName="phone" required id="phone" type="tel" (blur)="fixPhoneValue(form.controls.phone)">
							<mat-error *ngIf='form.controls.phone.errors && form.controls.phone.errors.required'><me-label key='Required field'></me-label></mat-error>
							<mat-error *ngIf='form.controls.phone.errors && form.controls.phone.errors.invalid'><me-label key='Invalid phone number'></me-label></mat-error>
						</mat-form-field>
						<mat-form-field style="width:100%">
							<input matInput [placeholder]="inlineLabels['Second Email Address']" formControlName="email2" (blur)="lowerCaseOfValue(form.controls.email2)">
							<mat-error *ngIf='form.controls.email2.errors && form.controls.email2.errors.pattern'><me-label key='Invalid Email'></me-label></mat-error>
						</mat-form-field>
						<mat-form-field style="width:100%">
							<input matInput [placeholder]="inlineLabels['Third Email Address']" formControlName="email3" (blur)="lowerCaseOfValue(form.controls.email3)">
							<mat-error *ngIf='form.controls.email3.errors && form.controls.email3.errors.pattern'><me-label key='Invalid Email'></me-label></mat-error>
						</mat-form-field>
					</form>

					<div></div>

					<div class="text-center">
						<spinner-button (safeClick)='signout()' [disabled]='!form.invalid'><me-label key='Logout'></me-label></spinner-button>
						<spinner-button (safeClick)='next()' [disabled]='form.invalid || doingNext' [spinning]='doingNext'><me-label key='Next'></me-label> &gt;</spinner-button>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>