<div class='q' *ngIf='showQuestion'>{{ answer.question.text }}</div>
<div class='a noanswer' *ngIf='!hasAnswer()'><me-label ley='Not Answered'></me-label></div>
<div class='a' *ngIf='hasAnswer()'>
	<div class='textfield' *ngIf='answer.question.questionTypeId < 4 || answer.question.questionTypeId === 6 || answer.question.questionTypeId === 7 || answer.question.questionTypeId === 8 || answer.question.questionTypeId === 9  || answer.question.questionTypeId === 10'>{{answer.answer}}</div>
	<div *ngIf='answer.question.questionTypeId === 4'>
		<mat-radio-group disabled class="radio-group" [value]='answer.answer'>
			<i class="far fa-thumbs-down"></i>
			<mat-radio-button *ngFor="let i of ['1','2','3','4','5']" [value]="i" class='{{"rating" + i}}'></mat-radio-button>
			<i class="far fa-thumbs-up"></i>
		</mat-radio-group>
	</div>
	<div *ngIf='answer.question.questionTypeId === 5'>
		{{answer.answer}}
	</div>
</div>