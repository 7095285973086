import { PendingSearchMatch, SearchableData, SearchMatchLevel, SearchRegExps, TextToMatch } from "../interfaces";
import { checkTexts } from "./check-texts";


export async function findEventMatches(
	data: SearchableData,
	re: SearchRegExps,
): Promise<PendingSearchMatch[]> {


	const matches: PendingSearchMatch[] = [];


	for (const event of data.events) {

		const date = new Date(event.startUTC * 1000);

		const texts: TextToMatch[] = [
			{ text: getFormattedDate(date), field: 'Date' },
			{ text: date.toDateString(), field: 'Date' },
			{ text: event.publicNotes, field: 'Public Notes' },
		];

		const match = checkTexts(event, texts, re);

		if (match.level > SearchMatchLevel.None) matches.push(match);

	}


	return matches;
}


function getFormattedDate(date) {

	const year = date.getFullYear();

	let month = (1 + date.getMonth()).toString();
	month = month.length > 1 ? month : '0' + month;

	let day = date.getDate().toString();
	day = day.length > 1 ? day : '0' + day;

	return month + '/' + day + '/' + year;
}