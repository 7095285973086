import { AccStageId, AppAreaFuncResponse, AppAreaIdentifierWithSiteId } from "@me-interfaces";
import { AppSubArea } from "@me-services/core/area";
import { DataService } from "@me-services/core/data";
import { FuncService } from "@me-services/core/func";
import { UtilityService } from "@me-services/core/utility";

export class AccSubAreaAccelerator extends AppSubArea {

	constructor(
		private func: FuncService,
		ds: DataService,
		util: UtilityService,
		getId: () => AppAreaIdentifierWithSiteId<number>,
		applyResponse: (response: AppAreaFuncResponse) => Promise<{ success: boolean, insertId?: number }>,
	) {
		super(ds, util, getId, applyResponse);
	}


	async setStage(parameters: { accStageId: AccStageId }) {
		return await this.call(this.func.areas.acc.setStage, parameters);
	}

	async setEnabledQuarterlyGoal(parameters: { enabledQuarters: [boolean, boolean, boolean, boolean] }) {
		return await this.call(this.func.areas.acc.setQuarterlyEnabled, parameters);
	}
}