import { Component, OnInit, ViewChild } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { GridColumnType, GridSetup, SELECTION_GRID_ACTION_KEY } from '@me-grid';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { GridPart } from '@me-shared-parts/UI-common';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { StaffOverviewPageService } from '../../staff-overview-page.service';


interface TeamRow {
	staffTeamId: number,
	staffDepartmentId: number,
	department: string,
	name: string,
	description: string,
	leadPersonId: number,
	leadPersonName: string,
	email: string,
	updatedUTC: number,
}


@Component({
	selector: 'staff-teams-view-part',
	templateUrl: './teams-view.part.html',
	styleUrls: ['./teams-view.part.scss'],
})
export class StaffTeamsViewPart extends DestroyablePart implements OnInit {

	public gridSetup = this.setupGrid(false);

	public rows$: Observable<TeamRow[]> = of([]);

	@ViewChild(GridPart) meGrid: GridPart<TeamRow>;

	constructor(
		private util: UtilityService,
		public ds: DataService,
		public pageService: StaffOverviewPageService
	) {
		super();
	}

	ngOnInit(): void {

		super.initDestroyable();

		this.rows$ = this.ds.admin.singletonsAsOfUTC$.pipe(mergeMap(utc => this.buildRows(this.ds)));
	}


	async buildRows(ds: DataService): Promise<TeamRow[]> {

		return [];

	}


	private setupGrid(canWrite: boolean): GridSetup<TeamRow> {

		return {
			experience: 'none',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Team",
			rowPluralName: "Teams",
			rowKey: "staffTeamId",
			stateKey: "staff-teams",
			canAdd: canWrite,
			canRefresh: false,
			canDownload: false,
			columnsToAdd: [
				{ field: "staffTeamId", header: "staffTeamId", width: 60, type: GridColumnType.number, hidden: true },
				{ field: "staffDepartmentId", header: "staffDepartmentId", width: 60, type: GridColumnType.number, hidden: true },
				{ field: "name", header: "Name", width: 100, type: GridColumnType.text },
				{ field: "department", header: "Department", width: 100, type: GridColumnType.text },
				{ field: "description", header: "Description", width: 100, type: GridColumnType.text },
				{ field: "leadPersonName", header: "Lead", width: 100, type: GridColumnType.text },
				{ field: "email", header: "Email", width: 100, type: GridColumnType.text },
			],
		};
	}



	async gridActionHandler(action: { actionKey: string, rows: TeamRow[] }) {

		if (action.actionKey == SELECTION_GRID_ACTION_KEY) {
			// Nothing to do with a simple row selection
		}
	}

}