import { Component, OnChanges, Input, HostBinding } from '@angular/core';
import { CaptionedLayout, Concept } from '@me-interfaces';
import { getIconContext, Icon, IconContext } from '../icon';
import { getIconContextForConcept } from '../icon/get-icon-for-concept';

@Component({
	selector: 'captioned-layout',
	templateUrl: './SHR-CMN_captioned-layout.part.html',
	styleUrls: ['SHR-CMN_captioned-layout.part.scss'],
})
export class CaptionedLayoutPart implements OnChanges {

	@Input() layout: CaptionedLayout;
	iconContext: IconContext;

	ngOnChanges() {
		this.iconContext = this.getIconContextForLayout(this.layout);
	}

	getIconContextForLayout(layout: CaptionedLayout) {
		let icon: Icon;
		if (layout?.person) icon = Icon.concept_person;
		else if (layout?.company) icon = Icon.concept_company;

		return getIconContext(icon || Icon.empty, undefined, 'always');
	}
}
