
/**
 * The fields that can be edited for an invited participant
 * of an application, before they have accepted the invote. 
 */
export interface ApplicationParticipantPendingEditableFields {
	firstName: string,
	lastName: string,
	title: string,
	email: string,
}


export interface ApplicationParticipantPending extends ApplicationParticipantPendingEditableFields {
	applicationParticipantPendingId: number,
	companyName: string,
	siteProgramId: number,
}