import { SharedDashboardViewName, SharedDashboardsService } from '@ADMIN-area';
import { RegionAreaService, RegionPageService, RegionDashboardViewId as ViewId } from '@REGION-area';
import { Component, Input, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AppAreaIdentifier } from '@me-interfaces';
import { DroplistItem, Icon, getIconContext } from '@me-shared-parts/UI-common';
import { map } from 'rxjs';


@Component({
	selector: 'region-views-part',
	templateUrl: './region-views.part.html',
})
export class RegionViewsPart extends DestroyablePart implements OnInit {

	@Input() readonly: boolean;
	identifier: AppAreaIdentifier<number>;


	viewDroplistItems: DroplistItem<never, ViewId>[] = [
		{ uniqueId: ViewId.Indicators, groupName: 'Region', iconContext: getIconContext(Icon.view_overview, undefined, 'never', 'Dashboard'), customSort: 'Region-1' },
		{ uniqueId: ViewId.Sites, groupName: 'Region', iconContext: getIconContext(Icon.concept_site, undefined, undefined, 'Sites'), customSort: 'Region-2' },
		{ uniqueId: ViewId.Accelerators, groupName: 'Region', iconContext: getIconContext(Icon.concept_accelerator, undefined, undefined, 'Accelerators'), customSort: 'Region-3' },
		{ uniqueId: ViewId.PitchContests, groupName: 'Region', iconContext: getIconContext(Icon.concept_pitchContest, undefined, undefined, 'Pitch Contests'), customSort: 'Region-4' },
		{ uniqueId: ViewId.Applications, groupName: 'Region', iconContext: getIconContext(Icon.concept_application, undefined, undefined, 'Applications'), customSort: 'Region-5' },
		{ uniqueId: ViewId.Businesses, groupName: 'Region', iconContext: getIconContext(Icon.concept_company, undefined, undefined, 'Businesses'), customSort: 'Region-6' },
		{ uniqueId: ViewId.Entrepreneurs, groupName: 'Region', iconContext: getIconContext(Icon.concept_person, undefined, undefined, 'Entrepreneurs'), customSort: 'Region-7' },
		{ uniqueId: ViewId.Events, groupName: 'Region', iconContext: getIconContext(Icon.concept_event, undefined, undefined, 'Events'), customSort: 'Region-8' },
		{ uniqueId: ViewId.Awards, groupName: 'Region', iconContext: getIconContext(Icon.concept_award, undefined, undefined, 'Awards'), customSort: 'Region-9' },
	];

	selectedViewItem$ = this.pageService.selectedView$.pipe(
		map(viewId => {
			const selectedViewItem = this.viewDroplistItems.find(item => item.uniqueId == viewId);
			this.sharedViewsService.view$.next(this.getSharedView(selectedViewItem));

			return selectedViewItem;
		}),
	);


	constructor(
		private pageService: RegionPageService,
		private regionAreaService: RegionAreaService,
		public sharedViewsService: SharedDashboardsService,
	) {
		super();
		this.identifier = regionAreaService.getId();
		sharedViewsService.identifier$.next(this.identifier);
	}


	ngOnInit(): void {

		super.initDestroyable();

		super.subscribe([this.regionAreaService.accessAndId$], async ([accessAndId]) => {
			this.readonly = accessAndId?.access?.root != 'Write';
			this.identifier = accessAndId?.id;
		});

	}


	onViewDroplistChange(selectedView: DroplistItem) {
		this.pageService.selectView(selectedView.uniqueId);
		this.sharedViewsService.view$.next(this.getSharedView(selectedView));
	}


	getSharedView(viewItem: DroplistItem<never, ViewId>): SharedDashboardViewName {

		if (!viewItem?.uniqueId) return undefined;

		const viewId = viewItem.uniqueId;

		if (viewId == ViewId.Indicators) return 'indicators';
		// if (viewId == ViewId.Regions) return 'regions';
		// if (viewId == ViewId.Sites) return 'sites';
		// if (viewId == ViewId.Businesses) return 'businesses';
		// if (viewId == ViewId.Entrepreneurs) return 'entrepreneurs';
		// if (viewId == ViewId.Awards) return 'awards';
		// if (viewId == ViewId.Events) return 'events';
		// if (viewId == ViewId.Accs) return 'accs';
		// if (viewId == ViewId.AccApplications) return 'acc-applications';
		// if (viewId == ViewId.AccTeams) return 'acc-teams';
		// if (viewId == ViewId.AccReaders) return 'acc-readers';
		// if (viewId == ViewId.AccInterviewers) return 'acc-interviewers';
		// if (viewId == ViewId.AccMentors) return 'acc-mentors';
		// if (viewId == ViewId.AccJudges) return 'acc-judges';
		// if (viewId == ViewId.Pics) return 'pics';
		// if (viewId == ViewId.PicApplications) return 'pic-applications';
		// if (viewId == ViewId.PicTeams) return 'pic-teams';
		// if (viewId == ViewId.PicReaders) return 'pic-readers';
		// if (viewId == ViewId.PicJudges) return 'pic-judges';

		return undefined;
	}


	showView(view: string, viewItem: DroplistItem<never, ViewId>) {

		if (!viewItem?.uniqueId) return false;

		const viewId = viewItem.uniqueId;

		if (viewId == ViewId.Indicators && view == 'Dashboard') return true;
		if (viewId == ViewId.Sites && view == 'Sites') return true;
		if (viewId == ViewId.Accelerators && view == 'Accelerators') return true;
		if (viewId == ViewId.PitchContests && view == 'PitchContests') return true;
		if (viewId == ViewId.Applications && view == 'Applications') return true;
		if (viewId == ViewId.Businesses && view == 'Businesses') return true;
		if (viewId == ViewId.Entrepreneurs && view == 'Entrepreneurs') return true;
		if (viewId == ViewId.Events && view == 'Events') return true;
		if (viewId == ViewId.Awards && view == 'Awards') return true;

		return false;
	}
}