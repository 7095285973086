import { Component, Input, OnChanges } from '@angular/core';
import { DbcUser, Person } from '@me-interfaces';
import { FuncService } from '@me-services/core/func';
import { UtilityService } from "@me-services/core/utility";
import { UrlService } from '@me-services/ui/url';

@Component({
	selector: 'person-explorer-demographics',
	templateUrl: './demographics.part.html',
	styleUrls: ['./demographics.part.scss'],
})
export class PersonExplorerDemographicsPart implements OnChanges {

	@Input() person: Person;
	@Input() user: DbcUser;

	demographics: {
		gender: string,
		races: string,
		hispanic: string,
		dob: string,
		veteran: string,
		immigrant: string,
		nativeEnglish: string,
		nativeSpanish: string,
		handicapped: string,
	};

	loading = false;

	public genders = { 'F': 'Female', 'M': 'Male', 'N': 'Non-Binary', 'O': 'Other', };
	public races = { 'N': 'American Indian or Alaska Native', 'A': 'Asian', 'B': 'Black or African American', 'P': 'Native Hawaiian or Other Pacific Islander', 'W': 'White', 'O': 'Other', };
	public hispanic = { 'Y': 'This person is Hispanic or Latino', 'N': 'This person is NOT Hispanic or Latino' };
	public veterans = { 'Y': 'This person is a U.S. Veteran', 'N': 'This person is NOT a U.S. Veteran' }
	public immigrants = { 'Y': 'This person immigrated to the U.S.', 'N': 'This person is NOT an immigrant to the U.S.' };
	public nativeEnglish = { 'Y': 'This person is a Native English Speaker', 'N': 'This person is NOT a Native English Speaker' };
	public nativeSpanish = { 'Y': 'This person is a native Spanish Speaker', 'N': 'This person is NOT a native Spanish Speaker' };




	constructor(private func: FuncService,
		private urlService: UrlService,
		private util: UtilityService) {
	}


	ngOnChanges(): void {
		if (!this.person) throw new Error(`Missing required attribute: person`);
		if (!this.user) throw new Error(`Missing required attribute: user`);
		this.demographics = undefined;

	}

	async getDemographicsDetails() {
		if (!this.user.canEditDemographics) return;

		this.loading = true;
		const personDemographics = await this.func.person.getDemographics({ personId: this.person.personId });

		if (!personDemographics) {
			this.demographics = undefined;
			this.loading = false;
			return;
		}

		let birth: Date;
		if (personDemographics.birthYear && personDemographics.birthDay) {
			birth = new Date(
				personDemographics.birthYear,				// year e.g. 1900
				Math.floor(personDemographics.birthDay / 100) - 1,	// zero-based month e.g. 11 for December
				personDemographics.birthDay % 100,			// day of month
				12, 0, 0, 0					// noon
			);
		}

		const gender = personDemographics.gender == 'O' ? personDemographics.genderOther ? `Other - ${personDemographics.genderOther}` : 'Other' : this.genders[personDemographics.gender] || 'Not Provided';
		const races = personDemographics.races == 'O' ? personDemographics.raceOther ? `Other - ${personDemographics.raceOther}` : 'Other' : this.races[personDemographics.races] || 'Not Provided';
		this.demographics = {
			dob: birth ? this.util.date.formatDate(birth, 'MMM D, YYYY', this.urlService.languageId) : 'Not Provided',
			gender,
			races,
			handicapped: personDemographics.handicapped ? 'Yes' : 'No',
			hispanic: this.hispanic[personDemographics.hispanic] || 'Not Provided',
			immigrant: this.immigrants[personDemographics.immigrant] || 'Not Provided',
			nativeEnglish: this.nativeEnglish[personDemographics.nativeEnglish] || 'Not Provided',
			nativeSpanish: this.nativeSpanish[personDemographics.nativeSpanish] || 'Not Provided',
			veteran: this.veterans[personDemographics.veteran] || 'Not Provided',
		};

		this.loading = false;
	}
}