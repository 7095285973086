import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatSelect, MatSelectChange } from '@angular/material/select';
import { Old_CompanyDetails } from '@me-interfaces';
import { DdCompanyType, DdIndustry, OldDomainDataService } from '@me-services/core/old-dd';
import { FuncService } from '@me-services/core/func';
import { UtilityService } from '@me-services/core/utility';
import { LabelsService } from '@me-services/ui/labels';

@Component({
	selector: 'company-editor',
	templateUrl: './CM_company-editor.part.html',
	styleUrls: ['./CM_company-editor.part.scss'],
})
export class CompanyEditorPart implements OnChanges {

	@Input() values: Old_CompanyDetails;
	pending: Old_CompanyDetails;

	@Output() update: EventEmitter<Old_CompanyDetails> = new EventEmitter();

	companyTypes: DdCompanyType[] = [];
	industries: DdIndustry[] = [];

	statuses = [
		{ id: 'Open', name: 'Company is still open.' },
		{ id: 'Closed', name: 'Company has closed.' },
	];
	offerings = [
		{ id: 'Products', name: 'Product(s)' },
		{ id: 'Services', name: 'Service(s)' },
		{ id: 'Both', name: 'Both Product(s) and Service(s)' },
	];

	saving = false;

	constructor(
		private func: FuncService,
		private util: UtilityService,
		dd: OldDomainDataService,
		public labels: LabelsService
	) {
		this.companyTypes = dd.companyTypes.objects;
		this.industries = dd.industries.objects;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.values) {
			this.values.shortName = this.values.shortName || ''; // this is needed for noChanges() to work
			this.pending = Object.assign({}, this.values);
		}
	}

	missingCompanyValue(field: string) {
		const value = (this.pending[field] || '').toString();
		return value.trim().length == 0;
	}

	missingAnyCompanyValues() {
		return this.missingCompanyValue('companyName') || this.pending.companyName.length < 4
			|| (this.pending.shortName !== undefined && this.pending.shortName.length > 0 && this.pending.shortName.length < 3)
			|| this.missingCompanyValue('companyTypeId')
			|| this.missingCompanyValue('industryId')
			|| this.missingCompanyValue('offerings')
			|| this.missingCompanyValue('status');
	}

	noChanges() {
		return JSON.stringify(this.values) == JSON.stringify(this.pending);
	}

	companySetValue(field: string, e: FocusEvent) {
		const element = e.target as HTMLInputElement;
		let value = (element.value || '').trim();

		// Allow all uppercase for 3 character short names
		if (value.length > 3 || value !== value.toUpperCase()) {
			value = this.util.text.fixCase(value);
		}
		this.pending[field] = element.value = value;
	}

	companySetSelectedValue(field: string, e: MatSelectChange) {
		const element = e.source as MatSelect;

		if (field.endsWith('Id')) {
			this.pending[field] = element.value = +(element.value || 0);
		}
		else {
			this.pending[field] = element.value = (element.value || '').trim();
		}
	}

	async updateDetails() {
		this.saving = true;
		const companyDetails: Old_CompanyDetails = this.pending;
		await this.func.public.company.updateDetails(companyDetails);
		this.values = Object.assign({}, this.pending);
		this.update.emit(this.values);
		this.saving = false;
	}
}