import { ActivatedRouteSnapshot } from "@angular/router";
import { AccApplicant, DbcCompanyProfile, DbsPerson, Old_Accelerator, Old_PitchContest } from '@me-interfaces';
import { findRouteData, replaceRouteData } from './find-replace-route-data';
import { DdAcc, DdPic } from '@me-services/core/old-dd';


export const RouterUtilities = {
	getSiteProgramInstances(route: ActivatedRouteSnapshot): {
		accelerators: DdAcc[],
		pitchContests: DdPic[],
	} {
		return findRouteData(route, "siteProgramInstances");
	},

	getAccelerator(route: ActivatedRouteSnapshot): Old_Accelerator {
		return findRouteData(route, "accelerator");
	},

	getPerson(route: ActivatedRouteSnapshot): DbsPerson {
		return findRouteData(route, "personProfile");
	},

	setPerson(route: ActivatedRouteSnapshot, personProfile: DbsPerson) {
		replaceRouteData(route, "personProfile", personProfile);
	},

	getCompanyProfile(route: ActivatedRouteSnapshot): DbcCompanyProfile {
		return findRouteData(route, "company");
	},

	setCompanyProfile(route: ActivatedRouteSnapshot, companyProfile: DbcCompanyProfile) {
		replaceRouteData(route, "company", companyProfile);
	},

	getApplicant(route: ActivatedRouteSnapshot): AccApplicant {
		return findRouteData(route, "applicant");
	},

	getPitch(route: ActivatedRouteSnapshot): Old_PitchContest {
		return findRouteData(route, "pitch");
	},

  routeToUrl(route: ActivatedRouteSnapshot) {
		return '/' + route.pathFromRoot
			.map(r => r.url)
			.filter(a => a.length)
			.map(a => a
				.map(u => u.path)
				.join('/'))
			.join('/');
	}
};
