import { Accelerator, CommercialSpace, DbConceptName, Dbs, DbsApplicationParticipant, DbsCompany, DbsPerson, ExplorablePackage, Immutable, PitchContest, SiteProgram, ApplicationStatusId } from '@me-interfaces';


interface MutableDbsApplication extends Dbs {

	readonly _concept: DbConceptName.Application,

	readonly applicationId: number,
	readonly applicationStatusId: ApplicationStatusId,
	readonly siteProgramId: number,
	readonly accId?: number,
	readonly picId?: number,
	readonly personId: number,
	readonly companyId: number,
	/**
	 * The name used for the company when the application was first created.
	 */
	readonly companyName: string,
	/**
	 * The company type selected when the application was first created.
	 */
	readonly companyTypeId: number,
	readonly annualRevenue: number,
	readonly numFullTimeEmployees: number,
	readonly numPartTimeEmployees: number,
	readonly numContractors: number,
	readonly answersId: number,
	readonly notes: string,
	/**
	 * Interview eventId
	 */
	readonly eventId: number,
	readonly restOfTeam: string,
	readonly createdUTC: number,
	readonly updatedUTC: number,
	readonly updatedByPersonId: number,
	readonly qualificationAnswersId: number,
	readonly discoveryAnswersId: number,
	readonly followUpUTC: number,
	readonly hasFinancials: boolean,
	readonly hasCashFlowProjections: boolean,
	readonly commercialSpace: CommercialSpace,
	readonly copiedFromApplicationId: number,
	readonly copiedToApplicationId: number,
}


interface MutableApplication extends MutableDbsApplication {
	//
	// Upward facing references (singletons, db, or packages) no arrays
	//
	accelerator?: Accelerator,
	pitchContest?: PitchContest,
	siteProgram: SiteProgram,

	//
	// Downward facing references (singletons or db only) arrays allowed
	//
	person: DbsPerson,
	company: DbsCompany,
	updatedByPersonName: string,
	applicationParticipants: {
		participant: DbsApplicationParticipant,
		person: DbsPerson
	}[],

	isOpen: boolean,
}


export interface DbsApplication extends Immutable<MutableDbsApplication> { }
export interface Application extends ExplorablePackage<DbConceptName.Application>, Immutable<MutableApplication> { }