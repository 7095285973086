import { Person } from "@me-interfaces";
import { ExplorerBox, ExplorerBoxClass } from "../../../explorer-box/explorer-box";


export async function getBoxesForLangAndBio(person: Person): Promise<ExplorerBox[]> {

	const boxes: ExplorerBox[] = [];

	if (person.speaksEnglish) {
		boxes.push({
			explorerBoxClass: ExplorerBoxClass.LangBio,
			header: `Speaks Fluent English`,
			notes: person.asSingleton.bioEN || `Bio Not Provided`,
			buttons: [],
		});
	}

	if (person.speaksSpanish) {
		boxes.push({
			explorerBoxClass: ExplorerBoxClass.LangBio,
			header: `Speaks Fluent Spanish`,
			notes: person.asSingleton.bioES || `Bio Not Provided`,
			buttons: [],
		});
	}

	return boxes;

}