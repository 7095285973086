export const enum EventTypeId {
	AccFinalPitch = 1,
	AccReading = 2,
	AccInterviewing = 3,
	AccMentorMatching = 4,
	PicReading = 5,
	PicContest = 6,
	AccQuarterliesQ2 = 9,
	AccQuarterliesQ3 = 10,
	AccQuarterliesQ4 = 11,
	AccShowcase = 12,

	DEIStaffCoreTraining = 13,
	DEIStaffAuxiliaryTraining = 14,
	DEIMentorCoreTraining = 15,
	DEIMentorAuxiliaryTraining = 16,
	DEIEntrepreneurCoreTraining = 17,

	AccSession = 18,
}


export const enum EventAttendedBy {
	Person = 'Person',
	Entrepreneur = 'Entrepreneur',
}

export const enum EventAccessTypeId {
	Accelerator = 1,
	PitchContest = 2,
	DEI = 4,
}