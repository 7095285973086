import { AccAreaService } from '@ACC-area';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { DbConceptName, DbaAccMatchAssessment, DbsPerson } from "@me-interfaces";
import { DataService } from '@me-services/core/data';
import { ExplorerButton } from '@me-services/core/data/explorer/explorer-button/explorer-button';
import { ReplaySubject } from 'rxjs';
import { AccMatchingTeam } from './acc-matching-team';
import { AccMatchingRating } from './rating/acc-matching-rating';


interface MatchingMentor {
	name: string,
	button: ExplorerButton,
	m2t: number,
	t2m: number,
}


@Component({
	selector: 'acc-matching-team-part',
	templateUrl: './acc-matching-team.part.html',
	styleUrls: ['./acc-matching-team.part.scss'],
})
export class AccMatchingTeamPart extends DestroyablePart implements OnChanges, OnInit {

	@Input() team: AccMatchingTeam;

	private _team$ = new ReplaySubject<AccMatchingTeam>(1);

	accTeamButton: ExplorerButton;
	coaches: MatchingMentor[] = [];
	mentors: MatchingMentor[] = [];

	constructor(
		private ds: DataService,
		private accAreaService: AccAreaService,
	) {
		super();
	}


	ngOnChanges() {
		if (this.team) this._team$.next(this.team);
	}


	ngOnInit(): void {
		super.initDestroyable();

		super.subscribe([
			this.accAreaService.accelerator.acc$,
			this.accAreaService.mentorMatching.assessments$,
			this._team$,
		],
			async ([acc, assessments, team]) => {

				if (!acc || !assessments || !team) return;


				this.accTeamButton = {
					explorableId: {
						id: team.accTeamId,
						conceptName: DbConceptName.AccTeam,
					}
				};


				const personMap = await this.ds.admin.person.getManyAsMap([...team.coachPersonIds, ...team.mentorPersonIds]);
				const assessmentMap = this.ds.util.array.toMap(assessments.filter(a => a.accTeamId == team.accTeamId), a => a.personId);

				this.coaches = this.getMentors(personMap, assessmentMap, team.coachPersonIds, 'Coach');
				this.mentors = this.getMentors(personMap, assessmentMap, team.mentorPersonIds, 'Mentor');

			}
		);
	}


	private getMentors(
		personMap: Readonly<Record<number, DbsPerson>>,
		assessmentMap: Record<string | number, DbaAccMatchAssessment>,
		personIds: number[],
		role: 'Coach' | 'Mentor',
	): MatchingMentor[] {

		return personIds
			.map(personId => personMap[personId])
			.map(person => {

				const assessment = assessmentMap[person.personId];

				return {
					name: `${person.firstName} ${person.lastName}`,
					button: {
						explorableId: {
							id: person.personId,
							conceptName: person._concept,
							tooltipOverride: role,
						}
					},
					m2t: assessment?.m2t,
					t2m: assessment?.t2m,
				}
			})
			.sort((p1, p2) => p1.name < p2.name ? -1 : 1);
	}


	getRating(value: number, name: string, direction: 'gave' | 'received'): AccMatchingRating {

		let desc = 'No Rating';
		if (value == 1) desc = 'a Strong No';
		else if (value == 2) desc = 'a No';
		else if (value == 3) desc = 'a Yes';
		else if (value == 4) desc = 'a Strong Yes';

		return { value, tooltip: `${name} ${direction} ${desc}` };
	}


	getDroppedOutWeek() {
		if (this.team?.droppedOutWeek) return ` - Dropped Out Week ${this.team.droppedOutWeek}`;
		else return '';
	}

}
