import { AccTeam, AwardKindId, AwardNameId, DbConceptName, Dbs, DbsPerson, DecidingRoleId, Event, Immutable, PicTeam } from '@me-interfaces';


export interface MutableDbsAward extends Dbs {

	readonly _concept: DbConceptName.Award,

	awardId: number,
	eventId: number,
	accTeamId?: number,
	picTeamId?: number,
	awardNameId: AwardNameId,
	decidingRoleId: DecidingRoleId,
	awardKindId: AwardKindId,
	value: number,
	notes: string,
	createdUTC: number,
	createdByPersonId: number,
	updatedUTC: number,
	updatedByPersonId: number,
}


interface MutableAward extends MutableDbsAward {
	event: Event,
	accTeam: AccTeam,
	picTeam: PicTeam,
	createdByPersonName: string,
	updatedByPersonName: string,
}


export interface DbsAward extends Immutable<MutableDbsAward> { }
export interface Award extends Immutable<MutableAward> { }