import { Component, Input } from '@angular/core';
import { ButtonCard } from '@me-interfaces/ui';
import { ButtonCardStyles } from './button-card-styles';

//
// The colors for color cycling
//
const COLORS = [
	'pink',
	'peach',
	'orange',
	'mustard',
	'lime',
	'green',
	'blue',
	'lilac',
	'lavendar',
];


@Component({
	selector: 'button-cards',
	templateUrl: './SHR-CMN_button-cards.part.html',
	styleUrls: ['./SHR-CMN_button-cards.part.scss'],
})
export class ButtonCardsPart {

	@Input() cards: ButtonCard[] = [];
	colorIndex = 0;
	colorByLabel = {};

	getStyle(card: ButtonCard, colorOnly = false) {
		const cardStyle = card.style;

		const style = ButtonCardStyles[cardStyle] || { icon: 'fal fa-square', color: 'gray' };
		if (style.cycleColors) {
			if (this.colorByLabel[card.label] == undefined) {
				this.colorByLabel[card.label] = COLORS[this.colorIndex % COLORS.length];
				this.colorIndex++;
			}
			style.color = this.colorByLabel[card.label];
		}

		if (colorOnly) return `${style.color} button-card-icon-shadow`;
		else return `${style.icon} ${style.color} button-card-icon-shadow`;
	}
}