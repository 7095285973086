/**
 * Calls to func.venue.add() return this interface.
 * It informs the caller what happened. Unless an
 * error occurs, add() will always return a venueId,
 * even if the venue place already exists. However,
 * domain data cache files are only updated if there
 * was a venue added.
 */
export interface VenueAddResult {
	
	/** Whether a new venue was added or a duplicate was found. */
	result: 'added' | 'duplicate',

	/** The venueId of the added venue or the duplicate if there already was one. */
	venueId: number,
}