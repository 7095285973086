<div class='accelerator-explorer-details-part' *ngIf="accelerator">

	<div id="container" class="explorer-details-container">

		<table class='pro-table pro-table-no-header pro-table-with-labels'>
			<tr>
				<td> Accelerator Id</td>
				<td> {{accelerator.accId}}</td>
			</tr>

			<tr>
				<td> Name</td>
				<td> {{accelerator.name}}</td>
			</tr>

			<tr>
				<td> Long Name</td>
				<td> {{accelerator.longName}}</td>
			</tr>

			<tr>
				<td> Stage</td>
				<td> {{accelerator.stage.name}}</td>
			</tr>

			<tr>
				<td> First day of Classes</td>
				<td>
					{{util.date.formatUTC(accelerator.startUTC, 'MMM D, YYYY', 'No Time', urlService.languageId)}}
				</td>
			</tr>

			<tr *ngIf="accelerator.applStartUTC">
				<td> Application Start Date</td>
				<td>
					{{util.date.formatUTC(accelerator.applStartUTC, 'MMM D, YYYY', 'No Time', urlService.languageId)}}
				</td>
			</tr>

			<tr>
				<td>Created On</td>
				<td>
					{{util.date.formatUTC(accelerator.createdUTC, 'MMM D, YYYY', 'H:MM AM EST', urlService.languageId)}}
				</td>
			</tr>

			<tr>
				<td>Last Edited By</td>
				<td>
					<explorer-button
						[button]="{ explorableId: { conceptName: 'person', id: accelerator.updatedByPersonId }}">
					</explorer-button>
					<div class="small-nowrap-text">
						on {{util.date.formatUTC(accelerator.updatedUTC, 'MMM D, YYYY', 'H:MM AM EST',
						urlService.languageId)}}
					</div>
				</td>
			</tr>

		</table>

		<div class="explorer-details-right">
		</div>

	</div>
</div>