import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { DBLCLICK_GRID_ACTION_KEY, GridColumnType, GridSetup, SELECTION_GRID_ACTION_KEY } from '@me-grid';
import { ExplorableConceptName } from '@me-interfaces';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';
import { LayoutService } from '@me-services/ui/layout';
import { getIconContextForSearchMatchLevel, Icon } from '@me-shared-parts/UI-common';
import { map, tap } from 'rxjs';
import { DataService } from '../../data.service';
import { Explorable } from '../../explorable';
import { SearchMatch } from '../engine/interfaces';
import { SearchService } from '../service/search-service';
import { SearchMatchRow } from './search-match-row';


@Component({
	selector: 'search-dialog',
	templateUrl: './search.dialog.html',
	styleUrls: ['./search.dialog.scss']
})
export class SearchDialog extends DestroyablePart {

	icon = 'fa-search';
	headerText = 'Search';

	exploreAction: DialogAction<{ conceptName: ExplorableConceptName, id: number }> = {
		id: 'explore',
		enabled: false,
		visible: true,
		label: 'Explore',
		linkType: 'callback',
		callback: async () => (this.row ? { conceptName: this.row.conceptName, id: this.row.id } : undefined),
		willCloseDialog: true,
	};

	selectAction: DialogAction<{ conceptName: ExplorableConceptName, id: number }> = {
		id: 'select',
		enabled: false,
		visible: false,
		label: 'Select',
		linkType: 'callback',
		callback: async () => (this.row ? { conceptName: this.row.conceptName, id: this.row.id } : undefined),
		willCloseDialog: true,
	};

	cancelAction: DialogAction = {
		id: 'close',
		enabled: true,
		visible: true,
		label: 'Cancel',
		linkType: "no-action",
		willCloseDialog: true,
	};

	row: { conceptName: ExplorableConceptName, id: number };

	actions: DialogAction<{ conceptName: ExplorableConceptName, id: number } | void>[] = [this.exploreAction, this.selectAction, this.cancelAction];


	public gridSetup = this.setupGrid();

	limitedToConcept: ExplorableConceptName | undefined;
	private get canSelect() { return !!this.limitedToConcept; }

	public rows$ = this.ds
		.searcher
		.searchService
		.results$
		.pipe(
			tap(matches => console.log(`${matches.length} matches being built into rows`)),
			map(matches => this.buildRows(matches))
		);


	public readonly searchService: SearchService;

	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ item: Explorable, limitedToConcept: ExplorableConceptName }>,
		public dialogRef: MatDialogRef<SearchDialog>,
		public ds: DataService,
		private layoutService: LayoutService,
	) {
		super();

		this.searchService = ds.searcher.searchService;
		this.searchService.preloadSearchableData();

		this.limitedToConcept = dialog.data.limitedToConcept;

		if (this.canSelect) {
			this.selectAction.visible = true;
			this.exploreAction.visible = false;
			this.gridSetup.actions.push({ key: 'select-match', icon: Icon.droplist_pickOne, label: 'Select' });
		}
	}


	private buildRows(matches: SearchMatch[]): SearchMatchRow[] {
		this.exploreAction.enabled = false;
		this.row = undefined;

		return matches.map(match => ({ uniqueKey: `${match.conceptName}-${match.id}`, ...match, levelIconContext: getIconContextForSearchMatchLevel(match.level, undefined, 'always') }));

	}


	private setupGrid(): GridSetup<SearchMatch> {

		return {
			size: {
				fitTo: 'DIALOG',
				dialogActions: true,
				dialogContext: this.dialog,
				heightMultiplier: 1,
				shrinkBy: 40,
			},
			rowSingularName: 'Match',
			rowPluralName: 'Matches',
			rowKey: 'uniqueKey',
			stateKey: 'data-search-tool',
			canAdd: false,
			canRefresh: false,
			canDownload: false,
			columnsToAdd: [
				{ field: 'levelIconContext', header: 'Level', width: 70, type: GridColumnType.iconContext },
				{ field: 'conceptName', header: 'Type', width: 80, type: GridColumnType.text },
				{ field: 'id', header: 'Id', width: 50, type: GridColumnType.number, hidden: true },
				{ field: 'name', header: 'Name', width: 200, type: GridColumnType.text, },
				{ field: 'location', header: 'Location', width: 150, type: GridColumnType.text, },
				{ field: 'siteCodes', header: 'Sites', width: 150, type: GridColumnType.text, },
				{ field: 'exactMatches', header: 'Exact Matches', width: 150, type: GridColumnType.text, hidden: true },
				{ field: 'highMatches', header: 'High Matches', width: 150, type: GridColumnType.text, hidden: true },
				{ field: 'mediumMatches', header: 'Medium Matches', width: 150, type: GridColumnType.text, hidden: true },
				{ field: 'lowMatches', header: 'Low Matches', width: 150, type: GridColumnType.text, hidden: true },
				{ field: 'updatedUTC', header: 'Updated', width: 120, type: GridColumnType.dateAndTimeUtc, hidden: true, },
			],
			actions: [
				{ key: 'explore-match', icon: Icon.dialog_explore, label: 'Explore' },
			],
			// actionEnabler: this.gridActionEnabler.bind(this),
		};
	}


	async gridActionHandler(action: { actionKey: string, rows: SearchMatchRow[] }) {
		if (action.rows.length) {
			const row = action.rows[0];

			if (row && action.actionKey == SELECTION_GRID_ACTION_KEY) {
				if (this.canSelect) {
					this.exploreAction.enabled = false;
					this.selectAction.enabled = true;
				}
				else this.exploreAction.enabled = true;
				this.row = { conceptName: row.conceptName, id: row.id };
			}
			else if (action.actionKey == DBLCLICK_GRID_ACTION_KEY || action.actionKey == 'explore-match') {
				this.row = this.exploreAction.callbackResult = { conceptName: row.conceptName, id: row.id };
				this.dialogRef.close(this.exploreAction);
			}
			else if (action.actionKey == 'select-match') {
				this.row = this.selectAction.callbackResult = { conceptName: row.conceptName, id: row.id };
				this.dialogRef.close(this.selectAction);
			}
		}

		// 
	}
}