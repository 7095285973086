<div class='acc-goals-edit-part'>
	<message-line *ngIf='!selectedGoalQuarterlyGoalFlags.goalsEnabled'>{{inlineLabels[QUARTER_NAMES[selectedGoal.quarter]]}} <me-label key='is not enabled for editing'></me-label>.</message-line>

	<table style='width:100%' class='pro-table pro-table-no-header pro-table-with-labels pro-table-without-border'>

		<tr>
			<td>
				<me-label key='Quarter'></me-label>
			</td>
			<td>
				{{inlineLabels[QUARTER_NAMES[selectedGoal.quarter]]}}
			</td>
		</tr>


		<tr>
			<td [ngClass]='{"pro-table-label-error": missing.summary}'>
				<me-label key='Summary'></me-label>
			</td>
			<td>
				<input kendoTextBox [value]='orgValues.summary' (input)="onInput('summary',$event)" (blur)='onBlur()'
					[attr.maxlength]='summaryMaxLength' [placeholder]=""
					[disabled]="!selectedGoalQuarterlyGoalFlags.goalsEnabled" />
			</td>
		</tr>

		<tr>
			<td>
				<me-label key='Details'></me-label>
			</td>
			<td>
				<textarea kendoTextArea [value]='orgValues.details' style="resize:none;" [placeholder]=""
					(valueChange)="valueChange('details',$event)" (blur)='onBlur()'
					[disabled]="!selectedGoalQuarterlyGoalFlags.goalsEnabled" rows='4'></textarea>
			</td>
		</tr>

		<tr>
			<td>
				<me-label key='Progress'></me-label>
			</td>
			<td>
				<textarea kendoTextArea [value]='orgValues.progress' style="resize:none;" [placeholder]=""
					(valueChange)="valueChange('progress',$event)" (blur)='onBlur()'
					[disabled]="!selectedGoalQuarterlyGoalFlags.progressEnabled" rows='4'></textarea>
			</td>
		</tr>
	</table>
	<button class="btn" [disabled]="!updateEnabled" (click)="updateGoal()">	<me-label key='Update'></me-label></button>

</div>