import { PicStageId } from '@me-interfaces';
import { DdPicStageRow } from './dd-pic-stage-row';
import { DdObject } from '../dd-object';
import { OldDomainDataService } from '../domain-data.service';

/**
 * @deprecated Use the DataService instead
 */
export class DdPicStage extends DdObject<DdPicStageRow> implements DdPicStageRow {

	constructor(dd: OldDomainDataService, data: DdPicStageRow) {
		super(data);
	}

	public get picStageId(): PicStageId {
		return this._data.picStageId;
	}

	public get name(): string {
		return this._data.name;
	}
}