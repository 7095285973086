import { Component, Input, OnChanges } from '@angular/core';
import { DbcPublicCompany } from '@me-interfaces';
import { DialogService } from '@me-services/ui/dialog';
import { getIconContext, Icon, IconContext, IconHideTextAt } from '@me-shared-parts/UI-common';
import { ShowPublicCompanyDialogService } from '../public-company-dialog/SHR-CM_show-public-company-dialog.service';

@Component({
	selector: 'public-company-button',
	templateUrl: './SHR-CM_public-company-button.part.html',
	styleUrls: ['./SHR-CM_public-company-button.part.scss']
})
export class PublicCompanyButtonPart implements OnChanges {

	@Input() company: DbcPublicCompany;
	@Input() hideText?: IconHideTextAt = 'never';
	@Input() showAddress = false;

	icon: IconContext = getIconContext(Icon.concept_company, undefined, this.hideText || 'never', '');


	constructor(
		public showPublicCompanyDialogService: ShowPublicCompanyDialogService
	) {
	}

	ngOnChanges() {
		this.icon = getIconContext(Icon.concept_company, undefined, this.hideText || 'never', this.company.longName);
	}


	async open() {
		await this.showPublicCompanyDialogService.show(this.company, this.showAddress);
	}
}