<div class="agreement-signature-part" *ngIf="name">


	<div class="agree-checkbox">
		<input type="checkbox" kendoCheckBox [checked]='checked' (change)='toggleCheckbox()' [disabled]="readonly" />
		<me-label key="Agreement Checkbox Msg"></me-label>
	</div>

	<ng-container *ngIf="checked">

		<div class="agreement-disclaimer">
			<me-label key="Agreement Disclaimer Msg"></me-label>
		</div>

		<div class="signature-name-body">
			<div>
				<kendo-textbox class='kendo-textbox' [placeholder]='name' (valueChange)='setName($event)'
					(blur)='onBlur()' [disabled]='!checked || readonly'>
				</kendo-textbox>
			</div>
			<div>
				<button class="btn" [disabled]="!canSign" (click)="agreementSigned.emit(true)">
					<me-label key="Sign"></me-label>
				</button>
			</div>
		</div>

	</ng-container>

</div>