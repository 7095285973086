import { CompanyBasicInfo, DbConceptName, DbsCompany } from "@me-interfaces";
import { OldDomainDataService } from '@me-services/core/old-dd';
import { FuncService } from "@me-services/core/func";
import { EntityWrapper } from "./entity-wrapper";

/** @deprecated DO NOT USE THIS!*/
export class CompanyWrapper extends EntityWrapper implements DbsCompany {

	private readonly company: DbsCompany;

	constructor(
		protected readonly _data: DbsCompany,
		protected override func: FuncService,
		protected override dd: OldDomainDataService
	) {
		super(_data, func, dd);
		this.company = _data;
	}

	override readonly _concept: DbConceptName.Company;

	//
	// DbsCompany Interface
	//
	public get companyId() { return this.company.companyId }
	public get companyTypeId() { return this.company.companyTypeId }
	public get longName() { return this.company.longName }
	public get shortName() { return this.company.shortName }
	public get phone() { return this.company.phone }
	public get phoneTypeId() { return this.company.phoneTypeId }
	public get status() { return this.company.status }
	public get offerings() { return this.company.offerings }
	public get industryId() { return this.company.industryId }
	public get positionId() { return this.company.positionId }
	public get createdUTC() { return this.company.createdUTC }
	public get _companyExtracts() { return this.company._companyExtracts }
	public override get updatedUTC() { return this.company.updatedUTC }
	public override get updatedByPersonId() { return this.company.updatedByPersonId }


	//
	// Helpers
	//

	public async updateBasicInfo(newValues: CompanyBasicInfo) {
		const company = await this.func.company.updateBasicInfo(newValues);

		//@ts-ignore TS2540
		this.company = Object.assign(this.company, company);

		// //@ts-ignore 
		// this.company.companyTypeId = company.companyTypeId;
		// //@ts-ignore 
		// this.company.longName = company.longName;
		// //@ts-ignore 
		// this.company.shortName = company.shortName;
		// //@ts-ignore 
		// this.company.phone = company.phone;
		// //@ts-ignore 
		// this.company.phoneTypeId = company.phoneTypeId;
		// //@ts-ignore 
		// this.company.status = company.status;
		// //@ts-ignore 
		// this.company.offerings = company.offerings;
		// //@ts-ignore 
		// this.company.industryId = company.industryId;
	}
}