export const NumberUtilities = {
  extractDigits: (text: string): string => {
    if (!text) text = '';
    return (text.match(/\d+/g) || []).join('');
  },
  /**
	 * Divide by 10 if the accStage Id is greater than 1 and append with 0. Return last two digits.
	 */
  getProgramStageNumber(id: number): string {
		if (id == 1) return ("0" + id).slice(-2);
		else {
			const stageId = ((id / 10) + 1)
			return ("0" + stageId).slice(-2);
		}
	}
};
