import { ExplorableConceptName } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { BehaviorSubject } from 'rxjs';
import { ExplorerDialog } from './dialog/explorer.dialog';
import { ExplorerNavigator } from './service/explorer-navigator';

/**
/**
 * The data service exposes a readonly singleton of this to 
 * manage the explorer dialog and navigation.
 */
export class Explorer {

	public readonly navigator = new ExplorerNavigator(this.ds);
	public readonly dialogIsOpen$ = new BehaviorSubject(false);


	constructor(private ds: DataService) {
	}

	private async _openDialog() {
		if (this.dialogIsOpen$.value) return;

		this.dialogIsOpen$.next(true);
		await this.ds.dialogService.showCustom(ExplorerDialog, { data: {} }, 1200, 1000);
		this.dialogIsOpen$.next(false);
	}

	/**
	 * The explorer will be opened showing the last thing that was explored.
	 */
	async exploreLast(): Promise<void> {
		await this._openDialog();
	}

	/**
	 * Explore any named explorable by id.
	 */
	async explore(name: ExplorableConceptName | 'person' | 'company', id: number) {

		const conceptName = <ExplorableConceptName>name;

		this.navigator.go({ conceptName, id });
		await this._openDialog();
	}
}