import { AccStageId } from "@me-interfaces";
import { FilterableGridController } from "@me-shared-parts/UI-common/grid/controller/filterable-grid-controller";

export type InterviewerGridFilterField = 'interviewerType' | 'countOfInterviews';


/**
 * Filter an application by a field's value.
 */
export interface InterviewerGridFilter {
	/** The accelerator stage where this filter was applied from. Only the grid of that stage will be updated. */
	accStageId: AccStageId,
	/** If AcceptedOrBeyond then the grid will be filtered by field=value AND status=Accepted+Beyond. This parameter is ignored if the field parameter = 'status' */
	field: InterviewerGridFilterField,
	value: string,
}


export function applyInterviewerGridFilter(
	/** The Accelerator Stage of the provided grid */
	accStageId: AccStageId,

	/** A controller of the grid with the columns that will be filtered */
	grid: FilterableGridController<unknown>,

	/** The details about what is to be filtered */
	filter: InterviewerGridFilter,
) {

	if (grid == undefined || filter == undefined) return;
	if (accStageId !== filter.accStageId) return


	//
	// Simply filter by reader type (e.g. imported, configured)
	//
	if (filter.field == 'interviewerType') {
		let value = '';
		if (filter.value == 'Imported Interviewers') value = 'Imported';
		if (filter.value == 'Configured Interviewers') value = 'Configured';
		if (value) grid.setFilters({ logic: 'and', filters: [{ field: filter.field, operator: 'contains', value }] });
	}
	else if (filter.field == 'countOfInterviews') {
		if (filter.value == 'Interviews' || filter.value == 'Active Interviewers') grid.setFilters({ logic: 'and', filters: [{ field: filter.field, operator: 'gt', value: 0 }] });
	}

}