<div class='site-toolbar-buttons-part' *ngIf="site">

	<app-area-reload-button [areaService]="siteAreaService"></app-area-reload-button>

	<button *ngIf='!site.isActive || site.hidden' class='btn btn-red' (click)='openMessage()'>
		<me-icon [icon]='icons.message'></me-icon>
	</button>

	<button class='btn' (click)='inspectRegion(site.regionId)'>
		<me-icon [icon]='icons.region'></me-icon>
	</button>

	<button class='btn' (click)='inspectSite(site.siteId)'>
		<me-icon [icon]='icons.site'></me-icon>
	</button>

	<button class='btn' (click)='inspectPerson(site.directorId)'>
		<me-icon [icon]='icons.ed'></me-icon>
	</button>

	<button class='btn btn-red' (click)='goToOldSiteAdmin()'>
		<me-icon [icon]='icons.oldAdmin'></me-icon>
	</button>
</div>