import { UpdatedField } from './updated-field';

export const enum FormName {
	AccInterview = 'AccInterview',
	AccJudgement = 'AccJudgement',
	AccReading = 'AccReading',
	PicJudgement = 'PicJudgement',
	PicReading = 'PicReading',
}

export interface UpdatedFormField extends UpdatedField {
	form: FormName,
}