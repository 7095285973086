/**
 * Defines the layout measurements used by the <page-tabs> component.
 */
export interface PageTabsConfig {

	/**
	 * The minimum width needed for the first tab to be expanded.  The content area must be at least
	 * the breakpoint width + padding + the pageTabsWidth for the first tab to be broken out of the
	 * page tabs.  Otherwise, the first tab content will be added as a tab to the pageTabs.
	 * Default: 500
	 */
	breakpoint?: number,


	/**
	 * The width of the remaining page tabs panel when the first tab is expanded.
	 * Default: 400
	 */
	pageTabsWidth?: number,


	/**
	 * Indicates if the page has a toolbar above the <page-tabs> part.  If so, the calculated
	 * PageTabsLayout will take that into account and reduce the tab bodies respectively.
	 * Default: true
	 */
	hasToolbar?: boolean,

}


/**
 * Default values for PageTabsConfig
 */
export const DEFAULT_CONFIG: PageTabsConfig = {
	breakpoint: 500,
	pageTabsWidth: 400,
	hasToolbar: true,
};