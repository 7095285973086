import { Injectable } from "@angular/core";
import { AppAreaIdentifier, DbsPerson, Site, SiteAreaAccess_old, SiteAreaRawData_old } from "@me-interfaces";
import { AppAreaService } from "@me-services/core/area";
import { DataService } from "@me-services/core/data";
import { OldDomainDataService } from '@me-services/core/old-dd';
import { DexieService } from "@me-services/core/dexie";
import { FuncService } from "@me-services/core/func";
import { UtilityService } from "@me-services/core/utility";
import { PageSpinnerService } from "@me-services/ui/page-spinner";
import { UrlService } from "@me-services/ui/url";
import { UserAreaService } from "@me-user-area";
import { Subject } from "rxjs";
import { SiteAreaAccelerator_old, SiteAreaPitch_old } from "./interfaces";
import { mapToData } from "./map-to-data";
import { SiteAreaData_old } from "./site-area-data";
import { SiteSubAreaConfig } from "./site-subarea-config";
import { SiteSubAreaRoles } from "./site-subarea-roles";

const NO_ACCESS: SiteAreaAccess_old = {
	root: 'None',
	sub: {
		accelerators: 'None',
		pitchContests: 'None',
		alumniNagging: 'None',
		config: 'None',
		roles: 'None',
		data: 'None',
		volunteer: 'None',
	},
};

@Injectable({
	providedIn: 'root',
})
export class OldSiteAreaService extends AppAreaService<AppAreaIdentifier<string>, SiteAreaAccess_old, SiteAreaRawData_old, SiteAreaData_old> {

	//
	// Data subsets
	//
	public readonly site$ = super.mapSubset(data => data.site);
	public readonly overview$ = super.mapSubset(data => data.overview);
	public readonly directors$ = super.mapSubset(data => data.directors);
	public readonly interns$ = super.mapSubset(data => data.interns);
	public readonly offeredSitePrograms$ = super.mapSubset(data => data.offeredSitePrograms);
	public readonly openVolunteerApplications$ = super.mapSubset(data => data.openVolunteerApplications);
	public readonly peopleWithSiteTags$ = super.mapSubset(data => data.peopleWithSiteTags);
	public readonly accs$ = super.mapSubset(data => data.accs);
	public readonly pics$ = super.mapSubset(data => data.pics);
	public readonly roledPeople$ = super.mapSubset(data => data.roledPeople);
	public readonly specialists$ = super.mapSubset(data => data.specialists);

	//
	// Sub areas
	//
	public readonly config = new SiteSubAreaConfig(this.func, this.ds, this.util, super.getId.bind(this), this.applyResponse.bind(this));
	public readonly roles = new SiteSubAreaRoles(this.func, this.ds, this.util, super.getId.bind(this), this.applyResponse.bind(this));


	constructor(
		urlService: UrlService,
		dexieService: DexieService,
		spinnerService: PageSpinnerService,
		util: UtilityService,
		private func: FuncService,
		dd: OldDomainDataService,
		ds: DataService,
		userAreaService: UserAreaService,
	) {
		super(
			'Staff',
			userAreaService.user$,
			'SiteOld',
			dexieService,
			ds,
			spinnerService,
			util,
			urlService.appAreas.siteCode$,
			func.areas.siteOld.get,
			async () => ({ area: 'SiteOld', access: NO_ACCESS, md5Hash: 'NO_ACCESS' }),
			async rawData => await mapToData(rawData, dd, util, ds),
			data => data.site.name,
		);
	}


	/**
	 * @deprecated Use super.subscribe(site$) instead.
	 * 
	 * Should always be called in ngOnInit() after a call to this.initDestroyable();
	 * Subscribe to 'site' changes and automatically unsubscribe when destroyed$ is triggered.
	 */
	public subscribeSite(destroyed$: Subject<void>, callback: (value: Site) => Promise<void>) {
		super._subscribe(this.site$, destroyed$, callback);
	}

	/**
	 * @deprecated Use super.subscribe(directors$) instead.
	 * 
	 * Should always be called in ngOnInit() after a call to this.initDestroyable();
	 * Subscribe to 'directors' changes and automatically unsubscribe when destroyed$ is triggered.
	 */
	public subscribeDirectors(destroyed$: Subject<void>, callback: (value: { director: DbsPerson, altDirector: DbsPerson }) => Promise<void>) {
		super._subscribe(this.directors$, destroyed$, callback);
	}

	/**
	 * @deprecated Use super.subscribe(offeredSitePrograms$) instead.
	 * 
	 * Should always be called in ngOnInit() after a call to this.initDestroyable();
	 * Subscribe to 'directors' changes and automatically unsubscribe when destroyed$ is triggered.
	 */
	public subscribeOfferedSitePrograms(destroyed$: Subject<void>, callback: (value: { siteProgramId: number, manager: DbsPerson, altManager?: DbsPerson, acceptingApplications?: boolean, internsWithAccess: DbsPerson[] }[]) => Promise<void>) {
		super._subscribe(this.offeredSitePrograms$, destroyed$, callback);
	}

	/**
	 * @deprecated Use super.subscribe(accs$) instead.
	 * 
	 * Should always be called in ngOnInit() after a call to this.initDestroyable();
	 * Subscribe to 'accs' changes and automatically unsubscribe when destroyed$ is triggered.
	 */
	public subscribeAccs(destroyed$: Subject<void>, callback: (value: SiteAreaAccelerator_old[]) => Promise<void>) {
		super._subscribe(this.accs$, destroyed$, callback);
	}

	/**
	 * @deprecated Use super.subscribe(pics$) instead.
	 * 
	 * Should always be called in ngOnInit() after a call to this.initDestroyable();
	 * Subscribe to 'pics' changes and automatically unsubscribe when destroyed$ is triggered.
	 */
	public subscribePics(destroyed$: Subject<void>, callback: (value: SiteAreaPitch_old[]) => Promise<void>) {
		super._subscribe(this.pics$, destroyed$, callback);
	}

	/**
	 * @deprecated Use super.subscribe(roledPeople$) instead.
	 * 
	 * Should always be called in ngOnInit() after a call to this.initDestroyable();
	 * Subscribe to 'roledPeople' changes and automatically unsubscribe when destroyed$ is triggered.
	 */
	public subscribeRoledPeople(destroyed$: Subject<void>, callback: (value: DbsPerson[]) => Promise<void>) {
		super._subscribe(this.roledPeople$, destroyed$, callback);
	}

}