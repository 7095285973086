import { AccAreaService, AccCohortPageService, AccSingleEventSelectorDialog } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { GridAction, GridColumnType, GridSetup } from '@me-grid';
import { AccStageId, AccTeam, Answer, DbaAccJudgement, Event, EventTypeId } from '@me-interfaces';
import { UtilityService } from '@me-services/core/utility';
import { DialogAction, DialogService } from '@me-services/ui/dialog';
import { Icon } from '@me-shared-parts/UI-common';
import { Observable, combineLatest, mergeMap } from 'rxjs';


interface TeamRow {
	accTeamId: number,
	countOfJudgements: number,
	eventId: number,
	eventNum: number,
	eventDate: Date,
}


@Component({
	selector: 'acc-final-pitch-teams-view-part',
	templateUrl: './acc-final-pitch-teams-view.part.html',
})
export class AccFinalPitchTeamsViewPart extends DestroyablePart implements OnInit {
	private accessAtStage$ = this.accAreaService.getAccessAtStage(AccStageId.Curriculum);
	readonly: boolean;
	public gridSetup = this.setupGrid();

	public rows$: Observable<TeamRow[]>;

	constructor(
		public accAreaService: AccAreaService,
		private dialogService: DialogService,
		private util: UtilityService,
		public pageService: AccCohortPageService
	) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();

		super.subscribe([this.accessAtStage$], async ([accessAtStage]) => {
			this.readonly = accessAtStage.access != 'Write';
		});

		this.rows$ = combineLatest([
			this.accAreaService.teams.teams$,
			this.accAreaService.judging.assessments$,
		]).pipe(mergeMap(data => this.buildRows(data[0], data[1])));
	}


	async buildRows(
		accTeams: readonly AccTeam[],
		judgeAssessments: { judgement: DbaAccJudgement, answers: readonly Answer[], }[],
	): Promise<TeamRow[]> {

		if (!accTeams) return [];

		const eventWithNumAndDate: {
			event: Event,
			num: number,
			numWithDate: string,
		}[] = await this.accAreaService.getEventsWithNumAndDate(EventTypeId.AccFinalPitch);

		return accTeams.filter(accTeam => !accTeam.droppedOutWeek).map(accTeam => {
			const eventWithNum = accTeam.finalEventId ? eventWithNumAndDate.find(e => e.event.eventId == accTeam.finalEventId) : undefined;
			return {
				accTeamId: accTeam.accTeamId,
				countOfJudgements: judgeAssessments.filter(ra => ra.judgement.accTeamId == accTeam.accTeamId).length,
				eventId: accTeam.finalEventId,
				eventDate: eventWithNum ? this.util.date.getDate(eventWithNum.event.startUTC) : undefined,
				eventNum: eventWithNum?.num,
			}
		});

	}


	private setupGrid(): GridSetup<TeamRow> {

		const setup: GridSetup<TeamRow> = {
			experience: 'ACCTEAM',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Team",
			rowPluralName: "Teams",
			rowKey: "accTeamId",
			stateKey: "acc-final-pitch-teams-view-part",
			canAdd: false,
			canRefresh: false,
			canDownload: true,
			columnsToAdd: [
				{ field: "eventId", header: "EventId", width: 100, type: GridColumnType.number, hidden: true },
				{ field: "eventNum", header: "Event #", width: 100, type: GridColumnType.number, hidden: true },
				{ field: "eventDate", header: "Judge Time", width: 150, type: GridColumnType.dateAndTime },
				{ field: "countOfJudgements", header: "Judgements", width: 110, type: GridColumnType.number },
			],
			actions: [
				{ key: 'set-event', icon: Icon.concept_event, label: 'Set Event', enabled: false },
			],
			initialState: {
				sort: [{ field: 'ACCTEAM_companyName', dir: 'asc' }],
			},
			actionEnabler: this.gridActionEnabler.bind(this),

		};

		return setup;
	}


	gridActionEnabler(action: GridAction, rows: TeamRow[]) {
		if (rows.length == 0) return false;

		if (action.key == 'set-event') {
			if (this.readonly) return false;
		}
		return true;
	}

	async gridActionHandler(action: { actionKey: string, rows: TeamRow[] }) {
		const row = action.rows[0];

		if (action.actionKey == 'set-event') {
			if (this.readonly) return;

			const action: DialogAction<number> = await this.dialogService.showCustom(
				AccSingleEventSelectorDialog,
				{
					data: {
						readonly: this.readonly,
						eventId: row.eventId,
						eventTypeId: EventTypeId.AccFinalPitch,
						header: `Set Team Event`
					},
				},
				350, 200
			);

			const id = action?.id;
			if (id == 'save') {
				await this.accAreaService.teams.actions.setFinalEventId({ accTeamId: row.accTeamId, eventId: action.callbackResult });
			}

		}

	}

}