import { AccApplicationContext, AccAreaService, AccPreAcceleratorPageService, AccReaderMaxReadsEditorDialog } from '@ACC-area';
import { ManageVolunteersDialog, SiteAreaService } from '@SITE-area';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { ADDROW_GRID_ACTION_KEY, GridAction, GridColumnType, GridSetup, PersonCols } from '@me-grid';
import { AccReaderWithMaxReads, AccStageId, Answer, ApplicationStatusId, DbaAccReading, Tag } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { DialogAction } from '@me-services/ui/dialog';
import { GridPart, Icon } from '@me-shared-parts/UI-common';
import { Observable, combineLatest, mergeMap } from 'rxjs';
import { applyReaderGridFilter } from '../../../acc-reader-filter-helpers';


const STAGE_ID = AccStageId.Reading;
interface ReaderRow {
	personId: number,
	accReaderId?: number,
	importedAccReaderId?: number,
	readerType: 'Configured' | 'Imported' | 'Imported/Configured',
	maxReads: number,
	name: string,
	countOfReadings: number,
	ratings4: [number, number, number, number,]
	ratings5: [number, number, number, number, number]
	assessments: string,
	score: number,
	updatedUTC: number,
}


@Component({
	selector: 'acc-reading-readers-view-part',
	templateUrl: './acc-reading-readers-view.part.html',
})
export class AccReadingReadersViewPart extends DestroyablePart implements OnInit, AfterViewInit {
	@ViewChild(GridPart) meGrid: GridPart<ReaderRow>;

	public gridSetup = this.setupGrid(false);
	private accessAtStage$ = this.accAreaService.getAccessAtStage(AccStageId.Reading);
	readonly: boolean;
	readonlyAccessAtStage: boolean;

	public rows$: Observable<ReaderRow[]>;
	private rows: ReaderRow[] = [];
	siteTag: Tag;


	constructor(
		private accAreaService: AccAreaService,
		private siteAreaService: SiteAreaService,
		private ds: DataService,
		private util: UtilityService,
		private pageService: AccPreAcceleratorPageService
	) {
		super();
	}

	async ngOnInit() {
		super.initDestroyable();

		super.subscribe([this.accAreaService.access$, this.accessAtStage$], async ([access, accessAtStage]) => {
			this.readonly = access?.root != 'Write';
			this.gridSetup = this.setupGrid(access.root == 'Write');
			this.readonlyAccessAtStage = accessAtStage.access != 'Write';
		});


		const siteId = this.siteAreaService.getId().id;
		if (siteId) {
			const siteTags = (await this.ds.admin.tag.getAllPackagesAsArray()).filter(tag => tag.site && tag.site.siteId == siteId);
			this.siteTag = siteTags.find(tag => tag.name == 'reader-approved');
		}


		this.rows$ = combineLatest([
			this.accAreaService.applications.applications$,
			this.accAreaService.reading.readers$,
			this.accAreaService.reading.assessments$,
		]).pipe(mergeMap(data => this.buildRows(data[0], data[1], data[2])));
	}


	ngAfterViewInit() {

		super.subscribe([this.pageService.readReadersFilter$], async ([filter]) => {
			applyReaderGridFilter(STAGE_ID, this.meGrid.grid, filter);
		});

	}

	async buildRows(
		applicationContexts: readonly AccApplicationContext[],
		existingReaders: readonly AccReaderWithMaxReads[],
		readAssessments: readonly { reading: DbaAccReading, answers: readonly Answer[], }[],
	): Promise<ReaderRow[]> {

		const rows: ReaderRow[] = [];
		if (!existingReaders || !readAssessments) {
			return rows;
		}

		const readableApplicatons = applicationContexts
			.map(ctx => ctx.application)
			.filter(application => application.applicationStatusId >= ApplicationStatusId.ReadPending);

		const existingReadersMap = this.util.array.toMap(existingReaders, (reader) => { return reader.reader.accReaderId; });
		const existingPeopleWithAccReaderId = existingReaders.map(r => ({ person: r.reader.person, accReaderId: r.reader.accReaderId }));

		const transferedReaderAssessments = readAssessments.filter(assessment => !existingReadersMap[assessment.reading.accReaderId]);
		const transferedPeopleWithAccReaderId = (await this.ds.admin.accReader.getManyPackagesAsArray(transferedReaderAssessments.map(assessment => assessment.reading.accReaderId))).map(r => ({ person: r.person, accReaderId: r.accReaderId }));


		//
		// Add all the imported readers
		// Note: No accReaderId set for transfer imported readers as they are phantom
		for (const personWithAccReaderId of transferedPeopleWithAccReaderId) {

			const assessments = readAssessments.filter(assessment => assessment.reading.accReaderId == personWithAccReaderId.accReaderId)
				.map(assessment => ({ rating: assessment.reading.rating, answers: assessment.answers }));
			const { ratings4, ratings5 } = this.util.ratings.getRating4AndRatings5(assessments);

			const foundMatch = rows.find(r => r.personId == personWithAccReaderId.person.personId);
			if (foundMatch) {
				foundMatch.countOfReadings += readAssessments.filter(reading => reading.reading.accReaderId == personWithAccReaderId.accReaderId).length;
				foundMatch.ratings4 = [
					foundMatch.ratings4[0] + ratings4[0],
					foundMatch.ratings4[1] + ratings4[1],
					foundMatch.ratings4[2] + ratings4[2],
					foundMatch.ratings4[3] + ratings4[3],
				];
				foundMatch.ratings5 = [
					foundMatch.ratings5[0] + ratings5[0],
					foundMatch.ratings5[1] + ratings5[1],
					foundMatch.ratings5[2] + ratings5[2],
					foundMatch.ratings5[3] + ratings5[3],
					foundMatch.ratings5[4] + ratings5[4],
				];
				foundMatch.assessments = this.util.ratings.formatForGridCell(foundMatch.ratings4);
				foundMatch.score = this.util.ratings.calculateScore(foundMatch.ratings5, foundMatch.ratings4);
			}
			else {
				rows.push({
					importedAccReaderId: personWithAccReaderId.accReaderId,
					personId: personWithAccReaderId.person.personId,
					countOfReadings: readAssessments.filter(reading => reading.reading.accReaderId == personWithAccReaderId.accReaderId).length,
					maxReads: 0,
					name: personWithAccReaderId.person.fullName,
					readerType: 'Imported',
					ratings4,
					ratings5,
					assessments: this.util.ratings.formatForGridCell(ratings4),
					score: this.util.ratings.calculateScore(ratings5, ratings4),
					updatedUTC: personWithAccReaderId.person.updatedUTC,
				});
			}
		}

		//
		// Add all the Configured readers
		//
		for (const personWithAccReaderId of existingPeopleWithAccReaderId) {
			const assessments = readAssessments.filter(assessment => assessment.reading.accReaderId == personWithAccReaderId.accReaderId)
				.map(assessment => ({ rating: assessment.reading.rating, answers: assessment.answers }));
			const { ratings4, ratings5 } = this.util.ratings.getRating4AndRatings5(assessments);

			const foundMatch = rows.find(r => r.personId == personWithAccReaderId.person.personId);
			if (foundMatch) {
				foundMatch.countOfReadings += readAssessments.filter(reading => reading.reading.accReaderId == personWithAccReaderId.accReaderId).length;
				foundMatch.maxReads = existingReadersMap[personWithAccReaderId.accReaderId].maxReads ?? readableApplicatons.length;
				foundMatch.readerType = 'Imported/Configured';
				foundMatch.accReaderId = personWithAccReaderId.accReaderId;
				foundMatch.ratings4 = [
					foundMatch.ratings4[0] + ratings4[0],
					foundMatch.ratings4[1] + ratings4[1],
					foundMatch.ratings4[2] + ratings4[2],
					foundMatch.ratings4[3] + ratings4[3],
				];
				foundMatch.ratings5 = [
					foundMatch.ratings5[0] + ratings5[0],
					foundMatch.ratings5[1] + ratings5[1],
					foundMatch.ratings5[2] + ratings5[2],
					foundMatch.ratings5[3] + ratings5[3],
					foundMatch.ratings5[4] + ratings5[4],
				];
				foundMatch.assessments = this.util.ratings.formatForGridCell(foundMatch.ratings4);
				foundMatch.score = this.util.ratings.calculateScore(foundMatch.ratings5, foundMatch.ratings4)

			}
			else {
				rows.push({
					personId: personWithAccReaderId.person.personId,
					countOfReadings: readAssessments.filter(reading => reading.reading.accReaderId == personWithAccReaderId.accReaderId).length,
					maxReads: existingReadersMap[personWithAccReaderId.accReaderId].maxReads ?? readableApplicatons.length,
					name: personWithAccReaderId.person.fullName,
					readerType: 'Configured',
					accReaderId: personWithAccReaderId.accReaderId,
					ratings4,
					ratings5,
					assessments: this.util.ratings.formatForGridCell(ratings4),
					score: this.util.ratings.calculateScore(ratings5, ratings4),
					updatedUTC: personWithAccReaderId.person.updatedUTC,
				});
			}
		}

		this.rows = [...rows];
		return rows;

	}


	private setupGrid(canWrite: boolean): GridSetup<ReaderRow> {

		const setup: GridSetup<ReaderRow> = {
			experience: 'PERSON',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Reader",
			rowPluralName: "Readers",
			rowKey: "personId",
			stateKey: "acc-reading-readers-view-part",
			canAdd: canWrite,
			canRefresh: false,
			canDownload: true,
			columnsToAdd: [
				{ field: "accReaderId", header: "accReaderId", width: 60, type: GridColumnType.number, hidden: true },
				{ field: "importedAccReaderId", header: "Imported accReaderId", width: 60, type: GridColumnType.number, hidden: true },
				{ field: "countOfReadings", header: "Readings", width: 88, type: GridColumnType.number },
				{ field: "maxReads", header: "Max Reads", width: 95, type: GridColumnType.number },
				{ field: "readerType", header: "Reader Type", width: 110, type: GridColumnType.text },
				{ field: "assessments", header: "Assessments", width: 115, type: GridColumnType.ratings, headerTooltip: `Reading Assessments Given` },
				{ field: "score", header: "Score", width: 115, type: GridColumnType.number, headerTooltip: `Reading Assessments Given Score`, fractionDigits: 1 },
			],
			columnsToAlter: [
				{ field: PersonCols.phone, hidden: false },
				{ field: PersonCols.language, hidden: false },
			],
			actions: [
				{ key: 'set-max-reads', icon: Icon.action_edit, label: 'Set Max Reads', enabled: false },
				{ key: 'remove-reader', icon: Icon.action_delete, label: 'Remove Reader', enabled: false },
			],
			actionEnabler: this.gridActionEnabler.bind(this),
			initialState: {},
		};

		return setup;
	}


	gridActionEnabler(action: GridAction, rows: ReaderRow[]) {
		if (rows.length == 0) return false;

		if (action.key == 'set-max-reads' || action.key == 'remove-reader') {
			if (this.readonly) return false;
		}
		return true;
	}

	async gridActionHandler(action: { actionKey: string, rows: ReaderRow[] }) {
		const row = action.rows[0];

		if (action.actionKey == ADDROW_GRID_ACTION_KEY) {
			if (this.readonly) return;
			if (this.readonlyAccessAtStage) {
				await this.ds.dialogService.showMessage(`Cannot add readers as the accelerator stage is not in 'Reading' stage.`, 300, 170);
			}
			else {
				const dialogAction: DialogAction<number[]> = await this.ds.dialogService.showCustom(
					ManageVolunteersDialog,
					{
						data: {
							readonly: this.readonly,
							initialSelections: this.rows.map(row => ({ personId: row.personId, disabled: row.countOfReadings > 0 })),
							tagIds: [this.siteTag.tagId],
							tagFullNameToAdd: this.siteTag.fullName,
							header: 'Readers'

						},
					},
					600, 530
				);

				const id = dialogAction?.id;
				if (id == 'update') {
					if (this.readonly) return;
					const selectedPersonIds = dialogAction.callbackResult;
					if (selectedPersonIds) await this.accAreaService.reading.actions.setReaders({ personIds: selectedPersonIds });
				}
			}
		}

		if (action.actionKey == 'set-max-reads') {
			if (this.readonly) return;

			if (row.readerType == 'Imported' || !row.accReaderId) {
				await this.ds.dialogService.showMessage(`Cannot set max read for ${row.name} as the Reader was imported when the application was transfered.`)
			}
			else {
				const action: DialogAction<number> = await this.ds.dialogService.showCustom(
					AccReaderMaxReadsEditorDialog,
					{
						data: {
							readonly: this.readonly,
							maxReads: row.maxReads,
						},
					},
					350, 200
				);

				const id = action?.id;
				if (id == 'save') {
					if (this.readonly) return;
					await this.accAreaService.reading.actions.setMaxReads({ accReaderId: row.accReaderId, maxReads: action.callbackResult });
				}
			}

		}


		if (action.actionKey == 'remove-reader') {
			if (this.readonly) return;

			if (row.countOfReadings > 0) {
				await this.ds.dialogService.showMessage(`${row.name} cannot be removed because ${row.countOfReadings} applicants have already been read.`, 400, 250, 'Okay');
			}

			else {
				const yes = await this.ds.dialogService.confirm(`Are you sure you want to remove reader ${row.name}?`, 400, 250, 'Remove', 'Cancel');
				if (yes) await this.accAreaService.reading.actions.removeReader({ accReaderId: row.accReaderId });
			}
		}

	}

}