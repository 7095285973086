import { Component, Input, OnChanges } from '@angular/core';
import { Region } from '@me-interfaces';
import { UtilityService } from '@me-services/core/utility';
import { UrlService } from '@me-services/ui/url';


@Component({
	selector: 'region-explorer-details',
	templateUrl: './details.part.html',
	styleUrls: ['./details.part.scss'],
})
export class RegionExplorerDetailsPart implements OnChanges {
	@Input() region: Region;

	constructor(
		public util: UtilityService,
		public urlService: UrlService,
	) { }


	ngOnChanges() {
		if (!this.region) throw new Error('Missing required attribute: region');
	}

}