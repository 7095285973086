import { AccAreaData } from "@ACC-area";
import { DbaAccSession } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { UrlService } from "@me-services/ui/url";
import { calcSessionSurveyCounts } from "./calc-session-survey-counts";
import { getSurveyCountsData } from "./get-survey-counts-data";
import { CurriculumSurveySessionCounts } from "./interfaces";


/**
 * Calculate a rollup of survey data for a given session
 */
export async function getSurveyCounts(
	session: DbaAccSession,
	ds: DataService,
	accAreaData: AccAreaData,
	urlService: UrlService,
): Promise<CurriculumSurveySessionCounts> {

	const data = await getSurveyCountsData(ds, ds.util, urlService, session, accAreaData);

	return calcSessionSurveyCounts(ds.util, data);

}
