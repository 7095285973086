import { Component, Input } from '@angular/core';
import { ExplorerBox } from './explorer-box';

@Component({
	selector: 'explorer-box',
	templateUrl: './explorer-box.part.html',
	styleUrls: ['./explorer-box.part.scss'],
})
export class ExplorerBoxPart {

	@Input() public box: ExplorerBox;

}