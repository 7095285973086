<div class="explorer-section-part">

	<div class="explorer-section-header-container">
		<div class="explorer-section-header">{{ header }}
			<button class="btn-x" (click)="xClick()">
				<i class="fas fa-x"></i>
			</button>
		</div>
	</div>

	<div class="explorer-section-body">
		<ng-content></ng-content>
	</div>

</div>