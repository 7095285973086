import { AccAreaData, AccAreaService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { DbaAccSession } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { DialogAction } from '@me-services/ui/dialog';
import { UrlService } from '@me-services/ui/url';
import { DroplistItem, Icon, getIconContext } from '@me-shared-parts/UI-common';
import { CommentsDialog } from 'admin/acc-area/dialogs/survey-comments/dialog-comments/comments.dialog';
import { filter, switchMap, take } from 'rxjs';
import { getSessionsWithSurveys } from "./get-sessions-with-surveys";
import { CurriculumSurveyComment, getSurveyCounts } from './survey-counts';


@Component({
	selector: 'acc-curriculum-tab-part',
	templateUrl: './acc-curriculum-tab.part.html',
	styleUrls: ['./acc-curriculum-tab.part.scss'],
})
export class AccCurriculumTabPart extends DestroyablePart implements OnInit {

	readonly: boolean;
	public surveyCounts: any;
	accAreaData: AccAreaData;
	lastItem: DroplistItem<DbaAccSession, number>;

	public listOfSessions$ = this.accAreaService.data$.pipe(
		filter(d => !!d),
		switchMap(d => getSessionsWithSurveys(d, this.ds)),
		switchMap(async sessions => {

			//
			// get all the session events for quick look up
			//
			const eventIds = sessions.map(s => s.eventId);
			const eventByEventId = await this.ds.admin.event.getManyAsMap(eventIds);

			//
			// transform each session into a list view item
			//
			return sessions.map(s => {

				//
				// build the session name
				//
				const sessionName = this.util.date.formatUTC(
					eventByEventId[s.eventId].startUTC,
					'MMM D, YYYY (DOW)',
					'H:MM AM EST',
					this.urlService.languageId,
				);

				//
				// translate the session into a dropListItem
				//
				const item: DroplistItem<DbaAccSession, number> = {
					uniqueId: s.accSessionId,
					data: s,
					groupName: 'Sessions',
					iconContext: getIconContext(Icon.view_topics, undefined, undefined, sessionName),
				};

				return item;
			})

		})
	);

	constructor(
		private accAreaService: AccAreaService,
		private ds: DataService,
		private util: UtilityService,
		private urlService: UrlService,
	) {
		super();
	}

	ngOnInit(): void {
		super.initDestroyable();
		super.subscribe([
			this.accAreaService.access$,
			this.accAreaService.data$,
		], async ([access, accAreaData]) => {

			if (!accAreaData) return;

			this.readonly = access?.root != 'Write';

			this.accAreaData = accAreaData;

		});

		this.listOfSessions$.pipe(
			take(1)
		).subscribe(s => {
			this.lastItem = s[s.length - 1];
			this.onSelection(this.lastItem);
		})

	}

	async onSelection(listItem: DroplistItem<DbaAccSession, number>) {
		if (!listItem?.data) return;
		const session = listItem.data;
		this.surveyCounts = await getSurveyCounts(session, this.ds, this.accAreaData, this.urlService,);
	}

	async showDialog(data: CurriculumSurveyComment[], label: string) {
		if (data[0].comment) {
			const action: DialogAction<boolean> = await this.ds.dialogService.showCustom(
				CommentsDialog,
				{
					data: {
						comments: data,
						label: label,
					},
				},
				990, 550
			);
		}
	}

}
