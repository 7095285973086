import { AccAreaService, AccCohortPageService, AccMultipleEventsSelectorDialog } from '@ACC-area';
import { ManageVolunteersDialog, SiteAreaService } from '@SITE-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { ADDROW_GRID_ACTION_KEY, GridAction, GridColumnType, GridSetup, PersonCols } from '@me-grid';
import { AccJudge, AccStageId, Answer, DbaAccJudgeEvent, DbaAccJudgement, Event, EventTypeId, Tag } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { DialogAction, DialogService } from '@me-services/ui/dialog';
import { Icon } from '@me-shared-parts/UI-common';
import { Observable, combineLatest, mergeMap } from 'rxjs';



interface JudgeRow {
	accJudgeId: number,
	personId: number,
	eventIds: number[],
	eventNums: string,
	name: string,
	countOfJudgements: number,
}


@Component({
	selector: 'acc-final-pitch-judges-view-part',
	templateUrl: './acc-final-pitch-judges-view.part.html',
})
export class AccFinalPitchJudgesViewPart extends DestroyablePart implements OnInit {
	private accessAtStage$ = this.accAreaService.getAccessAtStage(AccStageId.Curriculum);
	readonly: boolean;
	readonlyAccessAtStage: boolean;

	public gridSetup = this.setupGrid(false);

	public rows$: Observable<JudgeRow[]>;
	private rows: JudgeRow[];

	siteTag: Tag;

	constructor(
		public siteAreaService: SiteAreaService,
		public accAreaService: AccAreaService,
		public ds: DataService,
		public dialogService: DialogService,
		public pageService: AccCohortPageService,
	) {
		super();
	}

	async ngOnInit() {
		super.initDestroyable();

		super.subscribe([this.accAreaService.access$, this.accessAtStage$], async ([access, accessAtStage]) => {
			this.readonly = access?.root != 'Write';
			this.gridSetup = this.setupGrid(access.root == 'Write');
			this.readonlyAccessAtStage = accessAtStage.access != 'Write';
		});

		const siteId = this.siteAreaService.getId().id;
		if (siteId) {
			const siteTags = (await this.ds.admin.tag.getAllPackagesAsArray()).filter(tag => tag.site && tag.site.siteId == siteId);
			this.siteTag = siteTags.find(tag => tag.name == 'judge-approved');
		}

		this.rows$ = combineLatest([
			this.accAreaService.judging.judges$,
			this.accAreaService.judging.judgeEvents$,
			this.accAreaService.judging.assessments$,
			this.accAreaService.accelerator.events$,
		]).pipe(mergeMap(data => this.buildRows(data[0], data[1], data[2], data[3])));
	}


	async buildRows(
		judges: readonly AccJudge[],
		judgeEvents: readonly DbaAccJudgeEvent[],
		judgeAssessments: {
			judgement: DbaAccJudgement,
			answers: readonly Answer[],
		}[],
		events: readonly Event[],
	): Promise<JudgeRow[]> {

		if (!judges) {
			this.rows = [];
			return [];
		}

		const accJudgeEvents = await this.accAreaService.getEventsWithNumAndDate(EventTypeId.AccFinalPitch, events);

		const eventsWithId: { [index: number]: number } = {};
		accJudgeEvents.forEach(eventWithNum => { eventsWithId[eventWithNum.event.eventId] = eventWithNum.num });


		this.rows = judges.map(judge => {
			const eventIds = judgeEvents.filter(je => je.accJudgeId == judge.accJudgeId).map(ie => ie.eventId);
			const eventNums = eventIds.map(id => eventsWithId[id]).join(', ');
			return {
				accJudgeId: judge.accJudgeId,
				personId: judge.personId,
				eventIds,
				eventNums: eventIds.length ? eventNums : '',
				name: judge.person.name,
				countOfJudgements: judgeAssessments.filter(assessment => assessment.judgement.accJudgeId == judge.accJudgeId).length,
			}
		}).sort((a, b) => a.name > b.name ? 1 : -1);

		return this.rows;

	}


	private setupGrid(canWrite: boolean): GridSetup<JudgeRow> {

		const setup: GridSetup<JudgeRow> = {
			experience: 'PERSON',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Judge",
			rowPluralName: "Judges",
			rowKey: "accJudgeId",
			stateKey: "acc-final-pitch-judge-view-part",
			canAdd: canWrite,
			canRefresh: false,
			canDownload: true,
			columnsToAdd: [
				{ field: "accJudgeId", header: "accJudgeId", width: 120, type: GridColumnType.number, hidden: true },
				{ field: "eventNums", header: "Event #s", width: 100, type: GridColumnType.text },
				{ field: "countOfJudgements", header: "Judgements", width: 120, type: GridColumnType.number },
			],
			columnsToAlter: [
				{ field: PersonCols.phone, hidden: false },
				{ field: PersonCols.language, hidden: false },
			],
			actions: [
				{ key: 'set-events', icon: Icon.concept_event, label: 'Set Events', enabled: false },
				{ key: 'remove-judge', icon: Icon.action_delete, label: 'Remove Judge', enabled: false },
			],
			actionEnabler: this.gridActionEnabler.bind(this),

		};

		return setup;
	}


	gridActionEnabler(action: GridAction, rows: JudgeRow[]) {
		if (rows.length == 0) return false;

		if (action.key == 'set-events' || action.key == 'remove-judge') {
			if (this.readonly) return false;
		}
		return true;
	}


	async gridActionHandler(action: { actionKey: string, rows: JudgeRow[] }) {
		const row = action.rows[0];

		if (action.actionKey == ADDROW_GRID_ACTION_KEY) {
			if (this.readonly) return;
			if (this.readonlyAccessAtStage) {
				await this.ds.dialogService.showMessage(`Cannot add judges as the accelerator stage is not in 'Curriculum' stage.`, 300, 170);
			}
			else {
				const dialogAction: DialogAction<number[]> = await this.ds.dialogService.showCustom(
					ManageVolunteersDialog,
					{
						data: {
							readonly: this.readonly,
							initialSelections: this.rows.map(row => ({ personId: row.personId, disabled: row.countOfJudgements > 0 })),
							tagIds: [this.siteTag.tagId],
							tagFullNameToAdd: this.siteTag.fullName,
							header: 'Judges'
						},
					},
					600, 530
				);

				const id = dialogAction?.id;
				if (id == 'update') {
					if (this.readonly) return;
					const selectedPersonIds = dialogAction.callbackResult;
					if (selectedPersonIds) await this.accAreaService.judging.actions.setJudges({ personIds: selectedPersonIds });

				}
			}

		}


		else if (action.actionKey == 'set-events') {
			if (this.readonly) return;

			const action: DialogAction<number[]> = await this.ds.dialogService.showCustom(
				AccMultipleEventsSelectorDialog,
				{
					data: {
						readonly: this.readonly,
						eventIds: row.eventIds,
						eventTypeId: EventTypeId.AccFinalPitch,
						header: 'Set Judge Events'
					},
				},
				450, 450
			);

			const id = action?.id;
			if (id == 'save') {
				await this.accAreaService.judging.actions.setEvents({ accJudgeId: row.accJudgeId, eventIds: action.callbackResult });
			}


		}


		else if (action.actionKey == 'remove-judge') {
			if (this.readonly) return;

			if (row.countOfJudgements > 0) {
				await this.dialogService.showMessage(`${row.name} cannot be removed because ${row.countOfJudgements} teams have already been judged.`, 400, 250, 'Okay');
			}

			else {
				const yes = await this.dialogService.confirm(`Are you sure you want to remove Judge ${row.name}?`, 400, 250, 'Remove', 'Cancel');
				if (yes) await this.accAreaService.judging.actions.removeJudge({ accJudgeId: row.accJudgeId });
			}
		}


	}
}