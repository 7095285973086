<div class='acc-judge-counts-part'>
	<ng-container *ngIf='countsData'>

		<me-h3>Judge Counts</me-h3>
		<table class='pro-table pro-table-without-border pro-table-with-thin-rows'>
			<tr>
				<th>Type</th>
				<th>Count</th>
				<th>Contact</th>
			</tr>
			<tr>
				<td>Judges</td>
				<td>{{countsData.judgeCount}}</td>
				<td>
					<button class="text-btn text-btn-not-inline"
						(click)="openContactor(countsData.judgePersonIds)">
						<span class='contact-button-content'>
							<i class='far fa-envelope'></i> <i class='far fa-comment-lines'></i>
						</span>
					</button>
				</td>
			</tr>
			<tr>
				<td>Assessments</td>
				<td>{{countsData.assessmentCount}}</td>
				<td></td>
			</tr>
		</table>
	</ng-container>
</div>