import { DbsSpecialist, Specialist } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { Observable } from "rxjs";
import { DomainDataManagers } from "../interfaces/domain-data-managers";
import { PackageManager } from "../package-manager";
import { SingletonsManager } from "../singletons-manager";
import { PersonPackageManager } from "./person";
import { SitePackageManager } from "./site";


export class SpecialistPackageManager extends PackageManager<DbsSpecialist, Specialist>{

	constructor(
		singletonsAsOfUTC$: Observable<number>,
		util: UtilityService,
		sm: SingletonsManager<DbsSpecialist>,
		private domain: DomainDataManagers,
		private person: PersonPackageManager,
		private site: SitePackageManager,
	) {
		super(singletonsAsOfUTC$, util, sm);
	}


	/**
	 * Convert singletons to packages
	 */
	protected async _createPackages(dbsSpecialists: DbsSpecialist[]): Promise<Specialist[]> {

		//
		// Get all the related objects
		//
		const personIds: number[] = [];
		const siteIds: number[] = [];

		for (const specialist of dbsSpecialists) {
			personIds.push(specialist.personId);
			personIds.push(specialist.updatedByPersonId);
			siteIds.push(specialist.siteId);
		}

		const personMap = await this.person.getManyPackagesAsMap(personIds);
		const siteMap = await this.site.getManyPackagesAsMap(siteIds);


		//
		// Package 'em up
		//
		const specialists: Specialist[] = dbsSpecialists.map(specialist => {
			
			const updatedByPerson = personMap[specialist.updatedByPersonId];
			const person = personMap[specialist.personId];
			const site = siteMap[specialist.siteId];

			return {
				...specialist,
				person,
				site,
				updatedByPersonName: updatedByPerson?.fullName || `Person #${specialist.updatedByPersonId}`,
				topic: this.domain.topic.getOne(specialist.topicId),
				language: this.domain.language.getOne(specialist.languageId),
			};
		});

		return specialists;
	}


	/**
	 * Get all specialists for each siteId provided
	 */
	public async getBySiteIds(siteIds: number[]): Promise<Readonly<Record<number, ReadonlyArray<Specialist>>>> {

		return await this.getPackagesAsArraysByForeignIds('siteId', siteIds);
	}


	/**
	 * Get all specialists for each personId provided
	 */
	public async getByPersonIds(personIds: number[]): Promise<Readonly<Record<number, ReadonlyArray<Specialist>>>> {

		return await this.getPackagesAsArraysByForeignIds('personId', personIds);
	}
}