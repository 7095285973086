import { AccReaderWithMaxReads, Answer, DbaAccReading } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { CountRow, CountsTableData } from '@me-shared-parts/UI-common';
import { AccApplicationContext } from '@ACC-area';
import { AccPreAcceleratorStageId, getStageStatuses } from '../../acc-application-status-helpers';


export async function buildReaderGeneralCounts(
	ds: DataService,
	util: UtilityService,
	accStageId: AccPreAcceleratorStageId,
	applications: readonly AccApplicationContext[],
	readers: readonly AccReaderWithMaxReads[],
	readerAssessments: { reading: DbaAccReading, answers: readonly Answer[] }[],
): Promise<CountsTableData> {

	if (!applications || !readers || !readerAssessments) return undefined;

	const statuses = getStageStatuses(accStageId);

	const apps = applications
		.map(ctx => ctx.application)
		.filter(app => app.applicationStatusId >= statuses.pending.applicationStatusId);


	const applicantPersonIds: number[] = apps.map(app => app.personId);

	const applicationsRow: CountRow = {
		label: 'Readable Applications',
		count: apps.length,
		personIds: applicantPersonIds,
	}


	const configuredReaderMap = util.array.toMap(readers, (reader) => reader.reader.accReaderId);
	const configuredReaderPersonIds: number[] = util.array.cleanNumericIds(readers.map(r => r.reader.personId));

	const configuredReaderRow: CountRow = {
		label: 'Configured Readers',
		count: configuredReaderPersonIds.length,
		personIds: configuredReaderPersonIds,
	}



	const activeReaders = await ds.admin.accReader.getManyAsArray(readerAssessments.map(a => a.reading.accReaderId));
	const activeReaderPersonIds: number[] = util.array.cleanNumericIds(activeReaders.map(r => r.personId));

	const activeReaderRow: CountRow = {
		label: 'Active Readers',
		count: activeReaderPersonIds.length,
		personIds: activeReaderPersonIds,
	}

	const importedReaderPersonIds: number[] = util.array.cleanNumericIds(
		activeReaders.filter(reader => !configuredReaderMap[reader.accReaderId]).map(reader => reader.personId)
	);


	const importedReaderRow: CountRow = {
		label: 'Imported Readers',
		count: importedReaderPersonIds.length,
		personIds: importedReaderPersonIds,
	}

	const readings: CountRow = {
		label: 'Readings',
		count: readerAssessments.length,
		personIds: [],
	}


	const result: CountsTableData = {
		header: 'Item',
		rows: [
			applicationsRow,
			activeReaderRow,
			configuredReaderRow,
			importedReaderRow,
			readings,
		],
	};

	return result;
}