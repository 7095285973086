import { Injectable } from "@angular/core";
import { AppAreaIdentifierWithSite, DbsPitchContest, PicAreaAccess, PicAreaRawData } from "@me-interfaces";
import { AppAreaService } from "@me-services/core/area";
import { DataService } from "@me-services/core/data";
import { DexieService } from "@me-services/core/dexie";
import { FuncService } from "@me-services/core/func";
import { UtilityService } from "@me-services/core/utility";
import { PageSpinnerService } from "@me-services/ui/page-spinner";
import { UrlService } from "@me-services/ui/url";
import { UserAreaService } from "@me-user-area";
import { Subject } from "rxjs";
import { mapToData } from "./map-to-data";
import { PicAreaData } from "./pic-area-data";

const NO_ACCESS: PicAreaAccess = {
	root: 'None',
	sub: {
		awards: 'None',
	},
};

@Injectable({
	providedIn: 'root',
})
export class PicAreaService extends AppAreaService<AppAreaIdentifierWithSite<number>, PicAreaAccess, PicAreaRawData, PicAreaData> {

	//
	// Data subsets
	//
	public readonly pic$ = super.mapSubset(data => data.pic);
	public readonly contestJudgementCountsByPicTeamId$ = super.mapSubset(data => data.contestJudgementCountsByPicTeamId);


	//
	// Sub areas
	//


	constructor(
		urlService: UrlService,
		dexieService: DexieService,
		ds: DataService,
		spinnerService: PageSpinnerService,
		util: UtilityService,
		func: FuncService,
		userAreaService: UserAreaService,
	) {
		super(
			'Staff',
			userAreaService.user$,
			'Pic',
			dexieService,
			ds,
			spinnerService,
			util,
			urlService.appAreas.sitePicId$,
			func.areas.pic.get,
			async () => ({ area: 'Pic', access: NO_ACCESS, md5Hash: 'NO_ACCESS' }),
			async rawData => await mapToData(rawData, util, ds),
			data => data.pic.name,
		);
	}


	/**
	 * @deprecated Use super.subscribe(pic$) instead.
	 * 
	 * Should always be called in ngOnInit() after a call to this.initDestroyable();
	 * Subscribe to 'pic' changes and automatically unsubscribe when destroyed$ is triggered.
	 */
	public subscribePic(destroyed$: Subject<void>, callback: (value: DbsPitchContest) => Promise<void>) {
		super._subscribe(this.pic$, destroyed$, callback);
	}



}