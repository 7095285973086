import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DbsAward, EventTypeId, MutableDbsAward, TeamsForAward } from '@me-interfaces';
import { DdAwardNameRow, DdDecidingRoleRow, OldDomainDataService } from '@me-services/core/old-dd';
import { UtilityService } from '@me-services/core/utility';
import { AwardRow } from '../event-awards/SHR-PG_event-awards.part';

@Component({
	selector: 'award-edit',
	templateUrl: './SHR-PG_award-edit.part.html',
	styleUrls: ['SHR-PG_award-edit.part.scss'],
})
export class AwardEditPart implements OnChanges {

	@Input() eventTypeId: EventTypeId;
	@Input() award: DbsAward | AwardRow;
	@Input() readonly: boolean;
	@Input() list: TeamsForAward[] = [];
	@Input() isAdd: boolean;

	@Output() pendingAward = new EventEmitter<DbsAward>();

	orgAward: MutableDbsAward;
	newAward: MutableDbsAward;

	editing = false;

	awardKinds = this.dd.awardKinds.data;
	awardNames: DdAwardNameRow[] = this.dd.awardNames.data;
	decidingRoles: DdDecidingRoleRow[] = this.dd.decidingRoles.data;


	missing = {
		awardKindId: false,
		awardNameId: false,
		decidingRoleId: false,
		teamId: false,
		value: false,
	};


	notes = '';
	team: TeamsForAward;

	constructor(
		public dd: OldDomainDataService,
		public util: UtilityService
	) {
	}

	ngOnChanges() {
		this.initializeAward(this.award);
	}


	initializeAward(award: DbsAward) {
		if (!award) return;

		const awardNameIds = this.dd.awardNameEventTypes.data
			.filter(a => a.eventTypeId == this.eventTypeId)
			.map(a => a.awardNameId);

		this.awardNames = this.dd.awardNames.data.filter(a => awardNameIds.includes(a.awardNameId));

		this.orgAward = { ...award };
		this.newAward = { ...this.orgAward };

		this.notes = this.newAward.notes || '';
		const teamId = this.newAward.accTeamId || this.newAward.picTeamId;
		this.team = (this.list || []).find(l => l.teamId == teamId);
	}


	teamChange(team: TeamsForAward) {
		if (!team) return;
		if (team.teamId) {
			if (team.type == 'accTeam') this.newAward.accTeamId = team.teamId;
			else if (team.type == 'picTeam') this.newAward.picTeamId = team.teamId;

			this.team = team;
		}

		this.determineCanSave();
	}



	notesChange(text: string) {
		text = (text || '').trim();
		if (text.length) text = this.util.text.fixCase(text, 'sentence-case');
		this.notes = text;
		this.newAward.notes = text.trim();
		this.determineCanSave();
	}


	notesBlur() {
		this.notes = this.newAward.notes;
	}


	/**
	 * Whenever something changes, check if the save button should be enabled.
	 */
	determineCanSave() {

		this.missing.awardKindId = !this.newAward.awardKindId;
		this.missing.awardNameId = !this.newAward.awardNameId;
		this.missing.decidingRoleId = !this.newAward.decidingRoleId;
		this.missing.teamId = !this.team?.teamId;
		this.missing.value = !this.newAward.value;

		const changedSomething = !this.util.values.areSame(this.orgAward, this.newAward);
		const missingSomething = this.missing.awardKindId || this.missing.awardNameId || this.missing.decidingRoleId || this.missing.teamId || this.missing.value;

		if (changedSomething && !missingSomething) {
			this.pendingAward.emit(this.newAward);
		}
		else {
			this.pendingAward.emit(undefined);
		}
	}


	setValue(e: number) {
		this.newAward.value = e;
		this.determineCanSave();
	}


	setAwardName(e: number) {
		this.newAward.awardNameId = e;

		const decidingRoles = this.dd.awardNameDecidingRoles.data.map(a => {
			if (a.awardNameId == e) return a.decidingRoleId;
		}).join(',');

		this.decidingRoles = this.dd.decidingRoles.data.filter(a => decidingRoles.includes(a.decidingRoleId.toString()))

		this.newAward.decidingRoleId = undefined;
		this.determineCanSave();

	}


	setAwardKind(e: number) {
		this.newAward.awardKindId = e;
		this.determineCanSave();
	}


	setDecidingRole(e: number) {
		this.newAward.decidingRoleId = e;
		this.determineCanSave();
	}
}
