import { AccAreaData, AccAreaService, AccCohortPageService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DestroyablePart } from '@me-access-parts';
import { AccTeamCols, GridColumnType, GridSetup } from '@me-grid';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { Icon } from '@me-shared-parts/UI-common';
import { mergeMap } from 'rxjs/operators';
import { calculateParticipation } from '../team-participation/calculate-participation';
import { calculatePresentationScores } from './calculate-presentation-scores';


const OPEN_APPLICATION_ACTION_KEY = 'open-application';

interface TeamRow {
	accTeamId: number,
	applicationId: number,
	participationScore: number,
	presentationScore: number,
	score: number,
}


@Component({
	selector: 'acc-cohort-decide-awards-view-part',
	templateUrl: './acc-cohort-decide-awards-view.part.html',
})
export class AccCohortDecideAwardsViewPart extends DestroyablePart implements OnInit {

	public gridSetup = this.setupGrid();
	quarterlyEnabledStr: string;


	rows$ = this.accAreaService.data$.pipe(mergeMap(data => this.buildRows(data)));

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private accAreaService: AccAreaService,
		private ds: DataService,
		private util: UtilityService,
		private pageService: AccCohortPageService,
	) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();
	}

	async buildRows(data: AccAreaData): Promise<TeamRow[]> {

		const accTeams = data?.team.teams;

		if (!accTeams) return [];

		const participationByAccTeamId = await calculateParticipation(this.ds, this.util, data);
		const scoreByAccTeamId = await calculatePresentationScores(this.util, data);

		return accTeams.map(accTeam => {

			const accTeamId = accTeam.accTeamId;

			const participation = participationByAccTeamId[accTeamId];
			const participationScore = participation?.score ?? 0;
			const presentationScore = scoreByAccTeamId[accTeamId] ?? 0;
			const score = participationScore * .7 + presentationScore * .3;

			return {
				accTeamId,
				applicationId: accTeam.applicationId,
				participationScore,
				presentationScore,
				score,
			};
		});

	}


	private setupGrid(): GridSetup<TeamRow> {

		return {
			experience: 'ACCTEAM',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Team",
			rowPluralName: "Teams",
			rowKey: "accTeamId",
			stateKey: "adm-acc-cohort-decide-awards-page",
			canAdd: false,
			canRefresh: false,
			canDownload: true,
			columnsToAdd: [
				{ order: 250, field: 'participationScore', header: 'Participation Score', width: 80, type: GridColumnType.number, hidden: true },
				{ order: 250, field: 'presentationScore', header: 'Presentation Score', width: 80, type: GridColumnType.number, hidden: true },
				{ order: 250, field: 'score', header: 'Score', headerTooltip: 'Overall Score: Participation Score x .7 plus Presentation Score x .3', width: 80, type: GridColumnType.number },
			],
			columnsToAlter: [
				{ field: AccTeamCols.entrepreneurNames, width: 200, hidden: false },
			],
			actions: [
				{ key: OPEN_APPLICATION_ACTION_KEY, icon: Icon.concept_application, label: 'Open Application', enabled: false },
			],
			initialState: {
				sort: [{ field: 'score', dir: 'desc' }],
			},
		};
	}


	async gridActionHandler(action: { actionKey: string, rows: TeamRow[] }) {

		if (action.actionKey == OPEN_APPLICATION_ACTION_KEY) {
			if (action.rows[0].accTeamId) {
				this.router.navigate([`../applications/${action.rows[0].applicationId}`], { relativeTo: this.route });
			}
		}

	}
}