import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { DbcZip } from '@me-interfaces';
import { DdNearbyVenue, DdVenue, OldDomainDataService } from '@me-services/core/old-dd';
import { ListboxOption } from '@me-shared-parts/UI-common';
import { TabStripComponent } from '@progress/kendo-angular-layout';

@Component({
	selector: 'choose-venue-part',
	templateUrl: './SHR-ED_choose-venue.part.html',
	styleUrls: ['./SHR-ED_choose-venue.part.scss']
})
export class VenueChoosePart implements OnChanges {

	@Input() zip: DbcZip;
	@Input() venueInitialChoice: DdVenue;

	@Output() selection = new EventEmitter<DdNearbyVenue>();

	@ViewChild(TabStripComponent) tabs: TabStripComponent;

	venues: DdNearbyVenue[] = [];
	selectedVenue: DdNearbyVenue;


	options: ListboxOption[] = [];
	selectedOption: ListboxOption;


	constructor(private dd: OldDomainDataService) {
	}


	ngOnChanges() {
		//
		// If there is an initial venue, but there isn't already an option with that venue id,
		// then the venue was added and we should raise the selection event below.
		//
		const initialVenueWasAdded = !this.options.find(o => o.value == '' + this.venueInitialChoice?.venueId);

		this.venues = this.dd.venues.getNearbyVenues(this.zip, 50);
		this.valueChange(undefined);

		this.options = this.venues.map(v => ({ value: '' + v.venueId, text: v.displayedName }));
		this.selectedOption = undefined;

		if (this.venueInitialChoice) {
			this.selectedOption = this.options.find(o => o.value == '' + this.venueInitialChoice.venueId);
			if (this.selectedOption) this.selectedVenue = this.venues.find(v => v.venueId == this.venueInitialChoice.venueId);
			if (initialVenueWasAdded) this.valueChange(this.venueInitialChoice.venueId);
		}
	}


	valueChange(id: number) {

		const venueId = +id;

		if (isNaN(venueId)) {
			this.selectedVenue = undefined;
			this.selection.emit(undefined);
		}
		else {
			this.selectedVenue = this.venues.find(v => v.venueId == venueId);
			this.selection.emit(this.selectedVenue);
		}
	}


	getMapUrl(venue: DdNearbyVenue) {
		return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(venue.officialName)}&query_place_id=${venue.placeId}`;
	}

	onVenueChange(venue: DdVenue) {
		// Find and update the updated venue from the venues data.
		const updatedVenueIndex = this.venues.findIndex(v => v.venueId === venue.venueId);
		this.venues[updatedVenueIndex].displayedName = venue.displayedName;
		this.venues[updatedVenueIndex].publicNotes = venue.publicNotes;
		this.venues[updatedVenueIndex].internalNotes = venue.internalNotes;

		// Find and update the text from the options.
		const optionsIndex = this.options.findIndex(o => +o.value === venue.venueId);
		this.options[optionsIndex].text = venue.displayedName;
		if (+this.selectedOption?.value === venue.venueId) this.selectedOption.text = venue.displayedName;

		// Select the first tab venue.
		this.tabs.selectTab(0);
	}

	onVenueDelete(venueId: number) {
		// Find and delete the venue from the venues data.
		const deletedVenueIndex = this.venues.findIndex(v => v.venueId === venueId);
		if (deletedVenueIndex > -1) this.venues.splice(deletedVenueIndex, 1);

		// Find and delete the venue from the options.
		const optionsIndex = this.options.findIndex(o => +o.value === venueId);
		if (optionsIndex > -1) this.options.splice(optionsIndex, 1);

		// Set the selected option and selected venue to undefined.
		this.venueInitialChoice = undefined;
		this.selectedOption = undefined;
		this.selectedVenue = undefined;
	}

}