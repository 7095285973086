import { DbConceptName, Dbd, Immutable } from '@me-interfaces';


interface MutableDbdTimeZone extends Dbd {

	readonly _concept: DbConceptName.TimeZone,

	timeZoneId: number,
	name: string,
}


export interface DbdTimeZone extends Immutable<MutableDbdTimeZone> { }