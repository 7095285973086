<div class="address-field-dialog">

	<me-dialog-frame *ngIf='basePart' [header]='inlineLabels["Address"]' [actions]='actions' [dialogRef]='dialogRef'
		icon='fa-map-marker-alt' alertType='editor'>

		<field-chrome [basePart]='basePart' [highlightIfIncomplete]='false'>

			<div class='address-field-content'>

				<div>
					<kendo-label [text]='inlineLabels["Country"]'>

						<kendo-dropdownlist [data]="countryOptions" [textField]="'text'" [valueField]="'value'"
							[valuePrimitive]="true" [value]='pendingValue.inUS' (valueChange)='inUsChange($event)'
							[style.width.%]='100'>

							<ng-template kendoDropDownListValueTemplate let-dataItem>
								<me-label [key]='dataItem.text'></me-label>
							</ng-template>

							<ng-template kendoDropDownListItemTemplate let-dataItem>
								<me-label [key]='dataItem.text'></me-label>
							</ng-template>

						</kendo-dropdownlist>

					</kendo-label>
				</div>
				<div *ngIf='pendingValue.inUS==="Y"'>
					<kendo-label [text]='inlineLabels["Zip Code"]'>

						<input #zipField kendoTextBox placeholder="#####" maxlength="5" [style.width.%]='100'
							[disabled]='lookingUpZip' [class.has-error]='basePart.field.required && !pendingValue.cityAndState' [value]='zipCode'
							(input)='zipChange($event)'>

					</kendo-label>
				</div>
				<div>
					<kendo-label [text]='getStreet(pendingValue.inUS==="Y")'>

						<textarea #textArea kendoTextArea [placeholder]='getStreet(pendingValue.inUS==="Y")'
							[class.street-address-dlg]='pendingValue.inUS==="N"'
							[class.full-address-dlg]='pendingValue.inUS==="N"' [style.width.%]='100'
							[class.has-error]='basePart.field.required && !pendingValue.address'
							(input)='streetChange($event)'>{{pendingValue.address}}</textarea>


						<div style='margin: 0.25em 0 0 0.5em'>

							<div *ngIf='lookingUpZip'>
								<i class='far fa-sync-alt fa-spin'></i>
							</div>

							<div *ngIf='invalidZip' class='invalid-zip'>
								<me-label key='Invalid ZIP'></me-label>: {{ zipCode }}
							</div>

							<div *ngIf='pendingValue.cityAndState'>
								{{pendingValue.cityAndState}} {{zipCode}}
							</div>
						</div>

					</kendo-label>
				</div>

			</div>
		</field-chrome>

	</me-dialog-frame>
</div>