
import { DdTagPrefixRow } from './dd-tag-prefix-row';
import { DdTagPrefix } from './dd-tag-prefix';
import { DdObjects } from '../dd-objects';
import { OldDomainDataService } from '..';

/**
 * @deprecated Use the DataService instead
 */
export class DdTagPrefixes extends DdObjects<DdTagPrefixRow, DdTagPrefix> {

	constructor(private dd: OldDomainDataService) {
		super('tagPrefixId');
	}

	async loadData(hash: string, data: DdTagPrefixRow[]) {
		const objects = data.map(d => new DdTagPrefix(this.dd, d));
		this.loadObjects(hash, objects);
	}

	public getBySiteId(siteId: number) {
		return this._objects.find(tagPrefix => tagPrefix.siteId == siteId);
	}

}