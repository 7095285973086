interface ElapsedTimerSnapshot {
	/** Offset from the startMs in milliseconds */
	offset: number,
	message: string,
	detail?: unknown,
}


export class ElapsedTimer {

	completed = false;
	startDate = new Date();
	startMs = this.startDate.getTime();

	snapshots: ElapsedTimerSnapshot[] = [];

	/**
	 * Create an object that remembers a start time and then emits logs of the elapsed time at any snapshots and the total elapsed time when ended.
	 * @param name The name of the log instance. It should usually be the name of the function the log resides in.
	 */
	constructor(private name: string, private detail?: unknown) {
	}


	public addSnapshot(message: string, detail?: unknown) {
		if (this.completed) {
			console.error(`Call to ElapsedTimer.snapshot('${message}') after the ${this.name} timer has ended.`);
			return;
		}

		this.snapshots.push({ offset: Date.now() - this.startMs, message, detail });
	}



	public endAndRender(detail?: unknown) {
		if (this.completed) {
			console.error(`Call to ElapsedTimer.endAndRender() after the ${this.name} timer has ended.`);
			return;
		}

		const endDate = new Date();

		console.log('----');

		this.logWithOffset(0, `${this.name} started at ${this.startDate.toLocaleTimeString()}`, this.detail);

		for (const snapshot of this.snapshots) {
			this.logWithOffset(snapshot.offset, `${snapshot.message}`, snapshot.detail);
		}

		this.logWithOffset(endDate.getTime() - this.startMs, `${this.name} ended at ${endDate.toLocaleTimeString()}`, detail);

		console.log('----');
	}


	/**
	 * Log a message to the console with a preceding number of milliseconds and an optional detail object
	 */
	private logWithOffset(ms: number, message: string, detail?: unknown) {

		const offset = this.formatOffset(ms);

		if (detail) console.log(`${offset} ${message}`, detail);
		else console.log(`${offset} ${message}`);
	}


	/**
	 * Given a number of milliseconds, format the value and right-align it in a buffer of spaces.
	 * @param ms The count of milliseconds that represents an offset from the startMs value
	 */
	private formatOffset(ms: number) {

		const MIN_LENGTH = 10;

		let value = ms.toLocaleString();
		if (value.length < MIN_LENGTH) value = (Array(MIN_LENGTH).join(' ') + value).slice(-MIN_LENGTH);

		return value;
	}
}