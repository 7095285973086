
import { DdObject } from '../dd-object';
import { OldDomainDataService } from '../domain-data.service';
import { DdCityRow } from './dd-city-row';

/**
 * @deprecated Use the DataService instead
 */
export class DdCity extends DdObject<DdCityRow> implements DdCityRow {

	constructor(private dd: OldDomainDataService, data: DdCityRow) {
		super(data);
	}

	get cityId(): number {
		return this._data.cityId;
	}

	/**
	 * This returns the cityAndState. Use cityAndState intead
	 */
	get name(): string {
		return this._data.name;
	}

	get state(): string {
		return this._data.state;
	}


	get cityAndState(): string {
		return this.name;
	}

	get city(): string {
		return this.name?.split(',')[0];
	}
}