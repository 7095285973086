import { Component, Input, OnChanges } from '@angular/core';
import { DbsPerson } from '@me-interfaces';

const AVATAR_ROOT_URL = 'https://me-dia.eforall.org/avatars/';

@Component({
	selector: 'me-avatar',
	templateUrl: './SHR-CMN_avatar.part.html',
	styleUrls: ['SHR-CMN_avatar.part.scss'],
})
export class AvatarPart implements OnChanges {

	@Input() size: '30px' | '35px' | '40px' = '35px';
	@Input() avatar: DbsPerson;

	public imgSrc = 'assets/img/users/avatar.png';

	ngOnChanges() {
		if (!this.avatar) throw new Error('Required avatar attribute not provided on me-avatar component.');

		// TODO:
		//   Rename hasAvatar to avatarChangedUTC and make it a nullable int
		//   If avatarChangedUTC is not null override the imgSrc and use the UTC for cache busting
	}

	getClass() {
		if (this.size == '30px') return 'size30';
		else if (this.size == '35px') return 'size35';
		else if (this.size == '40px') return 'size40';
		else return '';
	}


}
