
/**
 * The set of filters to be used along with any search text.
 * Changes to this also require changes to...
 *   - The checkboxes part in the search dialog
 *   - The SearchService.canSearch$ observable
 */
export interface SearchFilters {
	awardee?: boolean,
	contactAdmin?: boolean,
	deceased?: boolean,
	droppedOut?: boolean,
	eforallAdmin?: boolean,
	noContact?: boolean,
	redFlag?: boolean,
	techAdmin?: boolean,

	/** True if at least one of the filters is enabled */
	filtered: boolean,
}

/**
 * The text will be split into all substrings containing each unique run of words.
 * The text contains the full original phrase cleaned up some.
 */
export function parseSearchText(text: string): string {

	//
	// Clean up the text.
	//
	text = text
		.trim()
		.split(' ')
		.filter(part => !!part) // remove any empty ones (if multiple spaces in a row)
		.join(' ');

	return text;

}