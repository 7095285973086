import { Injectable } from "@angular/core";
import { AppAreaIdentifier, DashboardCard, DbcUser, UserAreaAccess, UserAreaRawData } from "@me-interfaces";
import { AppSessionService } from "@me-services/core/app-session";
import { AppAreaService } from "@me-services/core/area";
import { AuthService, CustomAnalyticsEventName } from "@me-services/core/auth";
import { DataService } from "@me-services/core/data";
import { DexieService } from "@me-services/core/dexie";
import { FuncService } from "@me-services/core/func";
import { UtilityService } from "@me-services/core/utility";
import { MenuService } from "@me-services/ui/menu";
import { PageSpinnerService } from "@me-services/ui/page-spinner";
import { UrlService } from "@me-services/ui/url";
import { configureScope, setUser } from "@sentry/angular-ivy";
// import { H as Highlight } from 'highlight.run';
import { ReplaySubject } from "rxjs";
import { filter, take } from "rxjs/operators";
import { mapToData } from "./map-to-data";
import { UserAreaData } from "./user-area-data";
import { UserSubAreaCompany } from "./user-subarea-company";
import { UserSubAreaGoals } from "./user-subarea-goals";
import { UserSubAreaProfile } from "./user-subarea-profile";
import { UserSubAreaProgram } from "./user-subarea-program";



const NO_ACCESS: UserAreaAccess = {
	root: 'None',
	sub: {},
};

const user$ = new ReplaySubject<DbcUser | undefined>(1);

@Injectable({
	providedIn: 'root',
})
export class UserAreaService extends AppAreaService<AppAreaIdentifier<string>, UserAreaAccess, UserAreaRawData, UserAreaData> {

	//
	// Todo: The following subjects were moved from the old UserService. They should be
	// removed and factored into area service data subsets
	//
	public readonly user$ = user$;
	public readonly initialCards$ = new ReplaySubject<DashboardCard[]>(1);

	//
	// Sub areas
	//
	public readonly goals = new UserSubAreaGoals(this.func, this.ds, this.util, super.getId.bind(this), this.applyResponse.bind(this));
	public readonly profile = new UserSubAreaProfile(this.func, this.ds, this.util, super.getId.bind(this), this.applyResponse.bind(this));
	public readonly company = new UserSubAreaCompany(this.func, this.ds, this.util, super.getId.bind(this), this.applyResponse.bind(this));
	public readonly program = new UserSubAreaProgram(this.func, this.ds, this.util, super.getId.bind(this), this.applyResponse.bind(this));

	constructor(
		private auth: AuthService,
		private appSessionService: AppSessionService,
		urlService: UrlService,
		dexieService: DexieService,
		spinnerService: PageSpinnerService,
		ds: DataService,
		util: UtilityService,
		private menu: MenuService,
		private func: FuncService,
	) {
		super(
			'Public',
			user$,
			'User',
			dexieService,
			ds,
			spinnerService,
			util,
			urlService.appAreas.access$,
			async (payload: { identifier: AppAreaIdentifier<string>, singletonsCacheUTC: number }) => {

				await this.appSessionService.status$.pipe(
					filter(status => status.state == 'SESSION_STARTED'),
					take(1),
				).toPromise();

				const response = await func.areas.user.get(payload);
				return response;
			},
			async () => ({ area: 'User', access: NO_ACCESS, md5Hash: 'NO_ACCESS' }),
			async rawData => await mapToData(rawData, util, ds),
			data => 'User (todo: get user name)',
		);


		setTimeout(() => {
			auth.isSignedIn$.subscribe(async isSignedIn => {

				await this.ds.applyDomainCacheFiles();

				if (isSignedIn) {
					const { user, cards, singletonsCacheConfig } = await func.public.user.start();

					setUser({ id: '' + user.personId, email: user._email, username: user._name });

					// Highlight.identify(user._email, {
					// 	name: user._name,
					// 	personId: user.personId,
					// 	phone: user.phone,
					// });

					await this.ds.applySingletonsCacheConfig(singletonsCacheConfig);
					// await this.ds.admin.person.addOne(person);

					await this.logUserToAnalytics(user);
					user$.next(user);
					this.menu.userChanged(user);

					this.initialCards$.next(cards);

					if (user.isTechAdmin) this.util.log.enableTechMessages();
				}
				else {
					configureScope(scope => scope.setUser(null));
					user$.next(undefined);
					this.menu.userChanged(undefined);
					this.initialCards$.next([]);
				}
			});
		}, 100);
	}



	private async logUserToAnalytics(user: DbcUser) {

		if (!user) return;

		this.auth.analytics?.logEvent(CustomAnalyticsEventName.USER_GET, {
			personId: user.personId,
			name: user._name,
			email: user._email,
			entityId: user.entityId,
			isCrmUser: user.isCrmUser ? 1 : 0,
			zipId: user.zipId,
			canEditDemographics: user.canEditDemographics ? 1 : 0,
			isEForAllAdmin: user.isEForAllAdmin ? 1 : 0,
			isTechAdmin: user.isTechAdmin ? 1 : 0,
			phone: user.phone || '',
		});

		//
		// Add the custom properties to analytics
		//
		const props: firebase.default.analytics.CustomParams = {};
		props.PersonId = user.personId;
		props.Email = user._email;
		this.auth.analytics?.setUserProperties(props);
	}

}