import { AccApplicationContext, AccAreaService } from '@ACC-area';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { AccStageId } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { CountRow, CountsTableData } from '@me-shared-parts/UI-common';
import { ReplaySubject, combineLatest } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ApplicationGridApplicationStatus, ApplicationGridFilter } from '../../../acc-application-filter-helpers';
import { AccPreAcceleratorStageId, getStageStatuses } from '../../../acc-application-status-helpers';


@Component({
	selector: 'acc-application-demographics-counts-part',
	templateUrl: './acc-application-demographics-counts.part.html',
})
export class AccApplicationDemographicsCountsPart implements OnChanges {

	@Input() accStageId: AccPreAcceleratorStageId = AccStageId.Accepting;
	@Input() applicationStatus: ApplicationGridApplicationStatus;
	@Output() filter = new EventEmitter<ApplicationGridFilter>();

	/** Bumped each time OnChanges is fired. */
	private changes$ = new ReplaySubject<SimpleChanges>(1);

	data$ = combineLatest([this.changes$, this.accAreaService.applications.applications$]).pipe(mergeMap(latest => this.buildRows(latest[1])));


	constructor(
		private accAreaService: AccAreaService,
		private ds: DataService,
	) {
	}


	ngOnChanges(changes: SimpleChanges) {
		this.changes$.next(changes);
	}


	async buildRows(applications: readonly AccApplicationContext[]): Promise<CountsTableData> {

		if (!applications) return { header: 'Demographics', rows: [] };

		const statuses = getStageStatuses(this.accStageId);

		const all: CountRow = { label: 'All', count: 0, percent: 0, personIds: [] };
		const woman: CountRow = { label: 'Female', count: 0, percent: 0, personIds: [] };
		const notMan: CountRow = { label: 'Not Male', count: 0, percent: 0, personIds: [] };
		const bipoc: CountRow = { label: 'Bipoc', count: 0, percent: 0, personIds: [] };
		const asian: CountRow = { label: 'Asian', count: 0, percent: 0, personIds: [] };
		const black: CountRow = { label: 'Black', count: 0, percent: 0, personIds: [] };
		const white: CountRow = { label: 'White', count: 0, percent: 0, personIds: [] };
		const nativeAmerican: CountRow = { label: 'Indigenous', count: 0, percent: 0, personIds: [] };
		const pacificIslander: CountRow = { label: 'Pacific Islander', count: 0, percent: 0, personIds: [] };
		const otherRace: CountRow = { label: 'Other Race', count: 0, percent: 0, personIds: [] };
		const hispanic: CountRow = { label: 'Hispanic', count: 0, percent: 0, personIds: [] };
		const immigrant: CountRow = { label: 'Immigrant', count: 0, percent: 0, personIds: [] };
		const veteran: CountRow = { label: 'Veteran', count: 0, percent: 0, personIds: [] };
		const spanish: CountRow = { label: 'Spanish', count: 0, percent: 0, personIds: [] };
		const english: CountRow = { label: 'English', count: 0, percent: 0, personIds: [] };
		const deceased: CountRow = { label: 'Deceased', count: 0, percent: 0, personIds: [] };

		//
		// Clean up the list of applications
		//
		const apps = applications
			.filter(ctx => {
				if (this.applicationStatus == 'AcceptedOrBeyond') return ctx.application.applicationStatusId >= statuses.accepted.applicationStatusId;
				else return ctx.application.applicationStatusId >= statuses.pending.applicationStatusId;
			});

		//
		// Count them by status
		//
		for (const ctx of apps) {

			const app = ctx.application;
			const dgx = ctx.demographics;

			all.count++;
			all.personIds.push(app.personId);

			if (!dgx) continue;

			if (dgx.female) { woman.count++; woman.personIds.push(app.personId); }
			if (dgx.notMale) { notMan.count++; notMan.personIds.push(app.personId); }

			if (dgx.bipoc) { bipoc.count++; bipoc.personIds.push(app.personId); }
			if (dgx.asian) { asian.count++; asian.personIds.push(app.personId); }
			if (dgx.black) { black.count++; black.personIds.push(app.personId); }
			if (dgx.white) { white.count++; white.personIds.push(app.personId); }
			if (dgx.nativeAmerican) { nativeAmerican.count++; nativeAmerican.personIds.push(app.personId); }
			if (dgx.pacificIslander) { pacificIslander.count++; pacificIslander.personIds.push(app.personId); }
			if (dgx.otherRace) { otherRace.count++; otherRace.personIds.push(app.personId); }

			if (dgx.hispanic) { hispanic.count++; hispanic.personIds.push(app.personId); }
			if (dgx.immigrant) { immigrant.count++; immigrant.personIds.push(app.personId); }

			if (dgx.veteran) { veteran.count++; veteran.personIds.push(app.personId); }

			if (dgx.spanish) { spanish.count++; spanish.personIds.push(app.personId); }
			if (dgx.english) { english.count++; english.personIds.push(app.personId); }

			if (dgx.deceased) { deceased.count++; deceased.personIds.push(app.personId); }

		}


		//
		// Remove duplicate personId 
		//
		all.personIds = this.ds.util.array.cleanNumericIds(all.personIds);
		woman.personIds = this.ds.util.array.cleanNumericIds(woman.personIds);
		notMan.personIds = this.ds.util.array.cleanNumericIds(notMan.personIds);
		bipoc.personIds = this.ds.util.array.cleanNumericIds(bipoc.personIds);
		asian.personIds = this.ds.util.array.cleanNumericIds(asian.personIds);
		nativeAmerican.personIds = this.ds.util.array.cleanNumericIds(nativeAmerican.personIds);
		otherRace.personIds = this.ds.util.array.cleanNumericIds(otherRace.personIds);
		pacificIslander.personIds = this.ds.util.array.cleanNumericIds(pacificIslander.personIds);
		white.personIds = this.ds.util.array.cleanNumericIds(white.personIds);
		hispanic.personIds = this.ds.util.array.cleanNumericIds(hispanic.personIds);
		immigrant.personIds = this.ds.util.array.cleanNumericIds(immigrant.personIds);
		veteran.personIds = this.ds.util.array.cleanNumericIds(veteran.personIds);
		spanish.personIds = this.ds.util.array.cleanNumericIds(spanish.personIds);
		english.personIds = this.ds.util.array.cleanNumericIds(english.personIds);
		deceased.personIds = this.ds.util.array.cleanNumericIds(deceased.personIds);


		//
		// Set percents
		//
		const total = all.count;
		if (total > 0) {
			all.percent = 100;
			woman.percent = woman.count / total * 100;
			notMan.percent = notMan.count / total * 100;
			bipoc.percent = bipoc.count / total * 100;
			asian.percent = asian.count / total * 100;
			nativeAmerican.percent = nativeAmerican.count / total * 100;
			otherRace.percent = otherRace.count / total * 100;
			pacificIslander.percent = pacificIslander.count / total * 100;
			white.percent = white.count / total * 100;
			hispanic.percent = hispanic.count / total * 100;
			immigrant.percent = immigrant.count / total * 100;
			veteran.percent = veteran.count / total * 100;
			spanish.percent = spanish.count / total * 100;
			english.percent = english.count / total * 100;
			deceased.percent = deceased.count / total * 100;
		}


		const result = {
			header: 'Status', rows: [
				all,
				woman,
				notMan,
				bipoc,
				asian,
				black,
				white,
				nativeAmerican,
				pacificIslander,
				otherRace,
				hispanic,
				immigrant,
				veteran,
				spanish,
				english,
				deceased,
			],
		};

		return result;
	}

	async openContactor(personIds: number[]) {
		await this.ds.contactor.show(
			false,
			personIds,
			'acc-applications-counts-part',
		);
	}

	countClick(row: CountRow) {
		this.filter.emit({ accStageId: this.accStageId, applicationStatus: this.applicationStatus, field: 'demographics', value: row.label });
	}
}