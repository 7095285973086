import {
	CompanyStatus,
	CompanyOfferings,
	CompanyTypeId
} from '@me-interfaces';

export interface Old_CompanyUpdate {
	profileUpdate: boolean,
	companyTypeId: CompanyTypeId,
	name: string,
	shortName: string | undefined,
	description: string | undefined,
	phone: string | undefined,
	inUS: boolean,
	address: string | undefined,
	zipCode: string | undefined,
	status: CompanyStatus,
	offerings?: CompanyOfferings,
	industryId: number | undefined,
	website: string | undefined,
	linkedin: string | undefined,
	facebook: string | undefined,
	twitter: string | undefined,
}