import { EventEmitter } from "@angular/core";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { Icon } from "@me-shared-parts/UI-common/icon";
import { AccSurveyResponseTopicCols, buildColumn, GridColumnConfig } from '../columns';
import { GRID_EXPERIENCE, GridSetup } from "../interfaces";
import { GridExperience } from "./grid-experience";


/**
 * Standard experience for a grid of acc survey responses for topic
 */
export class AccSurveyResponseTopicGridExperience<ROW> extends GridExperience<ROW> {

	public static readonly experience: GRID_EXPERIENCE = 'ACC_SURVEY_RESPONSE_TOPIC';

	constructor(
		private ds: DataService,
		private util: UtilityService,
		setup: GridSetup<ROW>,
		gridAction: EventEmitter<{ actionKey: string; rows: ROW[]; }>,
	) {
		super(
			util, setup,
			[
				buildColumn(AccSurveyResponseTopicCols.accSessionTopicSurveyResponseId, GridColumnConfig.id, { hidden: true, header: 'accSessionTopicSurveyResponseId' }),
				buildColumn(AccSurveyResponseTopicCols.accSessionAttendeeId, GridColumnConfig.id, { hidden: true, header: 'accSessionAttendeeId' }),
				buildColumn(AccSurveyResponseTopicCols.topicId, GridColumnConfig.id, { hidden: true, header: 'topicId' }),
				buildColumn(AccSurveyResponseTopicCols.siteId, GridColumnConfig.siteId, { hidden: true }),
				buildColumn(AccSurveyResponseTopicCols.accId, GridColumnConfig.accId, { hidden: true }),
				buildColumn(AccSurveyResponseTopicCols.eventId, GridColumnConfig.eventId, { hidden: true }),
				buildColumn(AccSurveyResponseTopicCols.programManagerId, GridColumnConfig.personId, { hidden: true, header: 'programManagerId', headerTooltip: 'The personId of the accelerator program manager' }),
			],
			[
				buildColumn(AccSurveyResponseTopicCols.stateCode, GridColumnConfig.stateCode, { hidden: true }),
				buildColumn(AccSurveyResponseTopicCols.siteCode, GridColumnConfig.siteCode, { hidden: true }),
				buildColumn(AccSurveyResponseTopicCols.siteName, GridColumnConfig.siteName, { hidden: true }),
				buildColumn(AccSurveyResponseTopicCols.accYear, GridColumnConfig.year, { hidden: true }),
				buildColumn(AccSurveyResponseTopicCols.accSeason, GridColumnConfig.accSeason, { hidden: true }),
				buildColumn(AccSurveyResponseTopicCols.accName, GridColumnConfig.accName, { hidden: true }),
				buildColumn(AccSurveyResponseTopicCols.session, GridColumnConfig.text, { hidden: true, header: 'Session', width: 120 }),
				buildColumn(AccSurveyResponseTopicCols.venue, GridColumnConfig.venue, { hidden: true }),
				buildColumn(AccSurveyResponseTopicCols.sessionTopics, GridColumnConfig.text, { hidden: true, header: 'Session Topics', width: 120 }),
				buildColumn(AccSurveyResponseTopicCols.topic, GridColumnConfig.text, { header: 'Topic', width: 120 }),
			],
			[
				buildColumn(AccSurveyResponseTopicCols.relevanceBucket, GridColumnConfig.text, { hidden: false, header: 'Relevance', headerTooltip: 'Relevance: "This topic is important for my business."', width: 80 }),
				buildColumn(AccSurveyResponseTopicCols.relevance, GridColumnConfig.number, { hidden: true, header: 'Relevance Rating', headerTooltip: 'Relevance: "This topic is important for my business. (0..10)"', width: 80 }),
				buildColumn(AccSurveyResponseTopicCols.needBucket, GridColumnConfig.text, { hidden: false, header: 'Need', headerTooltip: 'Need: "I will use this topic knowledge within the next three months."', width: 80 }),
				buildColumn(AccSurveyResponseTopicCols.need, GridColumnConfig.number, { hidden: true, header: 'Need Rating', headerTooltip: 'Need: "I will use this topic knowledge within the next three months. (0..10)"', width: 80 }),
				buildColumn(AccSurveyResponseTopicCols.understandingBucket, GridColumnConfig.text, { hidden: false, header: 'Understanding', headerTooltip: 'Understanding: "I have a better understanding of this topic and can more confidently apply it."', width: 80 }),
				buildColumn(AccSurveyResponseTopicCols.understanding, GridColumnConfig.number, { hidden: true, header: 'Understanding Rating', headerTooltip: 'Understanding: "I have a better understanding of this topic and can more confidently apply it. (0..10)"', width: 80 }),
				buildColumn(AccSurveyResponseTopicCols.durationBucket, GridColumnConfig.text, { hidden: false, header: 'Duration', headerTooltip: 'Duration: "There was adequate time dedicated to this topic."', width: 80 }),
				buildColumn(AccSurveyResponseTopicCols.duration, GridColumnConfig.number, { hidden: true, header: 'Duration Rating', headerTooltip: 'Duration: "There was adequate time dedicated to this topic. (0..10)"', width: 80 }),
				buildColumn(AccSurveyResponseTopicCols.other, GridColumnConfig.text, { hidden: true, header: 'Comment', headerTooltip: 'Other Feedback (optional)' }),
				buildColumn(AccSurveyResponseTopicCols.createdUTC, GridColumnConfig.createdUtc, { hidden: true }),
			],
			[
				// { key: EXPLORE_GRID_ACTION_KEY, icon: Icon.dialog_explore, label: `Explore ${setup.rowSingularName}` },
				// { key: OPEN_GRID_ACTION_KEY, icon: Icon.action_open, label: `Open ${setup.rowSingularName}` },
				// { key: NOTES_GRID_ACTION_KEY, icon: Icon.action_editNotes, label: `Edit Survey Notes` },
			],
			gridAction,
			Icon.view_surveys,
		);
	}


	public async applyBaseValues(rows: ROW[]) {

		const accSessionTopicSurveyResponseIds = rows.map(row => <number>row['accSessionTopicSurveyResponseId']);
		const accSessionTopicSurveyResponseMap = await this.ds.admin.accSessionTopicSurveyResponse.getManyPackagesAsMap(accSessionTopicSurveyResponseIds);


		for (const row of rows) {
			const accSessionTopicSurveyResponseId = <number>row['accSessionTopicSurveyResponseId'];
			const response = accSessionTopicSurveyResponseMap[accSessionTopicSurveyResponseId];

			row[AccSurveyResponseTopicCols.accSessionTopicSurveyResponseId] = accSessionTopicSurveyResponseId;
			row[AccSurveyResponseTopicCols.accSessionAttendeeId] = response.accSessionAttendeeId;
			row[AccSurveyResponseTopicCols.topicId] = response.topicId;
			row[AccSurveyResponseTopicCols.siteId] = response.accelerator.siteProgram.siteId;
			row[AccSurveyResponseTopicCols.accId] = response.accId;
			row[AccSurveyResponseTopicCols.eventId] = response.eventId;
			row[AccSurveyResponseTopicCols.programManagerId] = response.accelerator.managerId;

			row[AccSurveyResponseTopicCols.session] = this.util.date.formatUTC(response.event.startUTC, 'MMM D, YYYY (DOW)', 'H:MM AM EST', this.ds.languageId);
			row[AccSurveyResponseTopicCols.venue] = response.event.venueId ? response.event.venue?.displayedName || 'Online' : 'Online';
			row[AccSurveyResponseTopicCols.sessionTopics] = response.event.name;
			row[AccSurveyResponseTopicCols.topic] = response.topic.longNameLabel;
			row[AccSurveyResponseTopicCols.relevanceBucket] = this.util.nps.getBucket(response.relevance);
			row[AccSurveyResponseTopicCols.relevance] = response.relevance;
			row[AccSurveyResponseTopicCols.needBucket] = this.util.nps.getBucket(response.need);
			row[AccSurveyResponseTopicCols.need] = response.need;
			row[AccSurveyResponseTopicCols.understandingBucket] = this.util.nps.getBucket(response.understanding);
			row[AccSurveyResponseTopicCols.understanding] = response.understanding;
			row[AccSurveyResponseTopicCols.durationBucket] = this.util.nps.getBucket(response.duration);
			row[AccSurveyResponseTopicCols.duration] = response.duration;
			row[AccSurveyResponseTopicCols.other] = response.other;

			row[AccSurveyResponseTopicCols.stateCode] = response.accelerator.siteProgram.site.stateCode;
			row[AccSurveyResponseTopicCols.siteCode] = response.accelerator.siteProgram.site.code6;
			row[AccSurveyResponseTopicCols.siteName] = response.accelerator.siteProgram.site.name;
			row[AccSurveyResponseTopicCols.accName] = response.accelerator.name;
			row[AccSurveyResponseTopicCols.accYear] = response.accelerator.year;
			row[AccSurveyResponseTopicCols.accSeason] = response.accelerator.season;
			row[AccSurveyResponseTopicCols.createdUTC] = response.createdUTC;

		}
	}


	public sortRows(rows: ROW[]) {
		// rows.sort((row1: ROW, row2: ROW) => {
		// 	const val1 = row1[AccTeamCols.companyName];
		// 	const val2 = row2[AccTeamCols.companyName];
		// 	return val1 < val2 ? -1 : 1;
		// });
		return 0;
	}


	public async explore(row: ROW) {
		// await this.ds.explorer.explore(DbConceptName.AccTeam, row[AccTeamCols.accTeamId]);
	}


	public async open(row: ROW) {
		// this.router.navigate([`access/admin/national/sites/${row['siteId']}/accelerators/${row['accId']}/cohort/${row[AccTeamCols.accTeamId]}`]);
	}


	public async editNotes(row: ROW) {
		// Notes dialog not implemented
	}
}