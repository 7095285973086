<me-dialog-frame [header]='header' [actions]='actions.list' [dialogRef]='dialogRef' [icon]='icon'>

	<div class='event-dialog-editor-part'>

		<div class='timezone-message'>
			<small>All times are local to {{timezone}}.</small>
		</div>

		<kendo-tabstrip (tabSelect)='onTabSelect($event)'>

			<kendo-tabstrip-tab *ngIf='!adding' title='Event' [selected]="selectedTab === 0">
				<ng-template kendoTabContent>

					<event-dialog-view [eventContextRows]='eventContextRows' [event]='event'
						[toolIconMessage]='toolIconMessage'></event-dialog-view>

				</ng-template>
			</kendo-tabstrip-tab>

			<kendo-tabstrip-tab *ngIf='userCanEdit' [title]='adding? "Add" : "Edit"' [selected]="selectedTab === 1">
				<ng-template kendoTabContent>

					<event-dialog-edit [eventContextRows]='eventContextRows' [event]='event'
						[toolIconMessage]='toolIconMessage' (pendingChanges)='setPendingChanges($event)'>
					</event-dialog-edit>

				</ng-template>
			</kendo-tabstrip-tab>

			<kendo-tabstrip-tab *ngIf="!adding && showAwards" title='Awards' [selected]="selectedTab === 2">
				<ng-template kendoTabContent>

					<event-awards-view [eventId]='event.eventId' [readonly]='!userCanEdit' [list]='teamsForAward'  [gridSize]='awardsGridSize'>
					</event-awards-view>

				</ng-template>
			</kendo-tabstrip-tab>

			<!-- Person events attendee-->
			<kendo-tabstrip-tab *ngIf="!adding && showAttendees" title='Attendees' [selected]="selectedTab === 3">
				<ng-template kendoTabContent>

					<event-attendees-view [eventId]='event.eventId' [readonly]='!userCanEdit'></event-attendees-view>

				</ng-template>
			</kendo-tabstrip-tab>

		</kendo-tabstrip>

	</div>
</me-dialog-frame>