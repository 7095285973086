import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { GridSetup } from '@me-grid';
import { DataService } from '@me-services/core/data';
import { mergeMap } from 'rxjs';


interface SitesRow {
	siteId: number,
}

@Component({
	selector: 'sites-page',
	templateUrl: './sites.page.html',
})
export class SitesPage extends DestroyablePart implements OnInit {

	public gridSetup = this.setupGrid();

	public rows$ = this.ds.admin.singletonsAsOfUTC$
		.pipe(mergeMap(async utc => {

			const sites = await this.ds.admin.site.getAllAsArray();
			return sites.map(s => ({ siteId: s.siteId }));
		}));


	constructor(private ds: DataService) {
		super();
	}

	async ngOnInit() {
		super.initDestroyable();
	}


	private setupGrid(): GridSetup<SitesRow> {
		return {
			experience: 'SITE',
			size: {
				fitTo: 'PAGE',
				heightMultiplier: 1,
				shrinkBy: 0,
			},
			rowSingularName: "Site",
			rowPluralName: "Sites",
			rowKey: "siteId",
			stateKey: "national-sites-page",
			canAdd: false,
			canRefresh: false,
			canDownload: true,
		};
	}

	async gridActionHandler(action: { actionKey: string, rows: SitesRow[] }) {
		const row = action.rows[0];
	}
}
