import { Component, HostListener, Input } from '@angular/core';

@Component({
	selector: 'me-save-changes-message',
	styleUrls: ['./SHR-CMN_save-changes-message.part.scss'],
	template:
		`
	<div class="save-changes-message pulse" >
		<i class="fas fa-exclamation-triangle" aria-hidden="true" title="Unsaved Changes"></i>
		<me-label key='Unsaved Changes'></me-label>
	</div>
  `
})
export class SaveChangesMessagePart {
	@Input() showSpeed = 500;
	@Input() moveSpeed = 1000;



	@HostListener('click')
	_onClick(): boolean {
		const x = jQuery('.main-wrapper');
		const scrollTop = x[0].scrollHeight - x[0].clientHeight;
		x.animate({ scrollTop }, { duration: this.moveSpeed });
		return false;
	}

}
