import { AccAreaService } from '@ACC-area';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AccMatchableMentor, AccMatchingSchedule, AccStageId, AccTeam, DbConceptName, LanguageId, SevenNums } from '@me-interfaces';
import { DialogService } from '@me-services/ui/dialog';
import { LabelsService } from '@me-services/ui/labels';
import { DAY_NAMES, DAY_NAMES_ES, MAX_SLOT_VALUE, SLOT_NAMES, SLOT_NAMES_ES, hammingWeight, isBitOff, isBitOn, meetingSlots, slotsToMeetingStarts, turnBitOff, turnBitOn } from '@me-services/ui/schedule-utils';
import { lastValueFrom, take } from 'rxjs';


@Component({
	selector: 'acc-mm-schedule-selector',
	styleUrls: ['./acc-mm-schedule-selector.part.scss'],
	templateUrl: './acc-mm-schedule-selector.part.html'
})
export class AccScheduleSelectorPart extends DestroyablePart implements OnInit, OnChanges {

	@Input() schedulee: AccTeam | AccMatchableMentor;
	private accessAtStage$ = this.accAreaService.getAccessAtStage(AccStageId.MatchingMentors);
	readonly: boolean;
	lastDate = 0;

	// Slots are the values entered by the user where each bit flag represents a half hour slot (2^0 -> 2^29)
	slots: SevenNums = [0, 0, 0, 0, 0, 0, 0];
	blockout: SevenNums = [0, 0, 0, 0, 0, 0, 0];

	// The slot flags that are in a sequence of at least 3 that are on (a valid 90 minute meeting)
	slotsInAMeeting: SevenNums = [0, 0, 0, 0, 0, 0, 0];

	// Bit set on while writing to the database
	slotsSpinning: SevenNums = [MAX_SLOT_VALUE, MAX_SLOT_VALUE, MAX_SLOT_VALUE, MAX_SLOT_VALUE, MAX_SLOT_VALUE, MAX_SLOT_VALUE, MAX_SLOT_VALUE];

	numMeetings = 0;
	totalMeetings = 0;

	dayNames = this.labels.languageId == LanguageId.Spanish ? DAY_NAMES_ES : DAY_NAMES;
	slotNames = this.labels.languageId == LanguageId.Spanish ? SLOT_NAMES_ES : SLOT_NAMES;


	constructor(
		private accAreaService: AccAreaService,
		private labels: LabelsService,
		private dialogService: DialogService,
	) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();

		super.subscribe([this.accessAtStage$], async ([accessAtStage]) => {
			this.readonly = accessAtStage.access != 'Write';
		});
	}

	ngOnChanges() {
		if (this.schedulee) this.setup();
	}


	async setup() {

		const acc = await lastValueFrom(this.accAreaService.accelerator.acc$.pipe(take(1)));

		console.log(this.schedulee);

		const blockout: SevenNums = [
			acc.accMeetingTimes.mon,
			acc.accMeetingTimes.tue,
			acc.accMeetingTimes.wed,
			acc.accMeetingTimes.thur,
			acc.accMeetingTimes.fri,
			acc.accMeetingTimes.sat,
			acc.accMeetingTimes.sun,
		];

		const values: AccMatchingSchedule = {
			blockout,
			date: 0,
			days: [0, 0, 0, 0, 0, 0, 0],
		};

		const days = this.schedulee.schedule.map((d, i) => d & (MAX_SLOT_VALUE - blockout[i]));
		values.days = [days[0], days[1], days[2], days[3], days[4], days[5], days[6]];
		values.date = Date.now() / 1000;

		this.setValues(values);
		this.slotsSpinning = [0, 0, 0, 0, 0, 0, 0];
	}


	countMeetingStarts(days: SevenNums, invert = false) {
		let numMeetings = 0;

		for (let d = 0; d < 7; d++) {
			let day = days[d];
			if (invert) day = MAX_SLOT_VALUE - day;

			// A bit is set if it AND the two following slot bits are set as true
			const meetingStarts = slotsToMeetingStarts(day);

			// Count the number of meeting starts (possible meetings)
			numMeetings += hammingWeight(meetingStarts);
		}

		return numMeetings;
	}


	getSlotsInAMeeting(days: SevenNums): SevenNums {

		const slotsInAMeeting: SevenNums = [0, 0, 0, 0, 0, 0, 0];

		for (let d = 0; d < 7; d++) {
			// A bit is set if it AND the two following slot bits are set as true
			const meetingStarts = slotsToMeetingStarts(days[d]);

			// Get the slots in which a person may physically be in a meeting 
			slotsInAMeeting[d] = meetingSlots(meetingStarts);
		}

		return slotsInAMeeting;
	}


	setValues(values: { days: SevenNums, date: number, blockout: SevenNums }) {

		if (values.date < this.lastDate) {
			this.slotsSpinning = [0, 0, 0, 0, 0, 0, 0]; // outa whack! Just reset all the spinners
			return; // could be out of order since async through interwebs
		}

		this.numMeetings = this.countMeetingStarts(values.days);
		this.slotsInAMeeting = this.getSlotsInAMeeting(values.days);
		this.totalMeetings = this.countMeetingStarts(values.blockout, true);

		this.slots = values.days;
		this.blockout = values.blockout;
		this.lastDate = values.date;

		console.log('setValues', values);

	}


	checkMarkClass(day, slot) {
		if (this.isSpinning(day, slot)) return 'fal fa-spinner-third fa-spin';
		if (this.isBlockout(day, slot)) return 'far fa-square';

		const on = isBitOn(this.slots[day], slot);
		return on ? 'fas fa-check-square' : 'far fa-square';
	}


	/**
	 * Determines if a particular half-hour slot is marked in a meeting or not
	 */
	inMeeting(day: number, slot: number): boolean {
		return isBitOn(this.slotsInAMeeting[day], slot);
	}


	/**
	 * Determines if a particular half-hour slot is currently updating (spinning icon)
	 */
	isSpinning(day: number, slot: number): boolean {
		return isBitOn(this.slotsSpinning[day], slot);
	}


	/**
	 * Determines if a particular half-hour slot is marked within blockout times
	 */
	isBlockout(day: number, slot: number): boolean {
		return isBitOn(this.blockout[day], slot);
	}


	/**
	 * Toggles a given half-hour slot
	 */
	toggleSlot(day: number, slot: number) {
		if (this.readonly) {
			this.cannotEditDialog();
			return;
		}

		if (this.isBlockout(day, slot)) return;
		this.toggle(day, slot, !isBitOn(this.slots[day], slot));
	}

	/**
	 * Toggles all the half-hour slots for an entire day
	 */
	toggleColumn(day: number) {
		if (this.readonly) {
			this.cannotEditDialog();
			return;
		}

		const unblockout = MAX_SLOT_VALUE - this.blockout[day];
		const set = (this.slots[day] & unblockout) !== unblockout;
		this.toggle(day, -1, set);
	}


	/**
	 * Toggles the same half-hour slot across each of the days
	 */
	toggleRow(slot: number) {
		if (this.readonly) {
			this.cannotEditDialog();
			return;
		}

		const set = undefined !== this.slots
			.filter((day, i) => isBitOff(this.blockout[i], slot))
			.find(day => isBitOff(day, slot));

		this.toggle(-1, slot, set);
	}


	/**
	 * Toggles the full set of half-hour blocks
	 */
	toggleAll() {
		if (this.readonly) {
			this.cannotEditDialog();
			return;
		}

		const set = undefined !== this.slots
			.find((day, i) => {
				const unblockout = MAX_SLOT_VALUE - this.blockout[i];
				return (day & unblockout) !== unblockout;
			});
		this.toggle(-1, -1, set);
	}



	async toggle(day: number, slot: number, set: boolean) {

		this.setSpinning(day, slot, set, turnBitOn);

		const tableCode = this.schedulee._concept == DbConceptName.AccTeam ? 'T' : 'M';
		let accTeamId: number;
		let personId: number;

		if (this.schedulee._concept == DbConceptName.AccTeam) {
			accTeamId = this.schedulee.accTeamId;
		}
		else {
			personId = this.schedulee.personId;
		}


		const response = await this.accAreaService.mentorMatching.actions.setSchedule({
			tableCode,
			slot, day, set,
			accTeamId, personId,
		});


		if (response.date > this.lastDate) {
			//
			// Replace elements directly, NOT THE WHOLE ARRAY.
			// This is directly updating the schedule on the singleton.
			//
			const schedule = <SevenNums>this.schedulee.schedule;

			schedule[0] = response.days[0];
			schedule[1] = response.days[1];
			schedule[2] = response.days[2];
			schedule[3] = response.days[3];
			schedule[4] = response.days[4];
			schedule[5] = response.days[5];
			schedule[6] = response.days[6];
		}

		this.setValues(response);


		this.setSpinning(day, slot, set, turnBitOff);

	}

	setSpinning(day: number, slot: number, set: boolean, turnBitOnOrOff: (value: number, bit: number) => number) {

		let dayIndexes = [0, 1, 2, 3, 4, 5, 6];
		if (day !== -1) dayIndexes = [day];

		let slotIndexes = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29];
		if (slot !== -1) slotIndexes = [slot];

		for (const d of dayIndexes) {
			for (const s of slotIndexes) {
				// if (!set || ((this.slots[d] & (2 ** s)) == 0)) {
				this.slotsSpinning[d] = turnBitOnOrOff(this.slotsSpinning[d], s);
				// }
			}
		}
	}

	async cannotEditDialog() {
		await this.dialogService.showMessage(
			`Cannot edit the schedule editor as the accelerator is not in Mentor Matching stage.`,
			300, 180
		);
	}
}