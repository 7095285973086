import { NationalDashboardPageService } from '@ADMIN-area';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { GridColumnType, GridSetup } from '@me-grid';
import { AppAreaIdentifier, Event } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { GridPart } from '@me-shared-parts/UI-common';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';


interface EventRow {
	eventId: number,
	picId: number,
	accId: number,
	siteCode: string,
	siteId: number,
	programName: string,
	type: 'Accelerator' | 'Pitch Contest',
	updatedUTC: number,
}

@Component({
	selector: 'shared-events-view-part',
	templateUrl: './events-view.part.html',
})
export class SharedEventsViewPart extends DestroyablePart implements OnInit {

	@Input() identifier: AppAreaIdentifier<number>;
	@ViewChild(GridPart) meGrid: GridPart<EventRow>;

	public gridSetup = this.setupGrid();
	public rows$: Observable<EventRow[]>;


	constructor(
		private pageService: NationalDashboardPageService,
		public ds: DataService,
		public util: UtilityService) {
		super();
	}


	ngOnInit() {

		if (!this.identifier) throw new Error(`The identifer attribute was not set for <shared-events-view-part>`);

		super.initDestroyable();

		this.rows$ = this.ds.admin.singletonsAsOfUTC$.pipe(mergeMap(utc => this.buildRows(this.ds)));
	}


	async buildRows(ds: DataService): Promise<EventRow[]> {

		const accelerators = await ds.admin.accelerator.getAllPackagesAsArray();
		const pitchContests = await ds.admin.pitchContest.getAllPackagesAsArray();
		const events = await ds.admin.event.getAllPackagesAsArray();


		if (!events) return [];

		const accMap = this.util.array.toMap(accelerators, acc => acc.accId);
		const picMap = this.util.array.toMap(pitchContests, pic => pic.picId);

		const rows: EventRow[] = events.map(e => ({
			eventId: e.eventId,
			accId: e.accId,
			picId: e.picId,
			siteCode: e.accId ? accMap[e.accId].siteProgram.site.code : picMap[e.picId].siteProgram.site.code,
			siteId: e.accId ? accMap[e.accId].siteProgram.siteId : picMap[e.picId].siteProgram.siteId,
			programName: e.accId ? accMap[e.accId].name : picMap[e.picId].name,
			type: e.accId ? 'Accelerator' : 'Pitch Contest',
			language: e.accId ? accMap[e.accId].siteProgram.program.language : picMap[e.picId].siteProgram.program.language,
			updatedUTC: e.updatedUTC,
		}));
		return rows;
	}


	private setupGrid(): GridSetup<Event> {


		return {
			experience: 'EVENT',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				shrinkBy: 0,
				heightMultiplier: 1,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Event",
			rowPluralName: "Events",
			rowKey: "eventId",
			stateKey: "national-all-events",
			canAdd: false,
			canRefresh: false,
			canDownload: true,
			columnsToAdd: [
				{ field: 'siteId', header: 'siteId', width: 80, type: GridColumnType.number, hidden: true },
				{ field: 'accId', header: 'accId', width: 80, type: GridColumnType.number, hidden: true },
				{ field: 'picId', header: 'picId', width: 80, type: GridColumnType.number, hidden: true },
				{ field: 'siteCode', header: 'Site Code', width: 100, type: GridColumnType.text, hidden: false },
				{ field: 'programName', header: 'Program', width: 200, type: GridColumnType.text },
				{ field: 'type', header: 'Type', width: 200, type: GridColumnType.text },
				{ field: 'EVENT_eventType', header: 'Event Type', width: 200, type: GridColumnType.text, hidden: false },
				{ field: 'EVENT_venueName', header: 'Venue', width: 250, type: GridColumnType.text, hidden: false },
			],
			initialState: {
				sort: [{ field: 'EVENT-startUTC', dir: 'desc' }],
				filter: { logic: 'and', filters: [{ field: 'EVENT_status', operator: 'neq', value: 'Complete' }] },

			},

		};
	}

}