import { Component, Input, OnChanges } from '@angular/core';
import { PersonAssociationExtract } from '@me-interfaces';
import { OldDomainDataService } from '@me-services/core/old-dd';

@Component({
	selector: 'person-roles',
	styleUrls: ['./SHR-PN_person-roles.part.scss'],
	templateUrl: './SHR-PN_person-roles.part.html',
})
export class PersonRolesPart implements OnChanges {

	@Input() tagIds: number[];
	@Input() associations: PersonAssociationExtract[];

	values = {
		'mentor': false,
		'entrepreneur': false,
		'reader': false,
		'interviewer': false,
		'specialist': false,
		'judge': false,
	}

	constructor(private dd: OldDomainDataService) { }

	ngOnChanges() {

		const tags = this.dd.tags.getByIds(this.tagIds);

		this.values.mentor =
			!!this.associations.find(association => association.role == 'Mentor') ||
			!!tags.find(tag => tag.name.endsWith('mentor-approved') || tag.name.endsWith('coach-approved') || tag.name.endsWith('mentor-current') || tag.name.endsWith('coach-current'));

		this.values.reader =
			!!this.associations.find(association => association.role == 'Reader') ||
			!!tags.find(tag => tag.name.endsWith('reader-approved') || tag.name.endsWith('reader-approved'));

		this.values.interviewer =
			!!this.associations.find(association => association.role == 'Interviewer') ||
			!!tags.find(tag => tag.name.endsWith('interviewer-approved') || tag.name.endsWith('interviewer-current'));

		this.values.specialist =
			!!this.associations.find(association => association.role == 'Specialist') ||
			!!tags.find(tag => tag.name.endsWith('specialist-approved') || tag.name.endsWith('specialist-current'));

		this.values.judge =
			!!this.associations.find(association => association.role == 'Judge') ||
			!!tags.find(tag => tag.name.endsWith('judge-approved') || tag.name.endsWith('judge-current'));


		this.values.entrepreneur = !!this.associations.find(association => association.role == 'Applicant' || association.role == 'In the program');

	}
}