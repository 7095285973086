import { FieldKindAddress } from '@me-interfaces';

export function renderAddress(address: FieldKindAddress): string {

	const addressLines: string[] = [];

	if (address.address) addressLines.push(address.address);

	if (address.inUS == 'Y' && address.zipId) {
		const zipCode: string = ("00000" + address.zipId).slice(-5);
		address.cityAndState ? addressLines.push(`${address.cityAndState} ${zipCode}`) : zipCode;
	}

	return addressLines.join('\n');
}