import { Answer } from "@me-interfaces";

export function calculateRating4AndRatings5(assessments: { rating: number, answers: readonly Answer[] }[]): { ratings4: [number, number, number, number], ratings5: [number, number, number, number, number] } {
	const ratings4: [number, number, number, number] = [0, 0, 0, 0];
	const ratings5: [number, number, number, number, number] = [0, 0, 0, 0, 0];
	for (const assessment of assessments) {
		if (assessment.rating == 1) ratings4[0]++;
		if (assessment.rating == 2) ratings4[1]++;
		if (assessment.rating == 3) ratings4[2]++;
		if (assessment.rating == 4) ratings4[3]++;

		for (const assessmentAnswer of assessment.answers.filter(answer => answer.question.questionTypeId == 4)) {
			const questionValue = (+assessmentAnswer.answer) - 1;
			ratings5[questionValue]++;
		}
	}

	return { ratings4, ratings5 };

	return;
}