import { Injectable, OnDestroy } from '@angular/core';
import { combineLatest, lastValueFrom, Observable, ObservableInputTuple, Subject, take, takeUntil } from 'rxjs';

/**
 * Components that subscribe to observables can have the subscriptions automatically unsubscribed and cleaned up by...
 * 1] Extend this component
 * 2] Include .pipe(takeUntil(this.destroyed$)) before the .subscribe()
 */
@Injectable()
export abstract class DestroyablePart implements OnDestroy {

	private _initialized = false;
	private _destroyed$ = new Subject<void>();

	/**
	 * @deprecated Use `super.subscribe()` instead. It reduces rxjs subscription boilerplate code.
	 */
	protected get destroyed$() {
		return this.destroyedWithInitCheck$;
	}

	/**
	 * A way to access the destroyed$ subject externally from the class it exists on. e.g. trackInlineLabels()
	 * 
	 * IMPORTANT: DestroyablePart subclasses SHOULD NOT USE THIS. Instead, they should use super.subscribe()
	 */
	public get destroyedWithInitCheck$() {
		if (!this._initialized) console.error('MEMORY LEAK: The destroyed$ subject was accessed before being initialized. Only use destroyed$ in ngOnInit() AFTER a call to initDestroyable() which must also be in ngOnInit.');
		return this._destroyed$;
	}


	/**
	 * initDestroyable should be called as the first line in the ngOnInit
	 * for any component that extends DestroyablePart
	 */
	protected initDestroyable(): Subject<void> {
		this._initialized = true;
		this._destroyed$ = new Subject<void>();
		return this._destroyed$;
	}


	ngOnDestroy(): void {
		this._initialized = false;
		this._destroyed$.next();
		this._destroyed$.complete();
	}


	/**
	 * An easy way to subscribe to an array of observables with automatic unsubscribe.
	 * 
	 * IMPORTANT: A call to super.initDestroyable() must be the first line in ngOnInit(). This can then be called in ngOnInit() or ngAfterViewInit();
	 */
	protected subscribe<A extends readonly unknown[]>(
		sources: readonly [...ObservableInputTuple<A>],
		callback: (values: A) => Promise<void>,
	) {
		combineLatest(sources)
			.pipe(takeUntil(this.destroyedWithInitCheck$))
			.subscribe(<unknown>callback);
	}


	/**
	 * An easy way to get the latest value from an observable.
	 */
	protected async lastValueFrom<T>(source: Observable<T>): Promise<T> {
		return lastValueFrom(source.pipe(take(1)));
	}

}