import { AccTeam, Award, DbsAward, DbsPerson, Event, EventTypeId, PicTeam } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { Observable } from "rxjs";
import { DomainDataManagers } from "../interfaces/domain-data-managers";
import { PackageManager } from "../package-manager";
import { SingletonsManager } from "../singletons-manager";
import { AccTeamPackageManager } from "./acc-team";
import { EventPackageManager } from "./event";
import { PicTeamPackageManager } from "./pic-team";


export class AwardPackageManager extends PackageManager<DbsAward, Award> {


	constructor(
		singletonsAsOfUTC$: Observable<number>,
		util: UtilityService,
		sm: SingletonsManager<DbsAward>,
		private domain: DomainDataManagers,
		private person: SingletonsManager<DbsPerson>,
		private event: EventPackageManager,
		private accTeam: AccTeamPackageManager,
		private picTeam: PicTeamPackageManager,
	) {
		super(singletonsAsOfUTC$, util, sm);
	}


	/**
	 * Convert an array of DbcAward to an array of Award
	 */
	protected async _createPackages(dbsAwards: DbsAward[]): Promise<Award[]> {

		//
		// Get all the related objects
		//
		const eventIds: number[] = [];
		const accTeamIds: number[] = [];
		const picTeamIds: number[] = [];
		const personIds: number[] = [];

		for (const award of dbsAwards) {
			eventIds.push(award.eventId);
			if (award.accTeamId) accTeamIds.push(award.accTeamId);
			if (award.picTeamId) picTeamIds.push(award.picTeamId);
			personIds.push(award.createdByPersonId);
			personIds.push(award.updatedByPersonId);
		}

		const personMap = await this.person.getManyAsMap(personIds);
		const eventMap = await this.event.getManyPackagesAsMap(eventIds);
		const accTeamMap = await this.accTeam.getManyPackagesAsMap(accTeamIds);
		const picTeamMap = await this.picTeam.getManyPackagesAsMap(picTeamIds);


		//
		// Package 'em up
		//
		const awards: Award[] = dbsAwards.map(award => {

			const event = eventMap[award.eventId];
			const accTeam = accTeamMap[award.accTeamId];
			const picTeam = picTeamMap[award.picTeamId];
			const createdByPerson = personMap[award.createdByPersonId];
			const updatedByPerson = personMap[award.updatedByPersonId];

			return {
				...award,
				event,
				accTeam,
				picTeam,
				createdByPersonName: createdByPerson?._name || `Person #${award.createdByPersonId}`,
				updatedByPersonName: updatedByPerson?._name || `Person #${award.updatedByPersonId}`,
				appUrl: this.createAppUrl(event, accTeam, picTeam),
			};
		});

		return awards;
	}


	private createAppUrl(event: Event, accTeam?: AccTeam, picTeam?: PicTeam): string {
		const eventTypeId = event.eventTypeId;
		const accId = event.accId || undefined;
		const picId = event.picId || undefined;
		const siteCode = picTeam?.pitchContest.siteProgram.site.code || undefined;
		const siteId = accTeam?.accelerator.siteProgram.site.code || undefined;

		let url = null;
		if (siteId && accId) {
			if (eventTypeId == EventTypeId.AccShowcase) url = `/access/admin/national/sites/${siteId}/accelerators/${accId}/cohort?tab=1&view=18`;
			else if (eventTypeId == EventTypeId.AccQuarterliesQ2) url = `/access/admin/national/sites/${siteId}/accelerators/${accId}/cohort?tab=1&view=19`;
			else if (eventTypeId == EventTypeId.AccQuarterliesQ3) url = `/access/admin/national/sites/${siteId}/accelerators/${accId}/cohort?tab=1&view=20`;
			else if (eventTypeId == EventTypeId.AccQuarterliesQ4) url = `/access/admin/national/sites/${siteId}/accelerators/${accId}/cohort?tab=1&view=21`;
		}
		else if (siteCode && picId) {
			if (eventTypeId == EventTypeId.PicContest) url = `/access/admin/communities/${siteCode.toLowerCase()}/programs/pitch-contests/${picId}/awards`;
		}

		return url;
	}


	/**
	 * Get all awards for each accId provided
	 */
	public async getByAccIds(accIds: number[]): Promise<Record<number, ReadonlyArray<Award>>> {

		const allAwards = await this.getAllPackagesAsArray();
		const awards = allAwards.filter(a => a.accTeam?.accId && accIds.includes(a.accTeam.accId));

		return this.util.array.toArrayMap(awards, award => award.accTeam.accId);
	}


	/**
	 * Get all awards for each picId provided
	 */
	public async getByPicIds(picIds: number[]): Promise<Record<number, ReadonlyArray<Award>>> {

		const allAwards = await this.getAllPackagesAsArray();
		const awards = allAwards.filter(a => a.picTeam?.picId && picIds.includes(a.picTeam.picId));

		return this.util.array.toArrayMap(awards, award => award.picTeam.picId);
	}


	/**
	 * Get all awards for each accTeamId provided
	 */
	public async getByAccTeamIds(accTeamIds: number[]): Promise<Readonly<Record<number, ReadonlyArray<Award>>>> {

		return await this.getPackagesAsArraysByForeignIds('accTeamId', accTeamIds);
	}


	/**
	 * Get all awards for each picTeamId provided
	 */
	public async getByPicTeamIds(picTeamIds: number[]): Promise<Readonly<Record<number, ReadonlyArray<Award>>>> {

		return await this.getPackagesAsArraysByForeignIds('picTeamId', picTeamIds);
	}


	/**
	 * Get all awards for each eventId provided
	 */
	public async getByEventIds(eventIds: number[]): Promise<Readonly<Record<number, ReadonlyArray<Award>>>> {

		return await this.getPackagesAsArraysByForeignIds('eventId', eventIds);
	}
}