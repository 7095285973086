import { DbcZip } from '@me-interfaces';
import { DdVenueRow } from './dd-venue-row';

/**
 * @deprecated Use the DataService instead
 */
export interface DdNearbyVenue extends DdVenueRow {
	distance: {
		zip: DbcZip,
		miles: number,
		kilometers: number,
	}
}