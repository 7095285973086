import { DataService } from "../../data.service";
import { Explorable } from "../../explorable";

export async function getMatchById(ds: DataService, text: string): Promise<Explorable> {

	const parts = text.split('id:');
	if (parts.length !== 2) return undefined;

	const id = parseInt(parts[1], 10);
	if (isNaN(id)) return undefined;

	if (text.startsWith('accid:')) return await ds.admin.accelerator.getOnePackage(id);
	if (text.startsWith('accteamid:')) return await ds.admin.accTeam.getOnePackage(id);
	if (text.startsWith('applicationid:')) return await ds.admin.application.getOnePackage(id);
	if (text.startsWith('companyid:')) return await ds.admin.company.getOnePackage(id);
	if (text.startsWith('eventid:')) return await ds.admin.event.getOnePackage(id);
	if (text.startsWith('personid:')) return await ds.admin.person.getOnePackage(id);
	if (text.startsWith('picteamid:')) return await ds.admin.picTeam.getOnePackage(id);
	if (text.startsWith('picid:')) return await ds.admin.pitchContest.getOnePackage(id);
	if (text.startsWith('siteid:')) return await ds.admin.site.getOnePackage(id);
	if (text.startsWith('venueid:')) return await ds.admin.venue.getOnePackage(id);

	return undefined;
}