
/**
 * When a user selects a grid action, from the actions menu or the context menu,
 * the grid component will raise a gridAction event and pass an instance of this
 * interface.
 */
export interface GridActionEvent<T> {

	/**
	 * The unique key that identifies which action was performed.
	 * It may be one that was passed in with the GridSetup.actions
	 * array or it could be one of the built-in actions; 
	 * ADDROW_GRID_ACTION_KEY, SELECTION_GRID_ACTION_KEY or DBLCLICK_GRID_ACTION_KEY.
	 */
	key: string,


	/**
	 * The rows that are currently selected.
	 */
	rows: T[],
}