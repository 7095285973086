export const enum IndustryId {
	Other = -1,
	Hospitality_Accommodations = 1,
	Administrative_Support = 2,
	Agriculture = 3,
	Animals = 4,
	Apparel_Accessories = 5,
	Arts_Culture = 6,
	Community_Support = 7,
	Education = 8,
	Entertainment_Recreation = 9,
	Food_Beverage = 10,
	Health_Wellness = 11,
	Finance_Accounting = 12,
	Salon_Spa_PersonalCare = 13,
	Retail = 14,
	Technology = 15,
	Trades_Electrician_Plumber = 16,
	Travel_Tourism = 17,
	Property_Management = 18,
	Environment = 19,
	Philanthropy = 20,
	Manufacturing = 21,
	Legal = 22,
	Marketing = 23,
	Medical = 24,
	Photography_Media = 25,
	Beauty_Cosmetics = 26,
	Coaching_Mentoring = 27,
	Event_Planning = 28,
	Personal_Relationships = 29,
	Automotive = 30,
	Cleaning = 31,
	Lawncare = 32,
	CreativeServices_Multimedia = 33,
	Childcare_FamilyServices = 34,
}