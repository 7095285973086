import { AccAreaService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AssessmentsData, Event, EventTypeId } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { DroplistItem, Icon, getIconContext } from '@me-shared-parts/UI-common';


@Component({
	selector: 'acc-final-pitch-assessments-view-part',
	templateUrl: './acc-final-pitch-assessments-view.part.html',
	styleUrls: ['./acc-final-pitch-assessments-view.part.scss'],
})
export class AccFinalPitchAssessmentsViewPart extends DestroyablePart implements OnInit {

	orderSelectedItem: DroplistItem<string>;
	private _data: {
		assessments: AssessmentsData[],
		judgeEvents: { eventId: number, numWithDate: string, event: Event }[]
	};

	public data: {
		assessments: AssessmentsData[],
		judgeEvents: { eventId: number, numWithDate: string, event: Event }[]
	};

	order = 'cpq';
	orderList: DroplistItem<string>[] = [
		{ uniqueId: 1, data: 'pcq', iconContext: getIconContext(Icon.droplist_pickOne, undefined, undefined, 'Judge, Team, Question') },
		{ uniqueId: 2, data: 'pqc', iconContext: getIconContext(Icon.droplist_pickOne, undefined, undefined, 'Judge, Question, Team') },
		{ uniqueId: 3, data: 'cqp', iconContext: getIconContext(Icon.droplist_pickOne, undefined, undefined, 'Team, Question, Judge') },
		{ uniqueId: 4, data: 'cpq', iconContext: getIconContext(Icon.droplist_pickOne, undefined, undefined, 'Team, Judge, Question') },
		{ uniqueId: 5, data: 'qpc', iconContext: getIconContext(Icon.droplist_pickOne, undefined, undefined, 'Question, Judge, Team') },
		{ uniqueId: 6, data: 'qcp', iconContext: getIconContext(Icon.droplist_pickOne, undefined, undefined, 'Question, Team, Judge') },
	];

	public defaultEvent: { eventId: number, numWithDate: string, event: Event } = {
		eventId: undefined,
		numWithDate: '',
		event: undefined,
	};

	eventIdToFilterBy: number;

	constructor(
		public accAreaService: AccAreaService,
		private ds: DataService,
	) {
		super();
	}

	ngOnInit(): void {
		super.initDestroyable();

		this.orderSelectedItem = this.orderList.find(order => order.data == this.order);

		super.subscribe([
			this.accAreaService.judging.assessments$,
			this.accAreaService.accelerator.events$,
		], async ([judgeAssessments, events]) => {

			if (!events || !judgeAssessments) return;

			const eventsWithNum = await this.accAreaService.getEventsWithNumAndDate(EventTypeId.AccFinalPitch, events);
			const judgeEvents = eventsWithNum.map(eventWithNum => ({ event: eventWithNum.event, eventId: eventWithNum.event.eventId, numWithDate: eventWithNum.numWithDate }));

			const accJudges = await this.ds.admin.accJudge.getManyPackagesAsMap(judgeAssessments.map(r => r.judgement.accJudgeId));
			const accTeams = await this.ds.admin.accTeam.getManyPackagesAsMap(judgeAssessments.map(r => r.judgement.accTeamId));

			this._data = {
				judgeEvents,
				assessments: judgeAssessments.reduce((a: AssessmentsData[], ja) => {
					const finalEventId = accTeams[ja.judgement.accTeamId].finalEventId;
					const rating = {
						answers: ja.answers,
						company: accTeams[ja.judgement.accTeamId].application.company,
						event: eventsWithNum.find(event => finalEventId && finalEventId == event.event.eventId)?.event,
					};
					const assessmentData = a.find(data => data.person.personId == accJudges[ja.judgement.accJudgeId].personId);
					if (assessmentData) {
						assessmentData.ratings.push(rating);
					}
					else a.push({
						person: accJudges[ja.judgement.accJudgeId].person.asSingleton,
						ratings: [rating],
					});
					return a;
				}, []),
			};
			this.filterDataByEventId();
		});
	}

	changeOrder(e: DroplistItem) {
		this.orderSelectedItem = e;
		this.order = e.data;
	}


	onSelectionChange(e: number | undefined) {
		this.eventIdToFilterBy = e;
		this.filterDataByEventId();
	}

	private filterDataByEventId() {
		if (this.eventIdToFilterBy) {
			this.data.assessments = this._data.assessments.reduce((a: AssessmentsData[], assessment) => {

				const ratings = assessment.ratings.filter(rating => rating.event.eventId == this.eventIdToFilterBy);
				if (ratings.length) {
					a.push({
						person: assessment.person,
						ratings,
					});
				}
				return a;
			}, []);
		}
		else {
			this.data = { ...this._data };
		}
	}
}