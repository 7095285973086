

import { DdObjects } from '../dd-objects';
import { OldDomainDataService } from '..';
import { DdAwardNameEventTypeRow } from './dd-award-name_event-type-row';
import { DdAwardNameEventType } from './dd-award-name_event-type';

/**
 * @deprecated Use the DataService instead
 */
export class DdAwardNameEventTypes extends DdObjects<DdAwardNameEventTypeRow, DdAwardNameEventType> {

	constructor(private dd: OldDomainDataService) {
		super('awardNameEventTypeId');
	}

	async loadData(hash: string, data: DdAwardNameEventTypeRow[]) {
		const objects = data.map(d => new DdAwardNameEventType(this.dd, d));
		this.loadObjects(hash, objects);
	}
}