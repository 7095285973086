import { CompanyTypeId } from "@me-interfaces";

/**
 * @deprecated Use the DataService instead
 */
export interface DdCompanyTypeRow {
	companyTypeId: CompanyTypeId,
	name: string,
	isNonprofit: boolean,
	isFormed: boolean,
	isEforAllOnly: boolean,
}