import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AccAreaService } from '@ACC-area';
import { DestroyablePart } from '@me-access-parts';
import { ApplicationCols, GridColumnType, GridSetup, SELECTION_GRID_ACTION_KEY } from '@me-grid';
import { ACC_APPLICATION_STATUS, AccTeam, ApplicationStatusId } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { GridPart } from '@me-shared-parts/UI-common';
import { Observable, combineLatest, of } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';
import { AccApplicationContext } from '@ACC-area';
import { AccOverviewPageService, ApplicationStatusFilter } from '@ACC-area';


interface ApplicationRow {
	applicationId: number,
	updatedUTC: number,
}


@Component({
	selector: 'acc-all-applications-view-part',
	templateUrl: './all-applications-view.part.html',
})
export class AccAllApplicationsViewPart extends DestroyablePart implements OnInit, AfterViewInit {

	public gridSetup = this.setupGrid();

	public rows$: Observable<ApplicationRow[]> = of([]);

	@ViewChild(GridPart) meGrid: GridPart<ApplicationRow>;

	constructor(
		private util: UtilityService,
		public accAreaService: AccAreaService,
		public ds: DataService,
		public pageService: AccOverviewPageService
	) {
		super();
	}

	ngOnInit(): void {

		super.initDestroyable();

		this.rows$ = combineLatest([
			this.ds.admin.singletonsAsOfUTC$,
			this.accAreaService.applications.applications$,
			this.accAreaService.teams.teams$,
		]).pipe(mergeMap(data => this.buildRows(data[0], data[1], data[2])));


		this.pageService
			.applicationsStatusFilter$
			.pipe(takeUntil(this.destroyed$))
			.subscribe(this.applyStatusFilter.bind(this));
	}


	ngAfterViewInit() {
		console.log(`ngAfterViewInit - this.meGrid =`, this.meGrid);
		this.applyStatusFilter(this.pageService.applicationsStatusFilter$.value);
	}


	applyStatusFilter(filter: ApplicationStatusFilter) {

		if (this.meGrid) {
			const grid = this.meGrid.grid;
			console.log(`meGrid.grid = `, grid);

			if (filter == 'All') grid.removeFilter(ApplicationCols.status);

			else if (filter == 'Accepted') grid.patchFilter({ field: ApplicationCols.status, operator: 'contains', value: ACC_APPLICATION_STATUS[ApplicationStatusId.TeamPromoted] });

			else if (filter == 'Open') grid.patchCompositeFilter([ApplicationCols.status], {
				logic: 'or', filters: [
					{ field: ApplicationCols.status, operator: 'contains', value: ACC_APPLICATION_STATUS[ApplicationStatusId.AcceptPending] },
					{ field: ApplicationCols.status, operator: 'contains', value: ACC_APPLICATION_STATUS[ApplicationStatusId.ReadPending] },
					{ field: ApplicationCols.status, operator: 'contains', value: ACC_APPLICATION_STATUS[ApplicationStatusId.InterviewPending] },
					{ field: ApplicationCols.status, operator: 'contains', value: ACC_APPLICATION_STATUS[ApplicationStatusId.SelectPending] },
					{ field: ApplicationCols.status, operator: 'contains', value: ACC_APPLICATION_STATUS[ApplicationStatusId.TeamPending] },
				]
			});

			else if (filter == 'Not Accepted') grid.patchCompositeFilter([ApplicationCols.status], {
				logic: 'and', filters: [
					{ field: ApplicationCols.status, operator: 'doesnotcontain', value: ACC_APPLICATION_STATUS[ApplicationStatusId.AcceptPending] },
					{ field: ApplicationCols.status, operator: 'doesnotcontain', value: ACC_APPLICATION_STATUS[ApplicationStatusId.ReadPending] },
					{ field: ApplicationCols.status, operator: 'doesnotcontain', value: ACC_APPLICATION_STATUS[ApplicationStatusId.InterviewPending] },
					{ field: ApplicationCols.status, operator: 'doesnotcontain', value: ACC_APPLICATION_STATUS[ApplicationStatusId.SelectPending] },
					{ field: ApplicationCols.status, operator: 'doesnotcontain', value: ACC_APPLICATION_STATUS[ApplicationStatusId.TeamPending] },
					{ field: ApplicationCols.status, operator: 'doesnotcontain', value: ACC_APPLICATION_STATUS[ApplicationStatusId.TeamPromoted] },
				]
			});

			else grid.removeFilter(ApplicationCols.status);
		}
		else {
			console.error(`this.meGrid == false`);
		}
	}


	async buildRows(
		singletonsAsOfUTC: number,
		applications: readonly AccApplicationContext[],
		accTeams: readonly AccTeam[],
	): Promise<ApplicationRow[]> {

		if (!applications || !accTeams) return [];

		const rows: ApplicationRow[] = applications.map(a => ({ applicationId: a.application.applicationId, updatedUTC: a.application.updatedUTC, }));
		return rows;
	}


	private setupGrid(): GridSetup<ApplicationRow> {

		return {
			experience: 'APPLICATION',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Application",
			rowPluralName: "Applications",
			rowKey: "applicationId",
			stateKey: "adm-acc-applications-page",
			canAdd: false,
			canRefresh: false,
			canDownload: true,
			columnsToAdd: [
				{ field: ApplicationCols.status, header: "Application Status", width: 175, type: GridColumnType.text, hidden: false },
				{ field: ApplicationCols.companyCityAndState, header: "Location", width: 150, type: GridColumnType.text, hidden: false },
			],
			initialState: {
				sort: [{ field: 'APPLICATION_companyName', dir: 'asc' }],
			},
		};
	}



	async gridActionHandler(action: { actionKey: string, rows: ApplicationRow[] }) {

		if (action.actionKey == SELECTION_GRID_ACTION_KEY) {
			// Nothing to do with a simple row selection
		}
	}

}