import { NationalDashboardPageService, SharedDashboardsService } from '@ADMIN-area';
import { Component } from '@angular/core';
import { GridSetup } from '@me-grid';
import { map } from 'rxjs/operators';


@Component({
	selector: 'shared-acc-judges-view-part',
	templateUrl: './acc-judges-view.part.html',
})
export class SharedAccJudgesViewPart {

	public gridSetup = this.setupGrid();

	public rows$ = this.sharedDashboardsService.dashboardData$.pipe(
		map(data => data ? data.accelerator.accJudgeIds.map(accJudgeId => ({ accJudgeId })) : [])
	);


	constructor(
		public pageService: NationalDashboardPageService,
		private sharedDashboardsService: SharedDashboardsService,
	) {
	}


	private setupGrid(): GridSetup<{ accJudgeId: number }> {

		return {
			experience: 'PERSON',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Judge",
			rowPluralName: "Judges",
			rowKey: "accJudgeId",
			stateKey: "shared-acc-judges-view-part",
			canAdd: false,
			canRefresh: false,
			canDownload: false,
			initialState: {},
		};
	}

}