import { AppAreaAccess, AppAreaAccessValue, AppSubAreaAccessValue } from '@me-interfaces';

export type AccSubArea =
	'assessments' | 'demographics' | 'removeTeam';


export interface AccAreaAccess extends AppAreaAccess {
	root: AppAreaAccessValue,
	sub: {
		assessments: AppSubAreaAccessValue,
		demographics: AppSubAreaAccessValue,
		removeTeam: AppSubAreaAccessValue,
	},

}