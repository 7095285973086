<fieldset *ngIf='netPromoter'>
	<legend><i class="fal fa-spinner-third fa-spin" *ngIf='saving'>&nbsp;</i> <me-label key='Recommend EforAll?'></me-label></legend>

	<div [class.saving]='saving'>
		<p><me-label key='md:Alum Promoter Question'></me-label></p>
		<div class="rating-container">
			<i><me-label key='Not at all likely'></me-label> </i>
			<mat-radio-group class="radio-group" [(ngModel)]='netPromoter.value' (change)='scoreChanged($event.value)'>
				<mat-radio-button *ngFor="let i of [0,1,2,3,4,5,6,7,8,9,10]" [value]="i" [checked]='i === netPromoter.value'>
					<p>{{i}}</p>
				</mat-radio-button>
			</mat-radio-group>
			<i><me-label key='Extremely likely'></me-label></i>
		</div>
	</div>
</fieldset>