import { DbConceptName, DbsEntity, DbsPosition, ExplorablePackage, Immutable, Person } from '@me-interfaces';
import { CompanyExtracts } from "../../db-extracts";


interface MutableDbsCompany extends DbsEntity {

	readonly _concept: DbConceptName.Company,

	readonly companyId: number,
	readonly entityId: number,
	readonly companyTypeId: number,
	readonly longName: string,
	readonly shortName: string,
	readonly _name: string,
	readonly inUS: boolean,
	readonly address: string,
	readonly zipId: number,
	readonly phone: string,
	readonly phoneTypeId: number,
	readonly offerings: string,
	readonly industryId: number,

	readonly updatedUTC: number,
	readonly updatedByPersonId: number,

	readonly status: 'Open' | 'Closed',
	readonly positionId: number,
	readonly createdUTC: number,
	readonly _companyExtracts: CompanyExtracts,
}


interface MutableCompany extends MutableDbsCompany {

	readonly noteIds: number[],
	readonly accApplicationIds: number[],
	readonly picApplicationIds: number[],

	readonly webLinks: {
		webLinkId: number,
		webLinkTypeId: number,
		url: string,
	}[],

	companyType: string,
	industry: string,
	phoneType: string,
	position: DbsPosition | undefined,
	contact: Person | undefined,


	readonly awards: { awardId: number, value: number }[],
	readonly awardedValue: number,
}


export interface DbsCompany extends Immutable<MutableDbsCompany> { }
export interface Company extends ExplorablePackage<DbConceptName.Company>, Immutable<MutableCompany> { }