import { Component, EventEmitter, Input, Output } from '@angular/core';


@Component({
	selector: 'explorer-section',
	templateUrl: './explorer-section.part.html',
	styleUrls: ['./explorer-section.part.scss'],
})
export class ExplorerSectionPart {

	@Input() header = 'Section';
	@Output() tabClose = new EventEmitter();

	xClick() {
		this.tabClose.emit(this.header);
	}
}