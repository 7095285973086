<div class='acc-goals-view-part'>

	<div>
		<table *ngIf="selectedGoal" style='width:100%'
			class='pro-table pro-table-with-thin-rows pro-table-no-header pro-table-with-labels pro-table-without-border'>
			<tr>
				<td>
					<me-label key='Quarter'></me-label>
				</td>
				<td> {{inlineLabels[QUARTER_NAMES[selectedGoal.quarter]]}}</td>
			</tr>

			<tr>
				<td>
					<me-label key='Summary'></me-label>
				</td>
				<td> {{selectedGoal.summary}}</td>
			</tr>

			<tr>
				<td>
					<me-label key='Details'></me-label>
				</td>
				<td> {{selectedGoal.details}}</td>
			</tr>

			<tr>
				<td>
					<me-label key='Progress'></me-label>
				</td>
				<td> {{selectedGoal.progress}}</td>
			</tr>
		</table>
	</div>
	<kendo-chat *ngIf='user' [messages]="comments" [user]="user" (sendMessage)="sendMessage($event)">
		<kendo-chat-messages [send]="inlineLabels['Send']" [messagePlaceholder]="inlineLabels['Add a comment...']">
		</kendo-chat-messages>
	</kendo-chat>
</div>