import { AccApplicationContext, AccAreaService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DestroyablePart } from '@me-access-parts';
import { AccInterviewer, AccReaderWithMaxReads, AccStageId, Accelerator, Answer, Application, ApplicationStatusId, DbaAccInterview } from '@me-interfaces';
import { QuestionStepper } from '@me-shared-parts/PG-program';
import { DroplistItem, Icon, getIconContext } from '@me-shared-parts/UI-common';
import { getIconContextForPackage } from '@me-shared-parts/UI-common/icon/get-icon-context-for-package';
import { Observable, combineLatest, lastValueFrom, map, take } from 'rxjs';


const DEFAULT_EDITOR_DATA = { uniqueId: undefined, iconContext: getIconContext(Icon.empty), data: undefined };

interface EditorData {
	applicationList: DroplistItem<Application>[],
	interviewerList: DroplistItem<AccInterviewer>[],
}


@Component({
	selector: 'acc-interviewing-editor-view-part',
	templateUrl: './acc-interviewing-editor-view.part.html',
	styleUrls: ['./acc-interviewing-editor-view.part.scss'],
})
export class AccInterviewingEditorViewPart extends DestroyablePart implements OnInit {

	public readonly AccStageId_Interviewing = AccStageId.Interviewing;
	data$: Observable<EditorData>;

	private accessAtStage$ = this.accAreaService.getAccessAtStage(AccStageId.Interviewing, 'assessments');
	readonly: boolean;

	public applicationSelectedItem: DroplistItem<Application>;
	public interviewerSelectedItem: DroplistItem<AccReaderWithMaxReads>;
	private interviewAssessments: {
		interview: DbaAccInterview,
		answers: readonly Answer[],
	}[];
	private interviewerAssessment: {
		rating: number | undefined,
		answers: readonly Answer[]
	};
	private defaultInterviewQuestions: {
		rating: number | undefined,
		answers: readonly Answer[]
	};

	stepper: QuestionStepper;
	ratingStep;
	form: FormGroup;
	accelerator: Accelerator;
	saving = false;

	constructor(
		public accAreaService: AccAreaService,
		private fb: FormBuilder,

	) {
		super();
	}

	async ngOnInit() {

		super.initDestroyable();

		super.subscribe([this.accessAtStage$], async ([accessAtStage]) => {
			this.readonly = accessAtStage.access != 'Write';
		});

		this.data$ = combineLatest([
			this.accAreaService.applications.applications$,
			this.accAreaService.interviewing.assessments$,
			this.accAreaService.interviewing.interviewers$,
			this.accAreaService.interviewing.defaultQuestions$
		]).pipe(map(data => this.buildData(data[0], data[1], data[2], data[3])));


		this.accelerator = await lastValueFrom(this.accAreaService.accelerator.acc$.pipe(take(1)));

	}

	buildData(
		applications: ReadonlyArray<AccApplicationContext>,
		interviewAssessments: { interview: DbaAccInterview, answers: readonly Answer[] }[],
		interviewers: ReadonlyArray<AccInterviewer>,
		defaultInterviewQuestions: ReadonlyArray<Answer>,
	): EditorData {

		if (!applications || !interviewers || !interviewAssessments || !defaultInterviewQuestions || this.readonly) return {
			applicationList: [],
			interviewerList: [],
		};


		this.interviewAssessments = interviewAssessments;

		this.defaultInterviewQuestions = {
			rating: undefined,
			answers: defaultInterviewQuestions
		};

		const applicationList: DroplistItem<Application>[] = applications
			.filter(app => app.application.applicationStatusId >= ApplicationStatusId.InterviewPending)
			.map(a => ({ uniqueId: a.application.applicationId, iconContext: getIconContextForPackage(a.application), data: a.application }));

		const interviewerList = interviewers
			.map(a => ({ uniqueId: a.accInterviewerId, iconContext: getIconContextForPackage(a.person), data: a }));

		applicationList.unshift(DEFAULT_EDITOR_DATA);
		interviewerList.unshift(DEFAULT_EDITOR_DATA);


		return {
			applicationList,
			interviewerList,
		};

	}


	onInterviewerChange(e: DroplistItem) {
		if (e.uniqueId) this.interviewerSelectedItem = e;
		else {
			this.interviewerSelectedItem = undefined;
			this.applicationSelectedItem = undefined;
		}
	}

	onApplicationChange(e: DroplistItem) {
		if (e.uniqueId) this.applicationSelectedItem = e;
		else this.applicationSelectedItem = undefined;
	}


	load() {
		if (!this.applicationSelectedItem || !this.interviewerSelectedItem) return;

		const answersFound = this.interviewAssessments.find(assessment => assessment.interview.accInterviewerId == this.interviewerSelectedItem.uniqueId && assessment.interview.applicationId == this.applicationSelectedItem.uniqueId);
		this.interviewerAssessment = answersFound ? { answers: answersFound.answers, rating: answersFound.interview.rating } : this.defaultInterviewQuestions;
		this.stepper = new QuestionStepper(0, this.interviewerAssessment.answers.length, 1);
		this.ratingStep = this.interviewerAssessment.answers.length;
		this.createForm();
	}

	private createForm() {
		this.form = this.fb.group({
			rating: [this.interviewerAssessment.rating, [Validators.required]],
		});

		this.resetForm();
	}

	private resetForm() {
		const values = {
			rating: this.interviewerAssessment.rating
		}
		this.form.reset(values);
		this.form.updateValueAndValidity();
	}

	async save() {

		if (this.readonly) return;
		this.saving = true;
		const values = this.form.value;
		const applicationId = this.applicationSelectedItem.uniqueId;
		const accInterviewerId = this.interviewerSelectedItem.uniqueId;

		const assessment = {
			rating: values.rating,
			answers: [],
		};

		for (const answer of this.interviewerAssessment.answers) {
			assessment.answers.push({
				question: answer.question,
				answer: values['q' + answer.question.questionId],
			});
		}

		await this.accAreaService.interviewing.actions.setAssessment({ accInterviewerId, applicationId, assessment });

		this.interviewerAssessment = undefined;
		this.saving = false;
	}
}
