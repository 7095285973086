
import { DdIndustryRow } from './dd-industry-row';
import { DdObject } from '../dd-object';
import { OldDomainDataService } from '../domain-data.service';

/**
 * @deprecated Use the DataService instead
 */
export class DdIndustry extends DdObject<DdIndustryRow> implements DdIndustryRow {

	constructor(dd: OldDomainDataService, data: DdIndustryRow) {
		super(data);
	}

	public get industryId(): number {
		return this._data.industryId;
	}

	public get name(): string {
		return this._data.name;
	}
}