<div class='explorer-part site-explorer-part'>

	<div class="icon-bar">
		<me-icon-bar #iconBar [icons]='views' [orientation]="'Vertical'" (selectionsChange)='setViews($event)'>
		</me-icon-bar>
	</div>

	<div class="content">
		<ng-container *ngIf='selectedViews'>
			<div class="explorer-header">
				<me-icon [icon]="icons.site"></me-icon>
			</div>
			<h4 class="explorer-sub-header">Site</h4>
			<div class="explorer-small-header" *ngIf="!site.isActive">Status:<span
					class="explorer-highlight-section">Inactive</span></div>

			<section>
				<explorer-buttons [buttons]='buttons'></explorer-buttons>
			</section>

			<explorer-section header="Site Details" (tabClose)="tabClose($event)"
				[style.display]="getDisplay('details')">
				<site-explorer-details [site]="site"></site-explorer-details>
			</explorer-section>

			<explorer-section
				[header]="util.text.singularOrPluralWithCount(acceleratorBoxes.length, 'Active Accelerator', 'Active Accelerators')"
				(tabClose)="tabClose($event)" [style.display]="getDisplay('accelerators')">
				<explorer-boxes>
					<ng-container *ngFor="let box of acceleratorBoxes">
						<explorer-box [box]="box"></explorer-box>
					</ng-container>
				</explorer-boxes>
			</explorer-section>

			<explorer-section
				[header]="util.text.singularOrPluralWithCount(pitchContestBoxes.length, 'Active Pitch Contest', 'Active Pitch Contests')"
				(tabClose)="tabClose($event)" [style.display]="getDisplay('pitchContests')">
				<explorer-boxes>
					<ng-container *ngFor="let box of pitchContestBoxes">
						<explorer-box [box]="box"></explorer-box>
					</ng-container>
				</explorer-boxes>
			</explorer-section>

			<explorer-section
				[header]="util.text.singularOrPluralWithCount(eventBoxes.length, 'Current Event', 'Current Events')"
				(tabClose)="tabClose($event)" [style.display]="getDisplay('events')">
				<explorer-boxes>
					<ng-container *ngFor="let box of eventBoxes">
						<explorer-box [box]="box"></explorer-box>
					</ng-container>
				</explorer-boxes>
			</explorer-section>

		</ng-container>
	</div>
</div>