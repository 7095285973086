import { Dbs, SingletonId, SingletonName } from "@me-interfaces";
import { DexieService } from "../dexie";
import { SingletonForeignId } from "../dexie/dexie-singletons-db";
import { UtilityService } from "../utility";
import { cleanArray } from "./clean-array";

export class SingletonsManager<T extends Readonly<Dbs>> {


	constructor(
		private readonly dexie: DexieService,
		private readonly util: UtilityService,
		private readonly name: SingletonName,
		public readonly identifier: SingletonId,
	) {
	}


	/**
	 * It's best practice to pass all of the ids needed for a particular singleton type first. If any are
	 * not in the cache then this method will wait for them to be downloaded and added to the cache.
	 */
	public async ensureAllDownloaded(ids: number[]): Promise<void> {
		if (ids.length == 0) return;
		const db = await this.dexie.getSingletonsDb();
		await db.ensureAllDownloaded(this.name, ids);
	}


	/**
	* Get one singleton by id. It will attempt to download it first if it is
	* not in the cache for some reason.
	* 
	* DO NOT call this multiple times in a loop or .map() function!
	* If looping or mapping, call getAllAsMap() FIRST and use the returned map instead.
	*/
	public async getOne(id: number): Promise<T> {

		return (await this.getManyAsArray([id]))[0];

	}


	/**
	 * Map an array of singleton ids to an array of singletons.
	 * Any ids not in the cache will be attempted to be downloaded.
	 * Ids still not in the singletons cache will then be ignored.
	 */
	public async getManyAsArray(originalIds: readonly number[]): Promise<T[]> {

		const ids = cleanArray(originalIds);
		if (!ids.length) return [];

		const db = await this.dexie.getSingletonsDb();

		await db.ensureAllDownloaded(this.name, ids);

		const map = await db.getAllAsMapFromCache<T>(this.name);

		const list = ids
			.map(id => map[id])
			.filter(dbs => !!dbs);

		return list;
	}


	/**
	 * Convert an array of singleton ids into a map for quick lookup.
	 */
	public async getManyAsMap(ids: ReadonlyArray<number>): Promise<Readonly<Record<number, T>>> {

		const singletons = await this.getManyAsArray(ids);
		return this.util.array.toMap(singletons, item => item[this.identifier]);
	}


	/**
	 * Get all of the singletons of this named type that are currently in the cache.
	 * 
	 * Call ensureAllDownloaded() first with needed ids to ensure they are available.
	 */
	public async getAllAsArray(): Promise<ReadonlyArray<T>> {

		const db = await this.dexie.getSingletonsDb();
		return await db.getAllAsArrayFromCache(this.name);
	}


	/**
	 * Get all of the singletons of this named type that are currently in the cache  as a map for quick lookup.
	 * 
	 * Call ensureAllDownloaded() first with needed ids to ensure they are available.
	 */
	public async getAllAsMap(): Promise<Readonly<Record<number, T>>> {

		const db = await this.dexie.getSingletonsDb();
		return await db.getAllAsMapFromCache(this.name);
	}


	/**
	 * Map a foreign key id an array of singletons. The foreignIdProperty must be included in the
	 * singleton's foreignIds config in the cacheConfigs (dexie-singletons-db.ts) or an empty array will be returned.
	 * 
	 * WARNING: Do not call this in a loop for multiple foreignIds. Use getArraysByForeignIds() instead.
	 */
	public async getArrayByForeignId(foreignIdProperty: SingletonForeignId, foreignId: number): Promise<T[]> {

		const db = await this.dexie.getSingletonsDb();
		return await db.getArrayByForeignIdFromCache(this.name, foreignIdProperty, foreignId);
	}


	/**
	 * Map an array of foreign key ids to a map of arrays of singletons. The foreignIdProperty must be included
	 * in the singleton's foreignIds config in the cacheConfigs (dexie-singletons-db.ts) or an empty array will be returned.
	 */
	public async getArraysByForeignIds(foreignIdProperty: SingletonForeignId, foreignIds: readonly number[]): Promise<Record<number, T[]>> {

		const db = await this.dexie.getSingletonsDb();
		return await db.getArraysByForeignIdsFromCache(this.name, foreignIdProperty, foreignIds);
	}
}