import { Component, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { LabelKey } from '@me-interfaces';
import { LabelsService } from '@me-services/ui/labels';
import { groupBy, GroupResult } from '@progress/kendo-data-query';
import { getIconContext, Icon, IconContext } from '../icon';
import { DroplistItem } from './droplist-item';
import { DEFAULT_POPUP_CONFIG, DroplistPopupConfig } from './droplist-popup-config';
import { PreventableEvent } from '@progress/kendo-angular-dropdowns';

const DEFAULT_ITEM_ID = Number.MAX_SAFE_INTEGER;
const FILTER_THRESHOLD = 12;


@Component({
	selector: 'me-droplist',
	templateUrl: './SHR-CMN_droplist.part.html',
	styleUrls: ['./SHR-CMN_droplist.part.scss'],
})
export class DroplistPart extends DestroyablePart implements OnInit, OnChanges {


	/** The initially selected item, if any. */
	@Input() selectedItem: DroplistItem;


	/** The set of items in the list. */
	@Input() items: DroplistItem[] = [];


	/**
	 * If true or if items > 12, then the top of the dropdown will contain a text area to filter the list.
	 * Default is true;
	 */
	@Input() filterable = true;


	/**
	 * If present, then a "Select an item..." option will be included with the provided text.
	 * If not present, then the user will not be able to unselect once a selection is made.
	 */
	@Input() optionalText: string | LabelKey;


	/**
	 * Sort the list by the attribute passed in.
	 */
	@Input() sortBy: 'uniqueId' | 'text' | 'customSort' = 'text';


	/**
	 * Group names are sorted alphabetically by default. Use groupSortValues to specifically
	 * force the order of one or more groups. Group names of the same number will be sorted
	 * alphabetically.  Group names not provided will be set to a very large number so they
	 * are sorted alphabetically after the group names provided here.
	 */
	@Input() groupSortValues: { [index: string]: number } = undefined;


	/**
	 * Optionally provide a popup config to have the part render an icon button that triggers
	 * the droplist in a popup.  Leave the popup config as undefined to render the droplist
	 * inline.
	 */
	@Input() popupConfig: DroplistPopupConfig = undefined;


	/**
	 * If true then the droplist will be rendered with rounded borders and hiver colors
	 * to distinguish droplists used for navigation from those used for data entry.
	 */
	@Input() navigation = false;

	/**
	 * If true, then droplist will be disabled.
	 */
	@Input() readonly = false;


	/**
	 * If the droplist has no items(Options), this message will be displayed.
	 */
	@Input() missingDataMessage = "No items to select";


	/**
	 * When the selection is changed by the user, this event will be raised to indicate
	 * the new selected value.
	 */
	@Output() selectionChange = new EventEmitter<DroplistItem | undefined>();

	@HostBinding('style.display') private displayStyle = 'inline-block';

	data: DroplistItem[] | GroupResult[] = [];
	groupable = false;
	defaultItem: DroplistItem = undefined;


	constructor(private labelsService: LabelsService) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();
	}

	async ngOnChanges() {

		if (!this.items) throw new Error(`Missing required attribute: items`);

		//
		// Check for untyped DropListItem items that are using the old id property.
		// If this happens, FIX THE TYPING!!
		//
		for (const item of this.items) {
			if (item['id']) throw new Error(`Someone wrote bad code that isn't typechecking for DropListItem! Find the place where the DropListItem is created and type the <DropListItem>{object} NOT the variable.`);
		}

		this.filterable = this.filterable && this.items.length > FILTER_THRESHOLD;

		//
		// Translate text if needed
		//
		for (const item of this.items) {
			item.iconContext.text = await this.labelsService.get(item.iconContext?.text || '');
		}


		//
		// Determine if we need grouping
		//
		this.groupable = false;
		for (const item of this.items) {
			if (item.groupName) {
				this.groupable = true;
				break;
			}
		}


		//
		// Sort items by group then text
		//
		const sortValues = this.groupSortValues || {};

		this.items = this.items.sort((i1, i2) => {
			if (this.groupable && i1.groupName !== i2.groupName) {
				const i1s = sortValues[i1.groupName] ?? Number.MAX_SAFE_INTEGER;
				const i2s = sortValues[i2.groupName] ?? Number.MAX_SAFE_INTEGER;
				if (i1s == i2s) return i1.groupName > i2.groupName ? 1 : -1;
				else return i1s > i2s ? 1 : -1;
			}
			else if(this.sortBy == 'text'){
				if (i1.iconContext[this.sortBy] !== i2.iconContext[this.sortBy]) {
					return i1.iconContext[this.sortBy] > i2.iconContext[this.sortBy] ? 1 : -1;
				}
			}
			else if (i1[this.sortBy] !== i2[this.sortBy]) {
				return i1[this.sortBy] > i2[this.sortBy] ? 1 : -1;
			}
			else return 0;
		});


		//
		// If grouping, handle it.
		//
		if (this.groupable) {
			for (const item of this.items) {
				item.groupName = item.groupName ?? '?';
			}

			this.data = groupBy(this.items, [{ field: 'groupName' }]);
		}
		else {
			this.data = this.items;
		}


		//
		// Add the optional selection if needed
		//
		this.defaultItem = undefined;

		if (this.optionalText) {
			this.defaultItem = { uniqueId: DEFAULT_ITEM_ID, iconContext: getIconContext(Icon.empty, undefined, undefined, await this.labelsService.get(this.optionalText)) };
		}

		if (this.popupConfig) {
			this.displayStyle = 'inline-block';
			this.popupConfig = { ...DEFAULT_POPUP_CONFIG, ...this.popupConfig };
			this.popupConfig.icon.text = this.popupConfig.message;
		}
		else {
			this.displayStyle = 'block';
		}
	}


	public handleFilter(query: string): void {

		const predicate = (item:DroplistItem) => item.iconContext.text.toLowerCase().indexOf(query.toLowerCase()) >= 0;

		if (this.groupable) {
			this.data = groupBy(this.items.filter(predicate), [{ field: "groupName" }]);
		}
		else {
			this.data = this.items.filter(predicate);
		}
	}


	public itemDisabled(itemArgs: { dataItem: DroplistItem, index: number }) {
		return !!itemArgs.dataItem?.disabled;
	}


	public valueChangeEvent(selectedItem: DroplistItem) {
		this.selectedItem = selectedItem;

		if (selectedItem.uniqueId == DEFAULT_ITEM_ID) this.selectionChange.emit(undefined);
		else this.selectionChange.emit(selectedItem);

		if (this.popupConfig) this.toggle(false);
	}


	//
	// Button Popup
	//

	public show = false;

	@ViewChild("anchor") public anchor: ElementRef;
	@ViewChild("popup", { read: ElementRef }) public popup: ElementRef;

	@HostListener("keydown", ["$event"])
	public keydown(event: any): void {
		if (event.keyCode === 27) {
			this.toggle(false);
		}
	}

	@HostListener("document:click", ["$event"])
	public documentClick(event: any): void {
		if (!this.contains(event.target)) {
			this.toggle(false);
		}
	}

	public toggle(show?: boolean): void {
		this.show = show !== undefined ? show : !this.show;
	}

	private contains(target: any): boolean {
		return (
			this.anchor.nativeElement.contains(target) ||
			(this.popup ? this.popup.nativeElement.contains(target) : false)
		);
	}


	public createIconContext(value: DroplistItem): IconContext {
		const iconContext: IconContext = { ...value.iconContext, hideText: 'never' };
		if (value.subText) iconContext.subText = value.subText;
		return iconContext;
	}


	public createListValueTemplate(value: DroplistItem): IconContext {

		const iconContext: IconContext = { ...value.iconContext, hideText: 'never' };
		if (value.subText) iconContext.subText = value.subText;

		let groupName = value.groupName;

		if (groupName) {
			if (groupName == 'Mentor Matching') groupName = 'MM';
			if (groupName == 'Quarter 2') groupName = 'Q2';
			if (groupName == 'Quarter 3') groupName = 'Q3';
			if (groupName == 'Quarter 4') groupName = 'Q4';

			iconContext.text = `${groupName} → ${iconContext.text}`;
		}

		return iconContext;
	}


	public onClose(event: PreventableEvent) {
		//
		// preventDefault() will stop the droplist from hiding. Use this
		// to inspect the contents of the list to determine CSS classes.
		//
		/// event.preventDefault();
	}
}
