<div class='acc-stage-advancer-part' *ngIf="icons$ | async; let icons">

	<div class="acc-stage-icons">
		<me-icon *ngFor="let icon of icons" [class]="icon.cssClass" [icon]="icon.icon"></me-icon>
	</div>

	<div class="acc-stage-buttons">
		<button class="btn" (click)="previous()" [disabled]="!canPrevious"><me-icon
				[icon]="previousIcon"></me-icon></button>
		<button class="btn" (click)="advance()" [disabled]="!canAdvance"><me-icon
				[icon]="advanceIcon"></me-icon></button>
		<stage-editor-part *ngIf="isTechAdmin"></stage-editor-part>
	</div>
</div>