import { Icon } from "@me-shared-parts/UI-common";
import { IconBarIcon } from "@me-shared-parts/UI-common/icon-bar";

export const VIEW_AWARDS = 'awards';
export const VIEW_BIOS = 'bios';
export const VIEW_COMPANIES = 'companies';
export const VIEW_DEMOGRAPHICS = 'demographics';
export const VIEW_DETAILS = 'details';
export const VIEW_INTERVIEWS = 'interviews';
export const VIEW_JUDGEMENTS = 'judgements';
export const VIEW_MENTORSHIPS = 'mentorships';
export const VIEW_NOTES = 'notes';
export const VIEW_PROGRAMMING = 'programming';
export const VIEW_READINGS = 'readings';
export const VIEW_RELATIONSHIPS = 'relationships';
export const VIEW_TAGS = 'tags';

export const views: IconBarIcon[] = [
	{ key: VIEW_DETAILS, icon: Icon.action_more, tooltip: 'Person Details', selected: true },
	{ key: VIEW_NOTES, icon: Icon.notes_some, tooltip: 'Notes', selected: true },
	{ key: VIEW_COMPANIES, icon: Icon.concept_company, tooltip: 'Companies', selected: true },
	{ key: VIEW_PROGRAMMING, icon: Icon.view_programming, tooltip: 'Programming', selected: true },
	{ key: VIEW_MENTORSHIPS, icon: Icon.view_mentors, tooltip: 'Mentorships', selected: true },
	{ key: VIEW_READINGS, icon: Icon.view_readers, tooltip: 'Readings', selected: true },
	{ key: VIEW_INTERVIEWS, icon: Icon.view_interviewers, tooltip: 'Interviews', selected: true },
	{ key: VIEW_JUDGEMENTS, icon: Icon.view_judges, tooltip: 'Judgements', selected: true },
	{ key: VIEW_RELATIONSHIPS, icon: Icon.view_relationships, tooltip: 'Relationships', selected: true },
	{ key: VIEW_AWARDS, icon: Icon.concept_award, tooltip: 'Awards', selected: true },
	{ key: VIEW_TAGS, icon: Icon.view_tags, tooltip: 'Tags', selected: true },
	{ key: VIEW_BIOS, icon: Icon.view_bios, tooltip: 'Languages & Bios', selected: true },
	{ key: VIEW_DEMOGRAPHICS, icon: Icon.view_demographics, tooltip: 'Demographics', selected: true },
];