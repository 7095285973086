
export const TextUtilities = {
  singularOrPlural(count: number, singularLabel: string, pluralLabel: string) {
    return count == 1 ? singularLabel : pluralLabel;
  },

  singularOrPluralWithCount(count: number, singularLabel: string, pluralLabel: string) {
    return `${count.toLocaleString()} ${count == 1 ? singularLabel : pluralLabel}`;
  },

  formatPhone(phoneNumber: string): string {
    let formattedNumber = phoneNumber || '';

    if (phoneNumber.length == 12 && phoneNumber.startsWith('+1')) {
      const match = phoneNumber.slice(2).match(/^(\d{3})(\d{3})(\d{4})$/);
      if (match) formattedNumber = '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }

    return formattedNumber;
  },

  isValidUrl(url: string) {
    const WEBSITE_REGEX = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
    url = (url ?? '').trim();
    return !!url.match(WEBSITE_REGEX);
  },

  isValidEmail(email: string) {
    const EMAIL_REGEX = /^[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9][A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/;
    email = (email ?? '').trim();
    return !!email.match(EMAIL_REGEX);
  },

  /**
   * Trims and lowercases the email address. If it is not a valid email returns null.
   */
  fixEmail(email: string) {

    email = (email ?? '').trim().toLowerCase();

    if (TextUtilities.isValidEmail(email)) return email;
    else return null;
  },

  /**
   * Convert text to "Title Case"
   * @param text 
   */
  titleCase(text: string): string {

    const smallWords = /^(a|an|and|as|at|but|by|en|for|if|in|nor|of|on|or|per|the|to|vs?\.?|via)$/i;

    return ('' + text)
      .trim()
      .toLowerCase()
      .replace(/[A-Za-z0-9\u00C0-\u00FF]+[^\s-]*/g, (match, index, title) => {

        if (index > 0 && index + match.length !== title.length &&
          match.search(smallWords) > -1 && title.charAt(index - 2) !== ":" &&
          (title.charAt(index + match.length) !== '-' || title.charAt(index - 1) === '-') &&
          title.charAt(index - 1).search(/[^\s-]/) < 0) {
          return match.toLowerCase();
        }

        if (match.substr(1).search(/[A-Z]|\../) > -1) {
          return match;
        }

        return match.charAt(0).toUpperCase() + match.substr(1);
      });
  },

  /**
   * Convert text to "Sentence case."
   * @param text
   */
  sentenceCase(text: string): string {
    return ('' + text)
      .toLowerCase()
      .split('. ')
      .map(s => s.trim())
      .filter(s => !!s.length)
      .map(s => s[0].toUpperCase() + s.slice(1))
      .join('. ');
  },

  /**
   * Adjusts text by trimming it and if the text is all lower or all upper case
   * then convert it to either title case or sentence case.
   * @param text 
   * @param kind 
   */
  fixCase(text: string, kind: 'title-case' | 'sentence-case' = 'title-case'): string {
    // Ensure it's a string and trim off spaces
    text = ('' + text).trim();

    // Allow all uppercase for two letters.
    if (text.length == 2 && kind == 'title-case' && text == text.toUpperCase()) return text;

    const lower = text.toLowerCase();
    
    if (text == lower || text == text.toUpperCase()) {
      if (kind == 'title-case') return TextUtilities.titleCase(lower);
      else if (kind == 'sentence-case') return TextUtilities.sentenceCase(lower);
    }

    return text;
  },
};
