import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { DbConceptName, SpecialistStatus } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';
import { DroplistItem, getIconClass, getIconContext, Icon } from '@me-shared-parts/UI-common';
import { SiteAreaService } from '@SITE-area';


export interface SpecialistCallBackValue { personId: number, topicId: number, languageId: number, status: SpecialistStatus, note: string, }


@Component({
	selector: 'site-specialist-edit-dialog',
	templateUrl: './specialist-edit.dialog.html',
})
export class SiteSpecialistEditDialog extends DestroyablePart implements OnInit {
	//
	// The action buttons
	//
	updateAction: DialogAction<SpecialistCallBackValue> = {
		id: 'update',
		enabled: false,
		visible: true,
		label: 'Update',
		linkType: 'callback',
		callback: this.save.bind(this),
		willCloseDialog: true,
	};

	cancelAction: DialogAction = {
		id: 'close',
		enabled: true,
		visible: true,
		label: 'Cancel',
		linkType: "no-action",
		willCloseDialog: true,
	};

	actions: DialogAction<SpecialistCallBackValue | void>[] = [this.updateAction, this.cancelAction];
	icon = getIconClass(Icon.action_edit);

	personIcon = getIconContext(Icon.concept_person, undefined, 'never', 'Person');

	header = 'Edit Specialist';
	isAdd: boolean;

	topics: DroplistItem[] = [...this.ds.domain.topic.getAll()].filter(topic => topic.enabled && topic.needsSpecialist).map(topic => (
		{ iconContext: getIconContext(Icon.droplist_pickOne, undefined, 'never', topic.shortNameLabel), uniqueId: topic.topicId }
	));
	selectedTopic: DroplistItem;

	languages: DroplistItem[] = [];
	selectedLanguage: DroplistItem;

	statuses = ['Consider', 'Active', 'Inactive'];
	selectedStatus: SpecialistStatus;

	readonly: boolean;
	siteId: number;

	newSelectedValue: SpecialistCallBackValue = { personId: undefined, topicId: undefined, languageId: undefined, note: undefined, status: undefined };


	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ isAdd: boolean, personId: number, topicId: number, languageId: number, status: SpecialistStatus, note: string }>,
		public dialogRef: MatDialogRef<SiteSpecialistEditDialog>,
		private util: UtilityService,
		private ds: DataService,
		private siteAreaService: SiteAreaService,
	) {
		super();
	}


	async ngOnInit() {
		super.initDestroyable();

		this.siteId = this.siteAreaService.getId().id;
		const langIds = this.util.array.cleanNumericIds([...await this.ds.admin.siteProgram.getAllPackagesAsArray()].filter(pgm => pgm.siteId == this.siteId)
			.map(sp => sp.program.languageId));

		this.languages = [...this.ds.domain.language.getAll()].filter(lang => langIds.includes(lang.languageId)).map(language => (
			{ iconContext: getIconContext(Icon.droplist_pickOne, undefined, 'never', language.name), uniqueId: language.languageId }
		));

		super.subscribe([
			this.siteAreaService.accessAndId$,
		], async ([accessAndId]) => {
			this.readonly = accessAndId?.access?.root != 'Write';
		});

		const { isAdd, languageId, note, personId, status, topicId } = this.dialog.data;

		this.personIcon.text = (await this.ds.admin.person.getOne(personId))._name;
		this.isAdd = isAdd;


		if (isAdd) {
			this.header = 'Add Specialist';
			this.icon = getIconClass(Icon.action_add);
			this.updateAction.id = 'add';
			this.updateAction.label = 'Add';
			this.updateAction.visible = true;
			this.newSelectedValue = { personId, topicId: undefined, languageId: undefined, note: '', status: undefined };
		}
		else {
			this.newSelectedValue = { languageId, note: note || '', personId, status, topicId };
			this.selectedTopic = this.topics.find(topic => topic.uniqueId == topicId);
			this.selectedLanguage = this.languages.find(language => language.uniqueId == languageId);
		}

		this.checkIfCanSave();
	}

	checkIfCanSave() {

		const data = this.dialog.data;
		this.updateAction.enabled = !this.readonly
			&& !!this.newSelectedValue.topicId
			&& !!this.newSelectedValue.languageId
			&& !!this.newSelectedValue.status
			&& this.newSelectedValue.personId !== undefined
			&& (this.newSelectedValue.topicId != data.topicId
				|| this.newSelectedValue.languageId != data.languageId
				|| this.newSelectedValue.status != data.status
				|| this.newSelectedValue.note != (data.note || '')
			);
	}



	public onTopicSelectionChange(e: DroplistItem) {
		this.selectedTopic = e;
		this.newSelectedValue.topicId = e ? this.selectedTopic.uniqueId : undefined;
		this.checkIfCanSave();
	}

	public onLanguageSelectionChange(e: DroplistItem) {
		this.selectedLanguage = e;
		this.newSelectedValue.languageId = e ? this.selectedLanguage.uniqueId : undefined;
		this.checkIfCanSave();
	}


	public onStatusSelectionChange(e: SpecialistStatus | undefined) {
		this.newSelectedValue.status = e;
		this.checkIfCanSave();
	}

	onInput(e) {
		this.newSelectedValue.note = this.util.text.fixCase((e.target.value ?? '').trim(), 'sentence-case');
		this.checkIfCanSave();
	}

	async explorePerson() {
		if (!this.newSelectedValue.personId) return;
		await this.ds.explorer.explore(DbConceptName.Person, this.newSelectedValue.personId);
	}


	async save() {
		if (this.dialog.data.isAdd) {

			const specialistsBySiteId = await this.ds.admin.specialist.getBySiteIds([this.siteId]);
			const specialists = specialistsBySiteId[this.siteId];

			if (specialists.find(specialist =>
				specialist.personId == this.newSelectedValue.personId
				&& specialist.languageId == this.newSelectedValue.languageId
				&& specialist.topicId == this.newSelectedValue.topicId
				&& specialist.siteId == this.siteId
			)) {
				const message = `This person is already a specialist for this site, topic, and language.`;
				await this.ds.dialogService.showMessage(message, 300, 140);
			}
			else return this.newSelectedValue;
		}
		else return this.newSelectedValue;

	}
}