import { Component, Input, OnInit } from '@angular/core';
import { DbcCompanyProfile } from '@me-interfaces';
import { CompanyProfileWrapper, OldEntityService } from '@me-services/old-services-and-wrappers/entity';
import { MainSelector } from '@me-shared-parts/UI-common';

@Component({
	selector: 'company-profile-editor',
	templateUrl: './SHR-ED_company-profile-editor.part.html',
	styleUrls: ['SHR-ED_company-profile-editor.part.scss'],
})
export class CompanyProfileEditorPart implements OnInit {

	@Input() company: DbcCompanyProfile;
	@Input() initialSelectorKey?: string;

	companyProfileWrapper: CompanyProfileWrapper;
	
	profileSelectors: MainSelector[] = [];
	editorsList = ['Basic Info', 'Description', 'Address', 'Web Links'];
	key: string;

	constructor(
		public entityService: OldEntityService,
	) { }

	async ngOnInit() {
		if (this.initialSelectorKey) {
			this.key = this.initialSelectorKey;
		}
		this.buildSelectors();
		this.companyProfileWrapper = this.entityService.wrapCompanyProfile(this.company);
	}

	buildSelectors() {
		this.profileSelectors = [];
		for (const editor of this.editorsList) {
			this.profileSelectors.push({
				key: editor,
				label: editor,
				locked: false,
			});
		}
	}

	setSelectedKey(key: string) {
		this.key = key;
	}

	selectionChange(key: string) {
		this.initialSelectorKey = key;
	}
}
