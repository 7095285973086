import { AccAreaService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';


@Component({
	selector: 'acc-teams-tab-part',
	templateUrl: './acc-teams-tab.part.html',
})
export class AccTeamsTabPart extends DestroyablePart implements OnInit {

	constructor(
		public accAreaService: AccAreaService,
	) {
		super();
	}

	ngOnInit(): void {
		super.initDestroyable();

	}

}
