import { CouncilMember, DbsCouncilMember, DbsPerson, DbsSite } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { Observable } from "rxjs";
import { PackageManager } from "../package-manager";
import { SingletonsManager } from "../singletons-manager";


export class CouncilMemberPackageManager extends PackageManager<DbsCouncilMember, CouncilMember> {


	constructor(
		singletonsAsOfUTC$: Observable<number>,
		util: UtilityService,
		sm: SingletonsManager<DbsCouncilMember>,
		private person: SingletonsManager<DbsPerson>,
		private site: SingletonsManager<DbsSite>,
	) {
		super(singletonsAsOfUTC$, util, sm);
	}

	/**
	 * Convert an array of DbsCouncilMember to an array of CouncilMember
	 */
	protected async _createPackages(dbsCouncilMember: DbsCouncilMember[]): Promise<CouncilMember[]> {

		//
		// Get all the people
		//
		const personIds: number[] = [];
		const siteIds: number[] = [];


		for (const councilMember of dbsCouncilMember) {
			personIds.push(councilMember.updatedByPersonId, councilMember.personId);
			siteIds.push(councilMember.siteId);
		}

		const personMap = await this.person.getManyAsMap(personIds);
		const siteMap = await this.site.getManyAsMap(siteIds);

		//
		// Package 'em up
		//
		const councilMembers: CouncilMember[] = dbsCouncilMember.map(councilMember => {

			return {
				...councilMember,
				person: personMap[councilMember.personId],
				site: siteMap[councilMember.siteId],
				updatedByPersonName: personMap[councilMember.updatedByPersonId]._name,
			};
		});

		return councilMembers;
	}



	/**
	 * Get all for each personId provided
	 */
	public async getByPersonIds(personIds: readonly number[]): Promise<Readonly<Record<number, ReadonlyArray<CouncilMember>>>> {

		return await this.getPackagesAsArraysByForeignIds('personId', personIds);
	}

	/**
	 * Get all for each siteId provided
	 */
	public async getBySiteIds(siteIds: readonly number[]): Promise<Readonly<Record<number, ReadonlyArray<CouncilMember>>>> {

		return await this.getPackagesAsArraysByForeignIds('siteId', siteIds);
	}
}