import { SiteAreaData, SiteAreaService } from '@SITE-area';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AccCols, GridColumnType, GridSetup, SELECTION_GRID_ACTION_KEY } from '@me-grid';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { GridPart } from '@me-shared-parts/UI-common';
import { Observable, combineLatest, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { SiteDashboardPageService } from '../../site-overview-page.service';


interface AcceleratorRow {
	accId: number,
	updatedUTC: number,
}


@Component({
	selector: 'site-accelerators-view-part',
	templateUrl: './accelerators-view.part.html',
})
export class SiteAcceleratorsViewPart extends DestroyablePart implements OnInit {

	public gridSetup = this.setupGrid();

	public rows$: Observable<AcceleratorRow[]> = of([]);

	@ViewChild(GridPart) meGrid: GridPart<AcceleratorRow>;

	constructor(
		private util: UtilityService,
		public siteAreaService: SiteAreaService,
		public ds: DataService,
		public pageService: SiteDashboardPageService
	) {
		super();
	}

	ngOnInit(): void {

		super.initDestroyable();

		this.rows$ = combineLatest([
			this.ds.admin.singletonsAsOfUTC$,
			this.siteAreaService.data$,
		]).pipe(mergeMap(data => this.buildRows(data[0], data[1])));
	}



	async buildRows(
		singletonsAsOfUTC: number,
		data: SiteAreaData,
	): Promise<AcceleratorRow[]> {

		if (!data) return [];

		const accelerators = (await this.ds.admin.accelerator.getBySiteIds([data.site.siteId]))[data.site.siteId];

		const rows: AcceleratorRow[] = accelerators.map(a => ({
			accId: a.accId,
			updatedUTC: a.updatedUTC,
		}));
		
		return rows;
	}



	private setupGrid(): GridSetup<AcceleratorRow> {

		return {
			experience: 'ACC',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Accelerator",
			rowPluralName: "Accelerators",
			rowKey: "accId",
			stateKey: "site-all-accelerator",
			canAdd: false,
			canRefresh: false,
			canDownload: false,
			columnsToAdd: [
				{ field: AccCols.siteName, header: 'Site', width: 160, type: GridColumnType.text, hidden: true },
			],
			initialState: {
				sort: [{ field: AccCols.startUTC, dir: 'desc' }],
			},
		};
	}



	async gridActionHandler(action: { actionKey: string, rows: AcceleratorRow[] }) {

		if (action.actionKey == SELECTION_GRID_ACTION_KEY) {
			// Nothing to do with a simple row selection
		}
	}

}