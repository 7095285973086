import { AppAreaApplyResponse, AppAreaFuncResponse, AppAreaIdentifier } from "@me-interfaces";
import { AppSubArea } from "@me-services/core/area";
import { DataService } from "@me-services/core/data";
import { FuncService } from "@me-services/core/func";
import { UtilityService } from "@me-services/core/utility";

export class SiteSubAreaConfig extends AppSubArea {

	constructor(
		private func: FuncService,
		ds: DataService,
		util: UtilityService,
		getId: () => AppAreaIdentifier<number>,
		applyResponse: (response: AppAreaFuncResponse) => Promise<AppAreaApplyResponse>,
	) {
		super(ds, util, getId, applyResponse);
	}


	async setExecutiveDirector(parameters: { directorId: number, previousDirectorId: number }) {
		return await this.call(this.func.areas.site.config.setExecutiveDirector, parameters);
	}

	async setAltExecutiveDirector(parameters: { altDirectorId: number, previousAltDirectorId: number }) {
		return await this.call(this.func.areas.site.config.setAltExecutiveDirector, parameters);
	}

	async setProgramManager(parameters: { siteProgramId: number, managerId: number, previousManagerId: number }) {
		return await this.call(this.func.areas.site.config.setProgramManager, parameters);
	}

	async setAltProgramManager(parameters: { siteProgramId: number, altManagerId: number, previousAltManagerId: number }) {
		return await this.call(this.func.areas.site.config.setAltProgramManager, parameters);
	}

	async setProgramIsOffered(parameters: { programId: number, enableProgram: boolean }) {
		return await this.call(this.func.areas.site.config.setProgramIsOffered, parameters);
	}



	//
	// Intern
	//

	async removeSiteIntern(parameters: { siteInternId: number, personId: number }) {
		return await this.call(this.func.areas.site.config.removeSiteIntern, parameters);
	}

	async setSiteInternFlags(parameters: { siteInternId: number, canViewContacts: boolean, canPrepareSiteContacts: boolean, internPersonId: number }) {
		return await this.call(this.func.areas.site.config.setSiteInternFlags, parameters);
	}

	async addSiteIntern(parameters: { personId: number }) {
		return await this.call(this.func.areas.site.config.addSiteIntern, parameters);
	}

	async removeProgramIntern(parameters: { siteInternId: number, siteProgramId: number, personId: number }) {
		return await this.call(this.func.areas.site.config.removeProgramIntern, parameters);
	}

	async addProgramIntern(parameters: { siteInternId: number, siteProgramId: number, personId: number }) {
		return await this.call(this.func.areas.site.config.addProgramIntern, parameters);
	}



	//
	// Council
	//
	async addCouncilMember(parameters: { personId: number }) {
		return await this.call(this.func.areas.site.config.addCouncilMember, parameters);
	}

	async removeCouncilMember(parameters: { councilMemberId: number, personId: number }) {
		return await this.call(this.func.areas.site.config.removeCouncilMember, parameters);
	}
	async setCouncilMember(parameters: { personId: number, startUTC: number, endUTC: number }) {
		return await this.call(this.func.areas.site.config.setCouncilMember, parameters);
	}
}