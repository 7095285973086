import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';


@Component({
	selector: 'opt-out-demographics-dialog',
	template: `<me-dialog-frame header='What does it mean to "Opt Out"?' [actions]='actions' [dialogRef]='dialogRef'> 

		<p>While we highly encourage users to self report their demographic information, it is voluntary. 
		The Opted Out option identifies users that have indicated they will not complete their demographics.</p>
		
		<p>Users that have not completed their demographics have a card on their dashboard asking them to do so, unless this option is selected.</p>
		
		<p>The demographic status icons show a green check mark if this option is selected, regardless whether they have filled out their demographics.</p>

	</me-dialog-frame>`,
	styles: ['@import "app-variables";',],
})
export class OptOutDemographicsDialog {

	noAction: DialogAction = {
		id: 'okay',
		enabled: true,
		visible: true,
		label: 'Okay',
		linkType: "no-action",
		willCloseDialog: true,
		url: undefined,
	};

	actions: DialogAction[] = [this.noAction];

	constructor(
		@Inject(MAT_DIALOG_DATA) dialog: DialogContext<any>,
		public dialogRef: MatDialogRef<OptOutDemographicsDialog, DialogAction>,
	) { }
}
