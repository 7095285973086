import { DbConceptName, Dbs, Immutable, Person, PitchContest } from '@me-interfaces';


interface MutableDbsPicReader extends Dbs {

	readonly _concept: DbConceptName.PicReader,

	picReaderId: number,
	picId: number,
	personId: number,
	readApplicationIds: number[],
	updatedUTC: number,
	updatedByPersonId: number,
}


interface MutablePicReader extends MutableDbsPicReader {
	person: Person,
	pitchContest: PitchContest,
	updatedByPersonName: string,
}


export interface DbsPicReader extends Immutable<MutableDbsPicReader> { }
export interface PicReader extends Immutable<MutablePicReader> { }