import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class BlurService {

	private readonly enabled = new BehaviorSubject<boolean>(false);
	public readonly enabled$ = this.enabled.asObservable();

	public toggle() {
		this.enabled.next(!this.enabled.value);
	}
}