import { NationalDashboardPageService, SharedDashboardsService } from '@ADMIN-area';
import { Component } from '@angular/core';
import { GridSetup } from '@me-grid';
import { map } from 'rxjs/operators';


@Component({
	selector: 'shared-businesses-view-part',
	templateUrl: './businesses-view.part.html',
})
export class SharedBusinessesViewPart {

	public gridSetup = this.setupGrid();

	public rows$ = this.sharedDashboardsService.dashboardData$.pipe(
		map(data => data ? data.overall.companyIds.map(companyId => ({ companyId })) : [])
	);


	constructor(
		public pageService: NationalDashboardPageService,
		private sharedDashboardsService: SharedDashboardsService,
	) {
	}


	private setupGrid(): GridSetup<{ companyId: number }> {

		return {
			experience: 'COMPANY',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Business",
			rowPluralName: "Businesses",
			rowKey: "companyId",
			stateKey: "shared-businesses-view-part",
			canAdd: false,
			canRefresh: false,
			canDownload: false,
			initialState: {},
		};
	}

}