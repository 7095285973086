import { Component, Input, OnChanges } from '@angular/core';


@Component({
	selector: 'menu-label',
	templateUrl: './ACS_menu-label.part.html',
	styleUrls: ['./ACS_menu-label.part.scss']
})

export class MenuLabelPart implements OnChanges {

	@Input() label: string;

	showLabel: boolean;

	public ngOnChanges(): void {
		if (this.label.startsWith(':')) {
			this.showLabel = true;
			this.label = this.label.slice(1);
		}
		else {
			this.showLabel = false;
		}
	}
}