import { PitchContest } from "@me-interfaces";
import { DataService } from "@me-services/core/data";


/**
 * Get all pitch contests for a set of siteProgramIds
 */
export async function getPics(ds: DataService, siteProgramIds: number[]): Promise<{ pics: ReadonlyArray<PitchContest>, picIds: Readonly<number[]> }> {

	if (siteProgramIds.length == 0) return { pics: [], picIds: [] };

	const pics = (await ds.admin.pitchContest.getAllPackagesAsArray()).filter(p => siteProgramIds.includes(p.siteProgramId));
	const picIds = pics.map(p => p.picId);

	return { pics, picIds };
}