import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ErrorPageService } from '@me-services/ui/error-page';
import { PageSpinnerService } from '@me-services/ui/page-spinner';

@Component({
	selector: 'access-data-error-part',
	encapsulation: ViewEncapsulation.None,
	templateUrl: './data-error.part.html',
	styleUrls: ['./data-error.part.scss']
})
export class AccessErrorDataPart implements OnInit {

	public errorType: 'Generic' | 'UnverifiedEmail' = undefined;

	constructor(
		private errorPage: ErrorPageService,
		private matDialog: MatDialog,
		private pageSpinnerService: PageSpinnerService,
	) {
	}

	ngOnInit() {
		const details = this.errorPage.snapshot;

		this.matDialog.closeAll();
		this.pageSpinnerService.forceOff();

		if (details.message == 'Error: No Verified Email Address') this.errorType = 'UnverifiedEmail';
		else this.errorType = 'Generic';
	}
}