import { DbConceptName, Dbs, Immutable } from '@me-interfaces';


interface MutableDbsAgreementType extends Dbs {

	readonly _concept: DbConceptName.AgreementType,

	readonly agreementTypeId: number,
	readonly nameES: string,
	readonly nameEN: string,
	readonly latestAgreementVersionId: number,
	readonly slug: string,
	readonly createdUTC: number,
	readonly updatedUTC: number,
	readonly updatedByPersonId: number,
}


export interface DbsAgreementType extends Immutable<MutableDbsAgreementType> { }