import { DdCityRow } from './dd-city-row';
import { DdCity } from './dd-city';
import { DdObjects } from '../dd-objects';
import { OldDomainDataService } from '..';

/**
 * @deprecated Use the DataService instead
 */
export class DdCities extends DdObjects<DdCityRow, DdCity> {

	constructor(private dd: OldDomainDataService) {
		super('cityId');
	}


	async loadData(hash: string, data: DdCityRow[]) {
		const objects = [...data].map(d => new DdCity(this.dd, d));
		this.loadObjects(hash, objects);
	}
}