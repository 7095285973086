
import { DdNoteCategoryRow } from './dd-note-category-row';
import { DdNoteCategory } from './dd-note-category';
import { DdObjects } from '../dd-objects';
import { OldDomainDataService } from '..';

/**
 * @deprecated Use the DataService instead
 */
export class DdNoteCategories extends DdObjects<DdNoteCategoryRow, DdNoteCategory> {

	constructor(private dd: OldDomainDataService) {
		super('noteCategoryId');
	}

	async loadData(hash: string, data: DdNoteCategoryRow[]) {
		const objects = data.map(d => new DdNoteCategory(this.dd, d));
		this.loadObjects(hash, objects);
	}
}