<div class="root-page" [class.page-is-spinning]='pageSpinner.value$ | async'>

	<ng-container>
		<access-login-part *ngIf="(auth.engineName$|async)==='ME' && atRootUrl$|async"></access-login-part>
		<router-outlet></router-outlet>
		<access-data-error-part *ngIf="(auth.engineName$|async)==='ME' && (errorPage.details$|async).error"></access-data-error-part>
	</ng-container>

	<div class="page-spinner">
		<div class="ps-icons">
			<i class='ps-icon fas fa-spinner-third fa-fw'></i>
			<i class='ps-icon fas fa-spinner-third fa-fw'></i>
			<i class='ps-icon fas fa-spinner-third fa-fw'></i>
			<i class='ps-icon fas fa-spinner-third fa-fw'></i>
			<i class='ps-icon fas fa-spinner-third fa-fw'></i>
			<i class='ps-icon fas fa-spinner-third fa-fw'></i>
			<i class='ps-icon fas fa-spinner-third fa-fw'></i>
		</div>
		<div class='page-spinner-text'><span></span></div>
		<div class='page-spinner-desc'><div>{{ pageSpinner.desc$ | async }}</div></div>
	</div>

</div>