import { ApplicationStatusFilter, NationalDashboardPageService } from '@ADMIN-area';
import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { ApplicationCols, GridColumnType, GridSetup, GridValueStyle, SELECTION_GRID_ACTION_KEY } from '@me-grid';
import { ACC_APPLICATION_STATUS, AppAreaIdentifier, ApplicationStatusId } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { GridPart } from '@me-shared-parts/UI-common';
import { Observable, of } from 'rxjs';
import { mergeMap, takeUntil } from 'rxjs/operators';


interface ApplicationRow {
	applicationId: number,
	programId: number,
	accId: number,
	picId: number,
	siteCode: string,
	siteId: number,
	programName: string,
	type: 'Accelerator' | 'Pitch Contest',
	stageName: string,
	language: string,
	updatedUTC: number,
}

@Component({
	selector: 'shared-acc-applications-view-part',
	templateUrl: './acc-applications-view.part.html',
})
export class SharedAccApplicationsViewPart extends DestroyablePart implements OnInit, AfterViewInit {

	@Input() identifier: AppAreaIdentifier<number>;
	@ViewChild(GridPart) meGrid: GridPart<ApplicationRow>;

	public gridSetup = this.setupGrid();
	public rows$: Observable<ApplicationRow[]> = of([]);


	constructor(
		private util: UtilityService,
		public ds: DataService,
		public pageService: NationalDashboardPageService
	) {
		super();
	}

	ngOnInit(): void {

		if (!this.identifier) throw new Error(`The identifer attribute was not set for <shared-acc-applications-view-part>`);

		super.initDestroyable();

		this.rows$ = this.ds.admin.singletonsAsOfUTC$.pipe(mergeMap(utc => this.buildRows(this.ds)));


		this.pageService
			.applicationsStatusFilter$
			.pipe(takeUntil(this.destroyed$))
			.subscribe(this.applyStatusFilter.bind(this));
	}


	ngAfterViewInit() {
		console.log(`ngAfterViewInit - this.meGrid =`, this.meGrid);
		this.applyStatusFilter(this.pageService.applicationsStatusFilter$.value);
	}


	applyStatusFilter(filter: ApplicationStatusFilter) {

		if (this.meGrid) {
			const grid = this.meGrid.grid;
			console.log(`meGrid.grid = `, grid);

			if (filter == 'All') grid.removeFilter(ApplicationCols.status);

			else if (filter == 'Accepted') grid.patchFilter({ field: ApplicationCols.status, operator: 'contains', value: ACC_APPLICATION_STATUS[ApplicationStatusId.TeamPromoted] });

			else if (filter == 'Open') grid.patchCompositeFilter([ApplicationCols.status], {
				logic: 'or', filters: [
					{ field: ApplicationCols.status, operator: 'contains', value: ACC_APPLICATION_STATUS[ApplicationStatusId.AcceptPending] },
					{ field: ApplicationCols.status, operator: 'contains', value: ACC_APPLICATION_STATUS[ApplicationStatusId.ReadPending] },
					{ field: ApplicationCols.status, operator: 'contains', value: ACC_APPLICATION_STATUS[ApplicationStatusId.InterviewPending] },
					{ field: ApplicationCols.status, operator: 'contains', value: ACC_APPLICATION_STATUS[ApplicationStatusId.SelectPending] },
					{ field: ApplicationCols.status, operator: 'contains', value: ACC_APPLICATION_STATUS[ApplicationStatusId.TeamPending] },
				]
			});

			else if (filter == 'Not Accepted') grid.patchCompositeFilter([ApplicationCols.status], {
				logic: 'and', filters: [
					{ field: ApplicationCols.status, operator: 'doesnotcontain', value: ACC_APPLICATION_STATUS[ApplicationStatusId.AcceptPending] },
					{ field: ApplicationCols.status, operator: 'doesnotcontain', value: ACC_APPLICATION_STATUS[ApplicationStatusId.ReadPending] },
					{ field: ApplicationCols.status, operator: 'doesnotcontain', value: ACC_APPLICATION_STATUS[ApplicationStatusId.InterviewPending] },
					{ field: ApplicationCols.status, operator: 'doesnotcontain', value: ACC_APPLICATION_STATUS[ApplicationStatusId.SelectPending] },
					{ field: ApplicationCols.status, operator: 'doesnotcontain', value: ACC_APPLICATION_STATUS[ApplicationStatusId.TeamPending] },
					{ field: ApplicationCols.status, operator: 'doesnotcontain', value: ACC_APPLICATION_STATUS[ApplicationStatusId.TeamPromoted] },
				]
			});

			else grid.removeFilter(ApplicationCols.status);
		}
		else {
			console.error(`this.meGrid == false`);
		}
	}


	async buildRows(ds: DataService): Promise<ApplicationRow[]> {

		const applications = await ds.admin.application.getAllPackagesAsArray();

		if (!applications) return [];

		const accApplications = applications.filter(a => !!a.accId);

		const rows: ApplicationRow[] = accApplications.map(a => ({
			applicationId: a.applicationId,
			programId: a.siteProgram.programId,
			accId: a.accId,
			picId: a.picId,
			siteCode: a.siteProgram.site.code,
			siteId: a.siteProgram.siteId,
			programName: a.accId ? a.accelerator.name : a.pitchContest.name,
			type: a.accId ? 'Accelerator' : 'Pitch Contest',
			stageName: a.accId ? `${a.accelerator.accStageId / 10}:  ${a.accelerator.stage.name}`
				: `${a.pitchContest.picStageId / 10}:  ${a.pitchContest.stage.name}`,
			language: a.siteProgram.program.language.name,
			updatedUTC: a.updatedUTC,
		}));
		return rows;
	}


	private valueStyle(row: ApplicationRow, field: string): GridValueStyle {
		const value = row[field];

		if (field == 'type') { // Program
			if (value == 'Accelerator') return { icon: 'far fa-tachometer-alt-fast', textStyle: 'Hidden' };
			if (value == 'Pitch Contest') return { icon: 'far fa-bullhorn', textStyle: 'Hidden' };
		}

		return { icon: 'fal fa-question', iconColor: 'Red', textStyle: 'Hidden' };
	}


	private setupGrid(): GridSetup<ApplicationRow> {
		const valueStyle = this.valueStyle.bind(this);

		return {
			experience: 'APPLICATION',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Application",
			rowPluralName: "Applications",
			rowKey: "applicationId",
			stateKey: "national-all-applications",
			canAdd: false,
			canRefresh: false,
			canDownload: false,
			// saveNoteAction: async (text: string, id: number) => await this.accAreaService.applications.actions.setNotes({ notes: text, applicationId: id }),
			columnsToAdd: [
				{ field: "programId", header: "programId", width: 60, type: GridColumnType.number, hidden: true },
				{ field: "accId", header: "accId", width: 60, type: GridColumnType.number, hidden: true },
				{ field: "picId", header: "picId", width: 60, type: GridColumnType.number, hidden: true },
				{ field: "siteId", header: "siteId", width: 60, type: GridColumnType.number, hidden: true },
				{ field: "siteCode", header: "Site", width: 120, type: GridColumnType.text },
				{ field: "programName", header: "Program Name", width: 150, type: GridColumnType.text },
				{ field: "type", header: "Program", width: 100, type: GridColumnType.text, valueStyle },
				{ field: "stageName", header: "Stage", width: 160, type: GridColumnType.text },
				{ field: "language", header: "Language", width: 100, type: GridColumnType.text },
				{ field: ApplicationCols.status, header: "Application Status", width: 175, type: GridColumnType.text, hidden: false },
			],
			initialState: {
				sort: [{ field: 'APPLICATION_companyName', dir: 'asc' }],
			},
		};
	}



	async gridActionHandler(action: { actionKey: string, rows: ApplicationRow[] }) {

		if (action.actionKey == SELECTION_GRID_ACTION_KEY) {
			// Nothing to do with a simple row selection
		}
	}

}