import { Old_AdminFlags, RoutePermission } from '@me-interfaces';

export interface Old_UserAdminFlagsForSite {
	isDirector: boolean,
	isManager: boolean,
	isSiteIntern: boolean,
	isSiteStaffOrBetter: boolean,
	isSiteAdmin: boolean,
}

/**
 * Extends the admin flags to include sites that can be administrated.
 */
export interface Old_UserAdminFlags extends Old_AdminFlags {
	allSiteAccess: boolean,
	adminRoutes: { [index: string]: { [index: string]: RoutePermission } },
	bySiteCode: {
		[index: string]: Old_UserAdminFlagsForSite
	},
	bySiteId: {
		[index: number]: Old_UserAdminFlagsForSite
	},
	canReadContactsDb: boolean,
}