import { Component, OnInit, ViewChild } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { DataService } from '@me-services/core/data';
import { DialogService } from '@me-services/ui/dialog';
import { TextBoxComponent } from '@progress/kendo-angular-inputs';
import { filter, map, takeUntil } from 'rxjs';
import { SearchFilters } from '../../engine/parse-search-text';

export interface SearchFilter { value: 'awardee' | 'contactAdmin' | 'deceased' | 'droppedOut' | 'eforallAdmin' | 'noContact' | 'redFlag' | 'techAdmin', text: string }

@Component({
	selector: 'search-header',
	templateUrl: './search-header.part.html',
	styleUrls: ['./search-header.part.scss'],
})
export class SearchHeaderPart extends DestroyablePart implements OnInit {

	@ViewChild('textField') textField: TextBoxComponent;



	constructor(
		public readonly ds: DataService,
		private readonly dialogService: DialogService,
	) {
		super();
	}


	ngOnInit() {
		this.ds.searcher.searchService.loading$
			.pipe(
				takeUntil(this.destroyed$),
				filter(l => l == undefined)
			)
			.subscribe(() => {
				//
				// Set focus to the search text box after loading completed.
				//
				if (this.textField) {
					console.log('Setting focus to search text field.');
					setTimeout(this.textField.focus.bind(this.textField));
				}
				else console.error(`textField is undefined`);
			});
	}


	public filters: SearchFilter[] = [
		{ value: 'awardee', text: 'Only Include People, Companies, or Teams that Received an Award' },
		{ value: 'contactAdmin', text: 'Only Include People that can Add/Edit Contacts (Staff)' },
		{ value: 'deceased', text: 'Only Include People that are Deceased' },
		{ value: 'droppedOut', text: 'Only Include Accelerator Teams that Dropped Out' },
		{ value: 'eforallAdmin', text: 'Only Include People that are EforAll Admins' },
		{ value: 'noContact', text: 'Only Include People That Wish to Not Be Contacted' },
		{ value: 'redFlag', text: 'Only Include People and Companies with a Red Flag Note' },
		{ value: 'techAdmin', text: 'Only Include People that are Tech Admins' },
	];


	public selectedFilters$ = this.ds.searcher.searchService.filters$.pipe(
		map(filters => {
			const f: SearchFilter[] = [];
			if (filters.awardee) f.push(this.filters.find(f => f.value == 'awardee'));
			if (filters.contactAdmin) f.push(this.filters.find(f => f.value == 'contactAdmin'));
			if (filters.deceased) f.push(this.filters.find(f => f.value == 'deceased'));
			if (filters.droppedOut) f.push(this.filters.find(f => f.value == 'droppedOut'));
			if (filters.eforallAdmin) f.push(this.filters.find(f => f.value == 'eforallAdmin'));
			if (filters.noContact) f.push(this.filters.find(f => f.value == 'noContact'));
			if (filters.redFlag) f.push(this.filters.find(f => f.value == 'redFlag'));
			if (filters.techAdmin) f.push(this.filters.find(f => f.value == 'techAdmin'));
			return f;
		}),
	)


	async searchFieldChange(value: string) {
		this.ds.searcher.searchService.setSearchText(value);
	}
	

	async searchFieldKeyup(e: KeyboardEvent) {

		if (e.isTrusted && e.key == 'Enter') {
			await this.ds.util.setTimeout();
			await this.ds.searcher.searchService.search();
		}
	}


	async help() {
		await this.dialogService.showMessage(`
Filters and search text are combined with 'and' logic.

For example, if two filters and search text are used, only items that match the first filter AND the second filter AND the text will be included in the results.`);
	}


	public setSearchFilters(filters: SearchFilter[]) {

		const f: SearchFilters = {
			awardee: !!filters.find(filter => filter.value == 'awardee'),
			contactAdmin: !!filters.find(filter => filter.value == 'contactAdmin'),
			deceased: !!filters.find(filter => filter.value == 'deceased'),
			droppedOut: !!filters.find(filter => filter.value == 'droppedOut'),
			eforallAdmin: !!filters.find(filter => filter.value == 'eforallAdmin'),
			noContact: !!filters.find(filter => filter.value == 'noContact'),
			redFlag: !!filters.find(filter => filter.value == 'redFlag'),
			techAdmin: !!filters.find(filter => filter.value == 'techAdmin'),
			filtered: false,
		};

		f.filtered =
			f.awardee ||
			f.contactAdmin ||
			f.deceased ||
			f.droppedOut ||
			f.eforallAdmin ||
			f.noContact ||
			f.redFlag ||
			f.techAdmin;


		this.ds.searcher.searchService.setSearchFilter(f);
	}


	public readonly filterPopup = {
		appendTo: 'root',
		width: 520,
	};


	getFiltersSummaryTag(length: number) {
		return length == 1 ? '1 Filter' : `${length} Filters`;
	}

}