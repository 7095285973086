<div class='staff-overview-page'>
	<me-toolbar icon='Staff'>
	</me-toolbar>

	<page-tabs [tabNames]='["Staff", "Counts"]' [pageService]='pageService'
		(layoutChange)='onLayoutChange($event)'>
		<ng-template pageTab1>
			<staff-overview-views-part></staff-overview-views-part>
		</ng-template>

		<ng-template pageTab2>
			<staff-counts-tab-part></staff-counts-tab-part>
		</ng-template>
	</page-tabs>

</div>