import { DbsAward, DbsSite, PicTeam, PitchContest } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";

export function getPicAwards(
	util: UtilityService,
	sites: readonly DbsSite[],
	awards: readonly DbsAward[],
	picsBySiteId: Record<number, PitchContest[]>,
	picTeams: readonly PicTeam[],
) {

	const picAwards = awards.filter(a => !!a.picTeamId);

	const picAwardeesBySiteId: Record<number, number> = {};
	const picAwardsBySiteId: Record<number, number> = {};

	for (const site of sites) {

		const siteId = site.siteId;
		const pics = picsBySiteId[siteId] ?? [];

		const picIds = pics.map(pic => pic.picId);

		const picTeamIds = picTeams
			.filter(t => picIds.includes(t.picId))
			.map(t => t.picTeamId);

		const sitePicAwards = picAwards.filter(a => picTeamIds.includes(a.picTeamId));

		picAwardeesBySiteId[siteId] = util.array.cleanNumericIds(sitePicAwards.map(a => a.picTeamId)).length;
		picAwardsBySiteId[siteId] = util.array.sum(sitePicAwards.map(a => a.value));
	}

	return { picAwardeesBySiteId, picAwardsBySiteId };
}