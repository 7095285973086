import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { DbcAccTeamGoal, DB_LENGTH, QuarterlyGoalFlags } from '@me-interfaces';
import { UtilityService } from '@me-services/core/utility';
import { LabelsService } from '@me-services/ui/labels';
import { AccGoalEditRow, QUARTER_NAMES } from '../../acc-goals-interfaces';

@Component({
	selector: 'acc-goals-edit-part',
	templateUrl: './SHR-PG_acc-goals-edit.part.html',
	styleUrls: ['SHR-PG_acc-goals-edit.part.scss'],
})
export class AccGoalsEditPart extends DestroyablePart implements OnInit, OnChanges {
	@Input() selectedGoal: DbcAccTeamGoal;
	@Input() selectedGoalQuarterlyGoalFlags: QuarterlyGoalFlags

	@Output() editGoal = new EventEmitter<AccGoalEditRow>();

	orgValues = {
		summary: '',
		details: '',
		progress: ''
	};

	newValues = {
		summary: '',
		details: '',
		progress: ''
	};

	updateEnabled = false;
	QUARTER_NAMES = QUARTER_NAMES;

	//
	// Flags that indicate if there are values is empty therefore data cannot be saved.
	//
	missing = {
		summary: false,
	};

	summaryMaxLength: number = DB_LENGTH.accTeamGoal.summary[1];

	inlineLabels = this.labels.trackInlineLabels(this, [
		'Q1',
		'Q2',
		'Q3',
		'Q4',
		'Update'
	]);


	constructor(
		private util: UtilityService,
		public labels: LabelsService,
	) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();
	}

	ngOnChanges() {
		if (!this.selectedGoal) throw new Error('Missing required attribute: selectedGoal');
		if (!this.selectedGoalQuarterlyGoalFlags) throw new Error('Missing required attribute: selectedGoalQuarterlyGoalFlags');

		if (this.selectedGoal) this.initializeGoal(this.selectedGoal);

	}

	initializeGoal(goal: DbcAccTeamGoal) {
		if (!goal) return;

		// Initialize orgValues and newValues
		this.orgValues.summary = this.newValues.summary = goal.summary || '';
		this.orgValues.details = this.newValues.details = goal.details || '';
		this.orgValues.progress = this.newValues.progress = goal.progress || '';
		this.determineCanSave();
	}



	/**
	 * Event raised when the value of the details and progress fields changes.
	 */
	valueChange(field: 'details' | 'progress', text: string) {
		this.newValues[field] = text;
		this.newValues[field] = text.trim();
		this.determineCanSave();
	}

	/**
	 * Event raised when the value of the summary field changes.
	 */
	onInput(field: 'summary', e) {
		const text = (e.target.value ?? '').trim();
		this.newValues[field] = text;
		this.determineCanSave();
	}

	/**
	 * Event raised when the fields loses focus.
	 */
	onBlur() {
		this.determineCanSave();
	}

	/**
	 * Whenever something changes, check if the update button should be enabled.
	 */
	determineCanSave() {
		this.updateEnabled = false;
		this.missing.summary = !this.newValues.summary;

		const changedSomething = !this.util.values.areSame(this.orgValues, this.newValues);
		const missingSomething = this.missing.summary;

		this.updateEnabled = changedSomething && !missingSomething;
	}

	updateGoal() {
		this.editGoal.emit({ ...this.newValues, accTeamGoalId: this.selectedGoal.accTeamGoalId });
	}
}