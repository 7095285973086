import { Dbs } from "@me-interfaces";
import { Observable } from "rxjs";
import { SingletonForeignId } from "../dexie/dexie-singletons-db";
import { UtilityService } from "../utility";
import { SingletonsManager } from "./singletons-manager";

export abstract class PackageManager<SINGLETON extends Readonly<Dbs>, PACKAGE> {

	/**
	 * Each time we instantiate a package we store it so we don't need to do it again.
	 * This avoids duplicate processing and garbage collection memory churn. However,
	 * each time the singletonsAsOfUTC$ is bumped, we need to clear the cache and
	 * start building them again. 
	 */
	private cache: Record<number, PACKAGE> = {};

	constructor(
		protected singletonsAsOfUTC$: Observable<number>,
		protected util: UtilityService,
		protected sm: SingletonsManager<SINGLETON>,
	) {
		singletonsAsOfUTC$.subscribe(() => {
			util.object.clear(this.cache);
		})
	}


	/**
	 * It's best practice to pass all of the ids needed for a particular singleton type first. If any are
	 * not in the cache then this method will wait for them to be downloaded and added to the cache.
	 */
	public async ensureAllDownloaded(ids: number[]): Promise<void> {
		await this.sm.ensureAllDownloaded(ids);
	}


	/**
	* Get one singleton by id. It will attempt to download it first if it is
	* not in the cache for some reason.
	* 
	* DO NOT call this multiple times in a loop or .map() function!
	* If looping or mapping, call getAllAsMap() FIRST and use the returned map instead.
	*/
	public async getOne(id: number): Promise<Readonly<SINGLETON>> {
		return await this.sm.getOne(id);
	}

	public async getManyAsArray(ids: number[]): Promise<SINGLETON[]> {
		return await this.sm.getManyAsArray(ids);
	}

	public async getManyAsMap(ids: number[]): Promise<Readonly<Record<number, SINGLETON>>> {
		return await this.sm.getManyAsMap(ids);
	}

	public async getAllAsArray(): Promise<ReadonlyArray<SINGLETON>> {
		return await this.sm.getAllAsArray();
	}

	public async getAllAsMap(): Promise<Readonly<Record<number, SINGLETON>>> {
		return await this.sm.getAllAsMap();
	}



	/**
	 * Convert an array of singletons into corresponding packages.
	 * This function delegates to the abstract _createPackages.
	 */
	protected async createPackages(singletons: Readonly<SINGLETON[]>): Promise<PACKAGE[]> {

		if (singletons.length == 0) return [];

		const packages: PACKAGE[] = await this._createPackages(singletons);

		return packages;
	}


	/**
	 * IMPORTANT: Do not call this directly. Instead call createPackages().
	 * Convert an array of singletons into corresponding packages.
	 */
	protected abstract _createPackages(singletons: Readonly<SINGLETON[]>): Promise<PACKAGE[]>;


	/**
	 * Return the packages that match the provided IDs. Build and cache any that are not yet in the cache.
	 */
	private async getPackagesFromCache(ids: ReadonlyArray<number>): Promise<PACKAGE[]> {

		if (ids.length == 0) return [];

		const cleanedIds = this.util.array.cleanNumericIds(ids, false);
		const missingIds = cleanedIds.filter(id => !this.cache[id]);

		await this.sm.ensureAllDownloaded(missingIds);

		const missingSingletons = await this.getManyAsArray(missingIds);
		const missingPackages = await this.createPackages(missingSingletons);

		for (const pkg of missingPackages) {
			const id = pkg[this.sm.identifier];
			this.cache[id] = pkg;
		}

		return cleanedIds.map(id => this.cache[id]);
	}


	/**
	* Get one package by id.
	*
	* DO NOT call this multiple times in a loop or .map() function!
	*
	* If looping or mapping, call getManyPackagesAsMap() FIRST and use the returned map instead.
	*/
	public async getOnePackage(id: number): Promise<PACKAGE> {

		const packages = await this.getPackagesFromCache([id]);
		return packages[0];
	}


	/**
	 * Map an array of ids to an array of packages. Missing singletons will be attempted to be downloaded.
	 */
	public async getManyPackagesAsArray(ids: ReadonlyArray<number>): Promise<ReadonlyArray<PACKAGE>> {

		return await this.getPackagesFromCache(ids);
	}


	/**
	 * Get many packages by id and return as a map for quick lookup
	 */
	public async getManyPackagesAsMap(ids: ReadonlyArray<number>): Promise<Readonly<Record<number, PACKAGE>>> {
		const packages = await this.getPackagesFromCache(ids);
		return this.createPackagesMap(packages)
	}


	/**
	* Package up all of the singletons that are currently in the cache
	*/
	public async getAllPackagesAsArray(): Promise<ReadonlyArray<PACKAGE>> {
		const singletons = await this.sm.getAllAsArray();
		const ids = <number[]>singletons.map(singleton => singleton[this.sm.identifier]);
		return await this.getPackagesFromCache(ids);
	}


	/**
	 * Get all packages and return as a map for quick lookup
	 */
	public async getAllPackagesAsMap(): Promise<Readonly<Record<number, PACKAGE>>> {
		const packages = await this.getAllPackagesAsArray();
		return this.createPackagesMap(packages)
	}


	/**
	 * Lookup packages using one or more foreign ids, and return a map with each foreign id mapping to an array of packages.
	 * 
	 * IMPORTANT: The foreignIdProperty parameter must be configured as a foreign key in the cacheConfigs. See \src\root\services-core\dexie\dexie-singletons-db.ts
	 * 
	 * DO NOT change this method to public.  Instead, PackageManager subclasses should expose wrapper methods (e.g. getByPersonIds) which delegate to this.
	 * 
	 * 
	 * @param foreignIdProperty A foreign key configured in dexie-singletons-db.ts
	 * @param foreignIds One or more numeric ids to lookup
	**/
	protected async getPackagesAsArraysByForeignIds(foreignIdProperty: SingletonForeignId, foreignIds: readonly number[]): Promise<Readonly<Record<number, ReadonlyArray<PACKAGE>>>> {

		//
		// Get an array of singletons for each of the provided foreignId values
		//
		const singletonsByForeignId = await this.sm.getArraysByForeignIds(foreignIdProperty, foreignIds);

		//
		// Get the list ids for the singletons that were found
		//
		const singletonIds: number[] = [];

		for (const foreignId of foreignIds) {
			singletonIds.push(...(singletonsByForeignId[foreignId] || []).map(singleton => singleton[this.sm.identifier]));
		}

		//
		// Ensure that all packages are build and cached.
		// Do this first to load them all in one shot.
		//
		const packageById = await this.getManyPackagesAsMap(singletonIds);

		const map: Record<number, PACKAGE[]> = {};

		for (const foreignId of foreignIds) {

			map[foreignId] = [];

			const singletons = singletonsByForeignId[foreignId] || [];

			for (const singletonId of singletons.map(singleton => singleton[this.sm.identifier])) {
				map[foreignId].push(packageById[singletonId]);
			}

		}

		return map;
	}



	/**
	 * Convert an array of packages into a map for fast lookup by id.
	 */
	public createPackagesMap(packages: ReadonlyArray<PACKAGE>): Readonly<Record<number, PACKAGE>> {
		return packages.reduce((map, p) => {
			const id = p[this.sm.identifier];
			map[id] = p;
			return map;
		}, {});
	}
}