<div class='quarterly-goals-part'>
	<ng-container *ngIf='quarterlies'>
		<message-line *ngIf="tool === 'Quarterlies_StaffViewer'">You have view only access.</message-line>

		<kendo-panelbar class='bare-panelbar' keepItemContent='true' selectable='false'>

			<kendo-panelbar-item *ngIf="tool === 'Quarterlies'" id="instructions" title=''>

				<ng-template kendoPanelBarItemTitle>
					{{ inlineLabels['Instructions'] }}
				</ng-template>

				<ng-template kendoPanelBarContent>
					<div class='instructions'>
						<me-label key='md:Quarterly Instructions'></me-label>
					</div>
				</ng-template>

			</kendo-panelbar-item>

			<kendo-panelbar-item id="quarter0" title=''>

				<ng-template kendoPanelBarItemTitle>
					<div class='header-section'>
						<div>{{inlineLabels['Pre-Accelerator Goals & Progress']}}</div>
						<div>
							<div class="not-editable" *ngIf='!quarterlies.overview.enabled.q0'>
								<i>
									<me-label [key]='disabledKey'></me-label>
								</i>
							</div>
							<div *ngIf='quarterlies.overview.enabled.q0'>
								<div class="remaining-text" *ngIf='(states.q0.status$ | async).numRemaining > 0'>
									{{ (states.q0.status$ | async).numRemaining }} {{inlineLabels['Remaining']}}</div>
								<div class="completed-text" *ngIf='(states.q0.status$ | async).numRemaining === 0'>
									{{inlineLabels['Completed']}}
								</div>
							</div>
						</div>
					</div>
				</ng-template>

				<ng-template kendoPanelBarContent>
					<div *ngIf='tool !== "Quarterlies_StaffViewer"'>
						<field-grid *ngIf='quarterlies.overview.enabled.q0' [fields]='quarterlies.q0.fields'
							[values]='quarterlies.q0.values' [tool]='tool' [forceValidation]='forceValidation'
							[state]='states.q0'>
						</field-grid>

						<field-grid-readonly *ngIf='!quarterlies.overview.enabled.q0' [fields]='quarterlies.q0.fields'
							[values]='quarterlies.q0.values'>
						</field-grid-readonly>
					</div>
					<div *ngIf='tool === "Quarterlies_StaffViewer"'>
						<field-grid-readonly [fields]='quarterlies.q0.fields' [values]='quarterlies.q0.values'>
						</field-grid-readonly>
					</div>
				</ng-template>
			</kendo-panelbar-item>

			<kendo-panelbar-item id="quarter1" title=''>

				<ng-template kendoPanelBarItemTitle>
					<div class='header-section'>
						<div>{{inlineLabels['Accelerator Curriculum Goals & Progress']}}</div>
						<div>
							<div class="not-editable" *ngIf='!quarterlies.overview.enabled.q1'>
								<i>
									<me-label [key]='disabledKey'></me-label>
								</i>
							</div>
							<div *ngIf='quarterlies.overview.enabled.q1'>
								<div class="remaining-text" *ngIf='(states.q1.status$ | async).numRemaining > 0'>
									{{ (states.q1.status$ | async).numRemaining }} {{inlineLabels['Remaining']}}</div>
								<div class="completed-text" *ngIf='(states.q1.status$ | async).numRemaining === 0'>
									{{inlineLabels['Completed']}}
								</div>
							</div>
						</div>
					</div>
				</ng-template>

				<ng-template kendoPanelBarContent>
					<div *ngIf='tool !== "Quarterlies_StaffViewer"'>
						<field-grid *ngIf='quarterlies.overview.enabled.q1' [fields]='quarterlies.q1.fields'
							[values]='quarterlies.q1.values' [tool]='tool' [forceValidation]='forceValidation'
							[state]='states.q1'>
						</field-grid>

						<field-grid-readonly *ngIf='!quarterlies.overview.enabled.q1' [fields]='quarterlies.q1.fields'
							[values]='quarterlies.q1.values'>
						</field-grid-readonly>
					</div>
					<div *ngIf='tool === "Quarterlies_StaffViewer"'>
						<field-grid-readonly [fields]='quarterlies.q1.fields' [values]='quarterlies.q1.values'>
						</field-grid-readonly>
					</div>
				</ng-template>
			</kendo-panelbar-item>

			<kendo-panelbar-item id="quarter2" title=''>

				<ng-template kendoPanelBarItemTitle>
					<div class='header-section'>
						<div>{{inlineLabels['Quarter 2 Goals & Progress']}}</div>
						<div>
							<div class="not-editable" *ngIf='!quarterlies.overview.enabled.q2'>
								<i>
									<me-label [key]='disabledKey'></me-label>
								</i>
							</div>
							<div *ngIf='quarterlies.overview.enabled.q2'>
								<div class="remaining-text" *ngIf='(states.q2.status$ | async).numRemaining > 0'>
									{{ (states.q2.status$ | async).numRemaining }} {{inlineLabels['Remaining']}}</div>
								<div class="completed-text" *ngIf='(states.q2.status$ | async).numRemaining === 0'>
									{{inlineLabels['Completed']}}
								</div>
							</div>
						</div>
					</div>
				</ng-template>

				<ng-template kendoPanelBarContent>
					<div *ngIf='tool !== "Quarterlies_StaffViewer"'>
						<field-grid *ngIf='quarterlies.overview.enabled.q2' [fields]='quarterlies.q2.fields'
							[values]='quarterlies.q2.values' [tool]='tool' [forceValidation]='forceValidation'
							[state]='states.q2'>
						</field-grid>

						<field-grid-readonly *ngIf='!quarterlies.overview.enabled.q2' [fields]='quarterlies.q2.fields'
							[values]='quarterlies.q2.values'>
						</field-grid-readonly>
					</div>
					<div *ngIf='tool === "Quarterlies_StaffViewer"'>
						<field-grid-readonly [fields]='quarterlies.q2.fields' [values]='quarterlies.q2.values'>
						</field-grid-readonly>
					</div>
				</ng-template>
			</kendo-panelbar-item>

			<kendo-panelbar-item id="quarter3" title=''>

				<ng-template kendoPanelBarItemTitle>
					<div class='header-section'>
						<div>{{inlineLabels['Quarter 3 Goals & Progress']}}</div>
						<div>
							<div class="not-editable" *ngIf='!quarterlies.overview.enabled.q3'>
								<i>
									<me-label [key]='disabledKey'></me-label>
								</i>
							</div>
							<div *ngIf='quarterlies.overview.enabled.q3'>
								<div class="remaining-text" *ngIf='(states.q3.status$ | async).numRemaining > 0'>
									{{ (states.q3.status$ | async).numRemaining }} {{inlineLabels['Remaining']}}</div>
								<div class="completed-text" *ngIf='(states.q3.status$ | async).numRemaining === 0'>
									{{inlineLabels['Completed']}}
								</div>
							</div>
						</div>
					</div>
				</ng-template>

				<ng-template kendoPanelBarContent>
					<div *ngIf='tool !== "Quarterlies_StaffViewer"'>
						<field-grid *ngIf='quarterlies.overview.enabled.q3' [fields]='quarterlies.q3.fields'
							[values]='quarterlies.q3.values' [tool]='tool' [forceValidation]='forceValidation'
							[state]='states.q3'>
						</field-grid>

						<field-grid-readonly *ngIf='!quarterlies.overview.enabled.q3' [fields]='quarterlies.q3.fields'
							[values]='quarterlies.q3.values'>
						</field-grid-readonly>
					</div>
					<div *ngIf='tool === "Quarterlies_StaffViewer"'>
						<field-grid-readonly [fields]='quarterlies.q3.fields' [values]='quarterlies.q3.values'>
						</field-grid-readonly>
					</div>
				</ng-template>
			</kendo-panelbar-item>

			<kendo-panelbar-item id="quarter4" title=''>

				<ng-template kendoPanelBarItemTitle>
					<div class='header-section'>
						<div>{{inlineLabels['Quarter 4 Goals & Progress']}}</div>
						<div>
							<div class="not-editable" *ngIf='!quarterlies.overview.enabled.q4'>
								<i>
									<me-label [key]='disabledKey'></me-label>
								</i>
							</div>
							<div *ngIf='quarterlies.overview.enabled.q4'>
								<div class="remaining-text" *ngIf='(states.q4.status$ | async).numRemaining > 0'>
									{{ (states.q4.status$ | async).numRemaining }} {{inlineLabels['Remaining']}}</div>
								<div class="completed-text" *ngIf='(states.q4.status$ | async).numRemaining === 0'>
									{{inlineLabels['Completed']}}
								</div>
							</div>
						</div>
					</div>
				</ng-template>

				<ng-template kendoPanelBarContent>
					<div *ngIf='tool !== "Quarterlies_StaffViewer"'>
						<field-grid *ngIf='quarterlies.overview.enabled.q4' [fields]='quarterlies.q4.fields'
							[values]='quarterlies.q4.values' [tool]='tool' [forceValidation]='forceValidation'
							[state]='states.q4'>
						</field-grid>

						<field-grid-readonly *ngIf='!quarterlies.overview.enabled.q4' [fields]='quarterlies.q4.fields'
							[values]='quarterlies.q4.values'>
						</field-grid-readonly>
					</div>
					<div *ngIf='tool === "Quarterlies_StaffViewer"'>
						<field-grid-readonly [fields]='quarterlies.q4.fields' [values]='quarterlies.q4.values'>
						</field-grid-readonly>
					</div>
				</ng-template>
			</kendo-panelbar-item>
		</kendo-panelbar>
	</ng-container>
</div>