import { AccStageId, DbConceptName, DbdAccLanguageSeason, DbdAccMeetingTimes, DbdAccStage, Dbs, ExplorablePackage, Immutable, SiteProgram } from "@me-interfaces";


interface MutableDbsAccelerator extends Dbs {

	readonly _concept: DbConceptName.Accelerator,

	readonly accId: number,
	readonly accStageId: number,
	readonly siteProgramId: number,
	readonly directorId: number,
	readonly managerId: number,
	readonly name: string,
	readonly longName: string,
	readonly year: number,
	readonly accLanguageSeasonId: number,
	readonly startUTC: number,
	readonly createdUTC: number,
	readonly applStartUTC: number,
	readonly applDuration: number,
	readonly applicantQuestionsId: number,
	readonly readerQuestionsId: number,
	readonly interviewQuestionsId: number,
	readonly judgeQuestionsId: number,
	readonly accMeetingTimesId: number,
	readonly scheduleTemplateId: number,
	readonly matchingHints: string,
	readonly enabledQuarterlyGoals: number,
	readonly canceled: boolean,
	readonly updatedUTC: number,
	readonly updatedByPersonId: number,

	/*
	 * additional column on acc
	 */
	readonly siteId: number,
}



interface MutableAccelerator extends MutableDbsAccelerator {
	directorName: string,
	managerName: string,
	siteProgram: SiteProgram,
	updatedByPersonName: string,
	stage: DbdAccStage,
	accMeetingTimes: DbdAccMeetingTimes,
	accLanguageSeason: DbdAccLanguageSeason,
	season: string,// 1 - Winter or 1 - Inverno
	userTools: {
		applicationUrl: string,
		readingUrl: string,
		interviewingUrl: string,
		matchingUrl: string,
		judgingUrl: string,
	},
}


export interface DbsAccelerator extends Immutable<MutableDbsAccelerator> { }
export interface Accelerator extends ExplorablePackage<DbConceptName.Accelerator>, Immutable<MutableAccelerator> { }