import { NationalDashboardPageService, SharedDashboardsService } from '@ADMIN-area';
import { Component } from '@angular/core';
import { GridSetup } from '@me-grid';
import { map } from 'rxjs/operators';


@Component({
	selector: 'shared-acc-entrepreneurs-view-part',
	templateUrl: './acc-entrepreneurs-view.part.html',
})
export class SharedAccEntrepreneursViewPart {

	public gridSetup = this.setupGrid();

	public rows$ = this.sharedDashboardsService.dashboardData$.pipe(
		map(data => data ? data.accelerator.accEntrepreneurPersonIds.map(accPersonId => ({ accPersonId })) : [])
	);


	constructor(
		public pageService: NationalDashboardPageService,
		private sharedDashboardsService: SharedDashboardsService,
	) {
	}


	private setupGrid(): GridSetup<{ accPersonId: number }> {

		return {
			experience: 'PERSON',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Entrepreneur",
			rowPluralName: "Entrepreneurs",
			rowKey: "accPersonId",
			stateKey: "shared-acc-entrepreneurs-view-part",
			canAdd: false,
			canRefresh: false,
			canDownload: false,
			initialState: {},
		};
	}

}