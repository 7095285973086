<div class='national-overview-view-part' *ngIf="viewDroplistItems.length">

	<ng-container *ngIf='selectedViewItem$ | async; let selectedViewItem'>

		<me-droplist class="views-droplist" [items]='viewDroplistItems' [selectedItem]='selectedViewItem'
			(selectionChange)='onViewDroplistChange($event)' [filterable]='false' [navigation]='true'
			sortBy='customSort' style='display:inline-block'
			[groupSortValues]='{"National":1, "Accelerator":2, "Pitch Contest":3, "Reporting":4}'>
		</me-droplist>


		<shared-dashboard-views-part [layout$]="pageService.layout$"></shared-dashboard-views-part>


		<!-- Reporting -->

		<national-reporting-looker-view-part *ngIf="showView('reporting-looker', selectedViewItem)">
		</national-reporting-looker-view-part>

		<national-reporting-charts-view-part *ngIf="showView('reporting-charts', selectedViewItem)">
		</national-reporting-charts-view-part>

	</ng-container>

</div>