import { BehaviorSubject } from "rxjs";

/**
 * Take a BehaviorSubject and only expose properties
 * that make the enclosed value readonly.
 */
export class ReadonlyBehaviorSubject<T> extends BehaviorSubject<T> {

	/**
	 * @deprecated The next() method should not be called on ReadonlyBehaviorSubject;
	 */
	override next(value: T): void {
		throw new Error(`ReadonlyBehaviorSubject.next() called!`);
	}

	/**
	 * Do not call this in regular code! ReadonlyBehaviorSubject extends BehaviorSubject
	 * and blocks access to the standard next() method. The few places that are supposed
	 * to set values can access super.next() through superNext(). Do not call this just
	 * anywhere, or you will circumvent the readonly-ness.
	 */
	get superNext() {
		return super.next;
	} 

}