import { Accelerator, AccReader, DbsAccReader } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { Observable } from "rxjs";
import { DomainDataManagers } from "../interfaces/domain-data-managers";
import { PackageManager } from "../package-manager";
import { SingletonsManager } from "../singletons-manager";
import { AcceleratorPackageManager } from "./accelerator";
import { PersonPackageManager } from "./person";

export class AccReaderPackageManager extends PackageManager<DbsAccReader, AccReader> {

	constructor(
		singletonsAsOfUTC$: Observable<number>,
		util: UtilityService,
		sm: SingletonsManager<DbsAccReader>,
		private domain: DomainDataManagers,
		private person: PersonPackageManager,
		private accelerator: AcceleratorPackageManager,
	) {
		super(singletonsAsOfUTC$, util, sm);
	}


	/**
	 * Convert an array of DbcAccReader to an array of AccReader
	 */
	protected async _createPackages(dbsAccReaders: DbsAccReader[]): Promise<AccReader[]> {

		//
		// Get all the people and companies
		//
		const personIds: number[] = [];
		const acceleratorIds: number[] = [];

		for (const accReader of dbsAccReaders) {
			personIds.push(accReader.personId);
			personIds.push(accReader.updatedByPersonId);
			acceleratorIds.push(accReader.accId);
		}

		const personMap = await this.person.getManyPackagesAsMap(personIds);
		const acceleratorMap = await this.accelerator.getManyPackagesAsMap(acceleratorIds);


		//
		// Package 'em up
		//
		const accReaders: AccReader[] = dbsAccReaders.map(dbsAccReader => {

			const accelerator = acceleratorMap[dbsAccReader.accId];
			const person = personMap[dbsAccReader.personId];
			const updatedByPerson = personMap[dbsAccReader.updatedByPersonId];


			return {
				...dbsAccReader,
				accelerator,
				person,
				updatedByPersonName: updatedByPerson?.name || `Person #${dbsAccReader.updatedByPersonId}`,
				appUrl: this.createAppUrl(accelerator),
			};
		});

		return accReaders;
	}


	private createAppUrl(acc: Accelerator): string {
		const siteId = acc.siteId;
		const accId = acc.accId;
		return `/access/admin/national/sites/${siteId}/accelerators/${accId}/pre-accelerator?tab=1&view=5`;
	}

	
	/**
	 * Get all acc readers for each personId provided
	 */
	public async getByPersonIds(personIds: readonly number[]): Promise<Record<number, ReadonlyArray<AccReader>>> {

		return await this.getPackagesAsArraysByForeignIds('personId', personIds);
	}


	/**
	 * Get all acc readers for each accId provided
	 */
	public async getByAccIds(accIds: readonly number[]): Promise<Record<number, ReadonlyArray<AccReader>>> {

		return await this.getPackagesAsArraysByForeignIds('accId', accIds);
	}
}