import { Answer, DbsCompany, DbsPerson, Question, } from '@me-interfaces';
import { Assessments, Ratings } from '../rating-results/SHR-CMN_rating-interfaces';



export interface AssessmentLevel {
	person?: DbsPerson,
	company?: DbsCompany,
	question?: Question,
	assessments: Assessments,
	ratings: Ratings,
	aScore: number, // assessments score
	rScore: number, // question ratings score
	score: number,
	tooltip: string,
	children?: AssessmentLevel[],
	answer?: Answer, // only used on the third level
}


export interface FlatAssessmentsData {
	personList: DbsPerson[],
	companyList: DbsCompany[],
	questionList: Question[],
	answers: { [key: string]: Answer },
	assessments: { [key: string]: Assessments }
}