import { Accelerator, DbConceptName, DbdTopic, Dbs, Event, Immutable, Person } from '@me-interfaces';


interface MutableDbsAccSessionSpecialistSurveyResponse extends Dbs {

	readonly _concept: DbConceptName.AccSessionSpecialistSurveyResponse,

	accSessionSpecialistSurveyResponseId: number,
	accSessionTopicSurveyResponseId: number,
	personId: number,
	professionalism: number,
	knowledge: number,
	effectiveness: number,
	other: string,
	updatedUTC: number,
	updatedByPersonId: number,


	//
	// Additional data added to dbs
	//
	accSessionAttendeeId: number,
	accId: number,
	eventId: number,
	topicId: number,
	createdUTC: number,
}

interface MutableAccSessionSpecialistSurveyResponse extends MutableDbsAccSessionSpecialistSurveyResponse {
	person: Person,
	topic: DbdTopic,
	accelerator: Accelerator,
	event: Event,
}


export interface DbsAccSessionSpecialistSurveyResponse extends Immutable<MutableDbsAccSessionSpecialistSurveyResponse> { }
export interface AccSessionSpecialistSurveyResponse extends Immutable<MutableAccSessionSpecialistSurveyResponse> { }