<me-dialog-frame [header]='headerText' [actions]='actions' [dialogRef]='dialogRef' [icon]='icon' alertType='editor'>

	<div class='mentor-config-dialog'>
		<table style='width:100%' class='pro-table pro-table-no-header pro-table-with-labels pro-table-without-border'>
			<tr>
				<td>Can Coach</td>
				<td>
					<kendo-dropdownlist [data]='canCoaches' textField="text" valueField="value" [value]='newCanCoach'
						[valuePrimitive]="true" (valueChange)='onCanCoachSelectionChange($event)'>
					</kendo-dropdownlist>
				</td>
			</tr>
			<tr>
				<td>Event</td>
				<td>

					<kendo-dropdownlist [data]='events' textField='numWithDate' valueField='eventId'
						[defaultItem]="defaultEvent" [value]='newEventId' [valuePrimitive]="true"
						(valueChange)='onSelectionChange($event)'>
					</kendo-dropdownlist>

				</td>
			</tr>
			<tr>
				<td>Use Count</td>
				<td>
					<kendo-dropdownlist [data]='useCounts' [value]='newUseCount' [valuePrimitive]="true"
						(valueChange)='onUseCountSelectionChange($event)'>
					</kendo-dropdownlist>
				</td>
			</tr>
		</table>
	</div>
</me-dialog-frame>