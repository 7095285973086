import { Component, Input } from '@angular/core';
import { ExplorerBox } from '../../../explorer-box/explorer-box';


@Component({
	selector: 'person-explorer-lang-and-bio-box',
	templateUrl: './lang-and-bio-box.part.html',
	styleUrls: ['./lang-and-bio-box.part.scss'],
})
export class PersonExplorerLangAndBioPart  {
	@Input() public box: ExplorerBox;
}
