import { DdPic, OldDomainDataService } from '@me-services/core/old-dd';
import { FuncService } from "@me-services/core/func";

/** @deprecated DO NOT USE THIS!*/
export class PicWrapper {

	constructor(private pic: DdPic, private func: FuncService, private dd: OldDomainDataService) {
		this.pic = pic;
	}


	public async setStage(picStageId: number): Promise<void> {
		const newStageId = await this.func.admin.site.pic.setStage({ picId: this.pic.picId, picStageId, siteCode: this.pic.siteProgram.site.code });
		await this.dd.fetchLatestData();
		this.pic.setPicStageId(newStageId);
	}
}