/**
 * @deprecated Use Person from DataService instead
 */
export interface Old_Person {
	personId: number,
	firstName: string,
	middleInit: string,	// will be either a single character or an empty string
	lastName: string,
	fullName: string,
	phone: {
		number?: string,
		canText: boolean,
	},
	hasAvatar: boolean, // TODO: Rename to avatarChangedUTC as number
	email: string,
}