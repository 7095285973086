<div class='acc-team-awards-part'>
	<ng-container *ngIf='awardsData$ | async; let awardsData'>
		<me-h2>Awards</me-h2>
		<table class='pro-table pro-table-without-border'>
			<tr>
				<th style='width: 120px;'>Event</th>
				<th style='width: 150px;'>Award</th>
				<th style='width: 80px;'>Value</th>
			</tr>

			<tr *ngFor='let award of awardsData'>
				<td>{{award.eventType}}</td>
				<td>{{award.awardName}}</td>
				<td>{{award.value}}</td>
			</tr>
		</table>
	</ng-container>
</div>