<field-chrome [basePart]='basePart' (fieldClick)='phoneField.focus()'>

	<div class='input-wrapper'>

		<input #phoneField kendoTextBox [value]='currentValue?.number' (focus)='setFocused(true)'
			(blur)='setFocused(false)' (input)='onInput()' [attr.maxlength]='field.maxLength'
			[disabled]='status === "saving"' [class.has-error]='!!errorText' style='padding-left:52px'
			[style.max-width]="maxWidth + 'em'" />

	</div>

</field-chrome>