export interface UserContext {
	
	//
	// Basic Info
	//
	userId: number,
	fullName: string,
	firstName: string,
	email: string,
	
	//
	// Access Flags
	// 
	isCrmUser: boolean,
	isEForAllAdmin: boolean,
	isTechAdmin: boolean,
	canEditDemographics: boolean,
}