import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { Company, DbcUser } from '@me-interfaces';
import { OldUserService } from '@me-services/old-services-and-wrappers/user';
import { UtilityService } from '@me-services/core/utility';
import { Icon, getIconContext } from '@me-shared-parts/UI-common';
import { IconBarIcon, getIconBarSelections } from '@me-shared-parts/UI-common/icon-bar';
import { IconBarPart, IconBarSelections } from '@me-shared-parts/UI-common/icon-bar/SHR-CMN_icon-bar.part';
import { DataService } from '../../../data.service';
import { ExplorerBox } from '../../explorer-box/explorer-box';
import { ExplorerNoteBox } from '../../explorer-note-box/explorer-note-box';
import { LinkOrExplorable } from '../../link-or-explorerable';
import { getBoxesForAccelerators } from './boxes/get-boxes-for-accelerators';
import { getBoxesForAwards } from './boxes/get-boxes-for-awards';
import { getBoxesForNotes } from './boxes/get-boxes-for-notes';
import { getBoxesForPeople } from './boxes/get-boxes-for-people';
import { getBoxesForPitchContests } from './boxes/get-boxes-for-pitch-contests';
import { getButtons } from './get-buttons';
import { VIEW_AWARDS, VIEW_DETAILS, VIEW_NOTES, VIEW_PEOPLE, VIEW_PROGRAMMING, views } from './views';

const STORAGE_KEY = 'CompanyExplorerIconSelection';

@Component({
	selector: 'company-explorer',
	templateUrl: './company-explorer.part.html',
	styleUrls: ['./company-explorer.part.scss'],
})
export class CompanyExplorerPart implements OnChanges {

	@Input() company: Company;
	@ViewChild('iconBar') iconBar: IconBarPart;

	public user: DbcUser;
	public views: IconBarIcon[] = undefined;

	public icons = {
		redFlag: getIconContext(Icon.notes_redFlag),
		company: getIconContext(Icon.concept_company),
		award: getIconContext(Icon.concept_award),
	};


	public selectedViews: IconBarSelections = undefined;
	public buttons: LinkOrExplorable[] = [];

	public peopleBoxes: ExplorerBox[] = [];
	public acceleratorBoxes: ExplorerBox[] = [];
	public pitchContestBoxes: ExplorerBox[] = [];
	public noteBoxes: ExplorerNoteBox[] = [];
	public awardBoxes: ExplorerBox[] = [];



	constructor(
		private util: UtilityService,
		private ds: DataService,
		private userService: OldUserService,
	) { }


	async ngOnChanges() {
		if (!this.company) throw new Error(`Missing required attribute: company`);
		this.user = await this.userService.getUser();
		this.icons.company.text = this.company.name;
		if (this.company.awardedValue > 0) this.icons.award.text = `Awarded: $${this.company.awardedValue.toLocaleString()}`;


		await this.createContent();
		this.views = this.updateIconBar();
	}


	/**
	 * CreateContent is called once when a new accelerator is passed in.  All content is created but
	 * may be shown or hidden using css instead of rebuilding everything again.
	 */
	private async createContent() {
		this.buttons = await getButtons(this.ds, this.company);
		this.peopleBoxes = await getBoxesForPeople(this.ds, this.company);
		this.acceleratorBoxes = await getBoxesForAccelerators(this.util, this.ds, this.company);
		this.pitchContestBoxes = await getBoxesForPitchContests(this.util, this.ds, this.company);
		this.noteBoxes = await getBoxesForNotes(this.util, this.ds, this.company);
		this.awardBoxes = await getBoxesForAwards(this.ds, this.company);
	}

	private updateIconBar(): IconBarIcon[] {

		//
		// Get selections from last time this explorer was accessed. If there
		// isn't on,then build the selections from the initial views in views.ts.
		//
		const selectedViewsStr = sessionStorage.getItem(STORAGE_KEY);
		let selectedViews: IconBarSelections;

		if (selectedViewsStr) {
			this.selectedViews = selectedViews = JSON.parse(selectedViewsStr);
		}
		else {
			this.selectedViews = selectedViews = getIconBarSelections(views);
			sessionStorage.setItem(STORAGE_KEY, JSON.stringify(this.selectedViews));
		}

		//
		// Create a new array of icon views using the initial set but overriding
		// whether disabled or not and the tooltip text based on the created content.
		//
		return views.map(view => {

			if (view.key == VIEW_DETAILS) {
				view.disabled = false; // always has a value
				view.selected = this.selectedViews.map[VIEW_DETAILS];
				view.tooltip = `Company Details`;
			}
			else if (view.key == VIEW_NOTES) {
				const count = this.noteBoxes.length;
				view.disabled = !count;
				view.selected = this.selectedViews.map[VIEW_NOTES];
				view.tooltip = this.util.text.singularOrPluralWithCount(count, 'Note', 'Notes');
			}
			else if (view.key == VIEW_PEOPLE) {
				const count = this.peopleBoxes.length;
				view.disabled = !count;
				view.selected = this.selectedViews.map[VIEW_PEOPLE];
				view.tooltip = this.util.text.singularOrPluralWithCount(count, 'Person', 'People');
			}
			else if (view.key == VIEW_PROGRAMMING) {
				const count = this.acceleratorBoxes.length + this.pitchContestBoxes.length;
				view.disabled = !count;
				view.selected = this.selectedViews.map[VIEW_PROGRAMMING];
				view.tooltip = this.util.text.singularOrPluralWithCount(count, 'Business Activity', 'Business Activities');
			}
			else if (view.key == VIEW_AWARDS) {
				const count = this.awardBoxes.length;
				view.disabled = !count;
				view.selected = this.selectedViews.map[VIEW_AWARDS];
				view.tooltip = this.util.text.singularOrPluralWithCount(count, 'Award', 'Awards');
			}

			return view;
		});


	}


	public async setViews(selectedViews: IconBarSelections) {
		if (!selectedViews) return;
		this.selectedViews = selectedViews;
		sessionStorage.setItem(STORAGE_KEY, JSON.stringify(selectedViews));

		for (const view of this.views) {
			view.selected = !!selectedViews.map[view.key];
		}
	}


	public getDisplay(key: string) {

		if (key == 'associations') {

			let display: 'none' | 'block' = 'none';

			for (const view of this.views) {
				if (view.key == 'people' && !view.disabled && view.selected == true) { display = 'block'; break; }
				if (view.key == 'programming' && !view.disabled && view.selected == true) { display = 'block'; break; }
				if (view.key == 'mentors' && !view.disabled && view.selected == true) { display = 'block'; break; }
				if (view.key == 'relationships' && !view.disabled && view.selected == true) { display = 'block'; break; }
			}

			return display;
		}

		else {
			const view = this.views.find(view => view.key == key);
			if (view == undefined) return 'none';
			return !!view.disabled || !view.selected ? 'none' : 'block';
		}
	}


	public click(action: 'red-flag' | 'awards') {
		if (this.iconBar) {
			if (action == 'red-flag') this.iconBar.selectOnlyOne(VIEW_NOTES);
			if (action == 'awards') this.iconBar.selectOnlyOne(VIEW_AWARDS);
		}
	}


	public tabClose(header: string) {

		if (header.endsWith('Associations')) {
			this.unselect(VIEW_PEOPLE);
			this.unselect(VIEW_PROGRAMMING);
		}

		if (header.endsWith('Details')) this.unselect(VIEW_DETAILS);
		if (header.endsWith('Notes')) this.unselect(VIEW_NOTES);
		if (header.endsWith('Awards')) this.unselect(VIEW_AWARDS);

	}


	private unselect(key: string) {

		this.iconBar.unselect(key);

		const view = this.views.find(view => view.key == key);
		if (view) view.selected = false;
	}

}