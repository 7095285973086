import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DestroyablePart } from '@me-access-parts';
import { LabelKey, AppSessionSearchDetails } from '@me-interfaces';
import { AuthService } from '@me-services/core/auth';
import { ConstantsService } from '@me-services/core/constants';
import { FuncService } from '@me-services/core/func';
import { AppSessionService } from '@me-services/core/app-session';
import { LabelsService } from '@me-services/ui/labels';
import { from, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { UtilityService } from '@me-services/core/utility';

@Component({
	selector: 'session-enter-search-details-page',
	templateUrl: './session-enter-search-details.page.html',
	styleUrls: ['./session-enter-search-details.page.scss']
})
export class SessionEnterSearchDetailsPage extends DestroyablePart implements OnInit, OnDestroy {

	form: FormGroup;
	zipName: string = undefined;
	doingNext = false;
	telInput = undefined;
	welcomeMessage: string | LabelKey = 'Welcome';

	inlineLabels = this.labels.trackInlineLabels(this, [
		'First Name',
		'Last Name',
		'Home: USA Zip Code',
		'Personal Phone Number',
		'Second Email Address',
		'Third Email Address',
	]);

	constructor(
		public auth: AuthService,
		private appSession: AppSessionService,
		private fb: FormBuilder,
		private util: UtilityService,
		private constants: ConstantsService,
		private func: FuncService,
		public labels: LabelsService
	) { super(); }

	ngOnInit() {
		super.initDestroyable();

		this.createForm();

		const input = document.querySelector("#phone");
		this.telInput = (<any>window).intlTelInput(input, {
			nationalMode: true,
			utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/11.1.0/js/utils.js"
		});

		this.createWelcomeMessage();
	}

	override ngOnDestroy() {
		this.telInput.destroy();
	}

	private async createWelcomeMessage() {
		const fb: any = await this.auth.me.firebaseUser$.pipe(take(1)).toPromise();

		this.welcomeMessage = {
			key: 'Welcome, {{name}}',
			fields: { name: fb.displayName },
		};
	}

	private createForm() {

		const phoneValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
			if (this.telInput === undefined) return null;  //will be undefined when ngOnInit calls createForm in ngOnInit
			if (control.value == '') return null;

			if (this.telInput.isValidNumber()) {
				return null;
			}
			else {
				return { invalid: true };
			}
		};

		this.form = this.fb.group({
			firstName: ['', [Validators.required, Validators.maxLength(20)]],
			lastName: ['', [Validators.required, Validators.maxLength(20)]],
			zipCode: ['', [Validators.required, Validators.pattern(/^\d{5}$/)], this.validateZip.bind(this)],
			phone: ['', [Validators.required, phoneValidator]],
			email2: ['', Validators.pattern(this.constants.EMAIL_REGEX)],
			email3: ['', Validators.pattern(this.constants.EMAIL_REGEX)],
		});
	}

	private validateZip(ctrl: AbstractControl): Observable<ValidationErrors | null> {
		this.zipName = undefined;

		return from(this.func.public.check.getZipData({ zipcode: ctrl.value }))
			.pipe(
				map(response => {
					this.zipName = response.valid ? response.name : undefined;
					return response.valid ? null : { badzip: true };
				})
			)
	}

	public fixCaseOfValue(ctrl: AbstractControl) {
		const value = this.util.text.fixCase(ctrl.value.trim());
		if (value !== ctrl.value) ctrl.patchValue(value);
	}

	public lowerCaseOfValue(ctrl: AbstractControl) {
		const value = ctrl.value.trim().toLowerCase();
		if (value !== ctrl.value) ctrl.patchValue(value);
	}

	public fixPhoneValue(ctrl: AbstractControl) {
		if (ctrl.errors) return;

		const value: string = this.telInput.getNumber();
		ctrl.patchValue(value);
	}

	public signout(): void {
		this.auth.me.signout();
	}

	public next(): void {
		if (this.form.invalid) return;

		this.doingNext = true;

		const details: AppSessionSearchDetails = {
			firstName: this.form.controls.firstName.value,
			lastName: this.form.controls.lastName.value,
			zip: this.form.controls.zipCode.value,
			phone: this.telInput.getNumber(),
			email2: this.form.controls.email2.value,
			email3: this.form.controls.email3.value,
		};

		this.appSession.findMatches(details);
	}
}