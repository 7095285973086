import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DestroyablePart } from '@me-access-parts';
import { GridSetup, RegionCols } from '@me-grid';
import { DbsRegion } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { GridPart } from '@me-shared-parts/UI-common';

interface RegionRow extends DbsRegion {
}


@Component({
	selector: 'regions-page',
	templateUrl: './regions.page.html',
})
export class RegionsPage extends DestroyablePart implements OnInit {

	public gridSetup = this.setupGrid();
	public rows: RegionRow[] = [];

	@ViewChild(GridPart) meGrid: GridPart<DbsRegion>;

	constructor(
		private route: ActivatedRoute,
		public router: Router,
		private util: UtilityService,
		public ds: DataService,
	) {
		super();
	}

	async ngOnInit() {

		super.initDestroyable();
		const regions = await this.ds.admin.region.getAllAsArray();

		this.rows = [];

		for (const region of regions) {

			const row: RegionRow = {
				...region,
			};

			this.rows.push(row);
		}

	}


	private setupGrid(): GridSetup<DbsRegion> {

		return {
			experience: 'REGION',
			size: {
				fitTo: 'PAGE',
				heightMultiplier: 1,
				shrinkBy: 0,
			},
			rowSingularName: "Region",
			rowPluralName: "Regions",
			rowKey: "regionId",
			stateKey: "regions-list",
			canAdd: false,
			canRefresh: false,
			canDownload: false,
			columnsToAlter: [
				{ field: RegionCols.directorEmail, hidden: false },
				{ field: RegionCols.siteCodes, hidden: false },
			],
		};
	}



	async gridActionHandler(action: { actionKey: string, rows: DbsRegion[] }) {
		// No local actions.
	}

}