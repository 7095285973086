<ng-container *ngIf="sharedViewsService.identifier$ | async; let identifier">

	<ng-container *ngIf="sharedViewsService.view$ | async; let view">

		<shared-indicators-view-part *ngIf="view==='indicators'" [layout$]="layout$"></shared-indicators-view-part>

		<shared-regions-view-part *ngIf="view==='regions'"></shared-regions-view-part>
		<shared-sites-view-part *ngIf="view==='sites'"></shared-sites-view-part>
		<shared-businesses-view-part *ngIf="view==='businesses'"></shared-businesses-view-part>
		<shared-entrepreneurs-view-part *ngIf="view==='entrepreneurs'"></shared-entrepreneurs-view-part>
		<shared-events-view-part *ngIf="view==='events'"></shared-events-view-part>
		<shared-awards-view-part *ngIf="view==='awards'"></shared-awards-view-part>

		<shared-accelerators-view-part *ngIf="view==='accs'"></shared-accelerators-view-part>
		<shared-acc-applications-view-part *ngIf="view==='acc-applications'"></shared-acc-applications-view-part>
		<shared-acc-teams-view-part *ngIf="view==='acc-teams'"></shared-acc-teams-view-part>
		<shared-acc-entrepreneurs *ngIf="view==='acc-entrepreneurs'"></shared-acc-entrepreneurs>
		<shared-acc-mentors-view-part *ngIf="view==='acc-mentors'"></shared-acc-mentors-view-part>
		<shared-acc-readers-view-part *ngIf="view==='acc-readers'"></shared-acc-readers-view-part>
		<shared-acc-interviewers-view-part *ngIf="view==='acc-interviewers'"></shared-acc-interviewers-view-part>
		<shared-acc-judges-view-part *ngIf="view==='acc-judges'"></shared-acc-judges-view-part>

		<shared-pitch-contests-view-part *ngIf="view==='pics'"></shared-pitch-contests-view-part>
		<shared-pic-applications-view-part *ngIf="view==='pic-applications'"></shared-pic-applications-view-part>
		<shared-pic-teams-view-part *ngIf="view==='pic-teams'"></shared-pic-teams-view-part>
		<shared-pic-entrepreneurs-view-part *ngIf="view==='pic-entrepreneurs'"></shared-pic-entrepreneurs-view-part>
		<shared-pic-readers-view-part *ngIf="view==='pic-readers'"></shared-pic-readers-view-part>
		<shared-pic-judges-view-part *ngIf="view==='pic-judges'"></shared-pic-judges-view-part>

	</ng-container>
</ng-container>