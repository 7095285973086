import { getApplicationStatusName } from "@ACC-area";
import { ApplicationStatusId, Company, DbConceptName } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { getIconForApplicationStatus } from "@me-shared-parts/UI-common";
import { DataService } from "../../../../data.service";
import { ExplorerBox, ExplorerBoxClass } from "../../../explorer-box/explorer-box";


export async function getBoxesForAccelerators(util: UtilityService, ds: DataService, company: Company): Promise<ExplorerBox[]> {

	if (company.accApplicationIds.length == 0) return [];

	const boxes: ExplorerBox[] = [];


	//
	// Clean the list of applicationIds, and sort them largest (newest) first.
	//
	const applicationIds = util.array.cleanNumericIds([...company.accApplicationIds]).reverse();

	//
	// Get the application packages
	//
	const applications = await ds.admin.application.getManyPackagesAsArray(applicationIds);


	for (const application of applications) {

		if (application.accelerator) {

			const applicationStatusIcon = getIconForApplicationStatus(application.applicationStatusId);
			const applicationStatus = getApplicationStatusName(application.applicationStatusId);
			const site = application.siteProgram.site;
			const acc = application.accelerator;


			const box: ExplorerBox = {

				explorerBoxClass: ExplorerBoxClass.Accelerator,
				header: 'Accelerator',
				buttons: [
					{ explorer: { explorableId: { conceptName: DbConceptName.Site, id: site.siteId }, navTooltip: `Site: ${site.name}` } },
					{ explorer: { nameOverride: `${site.code} ${acc.name}`, explorableId: { conceptName: DbConceptName.Accelerator, id: acc.accId }, navTooltip: `Accelerator: ${site.code} ${acc.name}` } },
					{ explorer: { explorableId: { conceptName: DbConceptName.Person, id: application.personId }, navTooltip: `Applicant: ${application.person._name}` } },
					{ explorer: { nameOverride: applicationStatus, explorableId: { conceptName: DbConceptName.Application, id: application.applicationId }, navIconOverride: applicationStatusIcon, navTooltip: `Application: ${applicationStatus}` } },
				],
				notes: '',
			};

			if (application.applicationStatusId == ApplicationStatusId.TeamPromoted) {

				const accTeamsByApplicationId = await ds.admin.accTeam.getByApplicationIds([application.applicationId]);
				const accTeams = accTeamsByApplicationId[application.applicationId];

				if (accTeams.length) { // should only be one (or zero)
					const accTeam = accTeams[0];
					const accTeamName = 'Cohort Team';
					box.buttons.push({ explorer: { nameOverride: accTeamName, explorableId: { conceptName: DbConceptName.AccTeam, id: accTeam.accTeamId }, navTooltip: `Team (entrepreneurs and mentors)` } });

					if (accTeam.droppedOutWeek) box.notes = `Team Dropped out week ${accTeam.droppedOutWeek}`;
				}
			}

			boxes.push(box);
		}

	}

	return boxes;

}