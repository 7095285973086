import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UtilityService } from '@me-services/core/utility';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';
import { LabelsService } from '@me-services/ui/labels';
import { cancelAction, saveAction } from './text-dialog-actions';

@Component({
	selector: 'text-dialog',
	templateUrl: './SHR-CMN_text.dialog.html',
	styleUrls: ['./SHR-CMN_text.dialog.scss']
})
export class TextDialog {

	orgValue = '';
	newValue = '';
	actions: DialogAction<string | void>[] = [saveAction, cancelAction];
	icon = 'fa-file-alt';
	headerText: string;
	editText = false;
	readonly = false;

	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ readonly: boolean, text: string, header: string }>,
		public dialogRef: MatDialogRef<TextDialog>,
		private labelsService: LabelsService,
		private util: UtilityService,
	) {
		saveAction.callback = this.saveText.bind(this);
		this.readonly = dialog.data?.readonly;
		this.headerText = dialog.data?.header || 'Edit Text';
		this.orgValue = this.newValue = (dialog.data?.text || '').trim();
		saveAction.enabled = this.checkIfCanSave();

		this.translateHeaderText();
	}

	async translateHeaderText() {
		this.headerText = await this.labelsService.get(this.headerText);

	}

	/**
	 * This function is called as the user is typing/pasting/deleting into a field
	 */
	onInput(e) {
		this.newValue = this.util.text.fixCase((e.target.value ?? '').trim(), 'sentence-case');
		saveAction.enabled = this.checkIfCanSave();
	}

	/**
	 * Look at each value and determine if the save/update button should be enabled
	 */
	checkIfCanSave(): boolean {
		return this.newValue.trim() !== this.orgValue;
	}

	async saveText(): Promise<string> {
		return this.newValue.trim();
	}
}