import { getApplicationStatusName } from "@ACC-area";
import { Application, DbConceptName } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { getIconForApplicationStatus } from "@me-shared-parts/UI-common";
import { ExplorerBox, ExplorerBoxClass } from "../../../explorer-box/explorer-box";


export async function getBoxesForRelatedApplications(util: UtilityService, ds: DataService, application: Application): Promise<{ boxes: ExplorerBox[], hasRelatedProgramAppl: boolean }> {

	const boxes: ExplorerBox[] = [];

	const relatedApplications: { [index: number]: Application } = {};

	const applicationsByCompanyId = await ds.admin.application.getByCompanyIds([application.companyId]);
	const companyApplications = applicationsByCompanyId[application.companyId].filter(a => a.applicationId !== application.applicationId);

	for (const app of companyApplications) {
		relatedApplications[app.applicationId] = app;
	}

	const participantIds = application.applicationParticipants.map(({ person }) => person.personId);

	const allApplications = await ds.admin.application.getAllPackagesAsMap();

	for (const appId in allApplications) {
		for (const participant of allApplications[appId].applicationParticipants) {
			if (participantIds.includes(participant.person.personId) && application.applicationId != +appId) relatedApplications[appId] = allApplications[appId];
		}
	}

	const relatedProgramsArray = [];

	for (const appId in relatedApplications) {
		const application = allApplications[appId];
		const applicationStatusIcon = getIconForApplicationStatus(application.applicationStatusId);
		const applicationStatus = getApplicationStatusName(application.applicationStatusId);
		relatedProgramsArray.push(allApplications[appId].siteProgram.programId);

		if (application.accId) {
			const box: ExplorerBox = {
				explorerBoxClass: ExplorerBoxClass.Application,
				header: `${application.siteProgram.site.code} Application`,
				buttons: [
					{ explorer: { explorableId: { conceptName: DbConceptName.Accelerator, id: application.accelerator.accId }, navTooltip: `${application.accelerator.name}` } },
					{ explorer: { explorableId: { conceptName: DbConceptName.Person, id: application.personId }, navTooltip: `Applicant: ${application.person._name}` } },
					{ explorer: { explorableId: { conceptName: DbConceptName.Company, id: application.companyId }, navTooltip: `Idea Name: ${application.companyName}` } },
					{ explorer: { nameOverride: applicationStatus, explorableId: { conceptName: DbConceptName.Application, id: application.applicationId }, navIconOverride: applicationStatusIcon, navTooltip: `Application: ${applicationStatus}` } },
				],
				notes: '',
			};

			boxes.push(box);
		}

		if (application.picId) {
			const box: ExplorerBox = {
				explorerBoxClass: ExplorerBoxClass.Application,
				header: `${application.siteProgram.site.code} Application`,
				buttons: [
					{ explorer: { explorableId: { conceptName: DbConceptName.PitchContest, id: application.pitchContest.picId }, navTooltip: `${application.pitchContest.name}` } },
					{ explorer: { explorableId: { conceptName: DbConceptName.Person, id: application.personId }, navTooltip: `Applicant: ${application.person._name}` } },
					{ explorer: { explorableId: { conceptName: DbConceptName.Company, id: application.companyId }, navTooltip: `Idea Name: ${application.companyName}` } },
					{ explorer: { nameOverride: applicationStatus, explorableId: { conceptName: DbConceptName.Application, id: application.applicationId }, navIconOverride: applicationStatusIcon, navTooltip: `Application: ${applicationStatus}` } },
				],
				notes: '',
			};

			boxes.push(box);
		}
	}

	const hasRelatedProgramAppl = !!relatedProgramsArray.find(programId => programId === application.siteProgram.programId);

	return { boxes, hasRelatedProgramAppl };
}