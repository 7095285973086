import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { AppSessionPersonMatch } from '@me-interfaces';

@Component({
	selector: 'session-person-part',
	templateUrl: './session-person.part.html',
	styleUrls: ['./session-person.part.scss'],
})
export class SessionPersonPart {

	@Input() person: AppSessionPersonMatch;
	@Output() sendCode = new EventEmitter<boolean>();

	sendingCode = false;

	yes() {
		this.sendingCode = true;
		this.sendCode.emit(true);
	}
	no() { this.sendCode.emit(false); }
}