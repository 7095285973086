import { Component, Input, OnChanges } from '@angular/core';
import { LanguageId } from '@me-interfaces';
import { LabelsService } from '@me-services/ui/labels';

@Component({
	selector: 'grid-cell-rating',
	templateUrl: './rating.part.html',
	styleUrls: ['rating.part.scss'],
})
export class RatingPart implements OnChanges {

	//
	// A number 1..4 representing N!, N, Y, or Y!
	//
	@Input() rating: 1 | 2 | 3 | 4;
	@Input() short = false;
	r: number | undefined = 0;


	constructor(public labels: LabelsService) { }


	ngOnChanges() {
		if (this.rating == undefined) {
			this.r = undefined;
		}
		else if (typeof this.rating === 'number') {
			this.r = this.rating;
		}
	}


	getClass() {
		const str = 'rating-part';
		if (this.r == undefined) return `${str} score-missing`;
		else if (this.r == 1) return `${str} score-1`;
		else if (this.r == 2) return `${str} score-2`;
		else if (this.r == 3) return `${str} score-3`;
		else if (this.r == 4) return `${str} score-4`;
	}


	showRating() {
		if (this.labels.languageId == LanguageId.Spanish) {
			if (this.r == undefined) return '?';
			else if (this.r == 1) return this.short ? 'N!' : 'NO!';
			else if (this.r == 2) return this.short ? 'N' : 'No';
			else if (this.r == 3) return this.short ? 'S' : 'Sí';
			else if (this.r == 4) return this.short ? 'S!' : '¡SÍ!';
		}
		else {
			if (this.r == undefined) return '?';
			else if (this.r == 1) return this.short ? 'N!' : 'NO!';
			else if (this.r == 2) return this.short ? 'N' : 'No';
			else if (this.r == 3) return this.short ? 'Y' : 'Yes';
			else if (this.r == 4) return this.short ? 'Y!' : 'YES!';
		}
	}
}
