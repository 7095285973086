import { Accelerator, DbConceptName, DbdTopic, Dbs, Event, Immutable } from '@me-interfaces';


interface MutableDbsAccSessionTopicSurveyResponse extends Dbs {

	readonly _concept: DbConceptName.AccSessionTopicSurveyResponse,

	accSessionTopicSurveyResponseId: number,
	accSessionSurveyResponseId: number,
	topicId: number,
	relevance: number,
	understanding: number,
	need: number,
	duration: number,
	other: string,
	updatedUTC: number,
	updatedByPersonId: number,

	//
	// Additional data added to dbs
	//
	accSessionAttendeeId: number,
	accId: number,
	eventId: number,
	createdUTC: number,
}

interface MutableAccSessionTopicSurveyResponse extends MutableDbsAccSessionTopicSurveyResponse {
	topic: DbdTopic,
	accelerator: Accelerator,
	event: Event,
}

export interface DbsAccSessionTopicSurveyResponse extends Immutable<MutableDbsAccSessionTopicSurveyResponse> { }
export interface AccSessionTopicSurveyResponse extends Immutable<MutableAccSessionTopicSurveyResponse> { }