import { Icon } from "../icon";


/**
 * An icon shown to the user in the bar
 */
export interface IconBarIcon {

	/** A unique identifier for this icon */
	key: string,

	/** An enumerated FontAwesome icon */
	icon: Icon,

	/** They text that describes the icon */
	tooltip: string,

	/** Whether the icon is initially selected */
	selected: boolean,

	/** Disable the button */
	disabled?: boolean,

}