import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from '@me-services/core/data';
import { Explorable } from '@me-services/core/data/explorable';
import { DialogService } from '@me-services/ui/dialog';
import { DroplistItem } from '@me-shared-parts/UI-common';
import { getIconContextForPackage } from '@me-shared-parts/UI-common/icon/get-icon-context-for-package';
import { mergeMap, Observable } from 'rxjs';
import { ExplorableIdentifier } from '../../service/explorable-identifier';


@Component({
	selector: 'explorer-header',
	templateUrl: './explorer-header.part.html',
	styleUrls: ['./explorer-header.part.scss'],
})
export class ExplorerHeaderPart {

	public recents$: Observable<DroplistItem<ExplorableIdentifier, string>[]> = this.ds
		.explorer
		.navigator
		.recents$
		.pipe(
			mergeMap(async recents => {

				let packages = await this.ds.admin.getMany(recents);

				//
				// Filter out any that weren't found. This could happen in
				// dev or local host when moving across environments.
				//
				packages = packages.filter(pkg => !!pkg);

				const packagesByUniqueId = this.ds.util.array.toMap(packages, (pkg: Explorable) => `${pkg._concept}-${pkg.id}`);

				return recents
					.map(recent => {
						const uniqueId = `${recent.conceptName}-${recent.id}`;
						const pkg: Explorable = packagesByUniqueId[uniqueId];
						return { uniqueId, pkg, recent };
					})
					.filter(data => !!data.pkg)		// Filter out any that were filtered up above
					.map(({ uniqueId, pkg, recent }) => {

						return {
							uniqueId,
							iconContext: getIconContextForPackage(pkg, undefined, undefined, pkg.explorerName),
							data: recent,
						};
					});
			})
		);

	constructor(
		public ds: DataService,
		private dialogService: DialogService,
		private router: Router,
	) {
	}


	recentSelection(selection: DroplistItem) {
		this.ds.explorer.navigator.go(selection.data);
	}


	async search() {
		const explorable = await this.ds.searcher.select();
		if (explorable) await this.ds.explorer.explore(explorable.conceptName, explorable.id);
	}


	async open() {
		const recents = this.ds.explorer.navigator.recents$.value;
		if (recents.length == 0) return;

		const selected = recents[0];
		const pkg = await this.ds.admin.get(selected);

		const appUrl = pkg.appUrl;

		if (!appUrl) await this.dialogService.showMessage(`Cannot navigate to a ${selected.conceptName} because there isn't an associated page.`);
		else {
			this.router.navigate([appUrl]);
			this.dialogService.closeAll();
		}
	}


	getTitle(title: string, showIt: boolean) {
		if (showIt) return title;
		else return null;
	}
}