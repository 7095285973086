import { AccAreaService } from '@ACC-area';
import { Component } from '@angular/core';
import { AccTeam, AccTeamGoalsAndAccess } from '@me-interfaces';
import { LayoutService } from '@me-services/ui/layout';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';


@Component({
	selector: 'acc-cohort-goals-and-progress-view-part',
	templateUrl: './acc-cohort-goals-and-progress-view.part.html',
})
export class AccCohortGoalsAndProgressViewPart {

	teamGoals$ = combineLatest([
		this.accAreaService.teams.teams$,
		this.accAreaService.teams.goals$,
	]).pipe(map(data => this.buildTeamGoals(data[0], data[1])));

	constructor(
		private accAreaService: AccAreaService,
		public layoutService: LayoutService,
	) {
		layoutService.dimensions$.subscribe(d => d.contentHeight);
	}


	buildTeamGoals(
		accTeams: readonly AccTeam[],
		teamGoals: readonly AccTeamGoalsAndAccess[],
	): AccTeamGoalsAndAccess[] {

		if (!accTeams || !teamGoals) return [];

		const activeTeamsIds = accTeams.filter(team => !team.droppedOutWeek).map(team => team.accTeamId);
		const activeTeamGoals = teamGoals.filter(tg => activeTeamsIds.includes(tg.team.accTeamId));

		return activeTeamGoals;
	}


	async onAddGoal(parameters: { accTeamId: number; quarter: number; summary: string; details: string; }) {
		await this.accAreaService.teams.actions.addGoal(parameters);
	}

	async onEditGoal(parameters: { accTeamGoalId: number; summary: string; details: string; progress: string; }) {
		await this.accAreaService.teams.actions.setGoal(parameters);
	}


	async onAddComment(parameters: { accTeamGoalId: number, comment: string }) {
		await this.accAreaService.teams.actions.addGoalComment(parameters);
	}

}