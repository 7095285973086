import { Component, Input, OnInit } from '@angular/core';
import { DbsPerson, DbcUser, PersonProfileAccessFlagsExtractForSite } from '@me-interfaces';
import { OldUserService } from '@me-services/old-services-and-wrappers/user';
import { DdTag, DdTagPrefix, OldDomainDataService } from '@me-services/core/old-dd';
import { MainSelector } from '@me-shared-parts/UI-common';

const OVERVIEW_TAB = 'OVERVIEW';
const KEY_PREFIX = 'selectable-view-prefix-name-';

export interface SelectorBody {
	key: string,
	prefix: string,
	locked: boolean,
	component: 'simple-tag-editor' | 'site-tag-editor',
}

@Component({
	selector: 'person-tag-editor',
	templateUrl: './SHR-CMN_person-tag-editor.part.html',
	styleUrls: ['SHR-CMN_person-tag-editor.part.scss'],
})
export class PersonTagEditorPart implements OnInit {

	@Input() person: DbsPerson;
	@Input() initialSelectorKey?: string;

	selectors: MainSelector[] = [];
	bodies: SelectorBody[] = [];
	personTags: DdTag[] = [];
	key: string;
	user: DbcUser;
	userPermissions: { [index: string]: boolean } = {};

	constructor(
		private dd: OldDomainDataService,
		private userService: OldUserService,
	) { }

	async ngOnInit() {
		this.user = await this.userService.getUser();
		
		if (this.initialSelectorKey) {
			this.key = this.initialSelectorKey;
		}
		this.buildSelectors(this.user, this.person, this.selectors);
		this.bodies = this.buildBodies(this.user, this.dd.tagPrefixes.objects);
	}

	createKey(tagPrefix: DdTagPrefix) {
		return `${KEY_PREFIX}${tagPrefix.prefix}`;
	}

	async buildSelectors(user: DbcUser, person: DbsPerson, priorMenu: MainSelector[]) {
		const statePriorMenu: MainSelector[] = [];
		const nonStatePriorMenu: MainSelector[] = [];
		this.personTags = this.dd.tags.getByIds(person._personExtracts.tagIds);
		for (const tagPrefix of this.dd.tagPrefixes.objects) {
			const count = this.personTags.filter(tag => tag.prefix == tagPrefix.prefix).length;

			let state: string = undefined;
			if (tagPrefix.site) {
				const zip: any = await tagPrefix.site.zip;
				if (zip?.state) state = zip.state;
			}

			if (state) {
				// Check if item exist in the priorMenu for rebuildSelectors
				let menuItem = priorMenu.find(item => item.label == state);
				
				// Add state tages to statePriorMenu for states to be sorted indenpendently
				if(menuItem === undefined) menuItem = statePriorMenu.find(item => item.label == state);
				const childKey = this.createKey(tagPrefix);

				if (menuItem) {
					const childItem = menuItem.children.find(c => c.key == childKey);

					if (childItem) {
						childItem.count = count;
					}
					else {
						menuItem.children.push({
							label: tagPrefix.prefix.toUpperCase(),
							key: childKey,
							count,
							locked: this.isPrefixLocked(user, tagPrefix),
						});
						menuItem.children.sort((a, b) => a.label > b.label ? 1 : -1);
					}
				}
				else {
					statePriorMenu.push({
						label: state,
						key: `${state} options`,
						locked: this.isPrefixLocked(user, tagPrefix),
						children: [
							{
								label: tagPrefix.prefix.toUpperCase(),
								key: childKey,
								count,
								locked: this.isPrefixLocked(user, tagPrefix),
							}
						]
					});
				}
			}
			else {
				const key = this.createKey(tagPrefix);
				// Check if item exist in the priorMenu for rebuildSelectors
				let menuItem = priorMenu.find(i => i.key == key);

				// Add nonstate tages to nonStatePriorMenu to be sorted indenpendently
				if(menuItem === undefined) menuItem = nonStatePriorMenu.find(item => item.label == state);
				if (menuItem) {
					menuItem.count = count;
				}
				else {
					nonStatePriorMenu.unshift({ label: tagPrefix.prefix.toUpperCase(), key, count, locked: this.isPrefixLocked(user, tagPrefix), });
				}
			}
		}

		// Sort only for first time buildSelector
		if(statePriorMenu.length > 0) {
			statePriorMenu.sort((a, b) => a.label > b.label ? 1 : -1);
			nonStatePriorMenu.sort((a, b) => a.label > b.label ? 1 : -1);
			priorMenu.push(...nonStatePriorMenu, ...statePriorMenu);
		}

		if (priorMenu.length && priorMenu[0].label == OVERVIEW_TAB) {
			priorMenu[0].count = this.personTags.length;
		}
		else {
			priorMenu.unshift({
				label: OVERVIEW_TAB,
				key: 'selectable-view-overview',
				count: this.personTags.length,
				locked: false,
			});
		}
	}

	isPrefixLocked(user: DbcUser, tagPrefix: DdTagPrefix) {
		//
		// Language can be edited by anyone with CRM access
		//
		if (tagPrefix.prefix == 'lang') return !user.isCrmUser;


		//
		// HQ & EFORALL can be edited by eforall admins or eforall execs
		//
		if (tagPrefix.prefix == 'hq' || tagPrefix.prefix == 'eforall') {
			if (user.isEForAllAdmin) return false;
			return !this.dd.tags.getByIds(user._personExtracts.tagIds).find(t => t.prefix == 'hq' && t.name == 'executive');
		}

		const bySiteCode = user._profileExtracts.accessFlags.bySiteCode;

		//
		// Specialties can be edited by anyone that is site staff (or better)
		//
		if (tagPrefix.prefix == 'specialty') {
			if (user.isEForAllAdmin) return false;
			return !Object.keys(bySiteCode).find(siteCode => {
				const siteFlags = bySiteCode[siteCode] || <PersonProfileAccessFlagsExtractForSite>{};
				return siteFlags.isSiteStaffOrBetter;
			});
		}

		//
		// Site tags can be edited by that site's staff, or better
		//
		if (tagPrefix.site) {
			const siteFlags = bySiteCode[tagPrefix.prefix.toUpperCase()];
			if (siteFlags && siteFlags.isSiteStaffOrBetter) return false;
		}

		return true;
	}

	buildBodies(user: DbcUser, tagPrefixes: DdTagPrefix[]): SelectorBody[] {
		return tagPrefixes.reduce((bodies: SelectorBody[], tagPrefix) => {

			if (tagPrefix.site) {
				bodies.push({
					key: this.createKey(tagPrefix),
					prefix: tagPrefix.prefix,
					locked: this.isPrefixLocked(user, tagPrefix),
					component: 'site-tag-editor',
				});
			}
			else {
				bodies.push({
					key: this.createKey(tagPrefix),
					prefix: tagPrefix.prefix,
					locked: this.isPrefixLocked(user, tagPrefix),
					component: 'simple-tag-editor',
				});
			}
			return bodies;
		}, []);
	}

	getTagsFromPrefix(prefix: string) {
		return this.dd.tags.getByPrefix(prefix);
	}

	setSelectedKey(key: string) {
		this.key = key;
	}

	selectionChange(key: string) {
		this.initialSelectorKey = key;
	}

	async tagsChanged(person: DbsPerson) {
		this.buildSelectors(this.user, person, this.selectors);
	}
}
