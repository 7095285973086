import { environment } from '@me-environment';
import { LanguageId } from '@me-interfaces';

export type BASE_URL
	= 'https://dev.eforall.app'
	| 'https://dev.eparatodos.app'
	| 'https://eforall.app'
	| 'https://eparatodos.app'
	| 'http://localhost:4200'
	| 'http://eparatodos.localhost:4200';


interface BaseUrl {
	env: 'LOCALHOST' | 'ME-PROD' | 'ME-DEV',
	lang: LanguageId,
	url: BASE_URL,
}

const BASE_URLS: BaseUrl[] = [
	{ env: 'ME-DEV', lang: LanguageId.English, url: 'https://dev.eforall.app' },
	{ env: 'ME-DEV', lang: LanguageId.Spanish, url: 'https://dev.eparatodos.app' },
	{ env: 'ME-PROD', lang: LanguageId.English, url: 'https://eforall.app' },
	{ env: 'ME-PROD', lang: LanguageId.Spanish, url: 'https://eparatodos.app' },
	{ env: 'LOCALHOST', lang: LanguageId.English, url: 'http://localhost:4200' },
	{ env: 'LOCALHOST', lang: LanguageId.Spanish, url: 'http://eparatodos.localhost:4200' },
];

/**
 * Get the base part of the app url based on the languageId and environment
 */
export function getBaseUrl(languageId: LanguageId) {

	const baseUrl = BASE_URLS.find(url => url.env == environment.name && url.lang == languageId);

	if (!baseUrl) throw new Error(`UrlService: No base url matches env ${environment.name}, and language ${languageId}.`);
	return baseUrl.url;
}