import { DbConceptName, Dbd, Immutable } from '@me-interfaces';


interface MutableDbdCity extends Dbd {

	readonly _concept: DbConceptName.City,

	readonly cityId: number,
	readonly name: string,
	readonly state: string,
}


export interface DbdCity extends Immutable<MutableDbdCity> { }