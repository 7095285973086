import {
	AccAssociationExtract,
	DbcCompanyForMenu,
	DbcPersonForMenu,
	DbsPosition,
	RelationshipType
} from '@me-interfaces';

export interface PersonPositionExtract {
	position: DbsPosition,
	company: DbcCompanyForMenu,
}

export interface PersonRelationshipExtract {
	readonly person: DbcPersonForMenu,
	readonly type?: RelationshipType,
	readonly notes?: string,
}

export interface PersonVolunteeringCountsExtract {
	reader: number,
	interviewer: number,
	mentor: number, // including coach count
	coach: number,
	specialist: number,
	judge: number,
	leadership: number,
}

export interface PersonBadgesExtract {
	acc?: 'Partial' | 'Bad' | 'Good' | undefined,
	mentor?: 'Partial' | 'Bad' | 'Good' | undefined,
}

export const enum AssociationType {
	AccApplicant = 'Application Acc',
	AccCohort = 'Accelerator Cohort',
	AccReader = 'Read Acc Application',
	AccInterviewer = 'Interviewed Acc Application',
	AccMentor = 'Mentor',
	AccJudge = 'Judge Acc Final Pitch',
	PicApplicant = 'Application Pic',
	PicParticipant = 'Pitch Contest Participant',
	PicReader = 'Read Pic Application',
	PicJudge = 'Judge Pitch Contest',
}

export interface PersonAssociationExtract {
	associationType: AssociationType,
	role: 'Reader' | 'Interviewer' | 'Mentor' | 'Specialist' | 'Judge' | 'Applicant' | 'In the program',
	siteId: number,
	siteCode: string,
	programInstanceId: number, //e.g. accId or picId
	dateUTC?: number,
	endDateUTC?: number,
	applicationId?: number,
	companyId?: number,
	companyName?: string,
	programTeamId?: number, //e.g. accTeamId or picTeamId
	applicationStatusId?: number,
	droppedOut?: boolean,
	isCoach?: boolean,
	picPresentType?: string,
	url: string,
}

export interface PersonExtracts {
	readonly accs: AccAssociationExtract[],
	readonly badges: PersonBadgesExtract,
	readonly lastLoginUTC: number | undefined,
	readonly positions: PersonPositionExtract[],
	readonly relationships: PersonRelationshipExtract[],
	readonly tagIds: number[],
	readonly associations: PersonAssociationExtract[]
}