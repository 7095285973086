import { Component, Input, OnChanges } from '@angular/core';
import { DbsPerson, PublicPersonForDialog } from '@me-interfaces';


@Component({
	selector: 'person-table',
	templateUrl: './SHR-PN_person-table.part.html',
	styleUrls: ['./SHR-PN_person-table.part.scss'],
})
export class PersonTablePart implements OnChanges {
	@Input() person: DbsPerson;
	@Input() publicFacingPerson: PublicPersonForDialog;

	email: string;
	phone: { phone: string, phoneTypeId?: number };

	ngOnChanges() {
		if (!this.person && !this.publicFacingPerson) throw new Error('Missing required attribute: person or publicFacingPerson');

		if (this.publicFacingPerson) {
			this.email = this.publicFacingPerson.email;
			this.phone = { phone: this.publicFacingPerson.phone, phoneTypeId: this.publicFacingPerson.phoneTypeId };
		}
		else if (this.person) {
			this.email = this.person._email;
			this.phone = { phone: this.person.phone, phoneTypeId: this.person.phoneTypeId };
		}

	}

	emailPerson(email: string) {
		window.open(`mailto:${email}`);
	}

}