import { AccApplicationTransferDialog, AccAreaService } from "@ACC-area";
import { Router } from "@angular/router";
import { ApplicationStatusId } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { DialogAction } from "@me-services/ui/dialog";


export async function accTransferApplication(router: Router, readonly: boolean, ds: DataService, accAreaService: AccAreaService, applicationId: number, applicationStatusId: ApplicationStatusId) {

	const isTransferrableStatus = [ApplicationStatusId.AcceptPending, ApplicationStatusId.ReadPending, ApplicationStatusId.InterviewPending, ApplicationStatusId.SelectPending].includes(applicationStatusId);
	const canTransfer = !readonly && isTransferrableStatus;

	if (!canTransfer) {
		await ds.dialogService.showMessage(`The application cannot be transfered as it is not in a transferable status.`);
	}
	else {

		const message = `When an application is transferred, it is 'moved' from one accelerator to another.
		
It will be like the applicant applied for the other accelerator initially.	All history of the application ever existing with the original accelerator will be lost.

Alternatively, you can defer the application so that it can be copied forward to a future accelerator of the same site.`;

		const yes = await ds.dialogService.confirm(message, 475, 325, 'I Understand', 'Cancel');

		if (yes) {
			const dialogAction: DialogAction<number | undefined> = await ds.dialogService.showCustom(
				AccApplicationTransferDialog,
				{
					data: {
						readonly,
						applicationStatusId: applicationStatusId,
						applicationId: applicationId,
					},
				},
				400, 300);

			const id = dialogAction?.id;
			if (id == 'transfer') {
				const accId = dialogAction.callbackResult;
				if (accId) {
					await accAreaService.applications.actions.transferApplication({ applicationId: applicationId, accId });
					const accelerator = await ds.admin.accelerator.getOnePackage(accId);
					router.navigate([`access/admin/national/sites/${accelerator.siteProgram.site.siteId}/accelerators/${accId}/pre-accelerator/${applicationId}`]);
				}
			}
		}
	}
}