import { DbsPicJudge, PicJudge, PitchContest } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { Observable } from "rxjs";
import { DomainDataManagers } from "../interfaces/domain-data-managers";
import { PackageManager } from "../package-manager";
import { SingletonsManager } from "../singletons-manager";
import { PersonPackageManager } from "./person";
import { PitchContestPackageManager } from "./pitch-contest";

export class PicJudgePackageManager extends PackageManager<DbsPicJudge, PicJudge> {

	constructor(
		singletonsAsOfUTC$: Observable<number>,
		util: UtilityService,
		sm: SingletonsManager<DbsPicJudge>,
		private domain: DomainDataManagers,
		private person: PersonPackageManager,
		private pitchContest: PitchContestPackageManager,
	) {
		super(singletonsAsOfUTC$, util, sm);
	}


	protected async _createPackages(dbsPicJudges: DbsPicJudge[]): Promise<PicJudge[]> {

		//
		// Get all the people and companies
		//
		const personIds: number[] = [];
		const pitchContestIds: number[] = [];

		for (const picJudge of dbsPicJudges) {
			personIds.push(picJudge.personId);
			personIds.push(picJudge.updatedByPersonId);
			pitchContestIds.push(picJudge.picId);
		}

		const personMap = await this.person.getManyPackagesAsMap(personIds);
		const pitchContestMap = await this.pitchContest.getManyPackagesAsMap(pitchContestIds);


		//
		// Package 'em up
		//
		const picJudges: PicJudge[] = dbsPicJudges.map(dbsPicJudge => {

			const pitchContest = pitchContestMap[dbsPicJudge.picId];
			const person = personMap[dbsPicJudge.personId];
			const updatedByPerson = personMap[dbsPicJudge.updatedByPersonId];


			return {
				...dbsPicJudge,
				pitchContest,
				person,
				updatedByPersonName: updatedByPerson?.name || `Person #${dbsPicJudge.updatedByPersonId}`,
				appUrl: this.createAppUrl(pitchContest),
			};
		});

		return picJudges;
	}

	private createAppUrl(pic: PitchContest): string {
		const picId = pic.picId;
		const siteCode = pic.siteProgram.site.code;
		return `/access/admin/communities/${siteCode.toLowerCase()}/programs/pitch-contests/${picId}/events/contest/event`;
	}


	/**
	 * Get all pic judges for each personId provided
	 */
	public async getByPersonIds(personIds: readonly number[]): Promise<Record<number, ReadonlyArray<PicJudge>>> {

		return await this.getPackagesAsArraysByForeignIds('personId', personIds);
	}


	/**
	 * Get all pic judges for each picId provided
	 */
	public async getByPicIds(picIds: readonly number[]): Promise<Record<number, ReadonlyArray<PicJudge>>> {

		return await this.getPackagesAsArraysByForeignIds('picId', picIds);
	}

}