import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@me-environment';
import { ServicesCoreModule } from '@me-services/core/SC.module';
import { SharedCompanyModule } from '@me-shared-parts/CM-company/CM.module';
import { SharedEditorsModule } from '@me-shared-parts/ED-editors/ED.module';
import { SharedProgramModule } from '@me-shared-parts/PG-program/PG.module';
import { SharedPersonModule } from '@me-shared-parts/PN-person/PN.module';
import { SharedCommonModule } from '@me-shared-parts/UI-common/UI.module';
import { NotificationModule } from '@progress/kendo-angular-notification';
import { createErrorHandler, TraceService } from "@sentry/angular-ivy";
import { MarkdownModule } from 'ngx-markdown';
import { accessDeclarations } from './access';
import { routes } from './app.routes';
import { BootstrapPage } from './bootstrap.page';


@NgModule({
	providers: [
		{
			provide: ErrorHandler,
			useValue: createErrorHandler({
				logErrors: true,
				showDialog: false,
			}),
		},
		{
			provide: TraceService,
			deps: [Router],
		},
		{
			provide: APP_INITIALIZER,
			useFactory: () => () => { /* */ },
			deps: [TraceService],
			multi: true,
		},
	],
	declarations: [
		...accessDeclarations,
		BootstrapPage,
	],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		NotificationModule,
		RouterModule.forRoot(routes),
		// RouterModule.forRoot(routes, { enableTracing: true }),
		MarkdownModule.forRoot(),
		SharedCommonModule,
		ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.name == 'ME-PROD' || environment.name == 'ME-DEV' }),

		CommonModule,
		MatCheckboxModule,
		MatInputModule,
		FormsModule,
		ReactiveFormsModule,
		OverlayModule,
		RouterModule.forChild(routes),
		ServicesCoreModule,
		SharedCompanyModule,
		SharedEditorsModule,
		SharedProgramModule,
		SharedPersonModule,
	],
	bootstrap: [BootstrapPage]
})
export class AppModule { }