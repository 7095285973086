import { ProgramPageService } from '@ADMIN-area';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { DBLCLICK_GRID_ACTION_KEY, GridAction, GridSetup, SELECTION_GRID_ACTION_KEY } from '@me-grid';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { GridPart, Icon } from '@me-shared-parts/UI-common';
import { GridColumnConfig, buildColumn } from '@me-shared-parts/UI-common/grid/columns';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { ProgramAgreementTypeDialog } from './dialog/agreement-type.dialog';


interface AgreementTypeRow {
	agreementTypeId: number,
	nameEN: string,
	nameES: string,
	versions: string, // e.g. "1,2"
	uniqueSignersCount: number,
	signaturesCount: number,
	createdUTC: number,
	updatedUTC: number,
}

@Component({
	selector: 'program-agreement-types-view-part',
	templateUrl: './agreement-types-view.part.html',
	styleUrls: ['./agreement-types-view.part.scss'],
})
export class ProgramAgreementTypesViewPart extends DestroyablePart implements OnInit {

	public gridSetup = this.setupGrid();

	public rows$: Observable<AgreementTypeRow[]> = of([]);

	@ViewChild(GridPart) meGrid: GridPart<AgreementTypeRow>;

	constructor(
		private util: UtilityService,
		public ds: DataService,
		public pageService: ProgramPageService
	) {
		super();
	}

	ngOnInit(): void {

		super.initDestroyable();

		this.rows$ = this.ds.admin.singletonsAsOfUTC$.pipe(mergeMap(data => this.buildRows(data)));
	}


	async buildRows(
		singletonsAsOfUTC: number
	): Promise<AgreementTypeRow[]> {

		const agreementTypes = await this.ds.admin.agreementType.getAllAsArray();
		const agreementSignatures = await this.ds.admin.agreementSignature.getAllAsArray();
		const agreementVersions = await this.ds.admin.agreementVersion.getAllAsArray();


		const rows: AgreementTypeRow[] = agreementTypes.map(a => {

			const versions = agreementVersions.filter(version => version.agreementTypeId == a.agreementTypeId);
			const versionIds = versions.map(v => v.agreementVersionId);
			const signatures = agreementSignatures.filter(signatures => versionIds.includes(signatures.agreementVersionId));
			const uniqueSigners = this.util.array.cleanNumericIds(signatures.map(signature => signature.personId));
			return {
				agreementTypeId: a.agreementTypeId,
				createdUTC: a.createdUTC,
				nameEN: a.nameEN,
				nameES: a.nameES,
				updatedUTC: a.updatedUTC,
				versions: versions.map(v => v.version).sort((a, b) => b - a).join(', '),
				signaturesCount: signatures.length,
				uniqueSignersCount: uniqueSigners.length
			}
		});


		return rows;
	}



	private setupGrid(): GridSetup<AgreementTypeRow> {

		return {
			experience: 'none',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Agreement",
			rowPluralName: "Agreements",
			rowKey: "agreementTypeId",
			stateKey: "program-agreement-types",
			canAdd: false,
			canRefresh: false,
			canDownload: false,
			columnsToAdd: [
				buildColumn('agreementTypeId', GridColumnConfig.id, { hidden: true, header: 'agreementTypeId', width: 90 }),
				buildColumn('nameEN', GridColumnConfig.text, { header: 'Name (EN)', width: 180 }),
				buildColumn('nameES', GridColumnConfig.text, { header: 'Name (ES)', width: 180 }),
				buildColumn('versions', GridColumnConfig.text, { header: 'Versions', width: 60 }),
				buildColumn('uniqueSignersCount', GridColumnConfig.number, { header: 'Signers', headerTooltip: 'Unique people that signed any version', width: 70 }),
				buildColumn('signaturesCount', GridColumnConfig.number, { header: 'Signatures', headerTooltip: 'Total count of signatures across versions', hidden: true, width: 70 }),
				buildColumn('createdUTC', GridColumnConfig.createdUtc, { header: 'Created', hidden: true }),
				buildColumn('updatedUTC', GridColumnConfig.updatedUtc, { header: 'Updated', hidden: true }),
			],
			actions: [
				{ key: 'view-agreement-type', icon: Icon.action_open, label: 'Read Agreement Text', enabled: false },
			],
			actionEnabler: this.gridActionEnabler.bind(this),

		};
	}


	gridActionEnabler(action: GridAction, rows: AgreementTypeRow[]) {
		if (rows.length == 0) return false;
		return true;
	}


	async gridActionHandler(action: { actionKey: string, rows: AgreementTypeRow[] }) {
		const row = action.rows[0];


		if (action.actionKey == SELECTION_GRID_ACTION_KEY) {
			// Nothing to do with a simple row selection
		}

		if (action.actionKey == 'view-agreement-type' || action.actionKey == DBLCLICK_GRID_ACTION_KEY) {

			await this.ds.dialogService.showCustom(
				ProgramAgreementTypeDialog,
				{
					data: {
						agreementTypeId: row.agreementTypeId,
					},
				},
				1200, 1200
			);


		}
	}

}