import { NationalDashboardPageService } from '@ADMIN-area';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DestroyablePart } from '@me-access-parts';
import { GridSetup } from '@me-grid';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { GridPart } from '@me-shared-parts/UI-common';


interface RegionRow {
	regionId: number,
	updatedUTC: number,
}


@Component({
	selector: 'shared-regions-view-part',
	templateUrl: './regions-view.part.html',
})
export class SharedRegionsViewPart extends DestroyablePart implements OnInit {

	public gridSetup = this.setupGrid();

	public rows: RegionRow[] = [];

	@ViewChild(GridPart) meGrid: GridPart<RegionRow>;

	constructor(
		public router: Router,
		private util: UtilityService,
		private pageService: NationalDashboardPageService,
		public ds: DataService,
	) {
		super();
	}

	async ngOnInit() {

		super.initDestroyable();
		const regions = await this.ds.admin.region.getAllAsArray();
		// const peopleMap = await this.ds.admin.person.getManyAsMap(regions.map(r => r.directorId))
		// this.rows = regions.map(r => ({ ...r, director: peopleMap[r.directorId], directorName: peopleMap[r.directorId]._name }));
		this.rows = regions.map(r => ({ regionId: r.regionId, updatedUTC: r.updatedUTC, }));
	}


	private setupGrid(): GridSetup<RegionRow> {

		return {
			experience: 'REGION',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Region",
			rowPluralName: "Regions",
			rowKey: "regionId",
			stateKey: "regions-list",
			canAdd: false,
			canRefresh: false,
			canDownload: false,
		};
	}



	async gridActionHandler(action: { actionKey: string, rows: RegionRow[] }) {
		// No local actions
	}

}