import { getIconClass, getIconDefaultText, Icon, IconContext, IconHideTextAt, IconStyle } from './index';


/**
 * Return an IconContext that can be displayed with the me-icon component.
 */
export function getIconContext(
	/**
	 * The named icon.
	 */
	icon: Icon,

	/**
	 * A standard FontAwesome style.
	 * Note that this is a suggestion.
	 * Some icons ignore this.
	 */
	iconStyle: IconStyle = 'fa-regular',

	/**
	 * Define the breakpoint where the text changes from being right of the
	 * icon to being hidden but added as a tooltip.
	 */
	hideText: IconHideTextAt = 'never',

	/**
	 * If undefined then the icon's default text (always English) will be used.
	 * Optionally, pass in text to use instead of the default.
	 */
	text: string = undefined,

	/** Optional flag indicating if a red flag should be rendered next to the item */
	hasRedFlag = false,

): IconContext {

	if (text == undefined || text == '') text = getIconDefaultText(icon);

	const iconClass = getIconClass(icon, iconStyle);
	return { icon, iconClass, text, hideText, hasRedFlag };
}
