import { Component, EventEmitter, Input, Output } from '@angular/core';

const ALERT_TYPE_DEFAULT = 'warning';


@Component({
	selector: 'message-line',
	templateUrl: './SHR-CMN_message-line.part.html',
	styleUrls: ['SHR-CMN_message-line.part.scss'],
})
export class MessageLinePart {

	@Input()
	public set alertType(value: string) {
		this._alertType = value;
	}

	public get alertType(): string {
		return this._alertType || ALERT_TYPE_DEFAULT;
	}

	@Input()
	public closeable = true;

	@Input()
	public closed: boolean;

	@Output()
	public closedChange = new EventEmitter<boolean>();

	private _alertType: string;

	@Input() line = '';

	@Input() large = false;

	public close(): void {
		this.closed = true;
		this.closedChange.emit(true);
	}
}
