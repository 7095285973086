
import { DbsPerson, SimplePerson } from '@me-interfaces';
import { DdLanguage } from '../dd-language/dd-language';
import { DdObject } from '../dd-object';
import { DdSiteProgram } from '../dd-site_program/dd-site-program';
import { OldDomainDataService } from '../domain-data.service';
import { DdAccRow } from './dd-acc-row';

/**
 * @deprecated Use Accelerator from the DataService instead
 */
export class DdAcc extends DdObject<DdAccRow> implements DdAccRow {

	constructor(private dd: OldDomainDataService, data: DdAccRow) {
		super(data);
	}

	public get accId(): number {
		return this._data.accId;
	}

	public get accStageId(): number {
		return this._data.accStageId;
	}

	public setAccStageId(stageId: number) {
		this._data.accStageId = stageId;
	}

	public get accStageName(): string {
		const accStage = this.dd.accStages.getById(this.accStageId);
		return accStage ? accStage.name : '';
	}

	public get siteProgramId(): number {
		return this._data.siteProgramId;
	}

	public get siteProgram(): DdSiteProgram {
		return this.dd.sitePrograms.getById(this.siteProgramId);
	}

	public get languageId(): number {
		return this.siteProgram.program.languageId;
	}

	public get language(): DdLanguage {
		return this.dd.languages.getById(this.siteProgram.program.languageId);
	}

	public get directorId(): number {
		return this._data.directorId;
	}

	public getDirector(): SimplePerson {
		return this.dd.getCachedPerson(this.directorId);
	}

	public get managerId(): number {
		return this._data.managerId;
	}

	public getManager(): SimplePerson {
		return this.dd.getCachedPerson(this.managerId);
	}

	public get name(): string {
		return this._data.name;
	}

	public get longName(): string {
		return this._data.longName;
	}

	public get startUTC(): number {
		return this._data.startUTC;
	}

	public get createdUTC(): number {
		return this._data.createdUTC;
	}

	public get applStartUTC(): number {
		return this._data.applStartUTC;
	}

	public get applDuration(): number {
		return this._data.applDuration;
	}
}