import { Component, Input, OnChanges } from '@angular/core';
import { getIconClass, Icon, IconStyle } from './index';


@Component({
	selector: 'i-icon',
	templateUrl: './SHR-CMN_simple-icon.part.html',
	styleUrls: ['./SHR-CMN_simple-icon.part.scss'],
})
export class IconSimplePart implements OnChanges {

	@Input() icon: Icon;
	@Input() iconStyle: IconStyle = 'fa-regular';
	@Input() fixedWidth = false;
	@Input() styles: { [index: string]: number | string } = {};

	iconClass = '';

	async ngOnChanges() {
		if (!this.icon) throw new Error(`Missing required attribute: Icon.`);
		this.iconClass = getIconClass(this.icon, this.iconStyle);
		if (this.fixedWidth) this.iconClass += ' fa-fw';
	}

}