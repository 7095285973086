import { RegionAreaService, RegionPageService } from '@REGION-area';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DestroyablePart } from '@me-access-parts';
import { GridSetup } from '@me-grid';
import { DataService } from '@me-services/core/data';
import { combineLatest, mergeMap } from 'rxjs';


interface SiteRow {
	siteId: number,
}


@Component({
	selector: 'region-sites-view-part',
	templateUrl: './sites-view.part.html',
})
export class RegionSitesViewPart extends DestroyablePart implements OnInit {

	public gridSetup = this.setupGrid();

	public rows$ = combineLatest([
		this.ds.admin.singletonsAsOfUTC$,
		this.regionAreaService.accessAndId$,
	]).pipe(mergeMap(async ([utc, accessAndId]) => {

		if (!accessAndId) return [];

		const identifier = accessAndId.id;
		const regionId = identifier.id;
		const sitesByRegionId = await this.ds.admin.site.getByRegionIds([regionId]);
		const sites = sitesByRegionId[regionId];

		return sites.map(s => ({ siteId: s.siteId }));
	}));


	constructor(
		private ds: DataService,
		private regionAreaService: RegionAreaService,
		private pageService: RegionPageService,
		private router: Router) {
		super();
	}

	async ngOnInit() {
		super.initDestroyable();
	}


	private setupGrid(): GridSetup<SiteRow> {
		return {
			experience: 'SITE',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Site",
			rowPluralName: "Sites",
			rowKey: "siteId",
			stateKey: "region-sites",
			canAdd: false,
			canRefresh: false,
			canDownload: true,
		};
	}


	async gridActionHandler(action: { actionKey: string, rows: SiteRow[] }) {
		const row = action.rows[0];
	}
}
