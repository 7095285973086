import { AccAreaService, AccCohortPageService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AccAreaAccess, AppAreaIdentifierWithSiteId } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { ShowCompanyExtractDialogService } from '@me-shared-parts/CM-company';
import { ShowPersonExtractDialogService } from '@me-shared-parts/PN-person';
import { Icon, PageTabsLayout, getIconContext } from '@me-shared-parts/UI-common';


@Component({
	selector: 'acc-cohort-page',
	templateUrl: './acc-cohort.page.html',
	styleUrls: ['./acc-cohort.page.scss'],
})
export class AccCohortPage extends DestroyablePart implements OnInit {

	readonly: boolean;
	icons = {
		website: getIconContext(Icon.link_website, undefined, 'lt-sm', 'Website'),
	};

	constructor(
		public showPersonExtractDialogService: ShowPersonExtractDialogService,
		public showCompanyExtractDialogService: ShowCompanyExtractDialogService,
		public accAreaService: AccAreaService,
		public ds: DataService,
		public pageService: AccCohortPageService,
	) {
		super();
	}

	ngOnInit(): void {
		super.initDestroyable();

		this.accAreaService.subscribeAccess(this.destroyed$, this.accessChanged);

	}

	// async openQuarterlyDialog() {
	// 	const action: DialogAction<[boolean, boolean, boolean, boolean, boolean]> = await this.dialog.show(
	// 		AccQuarterlyGoalsEnablerDialog,
	// 		{
	// 			launchedFromClass: AccConfigPage.name,
	// 			payload: {
	// 				accelerator: this.accelerator,
	// 				readonly: this.readonly
	// 			},
	// 		},
	// 		400, 500
	// 	);

	// 	const id = action?.id;
	// 	if (id == 'save') {
	// 		this.accAreaService.acc.updateEnabledQuarterlyGoal({ enabledQuarters: action.callbackResult });
	// 	}
	// }


	accessChanged = async (value: { access: AccAreaAccess, id: AppAreaIdentifierWithSiteId<number> }) => {
		this.readonly = value?.access?.root != 'Write';
	}


	onLayoutChange(layout: PageTabsLayout) {
		this.pageService.changeLayout(layout);
	}

}
