import { AccApplicationContext, AccAreaService, AccOverviewPageService, AccStageEditorDialog, AccOverviewPageTabId as TabId, AccOverviewPageViewId as ViewId, } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AccAreaAccess, AccInterviewer, AccJudge, AccMatchableMentor, AccReaderWithMaxReads, AccStageId, AccTeam, Accelerator, AgreementTypeId, AppAreaIdentifierWithSiteId, ApplicationStatusId, Event, EventTypeId, StageStatusItem, ToolLink } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { DialogAction, DialogService } from '@me-services/ui/dialog';
import { UrlService } from '@me-services/ui/url';
import { ShowEventDialogService } from '@me-shared-parts/ED-editors';
import { EventListRow } from '@me-shared-parts/PG-program/events-list/SHR-CMN_events-list.part';
import { getToolStatuses } from '../../get-tool-statuses';


@Component({
	selector: 'acc-user-tools-tab-part',
	templateUrl: './acc-user-tools-tab.part.html',
	styleUrls: [`./acc-user-tools-tab.part.scss`],
})
export class AccUserToolsTabPart extends DestroyablePart implements OnInit {

	accelerator: Accelerator;
	statuses: StageStatusItem[] = [];

	activeEvents: readonly Event[] = [];

	readonly: boolean;
	mentorHandbookToolLink: ToolLink;
	totalMentorCount: number;
	notSignedMentorHandbookCount: number;
	mediaConsentToolLink: ToolLink;
	totalMediaCount: number;
	notSignedMediaHandbookCount: number;
	entrepreneurHandbookToolLink: ToolLink;
	totalEntrepreneurCount: number;
	notSignedEntrepreneurHandbookCount: number;

	constructor(
		public accAreaService: AccAreaService,
		private dialogService: DialogService,
		public ds: DataService,
		public pageService: AccOverviewPageService,
		private showEventDialogService: ShowEventDialogService,
		public urlService: UrlService,
		public util: UtilityService,
	) {
		super();
	}

	async ngOnInit() {
		super.initDestroyable();

		this.accAreaService.subscribeAccess(this.destroyed$, this.accessChanged);

		super.subscribe([
			this.accAreaService.accelerator.acc$,
			this.accAreaService.applications.applications$,
			this.accAreaService.teams.teams$,
			this.accAreaService.accelerator.events$,
			this.accAreaService.reading.readers$,
			this.accAreaService.interviewing.interviewers$,
			this.accAreaService.mentorMatching.matchableMentors$,
			this.accAreaService.judging.judges$,
		], async ([
			acc,
			applications,
			accTeams,
			events,
			readers,
			interviewers,
			mentors,
			judges,
		]) => {

			if (!acc || !applications || !accTeams || !events || !readers || !interviewers || !mentors || !judges) return;

			this.mentorHandbookToolLink = {
				linkType: 'tool',
				url: `${this.urlService.getBaseUrl(acc.siteProgram.program.languageId)}/access/my/agreements/mentor-handbook`,
				enabled: true,
				overrideText: 'Page',
			};

			this.mediaConsentToolLink = {
				linkType: 'tool',
				url: `${this.urlService.getBaseUrl(acc.siteProgram.program.languageId)}/access/my/agreements/media-consent`,
				enabled: true,
				overrideText: 'Page',
			};

			this.entrepreneurHandbookToolLink = {
				linkType: 'tool',
				url: `${this.urlService.getBaseUrl(acc.siteProgram.program.languageId)}/access/my/agreements/entrepreneur-handbook`,
				enabled: true,
				overrideText: 'Page',
			};


			const now = Date.now() / 1000;
			this.activeEvents = events?.filter(event => {
				const e = event;
				if (now < Math.min(e.startUTC, e.toolStartUTC ?? Number.MAX_SAFE_INTEGER)) return false;	// before it started
				if (now > Math.max(e.endUTC, e.toolEndUTC ?? 0)) return false;	// after it ended
				return true;
			});

			this.accelerator = acc ?? undefined;

			this.statuses = getToolStatuses(acc, [...events], this.util, this.urlService, this.getEventVolunteerCount(events, applications, accTeams, readers, interviewers, mentors, judges));


			const agreementTypes = await this.ds.admin.agreementType.getAllAsArray();
			const latestEntrepreneurHandbookVersionId = agreementTypes.find(agreementType => agreementType.agreementTypeId == AgreementTypeId.EntrepreneurHandbook).latestAgreementVersionId;
			const latestMediaConsentVersionId = agreementTypes.find(agreementType => agreementType.agreementTypeId == AgreementTypeId.MediaConsent).latestAgreementVersionId;
			const latestMentorHandbookVersionId = agreementTypes.find(agreementType => agreementType.agreementTypeId == AgreementTypeId.MentorHandbook).latestAgreementVersionId;
			
			const signatures = await this.ds.admin.agreementSignature.getAllAsArray();
			const entHandbookVersionIds = (await this.ds.admin.agreementVersion.getArrayByForeignId('agreementTypeId', AgreementTypeId.EntrepreneurHandbook)).filter(v => v.agreementVersionId == latestEntrepreneurHandbookVersionId).map(version => version.agreementVersionId);
			const mentorHandbookVersionIds = (await this.ds.admin.agreementVersion.getArrayByForeignId('agreementTypeId', AgreementTypeId.MentorHandbook)).filter(v => v.agreementVersionId == latestMentorHandbookVersionId).map(version => version.agreementVersionId);
			const mediaConsentVersionIds = (await this.ds.admin.agreementVersion.getArrayByForeignId('agreementTypeId', AgreementTypeId.MediaConsent)).filter(v => v.agreementVersionId == latestMediaConsentVersionId).map(version => version.agreementVersionId);
			const entHandbookSignatures = signatures.filter(signature => entHandbookVersionIds.includes(signature.agreementVersionId));
			const mentorHandbookSignatures = signatures.filter(signature => mentorHandbookVersionIds.includes(signature.agreementVersionId));
			const mediaSignatures = signatures.filter(signature => mediaConsentVersionIds.includes(signature.agreementVersionId));


			const mentorIds: number[] = [];
			const entrepreneurIds: number[] = [];

			for (const accTeam of accTeams.filter(team => !team.droppedOutWeek)) {
				mentorIds.push(...accTeam.members
					.filter(m => ['C', 'M'].includes(m.member.role))
					.map(m => m.person.personId));

				entrepreneurIds.push(...accTeam.members
					.filter(m => m.member.role == 'E')
					.map(m => m.person.personId));
			}

			const entAndMentorIds = [...new Set([...mentorIds, ...entrepreneurIds])];
			const totalMentorIds = [...new Set(mentorIds)];
			const totalEntIds = [...new Set(entrepreneurIds)];

			this.totalEntrepreneurCount = totalEntIds.length;
			this.totalMediaCount = entAndMentorIds.length;
			this.totalMentorCount = totalMentorIds.length;

			this.notSignedMediaHandbookCount = this.totalMediaCount - mediaSignatures.filter(signature => entAndMentorIds.includes(signature.personId)).length;
			this.notSignedEntrepreneurHandbookCount = this.totalEntrepreneurCount - entHandbookSignatures.filter(signature => [...entrepreneurIds].includes(signature.personId)).length;
			this.notSignedMentorHandbookCount = this.totalMentorCount - mentorHandbookSignatures.filter(signature => [...mentorIds].includes(signature.personId)).length;

		});
	}


	accessChanged = async (value: { access: AccAreaAccess, id: AppAreaIdentifierWithSiteId<number> }) => {
		this.readonly = value?.access?.root != 'Write';
	}


	changeViewAndTab() {
		this.pageService.selectTabAndView(TabId.Accelerator, ViewId.AllMissingSignatures);
	}

	getEventVolunteerCount(events: ReadonlyArray<Event>, accApplications: ReadonlyArray<AccApplicationContext>, accTeams: ReadonlyArray<AccTeam>, readers: ReadonlyArray<AccReaderWithMaxReads>, interviewers: ReadonlyArray<AccInterviewer>, mentors: AccMatchableMentor[], judges: ReadonlyArray<AccJudge>) {
		const eventAndCounts = events.reduce((a: { [index: number]: { volunteerCounts: number, applicationOrTeamCount: number } }, event) => {
			if (event.eventTypeId == EventTypeId.AccReading) {
				a[event.eventId] = {
					volunteerCounts: readers.length,
					applicationOrTeamCount: accApplications.filter(application => application.application.applicationStatusId >= ApplicationStatusId.ReadPending).length,
				}
			}
			else if (event.eventTypeId == EventTypeId.AccInterviewing) {
				a[event.eventId] = {
					volunteerCounts: interviewers.length,
					applicationOrTeamCount: accApplications.filter(application => application.application.applicationStatusId >= ApplicationStatusId.InterviewPending).length,
				}
			}
			else if (event.eventTypeId == EventTypeId.AccMentorMatching) {
				a[event.eventId] = {
					volunteerCounts: mentors.length,
					applicationOrTeamCount: accTeams.filter(team => !team.droppedOutWeek).length,
				}
			}
			else if (event.eventTypeId == EventTypeId.AccFinalPitch) {
				a[event.eventId] = {
					volunteerCounts: judges.length,
					applicationOrTeamCount: accTeams.filter(team => !team.droppedOutWeek && team.finalEventId == event.eventId).length,
				}
			}

			return a;
		}, {});

		// this is a special exception for MM as we need the total even if there are no events
		eventAndCounts[0] = {
			volunteerCounts: mentors.length,
			applicationOrTeamCount: accTeams.filter(team => !team.droppedOutWeek).length,
		}

		return eventAndCounts;
	}

	async openStageDialog() {
		const action: DialogAction<AccStageId> = await this.dialogService.showCustom(
			AccStageEditorDialog,
			{
				data: {
					accStageId: this.accelerator.accStageId,
					readonly: this.readonly
				},
			},
			400, 250
		);

		const id = action?.id;
		if (id == 'save') {
			await this.accAreaService.accelerator.actions.setStage({ accStageId: action.callbackResult });
		}
	}

	async openEventDialog(e: EventListRow) {

		const accTeamsByAccId = await this.ds.admin.accTeam.getByAccIds([e.event.accId]);
		const accTeams = accTeamsByAccId[e.event.accId];

		const teamsForAward = [];
		for (const accTeam of accTeams) {
			const company = accTeam.application.company;
			teamsForAward.push({ teamId: accTeam.accTeamId, text: company.longName, type: 'accTeam' });
		}
		await this.showEventDialogService.edit(e.event, false, teamsForAward);
	}
}
