<div class="row justify-content-center">
	<div class="col-12 col-sm-8 col-md-6 col-lg-4">
		<div class="card">
			<div class="card-body">

				<p><b>{{welcomeMessage}}</b></p>

				<p class='bad-email'>{{(auth.me.firebaseUser$ | async)?.email}}</p>

				<ng-container *ngIf='!passwordUser'>
					<p>{{passwordNotVerified1}}</p>
					<p>{{passwordNotVerified2}}</p>
				</ng-container>

				<ng-container *ngIf='passwordUser'>
					<p>{{emailVerificationMessage}}</p>
					<span style='background-color:#FF9; padding:0.4rem; border-radius:5px;'>
						<b>{{checkSpamFolder}}</b>
					</span>
				</ng-container>

				<p></p>

				<div class="text-center">
					<button class='btn' (click)="signout()">{{goToLoginScreen}}</button>
					<button class='btn' (click)="sendEmail()" *ngIf='passwordUser'>{{resendEmail}}</button>
				</div>

			</div>
		</div>
	</div>
</div>