<h5><me-label key='Is this you?'></me-label></h5>
<div class="text-center">
	<p></p>
	<img src="assets/img/users/avatar.png" alt="user-img" class="img-circle user-img">
	<table class='table'>
		<tr>
			<td><me-label key='Name'></me-label></td>
			<td>{{ person.firstName }} {{ person.lastName }}</td>
		</tr>
		<tr *ngIf="person.city">
			<td><me-label key='Home'></me-label></td>
			<td>{{ person.city }}</td>
		</tr>
		<tr *ngIf="person.phoneLastFour">
			<td><me-label key='Phone'></me-label></td>
			<td>
				<code>### ### {{ person.phoneLastFour }}</code>
			</td>
		</tr>
		<tr *ngIf="person.obfuscatedEmail">
			<td><me-label key='Email'></me-label></td>
			<td>
				<code>{{ person.obfuscatedEmail }}</code>
			</td>
		</tr>
	</table>
	<div class="text-center">
		<spinner-button (safeClick)="yes()" [disabled]="sendingCode" [spinning]="sendingCode"><me-label key='Yes'></me-label></spinner-button>
		<button class='btn' (click)="no()" [disabled]="sendingCode"><me-label key='No'></me-label></button>
	</div>
	<div>
		<small>
			<i><me-label key='md:If Yes Code Message'></me-label></i>
		</small>
	</div>
</div>