import { SevenNums } from '@me-interfaces';

export const MAX_SLOT = 30; // 0 == 6AM and 30 = 8:30PM
export const MAX_SLOT_VALUE = 2 ** MAX_SLOT - 1;

export const DAY_NAMES = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
export const DAY_NAMES_ES = ['LUN', 'MAR', 'MIE', 'JUE', 'VIE', 'SAB', 'DOM'];
export const SLOT_NAMES = ['6:00', '6:30', '7:00', '7:30', '8:00', '8:30', '9:00', '9:30', '10:00', '10:30', '11:00', '11:30', 'Noon', '12:30', '1:00', '1:30', '2:00', '2:30', '3:00', '3:30', '4:00', '4:30', '5:00', '5:30', '6:00', '6:30', '7:00', '7:30', '8:00', '8:30'];
export const SLOT_NAMES_ES = ['6:00', '6:30', '7:00', '7:30', '8:00', '8:30', '9:00', '9:30', '10:00', '10:30', '11:00', '11:30', 'Mediodía', '12:30', '1:00', '1:30', '2:00', '2:30', '3:00', '3:30', '4:00', '4:30', '5:00', '5:30', '6:00', '6:30', '7:00', '7:30', '8:00', '8:30'];

export function isBitOn(value, bit): boolean {
	const bitValue = 2 ** bit;
	return (value & bitValue) == bitValue;
}

export function isBitOff(value, bit): boolean {
	return !isBitOn(value, bit);
}

export function turnBitOn(value: number, bit: number): number {
	return value | 2 ** bit;
}

export function turnBitOff(value, bit): number {
	const bitValue = 2 ** bit;
	const maxValue = (2 ** MAX_SLOT) - 1; // Bit value for the last possible 30 min block
	return value & (maxValue - bitValue);
}

/**
 * Given a number where each bit represents a half-hour slot, return a number
 * where each bit represents whether that slot and the following to slots were
 * on. In other words, turn on the bits that represent the half-hour slot when
 * a 90 minute meeting could start.
 * @param slots 
 */
export function slotsToMeetingStarts(slots: number): number {
	let meetings = 0;

	let mtg = 7; //first 3 half hours
	for (let i = 0; i < (MAX_SLOT - 2); i++) {
		if ((slots & mtg) == mtg) meetings |= 2 ** i;
		mtg = mtg << 1;
	}

	return meetings;
}

export function meetingStartsToSlots(starts: number): number {
	let slots = 0;

	let mtg = 7
	for (let i = 0; i < (MAX_SLOT - 2); i++) {
		const bit = 2 ** i;
		if ((starts & bit) == bit) slots |= mtg;
		mtg = mtg << 1;
	}

	return slots;
}

export function hammingWeight(meetings: number) {
	//returns the count of bits set for the given number
	meetings = meetings - ((meetings >> 1) & 0x55555555);
	meetings = (meetings & 0x33333333) + ((meetings >> 2) & 0x33333333);
	return ((meetings + (meetings >> 4) & 0xF0F0F0F) * 0x1010101) >> 24;
}

/**
 * Each set bit of the number passed in represents a half hour slot of a meeting
 * start time (90 minutes). The number passed out has the following two bits set
 * so that each bit represents the half hour slot in which someone might be in a
 * meeting
 */
export function meetingSlots(meetingStarts: number): number {
	let slots = 0;

	let mtg = 7; //first 3 half hours
	for (let i = 0; i < (MAX_SLOT - 2); i++) {
		if (meetingStarts & (2 ** i)) {
			slots |= mtg;
		}
		mtg = mtg << 1;
	}

	return slots;
}

export function overlappingSlotsToEnglish(allOverlap: SevenNums) {
	const ranges: string[] = [];
	let d = 0;
	for (const day of allOverlap) {
		let rangeStarted = undefined;
		for (let slot = 0; slot <= MAX_SLOT; slot++) {
			if (isBitOn(day, slot) && rangeStarted == undefined) {
				rangeStarted = slot;
			}
			else if ((!isBitOn(day, slot) && rangeStarted != undefined) || (isBitOn(day, slot) && slot == MAX_SLOT && rangeStarted != undefined)) {
				ranges.push(addText(d, rangeStarted, slot));
				rangeStarted = undefined;
			}
		}
		d++;
	}
	return ranges;
}

function addText(d: number, rangeStarted: number, slot: number) {
	const slotNames = [...SLOT_NAMES];
	slotNames.push('9:00');
	for (let s = 0; s <= MAX_SLOT; s++) {
		if (s < 12) slotNames[s] = `${slotNames[s]} AM`;
		else if (s > 12) slotNames[s] = `${slotNames[s]} PM`;
	}
	return `${DAY_NAMES[d]} ${slotNames[rangeStarted]} - ${slotNames[slot]}`;
}

/**
 * Function used to determine the overlapping meetings between AccTeamOrmentor(sched1) and Mentor(sched2)
 */
export function calcAccTeamOrMentorToMentorOverlap(sched1: SevenNums, sched2: SevenNums) {
	const schedule1 = sched1.map(day => slotsToMeetingStarts(day));
	const schedule2 = sched2.map(day => slotsToMeetingStarts(day));

	let overlaps = 0;

	for (const d of [0, 1, 2, 3, 4, 5, 6]) {
		overlaps += hammingWeight(schedule1[d] & schedule2[d]);
	}

	return overlaps;
}