import { DbcEventAttendee, DbConceptName, Dbs, DbsAward, DbsPerson, EventTypeId, ExplorablePackage, Immutable, LanguageId, PublicPersonForDialog, Venue } from '@me-interfaces';



/**
 * The context of an event, including the event type and program type.
 */
export interface EventContext {
	eventTypeId: EventTypeId,
	languageId: LanguageId,
	siteId?: number,
	siteProgramId?: number,
	accId?: number,
	picId?: number,
}


/**
 * A fully defined event in the database.
 */
export interface MutableDbsEvent extends EventContext, Dbs {

	readonly _concept: DbConceptName.Event,

	eventId: number,
	/**
	 * If the eventType is Session then it will return topic names or will be null
	 */
	name: string,
	hostPersonId: number,
	startUTC: number,
	endUTC: number,
	toolStartUTC: number,
	toolEndUTC: number,
	venueId: number | undefined,
	link: string,
	publicNotes: string,
	createdUTC: number,
	createdByPersonId: number,
	updatedUTC: number,
	updatedByPersonId: number,


	/**
	 * If the eventType supports awards then it will return the DbsAward[], 
	 * or will be [] if there are no awards added yet
	 */
	awards: DbsAward[],

	/**
	 * If the eventType supports attendedBy - Person then it will return the DbcEventAttendee[], 
	 * or will be [] if there are no attendee added yet
	 */
	attendees: DbcEventAttendee[],


	/** @deprecated This is set for legacy code. Avoid using it. */
	publicHost?: PublicPersonForDialog
}

/**
 * @deprecated Use Event from the DataService event package manager instead.
 */
export interface EventAndHost {
	event: DbsEvent,
	host: DbsPerson | undefined,
}


interface MutableEvent extends MutableDbsEvent {
	hostPerson: DbsPerson,
	createdByPersonName: string,
	updatedByPersonName: string,
	venue: Venue | undefined,
}


export interface DbsEvent extends Immutable<MutableDbsEvent> { }
export interface Event extends ExplorablePackage<DbConceptName.Event>, Immutable<MutableEvent> { }