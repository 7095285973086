import { AccTeam, DbConceptName } from "@me-interfaces";
import { LinkOrExplorable } from "../../link-or-explorerable";

export function getButtons(accTeam: AccTeam): LinkOrExplorable[] {

	const buttons: LinkOrExplorable[] = [];

	//
	// Company
	//

	buttons.push({ explorer: { nameOverride: accTeam.name, explorableId: { conceptName: DbConceptName.Company, id: accTeam.application.companyId }, navTooltip: `Company: ${accTeam.name}` } });


	//
	// Application
	//
	buttons.push({ explorer: { nameOverride: 'Application', explorableId: { conceptName: DbConceptName.Application, id: accTeam.applicationId }, navTooltip: `Application` } });


	return buttons;
}