<div class='acc-cohort-goals-and-progress-view-part'>
	<ng-container *ngIf='teamGoals$ | async; let teamGoals'>
		<ng-container *ngIf="layoutService.dimensions$ | async; let dims">
			<div class='goals-and-progress' [style.height.px]='dims.contentHeight - 2'>
				<acc-goals-team-selector-part *ngIf="teamGoals && teamGoals.length" [accTeamGoalsAndAccess]="teamGoals"
					(addGoal)="onAddGoal($event)" (editGoal)="onEditGoal($event)" (addComment)="onAddComment($event)">
				</acc-goals-team-selector-part>
				<message-line *ngIf="teamGoals && !teamGoals.length" alertType='info'>
					There are no teams for the selected accelerator.
				</message-line>
			</div>
		</ng-container>
	</ng-container>

</div>