export interface AccAssociationExtract {
	accId: number,
	applicationId: number,
	accTeamId?: number,
	awardee?: boolean,
}

export interface NoteCountsExtract {
	redFlags: number,
	total: number,
}

// export interface PicAssociationExtract {
// 	picId: number,
// 	applicationId: number,
// 	picTeamId?: number,
// 	awardee?: boolean,
// }
