import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Tag } from '@me-interfaces';
import { DdTag } from '@me-services/core/old-dd';

interface TagsGroupedByPrefix {
	prefix: string,
	tags: DdTag[]
}
@Component({
	selector: 'person-tags-overview',
	templateUrl: './SHR-CMN_person-tags-overview.part.html',
	styleUrls: ['SHR-CMN_person-tags-overview.part.scss'],
})
export class PersonTagsOverviewPart implements OnChanges {
	@Input() tags: DdTag[];
	@Input() expanded = true;
	@Input() inDialog = false;
	@Output() navigateToTagGroup = new EventEmitter<string>();

	tagsGroupedByPrefix: TagsGroupedByPrefix[] = [];
	userPermissions: { [index: string]: boolean } = {};

	ngOnChanges() {
		let siteTags: TagsGroupedByPrefix[] = [];
		const nonSiteTags: TagsGroupedByPrefix[] = [{
			prefix: 'lang',
			tags: []
		}];
		for (const tag of this.tags) {
			let arrayToPushIn: TagsGroupedByPrefix[] = [];
			if (tag.prefix !== 'lang' && tag.prefix !== 'hq' && tag.prefix !== 'eforall') arrayToPushIn = siteTags;
			else arrayToPushIn = nonSiteTags;
			const prefixFound = arrayToPushIn.find(t => t.prefix == tag.prefix);

			if (prefixFound) prefixFound.tags.push(tag);
			else {
				arrayToPushIn.push({
					prefix: tag.prefix,
					tags: [tag],
				});
			}
		}

		siteTags = siteTags
			.sort((a, b) => a.prefix > b.prefix ? 1 : -1)
			.map(siteTag => {
				siteTag.tags.sort((a, b) => a.name > b.name ? 1 : -1);
				return siteTag;
			});

		this.tagsGroupedByPrefix = [...siteTags, ...nonSiteTags];
	}

	public navigateToTagSet(tag: Tag) {
		if (this.inDialog) this.navigateToTagGroup.emit(`selectable-view-prefix-name-${tag.tagPrefix.prefix.toLowerCase()}`);
	}
}
