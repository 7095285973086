import { AccAreaService } from '@ACC-area';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { Event, EventTypeId } from '@me-interfaces';
import { UtilityService } from '@me-services/core/utility';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';


@Component({
	selector: 'multiple-events-selector-dialog',
	templateUrl: './multiple-events-selector.dialog.html',
})
export class AccMultipleEventsSelectorDialog extends DestroyablePart implements OnInit {

	saveAction: DialogAction<number[]> = {
		id: 'save',
		enabled: true,
		visible: true,
		label: ':Save',
		linkType: 'callback',
		callback: async () => (this.newEventIds),
		willCloseDialog: true,
	};

	cancelAction: DialogAction = {
		id: 'close',
		enabled: true,
		visible: true,
		label: ':Cancel',
		linkType: "no-action",
		willCloseDialog: true,
	};

	actions: DialogAction<number[] | void>[] = [this.saveAction, this.cancelAction];
	icon = 'fa-pen';
	headerText = 'Set Event';
	readonly: boolean;
	orgEventIds: number[];
	orgEventsDates: string[];
	newEventIds: number[];
	events: { eventId: number, numWithDate: string, event: Event }[];



	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ readonly: boolean, eventIds: number[], eventTypeId: EventTypeId, header?: string }>,
		public dialogRef: MatDialogRef<AccMultipleEventsSelectorDialog>,
		private util: UtilityService,
		private accAreaService: AccAreaService,
	) {
		super();
	}


	async ngOnInit() {
		super.initDestroyable();
		const { readonly, eventIds, eventTypeId, header } = this.dialog.data;
		this.readonly = readonly;
		this.orgEventIds = this.newEventIds = eventIds;

		if (header) this.headerText = header;

		this.buildEvents(eventTypeId);

		this.checkIfCanSave();
	}

	async buildEvents(eventTypeId: EventTypeId) {
		this.events = (await this.accAreaService.getEventsWithNumAndDate(eventTypeId))
			.map(event => ({ eventId: event.event.eventId, numWithDate: event.numWithDate, event: event.event }));

		const eventsFound = this.events.filter(e => this.orgEventIds.includes(e.eventId));
		if (!eventsFound.length) {
			this.orgEventIds = this.newEventIds = [];
		}
		else {
			this.orgEventsDates = eventsFound.map(event => this.events.find(e => e.eventId == event.eventId)?.numWithDate || '');
		}
	}


	/**
	 * This function is called as the user is typing/pasting/deleting into a field
	 */
	onSelectionChange(e: number[]) {
		this.newEventIds = e;
		this.checkIfCanSave();
	}


	/**
	 * Look at newMaxReads and determine if the save/update button should be enabled
	 */
	checkIfCanSave() {
		this.saveAction.enabled = !this.readonly && !this.util.values.areSame(this.newEventIds, this.orgEventIds);
	}
}