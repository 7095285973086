
import { AppSessionPersonMatch, AppSessionSearchDetails } from '@me-interfaces';

export type AppSessionState = 'NO_SESSION' | 'READY_TO_TRY_SESSION' | 'SESSION_STARTED' | 'NO_PERSON' | 'MATCHES' | 'PENDING_CODE' | 'WRONG_CODE';

export interface AppSessionStatus {
	state: AppSessionState;
	pendingCodeSentToThisEmail?: string;   // included with 'PENDING_CODE' and 'WRONG_CODE'
	pendingCodeSecsUntilCanRetry?: number; // included with 'PENDING_CODE' and 'WRONG_CODE'
	searchDetails?: AppSessionSearchDetails;  // included with 'MATCHES'
	matchingPeople?: AppSessionPersonMatch[]; // included with 'MATCHES'
}