import { AccAreaData, AccAreaService, AccCohortPageService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DestroyablePart } from '@me-access-parts';
import { AccTeamCols, GridColumnType, GridSetup } from '@me-grid';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { Icon } from '@me-shared-parts/UI-common';
import { switchMap } from 'rxjs/operators';
import { calculateParticipation } from './calculate-participation';


const OPEN_APPLICATION_ACTION_KEY = 'open-application';

interface TeamRow {
	accTeamId: number,

	sessionsWithAtLeastOneAttendee: number,
	sessionAttendeePercent: number,

	sessionsWithAtLeastOneSurvey: number,
	sessionSurveyPercent: number,

	score: number,
}


@Component({
	selector: 'acc-cohort-team-participation-view-part',
	templateUrl: './acc-cohort-team-participation-view.part.html',
})
export class AccCohortTeamParticipationViewPart extends DestroyablePart implements OnInit {

	public gridSetup = this.setupGrid();

	rows$ = this.accAreaService.data$.pipe(switchMap(data => this.buildRows(data)));


	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private accAreaService: AccAreaService,
		private ds: DataService,
		private util: UtilityService,
		private pageService: AccCohortPageService,
	) {
		super();
	}


	ngOnInit() {
		super.initDestroyable();
	}


	async buildRows(data: AccAreaData): Promise<TeamRow[]> {

		if (!data) return [];

		const accTeams = data.team.teams ?? [];
		const participationByAccTeamId = await calculateParticipation(this.ds, this.util, data);

		return accTeams.map(accTeam => {

			const accTeamId = accTeam.accTeamId;
			const participation = participationByAccTeamId[accTeamId] ?? {
				sessions: 0,
				sessionsWithAtLeastOneAttendee: 0,
				sessionAttendeePercent: 0,
				sessionsWithAtLeastOneSurvey: 0,
				sessionSurveyPercent: 0,
				score: 0,
			};

			return { accTeamId, ...participation };

		});

	}


	private setupGrid(): GridSetup<TeamRow> {

		return {
			experience: 'ACCTEAM',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Team",
			rowPluralName: "Teams",
			rowKey: "accTeamId",
			stateKey: "adm-acc-cohort-team-participation-page",
			canAdd: false,
			canRefresh: false,
			canDownload: true,
			columnsToAdd: [
				{ order: 202, field: 'sessionsWithAtLeastOneAttendee', header: 'Attendance', width: 60, type: GridColumnType.number, hidden: true },
				{ order: 202, field: 'sessionAttendeePercent', headerTooltip: `Percentage of past sessions of which at least one of the team's entrepreneurs attended`, header: 'Attendance %', width: 60, type: GridColumnType.percent },
				{ order: 202, field: 'sessionsWithAtLeastOneSurvey', header: 'Surveys', width: 60, type: GridColumnType.number, hidden: true },
				{ order: 202, field: 'sessionSurveyPercent', headerTooltip: `Percentage of past sessions of which at least one of the team's entrepreneurs completed a survey`, header: 'Surveys %', width: 60, type: GridColumnType.percent },
				{ order: 202, field: 'score', header: 'Score', headerTooltip: 'Attendance x 0.7 plus Surveys x 0.3', width: 80, type: GridColumnType.number },
			],
			columnsToAlter: [
				{ field: AccTeamCols.entrepreneurNames, width: 140, hidden: false },
			],
			actions: [
				{ key: OPEN_APPLICATION_ACTION_KEY, icon: Icon.concept_application, label: 'Open Application', enabled: false },
			],
			initialState: {
				sort: [{ field: 'score', dir: 'desc' }],
			},
		};
	}


	async gridActionHandler(action: { actionKey: string, rows: TeamRow[] }) {

		// if (action.actionKey == OPEN_APPLICATION_ACTION_KEY) {
		// 	if (action.rows[0].accTeamId) {
		// 		this.router.navigate([`../applications/${action.rows[0].applicationId}`], { relativeTo: this.route });
		// 	}
		// }

	}
}