import { Component, Input, OnChanges } from '@angular/core';
import { Address, DbsCompany } from '@me-interfaces';

@Component({
	selector: 'company-extract-banner',
	templateUrl: './SHR-CM_company-extract-banner.part.html',
	styleUrls: ['./SHR-CM_company-extract-banner.part.scss']
})
export class CompanyExtractBannerPart implements OnChanges {
	@Input() company: DbsCompany;

	address: Address;

	ngOnChanges() {
		if (!this.company) throw new Error('Missing required attribute: company');

		this.address = { address: this.company.address || undefined, zip: this.company._entityExtracts.zip || undefined };
	}
}