<div class="person-editors-part" *ngIf='person'>

	<a class='popout' target="_blank" title="Open in New Tab" [href]="popoutUrl">
		<i class="far fa-external-link"></i>
	</a>

	<kendo-tabstrip (tabSelect)="onTabSelect($event)" [class]='tabStripClass'>

		<kendo-tabstrip-tab [selected]="editorKey === 0">
			<ng-template kendoTabTitle>
				<i title="Profile" class="far fa-address-card"></i>
				<span *ngIf='editorKey === 0'>Profile</span>
			</ng-template>
			<ng-template kendoTabContent>
				<person-profile-editor [person]='person' [initialSelectorKey]='selectors.profile'>
				</person-profile-editor>
			</ng-template>
		</kendo-tabstrip-tab>

		<kendo-tabstrip-tab [selected]="editorKey === 1">
			<ng-template kendoTabTitle>
				<i title="Tags" class="far fa-tags"></i>
				<span *ngIf='person._personExtracts.tagIds.length > 0'>{{person._personExtracts.tagIds.length}}</span>
				<span *ngIf='editorKey === 1 && person._personExtracts.tagIds.length !== 1'>Tags</span>
				<span *ngIf='editorKey === 1 && person._personExtracts.tagIds.length ===1'>Tag</span>
			</ng-template>
			<ng-template kendoTabContent>
				<person-tag-editor [person]='person' [initialSelectorKey]='selectors.tags'>
				</person-tag-editor>
			</ng-template>
		</kendo-tabstrip-tab>

		<kendo-tabstrip-tab [selected]="editorKey === 2">
			<ng-template kendoTabTitle>
				<i title="Notes" class="far fa-sticky-note"
					[class.redFlag]='person._entityExtracts.noteCounts.redFlags > 0'></i>
				<span [class.redFlag]='person._entityExtracts.noteCounts.redFlags > 0'
					*ngIf='person._entityExtracts.noteCounts.total > 0'>{{person._entityExtracts.noteCounts.total}}</span>
				<span *ngIf='editorKey === 2 && person._entityExtracts.noteCounts.total !== 1'>Notes</span>
				<span *ngIf='editorKey === 2 && person._entityExtracts.noteCounts.total === 1'>Note</span>
			</ng-template>
			<ng-template kendoTabContent>
				<entity-notes-editor [entity]='person' [initialNoteId]='selectors.notes'></entity-notes-editor>
			</ng-template>
		</kendo-tabstrip-tab>

		<kendo-tabstrip-tab [selected]="editorKey === 3">
			<ng-template kendoTabTitle>
				<i title="Connections" class="far fa-exchange"></i>
				<span *ngIf='editorKey === 3'>Connections</span>
			</ng-template>
			<ng-template kendoTabContent>

				<message-box alertType='info'>
					Soon you will be able to view and edit connections right here.
					In the meantime, please enjoy the following buttons which launch the App into a different browser tab.
				</message-box>

				<p></p>

				<p>
					<a target='_blank'
						href="https://eforall.app/access/contacts/people/{{person.personId}}/connections/relationships"
						class="btn">Relationships</a> (Person to Person)
				</p>

				<p>
					<a target='_blank'
						href="https://eforall.app/access/contacts/people/{{person.personId}}/connections/positions"
						class="btn">Positions</a> (Person to Company)
				</p>

				<p>
					<a target='_blank'
						href="https://eforall.app/access/contacts/people/{{person.personId}}/connections/associations"
						class="btn">Associations</a> (Person to Program)
				</p>

			</ng-template>
		</kendo-tabstrip-tab>
	</kendo-tabstrip>
</div>