import { DdSite, OldDomainDataService } from '@me-services/core/old-dd';
import { FuncService } from "@me-services/core/func";

/** @deprecated DO NOT USE THIS!*/
export class SiteWrapper {

	public readonly site: DdSite;

	/** @deprecated */
	constructor(private _data: DdSite, private func: FuncService, private dd: OldDomainDataService) {
		this.site = _data;
	}


}