<div class='event-dialog-editor-view-part'>

	<table *ngIf='dates' style='width:100%'
		class='pro-table pro-table-no-header pro-table-with-labels pro-table-without-border'>

		<tr *ngFor='let row of eventContextRows'>
			<td>{{ row.label }} </td>
			<td>
				{{ row.value }}
				<small *ngIf='row.site' class='inline-row-text' style='top:0;'>{{ row.site }}</small>
			</td>
		</tr>

		<tr>
			<td>Event Time</td>
			<td>
				<div *ngIf='dates.eventDates.description'>{{ dates.eventDates.description }}</div>
				<div *ngIf='!dates.eventDates.description' class='missing-value'>Dates Not Provided</div>
			</td>
		</tr>

		<!------------- Tool start and end dates/times  --------------->

		<tr *ngIf='toolIconMessage'>
			<td kendoTooltip>
				<i class='far fa-question-circle tool-info-icon' [title]='toolIconMessage'
					(click)='toolIconClick()'></i>
				Tool Enabled
			</td>
			<td>
				<div *ngIf='dates.toolDates.description'>{{ dates.toolDates.description }}</div>
				<div *ngIf='!dates.toolDates.description' class='missing-value'>Dates Not Provided</div>
			</td>
		</tr>

		<!------------- Host  --------------->

		<tr>
			<td>Host</td>
			<td>
				<person-extract-link *ngIf='host' [person]='host'>
				</person-extract-link>
				<div *ngIf='!host' class='missing-value'>Host Not Provided</div>
			</td>
		</tr>

		<!------------- Location  --------------->

		<tr>
			<td>Location</td>
			<td>
				<div *ngIf='venue'>
					<a *ngIf='venue.website' [href]='venue.website' target='_blank'>{{venue.displayedName}}</a>
					<ng-container *ngIf='!venue.website'>{{venue.displayedName}}</ng-container>
				</div>
				<div *ngIf='!venue'>Online</div>
			</td>
		</tr>


		<!------------- Link and Notes  --------------->

		<tr>
			<td>Link</td>
			<td>
				<a *ngIf='event.link' [href]='event.link' target='_blank'>{{event.link}}</a>
				<div *ngIf='!event.link' class="missing-value">Link Not Provided</div>
			</td>
		</tr>

		<tr>
			<td>Public Notes</td>
			<td>
				<div *ngIf='event.publicNotes' class="public-notes">{{event.publicNotes}}</div>
				<div *ngIf='!event.publicNotes' class="missing-value">Notes Not Provided</div>
			</td>
		</tr>

	</table>

</div>