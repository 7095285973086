import { Component, Input, OnChanges } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DbsPerson, PersonRelationshipExtract } from '@me-interfaces';
import { FuncService } from '@me-services/core/func';
import { DialogService } from '@me-services/ui/dialog';
import { PersonExtractDialog } from '../../person-extract-dialog/SHR-PN_person-extract-dialog.dialog';

@Component({
	selector: 'person-extract-relationships',
	templateUrl: './SHR-PN_person-extract-relationships.part.html',
	styleUrls: ['./SHR-PN_person-extract-relationships.part.scss']
})
export class PersonExtractRelationshipsPart implements OnChanges {
	@Input() name: string;
	@Input() relationships: PersonRelationshipExtract[];
	@Input() dialogRef: MatDialogRef<PersonExtractDialog>;


	person: DbsPerson;

	constructor(private func: FuncService, private dialogService: DialogService) {
	}

	ngOnChanges() {
		if (!this.name) throw new Error('Missing required attribute: name');
		if (!this.relationships) throw new Error('Missing required attribute: relationships');
	}

	async open(personId: number) {

		this.person = await this.func.person.get(personId);

		this.dialogRef.close();

		await this.dialogService.showCustom(
			PersonExtractDialog,
			{
				data: {
					person: this.person,
				},
			},
			500, 500);
	}
}