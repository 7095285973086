import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { UtilityService } from '@me-services/core/utility';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';


@Component({
	selector: 'note-dialog',
	templateUrl: './SHR-PG_note-dialog.dialog.html',
	styleUrls: ['./SHR-PG_note-dialog.dialog.scss']
})
export class NoteDialog extends DestroyablePart implements OnInit {
	//
	// Configuration for the input fields 
	//
	notes = {
		value: '',
		changed: false,
		pending: '',
		valid: true,
	};

	readonly: boolean;


	//
	// The action buttons
	//
	saveAction: DialogAction<{ notes: string }> = {
		id: 'save',
		enabled: false,
		visible: true,
		label: 'Save',
		linkType: 'callback',
		callback: async () => ({
			notes: this.notes.pending,
		}),
		willCloseDialog: true,
	};

	closeAction: DialogAction<void> = {
		id: 'close',
		enabled: true,
		visible: true,
		label: 'Close',
		linkType: "no-action",
		willCloseDialog: true,
	};

	actions: DialogAction<{ notes: string } | void>[] = [this.saveAction, this.closeAction];

	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ notes: string, readonly: boolean }>,
		public dialogRef: MatDialogRef<NoteDialog>,
		private util: UtilityService,
	) {
		super();

		this.readonly = dialog.data?.readonly;

		if (this.dialog.data?.notes) {
			this.notes.pending = dialog.data.notes;
			this.notes.value = dialog.data.notes;
		}

		if (this.readonly) this.saveAction.enabled = false;
	}

	ngOnInit() {
		super.initDestroyable();
	}


	/**
	 * Record the pending value and check if it is valid (within the length range)
	 */
	setPendingValue(text: string) {

		//
		// Cleanup the text
		//
		text = (text ?? '').trim();

		//
		// Titles can be three-letter, uppercase (e.g. CEO) but anything else has the case fixed
		//
		text = this.util.text.fixCase(text, 'sentence-case');


		const values = this.notes;
		values.pending = text;
		values.valid = values.pending != values.value;

		this.checkIfCanSave();
	}


	/**
	 * This function is called as the user is typing/pasting/deleting into a field
	 */
	onInput(e) {
		const text = (e.target.value ?? '').trim();
		this.setPendingValue(text);
		this.checkIfCanSave();
	}

	/**
	 * When losing focus and a pending values was added, that pending value may have been
	 * cleaned (e.g. fixCase) so we update the value with the pending value so it is bound
	 * back into the input field and shown to the user. 
	 */
	onBlur() {
		this.notes.changed = true;
		if (this.notes.value !== this.notes.pending) {
			this.notes.value = this.notes.pending;
			this.checkIfCanSave();
		}
	}

	/**
	 * Look at each value and determine if the save/update button should be enabled
	 */
	checkIfCanSave() {
		this.saveAction.enabled = !this.readonly && this.notes.valid;
	}

}