import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { MatInput } from '@angular/material/input';
import { DestroyablePart } from '@me-access-parts';
import { LabelKey } from '@me-interfaces';
import { LabelsService } from '@me-services/ui/labels';
import { Subscription } from 'rxjs';


@Component({
	selector: 'editable-question',
	templateUrl: './SHR-CMN_editable-question.part.html',
	styleUrls: ['./SHR-CMN_editable-question.part.scss']
})
export class EditableQuestionPart extends DestroyablePart implements OnInit, AfterViewInit {

	@Input() panel: MatExpansionPanel;
	@Input() answer;
	@Input() formGroup: FormGroup;
	@Input() control: FormControl;
	@Input() controlName: string;
	@Output() ratingChanged = new EventEmitter();

	@ViewChild(MatInput) input1;
	@ViewChild(MatInput) input2;
	@ViewChild(MatInput) input3;
	@ViewChild(MatInput) input6;
	@ViewChild(MatInput) input7;

	panelOpenSubscription: Subscription;

	inlineLabels = this.labels.trackInlineLabels(this, [
		"Text",
		"Multiple line Text",
	]);

	constructor(public labels: LabelsService) { super(); }

	ngOnInit() {
		super.initDestroyable();
	}

	ngAfterViewInit() {

		if (this.panelOpenSubscription) return;

		this.panelOpenSubscription = this.panel.opened.subscribe(_ => {
			const id = this.answer.question.questionTypeId;
			const input: MatInput = this['input' + id];
			if (input) setTimeout(_ => input.focus(), 100)
		});
	}

	trim(ctrl: AbstractControl) {
		ctrl.setValue(ctrl.value.trim());
	}

	tooManyCharacters(ctrl: AbstractControl): LabelKey {
		const e = ctrl.errors.maxlength;
		return {
			key: 'Too many characters. {{lengths}}',
			fields: {
				lengths: e.actualLength + ' > ' + e.requiredLength
			}
		}
	}
}