<div class="explorer-box-part {{box.explorerBoxClass}}">

	<div class="explorer-box-header">{{ box.header }}</div>

	<div class="explorer-box-buttons">
		<ng-container *ngFor="let button of box.buttons">
			<link-button *ngIf="button.link" [link]="button.link"></link-button>
			<explorer-button *ngIf="button.explorer" [button]="button.explorer"></explorer-button>
		</ng-container>
	</div>

	<div class="explorer-box-notes">
		{{ box.notes }}
	</div>
</div>