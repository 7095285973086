<me-dialog-frame [header]='header' [showToolbar]='true' [dialogRef]='dialogRef' icon='far fa-building'>

	<ng-container toolbar *ngIf='company'>
		<a class="btn" (click)='openCompanyEdit()'>
			<me-icon [icon]="{ icon:'far fa-pencil', text:'Edit', hideText:'lt-md' }"></me-icon>
		</a>

		<a class="btn" (click)='openCompanyEdit(2)'>
			<me-icon [icon]="notesIcon"></me-icon>
		</a>

		<div class="toolbar-separator"></div>

		<a class="btn" *ngIf='telLink' [href]='telLink'>
			<me-icon [icon]="{ icon:'far fa-phone', text:'Call', hideText:'always' }"></me-icon>
		</a>

		<a class="btn" *ngIf='smsLink' [href]='smsLink'>
			<me-icon [icon]="{ icon:'far fa-comment-alt-lines', text:'Text Message', hideText:'always' }">
			</me-icon>
		</a>

		<div class="toolbar-separator" *ngIf='company._entityExtracts?.webLinks'></div>

		<a class="btn" *ngFor='let webLink of company._entityExtracts?.webLinks || []' target='_blank'
			[href]='webLink.url'>
			<me-icon [icon]="getWebLinkIcon(webLink)">
			</me-icon>
		</a>

	</ng-container>

	<div class="company-role-badges-container">
		<div class='company-role-badges-area'>
			<!-- <company-roles [tagIds]='company._companyExtracts.tagIds'
				[associations]='company._companyExtracts.associations || []'>
			</company-roles> -->
		</div>
	</div>

	<kendo-tabstrip class='tabs-only tabs-have-page-background'>
		<kendo-tabstrip-tab [title]="'Info'" [selected]="true">
			<ng-template kendoTabContent>
				<company-extract-info *ngIf='company' [company]="company" [dialogRef]='dialogRef'></company-extract-info>
			</ng-template>
		</kendo-tabstrip-tab>
		<kendo-tabstrip-tab [title]="'Associations'">
			<ng-template kendoTabContent>
				<i>Coming Soon!</i>
			</ng-template>
		</kendo-tabstrip-tab>
	</kendo-tabstrip>

</me-dialog-frame>