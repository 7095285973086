import { NationalDashboardPageService } from '@ADMIN-area';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { CompanyCols, GridColumnType, GridSetup, SELECTION_GRID_ACTION_KEY } from '@me-grid';
import { AppAreaIdentifier } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { GridPart } from '@me-shared-parts/UI-common';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';


interface BusinessRow {
	companyId: number,
	picId: number,
	siteCode: string,
	siteId: number,
	picName: string,
	presentType: string,
	language: string,
	updatedUTC: number,
}

@Component({
	selector: 'shared-pic-teams-view-part',
	templateUrl: './pic-teams-view.part.html',
})
export class SharedPicTeamsViewPart extends DestroyablePart implements OnInit {

	@Input() identifier: AppAreaIdentifier<number>;
	@ViewChild(GridPart) meGrid: GridPart<BusinessRow>;

	public gridSetup = this.setupGrid();
	public rows$: Observable<BusinessRow[]> = of([]);


	constructor(
		private util: UtilityService,
		public ds: DataService,
		public pageService: NationalDashboardPageService
	) {
		super();
	}


	ngOnInit(): void {

		if (!this.identifier) throw new Error(`The identifer attribute was not set for <shared-pic-teams-view-part>`);

		super.initDestroyable();

		this.rows$ = this.ds.admin.singletonsAsOfUTC$.pipe(mergeMap(utc => this.buildRows(this.ds)));
	}



	async buildRows(ds: DataService): Promise<BusinessRow[]> {

		const applications = await ds.admin.application.getAllPackagesAsArray();
		const picTeams = await ds.admin.picTeam.getAllPackagesAsArray();

		if (!applications || !picTeams) return [];
		const allPicApplications = applications.filter(a => a.picId);

		const allPicTeamsMapByApplicationId = this.util.array.toMap(picTeams, team => team.applicationId);


		const rows: BusinessRow[] = allPicApplications.map(application => ({
			companyId: application.companyId,
			picId: application.picId,
			siteCode: application.siteProgram.site.code,
			siteId: application.siteProgram.siteId,
			picName: application.pitchContest.name,
			presentType: allPicTeamsMapByApplicationId[application.applicationId]?.presentType || '',
			language: application.siteProgram.program.language.name,
			updatedUTC: application.updatedUTC,
		}));

		return rows;
	}



	private setupGrid(): GridSetup<BusinessRow> {

		return {
			experience: 'COMPANY',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Business",
			rowPluralName: "Businesses",
			rowKey: "companyId",
			stateKey: "national-pic-businesses",
			canAdd: false,
			canRefresh: false,
			canDownload: false,
			columnsToAdd: [
				{ field: "picId", header: "picId", width: 60, type: GridColumnType.number, hidden: true },
				{ field: "siteId", header: "siteId", width: 60, type: GridColumnType.number, hidden: true },
				{ field: "siteCode", header: "Site", width: 100, type: GridColumnType.text },
				{ field: "picName", header: "Program", width: 150, type: GridColumnType.text, hidden: false },
				{ field: "presentType", header: "Present Type", width: 100, type: GridColumnType.text, hidden: false },
				{ field: "language", header: "Language", width: 100, type: GridColumnType.text, hidden: false },
				{ field: CompanyCols.status, header: 'Status', width: 100, type: GridColumnType.text, hidden: false },

			],
			initialState: {
				sort: [{ field: CompanyCols.name, dir: 'asc' }],
			},
		};
	}



	async gridActionHandler(action: { actionKey: string, rows: BusinessRow[] }) {

		if (action.actionKey == SELECTION_GRID_ACTION_KEY) {
			// Nothing to do with a simple row selection
		}
	}

}