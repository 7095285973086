import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';


@Injectable({ providedIn: 'root' })
export class PageSpinnerService {

	public readonly _value$ = new BehaviorSubject<boolean>(false);
	public value$ = this._value$.asObservable().pipe(distinctUntilChanged());

	private readonly _timerText$ = new BehaviorSubject<string>('');
	public timerText$ = this._timerText$.asObservable().pipe(distinctUntilChanged());
	private startTime: number;
	private timerId: number;

	private readonly _desc$ = new BehaviorSubject<string>('');
	public desc$ = this._desc$.asObservable().pipe(distinctUntilChanged());
	private spinners: { desc: string, start: number }[] = [];
	private spinnerLogs: string[] = [];


	/**
	 * Add a named spinner. The spinner will run until removeSpinner is called with the same desc.
	 */
	public addSpinner(desc: string) {
		this.setSpinnerState(true);
		this.spinners.push({ desc, start: new Date().getTime() });
		this._desc$.next(this.spinners[0].desc)
		if (this.spinners.length == 1) this.startTimer();
	}

	/**
	 * Remove an existing spinner. The desc should be exactly the same as what was passed to addSpinner.
	 */
	public removeSpinner(desc: string) {
		let index = -1;

		for (let i = 0; i < this.spinners.length; i++) {
			if (this.spinners[i].desc == desc) {
				index = i;
				break;
			}
		}

		if (index > -1) {

			const spinner = this.spinners[index];
			this.spinners.splice(index, 1);

			const elapsed = (new Date()).getTime() - spinner.start;

			const lengthIndicator = '▬'.repeat(Math.floor(elapsed / 100));
			const spinnerLog = `${spinner.desc} - ${elapsed.toLocaleString()}ms ${lengthIndicator}`;
			this.spinnerLogs.push(spinnerLog);

			this.writeSpinnerLogToConsole(spinnerLog);
		}

		this.setSpinnerState(this.spinners.length > 0);

		if (this.spinners.length == 0) {
			this.stopTimer();
			this._desc$.next('');
		}
		else {
			this._desc$.next(this.spinners[this.spinners.length - 1].desc);
		}
	}


	/**
	 * Write the full log of spinner activity to the console.
	 */
	public writeSpinnerLogsToConsole() {
		for (const log of this.spinnerLogs) {
			this.writeSpinnerLogToConsole(log);
		}
	}


	/**
	 * Log details of one spinner. If it is the beginning or end of a run from loading singletons then group together.
	 */
	private writeSpinnerLogToConsole(spinnerLog: string) {
		if (spinnerLog.startsWith('Opening ')) console.group(spinnerLog.split(' ')[1]);
		console.log(`ps: ${spinnerLog}`);
		if (spinnerLog.startsWith('Storing ')) console.groupEnd();
	}

	/**
	 * Set the spinner request count to zero and force the spinner to stop.
	 */
	public forceOff() {

		const spinnersForcedOff = '"' + this.spinners.map(spinner => spinner.desc).join('" "') + '"';

		if (this.spinners.length) {
			let msg = `ps: 1 spinner forced off: ${spinnersForcedOff}`;
			if (this.spinners.length > 1) msg = `ps: ${this.spinners.length} spinners forced off: ${spinnersForcedOff}`;

			console.log(msg);
			this.spinnerLogs.push(msg);
		}

		this.spinners = [];
		this.setSpinnerState(false);
		this._desc$.next('');
		this.stopTimer();
	}


	private startTimer() {
		this.startTime = (new Date()).getTime();

		this.stopTimer();

		this.timerId = window.setInterval(() => {
			const elapsed = ((new Date()).getTime() - this.startTime) / 1000;
			this._timerText$.next(elapsed.toFixed(1));
		}, 100);

	}

	private stopTimer() {
		if (this.timerId) {
			window.clearInterval(this.timerId);
			this.timerId = undefined;
		}
	}

	private setSpinnerState(state: boolean) {
		this._value$.next(state);

		const body = document.getElementsByTagName('body')[0];
		const CLASS = 'page-is-spinning';

		if (state) {
			if (!body.classList.contains(CLASS)) body.classList.add(CLASS);
		}
		else {
			if (body.classList.contains(CLASS)) body.classList.remove(CLASS);
		}
	}
}