import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { ApplicationStatusLog } from '@me-interfaces';
import { ApplicationStatusData, getApplicationLog } from './application-status-data';

const ALERT_TYPES: { [index: number]: 'success' | 'info' | 'warning' | 'error' } = {

	1: 'info', 		// ApplicationStatusId.AcceptPending
	2: 'error', 	// ApplicationStatusId.AcceptAbandoned
	3: 'error',		// ApplicationStatusId.AcceptWithdrawn
	4: 'error', 	// ApplicationStatusId.AcceptRejected
	5: 'warning', 	// ApplicationStatusId.AcceptDeferred
	6: 'success', 	// ApplicationStatusId.AcceptCopiedForward

	7: 'info', 		// ApplicationStatusId.ReadPending
	8: 'error', 	// ApplicationStatusId.ReadAbandoned
	9: 'error',		// ApplicationStatusId.ReadWithdrawn
	10: 'error', 	// ApplicationStatusId.ReadRejected
	11: 'warning', 	// ApplicationStatusId.ReadDeferred
	12: 'success', 	// ApplicationStatusId.ReadCopiedForward

	13: 'info', 	// ApplicationStatusId.InterviewPending
	14: 'error', 	// ApplicationStatusId.InterviewAbandoned
	15: 'error',	// ApplicationStatusId.InterviewWithdrawn
	16: 'error', 	// ApplicationStatusId.InterviewRejected
	17: 'warning', 	// ApplicationStatusId.InterviewDeferred
	18: 'success', 	// ApplicationStatusId.InterviewCopiedForward

	19: 'info', 	// ApplicationStatusId.SelectPending
	20: 'error', 	// ApplicationStatusId.SelectAbandoned
	21: 'error',	// ApplicationStatusId.SelectWithdrawn
	22: 'error', 	// ApplicationStatusId.SelectRejected
	23: 'warning', 	// ApplicationStatusId.SelectDeferred
	24: 'success', 	// ApplicationStatusId.SelectCopiedForward

	25: 'info', 	// ApplicationStatusId.TeamPending
	26: 'success', 	// ApplicationStatusId.TeamPromoted

}

@Component({
	selector: 'application-status',
	templateUrl: './SHR-PG_application-status.part.html',
	styleUrls: ['SHR-PG_application-status.part.scss'],
})
export class ApplicationStatusPart extends DestroyablePart implements OnInit, OnChanges {

	@Input() type: 'acc' | 'pic';
	@Input() statusLog: ApplicationStatusLog;
	log: ApplicationStatusData;

	date: string;
	alertType: 'success' | 'info' | 'warning' | 'error' = 'info';

	constructor() { super(); }

	ngOnInit() {
		super.initDestroyable();
	}

	ngOnChanges() {
		if (!this.statusLog) throw new Error('Missing required attribute: log');
		this.log = getApplicationLog(this.statusLog, this.type);
		this.alertType = ALERT_TYPES[this.statusLog.applicationStatusId];
	}

}
