
import { DdTagRow } from './dd-tag-row';
import { DdObject } from '../dd-object';
import { OldDomainDataService } from '../domain-data.service';
import { DdTagPrefix } from '../index'

/**
 * @deprecated Use the DataService instead
 */
export class DdTag extends DdObject<DdTagRow> implements DdTagRow {

	constructor(private dd: OldDomainDataService, data: DdTagRow) {
		super(data);
	}

	public get tagId(): number {
		return this._data.tagId;
	}

	public get tagPrefixId(): number {
		return this._data.tagPrefixId;
	}

	public get tagPrefix(): DdTagPrefix {
		return this.dd.tagPrefixes.getById(this.tagPrefixId);
	}

	public get prefix(): string {
		const tagPrefix = this.dd.tagPrefixes.getById(this.tagPrefixId);
		return tagPrefix? tagPrefix.prefix : undefined;
	}

	public get name(): string {
		return this._data.name;
	}

	public get fullName(): string {
		return `${this.prefix}: ${this._data.name}`;
	}

	public get isAutoTag(): boolean {
		return this._data.isAutoTag;
	}
}