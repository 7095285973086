import { Dbc, EventAttendeeStatus } from '@me-interfaces';


export interface DbcEventAttendee extends Dbc {
	eventId: number,
	personId: number,
	status: EventAttendeeStatus,
	createdUTC: number,
	createdByPersonId: number,
	updatedUTC: number,
	updatedByPersonId: number,
}