import { DbConceptName, Dbd, Immutable, LabelKey, TopicTypeId } from '@me-interfaces';


interface MutableDbdTopic extends Dbd {

	readonly _concept: DbConceptName.Topic,
	readonly topicId: number,
	readonly topicTypeId: TopicTypeId,
	readonly shortNameLabel: string,
	readonly longNameLabel: string,
	readonly goalsEN: string,
	readonly goalsES: string,
	readonly enabled: boolean,
	readonly displayOrder: number,
	readonly needsSpecialist: boolean,
}


export interface DbdTopic extends Immutable<MutableDbdTopic> { }