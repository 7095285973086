import { AccAreaData } from "@ACC-area";
import { DataService } from "@me-services/core/data/data.service";
import { UtilityService } from "@me-services/core/utility";


export interface Participation {
	sessionsWithAtLeastOneAttendee: number,
	sessionAttendeePercent: number,
	sessionsWithAtLeastOneSurvey: number,
	sessionSurveyPercent: number,
	score: number,
}


/**
 * Given the data for one accelerator, determine how well each team participated and calculate an overall score.
 */
export async function calculateParticipation(ds: DataService, util: UtilityService, data: AccAreaData): Promise<Record<number, Participation>> {


	//
	// First get all the session and attendee info
	//
	const allSessions = data.curriculum.sessions ?? [];
	const allAttendees = data.curriculum.sessionAttendees?.filter(a => a.attendee.status == 'Present') ?? [];


	//
	// We need the event for each session to learn which ones have already happened
	//
	const eventIds = allSessions.map(session => session.eventId);
	const eventsById = await ds.admin.event.getManyAsMap(eventIds);


	//
	// We now use each event to filter the list of sessions to just the ones that have happened already
	//
	const now = Date.now() / 1000;

	const pastSessionIds = allSessions
		.filter(s => {
			const event = eventsById[s.eventId];
			if (!event) return false;
			return event.startUTC < now;
		})
		.map(s => s.accSessionId);


	//
	// If we have no past sessionIds then all participation values will be zero.
	// so, we can just return an empty map here.
	//
	if (!pastSessionIds.length) return {};


	//
	// Make a boolean map for quick lookup and then filter the attendees to only past sessions
	//
	const pastSessionIdMap = util.array.toBooleanMap(pastSessionIds);
	const pastAttendees = allAttendees.filter(a => pastSessionIdMap[a.attendee.accSessionId]);


	//
	// Get the set of attendees for each accTeamId and put it in a map of arrays
	//
	const pastAttendeesByAccTeamId = util.array.toArrayMap(pastAttendees, a => a.accTeam.accTeamId);


	//
	// Count all the sessionIds that were attended and surveyed for each accTeamId
	//
	const attendeeInfoByAccTeamId: Record<number, { attendedSessionIds: number[], surveyedSessionIds: number[] }> = {};
	const accTeamIds = util.array.cleanNumericIds(pastAttendees.map(a => a.accTeam.accTeamId));

	for (const accTeamId of accTeamIds) {

		const attendeeInfo = attendeeInfoByAccTeamId[accTeamId] = { attendedSessionIds: [], surveyedSessionIds: [] };
		const pastAttendees = pastAttendeesByAccTeamId[accTeamId] ?? [];

		//
		// Grab all the accSessionIds no deduped
		//
		for (const pastAttendee of pastAttendees) {
			const accSessionId = pastAttendee.attendee.accSessionId;

			attendeeInfo.attendedSessionIds.push(accSessionId);

			if (pastAttendee.accSessionSurveyResponse) attendeeInfo.surveyedSessionIds.push(accSessionId);
		}

		//
		// Remove duplicate accSessionIds so we can get a count of unique values
		//
		attendeeInfo.attendedSessionIds = util.array.cleanNumericIds(attendeeInfo.attendedSessionIds);
		attendeeInfo.surveyedSessionIds = util.array.cleanNumericIds(attendeeInfo.surveyedSessionIds);
	}


	//
	// Make all the calculations and return the details for each accTeamId
	//
	const participationByAccTeamId: Record<number, Participation> = {};

	for (const accTeamId of accTeamIds) {

		const attendeeInfo = attendeeInfoByAccTeamId[accTeamId];

		//
		// Gather the values
		//
		const sessions = pastSessionIds.length;
		const sessionsWithAtLeastOneAttendee = attendeeInfo.attendedSessionIds.length;
		const sessionAttendeePercent = sessionsWithAtLeastOneAttendee / sessions * 100;
		const sessionsWithAtLeastOneSurvey = attendeeInfo.surveyedSessionIds.length;
		const sessionSurveyPercent = sessionsWithAtLeastOneSurvey / sessions * 100;
		const score = sessionAttendeePercent * .7 + sessionSurveyPercent * .3;


		participationByAccTeamId[accTeamId] = {
			sessionsWithAtLeastOneAttendee,
			sessionAttendeePercent: Math.round(sessionAttendeePercent),
			sessionsWithAtLeastOneSurvey,
			sessionSurveyPercent: Math.round(sessionSurveyPercent),

			//
			// Round the score to two decimal places
			//
			score: Math.round(score * 100) / 100,
		};
	}

	
	return participationByAccTeamId;

}