import { AccApplicationContext, AccAreaService } from '@ACC-area';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AccStageId } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { CountRow, CountsTableData } from '@me-shared-parts/UI-common';
import { mergeMap } from 'rxjs/operators';
import { ApplicationGridFilter } from '../../../acc-application-filter-helpers';
import { AccPreAcceleratorStageId, getStageStatuses } from '../../../acc-application-status-helpers';


@Component({
	selector: 'acc-application-status-counts-part',
	templateUrl: './acc-application-status-counts.part.html',
})
export class AccApplicationStatusCountsPart {

	@Input() accStageId: AccPreAcceleratorStageId = AccStageId.Accepting;
	@Output() filter = new EventEmitter<ApplicationGridFilter>();

	data$ = this.accAreaService.applications.applications$.pipe(mergeMap(applications => this.buildRows(applications)));

	constructor(
		private accAreaService: AccAreaService,
		private ds: DataService,
	) {
	}



	async buildRows(applications: readonly AccApplicationContext[]): Promise<CountsTableData> {

		if (!applications) return { header: 'Status', rows: [] };

		const statuses = getStageStatuses(this.accStageId);

		const all: CountRow = { label: 'All', count: 0, percent: 0, personIds: [] };
		const pending: CountRow = { label: statuses.pending.name, count: 0, percent: 0, personIds: [] };
		const abandoned: CountRow = { label: statuses.abandoned.name, count: 0, percent: 0, personIds: [] };
		const withdrawn: CountRow = { label: statuses.withdrawn.name, count: 0, percent: 0, personIds: [] };
		const rejected: CountRow = { label: statuses.rejected.name, count: 0, percent: 0, personIds: [] };
		const deferred: CountRow = { label: statuses.deferred.name, count: 0, percent: 0, personIds: [] };
		const copiedForward: CountRow = { label: statuses.copiedForward.name, count: 0, percent: 0, personIds: [] };
		const accepted: CountRow = { label: statuses.accepted.name, count: 0, percent: 0, personIds: [] };
		const beyond: CountRow = { label: 'Beyond', count: 0, percent: 0, personIds: [] };

		//
		// Count them by status
		//
		for (const app of applications) {

			if (app.application.applicationStatusId < statuses.pending.applicationStatusId) continue;

			all.count++;
			all.personIds.push(app.application.personId);

			if (app.application.applicationStatusId == statuses.pending.applicationStatusId) {
				pending.count++;
				pending.personIds.push(app.application.personId);
			}
			else if (app.application.applicationStatusId == statuses.abandoned.applicationStatusId) {
				abandoned.count++;
				abandoned.personIds.push(app.application.personId);
			}
			else if (app.application.applicationStatusId == statuses.withdrawn.applicationStatusId) {
				withdrawn.count++;
				withdrawn.personIds.push(app.application.personId);
			}
			else if (app.application.applicationStatusId == statuses.rejected.applicationStatusId) {
				rejected.count++;
				rejected.personIds.push(app.application.personId);
			}
			else if (app.application.applicationStatusId == statuses.deferred.applicationStatusId) {
				deferred.count++;
				deferred.personIds.push(app.application.personId);
			}
			else if (app.application.applicationStatusId == statuses.copiedForward.applicationStatusId) {
				copiedForward.count++;
				copiedForward.personIds.push(app.application.personId);
			}
			else if (app.application.applicationStatusId == statuses.accepted.applicationStatusId) {
				accepted.count++;
				accepted.personIds.push(app.application.personId);
			}
			else if (app.application.applicationStatusId > statuses.accepted.applicationStatusId) {
				beyond.count++;
				beyond.personIds.push(app.application.personId);
			}
		}

		//
		// Remove duplicate personId 
		//
		all.personIds = this.ds.util.array.cleanNumericIds(all.personIds);
		pending.personIds = this.ds.util.array.cleanNumericIds(pending.personIds);
		abandoned.personIds = this.ds.util.array.cleanNumericIds(abandoned.personIds);
		withdrawn.personIds = this.ds.util.array.cleanNumericIds(withdrawn.personIds);
		rejected.personIds = this.ds.util.array.cleanNumericIds(rejected.personIds);
		deferred.personIds = this.ds.util.array.cleanNumericIds(deferred.personIds);
		copiedForward.personIds = this.ds.util.array.cleanNumericIds(copiedForward.personIds);
		accepted.personIds = this.ds.util.array.cleanNumericIds(accepted.personIds);
		beyond.personIds = this.ds.util.array.cleanNumericIds(beyond.personIds);

		const result = { header: 'Status', rows: [all], };

		//
		// Set percents
		//
		const total = all.count;
		if (total > 0) {
			all.percent = 100;
			pending.percent = pending.count / total * 100;
			abandoned.percent = abandoned.count / total * 100;
			withdrawn.percent = withdrawn.count / total * 100;
			rejected.percent = rejected.count / total * 100;
			deferred.percent = deferred.count / total * 100;
			copiedForward.percent = copiedForward.count / total * 100;
			accepted.percent = accepted.count / total * 100;
			beyond.percent = beyond.count / total * 100;
		}


		result.rows.push(pending);
		result.rows.push(abandoned);
		result.rows.push(withdrawn);
		result.rows.push(rejected);
		result.rows.push(deferred);
		result.rows.push(copiedForward);
		result.rows.push(accepted);
		result.rows.push(beyond);

		return result;
	}

	countClick(row: CountRow) {
		this.filter.emit({ accStageId: this.accStageId, applicationStatus: 'All', field: 'status', value: row.label });
	}
}