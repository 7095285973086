import { AccAreaService } from '@ACC-area';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { Event, EventTypeId } from '@me-interfaces';
import { UtilityService } from '@me-services/core/utility';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';
import { UrlService } from '@me-services/ui/url';


@Component({
	selector: 'acc-single-event-selector-dialog',
	templateUrl: './single-event-selector.dialog.html',
})
export class AccSingleEventSelectorDialog extends DestroyablePart implements OnInit {
	//
	// The action buttons
	//
	saveAction: DialogAction<number> = {
		id: 'save',
		enabled: true,
		visible: true,
		label: ':Save',
		linkType: 'callback',
		callback: async () => (this.newEventId),
		willCloseDialog: true,
	};

	cancelAction: DialogAction = {
		id: 'close',
		enabled: true,
		visible: true,
		label: ':Cancel',
		linkType: "no-action",
		willCloseDialog: true,
	};

	actions: DialogAction<number | void>[] = [this.saveAction, this.cancelAction];
	icon = 'fa-pen';
	headerText = 'Set Event';
	readonly: boolean;
	orgEventId: number;
	orgEventDate: string;
	newEventId: number;
	events: { eventId: number, numWithDate: string, event: Event }[];
	public defaultEvent: { eventId: number, numWithDate: string, event: Event } = {
		eventId: undefined,
		numWithDate: '',
		event: undefined,
	};



	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ readonly: boolean, eventId: number, eventTypeId: EventTypeId, header?: string }>,
		public dialogRef: MatDialogRef<AccSingleEventSelectorDialog>,
		private util: UtilityService,
		private urlService: UrlService,
		private accAreaService: AccAreaService,
	) {
		super();
	}


	async ngOnInit() {
		super.initDestroyable();

		const { readonly, eventId, eventTypeId, header } = this.dialog.data;
		this.readonly = readonly;
		this.orgEventId = this.newEventId = eventId;
		this.buildEvents(eventTypeId);
		if (header) this.headerText = header;

		this.checkIfCanSave();
	}

	async buildEvents(eventTypeId: EventTypeId) {
		this.events = (await this.accAreaService.getEventsWithNumAndDate(eventTypeId))
			.map(event => ({ eventId: event.event.eventId, numWithDate: event.numWithDate, event: event.event }));

		const eventFound = this.events.find(e => e.eventId == this.orgEventId);
		if (!eventFound) {
			this.orgEventId = this.newEventId = undefined;
		}
		else {
			this.orgEventDate = this.util.date.formatUTC(eventFound.event.startUTC, 'MMM D, YYYY', 'H:MM AM EST', this.urlService.languageId)
		}
	}


	/**
	 * This function is called as the user is typing/pasting/deleting into a field
	 */
	onSelectionChange(e: number | undefined) {
		this.newEventId = e;
		this.checkIfCanSave();
	}


	/**
	 * Look at newMaxReads and determine if the save/update button should be enabled
	 */
	checkIfCanSave() {
		this.saveAction.enabled = !this.readonly && this.newEventId != this.orgEventId;
	}
}