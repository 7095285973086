import { EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { ShowEventDialogService } from "@me-shared-parts/ED-editors";
import { GridSetup } from "../interfaces";
import { AccSurveyResponseSessionGridExperience } from "./acc-survey-by-session-grid-experience";
import { AccSurveyResponseSpecialistGridExperience } from "./acc-survey-by-specialist-grid-experience";
import { AccSurveyResponseTopicGridExperience } from "./acc-survey-by-topic-grid-experience";
import { AccTeamGridExperience } from "./acc-team-grid-experience";
import { AcceleratorGridExperience } from "./accelerator-grid-experience";
import { ApplicationGridExperience } from "./application-grid-experience";
import { AwardGridExperience } from "./award-grid-experience";
import { CompanyGridExperience } from "./company-grid-experience";
import { EventGridExperience } from "./event-grid-experience";
import { GenericGridExperience } from "./generic-grid-experience";
import { GridExperience } from "./grid-experience";
import { PersonGridExperience } from "./person-grid-experience";
import { PicTeamGridExperience } from "./pic-team-grid-experience";
import { PitchContestGridExperience } from "./pitch-contest-grid-experience";
import { RegionGridExperience } from "./region-grid-experience";
import { SiteGridExperience } from "./site-grid-experience";
import { ZipGridExperience } from "./zip-grid-experience";
import { ApplicationEditorService } from '../../../../admin/editor-services/application/application-editor.service';

/**
 * Create a GridExperience instance specifically for the GridBase in the setup 
 */
export function getGridExperience<ROW>(
	ds: DataService,
	util: UtilityService,
	setup: GridSetup<ROW>,
	gridAction: EventEmitter<{ actionKey: string; rows: ROW[]; }>,
	router: Router,
	showEventDialogService: ShowEventDialogService,
	applicationEditorService: ApplicationEditorService,
): GridExperience<ROW> {

	const base = setup.experience;

	if (base == AcceleratorGridExperience.experience) return new AcceleratorGridExperience(ds, util, setup, gridAction, router);
	if (base == AccSurveyResponseSessionGridExperience.experience) return new AccSurveyResponseSessionGridExperience(ds, util, setup, gridAction);
	if (base == AccSurveyResponseSpecialistGridExperience.experience) return new AccSurveyResponseSpecialistGridExperience(ds, util, setup, gridAction);
	if (base == AccSurveyResponseTopicGridExperience.experience) return new AccSurveyResponseTopicGridExperience(ds, util, setup, gridAction);
	if (base == AccTeamGridExperience.experience) return new AccTeamGridExperience(ds, util, setup, gridAction, router);
	if (base == ApplicationGridExperience.experience) return new ApplicationGridExperience(ds, util, setup, gridAction, router, applicationEditorService);
	if (base == AwardGridExperience.experience) return new AwardGridExperience(ds, util, setup, gridAction, router, showEventDialogService);
	if (base == CompanyGridExperience.experience) return new CompanyGridExperience(ds, util, setup, gridAction, router);
	if (base == EventGridExperience.experience) return new EventGridExperience(ds, util, setup, gridAction, showEventDialogService);
	if (base == PersonGridExperience.experience) return new PersonGridExperience(ds, util, setup, gridAction, router);
	if (base == PicTeamGridExperience.experience) return new PicTeamGridExperience(ds, util, setup, gridAction, router);
	if (base == PitchContestGridExperience.experience) return new PitchContestGridExperience(ds, util, setup, gridAction, router);
	if (base == RegionGridExperience.experience) return new RegionGridExperience(ds, util, setup, gridAction, router);
	if (base == SiteGridExperience.experience) return new SiteGridExperience(ds, util, setup, gridAction, router);
	if (base == ZipGridExperience.experience) return new ZipGridExperience(ds, util, setup, gridAction, router);

	return new GenericGridExperience(util, setup, gridAction);
}