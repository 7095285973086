import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';
import { DatePickerComponent } from '@progress/kendo-angular-dateinputs';
import * as moment from 'moment';

@Component({
	selector: 'follow-up-date-dialog',
	templateUrl: './SHR-PG_follow-up-date-dialog.dialog.html',
	styleUrls: ['./SHR-PG_follow-up-date-dialog.dialog.scss']
})
export class FollowUpDateDialog extends DestroyablePart implements OnInit {
	//
	// Configuration for the input fields 
	//
	followUpDate: {
		value: Date,
		changed: boolean,
		pending: Date,
		valid: boolean,
		min: Date,
		max: Date,
	} = {
			value: undefined,
			changed: false,
			pending: undefined,
			valid: true,
			min: undefined,
			max: undefined,
		};

	readonly: boolean;
	errorText: string;

	@ViewChild('dateField') dateField: DatePickerComponent;

	//
	// The action buttons
	//
	saveAction: DialogAction<number | undefined> = {
		id: 'save',
		enabled: false,
		visible: true,
		label: 'Set',
		linkType: 'callback',
		callback: async () => (this.followUpDate.pending ? this.followUpDate.pending.getTime() / 1000 : undefined),
		willCloseDialog: true,
	};

	clearAction: DialogAction<undefined> = {
		id: 'clear',
		enabled: false,
		visible: false,
		label: 'Clear',
		linkType: 'callback',
		callback: async () => (undefined),
		willCloseDialog: true,
	};

	actions: DialogAction<number | undefined>[] = [this.clearAction, this.saveAction];

	constructor(
		@Inject(MAT_DIALOG_DATA) dialog: DialogContext<{ applicationCreatedUTC: number, followUpUTC: number, readonly: boolean }>,
		public dialogRef: MatDialogRef<FollowUpDateDialog>,
	) {
		super();

		const payload = dialog.data;

		if (!payload?.applicationCreatedUTC) throw new Error('Missing required attribute: applicationCreatedUTC');

		this.readonly = payload?.readonly;

		const maxDate = new Date();
		maxDate.setFullYear(maxDate.getFullYear() + 100);
		let followUpDate = undefined;
		if (payload?.followUpUTC) {
			followUpDate = new Date(payload.followUpUTC * 1000);
			this.clearAction.visible = this.clearAction.enabled = !this.readonly;
		}
		this.followUpDate = { ... this.followUpDate, min: new Date(payload.applicationCreatedUTC * 1000), max: maxDate, value: followUpDate, pending: followUpDate };

	}

	ngOnInit() {
		super.initDestroyable();
	}


	/**
	 * Record the pending value and check if it is valid (within the date range)
	 */
	setPendingValue(date: Date | undefined) {
		date = date ?? undefined;

		this.followUpDate.pending = date;

		this.checkIfCanSave(date);
	}

	/**
	 * When losing focus and a pending values was added, that pending value may have been
	 * cleaned (e.g. fixCase) so we update the value with the pending value so it is bound
	 * back into the input field and shown to the user. 
	 */
	valueChange(event: Date) {
		this.errorText = undefined;
		this.followUpDate.changed = true;
		// let value: Date = this.dateField.value || null;

		this.setPendingValue(event);

	}

	/**
	 * Look at each value and determine if the save/update button should be enabled
	 */
	checkIfCanSave(date: Date | undefined) {
		this.saveAction.enabled = false;
		// let value: Date = this.dateField.value || null;
		if (!date) {
			this.errorText = 'Invalid Date';
			return;
		}
		const followUpUTC = Math.round(date.getTime() / 1000);
		const dateUTC = this.followUpDate.value?.getTime();

		if (followUpUTC == dateUTC) return;

		const maxUTC = this.followUpDate.max.getTime();
		const minUTC = this.followUpDate.min.getTime();

		if (followUpUTC < Math.round(minUTC / 1000)) {
			this.errorText = `Date is less than ${moment(minUTC).format("MM/DD/YYYY")}`;
			return;
		}
		if (followUpUTC > Math.round(maxUTC / 1000)) {
			this.errorText = `Date is greater than ${moment(maxUTC).format("MM/DD/YYYY")}`;
			return;
		}
		this.followUpDate.valid = !this.errorText && !!date;


		this.errorText = undefined;

		this.saveAction.enabled = !this.readonly && this.followUpDate.valid;
		this.clearAction.enabled = this.clearAction.visible = !this.readonly && !!this.followUpDate.value;
	}

}