import { AdminAreaRawData } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { AdminAreaPreparedData } from "./admin-area-prepared-data";



export async function prepareAreaData(rawData: AdminAreaRawData, util: UtilityService, ds: DataService): Promise<AdminAreaPreparedData> {

	const data: AdminAreaPreparedData = {
	}

	return data;
}