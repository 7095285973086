import { DdObject } from '../dd-object';
import { OldDomainDataService } from '../domain-data.service';
import { DdAccLanguageSeasonRow } from './dd-acc-season-row';

/**
 * @deprecated Use AccLanguageSeason from the DataService instead
 */
export class DdAccLanguageSeason extends DdObject<DdAccLanguageSeasonRow> implements DdAccLanguageSeasonRow {

	constructor(dd: OldDomainDataService, data: DdAccLanguageSeasonRow) {
		super(data);
	}

	public get name(): string {
		return this.data.name;
	}

	public get accLanguageSeasonId(): number {
		return this.data.accLanguageSeasonId;
	}

	public get longName(): string {
		return this.data.longName;
	}

	public get languageId(): number {
		return this.data.languageId;
	}

	public get order(): number {
		return this.data.order;
	}

}