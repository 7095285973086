import { SharedDashboardViewName, SharedDashboardsService } from '@ADMIN-area';
import { SiteAreaService, SiteDashboardPageService, SiteDashboardViewId as ViewId } from '@SITE-area';
import { Component, Input, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AppAreaIdentifier } from '@me-interfaces';
import { DroplistItem, Icon, getIconContext } from '@me-shared-parts/UI-common';
import { map } from 'rxjs';



@Component({
	selector: 'site-overview-views-part',
	templateUrl: './site-views.part.html',
})
export class SiteViewsPart extends DestroyablePart implements OnInit {

	@Input() readonly: boolean;
	identifier: AppAreaIdentifier<number>;


	viewDroplistItems: DroplistItem<never, ViewId>[] = [
		{ uniqueId: ViewId.Indicators, groupName: 'Site', iconContext: getIconContext(Icon.view_overview, undefined, 'never', 'Dashboard'), customSort: 'Site-1' },
		{ uniqueId: ViewId.Accelerators, groupName: 'Site', iconContext: getIconContext(Icon.concept_accelerator, undefined, undefined, 'Accelerators'), customSort: 'Site-2' },
		{ uniqueId: ViewId.PitchContests, groupName: 'Site', iconContext: getIconContext(Icon.concept_pitchContest, undefined, undefined, 'Pitch Contests'), customSort: 'Site-3' },
		{ uniqueId: ViewId.Applications, groupName: 'Site', iconContext: getIconContext(Icon.concept_application, undefined, undefined, 'Applications'), customSort: 'Site-4' },
		{ uniqueId: ViewId.Businesses, groupName: 'Site', iconContext: getIconContext(Icon.concept_company, undefined, undefined, 'Businesses'), customSort: 'Site-5' },
		{ uniqueId: ViewId.Entrepreneurs, groupName: 'Site', iconContext: getIconContext(Icon.concept_person, undefined, undefined, 'Entrepreneurs'), customSort: 'Site-6' },
		{ uniqueId: ViewId.Events, groupName: 'Site', iconContext: getIconContext(Icon.concept_event, undefined, undefined, 'Events'), customSort: 'Site-7' },
		{ uniqueId: ViewId.Awards, groupName: 'Site', iconContext: getIconContext(Icon.concept_award, undefined, undefined, 'Awards'), customSort: 'Site-8' },
		{ uniqueId: ViewId.HearReport, groupName: 'Reports', iconContext: getIconContext(Icon.view_report, undefined, undefined, 'Hear Report'), customSort: 'Site-rep-1' },
		{ uniqueId: ViewId.VolunteerApplications, groupName: 'Volunteers', iconContext: getIconContext(Icon.concept_application, undefined, undefined, 'Volunteer Applications'), customSort: 'Site-volunteer-1' },
		{ uniqueId: ViewId.VolunteerReaders, groupName: 'Volunteers', iconContext: getIconContext(Icon.view_readers, undefined, undefined, 'Readers'), customSort: 'Site-volunteer-2' },
		{ uniqueId: ViewId.VolunteerInterviewers, groupName: 'Volunteers', iconContext: getIconContext(Icon.view_interviewers, undefined, undefined, 'Interviewers'), customSort: 'Site-volunteer-3' },
		{ uniqueId: ViewId.VolunteerMentors, groupName: 'Volunteers', iconContext: getIconContext(Icon.view_mentors, undefined, undefined, 'Mentors'), customSort: 'Site-volunteer-4' },
		{ uniqueId: ViewId.VolunteerCoaches, groupName: 'Volunteers', iconContext: getIconContext(Icon.view_mentors, undefined, undefined, 'Coaches'), customSort: 'Site-volunteer-5' },
		{ uniqueId: ViewId.VolunteerJudges, groupName: 'Volunteers', iconContext: getIconContext(Icon.view_judges, undefined, undefined, 'Judges'), customSort: 'Site-volunteer-6' },
		{ uniqueId: ViewId.VolunteerSpecialists, groupName: 'Volunteers', iconContext: getIconContext(Icon.concept_person, undefined, undefined, 'Specialists'), customSort: 'Site-volunteer-7' },
		{ uniqueId: ViewId.VolunteerEventhelpers, groupName: 'Volunteers', iconContext: getIconContext(Icon.concept_person, undefined, undefined, 'Event Helpers'), customSort: 'Site-volunteer-8' },
		{ uniqueId: ViewId.ConfigDirectors, groupName: 'Configure', iconContext: getIconContext(Icon.view_config, undefined, undefined, 'Directors'), customSort: 'Site-config-1' },
		{ uniqueId: ViewId.ConfigPrograms, groupName: 'Configure', iconContext: getIconContext(Icon.view_config, undefined, undefined, 'Programs And Managers'), customSort: 'Site-config-2' },
		{ uniqueId: ViewId.ConfigInterns, groupName: 'Configure', iconContext: getIconContext(Icon.view_config, undefined, undefined, 'Interns'), customSort: 'Site-config-3' },
		{ uniqueId: ViewId.ConfigCouncil, groupName: 'Configure', iconContext: getIconContext(Icon.view_config, undefined, undefined, 'Council'), customSort: 'Site-config-4' },
		{ uniqueId: ViewId.ConfigSiteDescription, groupName: 'Configure', iconContext: getIconContext(Icon.view_config, undefined, undefined, 'Site Description (under development)'), customSort: 'Site-config-5' },
		{ uniqueId: ViewId.ConfigZips, groupName: 'Configure', iconContext: getIconContext(Icon.view_config, undefined, undefined, 'Zip Codes'), customSort: 'Site-config-6' },
	];

	selectedViewItem$ = this.pageService.selectedView$.pipe(
		map(viewId => this.viewDroplistItems.find(item => item.uniqueId == viewId)),
	);

	constructor(
		private pageService: SiteDashboardPageService,
		private siteAreaService: SiteAreaService,
		public sharedViewsService: SharedDashboardsService,
	) {
		super();
		this.identifier = siteAreaService.getId();
		sharedViewsService.identifier$.next(this.identifier);
	}


	ngOnInit(): void {

		super.initDestroyable();

		super.subscribe([this.siteAreaService.accessAndId$], async ([accessAndId]) => {
			this.readonly = accessAndId?.access?.root != 'Write';
			this.identifier = accessAndId?.id;
		})
	}


	onViewDroplistChange(selectedView: DroplistItem) {
		this.pageService.selectView(selectedView.uniqueId);
		this.sharedViewsService.view$.next(this.getSharedView(selectedView));
	}


	getSharedView(viewItem: DroplistItem<never, ViewId>): SharedDashboardViewName {

		if (!viewItem?.uniqueId) return undefined;

		const viewId = viewItem.uniqueId;

		if (viewId == ViewId.Indicators) return 'indicators';
		// if (viewId == ViewId.Regions) return 'regions';
		// if (viewId == ViewId.Sites) return 'sites';
		// if (viewId == ViewId.Businesses) return 'businesses';
		// if (viewId == ViewId.Entrepreneurs) return 'entrepreneurs';
		// if (viewId == ViewId.Awards) return 'awards';
		// if (viewId == ViewId.Events) return 'events';
		// if (viewId == ViewId.Accs) return 'accs';
		// if (viewId == ViewId.AccApplications) return 'acc-applications';
		// if (viewId == ViewId.AccTeams) return 'acc-teams';
		// if (viewId == ViewId.AccReaders) return 'acc-readers';
		// if (viewId == ViewId.AccInterviewers) return 'acc-interviewers';
		// if (viewId == ViewId.AccMentors) return 'acc-mentors';
		// if (viewId == ViewId.AccJudges) return 'acc-judges';
		// if (viewId == ViewId.Pics) return 'pics';
		// if (viewId == ViewId.PicApplications) return 'pic-applications';
		// if (viewId == ViewId.PicTeams) return 'pic-teams';
		// if (viewId == ViewId.PicReaders) return 'pic-readers';
		// if (viewId == ViewId.PicJudges) return 'pic-judges';

		return undefined;
	}


	showView(view: string, viewItem: DroplistItem<never, ViewId>) {

		if (!viewItem?.uniqueId) return false;

		const viewId = viewItem.uniqueId;

		if (viewId == ViewId.Indicators && view == 'Dashboard') return true;
		if (viewId == ViewId.Accelerators && view == 'Accelerators') return true;
		if (viewId == ViewId.PitchContests && view == 'PitchContests') return true;
		if (viewId == ViewId.Applications && view == 'Applications') return true;
		if (viewId == ViewId.Businesses && view == 'Businesses') return true;
		if (viewId == ViewId.Entrepreneurs && view == 'Entrepreneurs') return true;
		if (viewId == ViewId.Events && view == 'Events') return true;
		if (viewId == ViewId.Awards && view == 'Awards') return true;
		if (viewId == ViewId.HearReport && view == 'HearReport') return true;
		if (viewId == ViewId.VolunteerApplications && view == 'VolunteerApplications') return true;
		if (viewId == ViewId.VolunteerReaders && view == 'VolunteerReaders') return true;
		if (viewId == ViewId.VolunteerInterviewers && view == 'VolunteerInterviewers') return true;
		if (viewId == ViewId.VolunteerMentors && view == 'VolunteerMentors') return true;
		if (viewId == ViewId.VolunteerCoaches && view == 'VolunteerCoaches') return true;
		if (viewId == ViewId.VolunteerJudges && view == 'VolunteerJudges') return true;
		if (viewId == ViewId.VolunteerSpecialists && view == 'VolunteerSpecialists') return true;
		if (viewId == ViewId.VolunteerEventhelpers && view == 'VolunteerEventhelpers') return true;
		if (viewId == ViewId.ConfigDirectors && view == 'ConfigDirectors') return true;
		if (viewId == ViewId.ConfigPrograms && view == 'ConfigPrograms') return true;
		if (viewId == ViewId.ConfigInterns && view == 'ConfigInterns') return true;
		if (viewId == ViewId.ConfigCouncil && view == 'ConfigCouncil') return true;
		if (viewId == ViewId.ConfigSiteDescription && view == 'ConfigSiteDescription') return true;
		if (viewId == ViewId.ConfigZips && view == 'ConfigZips') return true;

		return false;
	}
}