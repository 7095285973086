import { Accelerator, AccJudge, DbConceptName, Person, PicJudge, PitchContest } from "@me-interfaces";
import { DataService } from "../../../../data.service";
import { ExplorerBox, ExplorerBoxClass } from "../../../explorer-box/explorer-box";

interface JudgeData {
	applStartUTC: number,
	siteName: string,
	siteId: number,
	siteCode: string,
	judgedAccTeamIds: number[],
	accelerator?: Accelerator,
	pitchContest?: PitchContest
}


export async function getBoxesForJudgement(ds: DataService, person: Person): Promise<ExplorerBox[]> {

	const boxes: ExplorerBox[] = [];

	const accJudgesByPersonId = await ds.admin.accJudge.getByPersonIds([person.personId]);
	const accJudges = accJudgesByPersonId[person.personId].filter(judge => judge.judgedAccTeamIds.length > 0);

	const picJudgesByPersonId = await ds.admin.picJudge.getByPersonIds([person.personId]);
	const picJudges = picJudgesByPersonId[person.personId].filter(judge => judge.judgedPicTeamIds.length > 0);

	const judges: JudgeData[] = getJudgeData([...accJudges, ...picJudges]);


	for (const judge of judges) {

		const box: ExplorerBox = {
			explorerBoxClass: ExplorerBoxClass.Judgement,
			header: 'Judge',
			buttons: [
				{ explorer: { explorableId: { conceptName: DbConceptName.Site, id: judge.siteId }, navTooltip: `Site: ${judge.siteName}` } },
			],
			notes: `Teams Judged: ${judge.judgedAccTeamIds.length}`,
		};

		if (judge.accelerator) box.buttons.push(
			{ explorer: { nameOverride: `${judge.accelerator.name} Accelerator`, explorableId: { conceptName: DbConceptName.Accelerator, id: judge.accelerator.accId }, navTooltip: `Accelerator: ${judge.siteCode} ${judge.accelerator.name}` } },
		);

		if (judge.pitchContest) box.buttons.push(
			{ explorer: { nameOverride: `${judge.pitchContest.name} Pitch Contest`, explorableId: { conceptName: DbConceptName.PitchContest, id: judge.pitchContest.picId }, navTooltip: `Pitch Contest: ${judge.siteCode} ${judge.pitchContest.name}` } },
		);

		boxes.push(box);
	}

	return boxes;


	/**
	 * Get sorted desc array of all different program types for judges
	 * @param judges 
	 * @returns 
	 */
	function getJudgeData(judges: (PicJudge | AccJudge)[]) {
		const data: JudgeData[] = [];
		for (const judge of judges) {
			if (judge._concept == DbConceptName.AccJudge) {
				const site = judge.accelerator.siteProgram.site;
				data.push({
					applStartUTC: judge.accelerator.applStartUTC,
					siteName: site.name,
					siteId: site.siteId,
					siteCode: site.code,
					judgedAccTeamIds: [...judge.judgedAccTeamIds],
					accelerator: judge.accelerator,
				});
			}
			else if (judge._concept == DbConceptName.PicJudge) {
				const site = judge.pitchContest.siteProgram.site;
				data.push({
					applStartUTC: judge.pitchContest.applStartUTC,
					siteName: site.name,
					siteId: site.siteId,
					siteCode: site.code,
					judgedAccTeamIds: [...judge.judgedPicTeamIds],
					pitchContest: judge.pitchContest,
				});
			}


		}
		return data.sort((a, b) => b.applStartUTC - a.applStartUTC);
	}
}