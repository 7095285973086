import { Injectable } from "@angular/core";
import { DataService } from "@me-services/core/data";
import { FuncService } from "@me-services/core/func";
import { DialogAction } from "@me-services/ui/dialog";
import { EditTextDialog } from "@me-shared-parts/UI-common/dialogs";

@Injectable({
	providedIn: 'root',
})
export class ApplicationEditorService {


	constructor(private ds: DataService, private func: FuncService) {
	}

	/**
	 * Open a simple text viewing/editing dialog.
	 *
	 * @param text The text to display.
	 * @returns 
	 */
	public async launchNoteDialog(
		applicationId: number,
		notes: string,
		header: string,
		readonly = false,
	): Promise<{ text: string }> {

		const action: DialogAction<{ text: string }> = await this.ds.dialogService.showCustom(
			EditTextDialog,
			{
				data: { text: notes, readonly, header, },
			},
			350, 220
		);

		const id = action?.id;
		if (id == 'save') {
			if (readonly) return;
			if (action.callbackResult) {
				//
				// Update notes
				//
				const notes = action.callbackResult.text;

				await this.updateNotes(applicationId, notes);
			}
		}
		return;
	}


	private async updateNotes(applicationId: number, notes: string) {
		const { singletonsData, singletonsCacheUTC } = await this.func.singletonEditors.application.setNotes({ applicationId, notes });
		await this.ds.mergeResponseSingletons(singletonsData, singletonsCacheUTC);
	}
}