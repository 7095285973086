import { DbsPerson, RelationshipType } from "@me-interfaces";
import { OldDomainDataService } from '@me-services/core/old-dd';
import { FuncService } from "@me-services/core/func";

/** @deprecated DO NOT USE THIS!*/
export class PersonDoNotContactNote {

	constructor(private readonly person: DbsPerson, private func: FuncService, private dd: OldDomainDataService) {
	}

	public async update(note: string) {
		const person = await this.func.person.updateDoNotContactNote({ note, affectedPersonId: this.person.personId });
		//@ts-ignore
		this.person = Object.assign(this.person, person);
	}

	public async remove() {
		const person = await this.func.person.updateDoNotContactNote({ note: null, affectedPersonId: this.person.personId });
		//@ts-ignore
		this.person = Object.assign(this.person, person);
	}
}