import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DbsPerson, PersonProfileEmailExtract } from '@me-interfaces';
import { OldEntityService } from '@me-services/old-services-and-wrappers/entity';
import { ConstantsService } from '@me-services/core/constants';
import { FuncService } from '@me-services/core/func';
import { DialogService } from '@me-services/ui/dialog';


@Component({
	selector: 'person-emails-editor',
	templateUrl: './SHR-ED_person-emails-editor.part.html',
	styleUrls: ['SHR-ED_person-emails-editor.part.scss'],
})
export class PersonEmailsEditorPart implements OnInit {
	@Input() person: DbsPerson;

	emails: PersonProfileEmailExtract[] = [];
	emailSelection: number;
	addForm: FormGroup;
	needNonEforAllEmail = false;
	showAddForm = false;
	updating: { [index: number]: boolean } = {};
	adding = false;
	deleting: { [index: number]: boolean } = {};


	constructor(
		private entityService: OldEntityService,
		public constants: ConstantsService,
		private func: FuncService,
		private fb: FormBuilder,
		private dialogService: DialogService,
	) { }


	ngOnInit() {
		this.createAddForm();
		this.load()
	}


	private load() {
		this.emails = [...this.person._profileExtracts.emails];
		this.emails = this.emails.sort((a, b) => a.email > b.email ? 1 : -1);
		this.addForm.reset({ email: undefined });
	}


	private createAddForm() {
		this.addForm = this.fb.group({
			email: ['', [Validators.required, Validators.pattern(this.constants.EMAIL_REGEX)], this.constants.validators.validateEmailNotInUse(this.func)],
		});
	}


	public async makePrimary(emailId: number) {
		this.updating[emailId] = true;
		await this.entityService.wrapPerson(this.person).email.setPrimary(emailId);
		this.load();
		this.updating[emailId] = false;
	}


	public async delete(emailId: number) {

		this.deleting[emailId] = true;

		const msg = `Do you want to remove "${this.emails.find(e => e.emailId == emailId).email}" from ${this.person._name}?`;
		const yes = await this.dialogService.confirm(msg);

		if (yes) {
			await this.entityService.wrapPerson(this.person).email.remove(emailId);
			this.load();
		}

		this.deleting[emailId] = false;
	}


	public async add() {
		this.adding = true;
		await this.entityService.wrapPerson(this.person).email.add(this.addForm.value.email);
		this.showAddForm = false;
		this.load();
		this.adding = false;
	}


	public cancel() {
		this.showAddForm = false;
		this.addForm.reset();
	}
}
