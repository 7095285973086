import { AppAreaName } from "@me-interfaces";
import { DashboardData } from "./interfaces";

/**
 * Create and return a DashboardData with all empty values
 */
export function getEmptyDashboardData(areaName: AppAreaName): DashboardData {

	const data: DashboardData = {

		areaName,

		englishAccelerators: false,
		spanishAccelerators: false,
		englishPitchContests: false,
		spanishPitchContests: false,


		indicators: {
			totals: {
				regions: 0,
				sites: 0,
				staff: 0,
				boardMembers: 0,
				zipCodes: 0,

				accelerators: 0,
				interviewers: 0,
				interviews: 0,
				mentors: 0,
				mentorships: 0,
				mentorQuits: 0,
				mentorRatio: 0,
				teamsGraduated: 0,
				teamsGraduatable: 0,
				teamGraduationRate: 0,
				entrepreneursGraduated: 0,
				teamsCompleted: 0,
				teamsCompletable: 0,
				teamCompletionRate: 0,
				entrepreneursCompleted: 0,
				acc3s: 0,
				acc3Businesses: 0,
				acc3BusinessesOpen: 0,
				acc3BusinessesOpenRate: 0,

				pitchContests: 0,

				readers: 0,
				readings: 0,
				judges: 0,
				judgments: 0,
				accApplicationsOpen: 0,
				accApplicationsClosed: 0,
				accApplicationsSubmitted: 0,
				accApplicationsAccepted: 0,
				accApplicationAcceptanceRate: 0,
				accEntrepreneursAccepted: 0,
				picApplicationsOpen: 0,
				picApplicationsClosed: 0,
				picApplicationsSubmitted: 0,
				picApplicationsAccepted: 0,
				picApplicationAcceptanceRate: 0,
				picEntrepreneursAccepted: 0,
				awardees: 0,
				awardedValue: 0,
			},

			spanishAccs: {
				accelerators: 0,
				interviewers: 0,
				interviews: 0,
				mentors: 0,
				mentorships: 0,
				mentorQuits: 0,
				mentorRatio: 0,
				teamsGraduated: 0,
				teamsGraduatable: 0,
				teamGraduationRate: 0,
				entrepreneursGraduated: 0,
				teamsCompleted: 0,
				teamsCompletable: 0,
				teamCompletionRate: 0,
				entrepreneursCompleted: 0,
				acc3s: 0,
				acc3Businesses: 0,
				acc3BusinessesOpen: 0,
				acc3BusinessesOpenRate: 0,

				readers: 0,
				readings: 0,
				judges: 0,
				judgments: 0,
				accApplicationsOpen: 0,
				accApplicationsClosed: 0,
				accApplicationsSubmitted: 0,
				accApplicationsAccepted: 0,
				accApplicationAcceptanceRate: 0,
				accEntrepreneursAccepted: 0,
				awardees: 0,
				awardedValue: 0,
			},

			spanishPics: {
				pitchContests: 0,

				readers: 0,
				readings: 0,
				judges: 0,
				judgments: 0,
				picApplicationsOpen: 0,
				picApplicationsClosed: 0,
				picApplicationsSubmitted: 0,
				picApplicationsAccepted: 0,
				picApplicationAcceptanceRate: 0,
				picEntrepreneursAccepted: 0,
				awardees: 0,
				awardedValue: 0,
			},

			englishAccs: {
				accelerators: 0,
				interviewers: 0,
				interviews: 0,
				mentors: 0,
				mentorships: 0,
				mentorQuits: 0,
				mentorRatio: 0,
				teamsGraduated: 0,
				teamsGraduatable: 0,
				teamGraduationRate: 0,
				entrepreneursGraduated: 0,
				teamsCompleted: 0,
				teamsCompletable: 0,
				teamCompletionRate: 0,
				entrepreneursCompleted: 0,
				acc3s: 0,
				acc3Businesses: 0,
				acc3BusinessesOpen: 0,
				acc3BusinessesOpenRate: 0,

				readers: 0,
				readings: 0,
				judges: 0,
				judgments: 0,
				accApplicationsOpen: 0,
				accApplicationsClosed: 0,
				accApplicationsSubmitted: 0,
				accApplicationsAccepted: 0,
				accApplicationAcceptanceRate: 0,
				accEntrepreneursAccepted: 0,
				awardees: 0,
				awardedValue: 0,
			},

			englishPics: {
				pitchContests: 0,

				readers: 0,
				readings: 0,
				judges: 0,
				judgments: 0,
				picApplicationsOpen: 0,
				picApplicationsClosed: 0,
				picApplicationsSubmitted: 0,
				picApplicationsAccepted: 0,
				picApplicationAcceptanceRate: 0,
				picEntrepreneursAccepted: 0,
				awardees: 0,
				awardedValue: 0,
			},
		},

		overall: {
			regionIds: [],
			siteIds: [],
			companyIds: [],
			entrepreneurPersonIds: [],
			awardIds: [],
			eventIds: [],
		},

		accelerator: {
			accIds: [],
			accApplicationIds: [],
			accTeamIds: [],
			accEntrepreneurPersonIds: [],
			accReaders: [],
			accInterviewerIds: [],
			accJudgeIds: [],
			accMentorPersonIds: [],
		},

		pitchContest: {
			picIds: [],
			picApplicationIds: [],
			picTeamIds: [],
			picEntrepreneurPersonIds: [],
			picReaderIds: [],
			picJudgeIds: [],
		}
	};

	return data;
}