/**
 * Given an array with four numbers representing counts of N!, N, Y, Y! ratings,
 * build and return a string that is suitable for the Ratings grid column type.
 */
export function formatForGridCell(ratings: number[]): string {

	if (!ratings) return '';

	const r1 = ratings[0] ?? 0;
	const r2 = ratings[1] ?? 0;
	const r3 = ratings[2] ?? 0;
	const r4 = ratings[3] ?? 0;

	return [formatRatingAsString(r1), formatRatingAsString(r2), formatRatingAsString(r3), formatRatingAsString(r4)].join('-');
}

/**
 * Convert the number to string of length seven with space padding added to the front 
 */
function formatRatingAsString(rating: number): string {

	rating = Math.floor(rating);

	const t = "       " + rating.toLocaleString();
	return t.slice(-7);
}