import { AdminAreaService } from '@ADMIN-area-service';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { DbConceptName } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { Icon, getIconContext } from '@me-shared-parts/UI-common';

const CEO_PERSONID = 19140;
@Component({
	selector: 'national-toolbar',
	templateUrl: `./national-toolbar.part.html`,
	styleUrls: [`./national-toolbar.part.scss`],
})
export class NationalToolbarPart extends DestroyablePart implements OnInit {

	icons = {
		ceo: getIconContext(Icon.concept_person, undefined, 'lt-sm', 'CEO'),
		// coo: getIconContext(Icon.concept_person, undefined, 'lt-sm', 'COO'),
	}




	constructor(
		private ds: DataService,
		public adminAreaService: AdminAreaService,
	) {
		super();
	}

	async ngOnInit() {

		super.initDestroyable();

		const ceo = await this.ds.admin.person.getOne(CEO_PERSONID);
		// const coo = await this.ds.admin.person.getOne(this.cooPersonId);

		this.icons.ceo.text = `CEO: ${ceo._name}`;
		// this.icons.coo.text = `COO: ${coo._name}`;

	}


	async inspectCEO() {
		await this.ds.explorer.explore(DbConceptName.Person, CEO_PERSONID);
	}

}