import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { CommonIndicatorValues } from "../interfaces";


/**
 * @returns Just the common programming values
 */
export async function getCommonIndicatorValues(
	ds: DataService,
	util: UtilityService,
	accIds: number[],
	picIds: number[],
): Promise<CommonIndicatorValues> {

	//
	// Gather related objects
	//
	const applications = (await ds.admin.application.getAllAsArray()).filter(a => accIds.includes(a.accId) || picIds.includes(a.picId));
	const applicationIds = applications.map(a => a.applicationId);

	const accTeams = (await ds.admin.accTeam.getAllPackagesAsArray()).filter(t => accIds.includes(t.accId));
	const accTeamByAccTeamId = util.array.toMap(accTeams, t => t.accTeamId);
	const accTeamIds = accTeams.map(t => t.accTeamId);

	const picTeams = (await ds.admin.picTeam.getAllPackagesAsArray()).filter(t => picIds.includes(t.picId));
	const picTeamByPicTeamId = util.array.toMap(picTeams, t => t.picTeamId);
	const picTeamIds = picTeams.map(t => t.picTeamId);


	//
	// Readers (acc + pic)
	//
	const accReadersByAccId = await ds.admin.accReader.getByAccIds(accIds);
	const accReaders = util.array.fromArrayMap(accReadersByAccId, accIds).filter(r => r.readApplicationIds.length > 0);
	const accReaderPersonIds = accReaders.map(r => r.personId);
	const picReadersByPicId = await ds.admin.picReader.getByPicIds(picIds);
	const picReaders = util.array.fromArrayMap(picReadersByPicId, picIds).filter(r => r.readApplicationIds.length > 0);
	const picReaderPersonIds = picReaders.map(r => r.personId);

	const readers = util.array.cleanNumericIds([...accReaderPersonIds, ...picReaderPersonIds]).length;


	//
	// Readings
	//
	let readings = 0;
	for (const accReader of accReaders) { readings += accReader.readApplicationIds.filter(id => applicationIds.includes(id)).length; }
	for (const picReader of picReaders) { readings += picReader.readApplicationIds.filter(id => applicationIds.includes(id)).length; }


	//
	// Judges (acc + pic)
	//
	const accJudgesByAccId = await ds.admin.accJudge.getByAccIds(accIds);
	const accJudges = util.array.fromArrayMap(accJudgesByAccId, accIds).filter(j => j.judgedAccTeamIds.length > 0);
	const accJudgePersonIds = accJudges.map(j => j.personId);
	const picJudgesByPicId = await ds.admin.picJudge.getByPicIds(picIds);
	const picJudges = util.array.fromArrayMap(picJudgesByPicId, picIds).filter(j => j.judgedPicTeamIds.length > 0);
	const picJudgePersonIds = picJudges.map(j => j.personId);

	const judges = util.array.cleanNumericIds([...accJudgePersonIds, ...picJudgePersonIds]).length;


	//
	// Judgments
	//
	let judgments = 0;
	for (const accJudge of accJudges) { judgments += accJudge.judgedAccTeamIds.filter(id => accTeamIds.includes(id)).length; }
	for (const picJudge of picJudges) { judgments += picJudge.judgedPicTeamIds.filter(id => picTeamIds.includes(id)).length; }


	//
	// Awards
	//
	const awards = (await ds.admin.award.getAllAsArray()).filter(a => accTeamIds.includes(a.accTeamId) || picTeamIds.includes(a.picTeamId));
	const awardeeCompanyIds: number[] = [];

	let awardedValue = 0;
	for (const award of awards) {
		awardedValue += award.value;
		if (award.accTeamId) awardeeCompanyIds.push(accTeamByAccTeamId[award.accTeamId]?.company.companyId);
		if (award.picTeamId) awardeeCompanyIds.push(picTeamByPicTeamId[award.picTeamId]?.company.companyId);
	}

	const awardees = util.array.cleanNumericIds(awardeeCompanyIds).length;





	return {
		readers,
		readings,
		judges,
		judgments,
		awardees,
		awardedValue,
	};
}