import { Injectable } from "@angular/core";
import { AccAreaAccess, AccAreaRawData, Accelerator, AccStageId, AccTeamGoalsAndAccess, AppAreaIdentifierWithSite, AppAreaIdentifierWithSiteId, Event, EventTypeId } from "@me-interfaces";
import { AppAreaService } from "@me-services/core/area";
import { DataService } from "@me-services/core/data";
import { DexieService } from "@me-services/core/dexie";
import { FuncService } from "@me-services/core/func";
import { UtilityService } from "@me-services/core/utility";
import { PageSpinnerService } from "@me-services/ui/page-spinner";
import { UrlService } from "@me-services/ui/url";
import { UserAreaService } from "@me-user-area";
import { combineLatest, lastValueFrom, map, Observable, Subject, take } from "rxjs";
import { AccAreaData_old } from "./acc-area-data";
import { AccSubAreaAccelerator } from "./acc-subarea-accelerator";
import { AccSubAreaApplications } from "./acc-subarea-applications";
import { AccSubAreaInterviewing } from "./acc-subarea-interviewing";
import { AccSubAreaJudging } from "./acc-subarea-judging";
import { AccSubAreaMentorMatching } from "./acc-subarea-mentor-matching";
import { AccSubAreaReading } from "./acc-subarea-reading";
import { AccSubAreaTeams } from "./acc-subarea-teams";
import { mapToData } from "./map-to-data";


const NO_ACCESS: AccAreaAccess = {
	root: 'None',
	sub: {
		assessments: 'None',
		demographics: 'None',
		removeTeam: 'None',
	},
};


export interface AccessAtStage {
	access: 'None' | 'Read' | 'Write',
	canUpdateApplicationStatuses: boolean,
	canViewDemographics: boolean,
	currentStageId: AccStageId,
	currentStageIs: 'Before' | 'Same' | 'After',
}


@Injectable({
	providedIn: 'root',
})
export class OldAccAreaService extends AppAreaService<AppAreaIdentifierWithSiteId<number>, AccAreaAccess, AccAreaRawData, AccAreaData_old> {


	/**
	 * Accelerator Sub Area - Data and Actions related to the accelerator.
	 */
	public readonly accelerator = {
		acc$: super.mapSubset(data => data.acc),
		events$: super.mapSubset(data => data.events),
		awards$: super.mapSubset(data => data.awards),

		actions: new AccSubAreaAccelerator(this.func, this.ds, this.util, super.getId.bind(this), this.applyResponse.bind(this)),
	};


	/**
	 * Applications Sub Area - Data and Actions related to applications.
	 */
	public readonly applications = {
		applications$: super.mapSubset(data => data.application.applications),
		statusLogs$: super.mapSubset(data => data.application.statusLogs),

		actions: new AccSubAreaApplications(this.func, this.ds, this.util, super.getId.bind(this), this.applyResponse.bind(this)),
	};


	/**
	 * Teams Sub Area - Data and Actions related to teams.
	 */
	public readonly teams = {
		teams$: super.mapSubset(data => data.team.teams),
		goals$: super.mapSubset(data => data.team.goals),

		actions: new AccSubAreaTeams(this.func, this.ds, this.util, super.getId.bind(this), this.applyResponse.bind(this)),
	};


	/**
	 * Reading Sub Area - Data and Actions related to readers.
	 */
	public readonly reading = {
		readers$: super.mapSubset(data => data.reading.readers),
		assessments$: super.mapSubset(data => data.reading.assessments),
		defaultQuestions$: super.mapSubset(data => data.reading.defaultQuestions),

		actions: new AccSubAreaReading(this.func, this.ds, this.util, super.getId.bind(this), this.applyResponse.bind(this)),
	};


	/**
	 * Interviewing Sub Area - Data and Actions related to interviewers.
	 */
	public readonly interviewing = {
		interviewers$: super.mapSubset(data => data.interviewing.interviewers),
		interviewerEvents$: super.mapSubset(data => data.interviewing.interviewerEvents),
		assessments$: super.mapSubset(data => data.interviewing.assessments),
		defaultQuestions$: super.mapSubset(data => data.interviewing.defaultQuestions),

		actions: new AccSubAreaInterviewing(this.func, this.ds, this.util, super.getId.bind(this), this.applyResponse.bind(this)),
	};


	/**
	 * Mentor Matching Sub Area - Data and Actions related to matching mentors.
	 */
	public readonly mentorMatching = {
		matchableMentors$: super.mapSubset(data => data.mentorMatching.matchableMentors),
		mentorsRollup$: super.mapSubset(data => data.mentorMatching.mentorsRollup),
		teamsRollup$: super.mapSubset(data => data.mentorMatching.teamsRollup),
		assessments$: super.mapSubset(data => data.mentorMatching.assessments),
		hints$: super.mapSubset(data => data.mentorMatching.hints),

		// hasAssignedMentors$: combineLatest([this.ds.admin.singletonsAsOfUTC$, this.accelerator.acc$])
		// 	.pipe(mergeMap(([utc, acc]) => {
		// 		return true;

		// 		this.ds.admin.accTeam
		// 	})),

		actions: new AccSubAreaMentorMatching(this.func, this.ds, this.util, super.getId.bind(this), this.applyResponse.bind(this)),
	};


	/**
	 * Judging Sub Area - Data and Actions related to judges.
	 */
	public readonly judging = {
		judges$: super.mapSubset(data => data.judging.judges),
		judgeEvents$: super.mapSubset(data => data.judging.judgeEvents),
		assessments$: super.mapSubset(data => data.judging.assessments),
		defaultQuestions$: super.mapSubset(data => data.judging.defaultQuestions),

		actions: new AccSubAreaJudging(this.func, this.ds, this.util, super.getId.bind(this), this.applyResponse.bind(this)),
	};


	constructor(
		urlService: UrlService,
		dexieService: DexieService,
		ds: DataService,
		spinnerService: PageSpinnerService,
		util: UtilityService,
		private func: FuncService,
		userAreaService: UserAreaService,
	) {
		super(
			'Staff',
			userAreaService.user$,
			'AccOld',
			dexieService,
			ds,
			spinnerService,
			util,
			urlService.appAreas.siteAccId$,
			func.areas.acc.get,
			async () => ({ area: 'AccOld', access: NO_ACCESS, md5Hash: 'NO_ACCESS' }),
			async rawData => await mapToData(rawData, util, ds),
			data => data.acc.name,
		);
	}



	/**
	 * @deprecated Use super.subscribe(accelerator.acc$) instead.
	 * 
	 * Should always be called in ngOnInit() after a call to this.initDestroyable();
	 * Subscribe to 'acc' changes and automatically unsubscribe when destroyed$ is triggered.
	 */
	public subscribeAcc(destroyed$: Subject<void>, callback: (value: Accelerator) => Promise<void>) {
		super._subscribe(this.accelerator.acc$, destroyed$, callback);
	}


	/**
	 * @deprecated Use super.subscribe(teams.goals$) instead.
	 * 
	 * Should always be called in ngOnInit() after a call to this.initDestroyable();
	 * Subscribe to 'teams' changes and automatically unsubscribe when destroyed$ is triggered.
	 */
	public subscribeTeamGoals(destroyed$: Subject<void>, callback: (value: AccTeamGoalsAndAccess[]) => Promise<void>) {
		super._subscribe(this.teams.goals$, destroyed$, callback);
	}


	/**
	 * Return an observable that emits a user's calculated access at a provided stage using an accessKey.  
	 * It determines how the provided stage relates to the actual accelerator stage and takes that into
	 * account, combining it with the actual access level.
	 * @param accStageId The stage to use to determiine access. This may be different than the accelerator's actual current stage.
	 * @param accessKey Either 'root' or a sub access. Default is root.
	 */
	public getAccessAtStage(accStageId: AccStageId, accessKey = 'root'): Observable<AccessAtStage> {

		const x = combineLatest([this.accelerator.acc$, this.accessAndId$])
			.pipe(
				map(([acc, accessAndId]): AccessAtStage => {

					if (acc == undefined || accessAndId == undefined) {
						return {
							access: 'None',
							canUpdateApplicationStatuses: false,
							canViewDemographics: false,
							currentStageIs: 'Before',
							currentStageId: AccStageId.Setup,
						};
					}

					const currentStageIs: 'Before' | 'Same' | 'After' = acc.accStageId < accStageId ? 'Before' : acc.accStageId == accStageId ? 'Same' : 'After';

					let access = accessAndId.access?.root == 'Invalid' ? 'None' : accessAndId.access?.root;
					if (accessKey !== 'root') access = accessAndId.access?.sub[accessKey] ?? 'None';

					const canUpdateApplicationStatuses = access == 'Write';
					if (access == 'Write' && currentStageIs == 'After') access = 'Read';

					const canViewDemographics = accessAndId.access?.sub.demographics == 'Read' || accessAndId.access?.sub.demographics == 'Write';

					return {
						access,
						canUpdateApplicationStatuses,
						canViewDemographics,
						currentStageId: acc.accStageId,
						currentStageIs,
					};
				})
			);

		return x;
	}


	/**
	 * Given an eventType, return the event with the calculated event # based on the sort
	 * also, return the numwithdate string.
	 * @param eventTypeId 
	 */
	public async getEventsWithNumAndDate(eventTypeId: EventTypeId, events: readonly Event[] = []): Promise<{ event: Event, num: number, numWithDate: string }[]> {

		if (!events.length) events = await lastValueFrom(this.accelerator.events$.pipe(take(1)));
		return events.filter(event => event.eventTypeId == eventTypeId)
			.sort((a, b) => {
				if (a.startUTC == b.startUTC) return a.eventId > b.eventId ? 1 : -1;
				return a.startUTC - b.startUTC;
			})
			.map((event, i) => ({ event, num: i + 1, numWithDate: `${i + 1} - ${this.util.date.formatUTC(event.startUTC, 'MM/DD/YYYY', 'H:MM AM EST', this.ds.languageId)}` }));

	}

}