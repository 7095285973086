import { Dba, DbConceptName, Immutable } from '@me-interfaces';


interface MutableDbaAccReading extends Dba {

	readonly _concept: DbConceptName.AccReading,

	accReaderId: number,
	applicationId: number,
	answersId: number,
	rating: number,
	polishedFeedback: string,
	polishedFeedbackSkipped: boolean,
}


export interface DbaAccReading extends Immutable<MutableDbaAccReading> { }