import { LanguageId, Old_MinimalContact, Old_SiteProgram } from '@me-interfaces';

/**
 * @deprecated Use Accelerator from the DataService instead
 */
export interface Old_Accelerator {
	accId: number,
	accStageId: number,
	accStage: string,
	siteProgramId: number,
	siteProgram: Old_SiteProgram,
	languageId: LanguageId,
	name: string,
	director: Old_MinimalContact,
	manager: Old_MinimalContact,
	applStartUTC: number,
	applDuration: number,
}