import { Injectable } from '@angular/core';
import { PublicPersonForDialog } from '@me-interfaces';
import { DialogData, DialogService } from '@me-services/ui/dialog';
import { PublicPersonDialog } from './SHR-PN_public-person-dialog.dialog';


@Injectable({ providedIn: 'root' })
/**
 * Provides utilities for launching public person extract dialog
 */
export class ShowPublicPersonDialogService {

	constructor(private dialogService: DialogService) { }


	/**
	 * Launches the extract dialog for assigned DbsPerson
	 */
	public async show(person: PublicPersonForDialog): Promise<any | undefined> {

		const data: DialogData<any> = { data: { person } };
		await this.dialogService.showCustom(PublicPersonDialog, data, 600, 275);

		return undefined;
	}

}