import { DexieService } from "@me-services/core/dexie";
import { UtilityService } from "@me-services/core/utility";
import { UrlService } from "@me-services/ui/url";
import { Observable } from "rxjs";
import { DomainDataManagers, SingletonManagers } from "../interfaces";
import { AccInterviewerPackageManager } from "../package-managers/acc-interviewer";
import { AccJudgePackageManager } from "../package-managers/acc-judge";
import { AccReaderPackageManager } from "../package-managers/acc-reader";
import { AccSessionSpecialistSurveyResponsePackageManager } from "../package-managers/acc-session-specialist-survey-response";
import { AccSessionSurveyResponsePackageManager } from "../package-managers/acc-session-survey-response";
import { AccSessionTopicSurveyResponsePackageManager } from "../package-managers/acc-session-topic-survey-response";
import { AccTeamPackageManager } from "../package-managers/acc-team";
import { AcceleratorPackageManager } from "../package-managers/accelerator";
import { ApplicationPackageManager } from "../package-managers/application";
import { AwardPackageManager } from "../package-managers/award";
import { CompanyPackageManager } from "../package-managers/company";
import { EventPackageManager } from "../package-managers/event";
import { PersonPackageManager } from "../package-managers/person";
import { PicJudgePackageManager } from "../package-managers/pic-judge";
import { PicReaderPackageManager } from "../package-managers/pic-reader";
import { PicTeamPackageManager } from "../package-managers/pic-team";
import { PitchContestPackageManager } from "../package-managers/pitch-contest";
import { ProgramPackageManager } from "../package-managers/program";
import { RegionPackageManager } from "../package-managers/region";
import { SitePackageManager } from "../package-managers/site";
import { SiteProgramPackageManager } from "../package-managers/site-program";
import { SpecialistPackageManager } from "../package-managers/specialist";
import { TagPackageManager } from "../package-managers/tag";
import { TagPrefixPackageManager } from "../package-managers/tag-prefix";
import { VenuePackageManager } from "../package-managers/venue";
import { AgreementSignaturePackageManager } from "../package-managers/agreement-signature";
import { CouncilMemberPackageManager } from "../package-managers/council-member";


export function initSingletonPackages(
	dexie: DexieService,
	domain: DomainDataManagers,
	sm: SingletonManagers,
	singletonsAsOfUTC$: Observable<number>,
	notifySingletonsChanged: () => Promise<void>,
	util: UtilityService,
	urlService: UrlService,
) {

	//
	// Build a package manager for each singleton
	//
	const program = new ProgramPackageManager(singletonsAsOfUTC$, util, sm.dbsProgram, domain, sm.dbsPerson);
	const site = new SitePackageManager(singletonsAsOfUTC$, util, sm.dbsSite, domain, sm.dbsPerson);
	const region = new RegionPackageManager(singletonsAsOfUTC$, util, sm.dbsRegion, domain, sm.dbsPerson);
	const siteProgram = new SiteProgramPackageManager(singletonsAsOfUTC$, util, sm.dbsSiteProgram, domain, sm.dbsPerson, program, site);
	const accelerator = new AcceleratorPackageManager(singletonsAsOfUTC$, util, sm.dbsAccelerator, urlService, domain, sm.dbsPerson, siteProgram);
	const pitchContest = new PitchContestPackageManager(singletonsAsOfUTC$, util, sm.dbsPitchContest, domain, sm.dbsPerson, siteProgram);
	const venue = new VenuePackageManager(singletonsAsOfUTC$, util, sm.dbsVenue, domain, sm.dbsPerson);
	const event = new EventPackageManager(singletonsAsOfUTC$, util, sm.dbsEvent, domain, sm.dbsPerson, venue, site, accelerator, pitchContest, notifySingletonsChanged, dexie);
	const application = new ApplicationPackageManager(singletonsAsOfUTC$, util, sm.dbsApplication, domain, sm.dbsPerson, sm.dbsCompany, accelerator, pitchContest, siteProgram, sm.dbsApplicationParticipant, sm.dbsEntityNote);
	const tagPrefix = new TagPrefixPackageManager(singletonsAsOfUTC$, util, sm.dbsTagPrefix, domain, sm.dbsPerson);
	const tag = new TagPackageManager(singletonsAsOfUTC$, util, sm.dbsTag, domain, sm.dbsPerson, tagPrefix, site);
	const person = new PersonPackageManager(singletonsAsOfUTC$, util,
		sm.dbsPerson, domain, sm.dbsPersonTag, tag, sm.dbsEmail, sm.dbsWebLink, sm.dbsEntityNote, sm.dbsRelationship,
		sm.dbsApplication, sm.dbsApplicationParticipant,
		sm.dbsAccelerator, sm.dbsAccTeam, sm.dbsAccTeamMember, sm.dbsAccReader, sm.dbsAccInterviewer, sm.dbsAccJudge,
		sm.dbsPitchContest, sm.dbsPicTeam, sm.dbsPicTeamMember, sm.dbsPicReader, sm.dbsPicJudge, sm.dbsAward);

	const accReader = new AccReaderPackageManager(singletonsAsOfUTC$, util, sm.dbsAccReader, domain, person, accelerator);
	const accInterviewer = new AccInterviewerPackageManager(singletonsAsOfUTC$, util, sm.dbsAccInterviewer, domain, person, accelerator);
	const picReader = new PicReaderPackageManager(singletonsAsOfUTC$, util, sm.dbsPicReader, domain, person, pitchContest);
	const company = new CompanyPackageManager(singletonsAsOfUTC$, util, sm.dbsCompany, domain, person, sm.dbsPosition, sm.dbsWebLink, sm.dbsEntityNote, sm.dbsApplication, sm.dbsAccTeam, sm.dbsPicTeam, sm.dbsAward);
	const accTeam = new AccTeamPackageManager(singletonsAsOfUTC$, util, sm.dbsAccTeam, domain, sm.dbsPerson, company, application, accelerator, sm.dbsAccTeamMember, sm.dbsAward, sm.dbsEntityNote);
	const picTeam = new PicTeamPackageManager(singletonsAsOfUTC$, util, sm.dbsPicTeam, domain, sm.dbsPerson, company, application, pitchContest, sm.dbsPicTeamMember, sm.dbsAward, sm.dbsEntityNote);
	const accJudge = new AccJudgePackageManager(singletonsAsOfUTC$, util, sm.dbsAccJudge, domain, person, accelerator);
	const picJudge = new PicJudgePackageManager(singletonsAsOfUTC$, util, sm.dbsPicJudge, domain, person, pitchContest);
	const specialist = new SpecialistPackageManager(singletonsAsOfUTC$, util, sm.dbsSpecialist, domain, person, site);
	const award = new AwardPackageManager(singletonsAsOfUTC$, util, sm.dbsAward, domain, sm.dbsPerson, event, accTeam, picTeam);
	const accSessionSurveyResponse = new AccSessionSurveyResponsePackageManager(singletonsAsOfUTC$, util, sm.dbsAccSessionSurveyResponse, accelerator, event);
	const accSessionTopicSurveyResponse = new AccSessionTopicSurveyResponsePackageManager(singletonsAsOfUTC$, util, sm.dbsAccSessionTopicSurveyResponse, accelerator, event, domain);
	const accSessionSpecialistSurveyResponse = new AccSessionSpecialistSurveyResponsePackageManager(singletonsAsOfUTC$, util, sm.dbsAccSessionSpecialistSurveyResponse, person, accelerator, event, domain);
	const agreementSignature = new AgreementSignaturePackageManager(singletonsAsOfUTC$, util, sm.dbsAgreementSignature, sm.dbsPerson, sm.dbsAgreementVersion, sm.dbsAgreementType);
	const councilMember = new CouncilMemberPackageManager(singletonsAsOfUTC$, util, sm.dbsCouncilMember, sm.dbsPerson, sm.dbsSite);


	//
	// IMPORTANT: The property names of the returned object MUST be an entry in the DbConceptName enum.
	//
	return {
		accelerator,
		accInterviewer,
		accJudge,
		accReader,
		accSessionSurveyResponse,
		accSessionTopicSurveyResponse,
		accSessionSpecialistSurveyResponse,
		accTeam,
		accTeamMember: sm.dbsAccTeamMember,
		agreementType: sm.dbsAgreementType,
		agreementVersion: sm.dbsAgreementVersion,
		agreementSignature,
		application,
		applicationParticipant: sm.dbsApplicationParticipant,
		award,
		company,
		councilMember,
		email: sm.dbsEmail,
		entityNote: sm.dbsEntityNote,
		event,
		person,
		personTag: sm.dbsPersonTag,
		picJudge,
		picReader,
		picTeam,
		picTeamMember: sm.dbsPicTeamMember,
		pitchContest,
		position: sm.dbsPosition,
		program,
		region,
		relationship: sm.dbsRelationship,
		siteProgram,
		site,
		specialist,
		tag,
		tagPrefix,
		venue,
		webLink: sm.dbsWebLink,
	}
}