<div class="acc-team-members-edit-entrepreneur-dialog">

	<me-dialog-frame header='Edit Entrepreneur' [actions]='actions' [dialogRef]='dialogRef' [icon]='icon' alertType='editor'>

		<div class='acc-team-members-edit-entrepreneur-dialog-content' *ngIf='!readonly'>

			<table style='width:100%'
				class='pro-table pro-table-no-header pro-table-with-labels pro-table-without-border'>

				<tr>
					<td>Entrepreneur</td>
					<td>
						{{memberName}}
					</td>
				</tr>

				<tr>
					<td>Role</td>
					<td>
						<me-droplist [ngClass]="selectedItem === null ? 'ng-invalid' : ''" *ngIf="items" [items]='items'
							[selectedItem]='selectedItem' (selectionChange)='valueChange($event)' [readonly]='readonly'>
						</me-droplist>
					</td>
				</tr>
			</table>

		</div>

	</me-dialog-frame>
</div>