import {
	Accelerator, AccMatchingRollups,
	AccMatchableMentor,
	AccTeam,
	DbaAccMatchAssessment,
	SevenNums
} from '@me-interfaces';
import { hammingWeight, MAX_SLOT_VALUE, slotsToMeetingStarts } from '@me-services/ui/schedule-utils';


export interface AccMMRollupData {
	meetingTimes: SevenNums,
	assessmentsMap: { [key: string]: [number | undefined, number | undefined] },
	teamsRollup: { [key: number]: AccMatchingRollups },
	mentorsRollup: { [key: number]: AccMatchingRollups },
}

export function calcRollups(acc: Accelerator, mentors: AccMatchableMentor[], teams: AccTeam[], matchAssessments: DbaAccMatchAssessment[]): { teamsRollup: { [key: number]: AccMatchingRollups }, mentorsRollup: { [key: number]: AccMatchingRollups }, } {
	const assessments = matchAssessments.filter(assessment => mentors.map(m => m.personId).includes(assessment.personId) && teams.map(t => t.accTeamId).includes(assessment.accTeamId));
	const data: AccMMRollupData = {
		meetingTimes: [acc.accMeetingTimes.mon, acc.accMeetingTimes.tue, acc.accMeetingTimes.wed, acc.accMeetingTimes.thur, acc.accMeetingTimes.fri, acc.accMeetingTimes.sat, acc.accMeetingTimes.sun],
		assessmentsMap: {},
		mentorsRollup: {},
		teamsRollup: {},
	};
	addMaps(assessments, data);
	addEmptyRollups(mentors, teams, data);
	determineDefaultSchedules(mentors, teams, data);
	countOverlaps(mentors, teams, data);
	calcAvailability(mentors, teams, data);
	countAssessments(mentors, teams, assessments, data);

	return { teamsRollup: data.teamsRollup, mentorsRollup: data.mentorsRollup };
}

function addMaps(assessments: DbaAccMatchAssessment[], data: AccMMRollupData) {

	data.assessmentsMap = assessments.reduce((map, assessment) => {
		map[`${assessment.personId}-${assessment.accTeamId}`] = [assessment.m2t, assessment.t2m];
		return map;
	}, {});


}

function addEmptyRollups(mentors: AccMatchableMentor[], teams: AccTeam[], data: AccMMRollupData) {
	for (const team of teams) {
		data.teamsRollup[team.accTeamId] = {
			availability: 0,
			overlaps: 0,
			medianMeetings: 0,
			assessmentsGiven: [0, 0, 0, 0],
			assessmentsRecvd: [0, 0, 0, 0],
			assessmentsGivenPercent: 0,
			positivityGiven: 0,
			positivityRecvd: 0,
			roleCountOfMeetings: 0,
			totalMeetings: 0,
			hasDefaultSchedule: true,
		}
	}

	for (const mentor of mentors) {
		data.mentorsRollup[mentor.personId] = {
			availability: 0,
			overlaps: 0,
			medianMeetings: 0,
			assessmentsGiven: [0, 0, 0, 0],
			assessmentsRecvd: [0, 0, 0, 0],
			assessmentsGivenPercent: 0,
			positivityGiven: 0,
			positivityRecvd: 0,
			roleCountOfMeetings: 0,
			totalMeetings: 0,
			hasDefaultSchedule: true,
		}
	}
}


function positiveBothWays(
	data: AccMMRollupData,
	personId: number,
	accTeamId: number): boolean {

	const a = data.assessmentsMap[`${personId}-${accTeamId}`] || [undefined, undefined];
	return ((a[0] || 5) >= 3 && (a[1] || 5) >= 3);
}

/**
 * Schedule overlap meetings count array for mentors and enterpreneurs(m2e,e2m)
 */
export function calcOverlap(sched: SevenNums, scheds: SevenNums[]): number[] {

	const schedMeetingStarts = sched.map(day => slotsToMeetingStarts(day));

	const results: number[] = [];
	for (const sc of scheds) {
		let c = 0;
		for (let d = 0; d < 7; d++) {
			if (sc[d] == 0) continue;
			c += hammingWeight(schedMeetingStarts[d] & slotsToMeetingStarts(sc[d]));
		}
		if (c) results.push(c);
	}
	return results;
}


function countOverlaps(mentors: AccMatchableMentor[], teams: AccTeam[], data: AccMMRollupData) {

	for (const team of teams) {
		const scheds: SevenNums[] = mentors
			.filter(m => positiveBothWays(data, m.personId, team.accTeamId))
			.map(m => {
				return data.mentorsRollup[m.personId].hasDefaultSchedule ? <SevenNums>[0, 0, 0, 0, 0, 0, 0] : <SevenNums>m.schedule;
			});

		const counts = data.teamsRollup[team.accTeamId].hasDefaultSchedule ? [] : calcOverlap(<SevenNums>team.schedule, scheds);
		data.teamsRollup[team.accTeamId].overlaps = counts.length;
		data.teamsRollup[team.accTeamId].medianMeetings = median(counts);
	}

	for (const mentor of mentors) {
		const scheds: SevenNums[] = teams
			.filter(t => positiveBothWays(data, mentor.personId, t.accTeamId))
			.map(t => {
				return data.teamsRollup[t.accTeamId].hasDefaultSchedule ? <SevenNums>[0, 0, 0, 0, 0, 0, 0] : <SevenNums>t.schedule;
			});

		const counts = data.mentorsRollup[mentor.personId].hasDefaultSchedule ? [] : calcOverlap(mentor.schedule, scheds);
		data.mentorsRollup[mentor.personId].overlaps = counts.length;
		data.mentorsRollup[mentor.personId].medianMeetings = median(counts);
	}
}

function determineDefaultSchedules(mentors: AccMatchableMentor[], teams: AccTeam[], data: AccMMRollupData) {
	const unblockout = data.meetingTimes.map(b => MAX_SLOT_VALUE - b);

	for (const team of teams) {
		data.teamsRollup[team.accTeamId].hasDefaultSchedule = true;
		for (const day of [0, 1, 2, 3, 4, 5, 6]) {
			if ((team.schedule[day] & unblockout[day]) !== unblockout[day]) {
				data.teamsRollup[team.accTeamId].hasDefaultSchedule = false;
				break;
			}
		}
	}

	for (const mentor of mentors) {
		data.mentorsRollup[mentor.personId].hasDefaultSchedule = true;
		for (const day of [0, 1, 2, 3, 4, 5, 6]) {
			if ((mentor.schedule[day] & unblockout[day]) !== unblockout[day]) {
				data.mentorsRollup[mentor.personId].hasDefaultSchedule = false;
				break;
			}
		}
	}
}

function countMeetingTimes(sched: SevenNums): number {
	return sched.reduce((c, slots) => {
		return c + hammingWeight(slotsToMeetingStarts(slots));
	}, 0);
}

function calcAvailability(mentors: AccMatchableMentor[], teams: AccTeam[], data: AccMMRollupData) {
	const totalMeetings = data.meetingTimes.reduce((c, day) => {
		// Blockouts have the bits that represent the half-hour slots of unavailable time set.
		// We subtract from the max value (all bits set) to flip the bits.
		return c + hammingWeight(slotsToMeetingStarts(MAX_SLOT_VALUE - day));
	}, 0);


	if (totalMeetings == 0) return 0;

	for (const team of teams) {
		data.teamsRollup[team.accTeamId].totalMeetings = totalMeetings;
		const countOfMeeting = data.teamsRollup[team.accTeamId].hasDefaultSchedule ? 0 : countMeetingTimes(<SevenNums>team.schedule);
		data.teamsRollup[team.accTeamId].availability = data.teamsRollup[team.accTeamId].hasDefaultSchedule ? 0 : Math.round(countOfMeeting / totalMeetings * 100);
		data.teamsRollup[team.accTeamId].roleCountOfMeetings = countOfMeeting;
	}

	for (const mentor of mentors) {
		data.mentorsRollup[mentor.personId].totalMeetings = totalMeetings;
		const countOfMeeting = data.mentorsRollup[mentor.personId].hasDefaultSchedule ? 0 : countMeetingTimes(mentor.schedule);
		data.mentorsRollup[mentor.personId].availability = data.mentorsRollup[mentor.personId].hasDefaultSchedule ? 0 : Math.round(countOfMeeting / totalMeetings * 100);
		data.mentorsRollup[mentor.personId].roleCountOfMeetings = countOfMeeting;
	}
}

function calcPositivity(ratings: [number, number, number, number]): number {
	const weights = [0, 1 / 3, 2 / 3, 1];
	let sum = 0;
	let count = 0;
	for (const i of [0, 1, 2, 3]) {
		sum += ratings[i] * weights[i];
		count += ratings[i];
	}

	if (count == 0) return 0;
	return Math.round(sum / count * 100);
}

function countAssessments(mentors: AccMatchableMentor[], teams: AccTeam[], assessments: DbaAccMatchAssessment[], data: AccMMRollupData) {

	//
	// Store the assessment counts with each team and mentor
	//
	for (const assessment of assessments) {
		if (assessment.m2t) {
			data.mentorsRollup[assessment.personId].assessmentsGiven[assessment.m2t - 1]++;
			data.teamsRollup[assessment.accTeamId].assessmentsRecvd[assessment.m2t - 1]++;
		}
		if (assessment.t2m) {
			data.teamsRollup[assessment.accTeamId].assessmentsGiven[assessment.t2m - 1]++;
			data.mentorsRollup[assessment.personId].assessmentsRecvd[assessment.t2m - 1]++;
		}
	}

	//
	// Calculate the percentage of assessments given compared to how many they are supposed to do.
	//
	if (mentors.length) {
		for (const team of teams) {
			const count = data.teamsRollup[team.accTeamId].assessmentsGiven.reduce((c, asmCount) => c + asmCount, 0);
			data.teamsRollup[team.accTeamId].assessmentsGivenPercent = Math.round(count / mentors.length * 100);
		}
	}

	if (teams.length) {
		for (const mentor of mentors) {
			const count = data.mentorsRollup[mentor.personId].assessmentsGiven.reduce((c, asmCount) => c + asmCount, 0);
			data.mentorsRollup[mentor.personId].assessmentsGivenPercent = Math.round(count / teams.length * 100);
		}
	}

	//
	// Determine the positivity
	//
	for (const team of teams) {
		data.teamsRollup[team.accTeamId].positivityGiven = calcPositivity(data.teamsRollup[team.accTeamId].assessmentsGiven);
		data.teamsRollup[team.accTeamId].positivityRecvd = calcPositivity(data.teamsRollup[team.accTeamId].assessmentsRecvd);
	}

	for (const mentor of mentors) {
		data.mentorsRollup[mentor.personId].positivityGiven = calcPositivity(data.mentorsRollup[mentor.personId].assessmentsGiven);
		data.mentorsRollup[mentor.personId].positivityRecvd = calcPositivity(data.mentorsRollup[mentor.personId].assessmentsRecvd);
	}
}



export function median(values: number[]) {
	if (values.length == 0) return 0;
	values.sort((a, b) => a - b);

	const half = Math.floor(values.length / 2);

	if (values.length % 2) return values[half];
	else return (values[half - 1] + values[half]) / 2.0;
}