import { VolunteerApplicationRawData } from "@me-interfaces";

export interface SitesAreaRawData {

	permissions: {
		personId: number,
		flag: string,
		admin: string,
		edCommunities: string,
		pmEsCommunities: string,
		pmEnCommunities: string,
		internCommunities: string,
	}[],

	mentorCounts: {
		accId: number,
		unique: number,
		uses: number,
		usedOnTeam: number,
	}[],

	goalCounts: {
		accTeamId: number,
		numGoals: number,
	}[],

	openVolunteerApplications: VolunteerApplicationRawData[],
}