import { ProgramPageService } from '@ADMIN-area';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { DBLCLICK_GRID_ACTION_KEY, GridAction, GridSetup } from '@me-grid';
import { DbdTopicMaterial, LanguageId } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { GridPart, Icon } from '@me-shared-parts/UI-common';
import { GridColumnConfig, buildColumn } from '@me-shared-parts/UI-common/grid/columns';


interface TrainingMaterialRow extends DbdTopicMaterial {
	topicMaterialType: string,
	topic: string,
	topicIsActive: boolean,
	topicDisplayOrder: number,
	updatedUTC: number,
}

@Component({
	selector: 'training-materials-by-language-part',
	templateUrl: './training-materials-by-language.part.html',
})
export class TrainingMaterialsByLanguagePart extends DestroyablePart implements OnInit {

	@Input() languageId: LanguageId;

	public gridSetup = this.setupGrid();

	public rows: TrainingMaterialRow[] = [];

	@ViewChild(GridPart) meGrid: GridPart<TrainingMaterialRow>;

	constructor(
		public ds: DataService,
		private util: UtilityService,
		public pageService: ProgramPageService
	) {
		super();
	}

	ngOnInit(): void {

		super.initDestroyable();

		if (!this.languageId) throw new Error('Missing required attribute: languageId');

		this.rows = this.buildRows();

	}


	buildRows(): TrainingMaterialRow[] {

		const topicMaterials = this.ds.domain.topicMaterial.getAll();
		const topicMaterialTypesMap = this.ds.domain.topicMaterialType.getAllAsMap();
		const topicsMap = this.ds.domain.topic.getAllAsMap();


		return topicMaterials.filter(material => material.languageId == this.languageId).reduce((a: TrainingMaterialRow[], topicMaterial) => {

			const topic = topicsMap[topicMaterial.topicId];
			if (topic.enabled) {
				a.push({
					...topicMaterial,
					topic: topic.longNameLabel,
					topicDisplayOrder: topic.displayOrder,
					topicMaterialType: topicMaterialTypesMap[topicMaterial.topicMaterialTypeId].name,
					updatedUTC: Date.now(),
					topicIsActive: topic.enabled,
				});
			}
			return a;
		}, []).sort((a, b) => {
			if (a.topicDisplayOrder == b.topicDisplayOrder) {
				return a.displayOrder > b.displayOrder ? 1 : -1;
			}
			return a.topicDisplayOrder > b.topicDisplayOrder ? 1 : -1;
		});
	}



	private setupGrid(): GridSetup<TrainingMaterialRow> {

		const topicInActiveFlag = (row: TrainingMaterialRow) => {
			return !row.topicIsActive;
		};

		const topicInActiveText = (row: TrainingMaterialRow) => {

			if (!row.topicIsActive) return `Topic In-Active.`;
			else return undefined;
		};

		return {
			experience: 'none',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Training Material",
			rowPluralName: "Training Materials",
			rowKey: "topicMaterialId",
			stateKey: "program-training-material",
			canAdd: false,
			canRefresh: false,
			canDownload: false,
			columnsToAdd: [
				buildColumn('topicMaterialId', GridColumnConfig.id, { hidden: true, header: 'topicMaterialId', width: 100 }),
				buildColumn('topicId', GridColumnConfig.id, { hidden: true, header: 'topicId', width: 100 }),
				buildColumn('languageId', GridColumnConfig.id, { hidden: true, header: 'languageId', width: 100 }),
				buildColumn('topicMaterialTypeId', GridColumnConfig.id, { hidden: true, header: 'topicMaterialTypeId', width: 100 }),
				buildColumn('topicDisplayOrder', GridColumnConfig.number, { hidden: true, header: 'Topic Order', width: 100 }),
				buildColumn('topic', GridColumnConfig.text, { header: 'Topic', width: 200, hidden: true, strikethrough: topicInActiveFlag, tooltip: topicInActiveText }),
				buildColumn('displayOrder', GridColumnConfig.number, { header: 'Order', width: 100, hidden: true, }),
				buildColumn('name', GridColumnConfig.text, { header: 'Name', width: 200, strikethrough: topicInActiveFlag, tooltip: topicInActiveText }),
				buildColumn('topicMaterialType', GridColumnConfig.text, { header: 'Type', width: 100 }),
				buildColumn('url', GridColumnConfig.text, { header: 'URL', width: 350, hidden: true, }),
				buildColumn('isWorksheet', GridColumnConfig.boolean, { header: 'Worksheet', width: 100, hidden: true, headerTooltip: 'Worksheets are documents intended to be copied and then editied by the entrepeneur.' }),
				buildColumn('code', GridColumnConfig.text, { header: 'Code', width: 100, hidden: true, headerTooltip: 'This is an internal code used by the program experience team.' }),
				buildColumn('isStaffOnly', GridColumnConfig.text, { header: 'Staff Only', width: 100, hidden: true }),
			],
			initialState: {
				group: [{ field: 'topic' }]
			},
			actions: [
				{ key: 'open-material', icon: Icon.link_website, label: 'Open Material', enabled: false },
				{ key: 'copy-url', icon: Icon.action_clipboard, label: 'Copy URL', enabled: false },
			],
			actionEnabler: this.gridActionEnabler.bind(this),
		};
	}

	gridActionEnabler(action: GridAction, rows: TrainingMaterialRow[]) {
		if (rows.length == 0) return false;

		return true;
	}

	async gridActionHandler(action: { actionKey: string, rows: TrainingMaterialRow[] }) {
		const row = action.rows[0];

		if (action.actionKey == 'open-material' || action.actionKey == DBLCLICK_GRID_ACTION_KEY) {
			window.open(row.url);
		}
		if (action.actionKey == 'copy-url') {
			await this.util.clipboard.writeText(row.url);
			await this.ds.dialogService.showMessage(`The following text has been copied to the clipboard.\n\n${row.url}`, 400, 250);
		}

	}


}