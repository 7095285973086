import { Component, Input, OnInit } from '@angular/core';
import { RouteMonitorPart } from '@me-ACS-parts';
import { UtilityService } from '@me-services/core/utility';
import { ActivatedRoute, Params } from '@angular/router';
import { NetPromoterScore } from '@me-interfaces';
import { FuncService } from '@me-services/core/func';

@Component({
	selector: 'alum-promoter',
	templateUrl: './SHR-CMN_alum-promoter.part.html',
	styleUrls: ['SHR-CMN_alum-promoter.part.scss'],
})
export class AlumPromoterPart extends RouteMonitorPart implements OnInit {

	@Input() accTeamId: number;
	netPromoter?: NetPromoterScore;
	saving = false;

	constructor(private util: UtilityService, private route: ActivatedRoute, private func: FuncService) {
		super(route, util);
	}

	ngOnInit() {
		this.routeParamsChanged();

	}
	async routeParamsChanged() {
		const accTeamId = this.accTeamId;
		this.netPromoter = await this.func.public.alumReport.getNps({ accTeamId });
	}

	async scoreChanged(value) {
		this.saving = true;
		const accTeamId = this.accTeamId;
		this.netPromoter = await this.func.public.alumReport.setNps({ accTeamId, value });
		this.saving = false;
	}
}
