import { DbsPerson, SiteAreaRawData } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { SiteAreaData } from "./interfaces/site-area-data";

//
// transform the raw data returned from the action function to
// data that can be used by the Angular app.
//
export async function mapToData(rawData: SiteAreaRawData, util: UtilityService, ds: DataService): Promise<SiteAreaData> {


	const personIds = [
		...rawData.volunteerApplications.map(va => va.application.personId),
		...rawData.volunteerApplications.map(va => va.closedByPersonId),
	];


	//Get personIds from rawData.offeredSitePrograms
	for (const sp of rawData.offeredSitePrograms) {
		personIds.push(...sp.internIdsWithAccess);
	}


	const people = await ds.admin.person.getManyPackagesAsMap(personIds);



	const data: SiteAreaData = {

		site: await ds.admin.site.getOnePackage(rawData.siteId),

		interns: rawData.interns,

		offeredSitePrograms: rawData.offeredSitePrograms,

		volunteerApplications: rawData.volunteerApplications.map(va => ({
			siteVolunteerApplication: va,
			applicant: people[va.application.personId],
		})),

		applicantHearReport: rawData.hearReport,

	};

	return data;
}