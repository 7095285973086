import { NamedConcept } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { getIconContext } from "./get-icon-context";
import { getIconForConcept } from "./get-icon-for-concept";
import { IconContext } from "./icon-context";
import { IconHideTextAt } from "./icon-hide-text-at";
import { IconStyle } from "./icon-style";


export function getConceptIcon(
	ds: DataService,
	concept: NamedConcept,

	/**
	 * A standard FontAwesome style.
	 * Note that this is a suggestion.
	 * Some icons ignore this.
	 */
	iconStyle: IconStyle = 'fa-regular',
	
	/**
	 * Define the breakpoint where the text changes from being right of the
	 * icon to being hidden but added as a tooltip.
	 */
	hideText: IconHideTextAt = 'never',

): IconContext {

	const name = ds.getConceptName(concept);
	const icon = getIconForConcept(concept);

	return getIconContext(icon, iconStyle, hideText, name);
}