import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-ACS-parts';
import { DataService } from '@me-services/core/data';


@Component({
	selector: 'site-volunteer-reader-view-part',
	templateUrl: './reader.part.html',
})
export class SiteVolunteerReaderPart extends DestroyablePart implements OnInit {


	constructor(
		private ds: DataService,
	) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();
	}

}