import { DestroyablePart } from './ACS_destroyable.part';
import { ActivatedRoute } from '@angular/router';
import { UtilityService } from '@me-services/core/utility';
import { Observable } from 'rxjs';
import { merge, takeUntil } from 'rxjs/operators';

export interface Params {
	[key: string]: any;
}

/** @deprecated Use app areas instead */
export abstract class RouteMonitorPart extends DestroyablePart {

	protected constructor(route: ActivatedRoute, util: UtilityService) {
		super();
		this.detectParamsChanges(route, util);
	}

	abstract routeParamsChanged(params: Params);

	detectParamsChanges(route: ActivatedRoute, util: UtilityService) {

		let params$: Observable<Params> = route.params;
		let params = {};

		while (route) {
			route = route.parent;
			if (route) params$ = params$.pipe(merge(route.params));
		}

		const routeParamsChangedDebounced = util.debounce(this.routeParamsChanged.bind(this), 100);

		params$
			.pipe(takeUntil(this.destroyed$))
			.subscribe(p => {
				params = Object.assign({}, params, p);
				routeParamsChangedDebounced(params);
			});
	}
}