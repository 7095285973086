import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { ApplicationFields, ApplicationStatusId, FieldTool } from '@me-interfaces';
import { LabelsService } from '@me-services/ui/labels';
import { FieldFormState } from '@me-shared-parts/UI-common';
import { PanelBarStateChangeEvent } from '@progress/kendo-angular-layout';
import { LabelSettings } from '@progress/kendo-angular-progressbar';
import { combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'application-editor',
	templateUrl: './SHR-PG_application-editor.part.html',
	styleUrls: ['./SHR-PG_application-editor.part.scss']
})
export class ApplicationEditorPart extends DestroyablePart implements OnInit {

	@Input() application: ApplicationFields;
	@Output() statusChange = new EventEmitter<ApplicationStatusId>();

	forceValidation = false;
	tool: FieldTool;
	applicationStatusIdAcceptPending = ApplicationStatusId.AcceptPending;
	applicationStatusIdAcceptWithdrawn = ApplicationStatusId.AcceptWithdrawn;


	//
	// Each form section has its own state which includes the progress
	// details and the list of text area fields for cycling through them.
	//
	states = {
		business: new FieldFormState(),
		businessRemaining: -1,
		you: new FieldFormState(),
		youRemaining: -1,
		team: new FieldFormState(),
		teamRemaining: -1,
		more: new FieldFormState(),
		moreRemaining: -1,
		staffOnly: new FieldFormState(),
		staffOnlyRemaining: -1,
	};


	withdrawing = false;
	submitting = false;

	progressValue = 0;
	progressText = '';
	progressLabel: LabelSettings = {
		visible: true,
		format: () => this.progressText,
		position: 'end'
	};
	emptyCssStyle = {
		color: '#FFF',
		background: '#A33'
	};
	progressCssStyle = {
		color: '#FFF',
		background: '#383'
	};

	expanded = 'instructions';

	inlineLabels = this.labels.trackInlineLabels(this, [
		'Completed',
		'Instructions',
		'Tell us about Your Business',
		'Tell us about You',
		'Tell us about Your Team',
		'Tell us More',
		'Staff Only',
	]);


	constructor(public labels: LabelsService) {
		super();
	}


	ngOnInit() {
		super.initDestroyable();

		if (!this.application) throw new Error('Missing required attribute: application');
		this.tool = this.application.overview.tool;

		combineLatest([
			this.states.business.status$,
			this.states.you.status$,
			this.states.team.status$,
			this.states.more.status$,
			this.states.staffOnly.status$,
			this.labels.getLabel$,
		])
			.pipe(takeUntil(this.destroyed$))
			.subscribe(([businessStatus, youStatus, teamStatus, moreStatus, staffOnlyStatus, getLabel]) => {

				const completed = businessStatus.numCompleted + youStatus.numCompleted + teamStatus.numCompleted + moreStatus.numCompleted + staffOnlyStatus.numCompleted;
				const total = businessStatus.numTotal + youStatus.numTotal + teamStatus.numTotal + moreStatus.numTotal + staffOnlyStatus.numCompleted;
				const remaining = total - completed;

				this.states.businessRemaining = businessStatus.numTotal - businessStatus.numCompleted;
				this.states.youRemaining = youStatus.numTotal - youStatus.numCompleted;
				this.states.teamRemaining = teamStatus.numTotal - teamStatus.numCompleted;
				this.states.moreRemaining = moreStatus.numTotal - moreStatus.numCompleted;
				this.states.staffOnlyRemaining = staffOnlyStatus.numTotal - staffOnlyStatus.numCompleted;

				this.progressValue = total ? Math.round(completed / total * 100) : 0;

				if (remaining) this.progressText = `${remaining} ${getLabel({ key: 'Remaining' })}`;
				else this.progressText = getLabel({ key: 'Completed' });

				this.progressLabel.position = remaining ? 'end' : 'center';
				this.progressLabel = { ...this.progressLabel }; // force binding
			});
	}


	async submit() {
		// this.submitting = true;
		this.forceValidation = true;
		if (this.application.overview.applicationStatusId == ApplicationStatusId.AcceptPending) this.statusChange.emit(ApplicationStatusId.AcceptSubmitted);
		// this.submitting = true;
	}

	withdraw() {
		// this.withdrawing = true;
		this.statusChange.emit(ApplicationStatusId.AcceptWithdrawn);
		// this.withdrawing = false;
	}


	panelChange(e: PanelBarStateChangeEvent) {

		if (!e) return;

		const expandedOne = e.items.find(i => i.focused && i.expanded);
		this.expanded = expandedOne?.id ?? '';

		let scrollTop = 0;
		if (this.expanded == 'instructions') scrollTop = 50;
		if (this.expanded == 'aboutCompany') scrollTop = 100;
		if (this.expanded == 'aboutYou') scrollTop = 150;
		if (this.expanded == 'aboutTeam') scrollTop = 200;
		if (this.expanded == 'tellMore') scrollTop = 250;

		jQuery('.main-wrapper').animate({ scrollTop }, { duration: 500 });
	}
}
