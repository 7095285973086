<div class="person-extract-banner-part" *ngIf="person">
	<div>
		<me-avatar [avatar]='person'></me-avatar>
	</div>
	<div class='person-details'>

		<div>
			<span style='margin-right:1em'>{{person._name}}</span>
		</div>

		<address-link [address]='address' [translate]='false'></address-link>

	</div>
</div>