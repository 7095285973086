<div class="field-grid-part">

	<ng-container *ngFor='let field of _fields'>

		<text-field *ngIf='field.kind==="Text"' [field]='field' [value]='getValue(field)' [state]='state' [tool]='tool'
			[forceValidation]='forceValidation' (fieldUpdate)='onFieldUpdate(field, $event)'></text-field>

		<textarea-field *ngIf='field.kind==="Textarea"' [field]='field' [value]='getValue(field)' [state]='state'
			[tool]='tool' [forceValidation]='forceValidation' (fieldUpdate)='onFieldUpdate(field, $event)'>
		</textarea-field>

		<textarea-dialog-field *ngIf='field.kind==="Textarea Dialog"' [field]='field' [value]='getValue(field)' [state]='state'
			[tool]='tool' [forceValidation]='forceValidation' (fieldUpdate)='onFieldUpdate(field, $event)'>
		</textarea-dialog-field>

		<select-field *ngIf='field.kind==="Select"' [field]='field' [value]='getValue(field)' [state]='state'
			[tool]='tool' [otherValue]='getOtherValue(field)' [forceValidation]='forceValidation'
			(fieldUpdate)='onFieldUpdate(field, $event)'></select-field>

		<numeric-field *ngIf='field.kind==="Numeric"' [field]='field' [value]='getValue(field)' [state]='state'
			[tool]='tool' [forceValidation]='forceValidation' (fieldUpdate)='onFieldUpdate(field, $event)'></numeric-field>

		<phone-field *ngIf='field.kind==="Phone"' [field]='field' [value]='getValue(field)' [state]='state' [tool]='tool'
			[forceValidation]='forceValidation' (fieldUpdate)='onFieldUpdate(field, $event)'>
		</phone-field>

		<date-field *ngIf='field.kind==="Date"' [field]='field' [value]='getValue(field)' [state]='state' [tool]='tool'
			[forceValidation]='forceValidation' (fieldUpdate)='onFieldUpdate(field, $event)'></date-field>

		<address-field *ngIf='field.kind==="Address"' [field]='field' [value]='getValue(field)' [state]='state'
			[tool]='tool' [forceValidation]='forceValidation' (fieldUpdate)='onFieldUpdate(field, $event)'>
		</address-field>

	</ng-container>


</div>