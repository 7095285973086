import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { Site } from '@me-interfaces';
import { UtilityService } from '@me-services/core/utility';
import { UrlService } from '@me-services/ui/url';
import { getIconContext, Icon } from '@me-shared-parts/UI-common';
import { getIconBarSelections, IconBarIcon } from '@me-shared-parts/UI-common/icon-bar';
import { IconBarPart, IconBarSelections } from '@me-shared-parts/UI-common/icon-bar/SHR-CMN_icon-bar.part';
import { DataService } from '../../../data.service';
import { ExplorerBox } from '../../explorer-box/explorer-box';
import { LinkOrExplorable } from '../../link-or-explorerable';
import { getBoxesForAccelerators } from './boxes/get-boxes-for-accelerators';
import { getBoxesForEvents } from './boxes/get-boxes-for-events';
import { getBoxesForPitchContests } from './boxes/get-boxes-for-pitch-contests';
import { getButtons } from './get-buttons';
import { views, VIEW_ACCELERATORS, VIEW_DETAILS, VIEW_EVENTS, VIEW_PITCH_CONTESTS } from './views';

const STORAGE_KEY = 'SiteExplorerIconSelection';

@Component({
	selector: 'site-explorer',
	templateUrl: './site-explorer.part.html',
	styleUrls: ['./site-explorer.part.scss'],
})
export class SiteExplorerPart implements OnChanges {

	@Input() public site: Site;
	@ViewChild('iconBar') iconBar: IconBarPart;

	public views = views;
	public icons = {
		site: getIconContext(Icon.concept_site)
	};


	public selectedViews: IconBarSelections = undefined;
	public buttons: LinkOrExplorable[] = [];

	public acceleratorBoxes: ExplorerBox[] = [];
	public pitchContestBoxes: ExplorerBox[] = [];
	public eventBoxes: ExplorerBox[] = [];

	constructor(
		public util: UtilityService,
		private ds: DataService,
		private urlService: UrlService
	) { }


	async ngOnChanges() {
		if (!this.site) throw new Error(`Missing required attribute: site`);
		this.icons.site.text = this.site.name;
		await this.createContent();
		this.views = this.updateIconBar();
	}


	/**
	 * CreateContent is called once when a new accelerator is passed in.  All content is created but
	 * may be shown or hidden using css instead of rebuilding everything again.
	 */
	private async createContent() {

		this.buttons = getButtons(this.site);
		this.acceleratorBoxes = await getBoxesForAccelerators(this.ds, this.site);
		this.pitchContestBoxes = await getBoxesForPitchContests(this.ds, this.site);
		this.eventBoxes = await getBoxesForEvents(this.util, this.ds, this.urlService, this.site);
	}


	private updateIconBar(): IconBarIcon[] {

		//
		// Get selections from last time this explorer was accessed. If there
		// isn't on,then build the selections from the initial views in views.ts.
		//
		const selectedViewsStr = sessionStorage.getItem(STORAGE_KEY);

		if (selectedViewsStr) {
			this.selectedViews = JSON.parse(selectedViewsStr);
		}
		else {
			this.selectedViews = getIconBarSelections(views);
			sessionStorage.setItem(STORAGE_KEY, JSON.stringify(this.selectedViews));
		}

		//
		// Create a new array of icon views using the initial set but overriding
		// whether disabled or not and the tooltip text based on the created content.
		//
		return views.map(view => {
			if (view.key == VIEW_DETAILS) {
				view.disabled = false; // always has a value
				view.selected = this.selectedViews.map[VIEW_DETAILS];
				view.tooltip = `Site Details`;
			}
			if (view.key == VIEW_ACCELERATORS) {
				const count = this.acceleratorBoxes.length;
				view.disabled = !count;
				view.selected = this.selectedViews.map[VIEW_ACCELERATORS];
				view.tooltip = this.util.text.singularOrPluralWithCount(count, 'Active Accelerator', 'Active Accelerators');
			}
			if (view.key == VIEW_PITCH_CONTESTS) {
				const count = this.pitchContestBoxes.length; // + this.pitchContestBoxes.length
				view.disabled = !count;
				view.selected = this.selectedViews.map[VIEW_PITCH_CONTESTS];
				view.tooltip = this.util.text.singularOrPluralWithCount(count, 'Active Pitch Contest', 'Active Pitch Contests');
			}
			if (view.key == VIEW_EVENTS) {
				const count = this.eventBoxes.length;
				view.disabled = !count;
				view.selected = this.selectedViews.map[VIEW_EVENTS];
				view.tooltip = this.util.text.singularOrPluralWithCount(count, 'Event', 'Events');
			}

			return view;
		});
	}


	public async setViews(selectedViews: IconBarSelections) {
		if (!selectedViews) return;
		this.selectedViews = selectedViews;
		sessionStorage.setItem(STORAGE_KEY, JSON.stringify(selectedViews));

		for (const view of this.views) {
			view.selected = !!selectedViews.map[view.key];
		}
	}


	public getDisplay(key: string) {
		const view = this.views.find(view => view.key == key);
		if (view == undefined) return 'none';
		return !!view.disabled || !view.selected ? 'none' : 'block';
	}


	public tabClose(header: string) {
		if (header.endsWith('Details')) this.unselect(VIEW_DETAILS);
		if (header.endsWith('Event') || header.endsWith('Events')) this.unselect(VIEW_EVENTS);
		if (header.endsWith('Accelerator') || header.endsWith('Accelerators')) this.unselect(VIEW_ACCELERATORS);
		if (header.endsWith('Contest') || header.endsWith('Contests')) this.unselect(VIEW_PITCH_CONTESTS);
	}


	private unselect(key: string) {

		this.iconBar.unselect(key);

		const view = this.views.find(view => view.key == key);
		if (view) view.selected = false;
	}
}