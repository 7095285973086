import { AppAreaAccess, AppAreaAccessValue, AppSubAreaAccessValue } from '@me-interfaces';

export type SitesSubArea =
	'activePrograms' |
	'upcomingEvents' |
	'permissions';


export interface SitesAreaAccess extends AppAreaAccess {
	root: AppAreaAccessValue, // access/admin/communities/list
	sub: {
		activePrograms: AppSubAreaAccessValue, //access/admin/communities/common/active-programs
		upcomingEvents: AppSubAreaAccessValue, //access/admin/communities/common/upcoming-events
		permissions: AppSubAreaAccessValue, //access/admin/communities/common/permissions
	},
}