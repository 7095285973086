import { getApplicationStatusName } from "@ACC-area";
import { ApplicationStatusId, Company, DbConceptName } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { getIconForApplicationStatus } from "@me-shared-parts/UI-common";
import { DataService } from "../../../../data.service";
import { ExplorerBox, ExplorerBoxClass } from "../../../explorer-box/explorer-box";


export async function getBoxesForPitchContests(util: UtilityService, ds: DataService, company: Company): Promise<ExplorerBox[]> {

	if (company.picApplicationIds.length == 0) return [];

	const boxes: ExplorerBox[] = [];


	//
	// Clean the list of applicationIds, and sort them largest (newest) first.
	//
	const applicationIds = util.array.cleanNumericIds([...company.picApplicationIds]).reverse();

	//
	// Get the application packages
	//
	const applications = await ds.admin.application.getManyPackagesAsArray(applicationIds);


	for (const application of applications) {

		if (application.pitchContest) {

			const applicationStatusIcon = getIconForApplicationStatus(application.applicationStatusId);
			const applicationStatus = getApplicationStatusName(application.applicationStatusId);
			const site = application.siteProgram.site;
			const pic = application.pitchContest;


			const box: ExplorerBox = {

				explorerBoxClass: ExplorerBoxClass.PitchContest,
				header: 'Pitch Contest',
				buttons: [
					{ explorer: { explorableId: { conceptName: DbConceptName.Site, id: site.siteId }, navTooltip: `Site: ${site.name}` } },
					{ explorer: { explorableId: { conceptName: DbConceptName.PitchContest, id: pic.picId }, navTooltip: `Pitch Contest: ${site.code} ${pic.name}` } },
					{ explorer: { explorableId: { conceptName: DbConceptName.Person, id: application.personId }, navTooltip: `Applicant: ${application.person._name}` } },
					{ explorer: { nameOverride: applicationStatus, explorableId: { conceptName: DbConceptName.Application, id: application.applicationId }, navIconOverride: applicationStatusIcon, navTooltip: `Application: ${applicationStatus}` } },
				],
				notes: '',
			};

			if (application.applicationStatusId == ApplicationStatusId.TeamPromoted) {

				const picTeamsByApplicationId = await ds.admin.picTeam.getByApplicationIds([application.applicationId]);
				const picTeams = picTeamsByApplicationId[application.applicationId];

				if (picTeams.length) { // should only be one (or zero)
					const picTeam = picTeams[0];
					const picTeamName = 'Entrepreneur(s)';
					box.buttons.push({ explorer: { nameOverride: picTeamName, explorableId: { conceptName: DbConceptName.PicTeam, id: picTeam.picTeamId }, navTooltip: `Pitching Team` } });
				}
			}

			boxes.push(box);
		}

	}

	return boxes;

}