import { Dba, DbConceptName, Immutable } from '@me-interfaces';


interface MutableDbaAccInterview extends Dba {

	readonly _concept: DbConceptName.AccInterview,

	accInterviewerId: number,
	applicationId: number,
	answersId: number,
	rating: number,
	polishedFeedback: string,
	polishedFeedbackSkipped: boolean,
}


export interface DbaAccInterview extends Immutable<MutableDbaAccInterview> { }