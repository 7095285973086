import { MenuItem } from "@me-interfaces";


export const ideasAndBugsMenu: MenuItem = {
	label: 'Ideas and Bugs',
	icon: 'fab fa-jira',

	subMenu: [
		{
			label: 'What is Jira?',
			url: 'https://www.loom.com/share/2934f3a88815436ca1abc46175c3b7e9',
			icon: 'fas fa-video',
		},
		{
			label: 'Roadmap',
			url: 'https://eforall.atlassian.net/jira/software/projects/APP/boards/1/roadmap',
			icon: 'fab fa-jira',
		},
		{
			label: 'Backlog',
			url: 'https://eforall.atlassian.net/jira/software/projects/APP/boards/1/backlog',
			icon: 'fas fa-file-user',
		},
		{
			label: 'Add an Idea',
			url: 'https://eforall.atlassian.net/secure/CreateIssue.jspa?pid=10001&issuetype=10008',
			icon: 'fas fa-smile-plus',
		},
		{
			label: 'Add a bug',
			url: 'https://eforall.atlassian.net/secure/CreateIssue.jspa?pid=10001&issuetype=10009',
			icon: 'fas fa-bug',
		},
	]
};