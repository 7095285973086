<div class='acc-team-view-part' *ngIf="viewDroplistItems.length">
	<ng-container *ngIf='selectedViewItem$ | async; let selectedViewItem'>
		<me-droplist class="views-droplist" [items]='viewDroplistItems' [selectedItem]='selectedViewItem'
			(selectionChange)='onViewDroplistChange($event)' [filterable]='false' [navigation]='true'
			sortBy='customSort'
			[groupSortValues]='{"Cohort":1, "Mentor Matching":2, "Final Presentations":3, "Showcase":4, "Quarterlies 2":5, "Quarterlies 3":6, "Quarterlies 4":7 }'
			style='display:inline-block'>
		</me-droplist>

		<acc-team-members-view-part [accTeamId]='accTeamId' *ngIf="showView('Members', selectedViewItem)">
		</acc-team-members-view-part>

		<acc-team-goals-view-part [accTeamId]='accTeamId' *ngIf="showView('Goals', selectedViewItem)">
		</acc-team-goals-view-part>
	</ng-container>
</div>