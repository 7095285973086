import { AccStageId } from "@me-interfaces";
import { getIconContext } from "./get-icon-context";
import { Icon } from "./icon";
import { IconContext } from "./icon-context";
import { IconHideTextAt } from "./icon-hide-text-at";
import { IconStyle } from "./icon-style";


/**
 * Return the Icon value for a given AccStageId
 */
export function getIconForAccStage(accStageId: AccStageId): Icon {

	if (accStageId == AccStageId.Setup) return Icon.accStage_setup;
	if (accStageId == AccStageId.Accepting) return Icon.accStage_accepting;
	if (accStageId == AccStageId.Reading) return Icon.accStage_reading;
	if (accStageId == AccStageId.Interviewing) return Icon.accStage_interviewing;
	if (accStageId == AccStageId.SelectingCohort) return Icon.accStage_selectingCohort;
	if (accStageId == AccStageId.MatchingMentors) return Icon.accStage_matchingMentors;
	if (accStageId == AccStageId.Curriculum) return Icon.accStage_curriculum;
	if (accStageId == AccStageId.SelectingWinners) return Icon.accStage_selectingWinners;
	if (accStageId == AccStageId.Quarterlies) return Icon.accStage_quarterlies;
	if (accStageId == AccStageId.Complete) return Icon.accStage_complete;

	return Icon.status_missing;
}


export function getIconContextForAccStage(accStageId: AccStageId, iconStyle?: IconStyle, hideText?: IconHideTextAt, text?: string, hasRedFlag?: boolean): IconContext {
	return getIconContext(getIconForAccStage(accStageId), iconStyle, hideText, text, hasRedFlag);
}