<me-dialog-frame [header]='headerText' [showToolbar]='true' [dialogRef]='dialogRef' [icon]='icon' alertType='editor'
	[actions]="actions">

	<ng-container toolbar>
		<search-header></search-header>
	</ng-container>

	<me-grid [setup]='gridSetup' [rows]='rows$ | async' [loading]='ds.searcher.searchService.busy$ | async' (gridAction)='gridActionHandler($event)'></me-grid>


</me-dialog-frame>