import { Component, Input, OnInit, Self } from '@angular/core';

const ACC_STAGES = [
	{ name: 'Setup', id: 1 },
	{ name: 'Applications', id: 10 },
	{ name: 'Reading', id: 20 },
	{ name: 'Interviewing', id: 30 },
	{ name: 'Selecting Cohort', id: 40 },
	{ name: 'Matching Mentors', id: 50 },
	{ name: 'Curriculum', id: 60 },
	{ name: 'Selecting Winners', id: 70 },
	{ name: 'Quarterlies', id: 80 },
	{ name: 'Complete', id: 90 },
];

const PIC_STAGES = [
	{ name: 'Setup', id: 1 },
	{ name: 'Applications', id: 10 },
	{ name: 'Reading', id: 20 },
	{ name: 'Selecting', id: 30 },
	{ name: 'Contest', id: 40 },
	{ name: 'Complete', id: 50 },
];

@Component({
	selector: 'stage-viewer',
	styleUrls: ['./SHR-CMN_stage-viewer.part.scss'],
	templateUrl: './SHR-CMN_stage-viewer.part.html',
})
export class StageViewerPart implements OnInit {

	@Input() stageId: number;
	@Input() program: 'accelerator' | 'pitchContest';

	stages = [];

	ngOnInit() {
		if (this.program == 'accelerator') this.stages = ACC_STAGES;
		else if (this.program == 'pitchContest') this.stages = PIC_STAGES;
		else this.stages = [];
	}
}