import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { DbsAgreementVersion, LanguageId } from '@me-interfaces';
import { UtilityService } from '@me-services/core/utility';
import { DialogAction, DialogContext, DialogService } from '@me-services/ui/dialog';
import { UserAreaService } from '@me-user-area';


@Component({
	selector: 'agreement-dialog',
	templateUrl: './agreement.dialog.html',
})
export class AgreementDialog extends DestroyablePart implements OnInit {

	//
	// The action buttons
	//
	cancelAction: DialogAction = {
		id: 'close',
		enabled: true,
		visible: true,
		label: 'Close Without Signing',
		linkType: "no-action",
		willCloseDialog: true,
	};

	actions: DialogAction[] = [this.cancelAction];
	icon = 'fa-file-contract';
	header = 'Agreement Text';
	public data: { languageId: LanguageId, alreadySigned: boolean, agreementVersion: DbsAgreementVersion, agreementType: { agreementTypeId: number, nameEN: string, nameES: string, }, readonly: boolean, agreementTextDivHeight?: number };

	agreementBodyDivHeight = this.dialog.height - 100; // dialog height minus the action, header, padding, selector, footer height


	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ languageId: LanguageId, agreementVersion: DbsAgreementVersion, agreementType: { agreementTypeId: number, nameEN: string, nameES: string, }, readonly: boolean, alreadySigned: boolean }>,
		public dialogRef: MatDialogRef<AgreementDialog>,
		public dialogService: DialogService,
		private util: UtilityService,
		private userAreaService: UserAreaService,
	) {
		super();
	}


	async ngOnInit() {
		super.initDestroyable();

		this.data = { ...this.dialog.data, agreementTextDivHeight: this.agreementBodyDivHeight };
		if (this.data.alreadySigned) this.cancelAction.label = 'Close';
		this.header = this.data.agreementType[`name${this.data.languageId == LanguageId.Spanish ? 'ES' : 'EN'}`]
	}


	public async signAgreement() {
		if (this.data.alreadySigned) return;
		const response = await this.userAreaService.program.addAgreementSignature({ agreementVersionId: this.data.agreementVersion.agreementVersionId });

		const currentDate = this.util.date.formatDate(new Date(), 'MMM D, YYYY', this.data.languageId);
		if (response.success) await this.dialogService.showMessage({ key: 'Agreement Thank You Msg', fields: { "signature-date": currentDate } }, 300, 200, { key: 'Close' });
		this.dialogRef.close({ callbackResult: true });

	}

}