import { Component, Input, OnChanges } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DownloadColumn } from '@me-interfaces/ui';
import { buildArrays, createHref, makeSafeHref } from './SHR-CMN_download-button';


@Component({
	selector: 'download-button',
	templateUrl: 'SHR-CMN_download-button.part.html',
	styleUrls: ['SHR-CMN_download-button.part.scss'],
})
export class DownloadButtonPart implements OnChanges {

	@Input() fileName = "download";
	@Input() extension = '.tsv';
	@Input() label = 'Download';
	@Input() rows = [];
	@Input() columns: DownloadColumn[];
	@Input() disabled = false;
	@Input() display: 'text-only' | 'icon-only' | 'text-icon' = 'text-only';

	href?: SafeUrl;

	download = '';
	noData = false;

	constructor(
		private sanitizer: DomSanitizer) {
	}

	ngOnChanges() {
		this.download = this.fileName + this.extension; //TODO: only add '.tsv' if not already there

		const values = buildArrays(this.columns, this.rows);
		const href = createHref(values);
		this.href = makeSafeHref(href, this.sanitizer);
	}

	isDisabled() {
		return this.disabled || !(this.href && this.columns.length && this.rows.length);
	}
}