import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import {
	AcceleratorExplorerPart, AcceleratorExplorerDetailsPart, AccTeamExplorerPart, AccTeamExplorerDetailsPart, ApplicationExplorerDetailsPart, ApplicationExplorerPart, ApplicationExplorerQuestionsPart, CompanyExplorerDetailsPart, CompanyExplorerPart, ContactDialog, EventExplorerDetailsPart, EventExplorerPart, ExplorerBoxesPart, ExplorerBoxPart, ExplorerButtonPart,
	ExplorerButtonsPart, ExplorerDialog, ExplorerHeaderPart, ExplorerNoteBoxesPart, ExplorerNoteBoxPart, ExplorerSectionPart, ExplorerTagBoxPart, PersonExplorerDemographicsPart, PersonExplorerDetailsPart, PersonExplorerLangAndBioPart, PersonExplorerPart,
	PicTeamExplorerPart, PicTeamExplorerDetailsPart, PitchContestExplorerPart, PitchContestExplorerDetailsPart, SearchDialog, SearchHeaderPart, RegionExplorerPart, RegionExplorerDetailsPart,SiteExplorerPart, SiteExplorerDetailsPart, VenueExplorerPart, VenueExplorerDetailsPart, VenueExplorerEventsPart
} from '@me-services/core';
import { SharedCommonModule } from '@me-shared-parts/UI-common/UI.module';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';

@NgModule({
	imports: [
		CommonModule,
		DateInputsModule,
		DropDownsModule,
		GridModule,
		InputsModule,
		LayoutModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		MatButtonToggleModule,
		MatDatepickerModule,
		MatInputModule,
		MatSelectModule,
		MatRadioModule,
		MatExpansionModule,
		MatAutocompleteModule,
		MatListModule,
		MatMenuModule,
		PopupModule,
		TooltipsModule,
		SharedCommonModule,
	],
	declarations: [
		//
		// Components
		//
		AcceleratorExplorerPart,
		AcceleratorExplorerDetailsPart,
		AccTeamExplorerPart,
		AccTeamExplorerDetailsPart,
		ApplicationExplorerPart,
		ApplicationExplorerDetailsPart,
		ApplicationExplorerQuestionsPart,
		CompanyExplorerDetailsPart,
		CompanyExplorerPart,
		EventExplorerDetailsPart,
		EventExplorerPart,
		ExplorerBoxesPart,
		ExplorerBoxPart,
		ExplorerButtonPart,
		ExplorerButtonsPart,
		ExplorerHeaderPart,
		ExplorerNoteBoxesPart,
		ExplorerNoteBoxPart,
		ExplorerSectionPart,
		ExplorerTagBoxPart,
		PersonExplorerDemographicsPart,
		PersonExplorerDetailsPart,
		PersonExplorerLangAndBioPart,
		PersonExplorerPart,
		PicTeamExplorerPart,
		PicTeamExplorerDetailsPart,
		PitchContestExplorerPart,
		PitchContestExplorerDetailsPart,
		SearchHeaderPart,
		RegionExplorerPart,
		RegionExplorerDetailsPart,
		SiteExplorerPart,
		SiteExplorerDetailsPart,
		VenueExplorerPart,
		VenueExplorerDetailsPart,
		VenueExplorerEventsPart,
		//
		// Directives
		//

		//
		// Pipes
		//

		//
		// Dialogs
		//
		ContactDialog,
		ExplorerDialog,
		SearchDialog,
	],
	exports: [
		//
		// Components
		//
		AcceleratorExplorerPart,
		AcceleratorExplorerDetailsPart,
		AccTeamExplorerPart,
		AccTeamExplorerDetailsPart,
		ApplicationExplorerPart,
		ApplicationExplorerDetailsPart,
		ApplicationExplorerQuestionsPart,
		CompanyExplorerDetailsPart,
		CompanyExplorerPart,
		EventExplorerDetailsPart,
		EventExplorerPart,
		ExplorerBoxesPart,
		ExplorerBoxPart,
		ExplorerButtonPart,
		ExplorerButtonsPart,
		ExplorerHeaderPart,
		ExplorerNoteBoxesPart,
		ExplorerNoteBoxPart,
		ExplorerSectionPart,
		ExplorerTagBoxPart,
		PersonExplorerDemographicsPart,
		PersonExplorerDetailsPart,
		PersonExplorerLangAndBioPart,
		PersonExplorerPart,
		PicTeamExplorerPart,
		PicTeamExplorerDetailsPart,
		PitchContestExplorerPart,
		PitchContestExplorerDetailsPart,
		SearchHeaderPart,
		RegionExplorerPart,
		RegionExplorerDetailsPart,
		SiteExplorerPart,
		SiteExplorerDetailsPart,
		VenueExplorerPart,
		VenueExplorerDetailsPart,
		VenueExplorerEventsPart,
		//
		// Directives
		//

		//
		// Pipes
		//

		//
		// Dialogs
		//
		ContactDialog,
		ExplorerDialog,
		SearchDialog,
	],
})

export class ServicesCoreModule { }