import { Accelerator } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";


export async function getAccs(util: UtilityService, ds: DataService) {

	const accsBySiteId: Record<number, Accelerator[]> = {};

	const accs = await ds.admin.accelerator.getAllPackagesAsArray();

	for (const acc of accs) {

		const siteId = acc.siteId;

		util.record.insertElement<Accelerator>(accsBySiteId, siteId, acc);
	}

	return { accsBySiteId };
}