import { AccAreaService, AccMultipleEventsSelectorDialog, AccPreAcceleratorPageService } from '@ACC-area';
import { ManageVolunteersDialog, SiteAreaService } from '@SITE-area';
import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { ADDROW_GRID_ACTION_KEY, GridAction, GridColumnType, GridSetup, PersonCols } from '@me-grid';
import { AccInterviewer, AccStageId, Answer, DbaAccInterview, DbaAccInterviewerEvent, Event, EventTypeId, Tag } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { DialogAction, DialogService } from '@me-services/ui/dialog';
import { GridPart, Icon } from '@me-shared-parts/UI-common';
import { Observable, combineLatest, mergeMap } from 'rxjs';
import { applyInterviewerGridFilter } from '../../../acc-interviewer-filter-helpers';


const STAGE_ID = AccStageId.Interviewing;
interface InterviewerRow {
	personId: number,
	accInterviewerId?: number,
	importedAccInterviewerId?: number,
	interviewerType: 'Configured' | 'Imported' | 'Imported/Configured',
	name: string,
	eventIds: number[],
	eventNums: string,
	countOfInterviews: number,
	ratings4: [number, number, number, number,]
	ratings5: [number, number, number, number, number]
	assessments: string,
	score: number,
	updatedUTC: number,
}


@Component({
	selector: 'acc-interviewing-interviewers-view-part',
	templateUrl: './acc-interviewing-interviewers-view.part.html',
})
export class AccInterviewingInterviewersViewPart extends DestroyablePart implements OnInit, AfterViewInit {

	@ViewChild(GridPart) meGrid: GridPart<InterviewerRow>;

	private accessAtStage$ = this.accAreaService.getAccessAtStage(AccStageId.Interviewing);

	public gridSetup = this.setupGrid(false);

	public rows$: Observable<InterviewerRow[]>;
	private rows: InterviewerRow[] = [];

	siteTag: Tag;
	readonly: boolean;
	readonlyAccessAtStage: boolean;

	constructor(
		private siteAreaService: SiteAreaService,
		private accAreaService: AccAreaService,
		private ds: DataService,
		private util: UtilityService,
		private dialogService: DialogService,
		private pageService: AccPreAcceleratorPageService,
	) {
		super();
	}

	async ngOnInit() {
		super.initDestroyable();

		super.subscribe([this.accAreaService.access$, this.accessAtStage$], async ([access, accessAtStage]) => {
			this.readonly = access?.root != 'Write';
			this.gridSetup = this.setupGrid(access.root == 'Write');
			this.readonlyAccessAtStage = accessAtStage.access != 'Write';
		});


		const siteId = this.siteAreaService.getId().id;
		if (siteId) {
			const siteTags = (await this.ds.admin.tag.getAllPackagesAsArray()).filter(tag => tag.site && tag.site.siteId == siteId);
			this.siteTag = siteTags.find(tag => tag.name == 'interviewer-approved');
		}



		this.rows$ = combineLatest([
			this.accAreaService.interviewing.interviewers$,
			this.accAreaService.interviewing.interviewerEvents$,
			this.accAreaService.interviewing.assessments$,
			this.accAreaService.accelerator.events$,
		]).pipe(mergeMap(data => this.buildRows(data[0], data[1], data[2], data[3])));
	}


	ngAfterViewInit() {

		super.subscribe([this.pageService.interviewInterviewersFilter$], async ([filter]) => {
			applyInterviewerGridFilter(STAGE_ID, this.meGrid.grid, filter);
		});

	}

	async buildRows(
		existingInterviewers: readonly AccInterviewer[],
		interviewerEvents: readonly DbaAccInterviewerEvent[],
		interviewAssessments: {
			interview: DbaAccInterview,
			answers: readonly Answer[],
		}[],
		events: readonly Event[],
	): Promise<InterviewerRow[]> {
		const rows: InterviewerRow[] = [];
		if (!existingInterviewers) {
			return [];
		}

		const accInterviewEvents = await this.accAreaService.getEventsWithNumAndDate(EventTypeId.AccInterviewing, events);

		const eventsWithId: { [index: number]: number } = {};
		accInterviewEvents.forEach(eventWithNum => { eventsWithId[eventWithNum.event.eventId] = eventWithNum.num });

		const existingInterviewersMap = this.util.array.toMap(existingInterviewers, (interviewer) => { return interviewer.accInterviewerId; });
		const existingPeopleWithAccInterviewerId = existingInterviewers.map(r => ({ person: r.person, accInterviewerId: r.accInterviewerId }));

		const transferedInterviewerAssessments = interviewAssessments.filter(assessment => !existingInterviewersMap[assessment.interview.accInterviewerId]);
		const transferedPeopleWithAccInterviewerId = (await this.ds.admin.accInterviewer.getManyPackagesAsArray(transferedInterviewerAssessments.map(assessment => assessment.interview.accInterviewerId))).map(r => ({ person: r.person, accInterviewerId: r.accInterviewerId }));


		//
		// Add all the imported readers
		// Note: No accInterviewerId set for transfer imported readers as they are phantom
		for (const personWithAccInterviewerId of transferedPeopleWithAccInterviewerId) {

			const assessments = interviewAssessments.filter(assessment => assessment.interview.accInterviewerId == personWithAccInterviewerId.accInterviewerId)
				.map(assessment => ({ rating: assessment.interview.rating, answers: assessment.answers }));
			const { ratings4, ratings5 } = this.util.ratings.getRating4AndRatings5(assessments);

			const foundMatch = rows.find(r => r.personId == personWithAccInterviewerId.person.personId);
			if (foundMatch) {
				foundMatch.countOfInterviews += interviewAssessments.filter(interview => interview.interview.accInterviewerId == personWithAccInterviewerId.accInterviewerId).length;
				foundMatch.ratings4 = [
					foundMatch.ratings4[0] + ratings4[0],
					foundMatch.ratings4[1] + ratings4[1],
					foundMatch.ratings4[2] + ratings4[2],
					foundMatch.ratings4[3] + ratings4[3],
				];
				foundMatch.ratings5 = [
					foundMatch.ratings5[0] + ratings5[0],
					foundMatch.ratings5[1] + ratings5[1],
					foundMatch.ratings5[2] + ratings5[2],
					foundMatch.ratings5[3] + ratings5[3],
					foundMatch.ratings5[4] + ratings5[4],
				];
				foundMatch.assessments = this.util.ratings.formatForGridCell(foundMatch.ratings4);
				foundMatch.score = this.util.ratings.calculateScore(foundMatch.ratings5, foundMatch.ratings4);

			}
			else {
				rows.push({
					importedAccInterviewerId: personWithAccInterviewerId.accInterviewerId,
					personId: personWithAccInterviewerId.person.personId,
					countOfInterviews: interviewAssessments.filter(interview => interview.interview.accInterviewerId == personWithAccInterviewerId.accInterviewerId).length,
					name: personWithAccInterviewerId.person.fullName,
					interviewerType: 'Imported',
					eventIds: [],
					eventNums: '',
					ratings4,
					ratings5,
					assessments: this.util.ratings.formatForGridCell(ratings4),
					score: this.util.ratings.calculateScore(ratings5, ratings4),
					updatedUTC: personWithAccInterviewerId.person.updatedUTC,
				});
			}
		}

		//
		// Add all the Configured readers
		//
		for (const personWithAccInterviewerId of existingPeopleWithAccInterviewerId) {
			const eventIds = interviewerEvents.filter(ie => ie.accInterviewerId == personWithAccInterviewerId.accInterviewerId).map(ie => ie.eventId);
			const eventNums = eventIds.map(id => eventsWithId[id]).join(', ');

			const assessments = interviewAssessments.filter(assessment => assessment.interview.accInterviewerId == personWithAccInterviewerId.accInterviewerId)
				.map(assessment => ({ rating: assessment.interview.rating, answers: assessment.answers }));
			const { ratings4, ratings5 } = this.util.ratings.getRating4AndRatings5(assessments);

			const foundMatch = rows.find(r => r.personId == personWithAccInterviewerId.person.personId);
			if (foundMatch) {
				foundMatch.countOfInterviews += interviewAssessments.filter(interview => interview.interview.accInterviewerId == personWithAccInterviewerId.accInterviewerId).length;
				foundMatch.interviewerType = 'Imported/Configured';
				foundMatch.accInterviewerId = personWithAccInterviewerId.accInterviewerId;
				foundMatch.eventIds = eventIds;
				foundMatch.eventNums = eventIds.length ? eventNums : '';
				foundMatch.ratings4 = [
					foundMatch.ratings4[0] + ratings4[0],
					foundMatch.ratings4[1] + ratings4[1],
					foundMatch.ratings4[2] + ratings4[2],
					foundMatch.ratings4[3] + ratings4[3],
				];
				foundMatch.ratings5 = [
					foundMatch.ratings5[0] + ratings5[0],
					foundMatch.ratings5[1] + ratings5[1],
					foundMatch.ratings5[2] + ratings5[2],
					foundMatch.ratings5[3] + ratings5[3],
					foundMatch.ratings5[4] + ratings5[4],
				];
				foundMatch.assessments = this.util.ratings.formatForGridCell(foundMatch.ratings4);
				foundMatch.score = this.util.ratings.calculateScore(foundMatch.ratings5, foundMatch.ratings4);

			}
			else {
				rows.push({
					personId: personWithAccInterviewerId.person.personId,
					countOfInterviews: interviewAssessments.filter(interview => interview.interview.accInterviewerId == personWithAccInterviewerId.accInterviewerId).length,
					eventIds,
					eventNums: eventIds.length ? eventNums : '',
					name: personWithAccInterviewerId.person.fullName,
					interviewerType: 'Configured',
					accInterviewerId: personWithAccInterviewerId.accInterviewerId,
					ratings4,
					ratings5,
					assessments: this.util.ratings.formatForGridCell(ratings4),
					score: this.util.ratings.calculateScore(ratings5, ratings4),
					updatedUTC: personWithAccInterviewerId.person.updatedUTC,
				});

			}
		}

		this.rows = [...rows];

		return rows;

	}


	private setupGrid(canWrite: boolean): GridSetup<InterviewerRow> {
		const setup: GridSetup<InterviewerRow> = {
			experience: 'PERSON',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Interviewer",
			rowPluralName: "Interviewers",
			rowKey: "personId",
			stateKey: "acc-interviewing-interviewer-view-part",
			canAdd: canWrite,
			canRefresh: false,
			canDownload: true,
			columnsToAdd: [
				{ field: "accInterviewerId", header: "accInterviewerId", width: 130, type: GridColumnType.number, hidden: true },
				{ field: "importedAccInterviewerId", header: "Imported accInterviewerId", width: 130, type: GridColumnType.number, hidden: true },
				{ field: "eventNums", header: "Event #s", width: 100, type: GridColumnType.text },
				{ field: "countOfInterviews", header: "Interviews", width: 120, type: GridColumnType.number },
				{ field: "interviewerType", header: "Interviewer Type", width: 110, type: GridColumnType.text },
				{ field: "assessments", header: "Assessments", width: 115, type: GridColumnType.ratings, headerTooltip: `Interview Assessments Given` },
				{ field: "score", header: "Score", width: 115, type: GridColumnType.number, headerTooltip: `Interview Assessments Given Score`, fractionDigits: 1 },
			],
			columnsToAlter: [
				{ field: PersonCols.phone, hidden: false },
				{ field: PersonCols.language, hidden: false },
			],
			actions: [
				{ key: 'set-events', icon: Icon.action_edit, label: 'Set Events', enabled: false },
				{ key: 'remove-interviewer', icon: Icon.action_delete, label: 'Remove Interviewer', enabled: false },
			],
			initialState: {},
			actionEnabler: this.gridActionEnabler.bind(this),

		};

		return setup;
	}


	gridActionEnabler(action: GridAction, rows: InterviewerRow[]) {
		if (rows.length == 0) return false;

		if (action.key == 'set-events' || action.key == 'remove-interviewer') {
			if (this.readonly) return false;
		}
		return true;
	}


	async gridActionHandler(action: { actionKey: string, rows: InterviewerRow[] }) {
		const row = action.rows[0];

		if (action.actionKey == ADDROW_GRID_ACTION_KEY) {
			if (this.readonly) return;
			if (this.readonlyAccessAtStage) {
				await this.ds.dialogService.showMessage(`Cannot add interviewers as the accelerator stage is not in 'Interviewing' stage.`, 300, 170);
			}
			else {
				const dialogAction: DialogAction<number[]> = await this.ds.dialogService.showCustom(
					ManageVolunteersDialog,
					{
						data: {
							readonly: this.readonly,
							initialSelections: this.rows.map(row => ({ personId: row.personId, disabled: row.countOfInterviews > 0 })),
							tagIds: [this.siteTag.tagId],
							tagFullNameToAdd: this.siteTag.fullName,
							header: 'Interviewers'

						},
					},
					600, 530
				);

				const id = dialogAction?.id;
				if (id == 'update') {
					if (this.readonly) return;
					const selectedPersonIds = dialogAction.callbackResult;
					if (selectedPersonIds) await this.accAreaService.interviewing.actions.setInterviewers({ personIds: selectedPersonIds });

				}
			}
		}

		if (action.actionKey == 'set-events') {
			if (this.readonly) return;


			if (row.interviewerType == 'Imported' || !row.accInterviewerId) {
				await this.ds.dialogService.showMessage(`Cannot set event for ${row.name} as the Interviewer was imported when the application was transfered.`)
			}
			else {
				const action: DialogAction<number[]> = await this.ds.dialogService.showCustom(
					AccMultipleEventsSelectorDialog,
					{
						data: {
							readonly: this.readonly,
							eventIds: row.eventIds,
							eventTypeId: EventTypeId.AccInterviewing,
							header: 'Set Interviewer Events'
						},
					},
					450, 450
				);

				const id = action?.id;
				if (id == 'save') {
					await this.accAreaService.interviewing.actions.setEvents({ accInterviewerId: row.accInterviewerId, eventIds: action.callbackResult });
				}
			}

		}


		if (action.actionKey == 'remove-interviewer') {
			if (this.readonly) return;

			if (row.countOfInterviews > 0) {
				await this.dialogService.showMessage(`${row.name} cannot be removed because ${row.countOfInterviews} applicants have already been interviewed.`, 400, 250, 'Okay');
			}

			else {
				const yes = await this.dialogService.confirm(`Are you sure you want to remove Interviewer ${row.name}?`, 400, 250, 'Remove', 'Cancel');
				if (yes) await this.accAreaService.interviewing.actions.removeInterviewer({ accInterviewerId: row.accInterviewerId });
			}
		}

	}

}