<div class='acc-applications-tab-part' *ngIf='data$ | async; let applicationsData'>

	<div class="application-buttons">
		<me-h3>Applications</me-h3>
		<button class='btn' (click)="changeGridFilter('All')">All:
			{{applicationsData.applications.length}}</button>
		<button class='btn' (click)="changeGridFilter('Open')">Open:
			{{applicationsData.counts.open}}</button>
		<button class='btn' (click)="changeGridFilter('Rejected')">Rejected:
			{{applicationsData.counts.rejected}}</button>
		<button class='btn' (click)="changeGridFilter('Accepted')">Accepted:
			{{applicationsData.counts.accepted}}</button>
	</div>

	<acc-applications-counts-part></acc-applications-counts-part>

</div>