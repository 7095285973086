import { DbConceptName, Dbd, Immutable } from '@me-interfaces';


interface MutableDbdTopicMaterialType extends Dbd {

	readonly _concept: DbConceptName.TopicMaterialType,
	readonly topicMaterialTypeId: number,
	readonly name: string,
}


export interface DbdTopicMaterialType extends Immutable<MutableDbdTopicMaterialType> { }