
import { PageSpinnerService } from '@me-services/ui/page-spinner';
import { Dexie } from 'dexie';

export const DOMAIN_DATA_DB_KEY = 'DOMAIN_DATA';

const HASH_KEY = 'HASH';
const DATA_KEY = 'DATA';

export class DexieDomainDataDb {

	private objects: Dexie.Table<any, string>;

	constructor(private pageSpinner: PageSpinnerService) {

		//
		// Generate the Dexie schema
		//
		const schema = { objects: '' };


		//
		// Build the database
		//
		const dexie = new Dexie(DOMAIN_DATA_DB_KEY);
		dexie.version(2).stores(schema);


		//
		// Construct the table objects
		//
		this.objects = dexie.table('objects');
	}


	async getHashesAndData(): Promise<{ hashes: { [name: string]: string }, data: { [name: string]: [] } }> {

		// let desc = `Domain.objects.get('${HASH_KEY}')`;
		// this.pageSpinner.addSpinner(desc);
		const hashes = await this.objects.get(HASH_KEY) ?? {};
		// this.pageSpinner.removeSpinner(desc);

		// desc = `Domain.objects.get('${DATA_KEY}')`;
		// this.pageSpinner.addSpinner(desc);
		const data = await this.objects.get(DATA_KEY) ?? {};
		// this.pageSpinner.removeSpinner(desc);

		return { hashes, data };
	}


	async putHashesAndData(hashes: { [name: string]: string }, data: { [name: string]: [] }) {
		// let desc = `Domain.objects.put('${HASH_KEY}')`;
		// this.pageSpinner.addSpinner(desc);
		await this.objects.put(hashes, HASH_KEY);
		// this.pageSpinner.removeSpinner(desc);

		// desc = `Domain.objects.put('${DATA_KEY}')`;
		// this.pageSpinner.addSpinner(desc);
		await this.objects.put(data, DATA_KEY);
		// this.pageSpinner.removeSpinner(desc);
	}

}