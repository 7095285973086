import { Component } from '@angular/core';
import { Router } from '@angular/router';


@Component({
	selector: 'error-unauthorized-page',
	templateUrl: './error-unauthorized.page.html',
	styleUrls: ['./error-unauthorized.page.scss']
})
export class AccessErrorUnauthorizedPage {
	router: Router;

	constructor(router: Router) {
		this.router = router;
		
		dataLayer.push({
			'event': 'auth-error'
		});
	}

	goToDashboard(): void {
		this.router.navigate(['/access/my/dashboard']);
	}
}