import { LabelKey } from '@me-interfaces';
import { Icon } from '@me-shared-parts/UI-common';

export const ADDROW_GRID_ACTION_KEY = '_ADD_ROW_';
export const DBLCLICK_GRID_ACTION_KEY = '_DBLCLICK_';
export const REFRESH_GRID_ACTION_KEY = '_REFRESH_';
export const DOWNLOAD_GRID_ACTION_KEY = '_DOWNLOAD_';
export const SELECTION_GRID_ACTION_KEY = '_SELECTION_';
export const EXPLORE_GRID_ACTION_KEY = '_EXPLORE_';
export const OPEN_GRID_ACTION_KEY = '_OPEN_';
export const NOTES_GRID_ACTION_KEY = '_NOTES_';


/**
 * A named activity that a user can perform on selected rows.
 */
export interface GridAction {

	/**
	 * Uniquely identifies this action. The key will be passed
	 * back to the component in the GridActionEvent.
	 */
	key: string,

	/**
	 * An icon enumerated value
	 */
	icon: Icon,

	/**
	 * The textual description of the action such as "Copy".
	 * If the grid will be visible to non-admin screens, then
	 * the text should be taken from the LabelsService.
	 */
	label: string | LabelKey,


	/**
	 * If false, the menu item will be disabled.
	 */
	enabled?: boolean,

	/**
	 * If truthy then a separator line will be rendered on top of the menu item.
	 */
	separated?: boolean,
}