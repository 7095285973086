import { DbConceptName, Dbd, Immutable } from '@me-interfaces';


interface MutableDbdAwardNameEventType extends Dbd {

	readonly _concept: DbConceptName.AwardNameEventType,

	awardNameEventTypeId: number,
	awardNameId: number,
	eventTypeId: number,
}


export interface DbdAwardNameEventType extends Immutable<MutableDbdAwardNameEventType> { }