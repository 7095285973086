import { AppAreaAccess, AppAreaAccessValue, AppSubAreaAccessValue } from "@me-interfaces";


export type AdminSubArea =
	'eventDialog';


export interface AdminAreaAccess extends AppAreaAccess {
	root: AppAreaAccessValue,
	sub: {
		eventDialog: AppSubAreaAccessValue,
		staff: AppSubAreaAccessValue,
	},
}