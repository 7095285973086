<app-area-access [areaService]='siteAreaService'>
	<ng-container>
		<div class='site-overview-page'>
			<me-toolbar icon='Site'>
				<site-toolbar-buttons></site-toolbar-buttons>
			</me-toolbar>

			<page-tabs [tabNames]='tabNames' [pageService]='pageService' (layoutChange)='onLayoutChange($event)'>
				<ng-template pageTab1>
					<site-overview-views-part [readonly]='readonly'></site-overview-views-part>
				</ng-template>

				<ng-template pageTab2>
					<get-help></get-help>
					<hr>
					<div class="agreement-section">
						<me-h2>Site Agreement Pages</me-h2>
						<div class="agreement-tool-section">
							<div>Council Handbook</div>
							<link-button *ngIf="councilHandbookToolLink" [link]="councilHandbookToolLink"></link-button>
						</div>
					</div>
				</ng-template>
			</page-tabs>

		</div>
	</ng-container>
</app-area-access>