import { UtilityService } from '@me-services/core/utility';
import { RatingLevel } from './SHR-CMN_rating-interfaces';


export function setLevelScores(util: UtilityService, levels: RatingLevel[], hasAssessments: boolean) {
	for (const l1 of levels) {
		let includeRatings4 = hasAssessments && l1.question == undefined;
		Object.assign(l1, util.scoring.calcCombinedScore(l1.ratings, includeRatings4 ? l1.assessments : undefined));

		for (const l2 of l1.children) {
			includeRatings4 = hasAssessments && l2.question == undefined;
			Object.assign(l2, util.scoring.calcCombinedScore(l2.ratings, includeRatings4 ? l2.assessments : undefined));
		}
	}
}

export function gradeTheScore(level: RatingLevel) {
	const score = level.score / 2 + 50;
	if (score >= 95) return 'A+';
	if (score >= 90) return 'A';
	if (score >= 85) return 'B+';
	if (score >= 80) return 'B';
	if (score >= 75) return 'C+';
	if (score >= 70) return 'C';
	if (score >= 65) return 'D+';
	if (score >= 60) return 'D';
	return 'F';
}