import { Company, DbConceptName } from "@me-interfaces";
import { Icon } from "@me-shared-parts/UI-common";
import { DataService } from "../../../../data.service";
import { ExplorerBox, ExplorerBoxClass } from "../../../explorer-box/explorer-box";


export async function getBoxesForPeople(ds: DataService, company: Company): Promise<ExplorerBox[]> {

	const boxes: ExplorerBox[] = [];

	const positionsByCompanyId = await ds.admin.position.getArraysByForeignIds('companyId', [company.companyId]);
	const positions = positionsByCompanyId[company.companyId];

	const personIds = positions.map(position => position.personId);
	const peopleMap = await ds.admin.person.getManyPackagesAsMap(personIds);

	for (const position of positions) {

		const person = peopleMap[position.personId];

		const box: ExplorerBox = {
			explorerBoxClass: ExplorerBoxClass.Position,
			header: 'Person',
			buttons: [
				{ explorer: { nameOverride: person.fullName, explorableId: { conceptName: DbConceptName.Person, id: position.personId }, navTooltip: `Person: ${person.fullName}` } },
				{ explorer: { nameOverride: position.title, explorableId: { conceptName: DbConceptName.Person, id: position.personId }, navIconOverride: Icon.concept_position, navTooltip: `Title: ${position.title}` } },
			],
			notes: '',
		};

		if (position.phone) {
			box.buttons.push({ link: { linkType: 'call', phone: position.phone, ext: position.extension } });
		}

		const notes: string[] = [];
		if (position.isFounder) notes.push('Founder');
		if (position.isAdmin) notes.push('Admin');
		box.notes = notes.join(', ');

		boxes.push(box);
	}

	return boxes.sort((a, b) => a.buttons[0].explorer.nameOverride > b.buttons[0].explorer.nameOverride ? 1 : -1);
}