
import { DbcZip } from '@me-interfaces';
import { OldDomainDataService } from '..';
import { DdObjects } from '../dd-objects';
import { DdNearbyVenue } from './dd-nearby-venue';
import { DdVenue } from './dd-venue';
import { DdVenueRow } from './dd-venue-row';

/**
 * @deprecated Use the DataService instead
 */
export class DdVenues extends DdObjects<DdVenueRow, DdVenue> {

	constructor(private dd: OldDomainDataService) {
		super('venueId');
	}

	async loadData(hash: string, data: DdVenueRow[]) {
		const objects = data.map(d => new DdVenue(this.dd, d));
		this.loadObjects(hash, objects);
	}

	/**
	 * Return a list of venues that are nearby a provided zip. The distances are
	 * from roughly the center of the zip code area. 
	 * @param zip The lat/lon of the zip will be used to calculate distances.
	 * @param withinMiles Include all venues within this many statue miles. Multiply by 0.621371 to convert kilometers to miles. 
	 */
	public getNearbyVenues(zip: DbcZip, withinMiles = 50): DdNearbyVenue[] {
		//
		// Latitudes and longitudes are stored in the database as integers and
		// must be divided by 100 before used for calculations or used on a map.
		//
		const lat1 = zip.latitude / 100;
		const lon1 = zip.longitude / 100;

		return this
			.data
			.map(venue => {
				const lat2 = venue.latitude / 100;
				const lon2 = venue.longitude / 100;
				let miles = this.calcDistance(lat1, lon1, lat2, lon2);
				let kilometers = miles * 0.621371;

				miles = Math.round(miles * 100) / 100;
				kilometers = Math.round(kilometers * 100) / 100;

				return { ...(venue as DdVenueRow), distance: { zip, miles, kilometers } };
			})
			.filter(nearbyVenue => nearbyVenue.zipId == zip.zipId || nearbyVenue.distance.miles <= withinMiles)
			.sort((v1, v2) => v1.distance.miles - v2.distance.miles);

	}


	/**
	 * Apply some spherical geometry to calculate the distance in miles. Note that
	 * the earth is not a perfect sphere so the actual calculated distances are
	 * only approximations.
	 */
	private calcDistance(lat1: number, lon1: number, lat2: number, lon2: number) {

		const RAD = Math.PI / 180;

		const earthRadius = 3956; // statue miles (use 6367 for kilometers)
		const dLat = (lat2 - lat1) * RAD;
		const dLon = (lon2 - lon1) * RAD;
		const a =
			Math.sin(dLat / 2) * Math.sin(dLat / 2) +
			Math.cos(lat1 * RAD) * Math.cos(lat2 * RAD) *
			Math.sin(dLon / 2) * Math.sin(dLon / 2);
		const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		return earthRadius * c;
	}

}