
import { DdTagPrefixRow } from './dd-tag-prefix-row';
import { DdObject } from '../dd-object';
import { OldDomainDataService } from '../domain-data.service';
import { DdTag } from '../dd-tag/dd-tag';
import { DdSite } from '../dd-site/dd-site';

/**
 * @deprecated Use the DataService instead
 */
export class DdTagPrefix extends DdObject<DdTagPrefixRow> implements DdTagPrefixRow {

	constructor(private dd: OldDomainDataService, data: DdTagPrefixRow) {
		super(data);
	}

	public get tagPrefixId(): number {
		return this._data.tagPrefixId;
	}

	public get prefix(): string {
		return this._data.prefix;
	}

	public get siteId(): number | undefined {
		return this._data.siteId;
	}

	public get site(): DdSite | undefined {
		return this.dd.sites.getById(this.siteId);
	}


	public get tags(): DdTag[] {
		const tagPrefixId = this._data.tagPrefixId;
		const tags = this.dd.tags.objects;
		return tags.filter(tag => tag.tagPrefixId == tagPrefixId);
	}
}