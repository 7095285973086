import { DbsEvent } from "@me-interfaces";
import { Icon } from "@me-shared-parts/UI-common";

/**
* Calculate the status of an event based on startUTC, endUTC, toolStartUTC and toolEndUTC if any.
*/
export function getEventStatus(event: DbsEvent): 'Upcoming' | 'Underway' | 'Complete' {

	const now = Math.round(new Date().getTime() / 1000);

	// If start date is before the current date and time return upcoming
	if (event.startUTC > now || (event.toolStartUTC ?? 0) > now) return 'Upcoming';

	// If current date and time is between start and end date
	if ((now > event.startUTC && now < event.endUTC) || (now > (event.toolStartUTC ?? 0) && now < (event.toolEndUTC ?? 0))) {
		return 'Underway';
	}

	if (now > event.endUTC || now > (event.toolEndUTC ?? 0)) return 'Complete';
}


/**
 * Calculate the status of an event based on startUTC, endUTC, toolStartUTC and toolEndUTC if any
 * and return the icon that represents that status.
 */
export function getEventStatusIcon(event: DbsEvent): Icon {
	const status = getEventStatus(event);
	if (status == 'Upcoming') return Icon.view_event_future;
	if (status == 'Underway') return Icon.view_event_current;
	if (status == 'Complete') return Icon.view_event_past;
}

