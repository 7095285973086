import { DbsEvent, RoutePermission } from '@me-interfaces';

export interface ProgramEvents {
	accId?: number,
	picId?: number,
	siteId: number,
	siteCode: string,
	programName: string,
	routePermission: RoutePermission,
	events: DbsEvent[],
}