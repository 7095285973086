import { calcScore } from './calc-score';


/**
 * Given a set of questions ratings, and optionally a set of assessments,
 * calculate a combined score using 90% of the assessment score and 10%
 * of the ratings score.  If assessments aren't provided then the resulting
 * score is based solely on the question ratings score.
 */
export function calcCombinedScore(
	ratings: [number, number, number, number, number],
	assessments?: [number, number, number, number],
): { score: number, aScore: number, rScore: number, tooltip: string } {

	let score = 0;
	let aScore = 0;
	let rScore = 0;
	let tooltip = '';

	if (assessments) {

		const a = calcScore(assessments);
		const r = calcScore(ratings);

		aScore = a.score;
		rScore = r.score;
		score = a.score * 0.9 + r.score * 0.1;
		tooltip = `${r.count} Ratings = ${r.score.toFixed(1)}%,  ${a.count} Assessments = ${a.score.toFixed(1)}%,  Combined = ${score.toFixed(1)}%`;
	}
	else {

		const r = calcScore(ratings);

		score = rScore = r.score;
		tooltip = `${r.count} Ratings = ${r.score.toFixed(1)}%`;
	}


	return { score, aScore, rScore, tooltip };
}