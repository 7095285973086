import { DbsPerson, Immutable, RoutePermission, UserAlert } from "@me-interfaces";

export interface AdminRoutesMap { [index: string]: { [index: string]: RoutePermission } }


interface MutableDbcUser extends DbsPerson {
	readonly gender: string,
	readonly genderOther: string,
	readonly races: string,
	readonly raceOther: string,
	readonly hispanic: boolean,
	readonly birthYear: number,
	readonly birthDay: number,
	readonly veteran: boolean,
	readonly immigrant: boolean,
	readonly handicapped: boolean,
	readonly nativeEnglish: boolean,
	readonly nativeSpanish: boolean,
	readonly alerts: UserAlert[],
	readonly adminRoutes: AdminRoutesMap,
}


export interface DbcUser extends Immutable<MutableDbcUser> { }