<div class="edit-text-dialog">

	<me-dialog-frame [header]='header' [actions]='actions' [dialogRef]='dialogRef' icon='fa-sticky-note' alertType='editor'>

		<div class="me-field">
			<div class="edit-text-dialog-content">
				<textarea kendoTextBox [value]='text.value' (input)='onInput($event)' (blur)='onBlur()'
					placeholder='Enter {{header}} here' [style.width.%]='100' [disabled]='readonly'>
				</textarea>
			</div>
		</div>

	</me-dialog-frame>
</div>