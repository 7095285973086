import { NationalDashboardPageService, SharedDashboardsService } from '@ADMIN-area';
import { Component } from '@angular/core';
import { GridSetup, PersonCols } from '@me-grid';
import { map } from 'rxjs/operators';


@Component({
	selector: 'shared-entrepreneurs-view-part',
	templateUrl: './entrepreneurs-view.part.html',
})
export class SharedEntrepreneursViewPart {

	public gridSetup = this.setupGrid();

	public rows$ = this.sharedDashboardsService.dashboardData$.pipe(
		map(data => data ? data.overall.entrepreneurPersonIds.map(personId => ({ personId })) : [])
	);


	constructor(
		public pageService: NationalDashboardPageService,
		private sharedDashboardsService: SharedDashboardsService,
	) {
	}


	private setupGrid(): GridSetup<{ personId: number }> {

		return {
			experience: 'PERSON',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Entrepreneur",
			rowPluralName: "Entrepreneurs",
			rowKey: "personId",
			stateKey: "national-all-entrepreneurs",
			canAdd: false,
			canRefresh: false,
			canDownload: false,
			columnsToAlter: [
				{ field: PersonCols.location, hidden: false },
				{ field: PersonCols.language, hidden: false },
				{ field: PersonCols.applications, hidden: false },
				{ field: PersonCols.awards, hidden: false },
			],
			initialState: {},
		};
	}

}