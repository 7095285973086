<app-area-access [areaService]='accAreaService'>
	<div class='acc-application-part' *ngIf='applicationData$ | async; let applicationData'>
		<me-toolbar icon='Acc'>

			<div class="acc-application-toolbar-items">

				<me-droplist class='acc-application-status' [items]='applicationData.applicationList'
					[selectedItem]='applicationData.applicationSelectedItem' sortBy='text' [filterable]="false"
					[popupConfig]='{ message:"Select an application to open..." }'
					(selectionChange)='onApplicationChange($event)'>
				</me-droplist>

				<button class='btn' (click)='inspectApplication(applicationData.applicationId)'>
					<me-icon [icon]='icons.appl'></me-icon>
				</button>

				<button class='btn' [class.btn-green]='tool === "Application_StaffEditor"' (click)='toggleTool()'
					[disabled]='readonly'>
					<me-icon *ngIf='tool === "Application_StaffViewer"' [icon]='icons.edit'></me-icon>
					<me-icon *ngIf='tool === "Application_StaffEditor"' [icon]='icons.check'></me-icon>
				</button>

				<button class='btn' (click)='inspectCompany(applicationData.companyId)'>
					<me-icon [icon]='icons.company'></me-icon>
				</button>
				<button class='btn' (click)='inspectApplicant(applicationData.personId)'>
					<me-icon [icon]='icons.person'></me-icon>
				</button>


				<application-notes-button [applicationId]='applicationData.applicationId' [notes]='applicationData.notes' hideText='lt-xl' [readonly]='readonly'>
				</application-notes-button>

				<button [disabled]="readonly"
					*ngIf='applicationData.applicationStatusId === ApplicationStatusId_SelectSelected && !applicationData.accTeam'
					class='btn' (click)='addAccTeam(applicationData)'>
					<me-icon [icon]='icons.accTeam'></me-icon>
				</button>

				<button class='btn' (click)='transferApplication(applicationData)' [disabled]='readonly'>
					<me-icon [icon]='icons.transfer'></me-icon>
				</button>

				<button *ngIf="applicationData.copiedFromApplicationId" class='btn'
					(click)="navigate(applicationData.copiedFromApplicationId)">
					Open the Original Application
				</button>

				<button *ngIf="applicationData.copiedToApplicationId" class='btn'
					(click)="navigate(applicationData.copiedToApplicationId)">
					Open the CopiedForward Application
				</button>

			</div>

		</me-toolbar>

		<page-tabs [tabNames]='["Application", "Ratings", "Activity"]' [pageService]='pageService'
			(layoutChange)='onLayoutChange($event)'>
			<ng-template pageTab1>
				<application-display [applicationId]='applicationData.applicationId' [tool]='tool' showHeader='false'>
				</application-display>
			</ng-template>

			<ng-template pageTab2>
				<ng-container *ngIf='!readonly'>
					<p *ngIf='!applicationData.interviews.length'>This application has no interviews.</p>
					<div *ngIf='applicationData.interviews.length' class='rating-results'>
						<assessment-results header='Interviews' [data]='applicationData.interviews' order='cpq'
							defaultSummarize='assessments'>
						</assessment-results>
					</div>
					<hr>
					<p *ngIf='!applicationData.readings.length'>This application has no readings.</p>
					<div *ngIf='applicationData.readings.length' class='rating-results'>
						<assessment-results header='Readings' [data]='applicationData.readings' order='cpq'
							defaultSummarize='assessments'>
						</assessment-results>
					</div>
				</ng-container>
				<ng-container *ngIf='readonly'>
					<message-line alertType='warning'>Assessment Results are hidden because you do not have EDIT
						access.
					</message-line>
				</ng-container>
			</ng-template>

			<ng-template pageTab3>
				<div *ngIf='applicationData.applicationStatusLogs'>
					<application-status-logs [statusLogs]='applicationData.applicationStatusLogs' [type]="acc">
					</application-status-logs>
				</div>
			</ng-template>
		</page-tabs>
	</div>
</app-area-access>