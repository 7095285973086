
/**
 * Optional flags that may be exported in an action and
 * used by the shimmer to alter how the action will be called.
 */
export interface FanClientFlags {
	/**
	 * If true, and there is a call that was made before that hasn't completed, thenthat call will be canceled before calling again.
	 */
	abortPendingCall?: boolean,

	/**
	 * If true, the PageSpinner service will not trigger a full page spinner during this call.
	 */
	noPageSpinner?: boolean,
}