import { DashboardData } from "../data";
import { AccIndicatorValues, CommonIndicatorValues, GeneralIndicatorValues, PicIndicatorValues } from "../data/interfaces";
import { IndicatorName, PerformanceIndicator, PerformanceIndicatorSet } from "./indicator-interfaces";


export function renderIndicators(data: DashboardData) {

	if (!data) return [];

	const i = data.indicators;

	const kpiSets: PerformanceIndicatorSet[] = [
		{
			header: `${data.areaName} Totals`,
			indicators: sort([
				...getGeneralIndicators(data.areaName, data.indicators.totals),
				...getAccIndicators(data.indicators.totals, '', true),
				...getPicIndicators(data.indicators.totals, '', true),
				...getCommonIndicators(data.indicators.totals, '', 'Accelerator or Pitch Contest'),
			]),
		},
	];

	if (data.spanishAccelerators) kpiSets.push(
		{
			header: 'Spanish Accelerators',
			indicators: sort([
				...getAccIndicators(data.indicators.spanishAccs, 'Spanish'),
				...getCommonIndicators(data.indicators.spanishAccs, 'Spanish', 'Accelerator'),
			]),
		},
	);

	if (data.englishAccelerators) kpiSets.push(
		{
			header: 'English Accelerators',
			indicators: sort([
				...getAccIndicators(data.indicators.englishAccs, 'English'),
				...getCommonIndicators(data.indicators.englishAccs, 'English', 'Accelerator'),
			]),

		},
	);

	if (data.spanishPitchContests) kpiSets.push(
		{
			header: 'Spanish Pitch Contests',
			indicators: sort([
				...getPicIndicators(data.indicators.spanishPics, 'Spanish'),
				...getCommonIndicators(data.indicators.spanishPics, 'Spanish', 'Pitch Contest'),
			]),
		},
	);

	if (data.englishPitchContests) kpiSets.push(
		{
			header: 'English Pitch Contests',
			indicators: sort([
				...getPicIndicators(data.indicators.englishPics, 'English'),
				...getCommonIndicators(data.indicators.englishPics, 'English', 'Pitch Contest'),
			]),
		},
	);

	return kpiSets;
}


function sort(indicators: PerformanceIndicator[]): PerformanceIndicator[] {
	return indicators.sort((a1, a2) => a1.i - a2.i);
}


function formatNumber(n: number): string {
	return n.toLocaleString();
}


function formatPercent(n: number): string {
	return (n * 100).toFixed(1) + '%';
}


function formatDollars(n: number): string {
	return '$' + n.toLocaleString();
}


function getGeneralIndicators(
	areaName: string,
	d: GeneralIndicatorValues,
): PerformanceIndicator[] {
	const indicators = [
		// { i: 3, name: IndicatorName.Staff, value: formatNumber(d.staff), desc: ['Staff'], tooltip: 'EforAll Staff Members (full-time and part-time)' },
		// { i: 4, name: IndicatorName.BoardMembers, value: formatNumber(d.boardMembers), desc: ['Local', 'Board Members'], tooltip: 'Site Board Members' },
		// { i: 5, name: IndicatorName.ZipCodes, value: formatNumber(d.zipCodes), desc: ['Zip Codes'], tooltip: 'Zip Codes of areas supported by Sites' },
	];

	//
	// Regions and Sites don't have sites
	//
	if (areaName == 'Admin') indicators.unshift(
		{ i: 1, name: IndicatorName.Regions, value: formatNumber(d.regions), desc: ['Regions'], tooltip: 'Regions are groups of Sites' },
	);

	//
	// Sites don't have sites
	//
	if (areaName == 'Admin' || areaName == 'Region') indicators.unshift(
		{ i: 2, name: IndicatorName.Sites, value: formatNumber(d.sites), desc: ['Sites'], tooltip: 'Sites are geographic areas that offer local programming with dedicated staff' },
	);

	return indicators;
}


function getCommonIndicators(
	d: CommonIndicatorValues,
	language: '' | 'Spanish' | 'English',
	program: 'Accelerator' | 'Pitch Contest' | 'Accelerator or Pitch Contest',
): PerformanceIndicator[] {


	const lp = language == '' ? program : `${language} ${program}`;

	return [
		{ i: 20, name: IndicatorName.Readers, value: formatNumber(d.readers), desc: ['Readers'], tooltip: `Unique people that have read ${lp} applications. Total readings: ${formatNumber(d.readings)}` },
		{ i: 22, name: IndicatorName.Judges, value: formatNumber(d.judges), desc: ['Judges'], tooltip: `Unique people that have judged ${lp} participants. Total judgments: ${formatNumber(d.judgments)}` },
		{ i: 90, name: IndicatorName.Awardees, value: formatNumber(d.awardees), desc: ['Awardees'], tooltip: `Unique businesses that have received one or more ${lp} awards` },
		{ i: 91, name: IndicatorName.AwardedValue, value: formatDollars(d.awardedValue), desc: ['Awarded Value'], smallText: true, tooltip: `Total value awarded to ${lp} businesses` },
	];
}


function getPicIndicators(
	d: PicIndicatorValues,
	l: '' | 'Spanish' | 'English',
	showPrefix = false,
): PerformanceIndicator[] {

	const prefix = showPrefix ? 'Pic ' : '';
	const postfix = showPrefix ? ' Pic' : '';

	return [
		{ i: 15, name: IndicatorName.PitchContests, value: formatNumber(d.pitchContests), desc: ['Pitch Contests'], tooltip: `Total Pitch Contests, any stage, unless canceled` },
		{ i: 16, name: IndicatorName.PicApplications, value: formatNumber(d.picApplicationsOpen), desc: ['Open' + postfix, 'Applications'], tooltip: `Of the ${formatNumber(d.picApplicationsOpen + d.picApplicationsClosed)} ${l} Pitch Contest applications, ${formatNumber(d.picApplicationsOpen)} are open and ${formatNumber(d.picApplicationsClosed)} are closed` },
		{ i: 17, name: IndicatorName.PicApplications, value: formatPercent(d.picApplicationAcceptanceRate), desc: [prefix + 'Application', 'Acceptance Rate'], tooltip: `Of the ${formatNumber(d.picApplicationsSubmitted)} ${l} Pitch Contest applications that were SUBMITTED, ${formatNumber(d.picApplicationsAccepted)} businesses with ${formatNumber(d.picEntrepreneursAccepted)} entrepreneurs were accepted into pitch contests` },
	];
}


function getAccIndicators(
	d: AccIndicatorValues,
	l: '' | 'Spanish' | 'English',
	showPrefix = false,
): PerformanceIndicator[] {

	const prefix = showPrefix ? 'Acc ' : '';
	const postfix = showPrefix ? ' Acc' : '';

	const indicators = [

		{ i: 10, name: IndicatorName.Accelerators, value: formatNumber(d.accelerators), desc: ['Accelerators'], tooltip: `Total Accelerators, any stage, unless canceled` },
		{ i: 11, name: IndicatorName.AccApplications, value: formatNumber(d.accApplicationsOpen), desc: ['Open' + postfix, 'Applications'], tooltip: `Of the ${formatNumber(d.accApplicationsOpen + d.accApplicationsClosed)} ${l} Accelerator applications, ${formatNumber(d.accApplicationsOpen)} are open and ${formatNumber(d.accApplicationsClosed)} are closed` },
		{ i: 12, name: IndicatorName.AccApplications, value: formatPercent(d.accApplicationAcceptanceRate), desc: [prefix + 'Application', 'Acceptance Rate'], tooltip: `Of the ${formatNumber(d.accApplicationsSubmitted)} ${l} Accelerator applications that were SUBMITTED, ${formatNumber(d.accApplicationsAccepted)} businesses with ${formatNumber(d.accEntrepreneursAccepted)} entrepreneurs were accepted into cohorts` },

		{ i: 21, name: IndicatorName.Interviewers, value: formatNumber(d.interviewers), desc: ['Interviewers'], tooltip: `Unique people that have interviewed ${l} Accelerator applications. Total interviews: ${formatNumber(d.interviews)}` },
		{ i: 23, name: IndicatorName.Mentors, value: formatNumber(d.mentors), desc: ['Mentors'], tooltip: `Unique people that have mentored. Total Mentorships: ${formatNumber(d.mentorships)}` },
		{ i: 24, name: IndicatorName.Mentors, value: formatNumber(d.mentorRatio), desc: ['Mentor Ratio'], tooltip: `${formatNumber(d.accApplicationsAccepted)} Teams with ${formatNumber(d.mentorships)} Mentorships` + (d.mentorQuits > 0 ? `, including the ${formatNumber(d.mentorQuits)} Mentors that quit during the Accelerator` : ``) },

		{ i: 30, name: IndicatorName.Teams, value: formatPercent(d.teamGraduationRate), desc: ['Team', 'Graduation'], tooltip: `With the ${formatNumber(d.teamsGraduatable)} teams of post-curriculum ${l} Accelerators (after 12 weeks), ${formatNumber(d.teamsGraduated)} graduated and ${formatNumber(d.teamsGraduatable - d.teamsGraduated)} dropped out. Entrepreneurs that graduated: ${formatNumber(d.entrepreneursGraduated)}` },
		{ i: 31, name: IndicatorName.Teams, value: formatPercent(d.teamCompletionRate), desc: ['Team', 'Completion'], tooltip: `With the ${formatNumber(d.teamsCompletable)} teams of completed ${l} Accelerators (after 52 weeks), ${formatNumber(d.teamsCompleted)} completed and ${formatNumber(d.teamsCompletable - d.teamsCompleted)} dropped out. Entrepreneurs that completed: ${formatNumber(d.entrepreneursCompleted)}` },
	];

	if (d.acc3s > 0) {
		indicators.push(...[
			{ i: 32, name: IndicatorName.Teams, value: formatPercent(d.acc3BusinessesOpenRate), desc: ['Open Businesses of', '3+ Year Old Accs'], tooltip: `There are ${formatNumber(d.acc3s)} ${l} Accelerators that are 3+ years old, which have ${formatNumber(d.acc3Businesses)} Businesses that completed one of those Accelerators. Of those Businesses, ${formatNumber(d.acc3BusinessesOpen)} are currently marked as Open` },
		]);
	}

	return indicators;
}