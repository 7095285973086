import { PicTeam, DbConceptName, PhoneTypeId } from "@me-interfaces";
import { ExplorerBox, ExplorerBoxClass } from "../../../explorer-box/explorer-box";


export async function getBoxesForMember(picTeam: PicTeam): Promise<ExplorerBox[]> {

	const boxes: ExplorerBox[] = [];

	for (const member of [...picTeam.members].sort((a, b) => a.person._name > b.person._name ? 1 : -1)) {
		const box: ExplorerBox = {
			explorerBoxClass: ExplorerBoxClass.Member,
			header: 'Member',
			buttons: [
				{ explorer: { explorableId: { conceptName: DbConceptName.Person, id: member.member.personId }, navTooltip: `Member: ${member.person._name}` } },
				{ link: { linkType: 'email', email: member.person._email } },
			],
			notes: '',
		};

		if (member.person.phone) {
			box.buttons.push({ link: { linkType: 'call', phone: member.person.phone } });

			if (member.person.phoneTypeId == PhoneTypeId.Mobile) {
				box.buttons.push({ link: { linkType: 'sms', phone: member.person.phone } });
			}
		}

		boxes.push(box);
	}

	return boxes;
}