<me-dialog-frame [header]='header' [showToolbar]='true' [dialogRef]='dialogRef' [actions]='actions.list'
	icon='far fa-location-arrow'>

	<ng-container toolbar>
		<input kendoTextBox placeholder='Zip Code' style='width: 80px;display: inline-flex;'
			(input)='onZipInput($event)' (blur)='onZipBlur()' [attr.maxlength]='5' [(ngModel)]='zipCode' />
		<div *ngIf='zip' style="vertical-align: text-top;padding-left: .5em;">{{zip.cityAndState}}</div>
		<div *ngIf='invalid' class='venue-dialog-part-invalid-zip'>{{zipCode}} is not a valid zip code</div>
		<button *ngIf='zip && !adding' class="btn" style='float:right' (click)='setAdding(true)'>
			<me-icon [icon]='addIcon'></me-icon>
		</button>
	</ng-container>

	<div class="venue-dialog-part">

		<ng-container *ngIf='loading'>
			<div class='centered-text'>
				<i class='fad fa-spinner-third fa-spin'></i>
				Loading...
			</div>
		</ng-container>

		<ng-container *ngIf='!loading'>

			<ng-container *ngIf='zip'>
				<choose-venue-part *ngIf='!adding' [zip]='zip' [venueInitialChoice]='venueInitialChoice'
					(selection)='onVenueSelection($event)'>
				</choose-venue-part>
				<add-venue-part *ngIf='adding' [zip]='zip' (foundPlace)=onFoundPlace($event)></add-venue-part>
			</ng-container>

			<div *ngIf='!zip' class='centered-text'>
				Please enter a valid U.S. zip code in the field above.
			</div>

		</ng-container>

	</div>

</me-dialog-frame>