import { Dbc } from "@me-interfaces";


export interface DbcApplicationParticipantPending extends Dbc {
	readonly applicationParticipantPendingId: number,
	readonly applicationId: number,
	readonly firstName: string,
	readonly lastName: string,
	readonly email: string,
	readonly title: string,
}