import { Component, OnChanges, Input } from '@angular/core';

@Component({
	selector: 'ratings-five',
	templateUrl: './SHR-CMN_ratings-five.part.html',
	styleUrls: ['SHR-CMN_ratings-five.part.scss'],
})
export class RatingsFivePart implements OnChanges {

	@Input() ratings: number[];

	r1: number;
	r2: number;
	r3: number;
	r4: number;
	r5: number;

	ngOnChanges() {
		const r = this.ratings;
		this.r1 = r.length > 0 ? r[0] : 0;
		this.r2 = r.length > 1 ? r[1] : 0;
		this.r3 = r.length > 2 ? r[2] : 0;
		this.r4 = r.length > 3 ? r[3] : 0;
		this.r5 = r.length > 4 ? r[4] : 0;
	}
}
