<div class='acc-toolbar-buttons-part' *ngIf="accelerator">

	<app-area-reload-button [areaService]="accAreaService"></app-area-reload-button>

	<button *ngIf='!accelerator.siteProgram.site.isActive || accelerator.siteProgram.site.hidden' class='btn btn-red'
		(click)='openMessage()'>
		<me-icon [icon]='icons.message'></me-icon>
	</button>


	<button class="btn" (click)='inspectSite(accelerator.siteProgram.siteId)'>
		<me-icon [icon]='icons.site'></me-icon>
	</button>

	<button class='btn' (click)='inspectAcc(accelerator.accId)'>
		<me-icon [icon]='icons.acc'></me-icon>
	</button>

	<button class='btn' (click)='inspectPerson(accelerator.directorId)'>
		<me-icon [icon]='icons.ed'></me-icon>
	</button>

	<button class='btn' (click)='inspectPerson(accelerator.managerId)'>
		<me-icon [icon]='icons.pm'></me-icon>
	</button>

	<button class='btn' (click)='openTutorialLink()'>
		<me-icon [icon]='icons.tutorialLinks'></me-icon>
	</button>

	<button class='btn btn-red' (click)='goToOldAccAdmin()'>
		<me-icon [icon]='icons.oldAdmin'></me-icon>
	</button>

</div>