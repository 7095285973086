import { Component, Input, OnChanges } from '@angular/core';
import { DbsPerson } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { DialogService } from '@me-services/ui/dialog';
import { getConceptIcon, getIconContext, Icon, IconHideTextAt } from '@me-shared-parts/UI-common';
import { PersonExtractDialog } from '../person-extract-dialog/SHR-PN_person-extract-dialog.dialog';


@Component({
	selector: 'person-extract-button',
	templateUrl: './SHR-PN_person-extract-button.part.html',
	styleUrls: ['./SHR-PN_person-extract-button.part.scss']
})
export class PersonExtractButtonPart implements OnChanges {

	@Input() person: DbsPerson;
	@Input() hideText?: IconHideTextAt;

	icon = getIconContext(Icon.concept_person, undefined, this.hideText || 'never', '');


	constructor(private ds: DataService, private dialogService: DialogService) {
	}

	ngOnChanges() {
		this.icon = getConceptIcon(this.ds, this.person, undefined, this.hideText || 'never');
	}


	async open() {
		await this.dialogService.showCustom(PersonExtractDialog, { data: { person: this.person, }, }, 800, 500);
	}
}