import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';


@Component({
	selector: 'acc-reader-max-reads-editor-dialog',
	templateUrl: './max-reads-editor.dialog.html',
})
export class AccReaderMaxReadsEditorDialog extends DestroyablePart implements OnInit {
	//
	// The action buttons
	//
	saveAction: DialogAction<number> = {
		id: 'save',
		enabled: true,
		visible: true,
		label: ':Save',
		linkType: 'callback',
		callback: async () => (this.newMaxReads),
		willCloseDialog: true,
	};

	cancelAction: DialogAction = {
		id: 'close',
		enabled: true,
		visible: true,
		label: ':Cancel',
		linkType: "no-action",
		willCloseDialog: true,
	};

	actions: DialogAction<number | void>[] = [this.saveAction, this.cancelAction];
	icon = 'fa-pen';
	headerText = 'Edit Max Reads';
	readonly: boolean;
	orgMaxReads: number;
	newMaxReads: number;


	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ readonly: boolean, maxReads: number }>,
		public dialogRef: MatDialogRef<AccReaderMaxReadsEditorDialog>,
	) {
		super();
	}


	ngOnInit() {
		super.initDestroyable();

		const { readonly, maxReads } = this.dialog.data;
		this.readonly = readonly;
		this.orgMaxReads = this.newMaxReads = maxReads;

		this.checkIfCanSave();
	}


	/**
	 * This function is called as the user is typing/pasting/deleting into a field
	 */
	onInput(e) {
		let maxReads = +e.target.value;
		if (isNaN(maxReads)) maxReads = undefined;
		else {
			if (maxReads < 1) maxReads = undefined;
			else if (maxReads > 100) maxReads = 100;
		}
		this.newMaxReads = maxReads;
		this.checkIfCanSave();
	}


	/**
	 * Look at newMaxReads and determine if the save/update button should be enabled
	 */
	checkIfCanSave() {
		this.saveAction.enabled = !this.readonly && this.newMaxReads != this.orgMaxReads;
	}
}