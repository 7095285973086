<div class='application-part' *ngIf='application'>

	<div class="progressbar-wrapper">
		<kendo-progressbar style='font-size:1em;' [value]="progressValue" [label]="progressLabel"
			[emptyCssStyle]="emptyCssStyle" [progressCssStyle]='progressCssStyle'>
		</kendo-progressbar>
	</div>

	<kendo-panelbar class='bare-panelbar' [expandMode]="'single'" keepItemContent='true' selectable='false'
		animate='true' (stateChange)='panelChange($event)'>

		<kendo-panelbar-item id="instructions" title='' [selected]="expanded==='instructions'"
			[expanded]="expanded==='instructions'">

			<ng-template kendoPanelBarItemTitle>
				<application-editor-section-title [title]="inlineLabels['Instructions']" [remaining]="-1">
				</application-editor-section-title>
			</ng-template>

			<ng-template kendoPanelBarContent>
				<div class='instructions'>
					<application-instructions [overview]='application.overview'> </application-instructions>
				</div>
			</ng-template>

		</kendo-panelbar-item>

		<kendo-panelbar-item id="aboutCompany" title='' [selected]="expanded==='aboutCompany'"
			[expanded]="expanded==='aboutCompany'">

			<ng-template kendoPanelBarItemTitle>
				<application-editor-section-title [title]="inlineLabels['Tell us about Your Business']"
					[remaining]="(states.business.status$ | async).numRemaining"></application-editor-section-title>
			</ng-template>

			<ng-template kendoPanelBarContent>
				<div class='company-fields'>
					<p style='padding:7px'>
						<me-label key='md:Application-Tell us about Your Business'></me-label>
					</p>
					<field-grid [fields]='application.business.fields' [values]='application.business.values'
						[state]='states.business' [tool]='tool' [forceValidation]='forceValidation'
						[textFields]='textFields'>
					</field-grid>
				</div>
			</ng-template>

		</kendo-panelbar-item>

		<kendo-panelbar-item id='aboutYou' title='' [selected]="expanded==='aboutYou'"
			[expanded]="expanded==='aboutYou'">

			<ng-template kendoPanelBarItemTitle>
				<application-editor-section-title [title]="inlineLabels['Tell us about You']"
					[remaining]="(states.you.status$ | async).numRemaining"></application-editor-section-title>
			</ng-template>

			<ng-template kendoPanelBarContent>
				<div class="applicant-fields">
					<p style='padding:7px'>
						<me-label key='md:Application-Tell us about You'></me-label>
					</p>
					<field-grid [fields]='application.applicant.fields' [values]='application.applicant.values'
						[state]='states.you' [tool]='tool' [forceValidation]='forceValidation'
						[textFields]='textFields'>
					</field-grid>
				</div>
			</ng-template>

		</kendo-panelbar-item>

		<kendo-panelbar-item id='aboutTeam' title='' [selected]="expanded==='aboutTeam'"
			[expanded]="expanded==='aboutTeam'">

			<ng-template kendoPanelBarItemTitle>
				<application-editor-section-title [title]="inlineLabels['Tell us about Your Team']"
					[remaining]="(states.team.status$ | async).numRemaining"></application-editor-section-title>
			</ng-template>

			<ng-template kendoPanelBarContent>
				<div class="participant-fields">
					<application-participants [forceValidation]='forceValidation' [application]='application'
						[youState]='states.you' [teamState]='states.team' (openAboutYou)="expanded='aboutYou'">
					</application-participants>
				</div>
			</ng-template>

		</kendo-panelbar-item>

		<kendo-panelbar-item id='tellMore' title='' [selected]="expanded==='tellMore'"
			[expanded]="expanded==='tellMore'">

			<ng-template kendoPanelBarItemTitle>
				<application-editor-section-title [title]="inlineLabels['Tell us More'] + '...'"
					[remaining]="(states.more.status$ | async).numRemaining"></application-editor-section-title>
			</ng-template>

			<ng-template kendoPanelBarContent>
				<div class="question-fields">
					<field-grid [fields]='application.questions.fields' [values]='application.questions.values'
						[state]='states.more' [tool]='tool' [forceValidation]='forceValidation'
						[textFields]='textFields'>
					</field-grid>
				</div>
			</ng-template>

		</kendo-panelbar-item>

		<kendo-panelbar-item *ngIf='application.staffOnlyQuestions.fields.length' id='staffOnly' title=''
			[selected]="expanded==='staffOnly'" [expanded]="expanded==='staffOnly'">

			<ng-template kendoPanelBarItemTitle>
				<application-editor-section-title [title]="inlineLabels['Staff Only'] + '...'"
					[remaining]="(states.staffOnly.status$ | async).numRemaining"></application-editor-section-title>
			</ng-template>

			<ng-template kendoPanelBarContent>
				<div class="question-fields">
					<field-grid [fields]='application.staffOnlyQuestions.fields'
						[values]='application.staffOnlyQuestions.values' [state]='states.staffOnly' [tool]='tool'
						[forceValidation]='forceValidation' [textFields]='textFields'>
					</field-grid>
				</div>
			</ng-template>

		</kendo-panelbar-item>
	</kendo-panelbar>

	<button-container
		*ngIf='application.overview.access.applicantCanSubmit || application.overview.access.applicantCanWithdraw || application.overview.access.canUpdate'>
		<spinner-button
			*ngIf='application.overview.access.applicantCanSubmit || (application.overview.access.canUpdate && application.overview.access.applicationStatusId === applicationStatusIdAcceptPending)'
			[spinning]='submitting' [disabled]='submitting' (safeClick)='submit()'>
			<me-label key='Submit'></me-label>
		</spinner-button>
		<spinner-button
			*ngIf='application.overview.access.applicantCanWithdraw || (application.overview.access.canUpdate && (application.overview.access.applicationStatusId === applicationStatusIdAcceptPending || application.overview.access.applicationStatusId === applicationStatusIdAcceptWithdrawn))'
			[spinning]='withdrawing' [disabled]='withdrawing' (safeClick)='withdraw()'>
			<me-label key='Withdraw'></me-label>
		</spinner-button>
	</button-container>
</div>