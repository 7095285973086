import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { SpecificPicIndicatorValues } from "../interfaces";
import { ApplicationStatusId, OpenApplicationStatusIds, SubmittedApplicationStatusIds } from "@me-interfaces";


/**
 * @returns Just the pitch contest values
 */
export async function getSpecificPicIndicatorValues(
	ds: DataService,
	util: UtilityService,
	picIds: number[],
): Promise<SpecificPicIndicatorValues> {

	//
	// Applications and Entrepreneurs Accepted
	//
	const applicationsByPicId = await ds.admin.application.getByPicIds(picIds);
	const picApplications = util.array.fromArrayMap(applicationsByPicId, picIds);

	const picApplicationsOpen = picApplications.filter(a => OpenApplicationStatusIds.includes(a.applicationStatusId)).length;
	const picApplicationsClosed = picApplications.length - picApplicationsOpen;

	const acceptedApplicationIds = picApplications.filter(a => a.applicationStatusId == ApplicationStatusId.TeamPromoted).map(a => a.applicationId);
	const acceptedApplicationParticipantsByApplicationId = await ds.admin.applicationParticipant.getArraysByForeignIds('applicationId', acceptedApplicationIds);
	const acceptedApplicationParticipants = util.array.fromArrayMap(acceptedApplicationParticipantsByApplicationId, acceptedApplicationIds);
	const acceptedApplicationParticipantPersonIds = acceptedApplicationParticipants.map(p => p.personId);

	const acceptedTeamsByApplicationId = await ds.admin.picTeam.getByApplicationIds(acceptedApplicationIds);
	const acceptedTeams = util.array.fromArrayMap(acceptedTeamsByApplicationId, acceptedApplicationIds);
	const acceptedPicTeamIds = acceptedTeams.map(t => t.picTeamId);
	const acceptedTeamMembersByPicTeamId = await ds.admin.picTeamMember.getArraysByForeignIds('picTeamId', acceptedPicTeamIds);
	const acceptedTeamMembers = util.array.fromArrayMap(acceptedTeamMembersByPicTeamId, acceptedPicTeamIds);
	const acceptedTeamMemberPersonIds = acceptedTeamMembers.map(m => m.personId);

	const picApplicationsAccepted = acceptedApplicationIds.length;
	const picEntrepreneursAccepted = util.array.cleanNumericIds([...acceptedApplicationParticipantPersonIds, ...acceptedTeamMemberPersonIds]).length;


	//
	// Application Acceptance Rate
	//
	const submittedApplicationIds = picApplications.filter(a => SubmittedApplicationStatusIds.includes(a.applicationStatusId)).map(a => a.applicationId);

	const picApplicationsSubmitted = submittedApplicationIds.length;
	const picApplicationAcceptanceRate = picApplicationsSubmitted > 0 ? picApplicationsAccepted / picApplicationsSubmitted : 0;


	return {
		pitchContests: picIds.length,
		picApplicationsOpen,
		picApplicationsClosed,
		picApplicationsSubmitted,
		picApplicationsAccepted,
		picApplicationAcceptanceRate,
		picEntrepreneursAccepted,
	};
}