
import { DdEventTypeRow } from './dd-event-type-row';
import { DdEventType } from './dd-event-type';
import { DdObjects } from '../dd-objects';
import { OldDomainDataService } from '..';

/**
 * @deprecated Use the DataService instead
 */
export class DdEventTypes extends DdObjects<DdEventTypeRow, DdEventType> {

	constructor(private dd: OldDomainDataService) {
		super('eventTypeId');
	}

	async loadData(hash: string, data: DdEventTypeRow[]) {
		const objects = data.map(d => new DdEventType(this.dd, d));
		this.loadObjects(hash, objects);
	}
}