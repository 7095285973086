import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@me-services/core/auth';
import { ErrorPageService } from '@me-services/ui/error-page';

@Component({
	selector: 'error-generic-data-part',
	encapsulation: ViewEncapsulation.None,
	templateUrl: './error-generic-data.part.html',
	styleUrls: ['./error-generic-data.part.scss']
})
export class AccessErrorGenericDataPart implements OnInit {

	background = 'transparent';
	activity = '';
	name = '';
	message = '';
	stack = '';

	canlogout = true;

	constructor(
		private router: Router,
		private auth: AuthService,
		private errorPage: ErrorPageService,
	) {
	}

	ngOnInit() {
		const details = this.errorPage.snapshot;
		if (details.error) {
			this.background = 'darkred';
			this.activity = details.activity;
			this.name = details.name;
			this.message = details.message;
			this.stack = details.stack;

			dataLayer.push({
				'event': 'data-error',
				'Error Message': `${details.name}`,
				'Error Details': `${details.message}`
			});
		}
		else {
			this.background = 'darkgreen';
			this.activity = 'Normal Activity';
			this.name = 'No Error';
			this.message = 'Everything is hunky dory!';
			this.stack = '';
		}
	}

	goBack() {
		this.errorPage.clearError();
	}

	goToDashboard(): void {
		this.errorPage.clearError();
		this.router.navigate(['/access/my/dashboard']);
	}

	logout() {
		this.errorPage.clearError();
		this.auth.me.signout();
		this.canlogout = false;
	}
}