<mat-accordion class='simple-accordion' [multi]='true'>
	<mat-expansion-panel *ngFor='let roleGroup of tagsGroupedByRole' [expanded]='expanded'>
		<mat-expansion-panel-header>
			<mat-panel-title>
				<div>{{ roleGroup.role.toUpperCase() }}</div>
			</mat-panel-title>
		</mat-expansion-panel-header>
		<ng-container *ngFor='let tagAndCount of roleGroup.tags'>
			<old-tag [tag]='tagAndCount.tag' [clickable]='false' [showPrefix]='false'></old-tag><span *ngIf='showCount'> {{tagAndCount.count}}</span>
		</ng-container>
	</mat-expansion-panel>
</mat-accordion>