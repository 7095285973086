import { DbcZip, DbsPerson, DbsVenue, Venue } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { Observable } from "rxjs";
import { DomainDataManagers } from "../interfaces/domain-data-managers";
import { PackageManager } from "../package-manager";
import { SingletonsManager } from "../singletons-manager";


export class VenuePackageManager extends PackageManager<DbsVenue, Venue> {

	constructor(
		singletonsAsOfUTC$: Observable<number>,
		util: UtilityService,
		sm: SingletonsManager<DbsVenue>,
		private domain: DomainDataManagers,
		private person: SingletonsManager<DbsPerson>,
	) {
		super(singletonsAsOfUTC$, util, sm);
	}


	/**
	 * Convert an array of DbcVenue to an array of Venue
	 */
	protected async _createPackages(dbsVenues: DbsVenue[]): Promise<Venue[]> {

		//
		// Get all the people
		//
		const personIds: number[] = [];
		for (const venue of dbsVenues) {
			personIds.push(venue.createdByPersonId);
			personIds.push(venue.updatedByPersonId);
		}

		const personMap = await this.person.getManyAsMap(personIds);


		//
		// Get all the zips
		//
		const zipIds: number[] = [... new Set(dbsVenues.map(venue => venue.zipId))];
		const dbcZips = await this.domain.zip.getAll();

		const zipMap = zipIds.reduce((map, zipId) => {
			map[zipId] = dbcZips[zipId];
			return map;
		}, <{ [zipId: number]: DbcZip }>{});


		//
		// Package 'em up
		//
		const venues: Venue[] = dbsVenues.map(venue => {

			const createdByPerson = personMap[venue.createdByPersonId];
			const updatedByPerson = personMap[venue.updatedByPersonId];
			const zip = zipMap[venue.zipId];

			return {
				...venue,
				id: venue.venueId,
				name: venue.displayedName,
				explorerName: venue.displayedName,
				createdByPersonName: createdByPerson?._name || `Person #${venue.createdByPersonId}`,
				updatedByPersonName: updatedByPerson?._name || `Person #${venue.updatedByPersonId}`,
				zip,
				appUrl: null,
				hasRedFlag: false,
			};
		});

		return venues;
	}

}