/**
 * The initial set of data and option dialog instance identifier.
 */
export interface DialogData<T> {

	/**
	 * The DialogService.activity event streams dialog activities from any dialog launched from
	 * any component. Set the instanceIdentifier to uniquely identify a dialog instance when
	 * observing that stream.
	 * 
	 * Simple dialogs do not need to set instanceIdentifier
	 */
	instanceIdentifier?: string,

	/**
	 * An optional set of extra values.
	 */
	data?: T,

}


/**
 * When a matDialog is opened using the DialogService, an instance of DialogContext is
 * passed to it which includes an identifier for what launched it and an optional payload.
 */
export interface DialogContext<T> extends DialogData<T> {
	
	/**
	 * Actual width of the dialog which may be smaller than equested depending on the screen size.
	 */
	width: number,

	/**
	 * Actual height of the dialog which may be smaller than equested depending on the screen size.
	 */
	height: number,
}