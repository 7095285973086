import { AwardNameId } from '@me-interfaces';
import { DdObject } from '../dd-object';
import { OldDomainDataService } from '../domain-data.service';
import { DdAwardNameEventTypeRow } from './dd-award-name_event-type-row';

/**
 * @deprecated Use the DataService instead
 */
export class DdAwardNameEventType extends DdObject<DdAwardNameEventTypeRow> implements DdAwardNameEventTypeRow {

	constructor(dd: OldDomainDataService, data: DdAwardNameEventTypeRow) {
		super(data);
	}

	public get awardNameEventTypeId(): number {
		return this._data.awardNameEventTypeId;
	}

	public get awardNameId(): number {
		return this._data.awardNameId;
	}

	public get eventTypeId(): number {
		return this._data.eventTypeId;
	}
}