import { DbConceptName, ExplorableConceptName } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { DialogAction } from '@me-services/ui/dialog';
import { SearchDialog } from './dialog/search.dialog';
import { SearchService } from './service/search-service';


/**
 * The data service exposes a readonly singleton of this to 
 * manage the search dialog and results.
 */
export class Searcher {

	public readonly searchService = new SearchService(this.ds);

	
	constructor(private ds: DataService) {
	}

	private async _openDialog(limitedToConcept: ExplorableConceptName = undefined): Promise<{ conceptName: ExplorableConceptName, id: number } | undefined> {
		const action: DialogAction<{ conceptName: ExplorableConceptName, id: number } | void> = await this.ds.dialogService.showCustom(SearchDialog, { data: { limitedToConcept } }, 900, 700);

		return action?.callbackResult || undefined;
	}


	async select(): Promise<{ conceptName: ExplorableConceptName, id: number } | undefined> {
		return await this._openDialog();
	}


	async selectPerson(): Promise<{ conceptName: DbConceptName.Person, id: number } | undefined> {
		const result = await this._openDialog(DbConceptName.Person);

		if (result && result.conceptName == DbConceptName.Person) return { conceptName: DbConceptName.Person, id: result.id };
		return undefined;

	}

	async selectCompany(): Promise<{ conceptName: DbConceptName.Company, id: number } | undefined> {
		const result = await this._openDialog(DbConceptName.Company);

		if (result && result.conceptName == DbConceptName.Company) return { conceptName: DbConceptName.Company, id: result.id };
		return undefined;

	}

}