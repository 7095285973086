import { Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { UtilityService } from '@me-services/core/utility';
import { LabelsService } from '@me-services/ui/labels';
import { LayoutDimensions, LayoutService } from '@me-services/ui/layout';
import { PAGE_TABS_LEFT_AND_RIGHT_PADDING, TAB1_VIEW_DROPLIST_HEIGHT, TABS_COLUMN_GAP, TOOLBAR_HEIGHT } from '@me-services/ui/layout/layout-constants';
import { MenuService } from '@me-services/ui/menu';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { PageTabsLayoutPageService } from '.';
import { DEFAULT_CONFIG, PageTabsConfig } from './SHR-CMN_page-tabs-config';
import { PageTabsLayout } from './SHR-CMN_page-tabs-layout';
import { PageTab1Directive, PageTab2Directive, PageTab3Directive, PageTab4Directive, PageTab5Directive, PageTab6Directive, PageTab7Directive } from './SHR-CMN_page-tabs.directives';


@Component({
	selector: 'page-tabs',
	templateUrl: './SHR-CMN_page-tabs.part.html',
	styleUrls: ['SHR-CMN_page-tabs.part.scss'],
})
export class PageTabsPart extends DestroyablePart implements OnInit {

	@Input() tabNames: string[] = ['One', 'Two'];
	@Input() pageService: PageTabsLayoutPageService<number, number> = undefined;
	@Input() config: PageTabsConfig = DEFAULT_CONFIG;

	@Output() layoutChange = new EventEmitter<PageTabsLayout>();

	@ContentChild(PageTab1Directive, { static: true }) pageTab1: PageTab1Directive;
	@ContentChild(PageTab2Directive, { static: true }) pageTab2: PageTab2Directive;
	@ContentChild(PageTab3Directive, { static: true }) pageTab3: PageTab3Directive;
	@ContentChild(PageTab4Directive, { static: true }) pageTab4: PageTab4Directive;
	@ContentChild(PageTab5Directive, { static: true }) pageTab5: PageTab5Directive;
	@ContentChild(PageTab6Directive, { static: true }) pageTab6: PageTab6Directive;
	@ContentChild(PageTab7Directive, { static: true }) pageTab7: PageTab7Directive;


	readonly gap = 13; // number of pixels between expanded first tab and page tabs
	gridTemplateColumns = '1fr';

	contentHeight = 500;

	tab1ShouldExpand$ = new BehaviorSubject(true);
	tab1ForceCollapse$ = new BehaviorSubject(false);

	tab1Expanded$ = combineLatest([this.tab1ShouldExpand$, this.tab1ForceCollapse$]).pipe(
		map(([shouldExpand, forceCollapse]: [boolean, boolean]) => shouldExpand && !forceCollapse),
		distinctUntilChanged(),
	);

	leftTabIndex$ = new BehaviorSubject<number>(0);
	rightTabIndex$ = new BehaviorSubject<number>(1);

	extraTabs: { name: string, template: TemplateRef<unknown> }[]


	constructor(
		private labelsService: LabelsService,
		private layoutService: LayoutService,
		private menu: MenuService,
		private util: UtilityService,
	) {
		super();
	}


	async ngOnInit() {
		super.initDestroyable();

		//
		// Validate paramters
		//
		if (!this.tabNames) throw new Error(`Missing required attribute: tabNames.`);
		if ((this.tabNames?.length ?? 0) < 2) throw new Error(`Attribute tabNames must be at least 2 tab names.`);
		if ((this.tabNames?.length ?? 0) > 7) this.util.log.errorMessage(`Attribute tabNames exceeds the maximum of 7 tab names.`);
		if (!this.pageService) throw new Error('Missing required attribute: pageService. Pass the page service which should be a subclass of PageTabsLayoutPageService.');


		//
		// Initialize selections
		//
		this.leftTabIndex$.next(0);
		this.rightTabIndex$.next(1);


		//
		// Merge in default values for any missing config values
		//
		this.config = { ...DEFAULT_CONFIG, ...(this.config || {}) };


		//
		// Translate labels if needed
		//
		for (let i = 0; i < this.tabNames.length; i++) {
			this.tabNames[i] = await this.labelsService.get(this.tabNames[i]);
		}


		//
		// Setup all the tabs beyond the main one
		//
		this.extraTabs = this.getExtraTabs();


		//
		// Select the first tab
		//
		this.selectTab(0);


		//
		// Handle layout changes
		//
		const dimensions = this.layoutService.dimensions$.value;
		this.applyDimensions(dimensions);

		super.subscribe([this.layoutService.dimensions$], async ([dimensions]) => {
			this.applyDimensions(dimensions);
			this.buildPageTabsLayout();
		});


		super.subscribe([
			this.pageService.selectedTab$,
			this.tab1Expanded$,
		],
			async ([tabId, expanded]) => {
				//
				// tabId should be an enum with the first entry having a value of 1
				// So, we subract one so it can be indexed into the tabNames list.
				//
				tabId--;

				this.selectTab(tabId);
				this.gridTemplateColumns = '1fr' + (expanded ? ` ${this.config.pageTabsWidth}px` : '');

				this.buildPageTabsLayout();
			});
	}



	/**
	 * Returns the list of tab names, not including the first one (the main tab)
	 */
	private getExtraTabs(): { name: string, template: TemplateRef<unknown> }[] {

		if ((this.tabNames?.length ?? 0) < 2) return [];

		return this.tabNames.slice(1).map((name: string, i: number) => {

			let template: TemplateRef<unknown> = undefined;

			if (i == 0) template = this.pageTab2?.tpl;
			if (i == 1) template = this.pageTab3?.tpl;
			if (i == 2) template = this.pageTab4?.tpl;
			if (i == 3) template = this.pageTab5?.tpl;
			if (i == 4) template = this.pageTab6?.tpl;
			if (i == 5) template = this.pageTab7?.tpl;

			return { name, template };
		});

	}


	/**
	 * Given an index value into the tabNames array
	 */
	selectTab(tabId: number) {

		const expanded = this.tab1ShouldExpand$.value && !this.tab1ForceCollapse$.value;

		if (expanded) {
			if (this.leftTabIndex$.value !== 0) this.leftTabIndex$.next(0);
			if (tabId < 1) tabId = 1;	// Skip the main tab because it's on the left side
			if (this.rightTabIndex$.value !== tabId) this.rightTabIndex$.next(tabId);
		}
		else {
			if (this.leftTabIndex$.value !== tabId) this.leftTabIndex$.next(tabId);
			if (this.rightTabIndex$.value !== tabId) this.rightTabIndex$.next(tabId);
		}

	}


	/**
	 * Handle the kendo-tabstrip (tabSelect) event which is fired when a tab is changed by the user. 
	 */
	onTabSelect(side: 'left' | 'right', tab: SelectEvent) {

		const tabNameIndex = this.tabNames.indexOf(tab.title);
		this.pageService.selectTab(tabNameIndex + 1);
		this.selectTab(tabNameIndex);
	}



	/**
	 * Called whenever the dimensions change in the layout service
	 */
	async applyDimensions(dimensions: LayoutDimensions): Promise<void> {

		const breakpoint = this.config.breakpoint + this.gap + this.config.pageTabsWidth;

		//
		// Determine whether the first tab is broken out or part of the tab strip
		//
		const shouldExpand = dimensions.contentWidth >= breakpoint;

		if (this.tab1ShouldExpand$.value !== shouldExpand) {
			this.tab1ShouldExpand$.next(shouldExpand);
		}
	}


	/**
	 * Called whenever the dimensions change in the layout service
	 */
	buildPageTabsLayout() {

		let dimensions = this.layoutService.dimensions$.value;

		const forceCollapse = this.tab1ForceCollapse$.value;
		const shouldExpand = this.tab1ShouldExpand$.value;
		const tab1Expanded = shouldExpand && !forceCollapse;

		//
		// Subtract the toolbar height if there is one.
		//
		if (this.config.hasToolbar) {
			dimensions = {
				...dimensions,
				contentHeight: dimensions.contentHeight - TOOLBAR_HEIGHT,
				pageTabContentHeight: dimensions.pageTabContentHeight - TOOLBAR_HEIGHT
			};
		}

		//
		// Set the content height
		//
		this.contentHeight = dimensions.contentHeight;

		//
		// Emit the layout
		//
		const pageTabContentWidth = dimensions.contentWidth - PAGE_TABS_LEFT_AND_RIGHT_PADDING;
		const tabContentWidth = this.config.pageTabsWidth - PAGE_TABS_LEFT_AND_RIGHT_PADDING;
		const tab1ExpandedContentWidth = dimensions.contentWidth - this.config.pageTabsWidth - TABS_COLUMN_GAP - PAGE_TABS_LEFT_AND_RIGHT_PADDING;

		const layout: PageTabsLayout = {
			tab1Expanded: tab1Expanded,
			tab1ContentWidth: tab1Expanded ? tab1ExpandedContentWidth : pageTabContentWidth,
			tab1ContentHeight: dimensions.pageTabContentHeight,
			tab1ViewDroplistHeight: TAB1_VIEW_DROPLIST_HEIGHT,
			tabContentWidth: tab1Expanded ? tabContentWidth : pageTabContentWidth,
			tabContentHeight: dimensions.pageTabContentHeight,
			dimensions,
		};

		this.layoutChange.emit(layout);
	}


	toggleForce() {
		this.menu.setCollapsed(true);
		this.tab1ForceCollapse$.next(!this.tab1ForceCollapse$.value);
	}
}
