import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';
import { DroplistItem, getIconClass, getIconContext, Icon } from '@me-shared-parts/UI-common';

export type AccTeamMemberEditEntrepreneurDialogCallbackValue = { accTeamMemberId: number };

@Component({
	selector: 'acc-team-members-edit-entrepreneur-dialog',
	templateUrl: './acc-team-members-edit-entrepreneur.dialog.html',
	styleUrls: ['./acc-team-members-edit-entrepreneur.dialog.scss']
})
export class AccTeamMembersEditEntrepreneurDialog extends DestroyablePart implements OnInit {

	readonly: boolean;

	items: DroplistItem[] = [
		{ uniqueId: 1, iconContext: getIconContext(Icon.concept_person, undefined, undefined, 'Entrepreneur'), customSort: '1' },
	];
	selectedItem: DroplistItem = this.items[0];

	//
	// The action buttons
	//

	//
	// The action buttons
	//

	updateAction: DialogAction = {
		id: 'update',
		enabled: false,
		visible: true,
		label: 'Update',
		linkType: "no-action",
		willCloseDialog: true,
	};

	cancelAction: DialogAction = {
		id: 'close',
		enabled: true,
		visible: true,
		label: ':Cancel',
		linkType: "no-action",
		willCloseDialog: true,
	};

	removeAction: DialogAction<AccTeamMemberEditEntrepreneurDialogCallbackValue> = {
		id: 'remove',
		enabled: true,
		visible: true,
		label: ':Remove',
		linkType: 'callback',
		callback: async () => ({
			accTeamMemberId: this.dialog.data.accTeamMemberId,
		}),
		willCloseDialog: true,
	};

	actions: DialogAction<AccTeamMemberEditEntrepreneurDialogCallbackValue | void>[] = [this.updateAction, this.cancelAction, this.removeAction];
	icon = getIconClass(Icon.action_edit);
	memberName: string;


	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ readonly: boolean, accTeamMemberId: number, memberName: string }>,
		public dialogRef: MatDialogRef<AccTeamMembersEditEntrepreneurDialog>,
	) {
		super();
	}


	async ngOnInit() {
		super.initDestroyable();

		this.readonly = this.dialog.data.readonly;
		this.memberName = this.dialog.data.memberName;
	}

}