import { AccAreaService, StatusIcon } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AccStageId, DbaAccJudgeEvent, DbdAccStage } from '@me-interfaces';
import { Icon, getIconClass } from '@me-shared-parts/UI-common';


@Component({
	selector: 'acc-final-tab-part',
	templateUrl: './acc-final-tab.part.html',
	styleUrls: ['./acc-final-tab.part.scss'],
})
export class AccFinalTabPart extends DestroyablePart implements OnInit {

	accStageId = AccStageId.Curriculum;

	stageStatusIcon: StatusIcon;
	judgesStatusIcon: StatusIcon;
	teamsStatusIcon: StatusIcon;
	eventsStatusIcon: StatusIcon;

	constructor(
		public accAreaService: AccAreaService,
	) {
		super();
	}

	ngOnInit(): void {
		super.initDestroyable();

		super.subscribe([this.accAreaService.accelerator.acc$, this.accAreaService.teams.teams$, this.accAreaService.judging.judges$, this.accAreaService.judging.judgeEvents$], async ([acc, teams, judges, events]) => {
			this.stageStatusIcon = this.getStageStatusIcon(acc?.stage);
			this.setTeamsStatusIcon(teams?.length);
			this.setJudgesStatusIcon(judges?.length);
			if (events) this.setEventsStatusIcon(events);
		}
		)
	}

	getStageStatusIcon(accStage: DbdAccStage) {
		if (accStage?.accStageId && accStage.accStageId != this.accStageId) return {
			icon: {
				icon: Icon.status_x,
				iconClass: getIconClass(Icon.status_x),
				text: `Current Stage: ${accStage.name}`,
			},
		};
		return undefined;
	}

	setTeamsStatusIcon(totalAccTeams: number) {
		const icon: Icon = totalAccTeams > 0 ? Icon.status_success : Icon.status_x;
		const link = '';

		this.teamsStatusIcon = {
			icon: {
				icon,
				iconClass: getIconClass(icon),
				text: `${totalAccTeams} Team${totalAccTeams != 1 ? 's' : ''} in Cohort`,
			},
			link: totalAccTeams > 0 ? undefined : link,
		};
	}

	setJudgesStatusIcon(totalJudges: number) {
		const icon: Icon = totalJudges > 0 ? Icon.status_success : Icon.status_x;
		const link = '';

		this.judgesStatusIcon = {
			icon: {
				icon,
				iconClass: getIconClass(icon),
				text: `${totalJudges} Judge${totalJudges != 1 ? 's' : ''}`,
			},
			link: totalJudges > 0 ? undefined : link,
		};
	}


	setEventsStatusIcon(events: readonly DbaAccJudgeEvent[]) {
		const icon: Icon = events?.length > 0 ? Icon.status_success : Icon.status_x;
		const link = '';

		this.eventsStatusIcon = {
			icon: {
				icon,
				iconClass: getIconClass(icon),
				text: `${events?.length} Final Presentation Event${events?.length != 1 ? 's' : ''}`,
			},
			link: events?.length > 0 ? undefined : link,
		};
	}

}
