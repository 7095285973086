import { EventTypeId, EventAttendedBy } from "@me-interfaces";

/**
 * @deprecated Use the DataService instead
 */
export interface DdEventTypeRow {
	eventTypeId: EventTypeId,
	fullName: string,
	name: string,
	toolNameLabelKey: string,
	org: 'EforAll' | 'Eforever',
	attendedBy: EventAttendedBy,
}