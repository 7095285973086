import { Icon } from "@me-shared-parts/UI-common";
import { IconBarIcon } from "@me-shared-parts/UI-common/icon-bar";

export const VIEW_DETAILS = 'details';
export const VIEW_COHORT = 'cohort';
export const VIEW_MENTORS = 'mentors';
export const VIEW_APPLICATIONS = 'applications';
export const VIEW_EVENTS = 'events';
export const VIEW_AWARDS = 'awards';

export const views: IconBarIcon[] = [
	{ key: VIEW_DETAILS, icon: Icon.action_more, tooltip: 'Accelerator Details', selected: true },
	{ key: VIEW_COHORT, icon: Icon.concept_accTeam, tooltip: 'Cohort', selected: true },
	{ key: VIEW_MENTORS, icon: Icon.view_mentors, tooltip: 'Mentor', selected: true },
	{ key: VIEW_APPLICATIONS, icon: Icon.concept_application, tooltip: 'Applications', selected: true },
	{ key: VIEW_EVENTS, icon: Icon.concept_event, tooltip: 'Events', selected: true },
	{ key: VIEW_AWARDS, icon: Icon.concept_award, tooltip: 'Awards', selected: true },
];