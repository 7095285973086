import { AccAreaService } from '@ACC-area';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { MatchingHint, SevenNums } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';
import { calcAccTeamOrMentorToMentorOverlap } from '@me-services/ui/schedule-utils';
import { DroplistItem, getIconClass, getIconContext, Icon } from '@me-shared-parts/UI-common';
import { SiteAreaService } from '@SITE-area';


@Component({
	selector: 'matching-hints-edit-dialog',
	templateUrl: './matching-hints-edit.dialog.html',
})
export class MatchingHintsEditDialog extends DestroyablePart implements OnInit {
	//
	// The action buttons
	//
	updateAction: DialogAction<MatchingHint> = {
		id: 'update',
		enabled: false,
		visible: true,
		label: 'Update',
		linkType: 'callback',
		callback: async () => (this.newSelectedValue),
		willCloseDialog: true,
	};

	cancelAction: DialogAction = {
		id: 'close',
		enabled: true,
		visible: true,
		label: 'Cancel',
		linkType: "no-action",
		willCloseDialog: true,
	};

	actions: DialogAction<MatchingHint | void>[] = [this.updateAction, this.cancelAction];
	icon = getIconClass(Icon.action_edit);
	header = 'Edit Hint';

	private coachTagId: number;

	types: DroplistItem<{ kind: "T+M" | "T-M" | "M+M" | "M-M" }>[] = [
		{ iconContext: getIconContext(Icon.droplist_pickOne, undefined, 'never', 'Team with Mentor'), uniqueId: 1, data: { kind: 'T+M' } },
		{ iconContext: getIconContext(Icon.droplist_pickOne, undefined, 'never', 'Team not with Mentor'), uniqueId: 2, data: { kind: 'T-M' } },
		{ iconContext: getIconContext(Icon.droplist_pickOne, undefined, 'never', 'Mentor with Mentor'), uniqueId: 3, data: { kind: 'M+M' } },
		{ iconContext: getIconContext(Icon.droplist_pickOne, undefined, 'never', 'Mentor not with Mentor'), uniqueId: 4, data: { kind: 'M-M' } },
	];
	selectedType: DroplistItem<{ kind: "T+M" | "T-M" | "M+M" | "M-M" }>;

	importances: DroplistItem<{ critical: boolean }>[] = [
		{ iconContext: getIconContext(Icon.droplist_pickOne, undefined, 'never', 'Strongly Desired'), uniqueId: 1, data: { critical: true } },
		{ iconContext: getIconContext(Icon.droplist_pickOne, undefined, 'never', 'Nice to have'), uniqueId: 2, data: { critical: false } }
	];
	selectedImportance: DroplistItem<{ critical: boolean }>;

	teams: DroplistItem<{ schedule: SevenNums }>[];
	mentors: DroplistItem<{ schedule: SevenNums }>[];
	roleSelected: 'Mentor' | 'Team';
	selectedRole: DroplistItem<{ schedule: SevenNums }>;
	selectedMentor: DroplistItem<{ schedule: SevenNums }>;
	readonly: boolean;

	newSelectedValue: MatchingHint = { critical: undefined, id1: undefined, id2: undefined, kind: undefined, overlaps: undefined };


	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ isAdd: boolean, kind: 'T+M' | 'T-M' | 'M+M' | 'M-M', id1: number, id2: number, critical: boolean, overlaps: number }>,
		public dialogRef: MatDialogRef<MatchingHintsEditDialog>,
		private util: UtilityService,
		private ds: DataService,
		private siteAreaService: SiteAreaService,
		private accAreaService: AccAreaService,
	) {
		super();
	}


	async ngOnInit() {
		super.initDestroyable();

		const siteId = this.siteAreaService.getId().id;
		const siteTags = (await this.ds.admin.tag.getAllPackagesAsArray()).filter(tag => tag.site && tag.site.siteId == siteId);
		this.coachTagId = siteTags.find(tag => tag.name == 'coach-approved')?.tagId;


		super.subscribe([
			this.accAreaService.accessAndId$,
			this.accAreaService.mentorMatching.matchableMentors$,
			this.accAreaService.teams.teams$,
		], async ([accessAndId, mentors, teams]) => {

			this.readonly = accessAndId?.access?.root != 'Write';

			this.teams = teams.map(team => {
				return {
					iconContext: getIconContext(Icon.concept_accTeam, undefined, 'never', team.name),
					uniqueId: team.accTeamId,
					subText: team.application.person._name,
					data: { schedule: <SevenNums>team.schedule },
				}
			});

			this.mentors = mentors.map(mentor => {
				const hasCoachTag = mentor.person.tags.find(tag => tag.tagId == this.coachTagId);
				return {
					iconContext: getIconContext(Icon.concept_person, undefined, 'never', `${mentor.person.fullName}${hasCoachTag ? '(c)' : ''}`),
					uniqueId: mentor.personId,
					data: { schedule: mentor.schedule },
				}
			});

		});

		const { isAdd, critical, id1, id2, kind, overlaps } = this.dialog.data;


		if (isAdd) {
			this.header = 'Add Hint';
			this.icon = getIconClass(Icon.action_add);
			this.updateAction.id = 'add';
			this.updateAction.label = 'Add';
			this.updateAction.visible = true;
			this.newSelectedValue = { critical: undefined, id1: undefined, id2: undefined, kind: undefined, overlaps: undefined };
		}
		else {
			this.newSelectedValue = { critical, id1, id2, kind, overlaps };
			this.selectedType = this.types.find(type => type.data.kind == kind);
			this.selectedImportance = this.importances.find(importance => importance.data.critical == critical);
			if (kind == 'T+M' || kind == 'T-M') {
				this.selectedRole = this.teams.find(team => team.uniqueId == id1);
				this.roleSelected = 'Team';
			}
			else {
				this.selectedRole = this.mentors.find(mentor => mentor.uniqueId == id1);
				this.roleSelected = 'Mentor';
			}
			this.selectedMentor = this.mentors.find(mentor => mentor.uniqueId == id2);
		}

		this.checkIfCanSave();
	}

	checkIfCanSave() {
		this.newSelectedValue.overlaps = undefined;
		if (!!this.newSelectedValue.id1 && !!this.newSelectedValue.id2) {
			this.newSelectedValue.overlaps = calcAccTeamOrMentorToMentorOverlap(this.selectedRole.data.schedule, this.selectedMentor.data.schedule);
		}

		const data = this.dialog.data;
		this.updateAction.enabled = !this.readonly
			&& !!this.newSelectedValue.kind
			&& !!this.newSelectedValue.id1
			&& !!this.newSelectedValue.id2
			&& this.newSelectedValue.critical !== undefined
			&& (this.newSelectedValue.kind != data.kind
				|| this.newSelectedValue.critical != data.critical
				|| this.newSelectedValue.id1 != data.id1
				|| this.newSelectedValue.id2 != data.id2
			);
	}


	hintOverlapsAlertType() {
		if (this.newSelectedValue.kind[1] == '+') {
			if (!this.newSelectedValue.overlaps) return 'error';
			if (this.newSelectedValue.overlaps < 5) return 'warning';
		}
		return 'info';
	}


	public onTypeSelectionChange(e: DroplistItem<{ kind: "T+M" | "T-M" | "M+M" | "M-M" }>) {
		this.selectedType = e;
		this.newSelectedValue.kind = e ? e.data.kind : undefined;
		if (["T+M", "T-M"].includes(this.newSelectedValue.kind)) this.roleSelected = 'Team';
		else if (["M+M", "M-M"].includes(this.newSelectedValue.kind)) this.roleSelected = 'Mentor';
		this.selectedRole = undefined;
		this.newSelectedValue.id1 = undefined;
		this.checkIfCanSave();
	}


	public onSelectionChange(e: DroplistItem) {
		this.selectedRole = e;
		this.newSelectedValue.id1 = e ? this.selectedRole.uniqueId : undefined;
		this.checkIfCanSave();
	}


	public onMentorSelectionChange(e: DroplistItem) {
		this.selectedMentor = e;
		this.newSelectedValue.id2 = e ? this.selectedMentor.uniqueId : undefined;
		this.checkIfCanSave();
	}


	public onImportanceSelectionChange(e: DroplistItem<{ critical: boolean }>) {
		this.selectedImportance = e;
		this.newSelectedValue.critical = e ? e.data.critical : undefined;

		this.checkIfCanSave();
	}




}