import { Icon } from "@me-shared-parts/UI-common";
import { IconBarIcon } from "@me-shared-parts/UI-common/icon-bar";

export const VIEW_DETAILS = 'details';
export const VIEW_ACCELERATORS = 'accelerators';
export const VIEW_PITCH_CONTESTS = 'pitchContests';
export const VIEW_EVENTS = 'events';

export const views: IconBarIcon[] = [
	{ key: VIEW_DETAILS, icon: Icon.action_more, tooltip: 'Site Details', selected: true },
	{ key: VIEW_ACCELERATORS, icon: Icon.concept_accelerator, tooltip: 'Accelerators', selected: true },
	{ key: VIEW_PITCH_CONTESTS, icon: Icon.concept_pitchContest, tooltip: 'Pitch Contests', selected: true },
	{ key: VIEW_EVENTS, icon: Icon.concept_event, tooltip: 'Events', selected: true },
];