import { AppAreaIdentifier, DbsSite, Site } from "@me-interfaces";
import { DataService } from "@me-services/core/data";

/**
 * Given an identifier for national, a region, or a site, return the list of Site packages
 */
export async function getSites(ds: DataService, identifier: AppAreaIdentifier<number>): Promise<{ sites: ReadonlyArray<Site>, siteIds: Readonly<number[]> }> {
	
	const response: { sites: ReadonlyArray<Site>, siteIds: Readonly<number[]> } = { sites: [], siteIds: [] };

	const areaName = identifier?.areaName;
	if (!['Admin', 'Region', 'Site'].includes(areaName)) return response;


	if (areaName == 'Site') {
		const siteId = identifier.id;
		response.sites = [await ds.admin.site.getOnePackage(siteId)];
	}

	else if (areaName == 'Region') {
		const regionId = identifier.id;
		const sitesByRegionId = await ds.admin.site.getByRegionIds([regionId]);
		response.sites = sitesByRegionId[regionId];
	}
		
	else {
		response.sites = await ds.admin.site.getAllPackagesAsArray();
	}

	response.siteIds = response.sites.map(site => site.siteId);

	return response;
}