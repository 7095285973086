
import { DdProgramRow } from './dd-program-row';
import { DdProgram } from './dd-program';
import { DdObjects } from '../dd-objects';
import { OldDomainDataService } from '..';

/**
 * @deprecated Use the DataService instead
 */
export class DdPrograms extends DdObjects<DdProgramRow, DdProgram> {

	constructor(private dd: OldDomainDataService) {
		super('programId');
	}

	async loadData(hash: string, data: DdProgramRow[]) {
		const objects = data.map(d => new DdProgram(this.dd, d));
		this.loadObjects(hash, objects);
	}
}