<div class="action-menu">
	<button class='btn' [matMenuTriggerFor]="menu" [class.highlighted]='highlighted'
		[class.toolbar-btn]='location === "toolbar"' [class.grid-btn]='location === "grid"'
		[class.kendo-grid-menu-button]='location === "kendo-grid"'>
		<i [class]='"far fa-fw " + icon' [class.red-flag]='icon==="fas fa-flag"'></i>
	</button>
</div>

<mat-menu #menu="matMenu" overlapTrigger='true'>
	<div mat-menu-header>{{header}}</div>
	<ng-container *ngFor='let action of actions'>
		<ng-container *ngIf='action.linkType==="href"'>
			<a mat-menu-item [href]='sanitize(action.url)' target='_blank' [class.separator]='action.separated'>
				<i *ngIf='hasIcons' [class]='"far fa-fw " + action.icon'></i>
				<span>{{action.label}}</span>
			</a>
		</ng-container>
		<ng-container *ngIf='action.linkType==="routerLink"'>
			<a mat-menu-item [routerLink]='action.url' target='_blank' [class.separator]='action.separated'>
				<i *ngIf='hasIcons' [class]='"far fa-fw " + action.icon'></i>
				<span>{{action.label}}</span>
			</a>
		</ng-container>
		<ng-container *ngIf='action.linkType!=="href" && action.linkType!=="routerLink"'>
			<button mat-menu-item (click)='click(action)' [class.separator]='action.separated'>
				<i *ngIf='hasIcons' [class]='"far fa-fw " + action.icon'></i>
				<span>{{action.label}}</span>
			</button>
		</ng-container>
	</ng-container>
</mat-menu>