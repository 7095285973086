import { AppAreaAccess, AppAreaAccessValue, AppSubAreaAccessValue } from '@me-interfaces';

export type PicSubArea =
	'awards';

export interface PicAreaAccess extends AppAreaAccess {
	root: AppAreaAccessValue,
	sub: {
		awards: AppSubAreaAccessValue,
	},
}