import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { MenuAction } from './menu-action';

export type LanguageSelection = 'any' | 'EN' | 'ES' | 'EN,ES' | 'none';
const LANG_KEY = 'me::langFilterSelection';
@Component({
	selector: 'language-selector-menu',
	template: `<actions-menu [actions]='actions$ | async' [icon]='icon$ | async' tooltip='Languages' header='Languages' [location]='location'></actions-menu>`,
})
export class LanguageSelectorMenuPart implements OnInit {

	@Output() selection = new EventEmitter<LanguageSelection>();
	@Input() location: '' | 'toolbar' | 'grid' | 'kendo-grid' = '';
	@Input() parentClass?: string;

	public icon$ = new BehaviorSubject<string>('fa-comment-alt-lines');
	public actions$ = new BehaviorSubject<MenuAction[]>([
		{
			label: 'Any',
			linkType: 'callback',
			callback: async () => this.select('any'),
			icon: 'fa-check',
		}
	]);

	constructor() {
		this.select('any');
	}

	ngOnInit() {
		let langFilter: LanguageSelection = 'any';

		if (this.parentClass) {
			const storedState = sessionStorage.getItem(LANG_KEY + this.parentClass)
			if (storedState) langFilter = <LanguageSelection>storedState;
		}
		this.select(langFilter);

	}

	select(selection: LanguageSelection) {

		this.actions$.next([
			{
				label: 'Any',
				linkType: 'callback',
				callback: async () => this.select('any'),
				icon: selection == 'any' ? 'fa-check' : undefined,
			},
			{
				label: 'English',
				linkType: 'callback',
				callback: async () => this.select('EN'),
				icon: selection == 'EN' ? 'fa-check' : undefined,
				separated: true,
			},
			{
				label: 'Spanish',
				linkType: 'callback',
				callback: async () => this.select('ES'),
				icon: selection == 'ES' ? 'fa-check' : undefined,
			},
			{
				label: 'Both',
				linkType: 'callback',
				callback: async () => this.select('EN,ES'),
				icon: selection == 'EN,ES' ? 'fa-check' : undefined,
			},
			{
				label: 'Neither',
				linkType: 'callback',
				callback: async () => this.select('none'),
				icon: selection == 'none' ? 'fa-check' : undefined,
			},
		]);

		this.icon$.next('fa-comment-alt-lines' + (selection == 'any' ? '' : ' highlighted'));
		if (sessionStorage && this.parentClass) sessionStorage.setItem(LANG_KEY + this.parentClass, selection);
		this.selection.emit(selection);
	}
}
