import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DbsEvent, EventTypeId, MutableDbsEvent, SimplePerson } from '@me-interfaces';
import { StaffMemberService } from '@me-services/old-services-and-wrappers/staff-member/staff-member.service';
import { OldUserService } from '@me-services/old-services-and-wrappers/user';
import { DataService } from '@me-services/core/data';
import { DdLanguageRow, DdVenue, OldDomainDataService } from '@me-services/core/old-dd';
import { UtilityService } from '@me-services/core/utility';
import { convertEventToMutable } from '../../convert-event-to-mutable';
import { DateRange, DatesProcessor, HOUR, INVALID_EVENT_DURATION } from '../dates-processor';

const DATE_FORMAT = 'g';
const NOTES_PLACEHOLDER = `Notes shown to the public user.\nDon't include any sensitive information.`;


@Component({
	selector: 'event-dialog-edit',
	templateUrl: './SHR-ED_edit-event.part.html',
	styleUrls: ['./SHR-ED_edit-event.part.scss']
})
export class EventDialogEditPart implements OnChanges {

	INVALID_EVENT_DURATION = INVALID_EVENT_DURATION;

	@Input() eventContextRows: { label: string, value: string, site?: string }[] = [];
	@Input() event: DbsEvent;
	@Input() toolIconMessage = '';

	@Output() pendingChanges = new EventEmitter<DbsEvent>();

	orgEvent: MutableDbsEvent;
	newEvent: MutableDbsEvent;

	dates: DatesProcessor;

	sessionEventTypeId = EventTypeId.AccSession;


	dateFormat = DATE_FORMAT;
	notesPlaceholder = NOTES_PLACEHOLDER;

	editing = false;

	toolDatesOptions: { text: string, value: 'automatic' | 'custom' }[] = [
		{ text: "24 hours before event to 24 hours after", value: "automatic" },
		{ text: "Custom", value: "custom" },
	];

	get toolDatesOption() {
		//
		// If the tool dates are not one day before the event to one day after then preselect 'custom'
		//
		const toolDatesOptionIndex = this.dates.useAutomaticToolDates ? 0 : 1;
		return this.toolDatesOptions[toolDatesOptionIndex];

	}


	meetingTypeOptions: { text: string, value: 'online' | 'in-person' }[] = [
		{ text: "Online", value: "online" },
		{ text: "In Person", value: "in-person" },
	];
	meetingTypeOption = this.meetingTypeOptions[0];

	hosts: SimplePerson[] = [];
	languages: DdLanguageRow[] = this.dd.languages.data;
	defaultHost: { _name: '', personId: 0 };
	host: SimplePerson;
	venue: DdVenue;

	//
	// Strings are databound indirectly (not on the newEvent object) so trimming doesn't screw up editing
	//
	link = '';
	publicNotes = '';


	//
	// Flags that indicate if there are values not given or
	// incorrect and therefore the dialog cannot be saved.
	//
	missing = {
		eventDates: false,
		toolDates: false,
		venue: false,
		link: false,
		languageId: false,
	};


	constructor(
		private util: UtilityService,
		private userService: OldUserService,
		private dd: OldDomainDataService,
		private ds: DataService,
		private staffMemberService: StaffMemberService) {

		this.initializeHosts();
	}


	async ngOnChanges() {
		await this.initializeEvent(this.event);
	}


	async initializeEvent(event: DbsEvent) {

		if (!event) return;


		//
		// Create copies of the original event.
		//
		this.orgEvent = { ...convertEventToMutable(event) };
		this.newEvent = { ...this.orgEvent };
		this.dates = new DatesProcessor(this.newEvent, this.util);

		if (this.newEvent.hostPersonId) this.host = await this.ds.admin.person.getOne(this.newEvent.hostPersonId);

		if (this.newEvent.venueId) {
			this.meetingTypeOption = this.meetingTypeOptions[1];
			this.venue = this.dd.venues.getById(this.newEvent.venueId);
		}

		this.link = this.newEvent.link || '';
		this.publicNotes = this.newEvent.publicNotes || '';

	}


	async initializeHosts() {
		const members = await this.staffMemberService.getStaffMembers();
		this.hosts = [...members];

		const user = await this.userService.getUser();
		if (!this.hosts.find(u => u.personId == user.personId)) this.hosts.push(user);

		this.hosts.sort((u1, u2) => u1._name > u2._name ? 1 : -1);
	}



	/**
	 * Event raised when the value of one of the four kendo datetime inputs changes.
	 */
	dateChange(dateRange: DateRange, startOrEnd: 'start' | 'end', date: Date | undefined) {
		dateRange[startOrEnd] = date;

		if (date && dateRange.kind == 'event') {
			if (startOrEnd == 'start') {
				let duration = (dateRange.end?.getTime() ?? 0) - date.getTime();
				if (duration <= 0) duration = 2 * HOUR;
				dateRange.end = new Date(date.getTime() + duration);
			}
		}

		this.dates.processDates(dateRange);
		this.determineCanSave();
	}



	/**
	 * Power the dropdownlist where the user selects for the default tool dates or custom
	 */
	toolDatesOptionChange(o: { text: string, value: 'automatic' | 'custom' }) {
		this.dates.useAutomaticToolDates = o.value == 'automatic';
		this.determineCanSave();
	}


	/**
	 * Event raised when the value of the link field changes.
	 */
	linkChange(text: string) {
		this.link = text;
		this.newEvent.link = text.trim();
		this.determineCanSave();
	}


	/**
	 * Event raised when the link field loses focus.
	 */
	linkBlur() {
		this.link = this.newEvent.link;
	}


	/**
	 * Event raised when the value of the public notes field changes.
	 */
	notesChange(text: string) {
		this.publicNotes = text;
		this.newEvent.publicNotes = text.trim();
		this.determineCanSave();
	}


	/**
	 * Event raised when the public notes field loses focus.
	 */
	notesBlur() {
		this.publicNotes = this.newEvent.publicNotes;
	}



	/**
	 * Power the dropdownlist where the user selects online or in-person
	 */
	meetingTypeOptionChange(meetingTypeOption: { text: string, value: 'online' | 'in-person' }) {
		this.meetingTypeOption = meetingTypeOption;
		if (meetingTypeOption.value == 'online') this.newEvent.venueId = undefined;
		this.determineCanSave();
	}

	/**
	 * Event raised when a venue is selected from the venue dialog.
	 */
	onVenueSelection(venue: DdVenue) {
		this.newEvent.venueId = venue?.venueId;
		this.determineCanSave();
	}


	hostChange(host: { _name: string, personId: number }) {

		this.newEvent.hostPersonId = host?.personId;
		this.determineCanSave();
	}

	languageChange(languageId: number) {
		this.newEvent.languageId = languageId;
		this.determineCanSave();
	}



	/**
	 * Whenever something changes, check if the save button should be enabled.
	 */
	determineCanSave() {

		this.missing.eventDates = !this.dates.eventDates.start || !this.dates.eventDates.end || this.dates.eventDates.duration == INVALID_EVENT_DURATION;
		this.missing.toolDates = this.toolDatesOption.value == 'custom' && (!this.dates.toolDates.start || !this.dates.toolDates.end && this.dates.toolDates.duration == INVALID_EVENT_DURATION);
		this.missing.venue = this.meetingTypeOption.value == 'in-person' && !this.newEvent.venueId;
		this.missing.link = false;
		this.missing.languageId = !this.newEvent.languageId;

		if (this.newEvent.link) this.missing.link = !this.util.text.isValidUrl(this.newEvent.link);

		const changedSomething = !this.util.values.areSame(this.orgEvent, this.newEvent);
		const missingSomething = this.missing.eventDates || this.missing.toolDates || this.missing.venue || this.missing.link || this.missing.languageId;

		if (changedSomething && !missingSomething) {
			this.pendingChanges.emit(this.newEvent);
		}
		else {
			this.pendingChanges.emit(undefined);
		}
	}





	toolIconClick() {
		window.alert(this.toolIconMessage);
	}

}