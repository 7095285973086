import { IconBarIcon } from "./icon-bar-icon";
import { IconBarSelections } from "./SHR-CMN_icon-bar.part";

export function getIconBarSelections(icons: IconBarIcon[]): IconBarSelections {

	const selections = icons
		.reduce((keys, icon) => {

			const selected = icon.selected && !icon.disabled;

			keys.map[icon.key] = selected;
			if (selected) keys.array.push(icon.key);

			return keys;
		}, { array: [], map: {} });


	return selections;
}