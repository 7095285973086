import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { GridColumn, GridColumnType, GridDownloadColumn } from '@me-grid';
import { UtilityService } from '@me-services/core/utility';
import { DialogContext } from '@me-services/ui/dialog';

@Component({
	selector: 'download-dialog',
	templateUrl: './download.dialog.html',
	styleUrls: ['./download.dialog.scss']
})
export class GridDownloadDialog extends DestroyablePart implements OnInit {


	name: string;
	fileName: string;

	rows: string[];
	selectedRow: string;
	downloadRows: any[] = [];

	columns: { header: string, field: string, type: GridColumnType, hidden: boolean, }[];
	selectedCols: { header: string, field: string, type: GridColumnType, hidden: boolean, }[] = [];
	downloadColumns: GridDownloadColumn[] = [];

	filteredRows: any[];
	allRows: any[];

	gridColumns: GridColumn<any>[];

	msg1 = `For internal use only! 
	Exported data may NOT be shared externally or imported into a third-party application without prior permission from senior management.`;
	msg2 = `Red Flags added to the app after download will not be reflected in downloaded spreadsheet.
	ALWAYS download and use a NEW contact spreadsheet just before sending out a bulk email.`;

	constructor(
		@Inject(MAT_DIALOG_DATA) dialog: DialogContext<{
			name: string, columns: { header: string, field: string, type: GridColumnType, hidden: boolean, }[], allRows: any[], filteredRows: any[],
		}>,
		public dialogRef: MatDialogRef<GridDownloadDialog>,
		private util: UtilityService,
	) {
		super();

		const payload = dialog.data;
		this.name = payload.name;
		this.allRows = payload.allRows || [];
		this.filteredRows = payload.filteredRows || [];
		this.columns = payload.columns;
		this.rows = [`Filtered Rows (${this.filteredRows.length})`, `All Rows (${this.allRows.length})`];
		this.selectedRow = `Filtered Rows (${this.filteredRows.length})`;
	}


	ngOnInit() {
		super.initDestroyable();

		this.selectedCols = this.columns.filter(col => !col.hidden);
		this.downloadRows = this.filteredRows;
		this.fileName = this.name.split(' ').join('_');

		this.setColumnsForDownload();
	}

	columnSelectionChange(e: { header: string, field: string, type: GridColumnType, hidden: boolean, }[]) {
		this.selectedCols = e;
	}


	rowSelectionChange(e: string) {
		this.selectedRow = e;
		this.downloadRows = this.selectedRow.startsWith('Filtered Rows') ? this.filteredRows : this.allRows;
	}

	nameChange(e) {
		this.name = e;
	}

	onBlur() {
		const text = (this.name ?? '').trim();
		this.name = this.util.text.titleCase(text);
		this.fileName = this.name.split(' ').join('_');
	}

	setColumnsForDownload() {

		this.downloadColumns = this.selectedCols.map(col => {

			let type: 'text' | 'string' | 'number' | 'date' | 'datetime' | 'time' | 'boolean' | 'icon' | 'iconContext' | 'entityNotes' | 'redFlag' | 'notes' | "number[]";

			if (col.type == 'date' || col.type == 'dateUtc') { type = 'date'; }
			else if (col.type == 'datetime' || col.type == 'datetimeUtc') { type = 'datetime'; }
			else if (col.type == 'time' || col.type == 'timeUtc') { type = 'time'; }
			else if (col.type == 'icon') { type = 'icon'; }
			else if (col.type == 'iconContext') { type = 'iconContext'; }
			else if (col.type == GridColumnType.entityNotes) type = 'number';
			else if (col.type == GridColumnType.dollars) type = 'number';
			else if (col.type == GridColumnType.percent) type = 'number';
			else if (col.type == GridColumnType.gauge) type = 'number';
			else if (col.type == GridColumnType.progressBar) type = 'number';
			else if (col.type == GridColumnType.phone) type = 'number';
			else if (col.type == GridColumnType.redFlag) type = 'boolean';
			else if (col.type == GridColumnType.notes) type = 'boolean';
			else if (col.type == GridColumnType.rating) type = 'number';
			else if (col.type == GridColumnType.ratings) type = 'string';
			else if (col.type == GridColumnType.year) type = 'number';
			else if (col.type == GridColumnType.season) type = 'number';
			else if (col.type == GridColumnType.zipCode) type = 'number';
			else type = col.type;

			return {
				header: col.header,
				property: col.field,
				type,
			}
		});

	}
}