export interface UserFeedback {
	programInstanceId: number,
	programInstanceName: string,
	programInstanceUTC: number,
	programType: 'Pitch' | 'Accelerator',
	siteCode: string,
	siteName: string,
	feedback: {
		reviewerLabel: 'Application Reader' | 'Interviewer' | 'Judge',
		polishedFeedback: string,
	}[],
}