import { Answer } from '@me-interfaces';
import { calculateRating4AndRatings5 } from './calculate-ratings-4-and-ratings-5';
import { calculateRatingsScore } from './calculate-score';
import { formatForGridCell } from './format-for-grid-cell';

export const RatingsUtilities = {

  /**
   * Given an array with rating representing counts of N!, N, Y, Y! amd answers
   * calculate and return a ratings 4 and ratings 5(answers assessment).
   */
  getRating4AndRatings5: (assessments: { rating: number, answers: readonly Answer[] }[]) => calculateRating4AndRatings5(assessments),

  /**
   * Given an array with four numbers representing counts of N!, N, Y, Y! ratings and answer assessments
   * calculate and return a score.
   */
  calculateScore: (ratings5: [number, number, number, number, number], ratings4?: [number, number, number, number]) => calculateRatingsScore(ratings5, ratings4),


  /**
   * Given an array with four numbers representing counts of N!, N, Y, Y! ratings,
   * build and return a score between 0 and 100.
   */
  formatForGridCell: (ratings: number[]) => formatForGridCell(ratings),
};
