
import { OldDomainDataService } from '..';
import { DdObjects } from '../dd-objects';
import { DdWebLinkType } from './dd-web-link-type';
import { DdWebLinkTypeRow } from './dd-web-link-type-row';

/**
 * @deprecated Use the DataService instead
 */
export class DdWebLinkTypes extends DdObjects<DdWebLinkTypeRow, DdWebLinkType> {

	constructor(private dd: OldDomainDataService) {
		super('webLinkTypeId');
	}

	async loadData(hash: string, data: DdWebLinkTypeRow[]) {
		const objects = data.map(d => new DdWebLinkType(this.dd, d));
		this.loadObjects(hash, objects);
	}
}