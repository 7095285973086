export interface UserBasicInfo{
	personId: number,
	prefixId: number,
	firstName: string,
	middleInit: string,
	lastName: string,
	suffixId: number,
	preferredName: string,
	formerName: string,
	phoneTypeId: number,
	phone: string,
}