import { PendingSearchMatch, SearchableData, SearchMatchLevel, SearchRegExps, TextToMatch } from "../interfaces";
import { checkTexts } from "./check-texts";


export async function findVenueMatches(
	data: SearchableData,
	re: SearchRegExps,
): Promise<PendingSearchMatch[]> {


	const matches: PendingSearchMatch[] = [];


	for (const venue of data.venues) {

		const texts: TextToMatch[] = [
			{ text: venue.name, field: 'Display Name' },
			{ text: venue.name, field: 'Name', fullMatchIsExact: true },
		];

		const match = checkTexts(venue, texts, re);

		if (match.level > SearchMatchLevel.None) matches.push(match);
	}

	return matches;
}