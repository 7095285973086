export class QuestionStepper {

	step = -1;
	readonly steps: number;

	constructor(public readonly baseSteps: number, public readonly questionSteps: number, public readonly postSteps = 0) {
		this.steps = baseSteps + questionSteps + postSteps;
	}

	set(step) { this.step = step; }
	prev() { this.step--; }
	next() { this.step++; }
}