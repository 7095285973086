<me-dialog-frame header='Configure Topic' [actions]='actions' [dialogRef]='dialogRef' [icon]='icon' alertType='editor'>

	<div class='acc-single-event-selector-dialog' *ngIf="newTopicConfig">
		<table style='width:100%' class='pro-table pro-table-no-header pro-table-with-labels pro-table-without-border'>
			<tr>
				<td>Topic</td>
				<td>{{ddTopic.shortNameLabel}}</td>
			</tr>
			<tr>
				<td style='width:90px;'>Session</td>
				<td>
					<kendo-dropdownlist style='width:100%' [data]='sessions' textField='weekWithDate'
						valueField='accSessionId' [defaultItem]="defaultSession" [value]='newTopicConfig.accSessionId'
						[valuePrimitive]="true" (valueChange)='onSelectionChange($event)'>
					</kendo-dropdownlist>

				</td>
			</tr>
			<tr *ngIf="ddTopic?.needsSpecialist">
				<td>Specialists</td>
				<td>
					<kendo-multiselect style='width:100%' [data]='groupedData'
						[value]="newTopicConfig.specialistPersonIds" [autoClose]="false"
						(valueChange)='specialistsChange($event)' [textField]="'name'" [valueField]="'personId'"
						[valuePrimitive]="true" [checkboxes]="true">
					</kendo-multiselect>
				</td>
			</tr>
		</table>
	</div>
</me-dialog-frame>