<field-chrome [basePart]='basePart' (fieldClick)='selectField.focus()'>

	<div class='input-wrapper'>

		<kendo-dropdownlist #selectField [data]="options" [textField]="'text'" [valueField]="'value'"
			(focus)='setFocused(true)' (blur)='setFocused(false)' [disabled]='status === "saving"' [value]='selectedOption'
			(valueChange)="onValueChange($event)" [class.has-error]='!!errorText' [style.max-width]="maxWidth + 'em'">
		</kendo-dropdownlist>

		<div *ngIf='(selectedOption?.showOtherValueField) && !field.otherValueKey'
			style='font-size: 1.3em; color: darkred'>Cannot show input because
			field.otherValueContext is missing!</div>

		<div [class.display-none]='!(selectedOption?.showOtherValueField) || !field.otherValueKey'>
			<input #otherField class='other-field' kendoTextBox [value]='currentOtherValue' (focus)='onOtherFocus()'
				(blur)='onOtherBlur()' (click)='$event.stopPropagation()' [disabled]='status === "saving"' />
		</div>

	</div>

</field-chrome>