import { DbaAccSession } from "@me-interfaces";
import { AccAreaData } from "../../../../area/interfaces";
import { DataService } from "@me-services/core/data";



export async function getSessionsWithSurveys(
	accAreaData: AccAreaData,
	ds: DataService,
): Promise<DbaAccSession[]> {

	const sessions = accAreaData.curriculum.sessions;
	const eventIds = sessions.map(session => session.eventId);
	const surveysByEventId = await ds.admin.accSessionSurveyResponse.getByEventIds(eventIds);
	return sessions.filter(s => surveysByEventId[s.eventId].length > 0);
}
