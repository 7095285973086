<field-chrome [basePart]='basePart' (fieldClick)='numericField.focus()'>

	<div class='input-wrapper'>

		<kendo-numerictextbox #numericField [value]='currentValue' (focus)='setFocused(true)' (blur)='setFocused(false)'
			[disabled]='status === "saving"' [class.has-error]='!!errorText'
			[format]='getFormat(field.numericFormat, field.decimalLength)' [style.max-width]="maxWidth + 'em'"
			[autoCorrect]='true' [min]='field.minValue' [max]='field.maxValue' (valueChange)="onValueChange($event)">
		</kendo-numerictextbox>

	</div>

</field-chrome>