import { Old_MinimalContact } from '@me-interfaces';

export interface LanguageTagFlags {
	english: boolean,
	spanish: boolean,
}

export interface PersonWithSiteTags extends Old_MinimalContact {
	tags: { [index: string]: boolean },
	tagsList: string,
	langs: LanguageTagFlags,
	langsList: string,
}