import { environment } from "./environments/environment";


export function setupGoogleTagManager() {

	const containerId = environment.google_tag_manager_container_id.eforAll;

	addHeadTag(containerId);
	addBodyTag(containerId);
}


function addHeadTag(containerId: string) {


	const head = document.getElementsByTagName('head')[0];

	const tagScript = document.createElement('script');
	tagScript.innerHTML = `
		dataLayer = [];

		(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
		new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
		j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
		'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
		})(window,document,'script','dataLayer','${containerId}');`;

	head.insertBefore(tagScript, head.firstChild);
}


function addBodyTag(containerId: string) {

	const body = document.getElementsByTagName('body')[0];

	const tagNoscript = document.createElement('noscript');
	tagNoscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${containerId}"
height="0" width="0" style="display:none;visibility:hidden"></iframe>`;

	body.insertBefore(tagNoscript, body.firstChild);
}