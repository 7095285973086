import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@me-environment';
import { LanguageId } from '@me-interfaces';
import { LabelsService } from '@me-services/ui/labels';
import { UrlService } from '@me-services/ui/url';


@Component({
	selector: 'apply-lang-jump',
	templateUrl: './SHR-CMN_apply-lang-jump.part.html',
	styleUrls: ['SHR-CMN_apply-lang-jump.part.scss'],
})
export class ApplyLangJumpPart {

	@Input() languageId: LanguageId;

	env: string;

	constructor(private router: Router, public labels: LabelsService, private urlService: UrlService) {
		this.env = environment.name;
	}


	getBaseUrl() {
		return this.urlService.getBaseUrl(this.languageId == LanguageId.Spanish ? LanguageId.English : LanguageId.Spanish);
	}


	getJumpToLink() {
		const baseURL = this.getBaseUrl();
		return `${baseURL}${this.router.url}`;
	}


	getJumpToText() {
		if (this.languageId == LanguageId.Spanish) {
			return 'Apply in English';
		}
		if (this.languageId == LanguageId.English) {
			return 'Apply in Spanish';
		}
	}


	getTarget() {
		return this.getBaseUrl();
	}
}
