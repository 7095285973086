import { AccTeam, DbConceptName, DbsPerson, PhoneTypeId } from "@me-interfaces";
import { DataService } from "../../../../data.service";
import { ExplorerBox, ExplorerBoxClass } from "../../../explorer-box/explorer-box";


export async function getBoxesForAccelerator(ds: DataService, accTeam: AccTeam): Promise<ExplorerBox[]> {

	const boxes: ExplorerBox[] = [];

	boxes.push({
		explorerBoxClass: ExplorerBoxClass.Accelerator,
		header: `Accelerator`,
		buttons: [
			{ explorer: { explorableId: { conceptName: DbConceptName.Site, id: accTeam.accelerator.siteProgram.siteId }, navTooltip: `Site: ${accTeam.accelerator.siteProgram.site.name}` } },
			{ explorer: { explorableId: { conceptName: DbConceptName.Accelerator, id: accTeam.accId }, navTooltip: `Accelerator: ${accTeam.accelerator.name}` } },
		],
		notes: `Stage: ${accTeam.accelerator.stage.name}`,
	});

	const people = await ds.admin.person.getManyAsMap([accTeam.accelerator.directorId, accTeam.accelerator.managerId]);

	const edBox: ExplorerBox = getPersonBox(people[accTeam.accelerator.directorId], 'Executive Director');
	const pmBox: ExplorerBox = getPersonBox(people[accTeam.accelerator.managerId], 'Program Manager');

	boxes.push(edBox, pmBox);

	return boxes;
}

function getPersonBox(person: DbsPerson, header: string) {
	const personBox: ExplorerBox = {
		explorerBoxClass: ExplorerBoxClass.Person,
		header,
		buttons: [
			{ explorer: { explorableId: { conceptName: DbConceptName.Person, id: person.personId }, navTooltip: `${header}: ${person._name}` } },
			{ link: { linkType: 'email', email: person._email } },
		],
		notes: '',
	};

	if (person.phone) {
		personBox.buttons.push({ link: { linkType: 'call', phone: person.phone } });

		if (person.phoneTypeId == PhoneTypeId.Mobile) {
			personBox.buttons.push({ link: { linkType: 'sms', phone: person.phone } });
		}
	}
	return personBox;
}
