import { EntityNote } from "@me-interfaces";
import { FuncService } from "@me-services/core/func";

/** @deprecated DO NOT USE THIS!*/
export class EntityNotesWrapper {

	public currentNotes: EntityNote[]

	constructor(private entityId: number, private func: FuncService, public _notes: EntityNote[]) {
		this.currentNotes = _notes;
	}

	public async getCurrentNotes(): Promise<void> {
		this._notes = await this.func.admin.contactsDb.entity.getNotes({ entityId: this.entityId });

		this.currentNotes = [...this._notes];
	}

	public async addNote(noteCategoryId: number, subject: string, note?: string, siteId?: number): Promise<number> {
		const noteId = await this.func.admin.contactsDb.entity.addNote({
			entityId: this.entityId,
			noteCategoryId: noteCategoryId,
			subject: subject,
			note: note || null,
			siteId: siteId || null
		});
		await this.getCurrentNotes();
		return noteId;
	}

	public async updateNote(noteId: number, noteCategoryId: number, subject: string, note?: string, siteId?: number): Promise<void> {
		await this.func.admin.contactsDb.entity.updateNote({
			entityNoteId: noteId,
			noteCategoryId: noteCategoryId,
			subject: subject,
			note: note || null,
			siteId: siteId || null
		});
		await this.getCurrentNotes();
	}

	public async removeNote(noteId: number): Promise<void> {
		await this.func.admin.contactsDb.entity.removeNote({ entityNoteId: noteId });
		await this.getCurrentNotes();
	}
}