import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { DbsPerson } from '@me-interfaces';
import { OldAccService } from '@me-services/old-services-and-wrappers/accelerator';
import { OldPicService } from '@me-services/old-services-and-wrappers/pitch';
import { OldSiteService } from '@me-services/old-services-and-wrappers/site';
import { OldUserService, UserWrapper } from '@me-services/old-services-and-wrappers/user';
import { DataService } from '@me-services/core/data';
import { EntryService } from '@me-services/core/entry';
import { UtilityService } from '@me-services/core/utility';
import { LabelsService } from '@me-services/ui/labels';
import { LayoutService } from '@me-services/ui/layout';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { DestroyablePart } from '../ACS_destroyable.part';
import { Breadcrumb, BreadcrumbMenuItem } from './ACS_breadcrumb';

const MAX_HISTORY = 7;

@Component({
	selector: 'me-breadcrumb',
	styleUrls: ['./ACS_breadcrumb.part.scss'],
	templateUrl: './ACS_breadcrumb.part.html'
})
export class BreadcrumbPart extends DestroyablePart implements OnInit, OnDestroy {

	public userIsSiteAdmin = false;
	public breadcrumbs: Breadcrumb[] = [];
	public tabs: BreadcrumbMenuItem[] = [];
	public nextCrumbIndex: number;
	public prevCrumbIndex: number;

	private person: DbsPerson = undefined;

	private userSubscription: Subscription;
	private routerSubscription: Subscription;

	inlineLabels = this.labels.trackInlineLabels(this, ['Go Up One Level']);

	constructor(private router: Router,
		private title: Title,
		private util: UtilityService,
		private entry: EntryService,
		private labels: LabelsService,
		private accService: OldAccService,
		private picService: OldPicService,
		private siteService: OldSiteService,
		private userService: OldUserService,
		private ds: DataService,
		public layoutService: LayoutService,
	) {
		super();
	}


	ngOnInit() {
		super.initDestroyable();

		this.reset();

		this.userSubscription = this.userService.user$.subscribe(user => {
			this.buildCrumbs(this.userService.wrapUser(user));
			this.buildTitle();
		});

		this.routerSubscription = this.router.events.subscribe(async event => {
			if (event instanceof NavigationEnd) {
				const user = await this.userService.getUserAsWrappedUser();
				this.buildCrumbs(user);
				this.buildTitle();
			}
		});
	}


	override ngOnDestroy() {
		if (this.userSubscription) this.userSubscription.unsubscribe();
		if (this.routerSubscription) this.routerSubscription.unsubscribe();
		this.reset();
	}


	reset() {
		this.breadcrumbs = [];
		this.tabs = [];
		this.title.setTitle('EforAll');
	}


	buildCrumbs(user: UserWrapper) {

		if (!user) {
			this.reset();
			return;
		}

		this.breadcrumbs = [];
		this.tabs = [];

		//
		// Recursively build the breadcrumbs
		//
		this.buildCrumb(user, this.router.routerState.snapshot.root, []);


		//
		// Add a header menu item to each breadcrumb menu
		//
		if (this.breadcrumbs.length) this.breadcrumbs[0].menu.unshift({
			label: 'Dashboard',
			isLabelKey: true,
			path: '/access/my/dashboard',
			includeInTabs: false,
		});

		for (let b = 1; b < this.breadcrumbs.length; b++) {
			const prior = this.breadcrumbs[b - 1];
			this.breadcrumbs[b].menu.unshift({
				label: prior.label,
				isLabelKey: prior.isLabelKey,
				path: prior.path,
				includeInTabs: false,
			});
		}


		//
		// Tabs are always the last breadcrumb menu
		//
		if (this.breadcrumbs.length) {
			this.tabs = this.breadcrumbs[this.breadcrumbs.length - 1]
				.menu
				.filter(i => i.includeInTabs && (!i.siteAdminOnly || this.userIsSiteAdmin))
				.map(m => {
					const tab = Object.assign({}, m);
					tab.label = tab.tabLabel || tab.label;	// The tab might have a different label than the menu
					return tab;
				});

			this.layoutService.showNavTabsAuto({ hasMultipleTabs: this.tabs.length > 1 });

		}
	}

	private buildCrumb(user: UserWrapper, node: ActivatedRouteSnapshot, menu: BreadcrumbMenuItem[], url = '') {

		const urlPaths = this.router.url.split('/');

		//
		// Get the text of the breadcrumb if there is one
		//
		let menuLabel: string = (node.data && node.data.breadcrumb);
		let tabLabel: string = undefined;

		//
		// If this route has any url segments, join them into one segment
		//
		let urlSegment = node.url.map<string>(urlSegment => { return urlSegment.path }).join('/');
		if (urlSegment == '') urlSegment = undefined;

		if (urlSegment) {

			const parentUrl = url;
			url = urlSegment = url + '/' + urlSegment;

			if (menuLabel) {

				//
				// Change ids to the actual names
				//
				if (menuLabel == ':personId') {
					this.person = node.data.personProfile;
					menuLabel = this.person ? `${this.person._name}` : node.params.personId;
					menu = this.getCachedMenu(parentUrl, menuLabel, urlSegment);
				}
				else if (menuLabel == ':companyId') {
					const company = node.data.company;
					menuLabel = company ? (company.shortName || company.longName) : node.params.companyId;
					menu = this.getCachedMenu(parentUrl, menuLabel, urlSegment);
				}
				else if (menuLabel == ':sitecode') {
					const site = this.siteService.getDdSiteFromRoute(node);
					menuLabel = site.code;
					this.userIsSiteAdmin = !!user.sites.getAccessFlags(site.code).isSiteAdmin;

					menu = this.getCachedMenu(parentUrl, `${site.name} (${site.code})`, urlSegment);
				}
				else if (menuLabel == ':siteId') {
					const site = this.siteService.getDdSiteFromSiteId(node.params.siteId);
					menuLabel = site.name;
					this.userIsSiteAdmin = !!user.sites.getAccessFlags(site.code).isSiteAdmin;

					menu = this.getCachedMenu(parentUrl, `${site.name} (${site.code})`, urlSegment);
				}
				else if (menuLabel == ':accId' || menuLabel == ':accId-admin') {
					const acc = node.data.accelerator;
					menuLabel = acc.name;
					const siteCode = this.siteService.getDdSiteFromRoute(node).code;
					menu = this.getCachedMenu(parentUrl, menuLabel, urlSegment);
				}
				else if (menuLabel == ':accId-sites') {
					menuLabel = node.data.menuLabel || node.params.accId;
					menu = this.getCachedMenu(parentUrl, menuLabel, urlSegment);
				}
				else if (menuLabel == ':applicationId') {
					menuLabel = node.data.menuLabel || node.params.applicationId;
					menu = this.getCachedMenu(parentUrl, menuLabel, urlSegment);
				}
				else if (menuLabel == ':regionId') {
					menuLabel = node.data.menuLabel || node.params.regionId;
					menu = this.getCachedMenu(parentUrl, menuLabel, urlSegment);
				}
				else if (menuLabel == ':accApplId') {
					menuLabel = node.data.menuLabel;
					menu = this.getCachedMenu(parentUrl, menuLabel, urlSegment);
				}
				else if (menuLabel == ':accApplForReadingId') {
					const applicant = node.data.applicantForReading.applicant;
					menuLabel = applicant.company._name;
					menu = this.getCachedMenu(parentUrl, menuLabel, urlSegment);
				}
				else if (menuLabel == ':accApplForInterviewingId') {
					const applicant = node.data.applicantForInterview.applicant;
					menuLabel = applicant.company._name;
					menu = this.getCachedMenu(parentUrl, menuLabel, urlSegment);
				}
				else if (menuLabel == ':accTeamForJudgingId') {
					const team = node.data.team;
					menuLabel = team.name;
					menu = this.getCachedMenu(parentUrl, menuLabel, urlSegment);
				}
				else if (menuLabel == ':accTeamId') {
					const accTeam = node.data;
					menuLabel = accTeam.menuLabel;
					menu = this.getCachedMenu(parentUrl, menuLabel, urlSegment);
				}
				else if (menuLabel == ':admEventId') {
					const event = node.data;
					menuLabel = event.menuLabel;
					menu = this.getCachedMenu(parentUrl, menuLabel, urlSegment);
				}
				else if (menuLabel == ':picId' || menuLabel == ':picId-admin') {
					const pitch = node.data.pitch;
					menuLabel = pitch.name;
					menu = this.getCachedMenu(parentUrl, menuLabel, urlSegment);
				}
				else if (menuLabel == ':picApplId') {
					const applicant = node.data;
					menuLabel = applicant.menuLabel;
					menu = this.getCachedMenu(parentUrl, menuLabel, urlSegment);
				}
				else if (menuLabel == ':picApplForReadingId') {
					const applicant = node.data.applicantReading.applicant;
					menuLabel = applicant.company._name;
					menu = this.getCachedMenu(parentUrl, menuLabel, urlSegment);
				}
				else if (menuLabel == ':picTeamForJudgingId') {
					const team = node.data.team;
					menuLabel = team.name;
					menu = this.getCachedMenu(parentUrl, menuLabel, urlSegment);
				}
				else if (menuLabel == ':volunteerApplId') {
					const appl = node.data.volunteeringApplication;
					menuLabel = this.labels.getMoment()(appl.createdUTC * 1000).format('MMM Do YYYY');
					menu = this.getCachedMenu(parentUrl, menuLabel, urlSegment);
				}
				else if (menuLabel == ':personPositionId') {
					const positionId = +node.params.id;
					const position = this.person._personExtracts ? this.person._personExtracts.positions.find(pos => pos.position.positionId == positionId) : undefined;
					menuLabel = position && position.company ? position.company._name : 'Unknown';
					tabLabel = `${menuLabel}  +  ${this.person._name}`;
					menu = this.getCachedMenu(parentUrl, menuLabel, urlSegment, tabLabel);
				}


				const crumb = {
					label: menuLabel,
					tabLabel,
					isLabelKey: false,
					path: urlSegment,
					menu,
				};

				if (menuLabel && menuLabel.startsWith('key:')) {
					crumb.label = menuLabel.substring(4);
					crumb.isLabelKey = true;
				}

				this.breadcrumbs.push(crumb);
			}
		}

		//
		// Use the routeConfig children to determine the available child paths
		//
		let nodemenu: BreadcrumbMenuItem[] = undefined;

		//
		// If we are showing an accelerator or pitch contest (non admin) then 
		// child pages should only show the current tab/breadcrumb.
		//   e.g., don't show 'apply', read', 'interview', ...
		//
		const frontEndProgram = node.routeConfig && node.routeConfig.data && (
			node.routeConfig.data.breadcrumb == ':accId' ||
			node.routeConfig.data.breadcrumb == ':picId');

		if (node.routeConfig && node.routeConfig.children) {
			nodemenu = node.routeConfig.children
				.filter(child => child.path && child.data && child.data.breadcrumb)
				.filter(child => child.path.indexOf('/') == -1) /* skip anything with an embedded child path */
				.filter(child => child.path.indexOf(':') == -1) /* skip anything with a variable */
				.filter(child => {
					if (frontEndProgram) return urlPaths.includes(child.path);
					return true;
				})
				.filter(child => this.entry.showTo(user, this.siteService.getDdSiteFromRoute(node), child.data?.showTo))
				.filter(child => {
					if (child.data && child.data.breadcrumb === 'key:Mentoring') {
						return (user?._personExtracts.badges.mentor == 'Good');
					}
					else return true;
				})
				.filter(child => {
					if (child.data && child.data.breadcrumb === 'Admin') {
						return (user?.isTechAdmin);
					}
					else return true;
				})
				.map(child => {
					const crumb = {
						label: child.data.breadcrumb,
						isLabelKey: false,
						path: url + '/' + child.path,
						includeInTabs: !child.data.noTab,
						siteAdminOnly: node.data.breadcrumb == ':sitecode' && child.path == 'admin',
					};

					if (crumb.label.startsWith('key:')) {
						crumb.label = crumb.label.substring(4);
						crumb.isLabelKey = true;
					}
					return crumb;
				});
		}

		if (nodemenu) {
			nodemenu.sort((a, b) => {
				//dynamic fields go to the end of the list
				const dynamicA = a.label.indexOf(':') > -1;
				const dynamicB = b.label.indexOf(':') > -1;
				if (dynamicA && !dynamicB) return 1;
				if (dynamicB && !dynamicA) return -1;
				return 0; // a.name.localeCompare(b.name);
			});
		}

		if (node.firstChild) {
			this.buildCrumb(user, node.firstChild, nodemenu, url);
		}
	}


	/**
	 * Create and add a title (shown in the browser tab)
	 */
	async buildTitle() {
		//
		// Synchronously don't try to map and keys, so we get something fast. This will be English
		//
		let title = this.breadcrumbs.map(breadcrumb => breadcrumb.label).join(' > ');
		this.title.setTitle(title);

		//
		// Asynchronously wait for labels to load and then set translated values
		//
		const getLabel = await this.labels.getLabel$.pipe(take(1)).toPromise();

		title = this.breadcrumbs.map(breadcrumb => {
			if (breadcrumb.isLabelKey) return getLabel({ key: breadcrumb.label });
			return breadcrumb.label;
		}).join(' > ');

		this.title.setTitle(title);
	}


	getCachedMenu(keySuffix: string, name: string, url: string, tabLabel: string = undefined): BreadcrumbMenuItem[] {

		// Change the prefix to forcefully reset everyone's cached links
		const key = 'VGaryTest-' + keySuffix;

		const item: BreadcrumbMenuItem = {
			label: name,
			tabLabel,
			isLabelKey: false,
			path: url,
			includeInTabs: true,
		};

		// Get prior menu
		let menu: BreadcrumbMenuItem[] = JSON.parse(localStorage.getItem(key) || '[]');

		// Remove the current item from the prior menu if it is there
		menu = menu.filter(i => i.path !== item.path);

		// Make room for our new one
		if (menu.length >= MAX_HISTORY) menu.shift();

		// Add our new one
		menu.push(item);

		// Remember for later
		localStorage.setItem(key, JSON.stringify(menu));

		// Only the last one is shown in the nav tabs
		menu.forEach(m => m.includeInTabs = false);
		menu[menu.length - 1].includeInTabs = true;

		// Sort it
		menu.sort((a, b) => {
			return a.label < b.label ? -1 : 1;
		});

		return menu;
	}

	goUp() {
		const url = this.combineUrls(this.router.routerState.snapshot.url, '..');
		this.util.log.techMessage('Navigating to ' + url);
		this.router.navigateByUrl(url);
		// this.router.navigate([url]);
	}

	combineUrls(one: string, two: string): string {
		const a = one.split('/');
		const b = two.split('/');

		if (two == '..') {	// simply go up a level

			a.pop();

			//
			// If the last segment is a number than it is an ID. If we are going up
			// one level, we should skip passed the ID (go up two levels)
			//
			const lastSegment = +a[a.length - 1];
			if (isNaN(lastSegment) == false) a.pop();
		}
		else {
			//
			// This handles a more complicated combination
			//
			for (let segment of b) {
				segment = segment.trim();
				if (segment == '') continue;
				if (segment == '.') continue;
				if (segment == '..') a.pop();
				else a.push(segment);
			}
		}

		return a.join('/');
	}


	getBreadcrumbClasses(i) {
		const c = this.breadcrumbs.length - i;
		if (c >= 6) return 'breadcrumb-item d-none';
		if (c == 5) return 'breadcrumb-item d-none d-xl-block';
		if (c == 4) return 'breadcrumb-item d-none d-lg-block';
		if (c == 3) return 'breadcrumb-item d-none d-sm-block';
		return 'breadcrumb-item';
	}

	isMenuItemSelected(breadcrumb: Breadcrumb, menu: BreadcrumbMenuItem) {
		if (breadcrumb.label == menu.label) return true;
		if (breadcrumb.label.length == 4) { // hackey way to check for a site code
			return menu.label.indexOf(`(${breadcrumb.label})`) >= 0;
		}
		return false;
	}


	async toggleNavTabs() {
		let value = await this.layoutService.showNavTabs$.pipe(take(1)).toPromise();
		value = !value;
		this.layoutService.showNavTabsVisibility$.next(value ? 'Show' : 'Hide');
	}
}