<div class="container-fluid root-app-login-page" style="position:absolute;">
	<!-- [hidden]='(pageSpinner.value$ | async)'> -->
	<div class="row justify-content-center">
		<div style="width: 95%; max-width: 320px">

			<div class="card login-container">

				<access-login-logo></access-login-logo>

				<div [hidden]='auth.hasWebStorage' style='text-align:center;width:100%;color:red;'>
					<me-label key='md:Web Storage Not Found'></me-label>
				</div>

				<div [hidden]='(auth.me.firebaseUser$ | async)'>
					<access-login-buttons></access-login-buttons>
				</div>

				<ng-container *ngIf="!!(auth.me.firebaseUser$ | async)">
					<div *ngIf="!(errorPage.details$|async).error" class='login-button-container'>
						<button class='btn' (click)='auth.me.signout()'>
							<me-label key='Logout'></me-label>
						</button>
						<a href="{{appSession.continuePath$ | async}}" class='btn'>
							<me-label key='Next'></me-label> &gt;
						</a>
					</div>
				</ng-container>

			</div>
		</div>
	</div>
</div>