/**
 * Given an array of numbers (four for assessments and five for question ratings),
 * calculate a score between 0 and 100.  The first element is worth 0 and the last 
 * element is worth 100.  In-between elements are pro-rated.  The value in each
 * element is used to weight the score.
 * @returns The score and the total count of values across the array elements
 */
export function calcScore(values: [number, number, number, number] | [number, number, number, number, number]) {

	//
	// The length will either be 4 or 5 so the div will either be 33.3-> or 25
	//
	const div = 100 / (values.length - 1);

	//
	// Add up the numbers in each element
	//
	const count = values.reduce((p, c) => p + c, 0);
	if (count == 0) return { score: 0, count: 0 };

	//
	// Perform a weighted calculation with the result being 0..100
	//
	return { score: values.reduce((p, c, i) => p + c * i * div, 0) / count, count };
}