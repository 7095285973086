import { Component, Input, OnChanges } from '@angular/core';
import { Company } from '@me-interfaces';
import { DataService } from '@me-services/core/data/data.service';
import { UtilityService } from '@me-services/core/utility';

interface CompanyDetails {
	orgType: string,
	industry: string,
	offerings: string,
	updatedByPersonId: number,
	updatedByPersonName: string,
	updatedUTC: number,
	createdUTC: number,
}

@Component({
	selector: 'company-explorer-details',
	templateUrl: './details.part.html',
	styleUrls: ['./details.part.scss'],
})
export class CompanyExplorerDetailsPart implements OnChanges {
	@Input() company: Company;

	companyDetails: CompanyDetails;


	constructor(public util: UtilityService, public ds: DataService) { }



	async ngOnChanges() {
		if (!this.company) throw new Error('Missing required attribute: company');
		const updatedPerson = await this.ds.admin.person.getOne(this.company.updatedByPersonId);
		this.companyDetails = {
			orgType: this.company.companyTypeId ? this.ds.domain.companyType.getOne(this.company.companyTypeId).name : '',
			industry: this.company.industryId ? this.ds.domain.industry.getOne(this.company.industryId).name : '',
			offerings: this.company.offerings,
			updatedByPersonId: this.company.updatedByPersonId,
			updatedByPersonName: updatedPerson._name,
			updatedUTC: this.company.updatedUTC,
			createdUTC: this.company.createdUTC,
		};
	}

}