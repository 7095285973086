import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { AccTeamGoalsAndAccess } from '@me-interfaces';
import { DroplistItem, getIconContextForIndustry } from '@me-shared-parts/UI-common';
import { AccGoalAddRow, AccGoalEditRow } from './acc-goals-interfaces';


@Component({
	selector: 'acc-goals-team-selector-part',
	templateUrl: './SHR-PG_acc-goals-team-selector.part.html',
	styleUrls: ['SHR-PG_acc-goals-team-selector.part.scss'],
})
export class AccGoalsTeamSelectorPart implements OnChanges {

	@Input() accTeamGoalsAndAccess: AccTeamGoalsAndAccess[];
	@Input() userAccTeamId: number = null;

	@Output() editGoal = new EventEmitter<AccGoalEditRow>();
	@Output() addGoal = new EventEmitter<AccGoalAddRow>();
	@Output() addComment = new EventEmitter<{ comment: string, accTeamGoalId: number }>();

	teamDroplistItems: DroplistItem<AccTeamGoalsAndAccess>[] = [];
	teamDroplistSelectedItem: DroplistItem<AccTeamGoalsAndAccess>;

	ngOnChanges() {
		if (!this.accTeamGoalsAndAccess) throw new Error('Missing required attribute: accTeamGoalsAndAccess');

		//Build teams droplist
		if (this.accTeamGoalsAndAccess) {
			this.teamDroplistItems = this.accTeamGoalsAndAccess.map(t => {
				return {
					uniqueId: t.team.accTeamId,
					groupName: `${t.acc.siteName} ${t.acc.name}`,
					iconContext: getIconContextForIndustry(t.team.industryId, undefined, undefined, t.goals.length ? `${t.team.name} (${t.goals.length})` : t.team.name),
					data: t
				}
			});
		}

		//Preselect the selectedAccTeam
		if (this.teamDroplistSelectedItem) this.teamDroplistSelectedItem = this.teamDroplistItems.find(d => d.uniqueId == this.teamDroplistSelectedItem.uniqueId);
		else if (this.userAccTeamId) this.teamDroplistSelectedItem = this.teamDroplistItems.find(d => d.uniqueId == this.userAccTeamId);
		else if (!this.teamDroplistSelectedItem) this.teamDroplistSelectedItem = this.teamDroplistItems[0];

	}

	/**
	 * 
	 * On teams droplist change, set selected team.
	 */
	teamsDroplistChange(team: DroplistItem<AccTeamGoalsAndAccess>) {
		this.teamDroplistSelectedItem = team;
	}
}
