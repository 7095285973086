import { DbConceptName } from "@me-interfaces";
import { BehaviorSubject, mergeMap } from "rxjs";
import { DataService } from "../../data.service";
import { ExplorableIdentifier } from "./explorable-identifier";

const EXPLORER_NAV_KEY = 'explorer-nav-key';
const MAX_QUEUE_SIZE = 100;


/**
 * The set of items in the explorer history list and which one is currently selected.
 */
export interface ExplorerQueue {
	list: ExplorableIdentifier[],
	currentIndex: number,
}


/**
 * Manages the queue of navigations (current and prior) and the currently selected index.
 */
export class ExplorerNavigator {


	/**
	 * The recent list contains all navigations that have been displayed, in the order in which
	 * they were displayed. However, duplicates are filtered out. This is what is shown within
	 * the droplist in the explorer.
	 */
	public readonly recents$ = new BehaviorSubject<ExplorableIdentifier[]>([{ conceptName: DbConceptName.Company, id: 1 }]);

	/**
	 * Same as recents$ but the navigations are mapped to the 
	 */
	public readonly recentPackages$ = this.recents$.pipe(
		mergeMap(async navs => this.ds.admin.getMany(navs))
	);


	/**
	 * History is the list of navigated items that works like the browser history, with back
	 * and forward navigation.  If the user backs up and then navigates elswhere then all of
	 * the history after it is cropped and replaced with the new navigation. It's possible
	 * for a unique navigation to be in the history list multiple times.
	 */
	private _history: ExplorableIdentifier[] = [...this.recents$.value];
	private _historyIndex = 0;


	constructor(private ds: DataService) {

		this.load();

		ds.admin.singletonsAsOfUTC$.subscribe(() => {

			//
			// Trigger recentPackages$ to get latest singletons
			//
			this.recents$.next(this.recents$.value);
		});
	}


	/**
	 * True if it is possible to back up and show the prior viewed item
	 */
	public get canGoBack() {
		return this._historyIndex < this._history.length - 1;
	}


	/**
	 * Backup and show the prior viewed item 
	 */
	public goBack() {
		if (!this.canGoBack) return;
		this._historyIndex++;
		const historyItem = this._history[this._historyIndex];
		this._addRecent(historyItem);
	}


	/**
	 * True if it is possible to return to the last viewed item after a call to goBack()
	 */
	public get canGoForward() {
		return this._historyIndex > 0;
	}


	/**
	 * Return to the last viewed item after a call to goBack()
	 */
	public goForward() {
		if (!this.canGoForward) return;
		this._historyIndex--;
		const historyItem = this._history[this._historyIndex];
		this._addRecent(historyItem);

	}


	private async _addRecent(nav: ExplorableIdentifier) {

		//
		// Remove the new nav from the recents if there
		//
		let recents = this.recents$
			.value
			.filter(n => n.id !== nav.id || n.conceptName !== nav.conceptName);


		//
		// Add the new nav at the beginning and ensure the max length is not exceeded
		//
		recents.unshift({ id: nav.id, conceptName: nav.conceptName });
		if (recents.length > MAX_QUEUE_SIZE) recents = recents.slice(0, MAX_QUEUE_SIZE);


		//
		// Store and communicate
		//
		this.recents$.next(recents);
		this.store(recents);
	}


	/**
	 * Go to a new item. If the item exists somewhere in the history, it will be filtered out first.
	 */
	public go(nav: ExplorableIdentifier) {

		//
		// Remove any history items after the history index.
		//
		if (this._historyIndex > 0) {
			this._history = this._history.slice(this._historyIndex);
			this._historyIndex = 0;
		}

		//
		// Apply it
		//
		if (this._history.length) {

			const last = this._history[0];

			if (last.id !== nav.id || last.conceptName !== nav.conceptName) {

				this._history.unshift({ id: nav.id, conceptName: nav.conceptName });
				this._addRecent(nav);
			}
		}
	}


	/**
	 * Write the queue to local storage so it will be available when the app reloads.
	 */
	private store(list: ExplorableIdentifier[]) {
		//
		// Make a copy. The names don't need to be stored
		// in local storage and will be reapplied when reloaded.
		//
		list = this.recents$.value
			.filter(i => {
				if (i.conceptName) return true;
				else {
					debugger;
					return false;
				}
			})
			.map(n => ({ conceptName: n.conceptName, id: n.id }));

		//
		// Store them
		//
		localStorage.setItem(EXPLORER_NAV_KEY, JSON.stringify(list));
	}


	/**
	 * Read the recents from local storage.
	 */
	private async load() {
		const lists = localStorage.getItem(EXPLORER_NAV_KEY);

		if (lists) {
			const list: ExplorableIdentifier[] = JSON
				.parse(lists)
				.filter(i => !!i.conceptName);


			this._history = list;
			this._historyIndex = 0;

			this.recents$.next([...list]);
		}
	}

}