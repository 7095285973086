
import { Icon } from '@me-shared-parts/UI-common';
import { Accelerator, DbsEvent, PitchContest } from '@me-interfaces';
import { getAccToolEnabledAndLink } from './get-acc-tool-enabled-and-link';
import { getEventStatus, getEventStatusIcon } from './get-event-status';
import { getPicToolEnabledAndLink } from './get-pic-tool-enabled-and-link';

export const EventUtilities = {

  /**
   * Calculate the status of the event based on startUTC, endUTC, toolStartUTC and toolEndUTC if any.
   */
  getStatus: (event: DbsEvent): 'Upcoming' | 'Underway' | 'Complete' => {
    return getEventStatus(event);
  },

  /**
   * Calculate the status of an event based on startUTC, endUTC, toolStartUTC and toolEndUTC if any
   * and return the icon that represents that status.
   */
  getStatusIcon: (event: DbsEvent): Icon => {
    return getEventStatusIcon(event);
  },


  /**
   * Calculate the Accelerator link of the tool and the status(enabled) based on the event type, event status and the accStage.
   * Returns enabled status: boolean and the link for the tool.
   */
  getAccToolStatusAndLink: (event: DbsEvent, accelerator: Accelerator): { enabled: boolean, link: string } => {

    return getAccToolEnabledAndLink(event, accelerator);
  },

  /**
   * Calculate the Pitch link of the tool and the status(enabled) based on the event type, event status and the picStage.
   * Returns enabled status: boolean and the link for the tool.
   */
  getPicToolStatusAndLink: (event: DbsEvent, pitchContest: PitchContest): { enabled: boolean, link: string } => {

    return getPicToolEnabledAndLink(event, pitchContest);
  }
};
