import { Dba, DbConceptName, Immutable } from '@me-interfaces';


export type SegmentType = 'video' | 'quiz';

interface MutableDbaCourseSegment extends Dba {

	readonly _concept: DbConceptName.CourseSegment,

	courseSegmentId: number,
	courseSectionId: number,
	timeInSeconds
	segmentType: SegmentType,
	wistiaId: string
	transcript: string
	order: number,
	title: string,
	createdUTC: number,
	updatedUTC: number,
}


export interface DbaCourseSegment extends Immutable<MutableDbaCourseSegment> { }