import { Component, Input, OnChanges } from '@angular/core';
import { Person } from '@me-interfaces';
import { DataService } from '@me-services/core/data/data.service';
import { FuncService } from '@me-services/core/func';
import { UtilityService } from '@me-services/core/utility';

interface PersonDetails {
	personId: number,
	doNotContactNote: string,
	prefix: string,
	emails: string[],
	suffix: string,
	firstName: string,
	formerName: string,
	preferredName: string,
	lastLoginDate: string,
	updatedByPersonId: number,
	updatedByPersonName: string,
	updatedUTC: number,
}


interface Signed {
	agreementName: string,
	signedDate: string,
	signedUTC: number,
}


@Component({
	selector: 'person-explorer-details',
	templateUrl: './details.part.html',
	styleUrls: ['./details.part.scss'],
})
export class PersonExplorerDetailsPart implements OnChanges {
	@Input() person: Person;

	personDetails: PersonDetails;
	signatures: Signed[] = [];


	constructor(private func: FuncService, private util: UtilityService, private ds: DataService) { }


	async ngOnChanges() {
		if (!this.person) throw new Error('Missing required attribute: person');

		const updatedPerson = await this.ds.admin.person.getOne(this.person.asSingleton.updatedByPersonId);

		this.personDetails = {
			personId: this.person.personId,
			doNotContactNote: this.person.asSingleton.doNotContactNote,
			prefix: this.person.asSingleton.prefixId ? this.ds.domain.prefix.getOne(this.person.asSingleton.prefixId).name : undefined,
			emails: [...this.person.emails].filter(email => !email.isDefault).map(email => email.email).sort((a, b) => a > b ? 1 : -1),
			suffix: this.person.asSingleton.suffixId ? this.ds.domain.suffix.getOne(this.person.asSingleton.suffixId).name : undefined,
			firstName: this.person.asSingleton.firstName,
			formerName: this.person.asSingleton.formerName,
			preferredName: this.person.asSingleton.preferredName,
			lastLoginDate: '',
			updatedByPersonId: this.person.asSingleton.updatedByPersonId,
			updatedByPersonName: updatedPerson._name,
			updatedUTC: this.person.updatedUTC,
		};

		const signaturesByPersonId = await this.ds.admin.agreementSignature.getByPersonIds([this.person.personId]);
		const signatures = signaturesByPersonId[this.person.personId] ?? [];


		this.signatures = signatures
			.map(s => ({
				agreementName: `${s.agreementType.nameEN} v${s.agreementVersion.version}`,
				signedDate: this.util.date.formatUTC(s.updatedUTC, 'MMM D, YYYY', 'No Time', this.ds.languageId),
				signedUTC: s.updatedUTC,
			}))
			.sort((s1, s2) => s2.signedUTC - s1.signedUTC);
	}


	async retrieveLastLogin() {
		const lastLogin = await this.func.person.getLastLogin({ personId: this.person.personId });
		if (!lastLogin) this.personDetails.lastLoginDate = 'Never';
		else this.personDetails.lastLoginDate = this.util.date.formatUTC(lastLogin, 'MMM D, YYYY', 'H:MM AM EST', this.ds.languageId);
	}
}