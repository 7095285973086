import { Component, Input, OnChanges } from '@angular/core';
import { Accelerator } from '@me-interfaces';
import { UtilityService } from '@me-services/core/utility';
import { UrlService } from '@me-services/ui/url';


@Component({
	selector: 'accelerator-explorer-details',
	templateUrl: './details.part.html',
	styleUrls: ['./details.part.scss'],
})
export class AcceleratorExplorerDetailsPart implements OnChanges {
	@Input() accelerator: Accelerator;

	constructor(
		public util: UtilityService,
		public urlService: UrlService,
	) { }


	ngOnChanges() {
		if (!this.accelerator) throw new Error('Missing required attribute: accelerator');
	}

}