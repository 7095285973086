import { DataService } from "@me-services/core/data";
import { PendingSearchMatch, SearchableData, SearchMatchLevel, SearchRegExps, TextToMatch } from "../interfaces";
import { checkTexts } from "./check-texts";


export async function findPersonMatches(
	data: SearchableData,
	re: SearchRegExps,
	term: string,
	termPhoneDigits: string,
	ds: DataService,
): Promise<PendingSearchMatch[]> {


	const matches: PendingSearchMatch[] = [];


	for (const person of data.persons) {

		const dbsPerson = person.asSingleton;
		const zip = data.zipByZipId[dbsPerson.zipId];

		//
		// General Text Properties
		//
		const texts: TextToMatch[] = [
			{ text: person.name, field: 'Name' },
			{ text: `${dbsPerson.firstName} ${dbsPerson.lastName}`, field: 'Full Name', fullMatchIsExact: true },
			{ text: dbsPerson.lastName, field: 'Last Name' },
			{ text: dbsPerson.formerName, field: 'Former Name' },
			{ text: dbsPerson.preferredName, field: 'Preferred Name' },
			{ text: dbsPerson.doNotContactNote, field: 'Do Not Contact Note' },
		];


		//
		// Emails
		//
		for (const email of person.emails) {
			texts.unshift({ text: email.email, field: 'Email', fullMatchIsExact: true });
		}


		//
		// Full name with Middle Initial
		//
		if (dbsPerson.middleInit) {
			texts.unshift({ text: `${dbsPerson.firstName} ${dbsPerson.middleInit} ${dbsPerson.lastName}`, field: 'Full Name', fullMatchIsExact: true });
			texts.unshift({ text: `${dbsPerson.firstName} ${dbsPerson.middleInit}. ${dbsPerson.lastName}`, field: 'Full Name', fullMatchIsExact: true });
		}


		//
		// Address
		//
		if (zip) {
			texts.push({ text: `${dbsPerson.address ?? ''} ${zip.cityAndState}`, field: 'Address' });
		}
		else {
			texts.push({ text: dbsPerson.address ?? '', field: 'Address' });
		}


		//
		// Notes
		//
		for (const note of data.entityNotesByEntityId[dbsPerson.entityId] || []) {
			texts.push({ text: note.note, field: 'Note' },);
		}

		//
		// Position titles
		//
		for (const position of data.positionsByPersonId[person.personId] || []) {
			texts.push({ text: position.title, field: 'Title' },);
		}

		//
		// Weblinks
		//
		for (const webLink of person.webLinks) {
			texts.push({ text: webLink.url, field: 'Web Link' },);
		}


		//
		// Tags
		//
		if (term.length >= 5) {
			for (const tag of person.tags) {
				texts.push({ text: tag.fullName, field: 'Tag' },);
			}
		}


		//
		// Match all the literals
		//
		const match = checkTexts(person, texts, re);


		//
		// Five digit zip code
		//
		if (zip) {
			if (re.full.test(zip.code)) {
				match.fieldMatches.push({ field: 'Zip Code', level: SearchMatchLevel.Low });
				match.level = Math.max(match.level, SearchMatchLevel.Low);
			}
		}


		//
		// Phone
		//
		if (dbsPerson.phone) {
			const digits = ds.util.number.extractDigits(dbsPerson.phone);

			//
			// US numbers are 10 with area code. International might be smaller? 8 seems like a good number?
			//
			if (digits.length > 8) {
				const phoneDigits = digits.length == 11 && digits.startsWith('1') ? digits.substring(1) : digits;


				if (termPhoneDigits == phoneDigits) {
					match.fieldMatches.push({ field: 'Phone', level: SearchMatchLevel.Exact });
					match.level = SearchMatchLevel.Exact;
				}
				else {
					if (termPhoneDigits.length >= 4 && phoneDigits.endsWith(termPhoneDigits)) {
						match.fieldMatches.push({ field: 'Phone', level: SearchMatchLevel.Low });
						match.level = Math.max(match.level, SearchMatchLevel.Low);
					}
				}
			}
		}


		if (match.level > SearchMatchLevel.None) matches.push(match);

	}

	return matches;
}
