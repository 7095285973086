import { AccAreaData, AccAreaService, getAccStageErrors } from '@ACC-area';
import { SiteAreaData, SiteAreaService } from '@SITE-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AccTeam, Accelerator, Application, ApplicationStatusId, Award, Event, StageStatusItem } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { Observable, combineLatest, switchMap } from 'rxjs';


interface ViewData {
	accelerator: Accelerator,
	accStartDate: string;
	accApplicationsStartDate: string;
	accApplicationsDueDate: string;
	counts: {
		numSubmittedApplications: number,
		numApplicationsStillOpen: number,
		numTeams: number,
		numEntrepreneurs: number,
		numMentors: number,
	},
	statuses: StageStatusItem[],
	problems: string[],
}


@Component({
	selector: 'acc-details-view-part',
	templateUrl: './details-view.part.html',
	styleUrls: ['./details-view.part.scss'],
})
export class AccDetailsViewPart extends DestroyablePart implements OnInit {

	accelerator: Accelerator;
	siteCode: string;
	public data$: Observable<ViewData>;


	constructor(
		public util: UtilityService,
		public ds: DataService,
		private siteAreaService: SiteAreaService,
		public accAreaService: AccAreaService
	) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();

		this.data$ = combineLatest([
			this.siteAreaService.data$,
			this.accAreaService.data$,
		]).pipe(switchMap(data => this.buildData(data[0], data[1])));
	}


	async buildData(
		siteArea: SiteAreaData,
		accArea: AccAreaData,
	): Promise<ViewData> {

		if (!siteArea || !accArea) return undefined;

		const site = siteArea.site;
		const acc = accArea.acc;

		//
		// Get related data for this ONE accelerator
		//
		const applications = (await this.ds.admin.application.getByAccIds([acc.accId]))[acc.accId];
		const teams = (await this.ds.admin.accTeam.getByAccIds([acc.accId]))[acc.accId];
		const events = (await this.ds.admin.event.getByAccIds([acc.accId]))[acc.accId];
		const awards = (await this.ds.admin.award.getByAccIds([acc.accId]))[acc.accId];

		const teamMembers = this.util.array.mergeChildArrays(teams, t => t.members);

		this.accelerator = acc;
		this.siteCode = site.code;


		return {
			accelerator: acc,
			counts: {
				numSubmittedApplications: applications.filter(a => a.applicationStatusId >= ApplicationStatusId.AcceptSubmitted).length,
				numApplicationsStillOpen: applications.filter(a => a.isOpen).length,
				numTeams: teams.filter(accTeam => !accTeam.droppedOutWeek).length,
				numEntrepreneurs: teamMembers.filter(m => m.member.role == 'E').length,
				numMentors: teamMembers.filter(m => m.member.role == 'C' || m.member.role == 'M').length,
			},
			accStartDate: this.util.date.formatUTC(acc.startUTC, 'MMM D, YYYY', 'No Time', this.ds.languageId),
			accApplicationsStartDate: this.util.date.formatUTC((acc.applStartUTC), 'MMM D, YYYY', 'No Time', this.ds.languageId),
			accApplicationsDueDate: this.util.date.formatUTC((acc.applStartUTC + (acc.applDuration * 60)), 'MMM D, YYYY', 'No Time', this.ds.languageId),
			statuses: [],
			problems: this.getProblems(this.util, acc, applications, teams, events, awards),
		};
	}


	getProblems(
		util: UtilityService,
		acc: Accelerator,
		applications: readonly Application[],
		teams: readonly AccTeam[],
		events: readonly Event[],
		awards: readonly Award[]
	) {

		const problems: string[] = [];
		const accStageId = acc.accStageId;
		const stageErrors = getAccStageErrors(util, acc, applications, teams, events, awards);
		const stageById = this.ds.domain.accStage.getAllAsMap();

		for (const error of stageErrors) {

			let timing = '';

			if (error.logic == 'Before' && accStageId < error.accStageId) timing = 'before';
			if (error.logic == 'At-Or-Before' && accStageId <= error.accStageId) timing = 'at or before';
			if (error.logic == 'At-Or-Beyond' && accStageId >= error.accStageId) timing = 'at or beyond';
			if (error.logic == 'Beyond' && accStageId > error.accStageId) timing = 'beyond';

			if (timing.length) problems.push(`${error.message}, but the stage is ${timing} ${stageById[error.accStageId].name}`);
		}

		return problems;
	}

}