<div class='acc-goals-part' style='height: calc(100% - 86px)'>

	<div *ngIf="!goalOptions?.length" class='no-goals'>
		<button *ngIf="canAddGoal" class="btn margin-bottom" (click)="openAddDialog()">
			<me-label key='Add Goal'></me-label>
		</button>

		<me-label *ngIf="!canAddGoal" key='There are no goals for the selected team.'></me-label>
	</div>

	<div *ngIf="goalOptions?.length" class="team-goals">
		<div style="margin-right: .5em;">
			<button *ngIf="canAddGoal" class="btn margin-bottom" (click)="openAddDialog()">
				<me-label key='Add Goal'></me-label>
			</button>

			<me-listbox [value]='selectedGoalOption?.value' [options]='goalOptions'
				(valueChange)='onGoalSelected($event)'>
			</me-listbox>
		</div>
		<div *ngIf="selectedGoal">
			<kendo-tabstrip (tabSelect)='onGoalTabSelect'>
				<kendo-tabstrip-tab [title]="inlineLabels['Goal']" [selected]='true'>
					<ng-template kendoTabContent>
						<acc-goals-view-part [selectedGoal]="selectedGoal" [comments]='selectedGoalComments'
							[canAddComment]='teamGoalsAndAccess.access.canAddComment'
							(addComment)='addComment.emit($event)'></acc-goals-view-part>
					</ng-template>
				</kendo-tabstrip-tab>

				<kendo-tabstrip-tab *ngIf="canEditGoal" [title]="inlineLabels['Edit']">
					<ng-template kendoTabContent>
						<acc-goals-edit-part [selectedGoal]="selectedGoal"
							[selectedGoalQuarterlyGoalFlags]="selectedGoalQuarterlyGoalFlags"
							(editGoal)="onEditGoal($event)">
						</acc-goals-edit-part>
					</ng-template>
				</kendo-tabstrip-tab>
			</kendo-tabstrip>
		</div>

		<div *ngIf='!selectedGoal'>
			<kendo-tabstrip (tabSelect)='onGoalTabSelect'>
				<kendo-tabstrip-tab [title]="inlineLabels['Goal']" [selected]='true'>
					<ng-template kendoTabContent>
						<div class='choose-instruction'>
							<me-icon [icon]="{ icon: 'far fa-arrow-left', text: chooseGoalText }"></me-icon>
						</div>
					</ng-template>
				</kendo-tabstrip-tab>
			</kendo-tabstrip>
		</div>
	</div>



</div>