import { DdVenue } from '@me-services/core/old-dd';
import { DialogAction } from '@me-services/ui/dialog';

export function getActions() {
	const actions = {
		select: { ...selectAction },
		add: { ...addAction },
		cancel: { ...cancelAction },
		list: [],
	};

	actions.list.push(...[
		actions.select,
		actions.add,
		actions.cancel,
	]);

	return actions;
}

const selectAction: DialogAction<DdVenue> = {
	id: 'select',
	enabled: false,
	visible: true,
	label: 'Select',
	cssClass: 'btn-green',
	linkType: "callback",
	willCloseDialog: true,
};


const addAction: DialogAction<void> = {
	id: 'add',
	enabled: false,
	visible: false,
	label: 'Add Venue',
	cssClass: 'btn-green',
	linkType: "callback",
	willCloseDialog: false,
};

const cancelAction: DialogAction<void> = {
	id: 'cancel',
	enabled: true,
	visible: true,
	label: 'Cancel',
	linkType: 'callback',
	willCloseDialog: false,
};