import { Component, Input } from '@angular/core';


@Component({
	selector: 'demographics-indicator',
	templateUrl: './SHR-CMN_demographics-indicator.part.html',
	styleUrls: ['./SHR-CMN_demographics-indicator.part.scss']
})
export class DemographicsIndicatorPart {

	@Input() status: string;

	getDemographics() {
		// if (this.status == 'O') return 'far fa-universal-access grey';
		if (this.status == 'Y' || this.status == 'O') return 'far fw fa-check';					// Given & Opted Out
		if (this.status == 'N' || this.status == 'P') return 'far fw fa-exclamation-circle attention';	// Not Given & Partial
	}

	getDemographicsTitle() {
		if (this.status == 'O') return 'Opted out';
		if (this.status == 'N') return 'Not Given';
		if (this.status == 'Y') return 'Given';
		if (this.status == 'P') return 'Partial';
	}
}
