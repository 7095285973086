import { DbConceptName, PitchContest } from "@me-interfaces";
import { DataService } from "../../../../data.service";
import { ExplorerBox, ExplorerBoxClass } from "../../../explorer-box/explorer-box";


export async function getBoxesForParticipants(ds: DataService, pitchContest: PitchContest): Promise<ExplorerBox[]> {

	const boxes: ExplorerBox[] = [];

	const picTeamsByPicId = await ds.admin.picTeam.getByPicIds([pitchContest.picId]);
	const picTeams = picTeamsByPicId[pitchContest.picId];

	for (const picTeam of picTeams) {

		const members = picTeam.members.map(member => `${member.person.firstName} ${member.person.lastName.slice(0, 1)}.`);
		const memberText = members.sort((a, b) => a > b ? 1 : -1).join(', ');
		const application = picTeam.application;

		const box: ExplorerBox = {
			explorerBoxClass: ExplorerBoxClass.Cohort,
			header: 'Team',
			buttons: [
				{ explorer: { nameOverride: `${memberText} & Team`, explorableId: { conceptName: DbConceptName.PicTeam, id: picTeam.picTeamId }, navTooltip: `Team: ${memberText} & Team` } },
				{ explorer: { nameOverride: picTeam.name, explorableId: { conceptName: DbConceptName.Company, id: application.companyId }, navTooltip: `Company: ${picTeam.name}` } },
				{ explorer: { nameOverride: 'Application', explorableId: { conceptName: DbConceptName.Application, id: application.applicationId }, navTooltip: `Application` } },
			],
			notes: picTeam.presentType,
		};

		boxes.push(box);
	}

	boxes.sort((a, b) => a.buttons[0].explorer.nameOverride.toLowerCase() > b.buttons[0].explorer.nameOverride.toLowerCase() ? 1 : -1);
	return boxes;
}