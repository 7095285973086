import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AppSessionSearchDetails, AppSessionStatus, LanguageId } from '@me-interfaces';
import { StaffMemberService } from '@me-services/old-services-and-wrappers/staff-member';
import { AuthService } from '@me-services/core/auth';
import { FuncService } from '@me-services/core/func';
import { OldDomainDataService } from '@me-services/core/old-dd';
import { ErrorPageService } from '@me-services/ui/error-page';
import { LabelsService } from '@me-services/ui/labels';
import firebase from 'firebase/compat/app';
import { BehaviorSubject, Subject } from 'rxjs';
import { distinctUntilChanged, shareReplay } from 'rxjs/operators';
import { UtilityService } from '../utility';
import { getUserAgent } from './get-user-agent';

const APP_SESSION_ID = 'appSessionId';
const APP_SESSION_REQUESTED_PATH = 'requestedPath';
const DEFAULT_REDIRECT_PATH = '/access/my/dashboard';

@Injectable({ providedIn: 'root' })
export class AppSessionService {

	private auth: AuthService;
	private authSubscribed = false;

	// The path for the root continue button
	public readonly continuePath$ = new BehaviorSubject(DEFAULT_REDIRECT_PATH);

	private readonly _status$ = new Subject<AppSessionStatus>();
	public readonly status$ = this._status$.pipe(
		distinctUntilChanged(),
		shareReplay(1), // Only one instance where each subscriber gets the latest value and then future values
	);


	constructor(
		private router: Router,
		private dd: OldDomainDataService,
		private staffMemberService: StaffMemberService,
		private labels: LabelsService,
		private func: FuncService,
		private errorPage: ErrorPageService,
		private util: UtilityService,
		private zone: NgZone,
	) {
	}


	public start(auth: AuthService) {

		this.auth = auth;
		this.recordFirstRoute();

		this.func.getAppSessionId = () => { return this.id; }
		this.func.setAppSessionId = (appSessionId: number) => {
			if (this.id != appSessionId) {
				this.id = appSessionId;
			}
		}

		this.status$.subscribe(this.navigateBasedOnStatus.bind(this));
		this.subscribeToAuth();
	}

	private async callToStartSession(): Promise<AppSessionStatus> {

		const session = await this.func.public.appSession.start({
			userAgent: getUserAgent(),
			languageId: this.labels.languageId,
		});

		if (session.status.state == 'SESSION_STARTED') {

			const { people, directorOfPrograms, zips, labels, staffMembers } = session.data;

			this.dd.setDirectorOfPrograms(directorOfPrograms);
			this.dd.addPeopleToCache(people);
			this.dd.addZipsToCache(zips);
			this.labels.setInitialLabels(labels);
			this.staffMemberService.setMembers(staffMembers);
		}

		return session.status;
	}

	subscribeToAuth() {
		if (this.authSubscribed) return;
		this.authSubscribed = true;

		this.auth.me.firebaseUser$.subscribe(async firebaseUser => {


			if (firebaseUser) {
				if (firebaseUser.emailVerified) {
					const status = await this.callToStartSession();
					this._status$.next(status);
				}
				else {
					this.errorPage.setError('AppSessionService', 'Error: No Verified Email Address', '', '', undefined, '', '', 0);
				}
			}
			else {
				this._status$.next({ state: 'NO_SESSION' });
				this.id = undefined;
			}
		});
	}

	async findMatches(details: AppSessionSearchDetails) {
		this._status$.next(await this.func.public.appSession.findMatches(details));
	}

	async createPerson(details: AppSessionSearchDetails) {
		this._status$.next(await this.func.public.appSession.createPerson(details));
		this.auth.analytics?.logEvent(firebase.analytics.EventName.SIGN_UP, { details });
	}

	async sendCode(assignedPersonId: number, languageId: LanguageId) {
		this._status$.next(await this.func.public.appSession.sendCode({ assignedPersonId }));
	}

	async resendCode(languageId: LanguageId) {
		await this.sendCode(-1, languageId);
	}

	async removeCode() {
		this._status$.next(await this.func.public.appSession.removeCode());
	}

	async assignPerson(code: string) {
		this._status$.next(await this.func.public.appSession.assignPerson({ code }));
	}

	private async navigateBasedOnStatus(status: AppSessionStatus) {
		const s = status.state;
		let path = '/';

		if (s == 'SESSION_STARTED') {
			path = this.redirectPath || DEFAULT_REDIRECT_PATH;
			this.redirectPath = undefined;
		}
		else if (s == 'NO_PERSON') path = '/session/enter-search-details';
		else if (s == 'MATCHES') path = '/session/is-this-you';
		else if (s == 'PENDING_CODE') path = '/session/enter-code';
		else if (s == 'WRONG_CODE') path = '/session/enter-code';
		else if (s == 'READY_TO_TRY_SESSION') {
			this._status$.next(await this.callToStartSession());
			return;
		}
		else if (s == 'NO_SESSION') path = '/';
		else this.util.log.errorMessage(`AppSessionService.navigateBasedOnStatus() with unknown or missing status: ${s}`);

		this.zone.run(() => {
			this.continuePath$.next(path);
			this.router.navigate([path]);
		});
	}

	get id(): number {
		return parseInt(sessionStorage.getItem(APP_SESSION_ID) || "0", 10);
	}

	set id(appSessionId: number | undefined) {
		if (appSessionId) sessionStorage.setItem(APP_SESSION_ID, appSessionId.toString(10));
		else sessionStorage.removeItem(APP_SESSION_ID);
	}

	// The original path that the user hit when starting the app
	public get redirectPath(): string {
		return sessionStorage.getItem(APP_SESSION_REQUESTED_PATH);
	}


	public set redirectPath(path: string | undefined) {
		if (path) sessionStorage.setItem(APP_SESSION_REQUESTED_PATH, path);
		else sessionStorage.removeItem(APP_SESSION_REQUESTED_PATH);
	}


	recordFirstRoute() {
		const redirectPath = location.pathname + location.search;

		if (redirectPath != '/') {	// Ignore the root because the Google Auth flow redirects back to /

			sessionStorage.setItem(APP_SESSION_REQUESTED_PATH, redirectPath);
		}
	}
}