import { Accelerator, AccTeam, DbsAward, DbsSite, PicTeam, PitchContest } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";

export function getAccAwards(
	util: UtilityService,
	sites: readonly DbsSite[],
	awards: readonly DbsAward[],
	accsBySiteId: Record<number, Accelerator[]>,
	accTeams: readonly AccTeam[],
) {

	const accAwards = awards.filter(a => !!a.accTeamId);

	const accAwardeesBySiteId: Record<number, number> = {};
	const accAwardsBySiteId: Record<number, number> = {};

	for (const site of sites) {

		const siteId = site.siteId;
		const accs = accsBySiteId[siteId] ?? [];

		const accIds = accs.map(acc => acc.accId);

		const accTeamIds = accTeams
			.filter(t => accIds.includes(t.accId))
			.map(t => t.accTeamId);

		const siteAccAwards = accAwards.filter(a => accTeamIds.includes(a.accTeamId));

		accAwardeesBySiteId[siteId] = util.array.cleanNumericIds(siteAccAwards.map(a => a.accTeamId)).length;
		accAwardsBySiteId[siteId] = util.array.sum(siteAccAwards.map(a => a.value));
	}

	return { accAwardeesBySiteId, accAwardsBySiteId };
}