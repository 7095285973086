import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AccTeamGoalsAndAccess, DbcAccTeamGoal, QuarterlyGoalFlags } from '@me-interfaces';
import { DialogAction, DialogService } from '@me-services/ui/dialog';
import { LabelsService } from '@me-services/ui/labels';
import { ListboxOption } from '@me-shared-parts/UI-common';
import { Message } from '@progress/kendo-angular-conversational-ui';
import { TabStripComponent } from '@progress/kendo-angular-layout';
import { AccGoalsAddDialog, AccGoalsAddDialogCallbackValue } from '../acc-goals-add-dialog/SHR-PG_acc-goals-add.dialog';
import { AccGoalAddRow, AccGoalEditRow } from '../acc-goals-interfaces';

@Component({
	selector: 'acc-goals-part',
	templateUrl: './SHR-PG_acc-goals.part.html',
	styleUrls: ['SHR-PG_acc-goals.part.scss'],
})
export class AccGoalsPart extends DestroyablePart implements OnInit, OnChanges {

	@Input() teamGoalsAndAccess: AccTeamGoalsAndAccess;

	/**
	 * This is a shared component and the act of adding a goal can be performed by either the
	 * admin or frontend tool service. So we raise an event to tell the service to perform the action.
	 */
	@Output() editGoal = new EventEmitter<AccGoalEditRow>();

	/**
	 * This is a shared component and the act of adding a goal can be performed by either the
	 * admin or frontend tool service. So we raise an event to tell the service to perform the action.
	 */
	@Output() addGoal = new EventEmitter<AccGoalAddRow>();

	/**
	 * This is a shared component and the act of adding a goal can be performed by either the
	 * admin or frontend tool service. So we raise an event to tell the service to perform the action.
	 */
	@Output() addComment = new EventEmitter<{ comment: string, accTeamGoalId: number }>();


	goalOptions: ListboxOption[] = [];
	selectedGoalOption: ListboxOption;

	selectedGoal: DbcAccTeamGoal;
	selectedGoalComments: Message[];
	selectedGoalQuarterlyGoalFlags: QuarterlyGoalFlags;

	canAddGoal: boolean;
	canEditGoal: boolean;

	/**
	 * This is a shared component and the act of adding a goal can be performed by either the
	 * admin or frontend tool service. So we raise an event for the service to add the goal
	 * and remember that it is happening with this flag.
	 */
	goalBeingAdded: boolean;

	@ViewChild(TabStripComponent) tabs: TabStripComponent;

	inlineLabels = this.labels.trackInlineLabels(this, [
		'Goal',
		'Edit',
		'Goal created by',
		'Goal updated by',
	]);


	chooseGoalText = 'Choose a Goal';

	constructor(
		public labels: LabelsService,
		private dialogService: DialogService,
	) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();
	}

	async ngOnChanges() {
		if (!this.teamGoalsAndAccess) throw new Error('Missing required attribute: teamGoalsAndAccess');

		this.goalOptions = [];
		this.chooseGoalText = await this.labels.get({ key: 'Choose a Goal' });

		// Build listbox options
		if (this.teamGoalsAndAccess.goals && this.teamGoalsAndAccess.goals.length) {
			this.goalOptions = this.teamGoalsAndAccess.goals.map(g => ({ value: '' + g.accTeamGoalId, text: `Q${g.quarter} - ${g.summary}` }));
		}

		/**
		 * If a new goal is added, then find the maxId and set the selectedGoal, selectedGoalOption and selectedGoalQuarterlyGoalFlags.
		 * If the goal is edited, then find and set selectedGoal, selectedGoalOption and selectedGoalQuarterlyGoalFlags else set them undefined
		 */
		if (this.goalBeingAdded && this.teamGoalsAndAccess.goals.length) {
			//Find max accTeamGoalId
			let maxValueIndex = 0;
			let maxId = this.teamGoalsAndAccess.goals[0].accTeamGoalId;

			this.teamGoalsAndAccess.goals.forEach((goal, index) => {
				if (goal.accTeamGoalId > maxId) {
					maxId = goal.accTeamGoalId
					maxValueIndex = index
				}
			})

			this.selectedGoal = this.teamGoalsAndAccess.goals[maxValueIndex];
			this.selectedGoalComments = this.getComments();
			this.selectedGoalOption = this.getSelectedGoalOption(maxId);
			this.selectedGoalQuarterlyGoalFlags = this.getSelectedGoalQuarterlyFlags(this.selectedGoal.quarter);

		} else if (this.selectedGoalOption) {
			const goal = this.getSelectedGoalOption(+this.selectedGoalOption.value);
			if (goal) {
				this.selectedGoalOption = goal;
				this.selectedGoal = this.getSelectedGoal(+goal.value);
				this.selectedGoalComments = this.getComments();
				this.selectedGoalQuarterlyGoalFlags = this.getSelectedGoalQuarterlyFlags(this.selectedGoal.quarter);
			}
			else {
				this.selectedGoalOption = undefined;
				this.selectedGoal = undefined;
				this.selectedGoalComments = undefined;
				this.selectedGoalQuarterlyGoalFlags = undefined;
			}
		}

		this.setShowAddAndEditGoal();

	}


	/**
	 * For a selected team, set canAddGoal and canEditGoal - to show/hide Add Goal button and edit goal tab.
	 */
	setShowAddAndEditGoal() {
		this.canAddGoal = this.teamGoalsAndAccess.access.canAddGoal;
		this.canEditGoal = this.teamGoalsAndAccess.access.canEditGoal;
	}


	/**
	 * Show the goal details for the selected goal.
	 * Show nothing if no goal was selected.
	 */
	onGoalSelected(goalId: string) {

		this.goalBeingAdded = false;

		const id = +goalId;

		if (id) {
			this.selectedGoalOption = this.getSelectedGoalOption(id);
			this.selectedGoal = this.getSelectedGoal(id);
			this.selectedGoalComments = this.getComments();
			this.selectedGoalQuarterlyGoalFlags = this.getSelectedGoalQuarterlyFlags(this.selectedGoal.quarter);
		}
		else {
			this.selectedGoalOption = undefined;
			this.selectedGoal = undefined;
			this.selectedGoalComments = [];
			this.selectedGoalQuarterlyGoalFlags = undefined;
		}
	}


	/**
	 * For a given goalId, find and return selectedGoalOption from goalOptions   
	 */
	getSelectedGoalOption(goalId: number) {
		return this.goalOptions.find(o => +o.value == goalId);
	}


	/**
	 * For a given goalId, find and return selectedGoal from teamGoalsAndAccess   
	 */
	getSelectedGoal(goalId: number) {
		return this.teamGoalsAndAccess.goals.find(g => +g.accTeamGoalId == +goalId);
	}


	/**
	 * For a given quarter get selectedGoalQuarterlyGoalFlags from quarterlyGoalFlags   
	 */
	getSelectedGoalQuarterlyFlags(quarterId: number) {
		return this.teamGoalsAndAccess.quarterlyGoalFlags[quarterId];
	}


	/**
	 * Click on the add goal opens the dialog to add a new goal.
	 */
	async openAddDialog() {
		const action: DialogAction<AccGoalsAddDialogCallbackValue> = await this.dialogService.showCustom(
			AccGoalsAddDialog,
			{
				data: {
					quarterlyGoalFlags: this.teamGoalsAndAccess.quarterlyGoalFlags,
				}
			},
			580, 310
		);

		const actionId = action?.id;
		const response = action?.callbackResult;

		if (actionId == 'add') {
			const newGoal: AccGoalAddRow = { ...response, accTeamId: this.teamGoalsAndAccess.team.accTeamId };
			this.goalBeingAdded = true;
			this.addGoal.emit(newGoal);
		}
	}


	getComments(): Message[] {
		const comments: Message[] = [];

		const goalComments = this.teamGoalsAndAccess.comments.filter(c => c.comment.accTeamGoalId == this.selectedGoal.accTeamGoalId);

		//
		// Always add a first comment showing who created the goal
		//
		comments.push({
			author: {
				id: 0,
				name: 'EforAll App',
			},
			text: `${this.inlineLabels['Goal created by']} ${this.selectedGoal.createdByPersonName}`,
			timestamp: new Date(this.selectedGoal.createdUTC * 1000)
		});

		if (this.selectedGoal.createdUTC != this.selectedGoal.updatedUTC) comments.push({
			author: {
				id: 0,
				name: 'EforAll App',
			},
			text: `${this.inlineLabels['Goal updated by']} ${this.selectedGoal.updatedByPersonName}`,
			timestamp: new Date(this.selectedGoal.updatedUTC * 1000)
		});


		return goalComments.reduce((a, comment) => {
			a.push({
				author: {
					id: comment.comment.personId,
					name: comment.commenterName,
				},
				text: comment.comment.comment,
				timestamp: new Date(comment.comment.createdUTC * 1000)
			});
			return a;
		}, comments).sort((a, b) => a.timestamp > b.timestamp ? 1 : -1);

	}


	onEditGoal(e: AccGoalEditRow) {
		this.goalBeingAdded = false;
		this.editGoal.emit(e);
	}
}