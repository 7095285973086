import { DdPicRow } from './dd-pic-row';
import { DdPic } from './dd-pic';
import { DdObjects } from '../dd-objects';
import { OldDomainDataService } from '..';

/**
 * @deprecated Use the DataService instead
 */
export class DdPics extends DdObjects<DdPicRow, DdPic> {

	constructor(private dd: OldDomainDataService) {
		super('picId');
	}

	async loadData(hash: string, data: DdPicRow[]) {
		const objects = data.map(d => new DdPic(this.dd, d));
		this.loadObjects(hash, objects);
	}
}