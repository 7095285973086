<div class='acc-application-counts-part'>

	<me-droplist [items]='viewDroplistItems' [selectedItem]='selectedViewItem'
		(selectionChange)='onViewDroplistChange($event)' [filterable]='false' [navigation]='true' sortBy='customSort'
		[groupSortValues]='groupSortValues' style='margin:1em 0 0.2em;position: relative; left:-3px'>
	</me-droplist>

	<ng-container *ngIf="selectedViewItem.uniqueId === 'general-counts'">
		<counts-table-part [data]="generalCountsData" (countClick)="generalCountClick($event)"></counts-table-part>
	</ng-container>

	<ng-container *ngIf="selectedViewItem.uniqueId === 'status'">
		<acc-application-status-counts-part [accStageId]="accStageId" (filter)="filter($event)">
		</acc-application-status-counts-part>
	</ng-container>

	<ng-container *ngIf="selectedViewItem.uniqueId.endsWith('-demographics')">
		<acc-application-demographics-counts-part [accStageId]="accStageId" [applicationStatus]="getApplicationStatus()"
			(filter)="filter($event)">
		</acc-application-demographics-counts-part>
	</ng-container>

	<ng-container *ngIf="selectedViewItem.uniqueId.endsWith('-industry')">
		<acc-application-other-field-counts-part [accStageId]="accStageId" [applicationStatus]="getApplicationStatus()"
			[field]="'industry'" (filter)="filter($event)">
		</acc-application-other-field-counts-part>
	</ng-container>

	<ng-container *ngIf="selectedViewItem.uniqueId.endsWith('-location')">
		<acc-application-other-field-counts-part [accStageId]="accStageId" [applicationStatus]="getApplicationStatus()"
			[field]="'location'" (filter)="filter($event)">
		</acc-application-other-field-counts-part>
	</ng-container>

	<ng-container *ngIf="selectedViewItem.uniqueId.endsWith('-offerings')">
		<acc-application-other-field-counts-part [accStageId]="accStageId" [applicationStatus]="getApplicationStatus()"
			[field]="'offerings'" (filter)="filter($event)">
		</acc-application-other-field-counts-part>
	</ng-container>

	<ng-container *ngIf="selectedViewItem.uniqueId.endsWith('-orgType')">
		<acc-application-other-field-counts-part [accStageId]="accStageId" [applicationStatus]="getApplicationStatus()"
			[field]="'orgType'" (filter)="filter($event)">
		</acc-application-other-field-counts-part>
	</ng-container>

</div>