import { CompanyTypeId, DbConceptName, Dbd, Immutable } from '@me-interfaces';


interface MutableDbdCompanyType extends Dbd {

	readonly _concept: DbConceptName.CompanyType,

	companyTypeId: CompanyTypeId,
	name: string,
	isNonprofit: boolean,
	isFormed: boolean,
	isEforAllOnly: boolean,
}


export interface DbdCompanyType extends Immutable<MutableDbdCompanyType> { }