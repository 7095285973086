import { SiteAreaData, SiteAreaService, SiteDashboardPageService } from '@SITE-area';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { ADDROW_GRID_ACTION_KEY, DBLCLICK_GRID_ACTION_KEY, GridAction, GridColumnType, GridSetup, PersonCols } from '@me-grid';
import { AgreementTypeId, LanguageId } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { DialogAction } from '@me-services/ui/dialog';
import { GridPart, Icon } from '@me-shared-parts/UI-common';
import { GridColumnConfig, buildColumn } from '@me-shared-parts/UI-common/grid/columns';
import { Observable, combineLatest, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { CouncilMemberCallBackValue, SiteCouncilMemberEditDialog } from './edit-dialog/council-member-edit.dialog';


interface CouncilMemberRow {
	councilMemberId: number,
	personId: number,
	personName: string,
	startUTC: number,
	endUTC: number,
	startDate: Date,
	endDate: Date,
	companyName: string,
	title: string,
	handbookSigned: string,
	mediaSigned: string,
	updatedUTC: number,
	status: 'Active' | 'Inactive',
}


@Component({
	selector: 'site-council-members-view-part',
	templateUrl: './council-members-view.part.html',
})
export class SiteCouncilMembersViewPart extends DestroyablePart implements OnInit {

	public readonly: boolean;
	public gridSetup = this.setupGrid(false);

	public rows$: Observable<CouncilMemberRow[]> = of([]);

	@ViewChild(GridPart) meGrid: GridPart<CouncilMemberRow>;

	constructor(
		private util: UtilityService,
		public siteAreaService: SiteAreaService,
		public ds: DataService,
		public pageService: SiteDashboardPageService
	) {
		super();
	}

	ngOnInit(): void {

		super.initDestroyable();

		super.subscribe([this.siteAreaService.access$], async ([access]) => {
			this.readonly = access?.root != 'Write';
			this.gridSetup = this.setupGrid(access.root == 'Write');
		});

		this.rows$ = combineLatest([
			this.ds.admin.singletonsAsOfUTC$,
			this.siteAreaService.data$,
		]).pipe(mergeMap(data => this.buildRows(data[0], data[1])));

	}



	async buildRows(
		singletonsAsOfUTC: number,
		data: SiteAreaData,
	) {
		if (!singletonsAsOfUTC || !data) return [];

		const councilMembers = (await this.ds.admin.councilMember.getBySiteIds([data.site.siteId]))[data.site.siteId];

		const personPositionMap = await this.ds.admin.position.getArraysByForeignIds('personId', councilMembers.map(m => m.personId));

		const companyIds: number[] = [];
		for (const personId in personPositionMap) {
			if (personPositionMap[+personId].length) {
				companyIds.push(personPositionMap[personId][0].companyId);
			}
		}

		const companyMap = await this.ds.admin.company.getManyAsMap(companyIds);


		const councilVersionIds = (await this.ds.admin.agreementVersion.getArrayByForeignId('agreementTypeId', AgreementTypeId.CouncilHandbook)).map(version => version.agreementVersionId);
		const mediaConsentVersionIds = (await this.ds.admin.agreementVersion.getArrayByForeignId('agreementTypeId', AgreementTypeId.MediaConsent)).map(version => version.agreementVersionId);
		const signatures = await this.ds.admin.agreementSignature.getAllAsArray();
		const councilSignatures = signatures.filter(signature => councilVersionIds.includes(signature.agreementVersionId));
		const mediaSignatures = signatures.filter(signature => mediaConsentVersionIds.includes(signature.agreementVersionId));

		const rows: CouncilMemberRow[] = councilMembers.map(member => {
			const position = personPositionMap[member.personId] ? personPositionMap[member.personId][0] : undefined;
			const companyName = position ? companyMap[position.companyId]?._name : '';
			const title = position ? position.title : '';
			const status = (member.endUTC && member.endUTC > (Date.now() / 1000)) || !member.endUTC ? 'Active' : 'Inactive';
			return {
				councilMemberId: member.councilMemberId,
				personId: member.personId,
				personName: member.person._name,
				startUTC: member.startUTC,
				startDate: this.util.date.getDate(member.startUTC),
				endUTC: member.endUTC || undefined,
				endDate: member.endUTC ? this.util.date.getDate(member.endUTC) : undefined,
				title,
				companyName,
				handbookSigned: councilSignatures.find(signature => signature.personId == member.personId) ? 'Signed' : '',
				mediaSigned: mediaSignatures.find(signature => signature.personId == member.personId) ? 'Signed' : '',
				updatedUTC: member.updatedUTC,
				status,
			};
		});

		return rows;
	}


	private setupGrid(canWrite: boolean): GridSetup<CouncilMemberRow> {

		return {
			experience: 'PERSON',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Council Member",
			rowPluralName: "Council Members",
			rowKey: "councilMemberId",
			stateKey: "site-council-members",
			canAdd: canWrite,
			canRefresh: false,
			canDownload: false,
			columnsToAdd: [
				buildColumn('councilMemberId', GridColumnConfig.id, { hidden: true, header: 'councilMemberId' }),
				buildColumn('companyName', GridColumnConfig.companyName, { header: 'Business' }),
				buildColumn('title', GridColumnConfig.personTitle, { header: 'Title' }),
				{ field: "startDate", header: "Start", width: 150, type: GridColumnType.date },
				{ field: "endDate", header: "End", width: 150, type: GridColumnType.date, hidden: true, },
				buildColumn('handbookSigned', GridColumnConfig.text, { header: 'Handbook', headerTooltip: 'Signed Council Handbook' }),
				buildColumn('mediaSigned', GridColumnConfig.text, { header: 'Media Consent', headerTooltip: 'Signed Media Consent', hidden: true }),
				buildColumn('status', GridColumnConfig.text, { header: 'Status' }),
			],
			// pre filter the ones with endUTC null
			actions: [
				{ key: 'edit-member', icon: Icon.action_edit, label: 'Edit Member', enabled: false },
				{ key: 'end-membership', icon: Icon.action_delete, label: 'End Membership', enabled: false },
			],
			initialState: {
				sort: [{ field: PersonCols.name, dir: 'asc' }],
				filter: {
					logic: 'and',
					filters: [
						{ field: 'status', operator: 'eq', value: 'Active' },
					],
				}
			},
			actionEnabler: this.gridActionEnabler.bind(this),
		};
	}


	gridActionEnabler(action: GridAction, rows: CouncilMemberRow[]) {
		if (rows.length == 0) return false;

		else if (action.key == 'end-membership') {
			if (rows.find(r => r.endUTC && r.endUTC <= (new Date().getTime() / 1000))) {
				return false;
			} else return true;
		}


		return true;
	}

	async gridActionHandler(action: { actionKey: string, rows: CouncilMemberRow[] }) {
		if (this.readonly) return;

		const row = action.rows[0];

		if (action.actionKey == ADDROW_GRID_ACTION_KEY) {

			const conceptAndId = await this.ds.searcher.selectPerson();

			if (conceptAndId) {
				const person = await this.ds.admin.person.getOne(conceptAndId.id);

				await this.siteAreaService.config.actions.addCouncilMember({ personId: person.personId });

			}

		}
		else if (action.actionKey == 'edit-member' || action.actionKey == DBLCLICK_GRID_ACTION_KEY) {
			const action: DialogAction<CouncilMemberCallBackValue> = await this.ds.dialogService.showCustom(SiteCouncilMemberEditDialog, {
				data: {
					councilMemberId: row.councilMemberId,
					personId: row.personId,
				}
			}, 450, 350);

			const id = action?.id;
			if (id == 'update') {
				if (action.callbackResult) await this.siteAreaService.config.actions.setCouncilMember({ personId: row.personId, startUTC: action.callbackResult.startUTC, endUTC: action.callbackResult.endUTC });
			}
			else if (id == 'remove') {
				if (action.callbackResult) {
					const message = `Unless this member was added by mistake, please retire membership by setting a past end date, instead of permanently deleting the member.\n\nDo you wish to permanently delete ${row.personName}?`;
					const yes = await this.ds.dialogService.confirm(message, 450, 300, 'Permanently Delete', 'Cancel');

					if (yes) await this.siteAreaService.config.actions.removeCouncilMember({ councilMemberId: action.callbackResult.councilMemberId, personId: action.callbackResult.personId });
				}
			}
		}
		else if (action.actionKey == 'end-membership') {
			if (row.endUTC && row.endUTC <= (new Date().getTime() / 1000)) return;

			const date = this.util.date.formatDate(new Date(), 'MMM D, YYYY', LanguageId.English);
			const yes = await this.ds.dialogService.confirm(`Are you sure you want to end ${row.personName}'s council membership? Their end date will be set to: ${date}`, 400, 250, 'End', 'Cancel');
			if (yes) await this.siteAreaService.config.actions.setCouncilMember({ personId: row.personId, startUTC: row.startUTC, endUTC: new Date().getTime() / 1000 });

		}
	}

}