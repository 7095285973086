<div class='acc-team-goals-view-part'>
	<ng-container *ngIf='goalsData$ | async; let goalsData'>
		<ng-container *ngIf="goalsData.length">
			<table class='pro-table pro-table-without-border'>
				<tr>
					<th style='width: 50px;'>Qtr</th>
					<th style='width: 200px;'>Summary</th>
					<th style='width: 350px;'>Details</th>
					<th style='width: 350px;'>Progress</th>
				</tr>

				<tr *ngFor='let award of goalsData'>
					<td>{{award.quarter}}</td>
					<td>{{award.summary}}</td>
					<td>{{award.details}}</td>
					<td>{{award.progress}}</td>
				</tr>
			</table>
		</ng-container>
		<ng-container *ngIf="!goalsData.length">
			<i>There are no goals for this team.</i>
		</ng-container>
	</ng-container>
</div>