import { AdminAreaService, ProgramPageService, ProgramDashboardViewId as ViewId } from '@ADMIN-area';
import { Component, Input, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AppAreaIdentifier, LanguageId } from '@me-interfaces';
import { DroplistItem, Icon, getIconContext } from '@me-shared-parts/UI-common';
import { map } from 'rxjs';


@Component({
	selector: 'program-views-part',
	templateUrl: './program-views.part.html',
})
export class ProgramViewsPart extends DestroyablePart implements OnInit {

	@Input() readonly: boolean;
	identifier: AppAreaIdentifier<string>;

	public englishlanguageId = LanguageId.English;
	public spanishlanguageId = LanguageId.Spanish;


	viewDroplistItems: DroplistItem<never, ViewId>[] = [
		{ uniqueId: ViewId.ProgramOverview, groupName: 'Program', iconContext: getIconContext(Icon.view_list, undefined, undefined, 'Dashboard'), customSort: 'Program-1' },
		{ uniqueId: ViewId.AgreementTypes, groupName: 'Agreements', iconContext: getIconContext(Icon.concept_agreementType, undefined, undefined, 'Agreements'), customSort: 'Program-2' },
		{ uniqueId: ViewId.AgreementSignatures, groupName: 'Agreements', iconContext: getIconContext(Icon.concept_agreementSignature, undefined, undefined, 'Signatures'), customSort: 'Program-3' },
		{ uniqueId: ViewId.SpanishTrainingMaterials, groupName: 'Curriculum', iconContext: getIconContext(Icon.material_handout, undefined, undefined, 'Spanish Training Materials'), customSort: 'Program-4' },
		{ uniqueId: ViewId.EnglishTrainingMaterials, groupName: 'Curriculum', iconContext: getIconContext(Icon.material_handout, undefined, undefined, 'English Training Materials'), customSort: 'Program-5' },
		{ uniqueId: ViewId.AcceleratorProblems, groupName: 'Problems', iconContext: getIconContext(Icon.material_handout, undefined, undefined, 'Accelerator Problems'), customSort: 'Program-6' },
	];

	selectedViewItem$ = this.pageService.selectedView$.pipe(
		map(viewId => this.viewDroplistItems.find(item => item.uniqueId == viewId)),
	);


	constructor(
		private pageService: ProgramPageService,
		private adminAreaService: AdminAreaService,
	) {
		super();
	}


	ngOnInit(): void {

		super.initDestroyable();

		super.subscribe([this.adminAreaService.accessAndId$], async ([accessAndId]) => {
			this.readonly = accessAndId?.access?.root != 'Write';
			this.identifier = accessAndId?.id;
		})
	}


	onViewDroplistChange(selectedView: DroplistItem) {
		this.pageService.selectView(selectedView.uniqueId);
	}


	showView(view: string, viewItem: DroplistItem<never, ViewId>) {

		if (!viewItem?.uniqueId) return false;

		const viewId = viewItem.uniqueId;

		if (viewId == ViewId.ProgramOverview && view == 'ProgramOverview') return true;
		if (viewId == ViewId.AgreementTypes && view == 'AgreementTypes') return true;
		if (viewId == ViewId.AgreementSignatures && view == 'AgreementSignatures') return true;
		if (viewId == ViewId.SpanishTrainingMaterials && view == 'SpanishTrainingMaterials') return true;
		if (viewId == ViewId.EnglishTrainingMaterials && view == 'EnglishTrainingMaterials') return true;
		if (viewId == ViewId.AcceleratorProblems && view == 'AcceleratorProblems') return true;

		return false;
	}
}
