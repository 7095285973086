import { Answer, DbsApplication, DbsApplicationParticipant, DbcApplicationParticipantPending, DbsCompany, DbsPerson, DbcPublicCompany, DbcZip } from "@me-interfaces";


export interface ApplicationParticipant_old extends DbsApplicationParticipant {
	readonly person: DbsPerson,
	readonly zip: DbcZip | undefined,
}


export interface ApplicationTeam {
	readonly applicationId: number,
	/** The Id of the person that is the applicant */
	readonly personId: number,
	readonly pendingParticipants: DbcApplicationParticipantPending[],
	readonly participants: ApplicationParticipant_old[],
	readonly restOfTeam: string,
}


export interface Old_Application extends DbsApplication, ApplicationTeam {
	readonly company: DbcPublicCompany,
	readonly companyZip: DbcZip | undefined,
	readonly participants: ApplicationParticipant_old[],
	readonly pendingParticipants: DbcApplicationParticipantPending[],
	readonly answers: Answer[],
}


export interface ApplicationListDetails extends DbsApplication {
	readonly applicant: DbsPerson,
	readonly applicantZip: DbcZip,
	readonly company: DbsCompany,
	readonly companyZip: DbcZip | undefined,
	readonly numberOfParticipants: number,
	readonly namesOfParticipants: string,
}