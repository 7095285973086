import { Component, EventEmitter, Input, OnDestroy, Renderer2 } from '@angular/core';
import { GridAction, GridController } from '@me-grid';
import { LabelsService } from '@me-services/ui/labels';
import { Offset } from "@progress/kendo-angular-popup";
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { getIconClass } from '../icon';
import { GridExperience } from './base';


@Component({
	selector: 'me-grid-menu',
	templateUrl: './SHR-CMN_grid-menu.part.html',
	styleUrls: ['SHR-CMN_grid-menu.part.scss'],
})
export class GridMenuPart<ROW> implements OnDestroy {

	@Input()
	public actions: GridAction[] = [];

	@Input()
	private experience: GridExperience<ROW>;

	@Input() public set grid(grid: GridController<any>) {
		this._grid = grid;
		this.unsubscribe();
		this.contextMenuClickSubscription = grid.contextMenu$.subscribe(this.showContextMenu);
	}

	private _grid: GridController<any>;
	public show: boolean;
	public dataItem: any;
	public offset: Offset;

	private contextMenuClickSubscription: Subscription;
	private documentClickSubscription: any;

	constructor(private renderer: Renderer2, public labelsService: LabelsService,) {
		this.showContextMenu = this.showContextMenu.bind(this);
		this.documentClickSubscription = this.renderer.listen(
			"document",
			"click",
			() => {
				this.show = false;
			}
		);
	}
	

	public getIconClass = getIconClass;

	public ngOnDestroy(): void {
		this.unsubscribe();
		this.documentClickSubscription();
	}


	public async onActionItemClick(action: GridAction) {
		if (!action.enabled) return;
		const rows = await this._grid.selectedRows$.pipe(take(1)).toPromise();
		this.experience.doGridAction(action.key, rows);
	}


	private showContextMenu(context: { rowData: any, left: number, top: number, }): void {
		if (!context) this.show = false;
		else {
			const { rowData, left, top } = context;
			this.dataItem = rowData;
			this.show = true;
			this.offset = { left, top };
		}
	}


	private unsubscribe(): void {
		if (this.contextMenuClickSubscription) {
			this.contextMenuClickSubscription.unsubscribe();
			this.contextMenuClickSubscription = null;
		}
	}
}