import { AccTeam, DbConceptName, DbsAccTeamMember, DbsPerson, ImmutableArray, ImmutableObject, PhoneTypeId } from "@me-interfaces";
import { ExplorerBox, ExplorerBoxClass } from "../../../explorer-box/explorer-box";


export async function getBoxesForMember(accTeam: AccTeam): Promise<ExplorerBox[]> {

	const entrepreneurs = getBoxes(accTeam.members, 'E');
	const coaches = getBoxes(accTeam.members, 'C');
	const mentors = getBoxes(accTeam.members, 'M');
	const quit = getBoxes(accTeam.members, 'X');

	return [...entrepreneurs, ...coaches, ...mentors, ...quit];
}


function getBoxes(members: ImmutableArray<{ member: DbsAccTeamMember, person: DbsPerson, }>, role: "E" | "C" | "M" | "X") {
	const filteredMembers = members.filter(member => member.member.role == role)
		.sort((a, b) => a.person._name > b.person._name ? 1 : -1);

	return filteredMembers.map(member => {
		const header = member.member.role == 'E' ? 'Entrepreneur' : member.member.role == 'C' ? 'Coach' : member.member.role == 'M' ? 'Mentor' : 'Quit';
		const box: ExplorerBox = {
			explorerBoxClass: ExplorerBoxClass.Member,
			header,
			buttons: [
				{ explorer: { explorableId: { conceptName: DbConceptName.Person, id: member.member.personId }, navTooltip: `${header}: ${member.person._name}` } },
				{ link: { linkType: 'email', email: member.person._email } },
			],
			notes: '',
		};

		if (member.person.phone) {
			box.buttons.push({ link: { linkType: 'call', phone: member.person.phone } });

			if (member.person.phoneTypeId == PhoneTypeId.Mobile) {
				box.buttons.push({ link: { linkType: 'sms', phone: member.person.phone } });
			}
		}

		return box;
	});
}
