<div class='acc-interview-checks-part'>

	<me-icon *ngIf="eventsStatusIcon" [href]="eventsStatusIcon.link" [icon]="eventsStatusIcon.icon"></me-icon>

	<me-icon *ngIf="interviewersStatusIcon" [href]="interviewersStatusIcon.link"
		[icon]="interviewersStatusIcon.icon"></me-icon>
		
	<me-icon *ngIf="applicationsStatusIcon" [href]="applicationsStatusIcon.link" [icon]="applicationsStatusIcon.icon">
	</me-icon>

</div>