import { SiteAreaData, SiteAreaService, SiteDashboardPageService } from '@SITE-area';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { GridSetup } from '@me-grid';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { GridPart } from '@me-shared-parts/UI-common';
import { Observable, combineLatest, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';


interface ZipCodeRow {
	zipId: number,
	updatedUTC: number,
}


@Component({
	selector: 'site-zip-codes-view-part',
	templateUrl: './zip-codes-view.part.html',
})
export class SiteZipCodesViewPart extends DestroyablePart implements OnInit {

	public gridSetup = this.setupGrid();

	public rows$: Observable<ZipCodeRow[]> = of([]);

	@ViewChild(GridPart) meGrid: GridPart<ZipCodeRow>;

	constructor(
		private util: UtilityService,
		public siteAreaService: SiteAreaService,
		public ds: DataService,
		public pageService: SiteDashboardPageService
	) {
		super();
	}

	ngOnInit(): void {

		super.initDestroyable();

		this.rows$ = combineLatest([
			this.ds.admin.singletonsAsOfUTC$,
			this.siteAreaService.data$,
		]).pipe(mergeMap(data => this.buildRows(data[0], data[1])));

	}



	async buildRows(
		singletonsAsOfUTC: number,
		data: SiteAreaData,
	) {

		if (!singletonsAsOfUTC || !data) return [];


		const siteZips = (await this.ds.domain.zip.getAll()).filter(zip => zip.enSiteId == data.site.siteId || zip.esSiteId == data.site.siteId);
		const rows: ZipCodeRow[] = siteZips.map(siteZip => ({
			zipId: siteZip.zipId,
			updatedUTC: 0,
		}));

		return rows;
	}



	private setupGrid(): GridSetup<ZipCodeRow> {

		return {
			experience: 'ZIP',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Zip Code",
			rowPluralName: "Zip Codes",
			rowKey: "zipId",
			stateKey: "site-zip-codes",
			canAdd: false,
			canRefresh: false,
			canDownload: false,
		};
	}

}