<div class="company-editors-part" *ngIf='profile'>

	<a class='popout' target="_blank" title="Open in New Tab" [href]="popoutUrl">
		<i class="far fa-external-link"></i>
	</a>

	<kendo-tabstrip (tabSelect)="onTabSelect($event)" [class]='tabStripClass'>

		<kendo-tabstrip-tab [selected]="editorKey === 0">
			<ng-template kendoTabTitle>
				<i title="Profile" class="far fa-address-card"></i>
				<span *ngIf='editorKey === 0'>Profile</span>
			</ng-template>
			<ng-template kendoTabContent>
				<company-profile-editor [company]='profile' [initialSelectorKey]='selectors.profile'>
				</company-profile-editor>
			</ng-template>
		</kendo-tabstrip-tab>

		<kendo-tabstrip-tab [selected]="editorKey === 1">
			<ng-template kendoTabTitle>
				<i title="Notes" class="far fa-sticky-note"
					[class.redFlag]='profile._entityExtracts.noteCounts.redFlags > 0'></i>
				<span [class.redFlag]='profile._entityExtracts.noteCounts.redFlags > 0'
					*ngIf='profile._entityExtracts.noteCounts.total > 0'>{{profile._entityExtracts.noteCounts.total}}</span>
				<span *ngIf='editorKey === 1 && profile._entityExtracts.noteCounts.total !== 1'>Notes</span>
				<span *ngIf='editorKey === 1 && profile._entityExtracts.noteCounts.total === 1'>Note</span>
			</ng-template>
			<ng-template kendoTabContent>
				<entity-notes-editor [entity]='profile' [initialNoteId]='selectors.notes'>
				</entity-notes-editor>
			</ng-template>
		</kendo-tabstrip-tab>

		<kendo-tabstrip-tab [selected]="editorKey === 2">
			<ng-template kendoTabTitle>
				<i title="Connections" class="far fa-exchange"></i>
				<span *ngIf='editorKey === 2'>Connections</span>
			</ng-template>
			<ng-template kendoTabContent>


				<message-box alertType='info'>
					Soon you will be able to view and edit connections right here.
					In the meantime, please enjoy the following buttons which launch the EforAll App into a different
					browser tab.
				</message-box>

				<p></p>

				<p>
					<a target='_blank'
						href="https://eforall.app/access/contacts/companies/{{profile.companyId}}/positions"
						class="btn">Positions</a> (Company to Person)
				</p>

				<p>
					<span target='_blank' disabled
						href="https://eforall.app/access/contacts/companies/{{profile.companyId}}/associations"
						class="btn">Associations</span> (Company to Program) - not implemented yet
				</p>

			</ng-template>
		</kendo-tabstrip-tab>
	</kendo-tabstrip>
</div>