import { Dbc } from "@me-interfaces";


export interface DbcAccTeamGoalComment extends Dbc {
	readonly accTeamGoalCommentId: number,
	readonly accTeamGoalId: number,
	readonly personId: number,
	readonly comment: string,
	readonly createdUTC: number,
	readonly createdByPersonId: number,
	readonly updatedUTC: number,
	readonly updatedByPersonId: number,
}