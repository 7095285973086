import { Dbc } from "@me-interfaces";


/**
 * @deprecated Use DbsPerson singletons instead
 */
export interface DbcCompanyForMenu extends Dbc {
	companyId: number,
	photoUTC: number,
	industryId: number,
	_name: string,
}