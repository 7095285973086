import { AccApplicationContext, AccAreaService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AccStageId, AccTeam, Accelerator, Answer, DbaAccReading, FeedbackData, QuestionTypeId } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { combineLatest, mergeMap } from 'rxjs';


interface ReadersFeedback {
	acc: Accelerator,
	feedback: FeedbackData[],
}


@Component({
	selector: 'acc-reading-feedback-view-part',
	templateUrl: './acc-reading-feedback-view.part.html',
})
export class AccReadingFeedbackViewPart extends DestroyablePart implements OnInit {

	private accessAtStage$ = this.accAreaService.getAccessAtStage(AccStageId.Reading);

	readonly: boolean;

	readersFeedback$ = combineLatest([
		this.accAreaService.accelerator.acc$,
		this.accAreaService.applications.applications$,
		this.accAreaService.reading.assessments$,
		this.accAreaService.teams.teams$
	]).pipe(mergeMap(data => this.buildFeedbackData(data[0], data[1], data[2], data[3])));


	constructor(
		private accAreaService: AccAreaService,
		private ds: DataService,
	) {
		super();
	}

	ngOnInit(): void {
		super.initDestroyable();

		super.subscribe([this.accessAtStage$], async ([accessAtStage]) => {
			this.readonly = accessAtStage.access != 'Write';
		});
	}


	async buildFeedbackData(
		acc: Accelerator,
		allApplications: readonly AccApplicationContext[],
		readerAssessments: {
			reading: DbaAccReading,
			answers: readonly Answer[],
		}[],
		accTeams: readonly AccTeam[],
	): Promise<ReadersFeedback> {

		if (!acc || !allApplications || !readerAssessments.length || !accTeams) undefined;

		const accReaders = await this.ds.admin.accReader.getManyPackagesAsArray([...readerAssessments.map(ra => ra.reading.accReaderId)]);
		const people = await this.ds.admin.person.getManyPackagesAsMap([
			...accReaders.map(ar => ar.personId),
		]);

		const readingFeedBack = [];

		for (const a of allApplications) {
			const appReaderAssessments = readerAssessments.filter(ra => ra.reading.applicationId === a.application.applicationId);

			if (appReaderAssessments.length) {
				const accTeam = accTeams.find(accTeam => accTeam.applicationId === a.application.applicationId);

				readingFeedBack.push({
					applicationId: a.application.applicationId,
					teamId: accTeam?.accTeamId,
					firstNames: a.application.person.firstName,
					emails: a.application.person._email,
					company: a.application.company.longName,
					hasLogo: false,
					status: this.getApplicationStatus(a.application.applicationStatusId),
					feedback: appReaderAssessments.map(r => {
						const originalFeedback = r.answers.find(a => a.question.questionTypeId === QuestionTypeId.EntrepreneurFeedback).answer;
						const feedbackForEforAll = r.answers.find(a => a.question.questionTypeId === QuestionTypeId.Ent_Feedback_Eforall).answer;
						const accReader = accReaders.find(ar => ar.accReaderId === r.reading.accReaderId);

						return {
							reviewerId: r.reading.accReaderId,
							reviewer: people[accReader.personId].asSingleton,
							originalFeedback,
							feedbackForEforAll,
							polishedFeedback: r.reading.polishedFeedback,
							polishedFeedbackSkipped: r.reading.polishedFeedbackSkipped,
						}
					})
				})
			}
		}

		return { acc, feedback: readingFeedBack };
	}

	getApplicationStatus(status: number) {
		if (status < 4) return '<= Read Pending';
		if (status == 4) return 'Read Rejected';
		if (status > 4) return '>= Read Accepted';
	}

	polishedFeedbackSkippedChanged(data: { reviewerId: number, applicationId: number, teamId: number, polishedFeedbackSkipped: boolean }) {
		if (this.readonly) return;
		const { applicationId, polishedFeedbackSkipped, reviewerId } = data;
		this.accAreaService.reading.actions.setPolishedFeedbackSkipped({ accReaderId: reviewerId, applicationId, polishedFeedbackSkipped });
	}

	polishedFeedbackChanged(data: { reviewerId: number, applicationId: number, teamId: number, polishedFeedback: string }) {
		if (this.readonly) return;
		const { applicationId, polishedFeedback, reviewerId } = data;
		this.accAreaService.reading.actions.setPolishedFeedback({ accReaderId: reviewerId, applicationId, polishedFeedback });
	}

}