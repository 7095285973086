import { EventEmitter } from "@angular/core";
import { UtilityService } from "@me-services/core/utility";
import { LabelsService } from "@me-services/ui/labels";
import { getIconClass, Icon } from "@me-shared-parts/UI-common/icon";
import { CtrlAltShift } from "../controller/selectable-grid-controller";
import { filterTypes } from "../filtering";
import { EXPLORE_GRID_ACTION_KEY, GridAction, GridColumn, GridColumnType, GridSetup, NOTES_GRID_ACTION_KEY, OPEN_GRID_ACTION_KEY } from "../interfaces";


/**
 * GridBase defines the set of columns, set of actions and event handling.
 */
export abstract class GridExperience<ROW> {

	public readonly columns: GridColumn<ROW>[];
	public readonly actions: GridAction[];
	public iconClass: string;

	constructor(
		util: UtilityService,
		setup: GridSetup<ROW>,
		idColumns: GridColumn<ROW>[],
		primaryColumns: GridColumn<ROW>[],
		secondaryColumns: GridColumn<ROW>[],
		actions: GridAction[],
		protected gridAction: EventEmitter<{ actionKey: string; rows: ROW[]; }>,
		icon: Icon,
	) {
		this.columns = this.combineColumns(util, setup, idColumns, primaryColumns, secondaryColumns);
		this.columns = this.cleanupColumns(this.columns);

		this.actions = this.combineActions(setup, actions);
		this.iconClass = getIconClass(icon);

	}


	/**
	 * Takes the set of columns in the GridSetup and a set of base
	 * columns for a rowBase and combines them into one set of columns.
	 * @param baseColumnsBefore The "primary" columns that will be displayed before the columns in the setup.
	 * @param baseColumnsAfter The "secondary" columns that will be displayed after the columns in the setup.
	 */
	private combineColumns(
		util: UtilityService,
		setup: GridSetup<ROW>,
		baseColumnsIds: GridColumn<ROW>[],
		baseColumnsBefore: GridColumn<ROW>[],
		baseColumnsAfter: GridColumn<ROW>[],
	): GridColumn<ROW>[] {

		const fieldPrefix = setup.experience + '_';
		let columnsToAdd = setup.columnsToAdd ?? [];

		//
		// Default the column orders if not provided
		//
		baseColumnsIds.forEach((col, i) => { if (!col.order) col.order = 1 + i; });
		baseColumnsBefore.forEach((col, i) => { if (!col.order) col.order = 101 + i; });
		baseColumnsAfter.forEach((col, i) => { if (!col.order) col.order = 201 + i; });
		columnsToAdd.forEach((col) => { if (!col.order) col.order = this.isIdField(col.field) ? 100 : 200; });


		//
		// Replace columns with the same id
		//
		const columnsToAddMap = util.array.toMap(columnsToAdd, c => c.field);
		const baseColumns = [...baseColumnsIds, ...baseColumnsBefore, ...baseColumnsAfter]
			.map(col => {
				const replacement = columnsToAddMap[col.field];
				if (replacement) {
					col = { ...col, ...replacement };
					columnsToAdd = columnsToAdd.filter(c => c.field !== replacement.field);
				}

				return col;
			});


		//
		// Combine to a single array
		//
		let columns = [
			...baseColumns,
			...columnsToAdd,
		];


		//
		// Alter columns if requested
		//
		if (setup.columnsToAlter) {
			columns = columns.map(column => {

				const columnsToAlter = setup.columnsToAlter.filter(c => c.field == column.field);
				for (const alteration of columnsToAlter) {
					column = { ...column, ...alteration };
				}

				return column;
			});
		}

		return columns.sort((c1, c2) => c1.order - c2.order);
	}


	/**
	 * Simple function to detect if a field name looks something like personId or companyId.
	 * It's true if the first letter is lower case and the last two are "Id".
	 */
	private isIdField(field: string) {
		field = field ?? '';
		if (!field.length) return false;

		return field[0] == field[0].toLowerCase() && field.endsWith('Id');
	}


	/**
	 * Set default values for any optional column properties.
	 */
	private cleanupColumns(columns: GridColumn<ROW>[]): GridColumn<ROW>[] {

		return columns
			.map(col => {
				//
				// Set the filter type based on the column style
				//
				col.type = col.type || GridColumnType.text;
				return { ...col, filterType: filterTypes[col.type] };
			})
			.map(col => {
				//
				// Initialize any missing column properties
				//
				return {
					...col,
					hidden: !!col.hidden,
					noDownload: !!col.noDownload,
					fractionDigits: col.fractionDigits ?? 0,
					booleanDisplay: col.booleanDisplay ?? [{ key: 'No' }, { key: 'Yes' }],
				}
			});
	}


	/**
	 * Apply language translations on column text as needed.
	 */
	public async translateColumns(labelsService: LabelsService) {

		//
		// Translate text as needed
		//
		for (const col of this.columns) {
			col.header = await labelsService.get(col.header);
			if (col.headerTooltip) col.headerTooltip = await labelsService.get(col.headerTooltip);
			col.booleanDisplay[0] = await labelsService.get(col.booleanDisplay[0]);
			col.booleanDisplay[1] = await labelsService.get(col.booleanDisplay[1]);
		}

	}


	/**
	 * Takes the set of actions in the GridSetup and a set of base
	 * actions for a rowBase and combines them into one set of actions.
	 */
	private combineActions(
		setup: GridSetup<ROW>,
		actions: GridAction[],
	) {
		const setupActions = setup.actions ?? [];

		if (actions.length && setupActions.length) setupActions[0].separated = true;

		return [
			...actions,
			...setupActions,
		];
	}


	/**
	 * Subclasses that have base columns must implement this function
	 * to calculate the values for each row.
	 */
	public abstract applyBaseValues(rows: ROW[]): Promise<void>;


	/**
	 * Sort the array of row inline
	 */
	public abstract sortRows(rows: ROW[]);


	/**
	 * Perform base grid actions. Any not handled will be raised via gridAction.emit()
	 */
	public async doGridAction(actionKey: string, rows: ROW[]) {
		if (actionKey == EXPLORE_GRID_ACTION_KEY) await this.explore(rows[0]);
		else if (actionKey == OPEN_GRID_ACTION_KEY) await this.open(rows[0]);
		else if (actionKey == NOTES_GRID_ACTION_KEY) await this.editNotes(rows[0]);
		else this.gridAction.emit({ actionKey, rows });
	}

	/**
	 * When a row is doubleclicked, the grid component will call this.
	 */
	public async handleDoubleClick(keys: CtrlAltShift, row: ROW) {
		if (row) {
			if (keys.ctrl) await this.open(row);
			else await this.explore(row);
		}
	}


	/**
	 * Launch the explorer window for a given row
	 */
	public abstract explore(row: ROW): Promise<void>;


	/**
	 * Navigate to the page for a given row
	 */
	public abstract open(row: ROW): Promise<void>;


	/**
	 * Open a dialog to edit notes
	 */
	public abstract editNotes(row: ROW): Promise<void>;
}