<div class='stage-status-part'>
	<me-h3>User Tools</me-h3>
	<div *ngFor='let topItem of statuses'>
		<hr>
		<div *ngIf='topItem.status !== "DISABLED"'>
			<i [class.fa-lock-open-alt]='topItem.status === "ENABLED"'
				[class.fa-lock-alt]='topItem.status !== "ENABLED"' class='fas fa-fw '></i>
			{{topItem.text}}
			<link-button *ngIf="topItem.link" [link]="topItem.link"></link-button>
			<a *ngIf="topItem.routerLink" title='Go To {{topItem.text}}' target="_blank"
				[routerLink]='topItem.routerLink'><i class='far fa-external-link'></i></a>
		</div>
		<span *ngIf='topItem.status === "DISABLED"' class='graywash'>
			<i class='far fa-fw fa-lock'></i>{{topItem.text}}</span>
		<div class='subItem' *ngFor='let problem of topItem.problems' [routerLink]='problem.routerLink' id='problem'>
			<span>{{problem.text}}</span>
		</div>
		<div class='subItem' *ngFor='let subItem of topItem.subItems'>
			<i [class.fa-lock-open-alt]='subItem.status === "ENABLED"'
				[class.fa-lock-alt]='subItem.status !== "ENABLED"' class='fas fa-fw '></i>
			<span>{{subItem.text}}</span>
			<div [routerLink]='problem.routerLink' class='subItem' id='problem'
				*ngFor='let problem of subItem.problems'>
				<span>{{problem.text}}</span>
			</div>
		</div>
	</div>
</div>