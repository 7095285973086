import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LayoutService } from '@me-services/ui/layout';
import { MainSelector } from './selector';

@Component({
	selector: 'selectable-view',
	templateUrl: './SHR-CMN_selectable-view.part.html',
	styleUrls: ['SHR-CMN_selectable-view.part.scss'],
})
export class SelectableViewPart {

	@Input() selectorList: MainSelector[];
	@Input() listTitle: string;
	@Input() initialSelectorKey?: string;
	@Output() selectorKeyChanged = new EventEmitter<string>(); // selectorKeyChanged

	constructor(public layout: LayoutService) { }

	selectionChanged(key: string) {
		this.selectorKeyChanged.emit(key);
	}
}
