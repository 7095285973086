import { AccessError404Page } from "./errors/error-404-page/error-404.page";
import { AccessErrorGenericDataPart } from "./errors/error-data-generic-part/error-generic-data.part";
import { AccessErrorDataPart } from "./errors/error-data-part/data-error.part";
import { AccessErrorUnauthorizedPage } from "./errors/error-unauthorized-page/error-unauthorized.page";
import { AccessErrorUnverifiedEmailPart } from "./errors/error-unverified-email-part/error-unverified-email.part";
import { AccessLoginButtonsPart } from "./login/login-buttons-part/login-buttons.part";
import { AccessLoginLogoPart } from "./login/login-logo-part/login-logo.part";
import { AccessLoginPart } from "./login/login-part/login.part";
import { AccessLogoutPage } from "./login/logout.page";
import { SessionEnterCodePage } from "./session/session-enter-code-page/session-enter-code.page";
import { SessionEnterSearchDetailsPage } from "./session/session-enter-search-details-page/session-enter-search-details.page";
import { SessionAddMePart } from "./session/session-is-this-you-page/session-add-me-part/session-add-me.part";
import { SessionIsThisYouPage } from "./session/session-is-this-you-page/session-is-this-you.page";
import { SessionPersonPart } from "./session/session-is-this-you-page/session-person-part/session-person.part";


export const accessPages = {
	AccessError404Page,
	AccessErrorUnauthorizedPage,
	AccessLogoutPage,
	SessionEnterCodePage,
	SessionEnterSearchDetailsPage,
	SessionIsThisYouPage,
};


export const accessDeclarations = [
	AccessError404Page,
	AccessErrorDataPart,
	AccessErrorGenericDataPart,
	AccessErrorUnauthorizedPage,
	AccessErrorUnverifiedEmailPart,
	AccessLoginButtonsPart,
	AccessLoginLogoPart,
	AccessLoginPart,
	AccessLogoutPage,
	SessionEnterCodePage,
	SessionEnterSearchDetailsPage,
	SessionIsThisYouPage,
	SessionAddMePart,
	SessionPersonPart,
];