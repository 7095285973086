import { AccAreaService } from '@ACC-area';
import { Component, Input, OnChanges } from '@angular/core';
import { AccTeam } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { mergeMap } from 'rxjs/operators';


export interface AwardRow {
	eventType: string,
	awardName: string,
	value: number,
}


@Component({
	selector: 'acc-team-awards-part',
	templateUrl: './acc-team-awards.part.html',
})
export class AccTeamAwardsPart implements OnChanges {
	@Input() accTeamId: number;
	private accTeamId$ = new BehaviorSubject<number>(undefined);

	awardsData$ = combineLatest([
		this.accAreaService.teams.teams$,
		this.accTeamId$,
	]).pipe(mergeMap(data => {
		const [accTeams, accTeamId] = data;
		return this.buildData(accTeams, accTeamId);
	}));

	constructor(
		private accAreaService: AccAreaService,
		private ds: DataService,
	) {
	}

	ngOnChanges() {
		if (!this.accTeamId) throw new Error('Missing required attribute: accTeamId');
		if (this.accTeamId != this.accTeamId$.value) this.accTeamId$.next(this.accTeamId);
	}


	async buildData(
		allAccTeams: readonly AccTeam[],
		accTeamId: number,
	): Promise<AwardRow[]> {

		if (!allAccTeams || !accTeamId) return [];

		const accTeam = allAccTeams.find(team => team.accTeamId === accTeamId);
		const accTeamAwards = (await this.ds.admin.award.getByAccTeamIds([accTeam.accTeamId]))[accTeam.accTeamId];

		const events = await this.ds.admin.event.getManyPackagesAsMap([...accTeamAwards.map(a => a.eventId)]);

		const awards = accTeamAwards.map(award => {
			return {
				eventType: this.ds.domain.eventType.getOne(events[award.eventId].eventTypeId).name,
				awardName: this.ds.domain.awardName.getOne(award.awardNameId).name,
				value: award.value,
			}
		})

		return awards;
	}
}