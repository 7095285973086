import { getApplicationStatusName } from "@ACC-area";
import { DbConceptName, PitchContest } from "@me-interfaces";
import { getIconForApplicationStatus } from "@me-shared-parts/UI-common";
import { DataService } from "../../../../data.service";
import { ExplorerBox, ExplorerBoxClass } from "../../../explorer-box/explorer-box";


export async function getBoxesForApplications(ds: DataService, pitchContest: PitchContest): Promise<ExplorerBox[]> {

	const boxes: ExplorerBox[] = [];

	const applicationsByPicId = await ds.admin.application.getByPicIds([pitchContest.picId]);
	const applications = [...applicationsByPicId[pitchContest.picId]].sort((a, b) => a.person._name > b.person._name ? 1 : -1);

	for (const application of applications) {

		const applicationStatusIcon = getIconForApplicationStatus(application.applicationStatusId);
		const applicationStatus = getApplicationStatusName(application.applicationStatusId);
		const box: ExplorerBox = {
			explorerBoxClass: ExplorerBoxClass.Application,
			header: 'Application',
			buttons: [
				{ explorer: { explorableId: { conceptName: DbConceptName.Person, id: application.personId }, navTooltip: `Applicant: ${application.person._name}` } },
				{ explorer: { explorableId: { conceptName: DbConceptName.Company, id: application.companyId }, navTooltip: `Idea Name: ${application.companyName}` } },
				{ explorer: { nameOverride: applicationStatus, explorableId: { conceptName: DbConceptName.Application, id: application.applicationId }, navIconOverride: applicationStatusIcon, navTooltip: `Application: ${applicationStatus}` } },
			],
			notes: '',
		};

		boxes.push(box);
	}

	return boxes;
}