export type AccMatchingDay = 0 | 1 | 2 | 3 | 4;
export type AccMatchingHour = 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22;

export const ACC_MATCHING_FULL_DAY = '3456789ABC123456789A';
export const ACC_MATCHING_EMPTY_DAY = '--------------------';

export interface AccMatchingAvailability {
	meetingsId: number,
	timeZoneId: number,
	timeZoneName: string,
	meetings: number,
	mon: string,
	tue: string,
	wed: string,
	thu: string,
	fri: string,
}

export interface AccMatchingMeetings {

	accId: number,
	accTeamId: number | undefined,
	mentorPersonId: number | undefined,

	/** Accelerator times that the entrepreneur teams and mentors may choose from */
	blockout: AccMatchingAvailability,

	/**
	 * Times that the entrepreneur team or mentor actually chose.
	 * Will be undefined if not meetings have been selected yet.
	 */
	available: AccMatchingAvailability | undefined,

	/** The unix_timestamp() when this data was read from the db */
	asOfUTC: number,
}


/**
 * All matching meetings for an entire accelerator
 */
export interface AccMatchingMeetingsAll {

	accId: number,

	/** Accelerator times that the entrepreneur teams and mentors may choose from */
	blockout: AccMatchingAvailability,


	teamAvailability: {
		[accTeamId: number]: AccMatchingAvailability | undefined
	},

	mentorAvailability: {
		[personId: number]: AccMatchingAvailability | undefined
	},

}