import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UtilityService } from '@me-services/core/utility';
import { ErrorPageService } from '@me-services/ui/error-page';
import firebase from 'firebase/compat/app';


@Component({
	selector: 'access-logout-page',
	template: ''
})
export class AccessLogoutPage {

	constructor(
		private router: Router,
		private errorPage: ErrorPageService,
		private util: UtilityService,
	) {
		this.signOut();
	}

	async signOut() {
		try {
			const auth = firebase.auth();

			//
			// If there is a signed in user then sign out and then reload the app
			//
			if (auth.currentUser) {
				await auth.setPersistence(firebase.auth.Auth.Persistence.NONE);
				await auth.signOut();
				await auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
			}

			this.errorPage.clearError();
			this.router.navigate(['/']);

		}
		catch (e) {
			this.util.log.error(`Error signing out:`, e);
		}
	}
}