
import { AppAreaAccess, AppAreaIdentifier, AppAreaState } from '@me-interfaces';
import { PageSpinnerService } from '@me-services/ui/page-spinner';
import { Dexie } from 'dexie';

export const AREA_DATA_DB_KEY = 'AREA_DATA';

export class DexieAreaDataDb {

	private objects: Dexie.Table<any, string>;

	constructor(private pageSpinner: PageSpinnerService) {


		//
		// Build the database
		//
		const dexie = new Dexie(AREA_DATA_DB_KEY);
		const schema = { objects: '' };
		dexie.version(2).stores(schema);


		//
		// Construct the objects table
		//
		this.objects = dexie.table('objects');
	}


	async getAreaData<ACCESS extends AppAreaAccess, RAW_DATA>(identifier: AppAreaIdentifier<string | number>): Promise<AppAreaState<ACCESS, RAW_DATA>> {
		const cacheKey = this.buildAppAreaCacheKey(identifier);
		
		// const desc = `Area.objects.get('${cacheKey}')`;
		// this.pageSpinner.addSpinner(desc);
		const data = await this.objects.get(cacheKey);
		// this.pageSpinner.removeSpinner(desc);

		return data;
	}


	async putAreaData<ACCESS extends AppAreaAccess, RAW_DATA>(identifier: AppAreaIdentifier<string | number>, data: AppAreaState<ACCESS, RAW_DATA>) {
		const cacheKey = this.buildAppAreaCacheKey(identifier);
		
		// const desc = `Area.objects.put('${cacheKey}')`;
		// this.pageSpinner.addSpinner(desc);
		await this.objects.put(data, cacheKey);
		// this.pageSpinner.removeSpinner(desc);
	}


	private buildAppAreaCacheKey(identifier: AppAreaIdentifier<string | number>) {
		return identifier.areaCacheKey + '/' + ('' + identifier.id);
	}
}