import { RegionAreaService, RegionPageService } from '@REGION-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { GridColumnType, GridSetup, GridValueStyle } from '@me-grid';
import { Award } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';


interface AwardRow {
	awardId: number,
	accId: number,
	picId: number,
	siteCode: string,
	siteId: number,
	programName: string,
	type: 'Accelerator' | 'Pitch Contest',
}


@Component({
	selector: 'region-awards-view-part',
	templateUrl: './awards-view.part.html',
})
export class RegionAwardsViewPart extends DestroyablePart implements OnInit {

	public gridSetup = this.setupGrid();
	public rows$: Observable<AwardRow[]>;

	constructor(
		public ds: DataService,
		public regionAreaService: RegionAreaService,
		private pageService: RegionPageService,
	) {
		super();
	}


	async ngOnInit() {
		super.initDestroyable();

		this.rows$ = this.ds.admin.singletonsAsOfUTC$.pipe(mergeMap(data => this.buildRows(data)));
	}

	async buildRows(singletonsAsOfUTC: number) {

		const identifier = this.regionAreaService.getId();
		const regionId = identifier.id;

		const sites = (await this.ds.admin.site.getByRegionIds([regionId]))[regionId];

		const siteIds = sites.map(site => site.siteId);
		const awards = (await this.ds.admin.award.getAllPackagesAsArray()).filter(award => (award.accTeam && siteIds.includes(award.accTeam.accelerator.siteProgram.siteId)) || (award.picTeam && siteIds.includes(award.picTeam.pitchContest.siteProgram.siteId)));

		const rows: AwardRow[] = awards.map(award => {
			const row: AwardRow = {
				awardId: award.awardId,
				accId: award.accTeamId ? award.accTeam.accId : undefined,
				picId: award.picTeamId ? award.picTeam.picId : undefined,
				siteCode: award.accTeamId ? award.accTeam.accelerator.siteProgram.site.code : award.picTeamId ? award.picTeam.pitchContest.siteProgram.site.code : undefined,
				siteId: award.accTeamId ? award.accTeam.accelerator.siteProgram.siteId : award.picTeamId ? award.picTeam.pitchContest.siteProgram.siteId : undefined,
				programName: award.accTeamId ? award.accTeam.accelerator.name : award.picTeamId ? award.picTeam.pitchContest.name : '',
				type: award.accTeamId ? 'Accelerator' : 'Pitch Contest',
			};
			return row;
		});

		return rows;
	}

	private valueStyle(row: AwardRow, field: string): GridValueStyle {
		const value = row[field];

		if (field == 'type') { // Program
			if (value == 'Accelerator') return { icon: 'far fa-tachometer-alt-fast', textStyle: 'Hidden' };
			if (value == 'Pitch Contest') return { icon: 'far fa-bullhorn', textStyle: 'Hidden' };
		}

		return { icon: 'fal fa-question', iconColor: 'Red', textStyle: 'Hidden' };
	}


	private setupGrid(): GridSetup<AwardRow> {
		const valueStyle = this.valueStyle.bind(this);
		return {
			experience: 'AWARD',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				shrinkBy: 0,
				heightMultiplier: 1,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Award",
			rowPluralName: "Awards",
			rowKey: "awardId",
			stateKey: "region-awards",
			canAdd: false,
			canRefresh: false,
			canDownload: true,
			columnsToAdd: [
				{ field: "accId", header: "accId", width: 60, type: GridColumnType.number, hidden: true },
				{ field: "picId", header: "picId", width: 60, type: GridColumnType.number, hidden: true },
				{ field: "siteId", header: "siteId", width: 60, type: GridColumnType.number, hidden: true },
				{ field: "siteCode", header: "Site", width: 120, type: GridColumnType.text },
				{ field: "programName", header: "Program Name", width: 150, type: GridColumnType.text },
				{ field: "type", header: "Program", width: 100, type: GridColumnType.text, valueStyle },
			],
			initialState: { sort: [{ field: 'AWARD_eventStart', dir: 'asc' }] },

		};
	}


	/**
	 * Handle events from the grid
	 */
	async gridActionHandler(action: { actionKey: string, rows: Award[] }) {

		const row = action.rows[0];
	}

}