/**
 * A type that represents any of the possible JS primitive values
 */
export type Primitive =
	| bigint
	| boolean
	| null
	| number
	| string
	| symbol
	| undefined
	// eslint-disable-next-line @typescript-eslint/ban-types -- Function Primitive is not typesafe, so ignore the eslint 
	| Function;

/**
 * Similar to Readonly<T> but recursive
 */
export type Immutable<T> =
	T extends Primitive ? T :
	T extends Array<infer U> ? ImmutableArray<U> :
	T extends Map<infer K, infer V> ? ImmutableMap<K, V> :
	T extends Set<infer M> ? ImmutableSet<M> : ImmutableObject<T>;

export type ImmutableArray<T> = ReadonlyArray<Immutable<T>>;
export type ImmutableMap<K, V> = ReadonlyMap<Immutable<K>, Immutable<V>>;
export type ImmutableSet<T> = ReadonlySet<Immutable<T>>;
export type ImmutableObject<T> = { readonly [K in keyof T]: Immutable<T[K]> };