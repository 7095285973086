<div class='region-overview-view-part' *ngIf="viewDroplistItems.length">

	<ng-container *ngIf='selectedViewItem$ | async; let selectedViewItem'>

		<me-droplist class="views-droplist" [items]='viewDroplistItems' [selectedItem]='selectedViewItem'
			(selectionChange)='onViewDroplistChange($event)' [filterable]='false' [navigation]='true'
			sortBy='customSort' style='display:inline-block'>
		</me-droplist>


		<shared-dashboard-views-part [layout$]="pageService.layout$"></shared-dashboard-views-part>



		<region-businesses-view-part *ngIf="showView('Businesses', selectedViewItem)" [identifier]="identifier">
		</region-businesses-view-part>


		<region-sites-view-part *ngIf="showView('Sites', selectedViewItem)">
		</region-sites-view-part>

		<region-events-view-part *ngIf="showView('Events', selectedViewItem)">
		</region-events-view-part>

		<region-entrepreneurs-view-part *ngIf="showView('Entrepreneurs', selectedViewItem)">
		</region-entrepreneurs-view-part>

		<region-accelerators-view-part *ngIf="showView('Accelerators', selectedViewItem)">
		</region-accelerators-view-part>

		<region-pitch-contests-view-part *ngIf="showView('PitchContests', selectedViewItem)">
		</region-pitch-contests-view-part>

		<region-applications-view-part *ngIf="showView('Applications', selectedViewItem)">
		</region-applications-view-part>

		<region-awards-view-part *ngIf="showView('Awards', selectedViewItem)">
		</region-awards-view-part>

	</ng-container>

</div>