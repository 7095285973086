import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatSelectionListChange } from '@angular/material/list';
import { FeedbackData, ReviewerFeedbackData } from '@me-interfaces';
import { DownloadColumn } from '@me-interfaces/ui';
import { MenuService } from '@me-services/ui/menu';

interface ViewModel {
	reviewType: 'Readers' | 'Interviewers' | 'Judges',
	filteredModel: FeedbackData[],
	companySelected: FeedbackData,
	countOfApplicationsUnpolished: number,
	reviewers: ReviewerFeedbackData[],
	reviewerFeedback: ReviewerFeedbackData,
	polishedFeedback: string,
}

@Component({
	selector: 'feedback-manager',
	templateUrl: './SHR-CMN_feedback-manager.part.html',
	styleUrls: ['SHR-CMN_feedback-manager.part.scss'],
})
export class FeedbackManagerPart implements OnChanges {

	@Input() model: FeedbackData[];
	@Input() reviewType: 'Readers' | 'Interviewers' | 'Judges';
	@Input() type: 'acc' | 'pic';
	@Input() name: string; // acc or pic name.
	@Input() readonly = false;

	@Output() polishedFeedbackSkippedChanged = new EventEmitter<{ reviewerId: number, applicationId: number, teamId: number, polishedFeedbackSkipped: boolean }>();
	@Output() polishedFeedbackChanged = new EventEmitter<{ reviewerId: number, applicationId: number, teamId: number, polishedFeedback: string }>();

	vm: ViewModel = {
		reviewType: undefined,
		filteredModel: [],
		companySelected: undefined,
		reviewers: [],
		countOfApplicationsUnpolished: 0,
		reviewerFeedback: undefined,
		polishedFeedback: undefined,
	};

	saving = false;

	public downloadFileName = 'not-loaded-yet';
	public downloadColumns: DownloadColumn[] = [];


	constructor(
		private menu: MenuService,
	) {
	}

	ngOnChanges() {
		this.updateView(this.model);
	}


	updateView(model: FeedbackData[]) {
		this.vm.reviewType = this.reviewType;
		this.vm.filteredModel = model.filter(feedback => {
			return feedback;
		}).sort((a, b) => a.company > b.company ? 1 : -1);
		this.setCounts();
		this.configureDownload();
	}

	resetValues() {
		this.vm.filteredModel = [];
		this.vm.reviewers = [];
		this.vm.countOfApplicationsUnpolished = 0;
		this.vm.reviewerFeedback = undefined;
		this.vm.polishedFeedback = undefined;
		this.vm.companySelected = undefined;
	}


	selectionChangeSetReviewers(e: MatSelectionListChange) {
		e.source.deselectAll();
		e.source.selectedOptions.select(e.options[0]);
		this.vm.companySelected = e.options[0].value;
		this.vm.reviewers = this.vm.companySelected.feedback;

		this.vm.reviewers.sort((a, b) => a.reviewer._name > b.reviewer._name ? 1 : -1);
		this.vm.reviewerFeedback = undefined;
	}

	setCounts() {
		this.vm.countOfApplicationsUnpolished = 0;
		this.vm.filteredModel = this.vm.filteredModel.map(feedback => {
			feedback['countOfUnpolishedReviewerFeedback'] = 0;

			feedback['status'] = feedback.status;

			for (const reviewerFeedback of feedback.feedback) {
				if (!reviewerFeedback.polishedFeedbackSkipped && !reviewerFeedback.polishedFeedback) {
					feedback['countOfUnpolishedReviewerFeedback']++;
				}
			}

			this.vm.countOfApplicationsUnpolished = feedback['countOfUnpolishedReviewerFeedback'] > 0 ? this.vm.countOfApplicationsUnpolished + 1 : this.vm.countOfApplicationsUnpolished;
			feedback['link'] = 'https://eforall.app/access/my/feedback';
			return feedback;
		});
	}

	selectionChangeSetReviewerFeedback(e: MatSelectionListChange) {
		e.source.deselectAll();
		e.source.selectedOptions.select(e.options[0]);
		this.vm.reviewerFeedback = e.options[0].value;
		if (this.vm.reviewerFeedback.feedbackForEforAll) {
			this.vm.reviewerFeedback['originalAndEforallFeedback'] = `${this.vm.reviewerFeedback.originalFeedback} \n\n\n---- Below feedback is for EforAll eyes only ----\n\n\n ${this.vm.reviewerFeedback.feedbackForEforAll}`;
		}
		else {
			this.vm.reviewerFeedback['originalAndEforallFeedback'] = this.vm.reviewerFeedback.originalFeedback;
		}
		this.vm.polishedFeedback = this.vm.reviewerFeedback.polishedFeedback;
	}

	setPolishedFeedback(e) {
		if (this.vm.reviewerFeedback) this.vm.polishedFeedback = (e.target.value || '').trim();
	}

	async setPolishedFeedbackSkipped(e) {
		this.vm.reviewerFeedback.polishedFeedbackSkipped = e.checked;
		this.polishedFeedbackSkippedChanged.emit(
			{
				reviewerId: this.vm.reviewerFeedback.reviewerId,
				applicationId: this.vm.companySelected.applicationId,
				teamId: this.vm.companySelected.teamId,
				polishedFeedbackSkipped: this.vm.reviewerFeedback.polishedFeedbackSkipped
			}
		)
		this.setCounts();
	}

	async save() {
		this.saving = true;
		this.polishedFeedbackChanged.emit(
			{
				reviewerId: this.vm.reviewerFeedback.reviewerId,
				applicationId: this.vm.companySelected.applicationId,
				teamId: this.vm.companySelected.teamId,
				polishedFeedback: this.vm.polishedFeedback
			}
		)
		this.vm.reviewerFeedback.polishedFeedback = this.vm.polishedFeedback;
		this.setCounts();
		this.saving = false;
	}


	revert() {
		this.vm.polishedFeedback = this.vm.reviewerFeedback.polishedFeedback;
	}

	configureDownload() {
		this.downloadFileName = `${this.type} feedback for ${this.name}`.toLowerCase();
		this.downloadColumns = [
			{ header: 'first name', property: 'firstNames', type: "string" },
			{ header: 'email', property: 'emails', type: "string" },
			{ header: 'link', property: 'link', type: "string" },
			{ header: 'company', property: 'company', type: "string" },
			{ header: 'status', property: 'status', type: "string" },
		];
	}
}
