import { ApplicationParticipantStatus, ApplicationParticipantPendingEditableFields } from "@me-interfaces";

export interface ApplicationFieldsParticipant extends ApplicationParticipantPendingEditableFields {

	status: ApplicationParticipantStatus,

	/** Set if status = ApplicationParticipantStatus.Invited */
	participantPendingId?: number,

	/** Set if status = ApplicationParticipantStatus.Incomplete OR status = ApplicationParticipantStatus.Complete */
	participantId?: number,

	/** True if application.personId == applicationParticipant.personId */
	isApplicant: boolean,

}