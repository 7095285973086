<div class="company-extract-banner-part" *ngIf="company">
	<div>
		<me-logo [logo]='company'></me-logo>
	</div>
	<div class='company-details'>

		<div>
			<span style='margin-right:1em'>{{company.longName}}<small *ngIf='!!company.shortName'>  ({{company.shortName}})</small></span>
		</div>

		<address-link [address]='address' [translate]='false'></address-link>

	</div>
</div>