import { AccAreaRawData, Accelerator, AccTeam, AccTeamGoalsAndAccess } from "@me-interfaces";

//
// transform the raw data returned from the action function to
// data that can be used by the Angular app.
//
export function mapTeamGoals(rawData: AccAreaRawData, acc: Accelerator, accTeams: ReadonlyArray<AccTeam>): AccTeamGoalsAndAccess[] {

	const allTeamGoals: AccTeamGoalsAndAccess[] = rawData.team.goals
		.map(tg => ({ tg, team: accTeams.find(team => team.accTeamId === tg.accTeamId) }))
		.filter(({ team }) => !!team)
		.map(({ tg, team }) => {

			return {
				acc: {
					accId: acc.accId,
					name: acc.name,
					siteName: acc.siteProgram.site.name,
				},
				team: {
					name: team.application.company._name,
					industryId: team.application.company.industryId,
					accTeamId: team.accTeamId,
				},
				goals: tg.goals,
				comments: tg.comments,
				access: tg.access,
				quarterlyGoalFlags: rawData.quarterlies.goalFlags,
			}
		});

	return allTeamGoals
}