<me-dialog-frame [header]='headerText' [showToolbar]='true' [dialogRef]='dialogRef' [icon]='icon' alertType='editor'>

	<ng-container toolbar>
		<explorer-header></explorer-header>
	</ng-container>

	<ng-container *ngIf="ds.explorer.navigator.recents$ | async; let recents">

		<ng-container *ngIf="selected$ | async; let selected">
			<acc-team-explorer *ngIf="selected._concept === 'accTeam'" [accTeam]="selected"></acc-team-explorer>
			<accelerator-explorer *ngIf="selected._concept === 'accelerator'" [accelerator]="selected"></accelerator-explorer>
			<application-explorer *ngIf="selected._concept === 'application'" [application]="selected"></application-explorer>
			<company-explorer *ngIf="selected._concept === 'company'" [company]="selected"></company-explorer>
			<event-explorer *ngIf="selected._concept === 'event'" [event]="selected"></event-explorer>
			<person-explorer *ngIf="selected._concept === 'person'" [person]="selected"></person-explorer>
			<pic-team-explorer *ngIf="selected._concept === 'picTeam'" [picTeam]="selected"></pic-team-explorer>
			<pitch-contest-explorer *ngIf="selected._concept === 'pitchContest'" [pitchContest]="selected"></pitch-contest-explorer>
			<region-explorer *ngIf="selected._concept === 'region'" [region]="selected"></region-explorer>
			<site-explorer *ngIf="selected._concept === 'site'" [site]="selected"></site-explorer>
			<venue-explorer *ngIf="selected._concept === 'venue'" [venue]="selected"></venue-explorer>
		</ng-container>
	</ng-container>

</me-dialog-frame>