import { Old_MinimalContact, Old_Program, Old_Site } from '@me-interfaces';

/**
 * @deprecated Use the SiteProgram from the DataService instead
 */
export interface Old_SiteProgram {
	siteProgramId: number,
	siteId: number,
	site: Old_Site,
	programId: number,
	program: Old_Program,
	isActive: boolean,
	manager: Old_MinimalContact,
	altManager?: Old_MinimalContact,
	createdUTC: number,
}