import { LanguageId } from "@me-interfaces";

/**
 * Check the domain name used and return the associated language.
 */
export function determineLanguageFromUrl() {

	const hostname: string = document?.location?.hostname?.toLowerCase();

	const isSpanish = ([
		'yo.localhost',
		'yo.eparatodos.org',
		'yo-dev.eparatodos.org',
		'eparatodos.app',
		'dev.eparatodos.app',
		'eparatodos.localhost',
	].includes(hostname));

	const languageId = isSpanish ? LanguageId.Spanish : LanguageId.English;

	return { languageId, isSpanish };
}