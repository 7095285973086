
import { DdCompanyTypeRow } from './dd-company-type-row';
import { DdObject } from '../dd-object';
import { OldDomainDataService } from '../domain-data.service';

/**
 * @deprecated Use the DataService instead
 */
export class DdCompanyType extends DdObject<DdCompanyTypeRow> implements DdCompanyTypeRow {

	constructor(dd: OldDomainDataService, data: DdCompanyTypeRow) {
		super(data);
	}

	public get companyTypeId(): number {
		return this._data.companyTypeId;
	}

	public get name(): string {
		return this._data.name;
	}

	public get isNonprofit(): boolean {
		return this._data.isNonprofit;
	}


	public get isFormed(): boolean {
		return this._data.isFormed;
	}

	
	public get isEforAllOnly(): boolean {
		return this._data.isEforAllOnly;
	}
}