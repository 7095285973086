import { Component, Input } from '@angular/core';
import { Old_Tag } from '@me-interfaces';

interface TagsGroupedByRole {
	role: string,
	tags: {
		tag: Old_Tag,
		count: number
	}[]
}
@Component({
	selector: 'site-tags-overview',
	templateUrl: './SHR-CMN_site-tags-overview.part.html',
	styleUrls: ['SHR-CMN_site-tags-overview.part.scss'],
})
export class SiteTagsOverviewPart {

	@Input() siteTagsWithCount: [];
	@Input() expanded = true;
	@Input() showCount = true;

	tagsGroupedByRole: TagsGroupedByRole[] = [];

}
