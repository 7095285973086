import { RegionAreaService } from '@REGION-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { DbConceptName, Region } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { Icon, getIconContext } from '@me-shared-parts/UI-common';


@Component({
	selector: 'region-toolbar',
	templateUrl: `./region-toolbar.part.html`,
	styleUrls: [`./region-toolbar.part.scss`],
})
export class RegionToolbarPart extends DestroyablePart implements OnInit {

	region: Region;

	icons = {
		region: getIconContext(Icon.concept_region, undefined, 'lt-md', 'Region'),
		dir: getIconContext(Icon.concept_person, undefined, 'lt-sm', 'Director'),
	}

	constructor(private ds: DataService, public regionAreaService: RegionAreaService) {
		super();
	}

	ngOnInit() {

		super.initDestroyable();

		super.subscribe(
			[this.regionAreaService.data$],
			async ([data]) => {
				if (data?.region) {
					this.region = data.region;
					this.icons.region.text = `${data.region.name}`;
					this.icons.dir.text = `RD: ${data.region.directorName}`;
				}
			},
		);

	}


	async inspectPerson(personId: number) {
		if (!personId) return;
		await this.ds.explorer.explore(DbConceptName.Person, personId);
	}

	async inspectRegion(regionId: number) {
		if (!regionId) return;
		await this.ds.explorer.explore(DbConceptName.Region, regionId);
	}

}