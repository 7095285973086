/**
 * This interface is used to get company details associated with the user for application purpose
 */
export interface CompanyForApplicationUser {
	companyId: number,
	companyName: string,
	companyTypeId: number,
	industryId: number
	title: string,

	/**
	 *  Name of the applicant if the companyId already has an application for the programInstance
	 */
	priorApplicant?: string,
}
