import { LanguageId, } from '@me-interfaces';


/**
 * The content sent to the backend for a function call 
 */
export interface FuncCallBody<T> {

	/**
	 * The call specific data to be sent to the function action.
	 */
	payload: T,

	/**
	 * The generated action name to be called. 
	 * See fan-actions.gen.ts for the full list.
	 */
	action: string,

	/**
	 * A reference to the record in the appSession table.
	 */
	appSessionId: number | number,

	/**
	 * A unique identifier for a device.
	 * It is stored in the localStorage.
	 */
	appInstanceUuid: string,

	/**
	 * A unique identifier for an instance of the app.
	 * It is stored in the sessionStorage.
	 */
	appDeviceUuid: string,

	/**
	 * A unique identifier for this call. If multiple attempts are made
	 * then the same UUID will be passed so the backend can de-duplicate.
	 */
	callUuid: string,

	/**
	 * The func service checks for onError events and when it happens it
	 * retries each call a certain number of times.
	 */
	callAttempt: number,

	/**
	 * The language the app is being presented in
	 */
	languageId: LanguageId,
}