<div class="me-alert" role="alert" [class.large-alert]="large" [hidden]="closed" [ngClass]="{
      'alert-info': alertType === 'info',
      'alert-success': alertType === 'success',
      'alert-warning': alertType === 'warning',
      'alert-danger': alertType === 'error',
      'alert-closeable': closeable
    }">
	<div class="alert-content">
		{{ line }}
		<ng-content></ng-content>
	</div>
	<button class="alert-close" type="button" [attr.aria-label]="'alert_close'" [hidden]="!closeable" (click)="close()">
		<span aria-hidden="true">
			X
		</span>
	</button>
</div>