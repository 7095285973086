import { DbConceptName, DbsPerson, PhoneTypeId, PicTeam } from "@me-interfaces";
import { DataService } from "../../../../data.service";
import { ExplorerBox, ExplorerBoxClass } from "../../../explorer-box/explorer-box";


export async function getBoxesForPitchContest(ds: DataService, picTeam: PicTeam): Promise<ExplorerBox[]> {

	const boxes: ExplorerBox[] = [];

	boxes.push({
		explorerBoxClass: ExplorerBoxClass.PitchContest,
		header: `Pitch Contest`,
		buttons: [
			{ explorer: { explorableId: { conceptName: DbConceptName.Site, id: picTeam.pitchContest.siteProgram.siteId }, navTooltip: `Site: ${picTeam.pitchContest.siteProgram.site.name}` } },
			{ explorer: { explorableId: { conceptName: DbConceptName.PitchContest, id: picTeam.picId }, navTooltip: `Pitch Contest: ${picTeam.pitchContest.name}` } },
		],
		notes: `Stage: ${picTeam.pitchContest.stage.name}`,
	});

	const people = await ds.admin.person.getManyAsMap([picTeam.pitchContest.directorId, picTeam.pitchContest.managerId]);

	const edBox: ExplorerBox = getPersonBox(people[picTeam.pitchContest.directorId], 'Executive Director');
	const pmBox: ExplorerBox = getPersonBox(people[picTeam.pitchContest.managerId], 'Program Manager');

	boxes.push(edBox, pmBox);

	return boxes;
}

function getPersonBox(person: DbsPerson, header: string) {
	const personBox: ExplorerBox = {
		explorerBoxClass: ExplorerBoxClass.Person,
		header,
		buttons: [
			{ explorer: { explorableId: { conceptName: DbConceptName.Person, id: person.personId }, navTooltip: `${header}: ${person._name}` } },
			{ link: { linkType: 'email', email: person._email } },
		],
		notes: '',
	};

	if (person.phone) {
		personBox.buttons.push({ link: { linkType: 'call', phone: person.phone } });

		if (person.phoneTypeId == PhoneTypeId.Mobile) {
			personBox.buttons.push({ link: { linkType: 'sms', phone: person.phone } });
		}
	}
	return personBox;
}
