import { Injectable } from '@angular/core';
import { environment } from '@me-environment';
import { DbcZip, SimplePerson } from '@me-interfaces';
import { FuncService } from '@me-services/core/func';
import { UtilityService } from '../utility';
import { DdAccMeetingTimess } from './dd-acc-meeting-times/dd-acc-meeting-timess';
import { DdAccLanguageSeasons } from './dd-acc-season/dd-acc-seasons';
import { DdAccStages } from './dd-acc-stage/dd-acc-stages';
import { DdAccs } from './dd-acc/dd-accs';
import { DdApplicationStatuses } from './dd-application-status/dd-application-statuses';
import { DdAwardKinds } from './dd-award-kind/dd-award-kinds';
import { DdAwardNames } from './dd-award-name/dd-award-names';
import { DdAwardNameDecidingRoles } from './dd-award-name_deciding-role/dd-award-name_deciding-roles';
import { DdAwardNameEventTypes } from './dd-award-name_event-type/dd-award-name_event-types';
import { DdCities } from './dd-city/dd-cities';
import { DdCompanyTypes } from './dd-company-type/dd-company-types';
import { DdDecidingRoles } from './dd-deciding-role/dd-deciding-roles';
import { DdEventTypes } from './dd-event-type/dd-event-types';
import { DdIndustries } from './dd-industry/dd-industries';
import { DdLanguages } from './dd-language/dd-languages';
import { DdNoteCategories } from './dd-note-category/dd-note-categories';
import { DdPhoneTypes } from './dd-phone-type/dd-phone-types';
import { DdPicStages } from './dd-pic-stage/dd-pic-stages';
import { DdPics } from './dd-pic/dd-pics';
import { DdPrefixes } from './dd-prefix/dd-prefixes';
import { DdProgramTypes } from './dd-program-type/dd-program-types';
import { DdPrograms } from './dd-program/dd-programs';
import { DdQuestionTypes } from './dd-question-type/dd-question-types';
import { DdQuestionsTypes } from './dd-questions-type/dd-questions-types';
import { DdSites } from './dd-site/dd-sites';
import { DdSitePrograms } from './dd-site_program/dd-site-programs';
import { DdSuffixes } from './dd-suffix/dd-suffixes';
import { DdTagPrefixes } from './dd-tag-prefix/dd-tag-prefixes';
import { DdTags } from './dd-tag/dd-tags';
import { DdVenues } from './dd-venue/dd-venues';
import { DdWebLinkTypes } from './dd-web-link-type/dd-web-link-types';

const DD_STORAGE_KEY = 'me::domainData';

/**
 * @deprecated Use the DataService instead
 */
@Injectable({ providedIn: 'root' })
export class OldDomainDataService {

	//
	// All of the following domain data caches do not follow the pattern
	// of allowed things in domain data.  They should be loaded in an app
	// area service; usually SitesAreaService so lists across sites can
	// be displayed, and single sites can filter as needed.
	//

	/** @deprecated */
	public readonly accs = new DdAccs(this);
	/** @deprecated */
	public readonly pics = new DdPics(this);
	/** @deprecated */
	public readonly sites = new DdSites(this);
	/** @deprecated */
	public readonly sitePrograms = new DdSitePrograms(this);
	/** @deprecated */
	public readonly venues = new DdVenues(this);

	/** @deprecated */
	private readonly _people: { [index: number]: SimplePerson } = {};
	/** @deprecated */
	private _directorOfPrograms: SimplePerson;


	/** @deprecated */
	private readonly _zips: { [index: number]: DbcZip } = {};

	//
	// Domain data should ONLY be values that tech team manually inserts into
	// the database which is then cached into cache files after a release. If
	// the table is something that we might insert by user-action then it is
	// not something that should be in domain data.  Domain data should never
	// be recreated by action, except the Tech Admin tool to regenerate it.
	//
	/** @deprecated Use dbd */
	public readonly accMeetingTimess = new DdAccMeetingTimess(this);
	/** @deprecated Use dbd */
	public readonly accLanguageSeasons = new DdAccLanguageSeasons(this);
	/** @deprecated Use dbd */
	public readonly accStages = new DdAccStages(this);
	/** @deprecated Use dbd */
	public readonly applicationStatuses = new DdApplicationStatuses(this);
	/** @deprecated Use dbd */
	public readonly awardNames = new DdAwardNames(this);
	/** @deprecated Use dbd */
	public readonly awardKinds = new DdAwardKinds(this);
	/** @deprecated Use dbd */
	public readonly awardNameDecidingRoles = new DdAwardNameDecidingRoles(this);
	/** @deprecated Use dbd */
	public readonly awardNameEventTypes = new DdAwardNameEventTypes(this);
	/** @deprecated Use dbd */
	public readonly cities = new DdCities(this);
	/** @deprecated Use dbd */
	public readonly companyTypes = new DdCompanyTypes(this);
	/** @deprecated Use dbd */
	public readonly decidingRoles = new DdDecidingRoles(this);
	/** @deprecated Use dbd */
	public readonly eventTypes = new DdEventTypes(this);
	/** @deprecated Use dbd */
	public readonly industries = new DdIndustries(this);
	/** @deprecated Use dbd */
	public readonly languages = new DdLanguages(this);
	/** @deprecated Use dbd */
	public readonly noteCategories = new DdNoteCategories(this);
	/** @deprecated Use dbd */
	public readonly phoneTypes = new DdPhoneTypes(this);
	/** @deprecated Use dbd */
	public readonly picStages = new DdPicStages(this);
	/** @deprecated Use dbd */
	public readonly prefixes = new DdPrefixes(this);
	/** @deprecated Use singletons */
	public readonly programs = new DdPrograms(this);
	/** @deprecated Use dbd */
	public readonly programTypes = new DdProgramTypes(this);
	/** @deprecated Use dbd */
	public readonly questionsTypes = new DdQuestionsTypes(this);
	/** @deprecated Use dbd */
	public readonly questionTypes = new DdQuestionTypes(this);
	/** @deprecated Use dbd */
	public readonly suffixes = new DdSuffixes(this);
	/** @deprecated Use singletons */
	public readonly tagPrefixes = new DdTagPrefixes(this);
	/** @deprecated Use singletons */
	public readonly tags = new DdTags(this);
	/** @deprecated Use dbd */
	public readonly webLinkTypes = new DdWebLinkTypes(this);
	// public readonly zips = new DdZips(this);

	//This promise will resolve after all the domain data files have been loaded and the objects populated
	public readonly loaded: Promise<true>;

	constructor(
		private func: FuncService,
		private util: UtilityService,
	) {
		this.loaded = this.fetchLatestData();
	}


	/**
	 * Read the manifest.json file from the dd storage bucket
	 */
	public async fetchLatestData(): Promise<true> {
		const manifestUrl = `https://storage.googleapis.com/${environment.firebaseConfig.projectId}_dd/manifest.json`;
		const response = await fetch(manifestUrl);
		const manifest = await response.json();


		const fetches = [
			this.fetchDataFile(manifest, 'accelerator.json', this.accs.loadData.bind(this.accs)),
			this.fetchDataFile(manifest, 'accMeetingTimes.json', this.accMeetingTimess.loadData.bind(this.accMeetingTimess)),
			this.fetchDataFile(manifest, 'accLanguageSeason.json', this.accLanguageSeasons.loadData.bind(this.accLanguageSeasons)),
			this.fetchDataFile(manifest, 'accStage.json', this.accStages.loadData.bind(this.accStages)),
			this.fetchDataFile(manifest, 'applicationStatus.json', this.applicationStatuses.loadData.bind(this.applicationStatuses)),
			this.fetchDataFile(manifest, 'awardName.json', this.awardNames.loadData.bind(this.awardNames)),
			this.fetchDataFile(manifest, 'awardKind.json', this.awardKinds.loadData.bind(this.awardKinds)),
			this.fetchDataFile(manifest, 'awardName_decidingRole.json', this.awardNameDecidingRoles.loadData.bind(this.awardNameDecidingRoles)),
			this.fetchDataFile(manifest, 'awardName_eventType.json', this.awardNameEventTypes.loadData.bind(this.awardNameEventTypes)),
			this.fetchDataFile(manifest, 'city.json', this.cities.loadData.bind(this.cities)),
			this.fetchDataFile(manifest, 'companyType.json', this.companyTypes.loadData.bind(this.companyTypes)),
			this.fetchDataFile(manifest, 'decidingRole.json', this.decidingRoles.loadData.bind(this.decidingRoles)),
			this.fetchDataFile(manifest, 'eventType.json', this.eventTypes.loadData.bind(this.eventTypes)),
			this.fetchDataFile(manifest, 'industry.json', this.industries.loadData.bind(this.industries)),
			this.fetchDataFile(manifest, 'language.json', this.languages.loadData.bind(this.languages)),
			this.fetchDataFile(manifest, 'noteCategory.json', this.noteCategories.loadData.bind(this.noteCategories)),
			this.fetchDataFile(manifest, 'phoneType.json', this.phoneTypes.loadData.bind(this.phoneTypes)),
			this.fetchDataFile(manifest, 'picStage.json', this.picStages.loadData.bind(this.picStages)),
			this.fetchDataFile(manifest, 'pitch.json', this.pics.loadData.bind(this.pics)),
			this.fetchDataFile(manifest, 'prefix.json', this.prefixes.loadData.bind(this.prefixes)),
			this.fetchDataFile(manifest, 'program.json', this.programs.loadData.bind(this.programs)),
			this.fetchDataFile(manifest, 'programType.json', this.programTypes.loadData.bind(this.programTypes)),
			this.fetchDataFile(manifest, 'questionsType.json', this.questionsTypes.loadData.bind(this.questionsTypes)),
			this.fetchDataFile(manifest, 'questionType.json', this.questionTypes.loadData.bind(this.questionTypes)),
			this.fetchDataFile(manifest, 'site_program.json', this.sitePrograms.loadData.bind(this.sitePrograms)),
			this.fetchDataFile(manifest, 'site.json', this.sites.loadData.bind(this.sites)),
			this.fetchDataFile(manifest, 'suffix.json', this.suffixes.loadData.bind(this.suffixes)),
			this.fetchDataFile(manifest, 'tag.json', this.tags.loadData.bind(this.tags)),
			this.fetchDataFile(manifest, 'tagPrefix.json', this.tagPrefixes.loadData.bind(this.tagPrefixes)),
			this.fetchDataFile(manifest, 'venue.json', this.venues.loadData.bind(this.venues)),
			this.fetchDataFile(manifest, 'webLinkType.json', this.webLinkTypes.loadData.bind(this.webLinkTypes)),
			// this.fetchDataFile(manifest, 'zip.json', this.zips.loadData.bind(this.zips)),
		];

		await Promise.all(fetches);
		return true;
	}


	private async fetchDataFile(
		manifest: any,
		filename: string,
		loadData: (hash: string, data: any[]) => Promise<void>) {

		const HASH_KEY = `${DD_STORAGE_KEY}::${filename}::hash`;
		const DATA_KEY = `${DD_STORAGE_KEY}::${filename}::data`;

		const m: { hash: string, url: string } = manifest[filename];

		if (m) {

			let data: any[];
			let cachedHash: string = undefined;
			if (localStorage) cachedHash = localStorage.getItem(HASH_KEY);

			if (cachedHash && cachedHash == m.hash) {
				data = JSON.parse(localStorage.getItem(DATA_KEY));
			}
			else {
				try {
					const response = await fetch(m.url);
					data = await response.json();

					localStorage.setItem(HASH_KEY, m.hash);
					localStorage.setItem(DATA_KEY, JSON.stringify(data));
				}
				catch (e) {
					this.util.log.error(`Couldn't load ${filename}`, e);
				}
			}

			await loadData(m.hash, data);

		}
		else {
			this.util.log.errorMessage(`${filename} is not present in the DD Manifest file!`);
		}

	}



	public setDirectorOfPrograms(person: SimplePerson) {
		this._directorOfPrograms = person;
	}


	/**
	 * As defined in the config table (ConfigId.DirectorOfProgramsPersonId)
	 */
	public get directorOfPrograms(): SimplePerson {
		return this._directorOfPrograms;
	}


	/**
	 * The AppSessionService will return the initial set of people and call this.
	 */
	public addPeopleToCache(people: SimplePerson[]) {
		for (const person of people) {
			this._people[person.personId] = person;
		}
	}


	/**
	 * Returns the cached person object. If somehow the domain data and the cache
	 * of people get out of sync, and there is no person with the requested personId,
	 * then we punt and return the director of programs.
	 */
	public getCachedPerson(personId: number): SimplePerson {
		return this._people[personId] || this.directorOfPrograms;
	}


	/**
	* If a person associated with a site or program is changed,
	* this will be called to refresh the cache.
	*/
	public async reloadPeopleCache() {
		const { people, directorOfPrograms } = await this.func.public.domainData.reloadPeople();
		this.setDirectorOfPrograms(directorOfPrograms);
		this.addPeopleToCache(people);
	}


	/**
	 * The AppSessionService will return the initial set of zips and call this.
	 */
	public addZipsToCache(zips: DbcZip[]) {
		for (const zip of zips) {
			this._zips[zip.zipId] = zip;
		}
	}


	/**
	* TODO: Replace the DD zip caching with the ZipService
	* Returns the cached zip object.
	*/
	public getCachedZip(zipId: number): DbcZip | undefined {
		return this._zips[zipId];
	}


	/**
	* If a zip associated with a site office is changed,
	* this will be called to refresh the cache.
	*/
	public async reloadZipCache() {
		const zips = await this.func.public.domainData.reloadZips();
		this.addZipsToCache(zips);
	}
}