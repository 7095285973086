import { RegionAreaService, RegionPageService } from '@REGION-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { GridColumnType, GridSetup } from '@me-grid';
import { Event } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';


interface EventRow {
	eventId: number,
	picId: number,
	accId: number,
	siteCode: string,
	siteId: number,
	programName: string,
	type: 'Accelerator' | 'Pitch Contest',
}


@Component({
	selector: 'region-events-view-part',
	templateUrl: './events-view.part.html',
})
export class RegionEventsViewPart extends DestroyablePart implements OnInit {


	public gridSetup = this.setupGrid();
	public rows$: Observable<EventRow[]>;


	constructor(
		private pageService: RegionPageService,
		public regionAreaService: RegionAreaService,
		public ds: DataService,
		public util: UtilityService) {
		super();
	}


	ngOnInit() {
		super.initDestroyable();

		this.rows$ = this.ds.admin.singletonsAsOfUTC$.pipe(mergeMap(data => this.buildRows(data)));
	}

	async buildRows(singletonsAsOfUTC: number) {

		const identifier = this.regionAreaService.getId();
		const regionId = identifier.id;

		const sites = (await this.ds.admin.site.getByRegionIds([regionId]))[regionId];

		const siteMap = this.util.array.toMap(sites, (site => site.siteId));

		const accMap = await this.ds.admin.accelerator.getAllAsMap();
		const picMap = await this.ds.admin.pitchContest.getAllAsMap();
		const eventsBySiteId = await this.ds.admin.event.getBySiteIds(sites.map(site => site.siteId));
		const events: Event[] = [];
		for (const siteId in eventsBySiteId) {
			events.push(...eventsBySiteId[siteId]);
		}


		const rows: EventRow[] = events
			.filter(e => e.accId || e.picId)
			.map(e => ({
				eventId: e.eventId,
				accId: e.accId,
				picId: e.picId,
				siteCode: e.siteId ? siteMap[e.siteId].code : '',
				siteId: e.siteId,
				programName: e.accId ? accMap[e.accId].name : picMap[e.picId].name,
				type: e.accId ? 'Accelerator' : 'Pitch Contest',
				language: this.ds.domain.language.getOne(e.languageId).name,
			}));
		return rows;
	}




	private setupGrid(): GridSetup<EventRow> {


		return {
			experience: 'EVENT',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				shrinkBy: 0,
				heightMultiplier: 1,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Event",
			rowPluralName: "Events",
			rowKey: "eventId",
			stateKey: "region-all-events",
			canAdd: false,
			canRefresh: false,
			canDownload: true,
			columnsToAdd: [
				{ field: 'siteId', header: 'siteId', width: 80, type: GridColumnType.number, hidden: true },
				{ field: 'accId', header: 'accId', width: 80, type: GridColumnType.number, hidden: true },
				{ field: 'picId', header: 'picId', width: 80, type: GridColumnType.number, hidden: true },
				{ field: 'siteCode', header: 'Site Code', width: 100, type: GridColumnType.text, hidden: false },
				{ field: 'programName', header: 'Program', width: 200, type: GridColumnType.text, hidden: false },
				{ field: 'type', header: 'Type', width: 200, type: GridColumnType.text, hidden: false },
				{ field: 'EVENT_eventType', header: 'Event Type', width: 200, type: GridColumnType.text, hidden: false },
				{ field: 'EVENT_venueName', header: 'Venue', width: 250, type: GridColumnType.text, hidden: false },
			],
			initialState: {
				sort: [{ field: 'EVENT-startUTC', dir: 'desc' }],
			},

		};
	}

}