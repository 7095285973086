<div class='region-explorer-details-part' *ngIf="region">

	<div id="container" class="explorer-details-container">

		<table class='pro-table pro-table-no-header pro-table-with-labels'>
			<tr>
				<td> Region Id</td>
				<td> {{region.regionId}}</td>
			</tr>

			<tr>
				<td> Name</td>
				<td> {{region.name}}</td>
			</tr>

			<tr>
				<td>Last Edited By</td>
				<td>
					<explorer-button
						[button]="{ explorableId: { conceptName: 'person', id: region.updatedByPersonId }}">
					</explorer-button>
					<div class="small-nowrap-text">
						on {{util.date.formatUTC(region.updatedUTC, 'MMM D, YYYY', 'H:MM AM EST',
						urlService.languageId)}}
					</div>
				</td>
			</tr>

		</table>

		<div class="explorer-details-right">
		</div>

	</div>
</div>