<div class="entity-notes-edit-note">
	<!-- <me-save-changes-message *ngIf='messagedisplay' [hidden]="messagedisplay.disabled"></me-save-changes-message> -->
	<div class='entity-notes-edit-note-content'>
		<form *ngIf='form' [formGroup]="form" novalidate>
			<table class='pro-table pro-table-no-header pro-table-with-labels'>
				<tr>
					<td>
						<span title='Category'>Category</span>
					</td>
					<td>
						<kendo-dropdownlist formControlName="noteCategoryId" [data]="noteCategories"
							[textField]="'label'" [valueField]="'noteCategoryId'" [valuePrimitive]="true" required>
							<ng-template kendoDropDownListValueTemplate let-dataItem>
								<i *ngIf="dataItem?.noteCategoryId" [class]="getNoteIcon(dataItem.noteCategoryId)"></i>
								{{ dataItem?.label }}
							</ng-template>
						</kendo-dropdownlist>
						<div *ngIf='form.controls.noteCategoryId.errors && form.controls.noteCategoryId.errors.required && form.dirty'
							class="k-tooltip-validation">
							Required field
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<span title='Subject'>Subject</span>
					</td>
					<td><input class="k-textbox" placeholder="Enter Subject" formControlName="subject" required />
						<div *ngIf='form.controls.subject.errors' class="k-tooltip-validation">
							<div *ngIf='form.controls.subject.errors.required && form.dirty'>
								Required field
							</div>
							<div *ngIf='form.controls.subject.errors.maxlength'>
								{{tooManyCharacters(form.controls.subject)}}
							</div>
						</div>
					</td>
				</tr>
				<tr>
					<td colspan="2">
						<textarea kendoTextArea placeholder="Enter Note" rows='5' formControlName="note"></textarea>
					</td>
				</tr>
				<tr>
					<td>
						<span title='Site'>Community</span>
					</td>
					<td>
						<kendo-dropdownlist formControlName="siteId" [defaultItem]="defaultSiteItem" [data]="sites"
							[textField]="'name'" [valueField]="'siteId'" [valuePrimitive]="true"
							[value]="form.value.siteId">
						</kendo-dropdownlist>
					</td>
				</tr>
				<ng-container *ngIf='note'>
					<tr>
						<td>
							<span title='Created By'>Created By</span>
						</td>
						<td>
							{{note.createdBy._name}}
						</td>
					</tr>
					<tr>
						<td>
							<span title='Created On'>Created On</span>
						</td>
						<td>
							{{note.createdOn}}
						</td>
					</tr>
					<tr>
						<td>
							<span title='Last Edited By'>Last Edited By</span>
						</td>
						<td>
							{{note.changedBy._name}}
						</td>
					</tr>
					<tr>
						<td>
							<span title='Last Edited On'>Last Edited On</span>
						</td>
						<td>
							{{note.editedOn}}
						</td>
					</tr>
				</ng-container>
			</table>

			<button-container>
				<spinner-button (safeClick)='resetForm()' [disabled]='!form.dirty || updating'>Revert</spinner-button>
				<spinner-button *ngIf='note' #messagedisplay (safeClick)='save()'
					[disabled]='form.invalid || !form.dirty || updating' [spinning]='updating'>Save Changes
				</spinner-button>
				<spinner-button *ngIf='note' #messagedisplay (safeClick)='delete()' [disabled]='updating'
					[spinning]='updating'>Delete </spinner-button>
				<spinner-button *ngIf='!note' #messagedisplay (safeClick)='add()'
					[disabled]='form.invalid || !form.dirty || updating' [spinning]='updating'>Add Note
				</spinner-button>
			</button-container>

		</form>
	</div>
</div>