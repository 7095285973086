import { Old_MinimalContact } from "@me-interfaces";

export interface MinimalVolunteerApplicant {
	volunteerApplicationId: number,
	contact: Old_MinimalContact,
	createdUTC: number,
	languageId: number,
	applyMentor: boolean,
	applyReader: boolean,
	applyInterviewer: boolean,
	applyClassSpeaker: boolean,
	applyOfficeHours: boolean,
	applyWorkshops: boolean,
	applyEventhelper: boolean,
	city?: string,
	closedUTC: number,
	closedBy: Old_MinimalContact,
}