<div class='explorer-part company-explorer-part'>

	<div class="icon-bar">
		<me-icon-bar #iconBar [icons]='views' [orientation]="'Vertical'" (selectionsChange)='setViews($event)'>
		</me-icon-bar>
	</div>

	<div class="content">
		<ng-container *ngIf='selectedViews'>
			<div class="explorer-header">
				<me-icon [icon]="icons.company"></me-icon>
			</div>
			<h4 class="explorer-sub-header">Business</h4>
			<div class="explorer-small-header">Status:<span class="explorer-highlight-section">{{company.status}}</span>
			</div>

			<section>
				<explorer-buttons [buttons]='buttons'>
					<button *ngIf="company.hasRedFlag" class="text-btn text-btn-not-inline border-red"
						(click)="click('red-flag')">
						<me-icon [icon]="icons.redFlag"></me-icon>
					</button>
					<button *ngIf="company.awardedValue > 0" class="text-btn text-btn-not-inline"
						(click)="click('awards')">
						<me-icon [icon]="icons.award"></me-icon>
					</button>
				</explorer-buttons>
			</section>

			<explorer-section header="Company Details" (tabClose)="tabClose($event)"
				[style.display]="getDisplay('details')">
				<company-explorer-details [company]="company"></company-explorer-details>
			</explorer-section>

			<explorer-section [header]="util.text.singularOrPluralWithCount(noteBoxes.length, 'Note', 'Notes')"
				(tabClose)="tabClose($event)" [style.display]="getDisplay('notes')">
				<explorer-note-boxes>
					<ng-container *ngFor="let box of noteBoxes">
						<explorer-note-box [box]="box"> </explorer-note-box>
					</ng-container>
				</explorer-note-boxes>
			</explorer-section>

			<explorer-section header="Associations" (tabClose)="tabClose($event)"
				[style.display]="getDisplay('associations')">
				<explorer-boxes>
					<ng-container *ngFor="let box of peopleBoxes">
						<explorer-box [box]="box" [style.display]="getDisplay('people')"> </explorer-box>
					</ng-container>

					<ng-container *ngFor="let box of acceleratorBoxes">
						<explorer-box [box]="box" [style.display]="getDisplay('programming')"> </explorer-box>
					</ng-container>

					<ng-container *ngFor="let box of pitchContestBoxes">
						<explorer-box [box]="box" [style.display]="getDisplay('programming')"> </explorer-box>
					</ng-container>
				</explorer-boxes>
			</explorer-section>

			<explorer-section [header]="util.text.singularOrPluralWithCount(awardBoxes.length, 'Award', 'Awards')"
				(tabClose)="tabClose($event)" [style.display]="getDisplay('awards')">
				<explorer-boxes>
					<ng-container *ngFor="let box of awardBoxes">
						<explorer-box [box]="box"> </explorer-box>
					</ng-container>
				</explorer-boxes>
			</explorer-section>

		</ng-container>
	</div>
</div>