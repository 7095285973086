<div class='acc-cohort-counts-part'>
	<ng-container *ngIf='countsData$ | async; let countsData'>

		<me-h2>Counts</me-h2>
		<table class='pro-table pro-table-without-border pro-table-with-thin-rows'>
			<tr>
				<th>Info</th>
				<th>Count</th>
				<th>Contact</th>
			</tr>
			<tr>
				<td>Teams</td>
				<td>{{countsData.teamsCount}}</td>
				<td>
					<button class="text-btn text-btn-not-inline" (click)="openContactor(countsData.teamPersonIds)">
						<span class='contact-button-content'>
							<i class='far fa-envelope'></i> <i class='far fa-comment-lines'></i>
						</span>
					</button>
				</td>

			</tr>
			<tr>
				<td>Entrepreneurs</td>
				<td>{{countsData.entrepreneursCount}}</td>
				<td>
					<button class="text-btn text-btn-not-inline" (click)="openContactor(countsData.entrepreneursPersonIds)">
						<span class='contact-button-content'>
							<i class='far fa-envelope'></i> <i class='far fa-comment-lines'></i>
						</span>
					</button>
				</td>
			</tr>
			<tr>
				<td>Mentors</td>
				<td>{{countsData.mentorsCount}}</td>
				<td>
					<button class="text-btn text-btn-not-inline" (click)="openContactor(countsData.mentorPersonIds)">
						<span class='contact-button-content'>
							<i class='far fa-envelope'></i> <i class='far fa-comment-lines'></i>
						</span>
					</button>
				</td>
			</tr>
			<tr>
				<td>Coaches</td>
				<td>{{countsData.coachesCount}}</td>
				<td>
					<button class="text-btn text-btn-not-inline" (click)="openContactor(countsData.coachesPersonIds)">
						<span class='contact-button-content'>
							<i class='far fa-envelope'></i> <i class='far fa-comment-lines'></i>
						</span>
					</button>
				</td>
			</tr>
		</table>

		<ng-container *ngIf='countsData.droppedOutTeams.length'>
			<me-h2>Teams That Dropped Out</me-h2>
			<ul>
				<li *ngFor='let team of countsData.droppedOutTeams'>{{team.name}}
				</li>
			</ul>
		</ng-container>

		<ng-container *ngIf='countsData.quitMentorNames.length'>
			<me-h2>Mentors That Quit</me-h2>
			<ul>
				<li *ngFor='let name of countsData.quitMentorNames'>{{name}}</li>
			</ul>
		</ng-container>
	</ng-container>
</div>