import { AccMatchingRollups_old } from '@me-interfaces';

export interface AccMatchingPlayer {
	personId: number,
	firstName: string,
	lastName: string,
	hasAvatar: boolean,
	email: string,
	schedule: [
		number,
		number,
		number,
		number,
		number,
		number,
		number,
	],
	hasDefaultSchedule: boolean,
	rollups: AccMatchingRollups_old,
}