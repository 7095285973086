import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { DbConceptName, Person } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';
import { getIconClass, getIconContext, Icon, MultiSelectItem } from '@me-shared-parts/UI-common';
import { getIconContextForPackage } from '@me-shared-parts/UI-common/icon/get-icon-context-for-package';
import { SiteAreaService } from '@SITE-area';
import { Observable, switchMap } from 'rxjs';


const LANG_EN_ID = 37;
const LANG_ES_ID = 38;


@Component({
	selector: 'manage-volunteers-dialog',
	templateUrl: './manage-volunteers.dialog.html',
	styleUrls: ['./manage-volunteers.dialog.scss']
})
export class ManageVolunteersDialog extends DestroyablePart implements OnInit {
	//
	// The action buttons
	//
	updateAction: DialogAction<number[]> = {
		id: 'update',
		enabled: false,
		visible: true,
		label: ':Update',
		linkType: 'callback',
		callback: async () => (this.selectedPersonIds),
		willCloseDialog: true,
	};

	cancelAction: DialogAction = {
		id: 'close',
		enabled: true,
		visible: true,
		label: ':Cancel',
		linkType: "no-action",
		willCloseDialog: true,
	};

	actions: DialogAction<number[] | void>[] = [this.updateAction, this.cancelAction];
	icon = getIconClass(Icon.droplist_pickOne);
	header = 'Manage';
	tagName: string;

	public buttonIcon = Icon.dialog_explore;

	public addIcon = getIconContext(Icon.action_add, undefined, 'never', 'Approve another person');

	readonly: boolean;
	selectedPersonIds: number[] = [];
	addTagToPersonIds: number[] = [];
	orgPersonIds: number[] = [];
	totalPersonIds: number[] = [];
	coachTagId: number;

	people$: Observable<MultiSelectItem<Person, number>[]> = this.ds.admin.singletonsAsOfUTC$
		.pipe(
			switchMap(async utc => {
				const initialSelections = this.dialog.data.initialSelections;
				const selectionByPersonId = this.util.array.toMap(initialSelections, selection => selection.personId);

				const tagIds = this.dialog.data.tagIds;
				const personTags = [];

				const personTagsByTagId = await this.ds.admin.personTag.getArraysByForeignIds('tagId', tagIds);

				for (const tagId of tagIds) {
					personTags.push(...(personTagsByTagId[tagId] || []));
				}

				this.totalPersonIds = this.util.array.cleanNumericIds([
					...personTags.map(personTag => personTag.personId),
					...initialSelections.map(person => person.personId)
				]);

				const people = await this.ds.admin.person.getManyPackagesAsArray(this.totalPersonIds);


				const list = people
					.map(p => (
						{
							iconContext: this.getPersonIconContext(p),
							uniqueId: p.personId,
							disabled: selectionByPersonId[p.personId]?.disabled || false,
							checked: !!selectionByPersonId[p.personId],
							data: p,
						}
					));


				this.selectedPersonIds = list.filter(person => person.checked).map(p => p.uniqueId);

				return list.sort((a, b) => a.iconContext.text > b.iconContext.text ? 1 : -1);

			})
		);


	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ readonly: boolean, initialSelections: { personId: number, disabled: boolean }[], tagIds: number[], header: string, tagFullNameToAdd: string }>,
		public dialogRef: MatDialogRef<ManageVolunteersDialog>,
		private util: UtilityService,
		private ds: DataService,
		private siteAreaService: SiteAreaService,
	) {
		super();
	}


	async ngOnInit() {
		super.initDestroyable();

		const { readonly, initialSelections, header, tagIds } = this.dialog.data;

		this.tagName = (await this.ds.admin.tag.getManyPackagesAsArray(tagIds)).map(tag => tag.fullName).join(' or ');
		const siteId = this.siteAreaService.getId().id;
		const siteTags = (await this.ds.admin.tag.getAllPackagesAsArray()).filter(tag => tag.site && tag.site.siteId == siteId);
		this.coachTagId = siteTags.find(tag => tag.name == 'coach-approved')?.tagId;

		this.header = header;
		this.readonly = readonly;

		this.orgPersonIds = this.selectedPersonIds = this.util.array.cleanNumericIds(initialSelections.map(selection => selection.personId)).sort((a, b) => a - b);

		this.checkIfCanSave();
	}


	/**
	 * Look at selectedPersonIds and determine if the add/update button should be enabled
	 */
	checkIfCanSave() {
		this.updateAction.enabled = !this.readonly && !this.util.values.areSame(this.orgPersonIds, this.selectedPersonIds.sort((a, b) => a - b));
	}

	public onSelectionChange(selectedItems: MultiSelectItem[]) {
		this.selectedPersonIds = selectedItems.map(item => item.uniqueId);
		this.checkIfCanSave();
	}


	private getPersonIconContext(p: Person) {
		const langs = [];
		let hasCoachTag: boolean;
		if (p.tags.find(tag => tag.tagId == LANG_EN_ID)) langs.push('EN');
		if (p.tags.find(tag => tag.tagId == LANG_ES_ID)) langs.push('ES');
		if (this.tagName.endsWith('coach-approved') && p.tags.find(tag => tag.tagId == this.coachTagId)) hasCoachTag = true;

		return getIconContextForPackage(p, undefined, undefined, `${p.name}${hasCoachTag ? '(c)' : ''}${langs.length ? ' - ' + langs.join(', ') : ''}`);
	}




	async onSearchClick() {
		const conceptAndId = await this.ds.searcher.selectPerson();

		if (conceptAndId) {

			this.dialog.data.initialSelections.push({ personId: conceptAndId.id, disabled: false });

			this.selectedPersonIds = this.util.array.cleanNumericIds([...this.selectedPersonIds, conceptAndId.id]);

			await this.siteAreaService.volunteer.actions.setSiteRoleTag({ personId: conceptAndId.id, tagFullName: this.dialog.data.tagFullNameToAdd });

			await this.ds.dialogService.showMessage(`Person has been given ${this.dialog.data.tagFullNameToAdd} Tag and added to the approved list.`);

			this.checkIfCanSave();
		}

	}


	async onButtonClick(item: MultiSelectItem) {
		if (!item || item && !item.uniqueId) return;
		await this.ds.explorer.explore(DbConceptName.Person, item.uniqueId);
	}
}
