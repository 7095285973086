
import { DdAwardKindRow } from './dd-award-kind-row';
import { DdAwardKind } from './dd-award-kind';
import { DdObjects } from '../dd-objects';
import { OldDomainDataService } from '..';

/**
 * @deprecated Use the DataService instead
 */
export class DdAwardKinds extends DdObjects<DdAwardKindRow, DdAwardKind> {

	constructor(private dd: OldDomainDataService) {
		super('awardKindId');
	}

	async loadData(hash: string, data: DdAwardKindRow[]) {
		const objects = data.map(d => new DdAwardKind(this.dd, d));
		this.loadObjects(hash, objects);
	}
}