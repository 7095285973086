import { AppAreaApplyResponse, AppAreaFuncResponse, AppAreaIdentifier, AppAreaIdentifierWithSiteId, ApplicationStatusId } from "@me-interfaces";
import { AppSubArea } from "@me-services/core/area";
import { DataService } from "@me-services/core/data";
import { FuncService } from "@me-services/core/func";
import { UtilityService } from "@me-services/core/utility";


export class SiteSubAreaApplications extends AppSubArea {

	constructor(
		private func: FuncService,
		ds: DataService,
		util: UtilityService,
		getId: () => AppAreaIdentifier<number>,
		applyResponse: (response: AppAreaFuncResponse) => Promise<AppAreaApplyResponse>,
	) {
		super(ds, util, getId, applyResponse);
	}


	async setNotes(parameters: { applicationId: number, notes: string }) {
		return await this.call(this.func.areas.site.applications.setNotes, parameters);
	}

}