import { AccAreaService } from '@ACC-area';
import { Component, Input, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AccStageId } from '@me-interfaces';
import { DataService } from '@me-services/core/data';


@Component({
	selector: 'acc-stage-summary',
	templateUrl: `./acc-stage-summary.part.html`,
	styleUrls: [`./acc-stage-summary.part.scss`],
})
export class AccStageSummaryPart extends DestroyablePart implements OnInit {

	@Input() accStageId: AccStageId;

	cssClass: ' red-box' | ' green-box';
	label: string;
	userToolUrl: string;
	altToolName: string;
	tooltip: string;

	constructor(
		private ds: DataService,
		private accAreaService: AccAreaService,
	) {
		super();
	}


	ngOnInit() {

		super.initDestroyable();

		super.subscribe(
			[this.accAreaService.accelerator.acc$],
			async ([acc]) => {

				if (!acc) return;

				const stage = this.ds.domain.accStage.getOne(this.accStageId);

				if (acc.accStageId < this.accStageId) {
					this.cssClass = ' red-box';
					this.label = `Not yet ${stage.name}`;
					this.tooltip = `Current Stage: ${acc.stage.name}`;
				}
				else if (acc.accStageId > this.accStageId) {
					this.cssClass = ' red-box';
					this.label = `No longer ${stage.name}`;
					this.tooltip = `Current Stage: ${acc.stage.name}`;
				}
				else {
					this.cssClass = ' green-box';
					this.label = `Currently ${stage.name}`;
					this.tooltip = undefined;
				}

				//
				// Select the correct user tool url
				//
				this.userToolUrl = null;
				if (this.accStageId == AccStageId.Accepting) {
					this.userToolUrl = acc.userTools.applicationUrl;
					this.altToolName = 'Apply Tool'
				}
				if (this.accStageId == AccStageId.Reading) {
					this.userToolUrl = acc.userTools.readingUrl;
					this.altToolName = 'Reader Tool'
				}
				if (this.accStageId == AccStageId.Interviewing) {
					this.userToolUrl = acc.userTools.interviewingUrl;
					this.altToolName = 'Interviewer Tool'
				}
				if (this.accStageId == AccStageId.MatchingMentors) {
					this.userToolUrl = acc.userTools.matchingUrl;
					this.altToolName = 'Matching Tool'
				}
				if (this.accStageId == AccStageId.Curriculum) {
					this.userToolUrl = acc.userTools.judgingUrl;
					this.altToolName = 'Judge Tool'
				}

			},
		);

	}


	getCssClass() {
		const hasTooltip = this.tooltip == undefined ? '' : ' has-tooltip';
		return 'acc-stage-summary-part' + (this.cssClass ?? '') + hasTooltip;
	}


	getToolLink() {
		if (!this.userToolUrl) return null;

		return {
			linkType: 'tool',
			url: this.userToolUrl,
			overrideText: this.altToolName ?? `Tool`,
			enabled: true,
		}

	}
}