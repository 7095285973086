import { EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { DbConceptName } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { Icon } from "@me-shared-parts/UI-common";
import { AccCols } from "../columns";
import { EXPLORE_GRID_ACTION_KEY, GridColumnType, GridSetup, GRID_EXPERIENCE, OPEN_GRID_ACTION_KEY } from "../interfaces";
import { GridExperience } from "./grid-experience";


/**
 * Standard experience for a grid of sites
 */
export class AcceleratorGridExperience<ROW> extends GridExperience<ROW> {

	public static readonly experience: GRID_EXPERIENCE = 'ACC';

	constructor(
		private ds: DataService,
		private util: UtilityService,
		setup: GridSetup<ROW>,
		gridAction: EventEmitter<{ actionKey: string; rows: ROW[]; }>,
		private router: Router,
	) {
		super(
			util, setup,
			[
				{ field: AccCols.accId, header: 'accId', width: 70, type: GridColumnType.number, hidden: true },
				{ field: AccCols.siteId, header: 'siteId', width: 70, type: GridColumnType.number, hidden: true },
			],
			[
				{ field: AccCols.siteCode, header: 'Site Code', width: 70, type: GridColumnType.text, hidden: true },
				{ field: AccCols.siteName, header: 'Site', width: 160, type: GridColumnType.text },
				{ field: AccCols.name, header: 'Name', width: 100, type: GridColumnType.text },
				{ field: AccCols.stage, header: 'Stage', width: 160, type: GridColumnType.text },
				{ field: AccCols.language, header: 'Language', width: 100, type: GridColumnType.text },
			],
			[
				{ field: AccCols.startUTC, header: 'Start', width: 100, type: GridColumnType.dateUtc, hidden: true },
			],
			[
				{ key: EXPLORE_GRID_ACTION_KEY, icon: Icon.dialog_explore, label: `Explore ${setup.rowSingularName}` },
				{ key: OPEN_GRID_ACTION_KEY, icon: Icon.action_open, label: `Open ${setup.rowSingularName}` },
				// { key: NOTES_GRID_ACTION_KEY, icon: Icon.action_editNotes, label: `Edit Accelerator Notes` },
			],
			gridAction,
			Icon.concept_accelerator,
		);
	}


	public async applyBaseValues(rows: ROW[]) {

		const accMap = await this.ds.admin.accelerator.getManyPackagesAsMap(rows.map(row => row['accId']));

		for (const row of rows) {

			const accId = row['accId'];
			const accelerator = accMap[accId];

			row[AccCols.accId] = accelerator.accId;
			row[AccCols.siteId] = accelerator.siteProgram.site.siteId;
			row[AccCols.siteCode] = accelerator.siteProgram.site.code6;
			row[AccCols.siteName] = accelerator.siteProgram.site.name;
			row[AccCols.name] = accelerator.name;
			row[AccCols.language] = accelerator.siteProgram.program.language.name;
			row[AccCols.startUTC] = accelerator.startUTC;
			row[AccCols.stage] = `${this.util.number.getProgramStageNumber(accelerator.accStageId)} - ${accelerator.stage.name}`;

		}
	}


	public sortRows(rows: ROW[]) {
		rows.sort((row1: ROW, row2: ROW) => {
			const val1 = row1[AccCols.accId];
			const val2 = row2[AccCols.accId];
			return val1 < val2 ? 1 : -1;
		});
	}


	public async explore(row: ROW) {
		await this.ds.explorer.explore(DbConceptName.Accelerator, row[AccCols.accId]);
	}


	public async open(row: ROW) {
		this.router.navigate([`access/admin/national/sites/${row[AccCols.siteId]}/accelerators/${row[AccCols.accId]}/overview`]);
	}


	public async editNotes(row: ROW) {
		// Notes dialog not implemented
	}
}