
import { DdAccStageRow } from './dd-acc-stage-row';
import { DdAccStage } from './dd-acc-stage';
import { DdObjects } from '../dd-objects';
import { OldDomainDataService } from '..';

/**
 * @deprecated Use AccStage from the DataService instead
 */
export class DdAccStages extends DdObjects<DdAccStageRow, DdAccStage> {

	constructor(private dd: OldDomainDataService) {
		super('accStageId');
	}

	async loadData(hash: string, data: DdAccStageRow[]) {
		const objects = data.map(d => new DdAccStage(this.dd, d));
		this.loadObjects(hash, objects);
	}
}