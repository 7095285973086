import { Icon } from "./icon";
import { IconStyle } from "./icon-style";



/**
 * Translate an Icon enum value to a CSS class string.
 * @param style Defines how FontAwesome will display the icon. This parameter is ignored if the style is hard-coded (e.g. brands, ...).
 * @returns
 */
export function getIconClass(icon: Icon, style: IconStyle = 'fa-regular'): string {

	if (icon == Icon.accStage_setup) return `${style} fa-circle`;
	if (icon == Icon.accStage_accepting) return `${style} fa-circle`;
	if (icon == Icon.accStage_reading) return `${style} fa-circle`;
	if (icon == Icon.accStage_interviewing) return `${style} fa-circle`;
	if (icon == Icon.accStage_selectingCohort) return `${style} fa-circle`;
	if (icon == Icon.accStage_matchingMentors) return `${style} fa-circle`;
	if (icon == Icon.accStage_curriculum) return `${style} fa-circle`;
	if (icon == Icon.accStage_selectingWinners) return `${style} fa-circle`;
	if (icon == Icon.accStage_quarterlies) return `${style} fa-circle`;
	if (icon == Icon.accStage_complete) return `${style} fa-circle`;

	if (icon == Icon.action_add) return `${style} fa-plus`;
	if (icon == Icon.action_calendar) return `${style} fa-calendar`;
	if (icon == Icon.action_call) return `${style} fa-phone`;
	if (icon == Icon.action_clipboard) return `${style} fa-clipboard`;
	if (icon == Icon.action_delete) return `${style} fa-times`;
	if (icon == Icon.action_down) return `${style} fa-arrow-down`;
	if (icon == Icon.action_edit) return `${style} fa-pencil`;
	if (icon == Icon.action_editNotes) return `${style} fa-money-check-pen`;
	if (icon == Icon.action_explore) return `${style} fa-user-secret`;
	if (icon == Icon.action_email) return `${style} fa-envelope`;
	if (icon == Icon.action_help) return `${style} fa-question`;
	if (icon == Icon.action_loading) return `${style} fa-spinner-third fa-spin`;
	if (icon == Icon.action_open) return `${style} fa-arrow-alt-right`;
	if (icon == Icon.action_map) return `${style} fa-map-location-dot`;
	if (icon == Icon.action_menu) return `${style} fa-ellipsis-v`;
	if (icon == Icon.action_more) return `${style} fa-table-list`;
	if (icon == Icon.action_next) return `${style} fa-caret-right`;
	if (icon == Icon.action_previous) return `${style} fa-caret-left`;
	if (icon == Icon.action_search) return `${style} fa-magnifying-glass`;
	if (icon == Icon.action_sms) return `${style} fa-comment-alt-lines`;
	if (icon == Icon.action_transfer) return `${style} fa-random`;
	if (icon == Icon.action_up) return `${style} fa-arrow-up`;
	if (icon == Icon.action_video) return `${style} fa-video`;
	if (icon == Icon.action_view) return `${style} fa-eye`;

	if (icon == Icon.concept_accelerator) return `${style} fa-tachometer-alt`;
	if (icon == Icon.concept_application) return `${style} fa-file-signature`;
	if (icon == Icon.concept_accTeam) return `${style} fa-people-group`;
	if (icon == Icon.concept_agreementType) return `${style} fa-file-contract`;
	if (icon == Icon.concept_agreementSignature) return `${style} fa-signature`;
	if (icon == Icon.concept_award) return `${style} fa-award`;
	if (icon == Icon.concept_company) return `${style} fa-house-building`;
	if (icon == Icon.concept_event) return `${style} fa-calendar-lines`;
	if (icon == Icon.concept_national) return `${style} fa-earth-americas`;
	if (icon == Icon.concept_picTeam) return `${style} fa-person-chalkboard`;
	if (icon == Icon.concept_pitchContest) return `${style} fa-bullhorn`;
	if (icon == Icon.concept_person) return `${style} fa-person`;
	if (icon == Icon.concept_people) return `${style} fa-people-group`;
	if (icon == Icon.concept_position) return `${style} fa-id-badge`; //fa-id-card-clip?
	if (icon == Icon.concept_program) return `${style} fa-hand-holding-heart`;
	if (icon == Icon.concept_region) return `${style} fa-r`;
	if (icon == Icon.concept_site) return `${style} fa-location-dot`;
	if (icon == Icon.concept_venue) return `${style} fa-map-location-dot`;
	if (icon == Icon.concept_zip) return `${style} fa-circle-location-arrow`;

	if (icon == Icon.droplist_defaultSelectAnItem) return `fa-duotone fa-left-right color-darkgray`;
	if (icon == Icon.droplist_pickOne) return `${style} fa-hand-pointer`;

	if (icon == Icon.dialog_explore) return `${style} fa-square-e`;

	if (icon == Icon.empty) return '';

	if (icon == Icon.eventType_accFinalPitch) return `${style} fa-user-chart`;
	if (icon == Icon.eventType_accInterviewing) return `${style} fa-comments-alt`;
	if (icon == Icon.eventType_accMentorMatching) return `${style} fa-users`;
	if (icon == Icon.eventType_accReading) return `${style} fa-book-reader`;
	if (icon == Icon.eventType_picContest) return `${style} fa-user-chart`;
	if (icon == Icon.eventType_picReading) return `${style} fa-book-reader`;
	if (icon == Icon.eventType_accQuarterliesQ2) return `${style} fa-chart-pie-alt`;
	if (icon == Icon.eventType_accQuarterliesQ3) return `${style} fa-chart-pie-alt`;
	if (icon == Icon.eventType_accQuarterliesQ4) return `${style} fa-chart-pie-alt`;
	if (icon == Icon.eventType_accShowcase) return `${style} fa-award`;

	if (icon == Icon.industry_administrativeSupport) return `${style} fa-user-headset`;
	if (icon == Icon.industry_agriculture) return `${style} fa-farm`;
	if (icon == Icon.industry_animals) return `${style} fa-paw`;
	if (icon == Icon.industry_apparel_accessories) return `${style} fa-tshirt`;
	if (icon == Icon.industry_arts_culture) return `${style} fa-paint-brush`;
	if (icon == Icon.industry_automotive) return `${style} fa-cars`;
	if (icon == Icon.industry_beauty_cosmetics) return `${style} fa-pencil-paintbrush`;
	if (icon == Icon.industry_childcare_familyServices) return `${style} fa-child`;
	if (icon == Icon.industry_cleaning) return `${style} fa-hand-sparkles`;
	if (icon == Icon.industry_coaching_mentoring) return `${style} fa-people-carry`;
	if (icon == Icon.industry_communitySupport) return `${style} fa-hand-holding-heart`;
	if (icon == Icon.industry_creativeServices_multimedia) return `${style} fa-photo-video`;
	if (icon == Icon.industry_education) return `${style} fa-chalkboard-teacher`;
	if (icon == Icon.industry_entertainment_recreation) return `${style} fa-umbrella-beach`;
	if (icon == Icon.industry_environment) return `${style} fa-globe-americas`;
	if (icon == Icon.industry_eventPlanning) return `${style} fa-calendar-day`;
	if (icon == Icon.industry_finance_accounting) return `${style} fa-calculator`;
	if (icon == Icon.industry_food_beverage) return `${style} fa-burger-soda`;
	if (icon == Icon.industry_health_wellness) return `${style} fa-heartbeat`;
	if (icon == Icon.industry_hospitality_accommodations) return `${style} fa-concierge-bell`;
	if (icon == Icon.industry_lawncare) return `${style} fa-hand-sprinkler`;
	if (icon == Icon.industry_legal) return `${style} fa-balance-scale`;
	if (icon == Icon.industry_manufacturing) return `${style} fa-tools`;
	if (icon == Icon.industry_marketing) return `${style} fa-rss`;
	if (icon == Icon.industry_medical) return `${style} fa-notes-medical`;
	if (icon == Icon.industry_other) return `${style} fa-hyphen`;
	if (icon == Icon.industry_personal_relationships) return `${style} fa-user-friends`;
	if (icon == Icon.industry_philanthropy) return `${style} fa-hand-holding-usd`;
	if (icon == Icon.industry_photography_media) return `${style} fa-camera-alt`;
	if (icon == Icon.industry_propertyManagement) return `${style} fa-building`;
	if (icon == Icon.industry_retail) return `${style} fa-shopping-cart`;
	if (icon == Icon.industry_salon_spa_personalCare) return `${style} fa-hand-heart`;
	if (icon == Icon.industry_technology) return `${style} fa-microchip`;
	if (icon == Icon.industry_trades_electrician_plumber) return `${style} fa-user-hard-hat`;
	if (icon == Icon.industry_travel_tourism) return `${style} fa-plane-departure`;

	if (icon == Icon.language_en) return `${style} fa-square-e`;
	if (icon == Icon.language_es) return `${style} fa-square-s`;

	if (icon == Icon.level_exact) return `fa-solid fa-circle-e color-darkgreen`;
	if (icon == Icon.level_high) return `fa-regular fa-circle-h color-green`;
	if (icon == Icon.level_medium) return `fa-light fa-circle-m color-red`;
	if (icon == Icon.level_low) return `fa-thin fa-circle-l color-darkred`;
	if (icon == Icon.level_related) return `fa-thin fa-circle-r color-gray`;

	if (icon == Icon.link_facebook) return `fa-brands fa-facebook-square`;
	if (icon == Icon.link_linkedIn) return `fa-brands fa-linkedin-in`;
	if (icon == Icon.link_twitter) return `fa-brands fa-twitter`;
	if (icon == Icon.link_website) return `${style} fa-link`;

	if (icon == Icon.material_spreadsheet) return `${style} fa-file-spreadsheet`;
	if (icon == Icon.material_slide) return `${style} fa-file-powerpoint`;
	if (icon == Icon.material_handout) return `${style} fa-file`;
	if (icon == Icon.material_pdf) return `${style} fa-file-pdf`;
	if (icon == Icon.material_doc) return `${style} fa-file-doc`;
	if (icon == Icon.material_video) return `${style} fa-video`;
	if (icon == Icon.material_weblink) return `${style} fa-link`;
	if (icon == Icon.material_blog) return `${style} fa-link`;

	if (icon == Icon.notes_none) return `${style} fa-file`;
	if (icon == Icon.notes_redFlag) return `fa-solid fa-flag color-redflag`;
	if (icon == Icon.notes_noRedFlag) return `fa-thin fa-flag`;
	if (icon == Icon.notes_some) return `${style} fa-file-lines`;

	if (icon == Icon.status_check) return `${style} fa-check`;
	if (icon == Icon.status_deceased) return `fa-regular fa-face-frown color-darkred`;
	if (icon == Icon.status_doNotContact) return `fa-solid fa-do-not-enter color-darkred`;
	if (icon == Icon.status_error) return `fa-solid fa-circle-xmark color-darkred`;
	if (icon == Icon.status_missing) return `${style} fa-hyphen color-lightgray`;
	if (icon == Icon.status_pending) return `${style} fa-clock color-lightgray`;
	if (icon == Icon.status_selected) return `${style} fa-chevron-right color-darkgreen`;
	if (icon == Icon.status_success) return `${style} fa-check color-darkgreen`;
	if (icon == Icon.status_warning) return `fa-solid fa-triangle-exclamation color-darkorange`;
	if (icon == Icon.status_x) return `${style} fa-times color-darkred`;

	if (icon == Icon.tool_disabled) return `${style} fa-link-horizontal-slash`;
	if (icon == Icon.tool_enabled) return `${style} fa-link-horizontal`;

	if (icon == Icon.view_applications) return `${style} fa-file-signature`;
	if (icon == Icon.view_assessments) return `${style} fa-heart`;
	if (icon == Icon.view_bios) return `${style} fa-comment-dots`;
	if (icon == Icon.view_bulk_editor) return `${style} fa-chart-tree-map`;
	if (icon == Icon.view_config) return `${style} fa-gears`;
	if (icon == Icon.view_demographics) return `${style} fa-id-card`;
	if (icon == Icon.view_decide_awards) return `${style} fa-dollar-sign`;
	if (icon == Icon.view_editor) return `${style} fa-pen`;
	if (icon == Icon.view_entrepreneurs) return `${style} fa-person`;
	if (icon == Icon.view_event_past) return `${style} fa-calendar-check`;
	if (icon == Icon.view_event_future) return `${style} fa-calendar-lines`;
	if (icon == Icon.view_event_current) return `${style} fa-calendar-lines color-darkgreen`;
	if (icon == Icon.view_events) return `${style} fa-calendar-lines`;
	if (icon == Icon.view_feedback) return `${style} fa-message-smile`;
	if (icon == Icon.view_goals) return `${style} fa-chart-line`;
	if (icon == Icon.view_hints) return `${style} fa-wand-sparkles`;
	if (icon == Icon.view_interviewers) return `${style} fa-people`;
	if (icon == Icon.view_judges) return `${style} fa-gavel`;
	if (icon == Icon.view_list) return `${style} fa-list`;
	if (icon == Icon.view_members) return `${style} fa-users-line`;
	if (icon == Icon.view_mm_heatmap) return `${style} fa-fire`;
	if (icon == Icon.view_mm_matcher) return `${style} fa-people-arrows`;
	if (icon == Icon.view_mentors) return `${style} fa-person-rays`;
	if (icon == Icon.view_overview) return `${style} fa-grip-horizontal`;
	if (icon == Icon.view_programming) return `${style} fa-lightbulb`;
	if (icon == Icon.view_relationships) return `${style} fa-people-arrows-left-right`;
	if (icon == Icon.view_readers) return `${style} fa-book-reader`;
	if (icon == Icon.view_report) return `${style} fa-chart-line`;
	if (icon == Icon.view_sessions) return `${style} fa-screen-users`;
	if (icon == Icon.view_surveys) return `${style} fa-flip-horizontal fa-message-smile`;
	if (icon == Icon.view_tags) return `${style} fa-tags`;
	if (icon == Icon.view_teams) return `${style} fa-people-group`;
	if (icon == Icon.view_topics) return `${style} fa-square-t`;
	if (icon == Icon.view_volunteers) return `${style} fa-hands-holding-heart`;

	return '';

}
