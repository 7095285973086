import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogContext } from '@me-services/ui/dialog';

@Component({
	selector: 'application-delete-dialog',
	templateUrl: './SHR-CMN_application-delete.dialog.html',
	styleUrls: ['./SHR-CMN_application-delete.dialog.scss']
})
export class ApplicationDeleteDialog {

	constructor(
		@Inject(MAT_DIALOG_DATA) public dialog: DialogContext<unknown>,
		private dialogRef: MatDialogRef<ApplicationDeleteDialog>,
	) {
	}

	onNoClick(): void {
		this.dialogRef.close('noClick');
	}

	action(e: 'remove' | 'cancel') {
		if (e) this.dialogRef.close(e);
	}
}
