import { SiteAreaData, SiteAreaService } from '@SITE-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { GridColumnType, GridSetup } from '@me-grid';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { Observable, combineLatest } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { SiteDashboardPageService } from '../../site-overview-page.service';


interface EventRow {
	eventId: number,
	picId: number,
	accId: number,
	programName: string,
	type: 'Accelerator' | 'Pitch Contest',
}


@Component({
	selector: 'site-events-view-part',
	templateUrl: './events-view.part.html',
	styleUrls: ['./events-view.part.scss'],
})
export class SiteEventsViewPart extends DestroyablePart implements OnInit {


	public gridSetup = this.setupGrid();
	public rows$: Observable<EventRow[]>;


	constructor(
		private pageService: SiteDashboardPageService,
		public siteAreaService: SiteAreaService,
		public ds: DataService,
		public util: UtilityService) {
		super();
	}


	ngOnInit() {
		super.initDestroyable();

		this.rows$ = combineLatest([
			this.ds.admin.singletonsAsOfUTC$,
			this.siteAreaService.data$,
		]).pipe(mergeMap(data => this.buildRows(data[0], data[1])));
	}

	async buildRows(singletonsAsOfUTC: number, data: SiteAreaData): Promise<EventRow[]> {

		if (!data) return [];

		const events = (await this.ds.admin.event.getBySiteIds([data.site.siteId]))[data.site.siteId];

		const accsMap = await this.ds.admin.accelerator.getManyAsMap(events.filter(e => e.accId).map(e => e.accId));
		const picsMap = await this.ds.admin.pitchContest.getManyAsMap(events.filter(e => e.picId).map(e => e.picId));

		const rows: EventRow[] = events.map(e => ({
			eventId: e.eventId,
			accId: e.accId,
			picId: e.picId,
			programName: e.accId ? accsMap[e.accId].name : e.picId ? picsMap[e.picId].name : undefined,
			type: e.accId ? 'Accelerator' : e.picId ? 'Pitch Contest' : undefined,
		}));

		return rows;
	}




	private setupGrid(): GridSetup<EventRow> {


		return {
			experience: 'EVENT',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				shrinkBy: 0,
				heightMultiplier: 1,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Event",
			rowPluralName: "Events",
			rowKey: "eventId",
			stateKey: "site-all-events",
			canAdd: false,
			canRefresh: false,
			canDownload: true,
			columnsToAdd: [
				{ field: 'accId', header: 'accId', width: 80, type: GridColumnType.number, hidden: true },
				{ field: 'picId', header: 'picId', width: 80, type: GridColumnType.number, hidden: true },
				{ field: 'programName', header: 'Program', width: 200, type: GridColumnType.text, hidden: false },
				{ field: 'type', header: 'Type', width: 200, type: GridColumnType.text, hidden: false },
				{ field: 'EVENT_toolStartUTC', header: 'Tool Start', width: 150, type: GridColumnType.dateAndTimeUtc, hidden: true },
				{ field: 'EVENT_toolEndUTC', header: 'Tool End', width: 150, type: GridColumnType.dateAndTimeUtc, hidden: true },
				{ field: 'EVENT_eventType', header: 'Event Type', width: 200, type: GridColumnType.text, hidden: false },
				{ field: 'EVENT_venueName', header: 'Venue', width: 250, type: GridColumnType.text, hidden: false },
			],
			initialState: {
				sort: [{ field: 'EVENT-startUTC', dir: 'desc' }],
				filter: { logic: 'and', filters: [{ field: 'EVENT_status', operator: 'neq', value: 'Complete' }] },
			},


		};
	}

}