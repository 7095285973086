import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { DdPic, OldDomainDataService } from '@me-services/core/old-dd';
import { FuncService } from '@me-services/core/func';
import { UtilityService } from '@me-services/core/utility';
import { PicWrapper } from './pic-wrapper';

/** @deprecated DO NOT USE THIS!*/
@Injectable({ providedIn: 'root' })
export class OldPicService {

	constructor(private func: FuncService, private dd: OldDomainDataService, private util: UtilityService,
	) { }

	public getPicIdFromUrl(url?: string): number {

		let picId: number = undefined;
		if (!url) url = window.location.pathname.toLowerCase();

		if (url.startsWith('/access/sites/')) {
			if (url.includes('/pitch-contests/')) {
				const parts = url.split('/');
				if (parts.length >= 5) picId = +parts[5];
			}
		}

		if (url.startsWith('/access/admin/communities/')) {
			if (url.includes('/programs/pitch-contests/')) {
				const parts = url.split('/');
				if (parts.length >= 8) picId = +parts[7];
			}
		}

		if (!picId) {
			this.util.log.errorMessage(`picService.getPicIdFromUrl() called for path ${url} which doesn't have a picId`);
		}

		return picId;
	}

	public getPicIdFromRoute(route: ActivatedRouteSnapshot): number {
		const url = this.util.router.routeToUrl(route);
		return this.getPicIdFromUrl(url);
	}

	public getDdPicFromUrl(url?: string): DdPic {

		let pic: DdPic = undefined;
		const picId = this.getPicIdFromUrl(url);

		if (picId) pic = this.dd.pics.getById(picId);

		return pic;
	}

	public getDdPicFromRoute(route: ActivatedRouteSnapshot): DdPic {
		const url = this.util.router.routeToUrl(route);
		return this.getDdPicFromUrl(url);
	}

	public wrapPic(pic: DdPic) {
		return new PicWrapper(pic, this.func, this.dd);
	}

}