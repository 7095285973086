import { Component, EventEmitter, Output } from '@angular/core';
import { AuthService } from '@me-services/core/auth';
import { take } from 'rxjs/operators';
import { LabelKey } from '@me-interfaces';

@Component({
	selector: 'session-add-me-part',
	templateUrl: './session-add-me.part.html',
	styleUrls: ['./session-add-me.part.scss'],
})
export class SessionAddMePart {

	@Output() join = new EventEmitter<void>();
	@Output() cancel = new EventEmitter<void>();
	adding = false;
	welcomeMessage: string | LabelKey = 'Welcome';

	constructor(public auth: AuthService) {
		this.createWelcomeMessage();
	}

	addMe() {
		this.adding = true;
		this.join.emit();
	}

	cancelMe() {
		this.cancel.emit();
	}

	private async createWelcomeMessage() {
		const fb: any = await this.auth.me.firebaseUser$.pipe(take(1)).toPromise();

		this.welcomeMessage = {
			key: 'Welcome, {{name}}',
			fields: { name: fb.displayName },
		};
	}
}