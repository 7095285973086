import { DbConceptName, PitchContest } from "@me-interfaces";
import { Icon } from "@me-shared-parts/UI-common";
import { DataService } from "../../../../data.service";
import { ExplorerBox, ExplorerBoxClass } from "../../../explorer-box/explorer-box";


export async function getBoxesForAwards(ds: DataService, pitchContest: PitchContest): Promise<ExplorerBox[]> {

	const boxes: ExplorerBox[] = [];

	const awards = (await ds.admin.award.getByPicIds([pitchContest.picId]))[pitchContest.picId] || [];

	for (const award of [...awards].sort((a, b) => a.event.name > b.event.name ? 1 : -1)) {
		const members = award.picTeam.members.map(member => `${member.person.firstName} ${member.person.lastName.slice(0, 1)}.`);
		const memberText = members.sort((a, b) => a > b ? 1 : -1).join(', ');
		const eventType = ds.domain.eventType.getOne(award.event.eventTypeId).name;
		const awardName = ds.domain.awardName.getOne(award.awardNameId).name;
		const awardKind = ds.domain.awardKind.getOne(award.awardKindId).name;
		const box: ExplorerBox = {
			explorerBoxClass: ExplorerBoxClass.Award,
			header: `Award`,
			buttons: [
				{ explorer: { nameOverride: eventType, explorableId: { conceptName: DbConceptName.Event, id: award.eventId }, navTooltip: `Event: ${eventType}` } },
				{ explorer: { nameOverride: `$${award.value} - ${awardName}`, explorableId: { conceptName: DbConceptName.Event, id: award.eventId }, navIconOverride: Icon.concept_award, navTooltip: `Award: $${award.value} - ${awardName}` } },
				{ explorer: { nameOverride: `${memberText} & Team`, explorableId: { conceptName: DbConceptName.PicTeam, id: award.picTeamId }, navTooltip: `Team: ${memberText} & Team` } },
			],
			notes: awardKind,
		};

		boxes.push(box);
	}

	return boxes;
}