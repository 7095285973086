<div class="textarea-question-dialog">

	<me-dialog-frame *ngIf='basePart' [header]='headerText' [actions]='actions' [dialogRef]='dialogRef'
		icon='fa-question' alertType='editor' (actionClick)='actionClick($event)'>

		<field-chrome [basePart]='basePart' [highlightIfIncomplete]='false' (fieldClick)='textArea.focus()'>

			<div class='length-container'>
				<div class="length-remaining" [class.has-error]='lengthLeft < 0'>{{lengthLeft}}</div>
			</div>
			<textarea #textArea [placeholder]='basePart.text.placeholder' kendoTextArea [autoSize]="true"
				(input)='textChange($event)' [style.min-width]="'100%'"
				[class.has-error]='!!basePart.errorText'>{{basePart.currentValue}}</textarea>

		</field-chrome>

	</me-dialog-frame>
</div>