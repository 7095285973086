import { Component, Input, OnChanges } from '@angular/core';
import { DbsEvent } from '@me-interfaces';
import { IconContext } from '@me-shared-parts/UI-common';

const NO_EVENT: IconContext = { text: "No Event", iconClass: "fas fa-location-arrow", hideText: 'never' };


@Component({
	selector: 'event-icon',
	templateUrl: './SHR-ED_event-icon.part.html',
})
export class EventIconPart implements OnChanges {

	@Input() event: DbsEvent = undefined;
	icon: IconContext = NO_EVENT;


	ngOnChanges() {

		if (this.event) {
			this.icon = { text: 'sdfsdf', iconClass: "far fa-location-arrow", hideText: 'never' };
		}
		else {
			this.icon = NO_EVENT;
		}
	}

}