<div class='person-explorer-details-part' *ngIf="personDetails">

	<div id="container" class="explorer-details-container">

		<table class='pro-table pro-table-no-header pro-table-with-labels'>
			<tr>
				<td> Person Id</td>
				<td> {{personDetails.personId}}</td>
			</tr>

			<tr *ngIf="personDetails.doNotContactNote">
				<td> Do Not Contact</td>
				<td> {{personDetails.doNotContactNote}}</td>
			</tr>

			<tr>
				<td> Last Login</td>
				<td *ngIf="!personDetails.lastLoginDate">
					<button class="text-btn text-btn-not-inline" (click)="retrieveLastLogin()">
						<me-icon [icon]="{ iconClass: 'fa-regular fa-eyes', text: 'Click to See', hideText: 'lt-md'}">
						</me-icon>
					</button>
				</td>
				<td *ngIf="personDetails.lastLoginDate"> {{personDetails.lastLoginDate }} <button class="text-btn"
						(click)="retrieveLastLogin()">Refresh</button></td>
			</tr>

			<tr *ngIf="personDetails.emails.length">
				<td>Other Emails</td>
				<td>
					<link-button *ngFor="let email of personDetails.emails" [link]="{ linkType: 'email', email: email}">
					</link-button>
				</td>
			</tr>

			<tr *ngIf="personDetails.prefix">
				<td>Prefix</td>
				<td>{{personDetails.prefix}}</td>
			</tr>

			<tr *ngIf="personDetails.suffix">
				<td>Suffix</td>
				<td>{{personDetails.suffix}}</td>
			</tr>

			<tr *ngIf="personDetails.formerName">
				<td>Former Name</td>
				<td>{{personDetails.formerName}}</td>
			</tr>

			<tr *ngIf="personDetails.preferredName">
				<td>Preferred Name</td>
				<td>{{personDetails.preferredName}}</td>
			</tr>

			<tr>
				<td>Last Edited By</td>
				<td>
					<explorer-button
						[button]="{ explorableId: { conceptName: 'person', id: personDetails.updatedByPersonId }}">
					</explorer-button>
					<div class="small-nowrap-text">
						on {{util.date.formatUTC(personDetails.updatedUTC, 'MMM D, YYYY', 'H:MM AM EST',
						ds.languageId)}}
					</div>
				</td>
			</tr>
		</table>

		<div class="explorer-details-right">
			<h6>Signed Agreements</h6>
			<i *ngIf="signatures.length === 0">{{ personDetails.firstName }} has not signed any agreements.</i>
			<ul>
				<li *ngFor="let signature of signatures">{{ signature.agreementName }}
					<div class="small-nowrap-text"> - signed {{ signature.signedDate }}</div>
				</li>
			</ul>
		</div>

	</div>
</div>