import { Injectable } from "@angular/core";
import { AppAreaIdentifier, DbsPerson, SiteAreaAccess, SiteAreaRawData } from "@me-interfaces";
import { AppAreaService } from "@me-services/core/area";
import { DataService } from "@me-services/core/data";
import { DexieService } from "@me-services/core/dexie";
import { FuncService } from "@me-services/core/func";
import { UtilityService } from "@me-services/core/utility";
import { PageSpinnerService } from "@me-services/ui/page-spinner";
import { UrlService } from "@me-services/ui/url";
import { UserAreaService } from "@me-user-area";
import { Subject } from "rxjs";
import { mapToData } from "./map-to-data";
import { SiteAreaData } from "./interfaces/site-area-data";
import { SiteSubAreaConfig } from "./site-subarea-config";
import { SiteSubAreaVolunteer } from "./site-subarea-volunteer";
import { SiteSubAreaApplications } from "./site-subarea-applications";

const NO_ACCESS: SiteAreaAccess = {
	root: 'None',
	sub: {
		config: 'None',
		data: 'None',
	}
};

@Injectable({
	providedIn: 'root',
})
export class SiteAreaService extends AppAreaService<AppAreaIdentifier<number>, SiteAreaAccess, SiteAreaRawData, SiteAreaData> {

	//
	// Data subsets
	//

	public readonly site = {
		site$: super.mapSubset(data => data.site),
	};


	public readonly config = {
		actions: new SiteSubAreaConfig(this.func, this.ds, this.util, super.getId.bind(this), this.applyResponse.bind(this)),
	};



	public readonly volunteer = {
		actions: new SiteSubAreaVolunteer(this.func, this.ds, this.util, super.getId.bind(this), this.applyResponse.bind(this)),
	};


	public applications = new SiteSubAreaApplications(this.func, this.ds, this.util, super.getId.bind(this), this.applyResponse.bind(this));


	constructor(
		urlService: UrlService,
		dexieService: DexieService,
		ds: DataService,
		spinnerService: PageSpinnerService,
		util: UtilityService,
		private func: FuncService,
		userAreaService: UserAreaService,
	) {
		super(
			'Staff',
			userAreaService.user$,
			'Site',
			dexieService,
			ds,
			spinnerService,
			util,
			urlService.appAreas.siteId$,
			func.areas.site.get,
			async () => ({ area: 'Site', access: NO_ACCESS, md5Hash: 'NO_ACCESS' }),
			async rawData => await mapToData(rawData, util, ds),
			data => data.site.name,
		);
	}

}