import { Accelerator, DbConceptName, Dbs, Immutable, Person } from '@me-interfaces';


interface MutableDbsAccJudge extends Dbs {

	readonly _concept: DbConceptName.AccJudge,

	accJudgeId: number,
	personId: number,
	accId: number,
	judgedAccTeamIds: number[],
	updatedUTC: number,
	updatedByPersonId: number,
}

interface MutableAccJudge extends MutableDbsAccJudge {
	person: Person,
	accelerator: Accelerator,
	updatedByPersonName: string,
}


export interface DbsAccJudge extends Immutable<MutableDbsAccJudge> { }
export interface AccJudge extends Immutable<MutableAccJudge> { }