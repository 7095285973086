import { MatchingHint } from "@me-interfaces";

/**
 * Given an array of matching hints, find and remove any duplicates.
 */
export function cleanMatchingHints(hints: MatchingHint[]): MatchingHint[] {

	//
	// Reverse the array so the newest duplicate is the one that is kept
	//
	hints.reverse();

	//
	// Remove duplicates
	//
	const keyMap: { [key: string]: MatchingHint } = {};

	hints = hints.filter(hint => {
		const key = createHintKey(hint);
		if (keyMap[key]) return false;
		keyMap[key] = hint;
		return true;
	});


	//
	// Reverse the reverse
	//
	hints.reverse();

	return hints;
}


/**
 * Return a string that uniquely identifies this hint given
 * the combination of ids.
 */
function createHintKey(hint: MatchingHint) {

	if (hint.kind == 'T+M' || hint.kind == 'T-M') {
		return `T${hint.id1}-P${hint.id2}`;
	}
	else {
		//
		// Always put the lowest personId first
		//
		const id1 = Math.min(hint.id1, hint.id2);
		const id2 = Math.max(hint.id1, hint.id2);
		return `P${id1}-P${id2}`;
	}
}