import { AppAreaApplyResponse, AppAreaFuncResponse, AppAreaIdentifierWithSite, AppAreaIdentifierWithSiteId } from "@me-interfaces";
import { AppSubArea } from "@me-services/core/area";
import { DataService } from "@me-services/core/data";
import { FuncService } from "@me-services/core/func";
import { UtilityService } from "@me-services/core/utility";

export class AccSubAreaTeams extends AppSubArea {

	constructor(
		private func: FuncService,
		ds: DataService,
		util: UtilityService,
		getId: () => AppAreaIdentifierWithSiteId<number>,
		applyResponse: (response: AppAreaFuncResponse) => Promise<AppAreaApplyResponse>,
	) {
		super(ds, util, getId, applyResponse);
	}


	async setNotes(parameters: { accTeamId: number, notes: string }) {
		return await this.call(this.func.areas.acc.teams.setNotes, parameters);
	}

	async setDroppedOutWeek(parameters: { accTeamId: number, droppedOutWeek: number }) {
		return await this.call(this.func.areas.acc.teams.setDroppedOut, parameters);
	}

	async setFinalEventId(parameters: { accTeamId: number, eventId: number }) {
		return await this.call(this.func.areas.acc.teams.setFinalEventId, parameters);
	}

	async removeMentor(parameters: { accTeamMemberId: number }) {
		return await this.call(this.func.areas.acc.teams.removeMentor, parameters);
	}

	async removeEntrepreneur(parameters: { accTeamMemberId: number, accTeamId: number }) {
		return await this.call(this.func.areas.acc.teams.removeEntrepreneur, parameters);
	}

	async setMentorRole(parameters: { accTeamMemberId: number, role: 'M' | 'C' | 'X', accTeamId: number }) {
		return await this.call(this.func.areas.acc.teams.setMentorRole, parameters);
	}

	async addGoal(parameters: { accTeamId: number, quarter: number, summary: string, details: string }) {
		return await this.call(this.func.areas.acc.teams.addGoal, parameters);
	}

	async setGoal(parameters: { accTeamGoalId: number, summary: string, details: string, progress: string }) {
		return await this.call(this.func.areas.acc.teams.setGoal, parameters);
	}

	async addGoalComment(parameters: { accTeamGoalId: number, comment: string }) {
		return await this.call(this.func.areas.acc.teams.addGoalComment, parameters);
	}

	async addMember(parameters: { accTeamId: number, personId: number, role: 'E' | 'M' | 'C' }) {
		return await this.call(this.func.areas.acc.teams.addMember, parameters);
	}
}