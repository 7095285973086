<div #toolbar class='me-toolbar-part'
	[ngClass]="{'toolbar-is-scrollable':isScrollable, 'acc-toolbar':accToolbar, 'pic-toolbar':picToolbar, 'program-toolbar':programToolbar, 'site-toolbar':siteToolbar, 'region-toolbar':regionToolbar, 'national-toolbar':nationalToolbar, 'staff-toolbar':staffToolbar}">

	<div class='toolbar-content'>
		<ng-content></ng-content>
	</div>

	<div class='toolbar-icon'
		*ngIf='accToolbar || nationalToolbar || picToolbar || programToolbar|| siteToolbar || regionToolbar || staffToolbar'>
		<i class='fa-fw fad fa-tachometer-alt' title='Accelerator Toolbar' *ngIf='accToolbar'></i>
		<i class='fa-fw fad fa-earth-americas' title='National Toolbar' *ngIf='nationalToolbar'></i>
		<i class='fa-fw fad fa-bullhorn' title='Pitch Contest Toolbar' *ngIf='picToolbar'></i>
		<i class='fa-fw fad fa-hand-holding-heart' title='Program Toolbar' *ngIf='programToolbar'></i>
		<i class='fa-fw fad fa-r' title='Region Toolbar' *ngIf='regionToolbar'></i>
		<i class='fa-fw fad fa-map-marker-alt' title='Site Toolbar' *ngIf='siteToolbar'></i>
		<i class='fa-fw fad fa-users' title='Staff Toolbar' *ngIf='staffToolbar'></i>
	</div>

</div>