
import { Dbs, SingletonDelta, SingletonId, SingletonName } from '@me-interfaces';
import { PageSpinnerService } from '@me-services/ui/page-spinner';
import { Dexie } from 'dexie';
import { ReplaySubject, lastValueFrom } from 'rxjs';
import { FuncService } from '../func';
import { UtilityService } from '../utility';


export const SINGLETONS_DATA_DB_KEY = 'SINGLETONS_DATA';
export type SingletonForeignId = (SingletonId | 'entityId');

export interface SingletonCacheConfig {
	name: SingletonName,
	/** The id property name that uniquely identifies an object of this named singleton type */
	id: SingletonId,
	/** The non-primary id properties that can be used to look up singletons */
	foreignIds: SingletonForeignId[],
}

//
// Each singleton must have a configuration which is used to construct the local cache.
//
export const cacheConfigs: SingletonCacheConfig[] = [
	{ name: 'accelerators', id: 'accId', foreignIds: ['siteId'] },
	{ name: 'accInterviewers', id: 'accInterviewerId', foreignIds: ['personId', 'accId'] },
	{ name: 'accJudges', id: 'accJudgeId', foreignIds: ['personId', 'accId'] },
	{ name: 'accReaders', id: 'accReaderId', foreignIds: ['personId', 'accId'] },
	{ name: 'accSessionSurveyResponses', id: 'accSessionSurveyResponseId', foreignIds: ['accId', 'eventId'] },
	{ name: 'accSessionTopicSurveyResponses', id: 'accSessionTopicSurveyResponseId', foreignIds: ['accId'] },
	{ name: 'accSessionSpecialistSurveyResponses', id: 'accSessionSpecialistSurveyResponseId', foreignIds: ['accId'] },
	{ name: 'accTeamMembers', id: 'accTeamMemberId', foreignIds: ['personId', 'accTeamId'] }, // no package
	{ name: 'accTeams', id: 'accTeamId', foreignIds: ['applicationId', 'accId'] },
	{ name: 'agreementTypes', id: 'agreementTypeId', foreignIds: [] },
	{ name: 'agreementVersions', id: 'agreementVersionId', foreignIds: ['agreementTypeId'] },
	{ name: 'agreementSignatures', id: 'agreementSignatureId', foreignIds: ['agreementVersionId', 'personId'] },
	{ name: 'applicationParticipants', id: 'applicationParticipantId', foreignIds: ['personId', 'applicationId'] }, // no package
	{ name: 'applications', id: 'applicationId', foreignIds: ['personId', 'accId', 'picId', 'companyId'] },
	{ name: 'awards', id: 'awardId', foreignIds: ['eventId', 'accTeamId', 'picTeamId'] },
	{ name: 'councilMembers', id: 'councilMemberId', foreignIds: ['personId', 'siteId'] },
	{ name: 'companies', id: 'companyId', foreignIds: [] },
	{ name: 'emails', id: 'emailId', foreignIds: ['personId'] }, // no package
	{ name: 'entityNotes', id: 'noteId', foreignIds: ['entityId'] }, // no package
	{ name: 'events', id: 'eventId', foreignIds: ['accId', 'picId', 'siteId'] },
	{ name: 'people', id: 'personId', foreignIds: [] },
	{ name: 'personTags', id: 'personTagId', foreignIds: ['tagId'] }, // no package
	{ name: 'picJudges', id: 'picJudgeId', foreignIds: ['personId', 'picId'] },
	{ name: 'picReaders', id: 'picReaderId', foreignIds: ['personId', 'picId'] },
	{ name: 'picTeamMembers', id: 'picTeamMemberId', foreignIds: ['personId', 'picTeamId'] }, // no package
	{ name: 'picTeams', id: 'picTeamId', foreignIds: ['applicationId', 'picId'] },
	{ name: 'pitchContests', id: 'picId', foreignIds: ['siteId'] },
	{ name: 'positions', id: 'positionId', foreignIds: ['personId', 'companyId'] }, // no package
	{ name: 'programs', id: 'programId', foreignIds: [] },
	{ name: 'regions', id: 'regionId', foreignIds: [] },
	{ name: 'relationships', id: 'personPersonId', foreignIds: [] },	// no package
	{ name: 'sitePrograms', id: 'siteProgramId', foreignIds: ['siteId'] },
	{ name: 'sites', id: 'siteId', foreignIds: ['regionId'] },
	{ name: 'specialists', id: 'specialistId', foreignIds: ['personId', 'siteId'] },
	{ name: 'tagPrefixes', id: 'tagPrefixId', foreignIds: [] },
	{ name: 'tags', id: 'tagId', foreignIds: [] },
	{ name: 'venues', id: 'venueId', foreignIds: [] },
	{ name: 'webLinks', id: 'webLinkId', foreignIds: ['entityId'] }, // no package
];

interface SingletonCache<DBS extends Dbs> {
	list: DBS[],
	map: Readonly<Record<number, DBS>>,
	foreignMaps: Record<SingletonForeignId, Readonly<Record<number, DBS[]>>>,
}


export class DexieSingletonsDb {

	private configMap: Record<SingletonName, SingletonCacheConfig>;
	private objects: Dexie.Table<Dbs[], SingletonName>;
	private cache: Record<string, SingletonCache<Dbs>> = {};
	private loaded$ = new ReplaySubject<true>(1);


	constructor(
		private func: FuncService,
		private util: UtilityService,
		private pageSpinner: PageSpinnerService,
	) {

		//
		// Map for quick lookup by name
		//
		this.configMap = util.array.toMap(cacheConfigs, config => config.name);


		//
		// Build the database
		//
		const dexie = new Dexie('SINGLETONS');
		const schema = { objects: '' };
		dexie.version(2).stores(schema);


		//
		// Construct the objects table and cache
		//
		this.objects = dexie.table('objects');
		this.loadFromDexie();
	}


	/**
	 * Loop through each singleton, read each from IndexedDb
	 */
	private async loadFromDexie() {

		for (const cacheConfig of cacheConfigs) {

			// const desc = `Singletons.objects.get('${cacheConfig.name}')`;
			// this.pageSpinner.addSpinner(desc);
			const list = await this.objects.get(cacheConfig.name) ?? [];
			// this.pageSpinner.removeSpinner(desc);

			this.cacheSingletonList(cacheConfig, list);
		}

		this.loaded$.next(true);
		this.loaded$.complete();
	}


	/**
	 * For any one singleton, load the data into the local cache, and build the maps
	 */
	private cacheSingletonList(cacheConfig: SingletonCacheConfig, list: Dbs[]) {

		//
		// Create an object to hold the map to all items. If there is a prior
		// map object use it and clear it first in case there are references
		// and to limit garbage collection.
		//
		const map: Record<number, Dbs> = this.cache[cacheConfig.name]?.map || {};
		this.util.object.clear(map);

		//
		// Similar to the map object, attempt to reuse the foreign map objects
		// but clear them out and refill them.
		//
		const foreignMaps: Record<string, Readonly<Record<number, Dbs[]>>> = this.cache[cacheConfig.name]?.foreignMaps || {};
		for (const foreignId of cacheConfig.foreignIds) {
			foreignMaps[foreignId] = foreignMaps[foreignId] ?? {};
			this.util.object.clear(foreignMaps[foreignId]);
		}


		//
		// Build a record to record missing foreignIds
		//
		const missingForeignIds: Record<string, number[]> = {};
		for (const foreignId of cacheConfig.foreignIds) {
			missingForeignIds[foreignId] = [];
		}

		//
		// Loop each item and build the maps
		//
		for (const item of list) {

			const singletonId = item[cacheConfig.id];
			map[singletonId] = item;


			for (const foreignId of cacheConfig.foreignIds) {
				const id: number = item[foreignId];
				if (!id) {
					missingForeignIds[foreignId].push(singletonId);
					continue;
				}

				const map = <Record<number, Dbs[]>>(foreignMaps[foreignId] = foreignMaps[foreignId] ?? {});
				const mappedList = map[id] = map[id] ?? [];
				mappedList.push(item);
			}
		}


		//
		// Report anything missing
		//
		for (const foreignId of cacheConfig.foreignIds) {
			const missingIds = missingForeignIds[foreignId];
			if (missingIds.length > 0 && missingIds.length == list.length) {
				console.error(`Singleton foreignId error: All ${cacheConfig.name} are missing a ${foreignId} value`);
			}
		}

		this.cache[cacheConfig.name] = { list, map, foreignMaps };
	}



	/**
	 * It's best practice to pass all of the ids needed for a particular singleton type first. If any are
	 * not in the cache then this method will wait for them to be downloaded and added to the cache.
	 */
	public async ensureAllDownloaded<DBS extends Dbs>(singletonName: SingletonName, ids: number[]): Promise<void> {

		if (ids.length == 0) return;

		await lastValueFrom(this.loaded$);

		const config = this.configMap[singletonName];
		const cache = this.cache[singletonName];
		const idProperty = config.id;


		//
		// Determine if any of the ids are missing
		//
		const map = cache.map;
		const missingIds: number[] = [];

		for (const id of ids) {
			if (!map[id]) {
				missingIds.push(id);
			}
		}

		if (missingIds.length == 0) return;


		//
		// Get the missing singleton objects, regardless of updatedUTC value
		//
		console.warn(`${singletonName} cache does not have a ${idProperty} with the following ids. They will be downloaded and cached separately.`, missingIds);
		const missing = <DBS[]>await this.func.areas.getMissingSingletons({ singletonName, missingIds });


		//
		// Push the missing singleton objects into the cache
		//
		await this.applyDelta(singletonName, { asOfUTC: 0, updated: missing, deletedIds: [] });

	}


	/**
	 * WARNING: Do not call this unless you know what you are doing!!!!
	 * This function directly changes the cache without any regard to to the UTC.
	 * 
	 * This should ONLY be called data.applySingletonsCacheConfig() or data.mergeResponseSingletons().
	 * The only known exception, for now, is the funkiness of deleting events (but that needs refactoring)
	 * 
	 * @param fromCacheFile If true, any previous items will be cleared out
	 * 
	 */
	async applyDelta<DBS extends Dbs>(
		name: SingletonName,
		delta: SingletonDelta<DBS>,
		fromCacheFile = false,
	) {

		//
		// If nothing has changed for this singleton then we bail and avoid touching IndexedDb
		//
		if (!delta.updated.length && !delta.deletedIds.length) return;


		//
		// Get the configuration for the named singleton
		//
		const config = this.configMap[name];
		if (!config) throw new Error(`No singleton cache config for ${name}`);
		const idProperty = config.id;


		//
		// Get the current list of items for this singleton
		//
		let list = this.cache[name]?.list;
		if (!list) throw new Error(`No singleton cache list for ${name}`);


		//
		// If we are applying delta from a loaded cache file then that should
		// be the full set and we don't need any of the previous items.
		//
		if (fromCacheFile) list = [];


		//
		// Replace/Add any changed items
		//
		const updatedMap = this.util.array.toMap<number, DBS>(delta.updated, item => item[idProperty]);
		list = list.filter(item => !updatedMap[item[idProperty]]);
		this.util.array.push(list, delta.updated);


		//
		// Remove any deleted items
		//
		const deletedMap = this.util.array.toBooleanMap(delta.deletedIds);
		list = list.filter(item => !deletedMap[item[idProperty]]);


		//
		// Write the values into IndexedDb
		//
		// const desc = `Singletons.objects.put('${name}')`;
		// this.pageSpinner.addSpinner(desc);
		await this.objects.put(list, name);
		// this.pageSpinner.removeSpinner(desc);


		//
		// Store the new list and rebuild that maps
		//
		this.cacheSingletonList(config, list);

	}



	/**
	 * Get the full set of a named singleton type as an array
	 */
	public async getAllAsArrayFromCache<DBS extends Dbs>(name: SingletonName): Promise<DBS[]> {

		await lastValueFrom(this.loaded$);
		return <DBS[]>this.cache[name].list;
	}



	/**
	 * Get the full set of a named singleton type as a map by id
	 */
	public async getAllAsMapFromCache<DBS extends Dbs>(name: SingletonName): Promise<Readonly<Record<number, DBS>>> {

		await lastValueFrom(this.loaded$);
		return <Readonly<Record<number, DBS>>>this.cache[name].map;
	}



	/**
	 * Get a list of named singletons that match a foreign key id
	 */
	public async getArrayByForeignIdFromCache<DBS extends Dbs>(name: SingletonName, foreignIdProperty: SingletonForeignId, foreignId: number): Promise<DBS[]> {

		await lastValueFrom(this.loaded$);

		const foreignMap = this.cache[name].foreignMaps[foreignIdProperty];
		if (!foreignMap) {
			console.error(`${name} cache is not configured with a foreign id map for ${foreignIdProperty}`);
			return [];
		}

		return <DBS[]>foreignMap[foreignId] ?? [];
	}



	/**
	 * Get a map of lists of named singletons that match a provided set of foreign key ids.
	 */
	public async getArraysByForeignIdsFromCache<DBS extends Dbs>(name: SingletonName, foreignIdProperty: SingletonForeignId, foreignIds: readonly number[]): Promise<Record<number, DBS[]>> {

		await lastValueFrom(this.loaded$);

		const foreignMap = this.cache[name].foreignMaps[foreignIdProperty];
		if (!foreignMap) {
			console.error(`${name} cache is not configured with a foreign id map for ${foreignIdProperty}`);
			return [];
		}

		const map: Record<number, DBS[]> = {};

		for (const foreignId of foreignIds) {
			map[foreignId] = <DBS[]>foreignMap[foreignId] ?? [];
		}

		return map;
	}

}