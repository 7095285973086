<app-area-access [areaService]='accAreaService'>
	<div class='acc-pre-accelerator-part'>
		
		<me-toolbar icon='Acc'>
			<acc-toolbar-buttons></acc-toolbar-buttons>
		</me-toolbar>

		<page-tabs [tabNames]='["Manage", "Accept", "Read", "Interview", "Select", "Match"]'
			[pageService]='pageService' (layoutChange)='onLayoutChange($event)'>

			<ng-template pageTab1>
				<acc-pre-accelerator-views-part> </acc-pre-accelerator-views-part>
			</ng-template>

			<ng-template pageTab2>
				<acc-accept-tab-part></acc-accept-tab-part>
			</ng-template>

			<ng-template pageTab3>
				<acc-read-tab-part></acc-read-tab-part>
			</ng-template>

			<ng-template pageTab4>
				<acc-interview-tab-part></acc-interview-tab-part>
			</ng-template>

			<ng-template pageTab5>
				<acc-select-tab-part></acc-select-tab-part>
			</ng-template>

			<ng-template pageTab6>
				<acc-match-tab-part></acc-match-tab-part>
			</ng-template>

		</page-tabs>
	</div>
</app-area-access>