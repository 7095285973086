<div class="acc-team-members-add-dialog">

	<me-dialog-frame header='Add Member' [actions]='actions' [dialogRef]='dialogRef' [icon]='icon' alertType='editor'>

		<div class='acc-team-members-add-dialog-content'>

			<table style='width:100%'
				class='pro-table pro-table-no-header pro-table-with-labels pro-table-without-border'>
				<tr>
					<td style="width: 120px;">Role</td>
					<td>
						<me-droplist [items]='items' [selectedItem]='selectedRole'
							(selectionChange)='roleSelectionChange($event)' [readonly]='readonly' sortBy="uniqueId">
						</me-droplist>
					</td>
				</tr>
				<ng-container *ngIf="selectedRole">
					<tr *ngIf="selectedRole.data !=='E'">
						<td>Mentor</td>
						<td>
							<me-droplist [items]='mentors' [selectedItem]='selectedMentor'
								(selectionChange)='mentorSelectionChange($event)' [readonly]='readonly' sortBy="text">
							</me-droplist>
						</td>
					</tr>
					<tr *ngIf="selectedRole.data ==='E'">
						<td>Entrepreneur</td>
						<td>
							<div style="display: grid;grid-template-columns: 1fr 50px;">
								<me-droplist [items]='entrepreneurs' [selectedItem]='selectedEntrepreneur'
									(selectionChange)='entrepreneurSelectionChange($event)' [readonly]='readonly'
									sortBy="text">
								</me-droplist>

								<button class="btn" (click)="showEditMessage()">
									<me-icon [icon]="editIcon"></me-icon>
								</button>
							</div>
						</td>
					</tr>
				</ng-container>
			</table>

		</div>

	</me-dialog-frame>
</div>