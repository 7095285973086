import { AppAreaFuncResponse, AppAreaIdentifier, DbsAward, DbsEvent, DbcEventAttendee, AppAreaApplyResponse } from "@me-interfaces";
import { AppSubArea } from "@me-services/core/area";
import { DataService } from "@me-services/core/data";
import { FuncService } from "@me-services/core/func";
import { UtilityService } from "@me-services/core/utility";


export class AdminSubAreaEvents extends AppSubArea {

	constructor(
		private func: FuncService,
		ds: DataService,
		util: UtilityService,
		getId: () => AppAreaIdentifier<string>,
		applyResponse: (response: AppAreaFuncResponse) => Promise<AppAreaApplyResponse>,
	) {
		super(ds, util, getId, applyResponse);
	}


	async upsertAttendee(parameters: { eventAttendee: DbcEventAttendee; }) {
		return await this.call(await this.func.areas.admin.events.upsertAttendee, parameters);
	}

	async addAward(parameters: { award: DbsAward; }) {
		return await this.call(await this.func.areas.admin.events.addAward, parameters);
	}

	async addEvent(parameters: { event: DbsEvent; }) {
		parameters.event = { ...parameters.event };
		parameters.event['host'] = undefined;  // extracts are too large to send up
		return await this.call(await this.func.areas.admin.events.addEvent, parameters);
	}

	async deleteAttendee(parameters: { eventId: number; personId: number; }) {
		return await this.call(await this.func.areas.admin.events.deleteAttendee, parameters);
	}

	async deleteAward(parameters: { awardId: number; }) {
		return await this.call(await this.func.areas.admin.events.deleteAward, parameters);
	}

	async deleteEvent(parameters: { eventId: number; }) {
		return await this.call(await this.func.areas.admin.events.deleteEvent, parameters);
	}

	async updateAward(parameters: { award: DbsAward; }) {
		return await this.call(await this.func.areas.admin.events.updateAward, parameters);
	}

	async updateEvent(parameters: { event: DbsEvent; }) {
		parameters.event = { ...parameters.event };
		parameters.event['host'] = undefined;  // extracts are too large to send up
		return await this.call(await this.func.areas.admin.events.updateEvent, parameters);
	}

}