import { DbConceptName, Dbs, Immutable } from '@me-interfaces';


interface MutableDbsAccTeamMember extends Dbs {

	readonly _concept: DbConceptName.AccTeamMember,

	readonly accTeamMemberId: number,
	readonly accTeamId: number,
	readonly personId: number,
	readonly role: 'E' | 'C' | 'M' | 'X',
	readonly notes: string,
	readonly updatedUTC: number,
	readonly updatedByPersonId: number,
}


export interface DbsAccTeamMember extends Immutable<MutableDbsAccTeamMember> { }