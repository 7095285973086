import { SiteVolunteerApplication } from "@me-interfaces";

export interface EforAllAreaRawData {
	missingDemographics: {
		site: string,
		year: number,
		season: string,
		personId: number,
		firstName: string,
		lastName: string,
		email: string,
		phone: string,
		demographicsGiven: 'P' | 'N' | 'O',
	}[],
}