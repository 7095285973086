import { EventEmitter } from "@angular/core";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { Icon } from "@me-shared-parts/UI-common/icon";
import { AccSurveyResponseSessionCols, buildColumn, GridColumnConfig } from '../columns';
import { GRID_EXPERIENCE, GridSetup } from "../interfaces";
import { GridExperience } from "./grid-experience";


/**
 * Standard experience for a grid of acc survey responses for session
 */
export class AccSurveyResponseSessionGridExperience<ROW> extends GridExperience<ROW> {

	public static readonly experience: GRID_EXPERIENCE = 'ACC_SURVEY_RESPONSE_SESSION';

	constructor(
		private ds: DataService,
		private util: UtilityService,
		setup: GridSetup<ROW>,
		gridAction: EventEmitter<{ actionKey: string; rows: ROW[]; }>,
	) {
		super(
			util, setup,
			[
				buildColumn(AccSurveyResponseSessionCols.accSessionSurveyResponseId, GridColumnConfig.id, { hidden: true, header: 'accSessionSurveyResponseId' }),
				buildColumn(AccSurveyResponseSessionCols.accSessionAttendeeId, GridColumnConfig.id, { hidden: true, header: 'accSessionAttendeeId' }),
				buildColumn(AccSurveyResponseSessionCols.siteId, GridColumnConfig.siteId, { hidden: true }),
				buildColumn(AccSurveyResponseSessionCols.accId, GridColumnConfig.accId, { hidden: true }),
				buildColumn(AccSurveyResponseSessionCols.eventId, GridColumnConfig.eventId, { hidden: true }),
				buildColumn(AccSurveyResponseSessionCols.programManagerId, GridColumnConfig.personId, { hidden: true, header: 'programManagerId', headerTooltip: 'The personId of the accelerator program manager' }),
			],
			[
				buildColumn(AccSurveyResponseSessionCols.stateCode, GridColumnConfig.stateCode, { hidden: true }),
				buildColumn(AccSurveyResponseSessionCols.siteCode, GridColumnConfig.siteCode, { hidden: true }),
				buildColumn(AccSurveyResponseSessionCols.siteName, GridColumnConfig.siteName, { hidden: true }),
				buildColumn(AccSurveyResponseSessionCols.accYear, GridColumnConfig.year, { hidden: true }),
				buildColumn(AccSurveyResponseSessionCols.accSeason, GridColumnConfig.accSeason, { hidden: true }),
				buildColumn(AccSurveyResponseSessionCols.accName, GridColumnConfig.accName, { hidden: true }),
				buildColumn(AccSurveyResponseSessionCols.session, GridColumnConfig.text, { hidden: false, header: 'Session', width: 120 }),
				buildColumn(AccSurveyResponseSessionCols.venue, GridColumnConfig.venue, { hidden: true }),
				buildColumn(AccSurveyResponseSessionCols.sessionTopics, GridColumnConfig.text, { hidden: true, header: 'Session Topics', width: 120 }),
			],
			[
				buildColumn(AccSurveyResponseSessionCols.accessibilityBucket, GridColumnConfig.text, { hidden: false, header: 'Accessibility', headerTooltip: 'Accessibility: "I was able to easily attend this session."', width: 80 }),
				buildColumn(AccSurveyResponseSessionCols.accessibility, GridColumnConfig.number, { hidden: true, header: 'Accessibility Rating', headerTooltip: 'Accessibility: "I was able to easily attend this session. (0..10)"', width: 80 }),
				buildColumn(AccSurveyResponseSessionCols.communicationBucket, GridColumnConfig.text, { hidden: false, header: 'Communication', headerTooltip: 'Communication: "I received all information of the session."', width: 80 }),
				buildColumn(AccSurveyResponseSessionCols.communication, GridColumnConfig.number, { hidden: true, header: 'Communication Rating', headerTooltip: 'Communication: "I received all information of the session. (0..10)"', width: 80 }),
				buildColumn(AccSurveyResponseSessionCols.organizationBucket, GridColumnConfig.text, { hidden: false, header: 'Organization', headerTooltip: 'Organization: "The session was well facilitated."', width: 80 }),
				buildColumn(AccSurveyResponseSessionCols.organization, GridColumnConfig.number, { hidden: true, header: 'Organization Rating', headerTooltip: 'Organization: "The session was well facilitated. (0..10)"', width: 80 }),
				buildColumn(AccSurveyResponseSessionCols.other, GridColumnConfig.text, { hidden: true, header: 'Comment', headerTooltip: 'Other Feedback (optional)' }),
				buildColumn(AccSurveyResponseSessionCols.createdUTC, GridColumnConfig.createdUtc, { hidden: true }),
			],
			[
				// { key: EXPLORE_GRID_ACTION_KEY, icon: Icon.dialog_explore, label: `Explore ${setup.rowSingularName}` },
				// { key: OPEN_GRID_ACTION_KEY, icon: Icon.action_open, label: `Open ${setup.rowSingularName}` },
				// { key: NOTES_GRID_ACTION_KEY, icon: Icon.editNotes, label: `Edit Survey Notes` },
			],
			gridAction,
			Icon.view_surveys,
		);
	}


	public async applyBaseValues(rows: ROW[]) {

		const accSessionSurveyResponseId = rows.map(row => <number>row['accSessionSurveyResponseId']);
		const accSessionSurveyResponseMap = await this.ds.admin.accSessionSurveyResponse.getManyPackagesAsMap(accSessionSurveyResponseId);


		for (const row of rows) {
			const accSessionSurveyResponseId = row['accSessionSurveyResponseId'];
			const response = accSessionSurveyResponseMap[accSessionSurveyResponseId];

			row[AccSurveyResponseSessionCols.accSessionSurveyResponseId] = accSessionSurveyResponseId;
			row[AccSurveyResponseSessionCols.accSessionAttendeeId] = response.accSessionAttendeeId;
			row[AccSurveyResponseSessionCols.siteId] = response.accelerator.siteProgram.siteId;
			row[AccSurveyResponseSessionCols.accId] = response.accId;
			row[AccSurveyResponseSessionCols.eventId] = response.eventId;
			row[AccSurveyResponseSessionCols.programManagerId] = response.accelerator.managerId;

			row[AccSurveyResponseSessionCols.session] = this.util.date.formatUTC(response.event.startUTC, 'MMM D, YYYY (DOW)', 'H:MM AM EST', this.ds.languageId);
			row[AccSurveyResponseSessionCols.venue] = response.event.venueId ? response.event.venue?.displayedName || 'Online' : 'Online';
			row[AccSurveyResponseSessionCols.sessionTopics] = response.event.name;
			row[AccSurveyResponseSessionCols.accessibilityBucket] = this.util.nps.getBucket(response.accessibility);
			row[AccSurveyResponseSessionCols.accessibility] = response.accessibility;
			row[AccSurveyResponseSessionCols.communicationBucket] = this.util.nps.getBucket(response.communication);
			row[AccSurveyResponseSessionCols.communication] = response.communication;
			row[AccSurveyResponseSessionCols.organizationBucket] = this.util.nps.getBucket(response.organization);
			row[AccSurveyResponseSessionCols.organization] = response.organization;
			row[AccSurveyResponseSessionCols.other] = response.other;

			row[AccSurveyResponseSessionCols.stateCode] = response.accelerator.siteProgram.site.stateCode;
			row[AccSurveyResponseSessionCols.siteCode] = response.accelerator.siteProgram.site.code6;
			row[AccSurveyResponseSessionCols.siteName] = response.accelerator.siteProgram.site.name;
			row[AccSurveyResponseSessionCols.accName] = response.accelerator.name;
			row[AccSurveyResponseSessionCols.accYear] = response.accelerator.year;
			row[AccSurveyResponseSessionCols.accSeason] = response.accelerator.season;
			row[AccSurveyResponseSessionCols.createdUTC] = response.createdUTC;

		}
	}


	public sortRows(rows: ROW[]) {
		// rows.sort((row1: ROW, row2: ROW) => {
		// 	const val1 = row1[AccTeamCols.companyName];
		// 	const val2 = row2[AccTeamCols.companyName];
		// 	return val1 < val2 ? -1 : 1;
		// });
		return 0;
	}


	public async explore(row: ROW) {
		// await this.ds.explorer.explore(DbConceptName.AccTeam, row[AccTeamCols.accTeamId]);
	}


	public async open(row: ROW) {
		// this.router.navigate([`access/admin/national/sites/${row['siteId']}/accelerators/${row['accId']}/cohort/${row[AccTeamCols.accTeamId]}`]);
	}


	public async editNotes(row: ROW) {
		// Notes dialog not implemented
	}
}