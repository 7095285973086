
import { DdSiteRow } from './dd-site-row';
import { DdSite } from './dd-site';
import { DdObjects } from '../dd-objects';
import { OldDomainDataService } from '..';

/**
 * @deprecated Use the DataService instead
 */
export class DdSites extends DdObjects<DdSiteRow, DdSite> {

	protected _mapByCode: { [index: string]: DdSite } = {};

	constructor(private dd: OldDomainDataService) {
		super('siteId');
	}

	async loadData(hash: string, data: DdSiteRow[]) {

		const objects = data.map(d => new DdSite(this.dd, d));
		this.loadObjects(hash, objects);


		//
		// Create a site code map
		//
		const map: { [index: string]: DdSite } = objects.reduce((map, site) => {
			map[site.code.toUpperCase()] = site;
			return map;
		}, {});

		this._mapByCode = map;
	}


	/**
	 * Return an array of objects, one per state, with the list of sites in that state.
	 */
	public async getByState(): Promise<{ state: string, sites: DdSite[] }[]> {

		const list: { state: string, sites: DdSite[] }[] = [];

		// Sort by name
		const sites = [...this._objects].sort((s1, s2) => { return s1.name < s2.name ? -1 : 1; });

		for (const site of sites.filter(site => site.isActive && !site.hidden)) {
			const zip = await site.zip;
			const state = zip.state;

			let stateSites = list.find(l => l.state == state);
			if (!stateSites) {
				stateSites = { state, sites: [] };
				list.push(stateSites);
			}
			stateSites.sites.push(site);
		}

		return list;
	}

	public getByCode(code: string): DdSite {
		return this._mapByCode[code.toUpperCase()];
	}

}