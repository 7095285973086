//
// NOTE: If you add to this, insert them alphabetically!
//
export const ButtonCardStyles = {
	Accelerator: { icon: 'fa-regular fa-tachometer-alt', cycleColors: true }, // use color index
	Accelerators: { icon: 'fa-regular fa-tachometer-alt', color: 'orange' },
	Add: { icon: 'fa-regular fa-plus-circle', color: 'green' },
	AddressSync: { icon: 'fa-regular fa-house-signal', color: 'blue' },
	Admin: { icon: 'fa-regular fa-lock-alt', color: 'red' },
	AlumniNag: { icon: 'fa-regular fa-graduation-cap', color: 'pink' },
	AlumniReport: { icon: 'fa-regular fa-user-graduate', color: 'mustard' },
	Applications: { icon: 'fa-regular fa-file-signature', color: 'peach' },
	Assessments: { icon: 'fa-regular fa-heart-square', color: 'peach' },
	Associations: { icon: 'fa-regular fa-lightbulb-on', color: 'peach' },
	AuditTrail: { icon: 'fa-regular fa-history', color: 'orange' },
	Awards: { icon: 'fa-regular fa-trophy', color: 'lavendar' },
	AwardsCeremony: { icon: 'fa-regular fa-award', color: 'mustard' },
	Cohort: { icon: 'fa-regular fa-users-class', color: 'orange' },
	Companies: { icon: 'fa-regular fa-building', color: 'lime' },
	CompanySearch: { icon: 'fa-regular fa-search', color: 'lime' },
	Common: { icon: 'fa-regular fa-layer-group', color: 'mustard' },
	Config: { icon: 'fa-regular fa-cogs', color: 'mustard' },
	Connections: { icon: 'fa-regular fa-exchange', color: 'lime' },
	Contact: { icon: 'fa-regular fa-mail-bulk', color: 'peach' },
	ChangeHistory: { icon: 'fa-regular fa-clipboard-list', color: 'lime' },
	Curriculum: { icon: 'fa-regular fa-chalkboard-teacher', color: 'lilac' },
	Dashboard: { icon: 'fa-regular fa-grip-horizontal', color: 'blue' },
	Data: { icon: 'fa-regular fa-chart-pie', color: 'orange' },
	Demographics: { icon: 'fa-regular fa-universal-access', color: 'lime' },
	Edit: { icon: 'fa-regular fa-edit', color: 'pink' },
	Editor: { icon: 'fa-regular fa-pen-square', color: 'lime' },
	EforAll: { icon: 'fa-regular fa-lightbulb', color: 'blue' },
	Eforever: { icon: 'fa-regular fa-infinity', color: 'lavendar' },
	Emails: { icon: 'fa-regular fa-envelope', color: 'green' },
	Events: { icon: 'fa-regular fa-calendar-lines', color: 'mustard' },
	Feedback: { icon: 'fa-regular fa-comment-alt-smile', color: 'pink' },
	FeedbackEforall: { icon: 'fa-regular fa-lightbulb', color: 'lilac' },
	FeedbackEntrepreneur: { icon: 'fa-regular fa-user-friends', color: 'lime' },
	FinalPitch: { icon: 'fa-regular fa-user-chart', color: 'green' },
	HearReport: { icon: 'fa-regular fa-ear', color: 'blue' },
	Heatmap: { icon: 'fa-regular fa-fire', color: 'blue' },
	Integration: { icon: 'fa-regular fa-download', color: 'peach' },
	Interviewers: {icon: 'fa-regular fa-diagnoses', color: 'blue'},
	Interviewing: { icon: 'fa-regular fa-comments-alt', color: 'lime' },
	Judges: {icon: 'fa-regular fa-gavel', color: 'mustard'},
	Languages: { icon: 'fa-regular fa-comment', color: 'lilac' },
	Links: { icon: 'fa-regular fa-link', color: 'blue' },
	List: { icon: 'fa-regular fa-list-alt', color: 'blue' },
	LeavesOfAbsence: { icon: 'fa-regular fa-user-clock', color: 'blue' },
	MailChimp: { icon: 'fab fa-mailchimp', color: 'lavendar' },
	Membership: { icon: 'fa-regular fa-user-shield', color: 'green' },
	MembershipDetails: { icon: 'fa-regular fa-id-card-alt', color: 'peach' },
	Mentoring: { icon: 'fa-regular fa-users', color: 'orange' },
	MentorMatching: { icon: 'fa-regular fa-users', color: 'orange' },
	Mentors: { icon: 'fa-regular fa-users', color: 'lime' },
	NewPerson: { icon: 'fa-regular fa-user-plus', color: 'peach' },
	Notes: { icon: 'fa-regular fa-sticky-note', color: 'lilac' },
	NotesFinder: { icon: 'fa-regular fa-sticky-note', color: 'mustard' },
	Discovery: { icon: 'fa-regular fa-snowboarding', color: 'mustard' },
	Participants: { icon: 'fa-regular fa-user-friends', color: 'lilac' },
	People: { icon: 'fa-regular fa-users-cog', color: 'pink' },
	PersonActivity: { icon: 'fa-regular fa-user-clock', color: 'lilac' },
	PersonSearch: { icon: 'fa-regular fa-search', color: 'orange' },
	Photo: { icon: 'fa-regular fa-grin', color: 'lavendar' },
	PitchContest: { icon: 'fa-regular fa-bullhorn', cycleColors: true }, // use color index
	PitchContests: { icon: 'fa-regular fa-bullhorn', color: 'lime' },
	Positions: { icon: 'fa-regular fa-user-tie', color: 'lime' },
	Preferences: { icon: 'fa-regular fa-user-cog', color: 'mustard' },
	Profile: { icon: 'fa-regular fa-id-card', color: 'peach' },
	Programs: { icon: 'fa-regular fa-hand-holding-heart', color: 'lavendar' },
	ProgressPitch: { icon: 'fa-regular fa-user-chart', color: 'blue' },
	Quarterlies: { icon: 'fa-regular fa-chart-pie-alt', color: 'lilac' },
	GoalsAndProgress: { icon: 'fa-regular fa-analytics', color: 'pink' },
	QuickContact: { icon: 'fa-regular fa-mail-bulk', color: 'lavendar' },
	Reading: { icon: 'fa-regular fa-book-reader', color: 'peach' },
	Relationships: { icon: 'fa-regular fa-user-friends', color: 'lilac' },
	Salesforce: { icon: 'fa-regular fa-cloud', color: 'orange' },
	Selection: { icon: 'fa-regular fa-user-check', color: 'lavendar' },
	Site: { icon: 'fa-regular fa-map-marker-smile', cycleColors: true }, // use color index
	Sites: { icon: 'fa-regular fa-map-marker-alt', color: 'orange' },
	SiteTags: { icon: 'fa-regular fa-tags', color: 'peach' },
	Specialists: { icon: 'fa-regular fa-users-crown', color: 'orange' },
	Tags: { icon: 'fa-regular fa-user-tag', color: 'orange' },
	Tasks: { icon: 'fa-regular fa-tasks', color: 'green' },
	Venues: { icon: 'fa-regular fa-map-marked-alt', color: 'green' },
	View: { icon: 'fa-regular fa-eye', color: 'lavendar' },
	Volunteering: { icon: 'fa-regular fa-hands-helping', color: 'lilac' },
	WinnerSelection: { icon: 'fa-regular fa-user-check', color: 'pink' },
}