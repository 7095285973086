export type AppAreaAccessValue = 'Invalid' | 'None' | 'Read' | 'Write';
export type AppSubAreaAccessValue = 'None' | 'Read' | 'Write';


/**
 * A minimal set of access flags used by an app area. Some app areas
 * extend this with other named access values.
 */
export interface AppAreaAccess {
	//TODO:  personId: number,
	root: AppAreaAccessValue,
	sub: {
		[subArea: string]: AppSubAreaAccessValue,
	}
}


