import { Component, Input, OnChanges } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DbsCompany } from '@me-interfaces';
import { CompanyExtractDialog } from '../SHR-CM_company-extract-dialog.dialog';


@Component({
	selector: 'company-extract-info',
	templateUrl: './SHR-CM_company-extract-info.part.html',
	styleUrls: ['./SHR-CM_company-extract-info.part.scss']
})
export class CompanyExtractInfoPart implements OnChanges {
	@Input() company: DbsCompany;
	@Input() dialogRef: MatDialogRef<CompanyExtractDialog>;

	ngOnChanges() {
		if (!this.company) throw new Error('Missing required attribute: company');
	}
}