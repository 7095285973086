import { AccAreaService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DestroyablePart } from '@me-access-parts';
import { Accelerator, DbConceptName } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { Icon, getIconContext } from '@me-shared-parts/UI-common';


@Component({
	selector: 'acc-toolbar-buttons',
	templateUrl: `./acc-toolbar-buttons.part.html`,
	styleUrls: [`./acc-toolbar-buttons.part.scss`],
})
export class AccToolbarButtonsPart extends DestroyablePart implements OnInit {

	accelerator: Accelerator;

	icons = {
		site: getIconContext(Icon.concept_site, undefined, 'always', 'Site'),
		acc: getIconContext(Icon.concept_accelerator, undefined, 'lt-md', 'Accelerator'),
		ed: getIconContext(Icon.concept_person, undefined, 'lt-sm', 'ED'),
		pm: getIconContext(Icon.concept_person, undefined, 'lt-sm', 'PM'),
		oldAdmin: getIconContext(Icon.concept_accelerator, undefined, 'lt-md', 'Old Acc Admin'),
		message: getIconContext(Icon.status_doNotContact, undefined, 'always', 'Message'),
		tutorialLinks: getIconContext(Icon.action_video, undefined, 'lt-sm', 'Tutorial Links'),
	}

	constructor(
		private router: Router,
		private ds: DataService,
		public accAreaService: AccAreaService,
	) {
		super();
	}

	ngOnInit() {

		super.initDestroyable();

		super.subscribe(
			[this.accAreaService.accelerator.acc$],
			async ([acc]) => {
				if (acc) {
					this.accelerator = acc;
					this.icons.acc.text = `${acc.siteProgram.site.code6} ${acc.name}`;
					this.icons.ed.text = `ED: ${acc.directorName}`;
					this.icons.pm.text = `PM: ${acc.managerName}`;
				}
			},
		);

	}


	async inspectSite(siteId: number) {
		if (!siteId) return;
		await this.ds.explorer.explore(DbConceptName.Site, siteId);
	}


	async inspectPerson(personId: number) {
		if (!personId) return;
		await this.ds.explorer.explore(DbConceptName.Person, personId);
	}


	async inspectAcc(accId: number) {
		if (!accId) return;
		await this.ds.explorer.explore(DbConceptName.Accelerator, accId);
	}


	goToOldAccAdmin() {
		const acc = this.accelerator;
		const siteCode = acc.siteProgram.site.code;
		this.router.navigateByUrl(`/access/admin/communities/${siteCode.toLowerCase()}/programs/accelerators/${acc.accId}/v1/overview`);
	}

	async openMessage() {
		if (!this.accelerator.siteProgram.site.isActive || this.accelerator.siteProgram.site.hidden) {
			const message = !this.accelerator.siteProgram.site.isActive ? `The accelerator site: ${this.accelerator.siteProgram.site.name} is marked inactive and can no longer be edited.` : `This accelerator's site: ${this.accelerator.siteProgram.site.name} is marked hidden. This site is not visible to end users but new applications can be created.`;

			await this.ds.dialogService.showMessage(message, 300, 230);
		}
	}

	openTutorialLink() {
		const accTutorialLink = 'https://docs.google.com/spreadsheets/d/1MlgZwZAMVnEZVzNqIOyIF2siw1ywgraCSR6Oyo7kVPQ/edit?usp=sharing';
		window.open(accTutorialLink, 'acc-tutorial');
	}

}