import { Icon } from "@me-shared-parts/UI-common";
import { IconBarIcon } from "@me-shared-parts/UI-common/icon-bar";

export const VIEW_DETAILS = 'details';
export const VIEW_PARTICIPANTS = 'participants';
export const VIEW_APPLICATIONS = 'applications';
export const VIEW_EVENTS = 'events';
export const VIEW_AWARDS = 'awards';

export const views: IconBarIcon[] = [
	{ key: VIEW_DETAILS, icon: Icon.action_more, tooltip: 'Pitch Contest Details', selected: true },
	{ key: VIEW_PARTICIPANTS, icon: Icon.concept_picTeam, tooltip: 'Participants', selected: true },
	{ key: VIEW_APPLICATIONS, icon: Icon.concept_application, tooltip: 'Applications', selected: true },
	{ key: VIEW_EVENTS, icon: Icon.concept_event, tooltip: 'Events', selected: true },
	{ key: VIEW_AWARDS, icon: Icon.concept_award, tooltip: 'Awards', selected: true },
];