import { AccAreaRawData, AccMatchableMentor, AccTeam, ApplicationStatusLog, DbaAccSessionAttendee, DbsAccSessionSurveyResponse, Person } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { calcRollups } from "./MM-rollups";
import { AccAreaData } from "./interfaces/acc-area-data";
import { cleanMatchingHints } from "./clean-matching-hints";
import { mapTeamGoals } from "./map-team-goals";

//
// transform the raw data returned from the action function to
// data that can be used by the Angular app.
//
export async function mapToData(rawData: AccAreaRawData, util: UtilityService, ds: DataService): Promise<AccAreaData> {

	const acc = await ds.admin.accelerator.getOnePackage(rawData.accId);
	const accApplicationsByAccId = await ds.admin.application.getByAccIds([rawData.accId]);
	const accApplications = accApplicationsByAccId[rawData.accId];
	const demographicsByApplicationId = rawData.application.demographicsByApplicationId;

	const applications = accApplications.map(application => {

		const demographics = demographicsByApplicationId ? demographicsByApplicationId[application.applicationId] : undefined;

		return {
			application,
			ideaText: rawData.application.ideaTexts[application.applicationId],
			demographics,
		};
	});

	const teamsByAccId = await ds.admin.accTeam.getByAccIds([rawData.accId]);
	const teams = teamsByAccId[rawData.accId];

	const people = await ds.admin.person.getManyPackagesAsMap([
		...rawData.application.statusLogs.map(sl => sl.personId),
		...rawData.mentorMatching.matchableMentors.map(mentor => mentor.personId),
		...rawData.curriculum.accTopicSpecialists.map(specialist => specialist.personId),
		...rawData.curriculum.sessionAttendees.map(attendee => attendee.attendee.personId)
	]);

	const statusLogs: ApplicationStatusLog[] = rawData.application.statusLogs
		.sort((a, b) => b.changedUTC - a.changedUTC)
		.map(sl => ({ ...sl, person: people[sl.personId].asSingleton, action: "Changed to" }));

	const events = (await ds.admin.event.getAllPackagesAsArray()).filter(event => event.accId == rawData.accId);
	const awards = (await ds.admin.award.getByAccIds([acc.accId]))[acc.accId] || [];

	const accReadersByAccId = await ds.admin.accReader.getByAccIds([rawData.accId]);
	const accReaders = accReadersByAccId[rawData.accId];

	const readers = accReaders.map(accReader => {
		return {
			reader: accReader,
			maxReads: rawData.reading.readerMaxReadsMap[accReader.accReaderId],
		};
	});

	const interviewersByAccId = await ds.admin.accInterviewer.getByAccIds([rawData.accId]);
	const interviewers = interviewersByAccId[rawData.accId];

	const judgesByAccId = await ds.admin.accJudge.getByAccIds([rawData.accId]);
	const judges = judgesByAccId[rawData.accId];

	const mentors: AccMatchableMentor[] = rawData.mentorMatching.matchableMentors.map(mentor => ({
		...mentor,
		person: people[mentor.personId],
		schedule: [mentor.mon & ~acc.accMeetingTimes.mon,
		mentor.tue & ~acc.accMeetingTimes.tue,
		mentor.wed & ~acc.accMeetingTimes.wed,
		mentor.thur & ~acc.accMeetingTimes.thur,
		mentor.fri & ~acc.accMeetingTimes.fri,
		mentor.sat & ~acc.accMeetingTimes.sat,
		mentor.sun & ~acc.accMeetingTimes.sun
		],
	}));

	const { teamsRollup, mentorsRollup } = calcRollups(acc, mentors, teams.filter(accTeam => !accTeam.droppedOutWeek), rawData.mentorMatching.assessments);
	const matchingHints = cleanMatchingHints(JSON.parse(acc.matchingHints ?? '[]'));

	const specialists = rawData.curriculum.accTopicSpecialists.map(specialist => ({ person: people[specialist.personId], topicSpecialist: specialist }));
	const accTeamMap = util.array.toMap(teams, team => team.accTeamId);


	const dbsAccSessionSurveyResponse = await ds.admin.accSessionSurveyResponse.getAllAsArray();

	const sessionAttendees = rawData.curriculum.sessionAttendees.map(sessionAttendee => ({
		accSessionAttendeeId: sessionAttendee.attendee.accSessionAttendeeId,
		attendee: sessionAttendee.attendee,
		person: people[sessionAttendee.attendee.personId],
		accTeam: accTeamMap[sessionAttendee.attendee.accTeamId],
		accSessionSurveyResponse: dbsAccSessionSurveyResponse.find(ssr => ssr.accSessionAttendeeId == sessionAttendee.attendee.accSessionAttendeeId),
	}));



	return {

		acc,
		events,
		awards,

		application: {
			applications,
			statusLogs,
			includesDemographics: !!demographicsByApplicationId,
		},

		reading: {
			readers,
			assessments: rawData.reading.assessments,
			defaultQuestions: rawData.reading.defaultQuestions,
		},

		interviewing: {
			interviewers,
			interviewerEvents: rawData.interviewing.interviewerEvents,
			assessments: rawData.interviewing.assessments,
			defaultQuestions: rawData.interviewing.defaultQuestions,
		},

		mentorMatching: {
			matchableMentors: mentors,
			assessments: rawData.mentorMatching.assessments,
			teamsRollup,
			mentorsRollup,
			hints: matchingHints,
			v2Schedules: rawData.mentorMatching.v2Schedules,
		},

		team: {
			teams,
			goals: mapTeamGoals(rawData, acc, teams),
		},

		judging: {
			judges,
			judgeEvents: rawData.judging.judgeEvents,
			assessments: rawData.judging.assessments,
			defaultQuestions: rawData.judging.defaultQuestions,
		},

		curriculum: {
			sessions: rawData.curriculum.sessions,
			sessionTopics: rawData.curriculum.sessionTopics,
			accTopics: rawData.curriculum.accTopics,
			topicSpecialists: specialists,
			sessionAttendees,
		},
	};
}