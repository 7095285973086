import { Answer, AppAreaApplyResponse, AppAreaFuncResponse, AppAreaIdentifierWithSite, AppAreaIdentifierWithSiteId } from "@me-interfaces";
import { AppSubArea } from "@me-services/core/area";
import { DataService } from "@me-services/core/data";
import { FuncService } from "@me-services/core/func";
import { UtilityService } from "@me-services/core/utility";


export class AccSubAreaReading extends AppSubArea {

	constructor(
		private func: FuncService,
		ds: DataService,
		util: UtilityService,
		getId: () => AppAreaIdentifierWithSiteId<number>,
		applyResponse: (response: AppAreaFuncResponse) => Promise<AppAreaApplyResponse>,
	) {
		super(ds, util, getId, applyResponse);
	}

	async removeReader(parameters: { accReaderId: number }) {
		return await this.call(this.func.areas.acc.reading.deleteReader, parameters);
	}

	async setReaders(parameters: { personIds: number[] }) {
		return await this.call(this.func.areas.acc.reading.setReaders, parameters);
	}

	async setMaxReads(parameters: { accReaderId: number, maxReads: number }) {
		return await this.call(this.func.areas.acc.reading.setMaxReads, parameters);
	}
	
	async setPolishedFeedbackSkipped(parameters: { accReaderId: number, applicationId: number, polishedFeedbackSkipped: boolean }) {
		return await this.call(this.func.areas.acc.reading.setPolishedFeedbackSkipped, parameters);
	}

	async setPolishedFeedback(parameters: { accReaderId: number, applicationId: number, polishedFeedback: string }) {
		return await this.call(this.func.areas.acc.reading.setPolishedFeedback, parameters);
	}

	async setAssessment(parameters: { accReaderId: number, applicationId: number, assessment: { rating: number, answers: Answer[], } }) {
		return await this.call(this.func.areas.acc.reading.setAssessment, parameters);
	}

}