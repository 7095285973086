<div class='explorer-part accelerator-explorer-part'>

	<div class="icon-bar">
		<me-icon-bar #iconBar [icons]='views' [orientation]="'Vertical'" (selectionsChange)='setViews($event)'>
		</me-icon-bar>
	</div>

	<div class="content">
		<ng-container *ngIf='selectedViews'>
			<div class="explorer-header">
				<me-icon [icon]="icons.venue"></me-icon>
			</div>
			<h4 class="explorer-sub-header">Venue</h4>

			<section>
				<explorer-buttons [buttons]='buttons'></explorer-buttons>
			</section>

			<explorer-section header="Venue Details" (tabClose)="tabClose($event)"
				[style.display]="getDisplay('details')">
				<venue-explorer-details [venue]="venue"></venue-explorer-details>
			</explorer-section>

			<explorer-section header="Events" (tabClose)="tabClose($event)" [style.display]="getDisplay('events')">
				<venue-explorer-events [venue]="venue"></venue-explorer-events>
			</explorer-section>
		</ng-container>
	</div>
</div>