import { EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { DbConceptName, DbsCompany, Event } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { ShowEventDialogService } from "@me-shared-parts/ED-editors";
import { Icon } from "@me-shared-parts/UI-common/icon";
import { EXPLORE_GRID_ACTION_KEY, GridColumnType, GridSetup, GRID_EXPERIENCE, OPEN_GRID_ACTION_KEY } from "../interfaces";
import { GridExperience } from "./grid-experience";

//
// Column keys
//
const AWARD_ID = 'AWARD_AWARDId';
const AWARD_NAME = 'AWARD_name';
const AWARD_COMPANY = 'AWARD_company';
const AWARD_VALUE = 'AWARD_value';
const AWARD_KIND = 'AWARD_kind';
const AWARD_DECIDEDBY = 'AWARD_decidedBy';
const AWARD_AWARDEENAMES = 'AWARD_awardeeNames';
const AWARD_AWARDEEEMAILS = 'AWARD_awardeeEmails';
const AWARD_EVENTID = 'AWARD_eventId';
const AWARD_EVENTTYPE = 'AWARD_eventType';
const AWARD_EVENTSTATUS = 'AWARD_eventStatus';
const AWARD_EVENTSTART = 'AWARD_eventStart';
const AWARD_EVENTEND = 'AWARD_eventEnd';
const AWARD_EVENTVENUE = 'AWARD_eventVenue';


/**
 * Standard experience for a grid of people
 */
export class AwardGridExperience<ROW> extends GridExperience<ROW> {

	public static readonly experience: GRID_EXPERIENCE = 'AWARD';

	constructor(
		private ds: DataService,
		private util: UtilityService,
		setup: GridSetup<ROW>,
		gridAction: EventEmitter<{ actionKey: string; rows: ROW[]; }>,
		private router: Router,
		private showEventDialogService: ShowEventDialogService,
	) {
		super(
			util, setup,
			[
				{ field: AWARD_ID, header: 'awardId', width: 80, type: GridColumnType.number, hidden: true },
			],
			[
				{ field: AWARD_COMPANY, header: 'Business', width: 200, type: GridColumnType.text },
				{ field: AWARD_EVENTTYPE, header: 'Event', width: 120, type: GridColumnType.text },
				{ field: AWARD_EVENTSTATUS, header: 'Status', width: 100, type: GridColumnType.text },
				{ field: AWARD_EVENTSTART, header: 'Event Start', width: 130, type: GridColumnType.dateAndTimeUtc },
				{ field: AWARD_EVENTEND, header: 'Event End', width: 130, type: GridColumnType.dateAndTimeUtc, hidden: true },
				{ field: AWARD_EVENTVENUE, header: 'Event Venue', width: 180, type: GridColumnType.text, hidden: true },
				{ field: AWARD_NAME, header: 'Award', width: 150, type: GridColumnType.text },
				{ field: AWARD_VALUE, header: 'Value', width: 80, type: GridColumnType.dollars },
			],
			[
				{ field: AWARD_KIND, header: 'Kind', width: 160, type: GridColumnType.text, hidden: true },
				{ field: AWARD_DECIDEDBY, header: 'Decided By', width: 180, type: GridColumnType.text, hidden: true },
				{ field: AWARD_AWARDEENAMES, header: 'Awardee Names', width: 220, type: GridColumnType.text, hidden: true },
				{ field: AWARD_AWARDEEEMAILS, header: 'Awardee Emails', width: 240, type: GridColumnType.text, hidden: true },
			],
			[
				{ key: EXPLORE_GRID_ACTION_KEY, icon: Icon.dialog_explore, label: `Explore ${setup.rowSingularName}` },
				{ key: OPEN_GRID_ACTION_KEY, icon: Icon.action_open, label: `Open ${setup.rowSingularName}` },
				// { key: NOTES_GRID_ACTION_KEY, icon: Icon.action_editNotes, label: `Edit Award Notes` },
			],
			gridAction,
			Icon.concept_event,
		);
	}


	public async applyBaseValues(rows: ROW[]) {

		const awardMap = await this.ds.admin.award.getManyPackagesAsMap(rows.map(row => row['awardId']));

		let awardeeNames = '';
		let awardeeEmails = '';
		let company: DbsCompany;

		for (const row of rows) {
			const awardId = row['awardId'];
			const award = awardMap[awardId];

			if (award.accTeamId) {
				company = award.accTeam.application.company;
				const members = award.accTeam.members.filter(m => m.member.role == 'E');
				awardeeNames = members.map(member => member.person._name).sort((a, b) => a > b ? 1 : -1).join(', ');
				awardeeEmails = members.map(member => member.person._email).sort((a, b) => a > b ? 1 : -1).join(', ');
			}
			if (award.picTeamId) {
				company = award.picTeam.application.company;
				awardeeNames = award.picTeam.members.map(member => member.person._name).sort((a, b) => a > b ? 1 : -1).join(', ');
				awardeeEmails = award.picTeam.members.map(member => member.person._email).sort((a, b) => a > b ? 1 : -1).join(', ');
			}


			row[AWARD_ID] = award.awardId;
			row[AWARD_NAME] = this.ds.domain.awardName.getOne(award.awardNameId).name;
			row[AWARD_COMPANY] = company.longName;
			row[AWARD_KIND] = this.ds.domain.awardKind.getOne(award.awardKindId).name;
			row[AWARD_DECIDEDBY] = this.ds.domain.decidingRole.getOne(award.decidingRoleId).name;
			row[AWARD_VALUE] = award.value;
			row[AWARD_AWARDEENAMES] = awardeeNames;
			row[AWARD_AWARDEEEMAILS] = awardeeEmails;
			row[AWARD_EVENTID] = award.eventId;
			row[AWARD_EVENTTYPE] = this.ds.domain.eventType.getOne(award.event.eventTypeId).name;
			row[AWARD_EVENTVENUE] = award.event.venue?.displayedName || 'Online';
			row[AWARD_EVENTSTATUS] = this.util.event.getStatus(award.event);
			row[AWARD_EVENTSTART] = award.event.startUTC;
			row[AWARD_EVENTEND] = award.event.endUTC;
			row['event'] = award.event;
		}
	}


	public sortRows(rows: ROW[]) {
		rows.sort((row1: ROW, row2: ROW) => {
			const val1 = row1[AWARD_COMPANY];
			const val2 = row2[AWARD_COMPANY];
			return val1 < val2 ? -1 : 1;
		});
	}


	public async explore(row: ROW) {
		await this.ds.explorer.explore(DbConceptName.Event, row[AWARD_EVENTID]);
	}


	public async open(row: ROW) {
		const teamsForAward = [];
		const event: Event = row['event'];
		if (this.ds.domain.awardNameEventType.getAll().find(a => a.eventTypeId == event.eventTypeId)) {
			if (event.accId) {

				const accTeamsByAccId = await this.ds.admin.accTeam.getByAccIds([event.accId]);
				const accTeams = accTeamsByAccId[event.accId];

				for (const accTeam of accTeams) {
					const company = accTeam.application.company;
					teamsForAward.push({ teamId: accTeam.accTeamId, text: company.longName, type: 'accTeam' });
				}
			}
			else if (event.picId) {

				const picTeamsByPicId = await this.ds.admin.picTeam.getByPicIds([event.picId]);
				const picTeams = picTeamsByPicId[event.picId];

				for (const picTeam of picTeams) {
					const company = picTeam.application.company;
					teamsForAward.push({ teamId: picTeam.picTeamId, text: company.longName, type: 'picTeam' });
				}
			}
		}
		await this.showEventDialogService.edit(event, false, teamsForAward);
	}


	public async editNotes(row: ROW) {
		// Notes dialog not implemented
	}
}