import { DbsAccSessionSpecialistSurveyResponse } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { SurveyCountsData } from "./get-survey-counts-data";
import { CurriculumSurveySpecialistCounts } from "./interfaces";


/**
 *
 * Given an array of survey results, calculate roll up values
 *
 * @param personId - The personId of the specialist
 * @param name - The name of the specialist
 * @param surveys - The personId is the attendee personId for the survey
 * @param attendeeByAttendeeId - Each of the session attendees in a map to lookup by attendee id
 */
export function calcSpecialistSurveyCounts(
	util: UtilityService,
	personId: number,
	name: string,
	surveys: DbsAccSessionSpecialistSurveyResponse[],
	data: SurveyCountsData,

) {

	const counts: CurriculumSurveySpecialistCounts = {
		name,
		personId,
		professionalismScore: util.nps.calcScore(surveys.map(s => s.professionalism)),
		knowledgeScore: util.nps.calcScore(surveys.map(s => s.knowledge)),
		effectivenessScore: util.nps.calcScore(surveys.map(s => s.effectiveness)),
		comments: surveys
			.filter(s => s.other?.trim().length > 0)
			.map(s => ({
				comment: s.other.trim(),
				entrepreneurName: data.attendeeByAttendeeId[s.accSessionAttendeeId]?.person.fullName ?? 'MISSING ATTENDEE',
				personId: data.attendeeByAttendeeId[s.accSessionAttendeeId]?.person.personId,
			}))

	};

	return counts;
}
