<me-h3>Positions</me-h3>

<div class='company-extract-positions-part' *ngIf='positions && positions.length'>
	<ng-container *ngFor='let position of positions'>
		<div class='position-grid'>
			<div class='position-details'>
				<div class='person-avatar'>
					<me-avatar [avatar]='position.person'></me-avatar>
				</div>
				<div class='person-info'>
					<a class='link' (click)="openPerson(position)">{{position.person._name}}</a>
					<span class='person-title'>{{position.position.title}}</span>
					<span>
						<ng-container *ngIf='position.position.phone'>
							<phone-link [phone]='position.position' [extension]='position.position.extension'>
							</phone-link>
						</ng-container>
						<ng-container *ngIf='!position.position.phone'>
							<small><i>No Office Phone provided.</i></small>
						</ng-container>
					</span>
				</div>
			</div>
		</div>
	</ng-container>
</div>
<div *ngIf='!positions || !positions.length'>No positions.</div>