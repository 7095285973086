import { EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { Icon } from "@me-shared-parts/UI-common";
import { buildColumn, GridColumnConfig, ZipCols } from "../columns";
import { GRID_EXPERIENCE, GridSetup } from "../interfaces";
import { GridExperience } from "./grid-experience";


/**
 * Standard experience for a grid of zips
 */
export class ZipGridExperience<ROW> extends GridExperience<ROW> {

	public static readonly experience: GRID_EXPERIENCE = 'ZIP';

	constructor(
		private ds: DataService,
		private util: UtilityService,
		setup: GridSetup<ROW>,
		gridAction: EventEmitter<{ actionKey: string; rows: ROW[]; }>,
		private router: Router,
	) {
		super(
			util, setup,
			[
				buildColumn(ZipCols.zipId, GridColumnConfig.id, { hidden: true, header: 'zipId' }),
				buildColumn(ZipCols.cityId, GridColumnConfig.id, { hidden: true, header: 'cityId' }),
			],
			[
				buildColumn(ZipCols.cityAndState, GridColumnConfig.cityAndState),
				buildColumn(ZipCols.city, GridColumnConfig.text, { hidden: true, header: 'City' }),
				buildColumn(ZipCols.state, GridColumnConfig.text, { hidden: true, header: 'State' }),
				buildColumn(ZipCols.zipCode, GridColumnConfig.zipCode),
				buildColumn(ZipCols.enSite, GridColumnConfig.text, { header: 'English Site', hidden: true, headerTooltip: 'Site able to offer English programming for this Zip Code' }),
				buildColumn(ZipCols.esSite, GridColumnConfig.text, { header: 'Spanish Site', hidden: true, headerTooltip: 'Site able to offer Spanish programming for this Zip Code' }),
			],
			[
				buildColumn(ZipCols.longitude, GridColumnConfig.text, { hidden: true, header: 'Longitude' }),
				buildColumn(ZipCols.latitude, GridColumnConfig.text, { hidden: true, header: 'Latitude' }),
				buildColumn(ZipCols.timeZone, GridColumnConfig.text, { hidden: true, header: 'Timezone' }),
			],
			[
				// { key: EXPLORE_GRID_ACTION_KEY, icon: Icon.dialog_explore, label: `Explore ${setup.rowSingularName}` },
				// { key: OPEN_GRID_ACTION_KEY, icon: Icon.action_open, label: `Open ${setup.rowSingularName}` },
				// { key: NOTES_GRID_ACTION_KEY, icon: Icon.action_editNotes, label: `Edit Zip Notes` },
			],
			gridAction,
			Icon.concept_zip,
		);
	}


	public async applyBaseValues(rows: ROW[]) {

		const zipMap = await this.ds.domain.zip.getAllAsMap();
		const timeZoneMap = await this.ds.domain.timeZone.getAllAsMap();
		const siteMap = await this.ds.admin.site.getAllAsMap();

		for (const row of rows) {

			const zipId = row['zipId'];
			const zip = zipMap[zipId];


			row[ZipCols.zipId] = zip.zipId;
			row[ZipCols.cityId] = zip.cityId;
			row[ZipCols.zipCode] = zip.code;
			row[ZipCols.cityAndState] = zip.cityAndState;
			row[ZipCols.city] = zip.city;
			row[ZipCols.state] = zip.state;
			row[ZipCols.longitude] = zip.longitude;
			row[ZipCols.latitude] = zip.latitude;
			row[ZipCols.enSite] = zip.enSiteId ? siteMap[zip.enSiteId]?.name : '';
			row[ZipCols.esSite] = zip.esSiteId ? siteMap[zip.esSiteId]?.name : '';
			row[ZipCols.timeZone] = zip.timeZoneId ? timeZoneMap[zip.timeZoneId]?.name : '';

		}
	}


	public sortRows(rows: ROW[]) {
		rows.sort((row1: ROW, row2: ROW) => {
			const val1 = row1[ZipCols.cityAndState];
			const val2 = row2[ZipCols.cityAndState];
			return val1 < val2 ? -1 : 1;
		});
	}


	public async explore(row: ROW) {
		// await this.ds.explorer.explore(DbConceptName.AccTeam, row[AccTeamCols.accTeamId]);
	}


	public async open(row: ROW) {
		// this.router.navigate([`access/admin/national/sites/${row['siteId']}/accelerators/${row['accId']}/cohort/${row[AccTeamCols.accTeamId]}`]);
	}


	public async editNotes(row: ROW) {
		// Notes dialog not implemented
	}
}