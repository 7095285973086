import { Component, HostListener, Input, OnChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TitledPersonGridRow } from '@me-interfaces';

/**
 * This part displays the avatar, fullname and title of a person, optionally the badges(founder, admin, default position).
 * in the big table form or small list form
 */
@Component({
	selector: 'titled-person-grid',
	templateUrl: './SHR-CMN_titled-person-grid.part.html',
	styleUrls: ['SHR-CMN_titled-person-grid.part.scss'],
})
export class TitledPersonGridPart implements OnChanges {

	@Input() rows: TitledPersonGridRow[];
	@Input() showBadges = true;
	@Input() showHeader = true;
	@Input() smallGrid = false;

	displaySmallGrid = false;
	dataSource: MatTableDataSource<TitledPersonGridRow>;
	displayedColumns = ['name'];
	constructor(private router: Router) { }

	ngOnChanges() {
		if (!this.smallGrid) this.displayedColumns.push('title');
		if (this.showBadges) this.displayedColumns.push('roles');

		window.dispatchEvent(new Event('resize'));
	}

	navigateToPosition(routerLink: string) {
		this.router.navigate([routerLink]);
	}

	@HostListener('window:resize', ['$event'])
	onWindowResize(event?) {
		if (window.innerWidth < 1000) {
			this.displayedColumns = this.showBadges ? ['name', 'roles'] : ['name'];
			this.displaySmallGrid = true;
		}
		else {
			this.displayedColumns = ['name'];
			if (!this.smallGrid) this.displayedColumns.push('title');
			if (this.showBadges) this.displayedColumns.push('roles');
			this.displaySmallGrid = false;
		}
	}

}
