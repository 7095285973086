import { AppAreaApplyResponse, AppAreaFuncResponse, AppAreaIdentifier, SpecialistStatus } from "@me-interfaces";
import { AppSubArea } from "@me-services/core/area";
import { DataService } from "@me-services/core/data";
import { FuncService } from "@me-services/core/func";
import { UtilityService } from "@me-services/core/utility";

export class SiteSubAreaRoles extends AppSubArea {

	constructor(
		private func: FuncService,
		ds: DataService,
		util: UtilityService,
		getId: () => AppAreaIdentifier<string>,
		applyResponse: (response: AppAreaFuncResponse) => Promise<AppAreaApplyResponse>,
	) {
		super(ds, util, getId, applyResponse);
	}


	async setSiteFollowerTag(parameters: { personId: number, tagFullName: string }) {
		return await this.call(this.func.areas.siteOld.roles.setSiteFollowerTag, parameters);
	}

	async setSiteRoleTag(parameters: { personId: number, tagFullName: string }) {
		return await this.call(this.func.areas.siteOld.roles.setSiteRoleTag, parameters);
	}

	async setSpecialist(parameters: { personId: number, topicId: number, languageId: number, status: SpecialistStatus, note: string }) {
		return await this.call(this.func.areas.siteOld.roles.setSpecialist, parameters);
	}

	async removeSpecialist(parameters: { specialistId: number }) {
		return await this.call(this.func.areas.siteOld.roles.removeSpecialist, parameters);
	}

}