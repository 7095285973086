export const enum CompanyTypeId {
	IdeaForProfit = 1,
	IdeaNotForProfit = 2,
	SoleProprietor = 3,
	LLC = 4,
	Partnership = 5,
	CCorp = 6,
	SCorp = 7,
	BCorp = 8,
	NonProfit_501C3 = 9,
	NonProfit_other = 10,
	Government = 11,
	Other = 12,
}