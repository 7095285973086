import { DbsPicReader, PicReader, PitchContest } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { Observable } from "rxjs";
import { DomainDataManagers } from "../interfaces/domain-data-managers";
import { PackageManager } from "../package-manager";
import { SingletonsManager } from "../singletons-manager";
import { PersonPackageManager } from "./person";
import { PitchContestPackageManager } from "./pitch-contest";

export class PicReaderPackageManager extends PackageManager<DbsPicReader, PicReader> {

	constructor(
		singletonsAsOfUTC$: Observable<number>,
		util: UtilityService,
		sm: SingletonsManager<DbsPicReader>,
		private domain: DomainDataManagers,
		private person: PersonPackageManager,
		private pitchContest: PitchContestPackageManager,
	) {
		super(singletonsAsOfUTC$, util, sm);
	}


	/**
	 * Convert an array of DbcPicReader to an array of PicReader
	 */
	protected async _createPackages(dbsPicReaders: DbsPicReader[]): Promise<PicReader[]> {

		//
		// Get all the people and companies
		//
		const personIds: number[] = [];
		const pitchContestIds: number[] = [];

		for (const picReader of dbsPicReaders) {
			personIds.push(picReader.personId);
			personIds.push(picReader.updatedByPersonId);
			pitchContestIds.push(picReader.picId);
		}

		const personMap = await this.person.getManyPackagesAsMap(personIds);
		const pitchContestMap = await this.pitchContest.getManyPackagesAsMap(pitchContestIds);


		//
		// Package 'em up
		//
		const picReaders: PicReader[] = dbsPicReaders.map(dbsPicReader => {

			const pitchContest = pitchContestMap[dbsPicReader.picId];
			const person = personMap[dbsPicReader.personId];
			const updatedByPerson = personMap[dbsPicReader.updatedByPersonId];


			return {
				...dbsPicReader,
				pitchContest,
				person,
				updatedByPersonName: updatedByPerson?.name || `Person #${dbsPicReader.updatedByPersonId}`,
				appUrl: this.createAppUrl(pitchContest),
			};
		});

		return picReaders;
	}


	private createAppUrl(pic: PitchContest): string {
		const picId = pic.picId;
		const siteCode = pic.siteProgram.site.code;
		return `/access/admin/communities/${siteCode.toLowerCase()}/programs/pitch-contests/${picId}/events/reading/event`;
	}

	
	/**
	 * Get all pic reads for each personId provided
	 */
	public async getByPersonIds(personIds: readonly number[]): Promise<Readonly<Record<number, ReadonlyArray<PicReader>>>> {

		return await this.getPackagesAsArraysByForeignIds('personId', personIds);
	}



	/**
	 * Get all pic readers for each picId provided
	 */
	public async getByPicIds(picIds: readonly number[]): Promise<Record<number, ReadonlyArray<PicReader>>> {

		return await this.getPackagesAsArraysByForeignIds('picId', picIds);
	}

}