import { Injectable } from '@angular/core';
import { PageTabsLayoutPageService } from '@me-shared-parts/UI-common';
import { BehaviorSubject } from 'rxjs';


/**
 * The list of tabs on this page
 */
export enum AccOverviewPageTabId {
	Accelerator = 1,
	UserTools = 2,
	Applications = 3,
}

/**
 * The list if main tab droplist views on this page
 */
export enum AccOverviewPageViewId {
	Details = 1,
	AllApplications = 2,
	AllEvents = 3,
	AllAwards = 4,
	AllMissingSignatures = 5,
}

export type ApplicationStatusFilter = 'All' | 'Open' | 'Not Accepted' | 'Accepted';

@Injectable({ providedIn: 'root' })
export class AccOverviewPageService extends PageTabsLayoutPageService<AccOverviewPageTabId, AccOverviewPageViewId> {

	public readonly applicationsStatusFilter$ = new BehaviorSubject<ApplicationStatusFilter>('All');

	constructor() {
		super(AccOverviewPageService.name, AccOverviewPageTabId.Accelerator, AccOverviewPageViewId.Details);
	}

}