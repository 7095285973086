export type UnemploymentStatus = 'N' | 'Y' | 'R';
/* 
'N' = Not Unemployed (Employed)
'Y' = Unemployed
'R' = Retired
*/

export const enum EmploymentStatusId {
	Unemployed = 1,
	Underemployed = 2,
	Employed = 3,
	Retired = 4,
}