import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LabelKey } from '@me-interfaces';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';
import { LabelsService } from '@me-services/ui/labels';
import { MessageDialog } from '..';

@Component({
	selector: 'confirmation-dialog',
	templateUrl: './SHR-CMN_confirmation.dialog.html',
	styleUrls: ['./SHR-CMN_confirmation.dialog.scss']
})
export class ConfirmationDialog implements OnInit {

	message: string | LabelKey;
	bullets: string[];
	header = 'Confirm';
	translateMessage = false;

	yesAction: DialogAction = {
		id: 'yes',
		enabled: true,
		visible: true,
		label: 'Yes',
		linkType: "no-action",
		willCloseDialog: true,
	};

	noAction: DialogAction = {
		id: 'no',
		enabled: true,
		visible: true,
		label: 'No',
		linkType: "no-action",
		willCloseDialog: true,
	};

	actions: DialogAction[] = [this.yesAction, this.noAction];

	constructor(
		@Inject(MAT_DIALOG_DATA) public dialog: DialogContext<{ messageOrLabelKey: string | LabelKey, yesButtonText?: string | LabelKey, noButtonText?: string | LabelKey, bullets: string[] }>,
		public dialogRef: MatDialogRef<MessageDialog>,
		public labels: LabelsService,
	) {
	}

	async ngOnInit() {
		if (this.dialog.data)
		{
			this.message = this.dialog.data.messageOrLabelKey || '';
			this.bullets = this.dialog.data.bullets;
		}
		this.translateMessage = typeof this.message !== 'string';
		this.header = await this.labels.get({ key: 'Confirm' });
		this.yesAction.label = this.dialog.data?.yesButtonText ? this.dialog.data.yesButtonText : ':Yes';
		this.noAction.label = this.dialog.data?.noButtonText ? this.dialog.data.noButtonText : ':No';
	}
}
