import { AccAreaService, AccOverviewPageService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { ADDROW_GRID_ACTION_KEY, GridSetup } from '@me-grid';
import { Award } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Component({
	selector: 'acc-all-awards-view-part',
	templateUrl: './all-awards-view.part.html',
})
export class AccAllAwardsViewPart extends DestroyablePart implements OnInit {

	public gridSetup = this.setupGrid();
	public rows$: Observable<Award[]>;

	constructor(
		public ds: DataService,
		public accAreaService: AccAreaService,
		private pageService: AccOverviewPageService,
	) {
		super();
	}


	async ngOnInit() {
		super.initDestroyable();

		this.rows$ = this.accAreaService.accelerator.awards$.pipe(map(awards => this.buildRows(awards)));
	}

	buildRows(awards: readonly Award[]) {
		if (!awards) return [];
		return [...awards];
	}


	private setupGrid(): GridSetup<Award> {
		return {
			experience: 'AWARD',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				shrinkBy: 0,
				heightMultiplier: 1,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Award",
			rowPluralName: "Awards",
			rowKey: "awardId",
			stateKey: "all-acc-awards",
			canAdd: true,
			canRefresh: false,
			canDownload: true,
			initialState: { sort: [{ field: 'AWARD_eventStart', dir: 'asc' }] },

		};
	}


	/**
	 * Handle events from the grid
	 */
	async gridActionHandler(action: { actionKey: string, rows: Award[] }) {

		if (action.actionKey == ADDROW_GRID_ACTION_KEY) {
			await this.ds.dialogService.showMessage(`This is a read-only list of all awards. Awards can be added to appropriate stages under the Cohort tab.`, 320, 200);
		}
	}

}