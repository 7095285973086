import { Component, OnChanges, Input } from '@angular/core';

@Component({
	selector: 'ratings-four',
	templateUrl: './SHR-CMN_ratings-four.part.html',
	styleUrls: ['SHR-CMN_ratings-four.part.scss'],
})
export class RatingsFourPart implements OnChanges {

	@Input() ratings: number | number[];
	@Input() flashTip1 = '';
	@Input() flashTip2 = '';
	@Input() flashTip3 = '';
	@Input() flashTip4 = '';

	r1: number;
	r2: number;
	r3: number;
	r4: number;

	ngOnChanges() {
		let r = this.ratings;
		if (typeof r == 'number') {
			const rating = [0, 0, 0, 0];
			rating[r - 1] = 1;
			r = rating;
		}
		this.r1 = r.length > 0 ? r[0] : 0;
		this.r2 = r.length > 1 ? r[1] : 0;
		this.r3 = r.length > 2 ? r[2] : 0;
		this.r4 = r.length > 3 ? r[3] : 0;
	}

	getClass() {
		if (this.r1) return 'rating score-1';
		else if (this.r2) return 'rating score-2';
		else if (this.r3) return 'rating score-3';
		else if (this.r4) return 'rating score-4';
	}
}
