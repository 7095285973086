import { DbConceptName, Region } from "@me-interfaces";
import { DataService } from "../../../../data.service";
import { ExplorerBox, ExplorerBoxClass } from "../../../explorer-box/explorer-box";


export async function getBoxesForSites(ds: DataService, region: Region): Promise<ExplorerBox[]> {

	const boxes: ExplorerBox[] = [];

	const sitesByRegionId = await ds.admin.site.getByRegionIds([region.regionId]);
	const sites = [...sitesByRegionId[region.regionId]].sort((a, b) => a.name > b.name ? 1 : -1);

	for (const site of sites) {

		const box: ExplorerBox = {
			explorerBoxClass: ExplorerBoxClass.Site,
			header: `${site.name}`,
			buttons: [
				{ explorer: { explorableId: { conceptName: DbConceptName.Site, id: site.siteId }, navTooltip: `Site: ${site.name}` } },
				{ explorer: { nameOverride: `ED: ${site.directorName}`, explorableId: { conceptName: DbConceptName.Person, id: site.directorId }, navTooltip: `ED: ${site.directorName}` } },
			],
			notes: `${site.code6}`,
		};

		boxes.push(box);
	}

	return boxes;
}