import { Component, Input, OnChanges } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { AccMatchingTeam } from './acc-matching-team';


@Component({
	selector: 'acc-matching-teams-part',
	templateUrl: './acc-matching-teams.part.html',
	styleUrls: ['./acc-matching-teams.part.scss'],
})
export class AccMatchingTeamsPart implements OnChanges {

	@Input() teams: AccMatchingTeam[];

	teams$ = new ReplaySubject<AccMatchingTeam[]>(1);


	ngOnChanges() {
		if (this.teams) this.teams$.next(this.teams);
	}


}
