import { Component, Input, OnInit } from '@angular/core';
import { Accelerator } from '@me-interfaces';
import { UtilityService } from '@me-services/core/utility';

@Component({
	selector: 'acc-details-part',
	templateUrl: './acc-details.part.html',
})
export class AccDetailsPart {
	@Input() accelerator: Accelerator;
	@Input() readonly: boolean;

	constructor(public util: UtilityService) {
	}


}