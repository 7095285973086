<div class='company-basic-info-editor-part'>
	<me-save-changes-message *ngIf='messagedisplay' [hidden]="messagedisplay.disabled"></me-save-changes-message>
	<div class='company-basic-info-editor-content'>
		<form [formGroup]="form" novalidate>
			<table class='pro-table pro-table-no-header pro-table-with-labels'>
				<tr>
					<td>
						<span>Name</span> </td>
					<td>
						<input class="k-textbox" placeholder="Name" formControlName="longName" required
							(blur)='constants.blur.fixCaseOfValue(form.controls.longName)'>
						<div fxLayout="row" *ngIf='form.controls.longName.errors' class="k-tooltip-validation">
							<div *ngIf='form.controls.longName.errors.required'>
								Required field </div>
							<div *ngIf='form.controls.longName.errors.maxlength'>
								{{tooManyCharacters(form.controls.longName)}} </div>
						</div>
					</td>
				</tr>

				<tr>
					<td>
						<span>Short Name</span>
					</td>
					<td>
						<input class="k-textbox" placeholder="Short Name" formControlName="shortName"
							(blur)='constants.blur.fixCaseOfValue(form.controls.shortName)' />
						<div fxLayout="row"
							*ngIf='form.controls.shortName.errors && form.controls.shortName.errors.maxlength'
							class="k-tooltip-validation">
							{{tooManyCharacters(form.controls.shortName)}} </div>
						<small><i>
								optional
							</i></small>
					</td>
				</tr>


				<tr>
					<td>
						Org Type
					</td>
					<td>
						<kendo-dropdownlist formControlName="companyTypeId" [data]="companyTypes"
							[textField]="'name'" [valueField]="'companyTypeId'" [valuePrimitive]="true">
							<ng-template kendoDropDownListValueTemplate let-companyType>
								{{companyType?.name}} </ng-template>
							<ng-template kendoDropDownListItemTemplate let-companyType>
								{{companyType?.name}}<br>
							</ng-template>
						</kendo-dropdownlist>
					</td>
				</tr>

				<tr>
					<td>
						Industry
					</td>
					<td>
						<kendo-dropdownlist formControlName="industryId" [data]="industries"
							[textField]="'name'" [valueField]="'industryId'" [valuePrimitive]="true">
							<ng-template kendoDropDownListValueTemplate let-industry>
								{{industry?.name}}<br>
							</ng-template>
							<ng-template kendoDropDownListItemTemplate let-industry>
								{{industry?.name}}<br>
							</ng-template>
						</kendo-dropdownlist>
					</td>
				</tr>


				<tr>
					<td>
						Offerings </td>
					<td>
						<kendo-dropdownlist formControlName="offerings" [data]="offerings">
							<ng-template kendoDropDownListValueTemplate let-offering>
								{{offering}}
							</ng-template>
							<ng-template kendoDropDownListItemTemplate let-offering>
								{{offering}} </ng-template>
						</kendo-dropdownlist>
					</td>
				</tr>

				<tr>
					<td>
						Status
					</td>
					<td>
						<kendo-dropdownlist formControlName="status" [data]="statuses">
							<ng-template kendoDropDownListValueTemplate let-status>
								{{status}}<br>
							</ng-template>
							<ng-template kendoDropDownListItemTemplate let-status>
								{{status}}<br>
							</ng-template>
						</kendo-dropdownlist>
					</td>
				</tr>

				<tr>
					<td>
						Phone
					</td>
					<td>
						<div class="intl-phone">
							<input class="k-textbox" placeholder="Business Phone Number" formControlName="phone"
								id="phone" type="tel"
								(blur)='constants.blur.fixPhoneValue(form.controls.phone, telInput)' />
							<div fxLayout="row" *ngIf='form.controls.phone.errors' class="k-tooltip-validation">
								<div *ngIf='form.controls.phone.errors.invalid'>
									Invalid Phone Number
								</div>
								<!-- <div *ngIf='form.controls.phone.errors.phoneInUse'>
									Phone already used
								</div> -->
							</div>
						</div>
					</td>
				</tr>
				<tr>
					<td>
						Phone Type </td>
					<td>
						<kendo-dropdownlist formControlName="phoneTypeId" [data]="phoneTypes"
							[textField]="'name'" [valueField]="'phoneTypeId'" [valuePrimitive]="true"
							[itemDisabled]="itemDisabled"
							[disabled]="!form.controls.phone.value || form.controls.phoneTypeId.value === 4">
							<ng-template kendoDropDownListValueTemplate let-phoneType>
								{{phoneType?.name}}<br>
							</ng-template>
							<ng-template kendoDropDownListItemTemplate let-phoneType>
								{{phoneType?.name}}<br>
							</ng-template>
						</kendo-dropdownlist>
					</td>
				</tr>

			</table>

			<button-container>
				<spinner-button (safeClick)='resetForm()' [disabled]='!form.dirty || updating'>Revert</spinner-button>
				<spinner-button #messagedisplay (safeClick)='save()'
					[disabled]='form.invalid || !form.dirty || updating' [spinning]='updating'>Save
					Changes
				</spinner-button>
			</button-container>

		</form>
	</div>
</div>