<me-dialog-frame [header]='headerText' [actions]='actions' [dialogRef]='dialogRef' [icon]='icon' alertType='editor'>
	<div class='acc-goals-add-dialog-content'>
		<table style='width:100%' class='pro-table pro-table-no-header pro-table-with-labels pro-table-without-border'>

			<tr>
				<td [ngClass]='{"pro-table-label-error": !values.quarter.valid && values.quarter.changed}'>
					<me-label key='Quarter'></me-label>
				</td>
				<td>
					<kendo-dropdownlist [value]="quarterOption" (valueChange)="quarterDroplistChange('quarter',$event)"
						[textField]="'text'" [valueField]="'value'" [data]='quarterOptions'>
					</kendo-dropdownlist>
				</td>
			</tr>

			<tr>
				<td [ngClass]='{"pro-table-label-error": !values.summary.valid && values.summary.changed}'>
					<me-label key='Summary'></me-label>
				</td>
				<td>
					<input kendoTextBox [value]='values.summary.value' (input)="onInput('summary',$event)"
						[attr.maxlength]='values.summary.max' (blur)="onBlur('summary')" [placeholder]="" />
				</td>
			</tr>

			<tr>
				<td>
					<me-label key='Details'></me-label>
				</td>
				<td>
					<textarea kendoTextArea [value]='values.details.value' style="resize:none;" [placeholder]=""
						(input)="onInput('details',$event)" (blur)="onBlur('details')" rows='4'></textarea>
				</td>
			</tr>
		</table>
	</div>
</me-dialog-frame>