import { DbConceptName, Dbs, Immutable } from '@me-interfaces';


interface MutableDbsPersonTag extends Dbs {

	readonly _concept: DbConceptName.PersonTag,

	readonly personTagId: number,
	readonly personId: number,
	readonly tagId: number,
	readonly createdUTC: number,
	readonly updatedUTC: number,
	readonly updatedByPersonId: number,
}


export interface DbsPersonTag extends Immutable<MutableDbsPersonTag> { }