<div class='acc-mentor-checks-part'>

	<me-icon *ngIf="eventsStatusIcon" [href]="eventsStatusIcon.link" [icon]="eventsStatusIcon.icon"></me-icon>

	<me-icon *ngIf="mentorsStatusIcon" [href]="mentorsStatusIcon.link" [icon]="mentorsStatusIcon.icon">
	</me-icon>
	
	<me-icon *ngIf="coachesStatusIcon" [href]="coachesStatusIcon.link" [icon]="coachesStatusIcon.icon">
	</me-icon>

</div>