import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AdminRoute, PersonProfileAccessFlagsExtractForSite, RoutePermission } from '@me-interfaces';
import { OldUserService, UserWrapper } from '@me-services/old-services-and-wrappers/user';
import { UrlService } from '@me-services/ui/url';
import { CustomAnalyticsEventName } from '../auth';
import { AuthService } from '../auth/auth.service';
import { DdSite } from '../old-dd';
import { UtilityService } from '../utility';

const ACTUAL_MENTORS = 'actual-mentors';
const ALUMNI_REPORTERS = 'alumni-reporters';
const CRM_USERS = 'crm-users';
const CROSS_SITE_ADMINS = 'cross-site-admins';
const FEEDBACK_RECIPIENTS = 'feedback-recipients';
const NO_ONE = 'no-one';
const SITE_ADMINS = 'site-admins';
const SITE_DIRECTOR = 'site-director';
const SYSTEM_ADMINS = 'system-admins';

export type AdminRouteKey = 'all-sites';


@Injectable({ providedIn: 'root' })
export class EntryService {


	constructor(
		private router: Router,
		private userService: OldUserService,
		private auth: AuthService,
		private urlService: UrlService,
		private util: UtilityService,
	) {
	}


	/**
	 * Determine if the current user should be shown a particular route in the breadcrumbs, navigation cards, etc.
	 * The showTo string is stored in the navigation config on the data attribute.
	 * @param user The currently logged in user.
	 * @param site The current site in the route navigation if there is one.
	 * @param showTo A string that maps to one of the known constants that describes who should the route be shown to.
	 */
	showTo(user: UserWrapper, site: DdSite, showTo: string): boolean {
		//
		// IMPORTANT: never make this function async because the
		// breadcrumb part syncronously filters the routes array.
		//

		if (!showTo) return true;

		if (!user) return false;

		if (showTo == CRM_USERS) return user.isCrmUser;
		else if (showTo == CROSS_SITE_ADMINS) return user.isEForAllAdmin;
		else if (showTo == SYSTEM_ADMINS) return user.isTechAdmin;
		else if (showTo == ACTUAL_MENTORS) return user._personExtracts.badges.mentor == 'Good';
		else if (showTo == ALUMNI_REPORTERS) return user._personExtracts.badges.acc == 'Good';
		else if (showTo == FEEDBACK_RECIPIENTS) return user._profileExtracts.hasFeedback;
		else if (showTo == NO_ONE) return false;

		const siteAccess = site ? user.sites.getAccessFlags(site.code) : <PersonProfileAccessFlagsExtractForSite>{};
		if (showTo == SITE_ADMINS) return siteAccess.isSiteAdmin;
		else if (showTo == SITE_DIRECTOR) return siteAccess.isDirector || user.isEForAllAdmin;

		this.util.log.errorMessage(`Unexpected data.showTo: ${showTo} in the route config.`);
		return false;
	}


	/**
	 * Given a route, which might contain :accId or :picId placeholders, convert
	 * it to a path with the placeholders replaced with the actual values.
	 * @param route
	 * @param path 
	 */
	private convertRouteToPath(route: ActivatedRouteSnapshot, path: AdminRoute): string {

		if (path.indexOf(':accId') > -1) {
			const accId = this.getParam(route, 'accId');
			return path.replace(':accId', accId);
		}

		if (path.indexOf(':picId') > -1) {
			const picId = this.getParam(route, 'picId');
			return path.replace(':picId', picId);
		}

		return path;
	}

	
	private async _getAccess(route: ActivatedRouteSnapshot, path: string, key: AdminRouteKey = undefined): Promise<RoutePermission> {
		if (!route) throw new Error('Missing route parameter.');
		if (!path) throw new Error('Missing path parameter.');

		const user = await this.userService.getUser();

		if (!user) {
			this.auth.analytics?.logEvent(CustomAnalyticsEventName.ADMINROUTE_NONE, {
				path,
				key,
				message: "No user",
			});
			return RoutePermission.None;
		}

		if (key == undefined) {
			let siteCode: string = this.getParam(route, 'sitecode');
			siteCode = siteCode.toUpperCase();
			key = <AdminRouteKey>siteCode;
		}

		const adminRoutes: { [index: string]: { [index: string]: RoutePermission } } = user.adminRoutes || {};

		const siteRoutes = adminRoutes[key];
		if (Object.keys(siteRoutes).length === 0) {
			this.auth.analytics?.logEvent(CustomAnalyticsEventName.ADMINROUTE_NONE, {
				path,
				key,
				email: user._email,
				personId: user.personId,
				name: user._name,
				message: `No community routes for ${key}`,
			});

			return RoutePermission.None;
		}

		const adminRoute = adminRoutes[key][path];

		if (adminRoute) return adminRoute;
		else {
			this.auth.analytics?.logEvent(CustomAnalyticsEventName.ADMINROUTE_NONE, {
				path,
				key,
				email: user._email,
				personId: user.personId,
				name: user._name,
				message: `No adminRoute found for the key ${key} and path ${path}`,
			});
			return RoutePermission.None
		}
	}


	/** @deprecated Replace calls to getAccess with app area Access. Move specialized permissions to the Access object if needed. */
	public async getAccess(route: ActivatedRouteSnapshot, path: AdminRoute, key: AdminRouteKey = undefined): Promise<RoutePermission> {
		return await this._getAccess(route, this.convertRouteToPath(route, path), key);
	}

	/** @deprecated Replace calls to getAccess with app area Access. Move specialized permissions to the Access object if needed. */
	public async canAccess(route: ActivatedRouteSnapshot, path: AdminRoute, key: AdminRouteKey = undefined): Promise<boolean> {
		const access = await this.getAccess(route, path, key);
		return access !== RoutePermission.None;
	}

	/** @deprecated Replace calls to getAccess with app area Access. Move specialized permissions to the Access object if needed. */
	public async canEdit(route: ActivatedRouteSnapshot, path: AdminRoute, key: AdminRouteKey = undefined, redirectIfNone = true) {
		const perm = await this.getAccess(route, path, key);
		if (redirectIfNone && perm == RoutePermission.None) this.router.navigate(['/unauthorized']);
		else return perm == RoutePermission.Edit;
	}


	private getParam(route: ActivatedRouteSnapshot, param: string): string {
		if (route.params[param]) return route.params[param];
		if (!route.parent) throw new Error(`EntryService.getParam could not find parameter ${param}.`);
		return this.getParam(route.parent, param);
	}
}