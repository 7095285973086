<table>
	<tr>
		<th></th>
		<th></th>
		<th></th>
	</tr>
	<tr>
		<td>
			<label class='company-label'>
				<me-label key='Name:'></me-label>
			</label>
		</td>
		<td class='field company-field' colspan="2" [class.no-value]='missingCompanyValue("companyName") || pending.companyName.length < 4'>
			<mat-form-field appearance="outline">
				<input matInput [value]='pending.companyName' (blur)='companySetValue("companyName", $event)' maxlength='40'>
			</mat-form-field>
		</td>
	</tr>
	<tr>
		<td>
			<label class='company-label'>
				<me-label key='Short Name:'></me-label>
			</label>
		</td>
		<td class='field company-field' [class.no-value]='this.pending.shortName !== undefined && pending.shortName.length > 0 && pending.shortName.length < 3 '>
			<div style='max-width: 7em;'>
				<mat-form-field appearance="outline">
					<input matInput [value]='pending.shortName || ""' (blur)='companySetValue("shortName", $event)' maxlength='12'>
				</mat-form-field>
			</div>
		</td>
		<td><small><i>
					<me-label key='optional'></me-label>
				</i></small></td>
	</tr>
	<tr>
		<td>
			<label class='company-label'>
				<me-label key='Org Type:'></me-label>
			</label>
		</td>
		<td class='field company-field' colspan="2" [class.no-value]='missingCompanyValue("companyTypeId")'>
			<mat-form-field appearance="outline">
				<mat-select #select="matSelect" [value]='pending.companyTypeId' (selectionChange)='companySetSelectedValue("companyTypeId", $event)'>
					<mat-option *ngFor="let companyType of companyTypes" [value]="companyType.companyTypeId">
						<me-label [key]='companyType.name'></me-label>
					</mat-option>
				</mat-select>
			</mat-form-field>

		</td>
	</tr>
	<tr>
		<td>
			<label class='company-label'>
				<me-label key='Industry:'></me-label>
			</label>
		</td>
		<td class='field company-field' colspan="2" [class.no-value]='missingCompanyValue("industryId")'>
			<mat-form-field appearance="outline">
				<mat-select #select="matSelect" [value]='pending.industryId' (selectionChange)='companySetSelectedValue("industryId", $event)'>
					<mat-option *ngFor="let industry of industries" [value]="industry.industryId">
						<me-label [key]='industry.name'></me-label>
					</mat-option>
				</mat-select>
			</mat-form-field>
		</td>
	</tr>
	<tr>
		<td>
			<label class='company-label'>
				<me-label key='Offerings:'></me-label>
			</label>
		</td>
		<td class='field company-field' colspan="2" [class.no-value]='missingCompanyValue("offerings")'>
			<mat-form-field appearance="outline">
				<mat-select #select="matSelect" [value]='pending.offerings' (selectionChange)='companySetSelectedValue("offerings", $event)'>
					<mat-option *ngFor="let offering of offerings" [value]="offering.id">
						<me-label [key]='offering.name'></me-label>
					</mat-option>
				</mat-select>
			</mat-form-field>
		</td>
	</tr>
	<tr>
		<td>
			<label class='company-label'>
				<me-label key='Status:'></me-label>
			</label>
		</td>
		<td class='field company-field' colspan="2" [class.no-value]='missingCompanyValue("status")'>
			<mat-form-field appearance="outline">
				<mat-select #select="matSelect" [value]='pending.status' (selectionChange)='companySetSelectedValue("status", $event)'>
					<mat-option *ngFor="let status of statuses" [value]="status.id">
						<me-label [key]='status.name'></me-label>
					</mat-option>
				</mat-select>
			</mat-form-field>
		</td>
	</tr>
</table>
<button-container>
	<spinner-button [spinning]='saving' [disabled]='saving || missingAnyCompanyValues() || noChanges()' (safeClick)='updateDetails()'>
		<me-label key='Update'></me-label>
	</spinner-button>
</button-container>