import { Injectable } from '@angular/core';
import { DdVenue } from '@me-services/core/old-dd';
import { DialogService } from '@me-services/ui/dialog';
import { VenueDialog } from './SHR-ED_venue.dialog';


@Injectable({ providedIn: 'root' })
/**
 * Provides utilities for editing and adding dialogs including
 * ability to launches a dialog for user interaction.
 */
export class ShowVenueDialogService {

	constructor(private dialogService: DialogService) {
	}


	async show(venue: DdVenue | undefined): Promise<DdVenue | undefined> {

		const action = await this.dialogService.showCustom(
			VenueDialog,
			{
				data: { venue, },
			},
			650, 550);


		return action?.callbackResult;
	}

}