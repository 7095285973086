import { AccAreaService, AccPreAcceleratorPageService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { ADDROW_GRID_ACTION_KEY, GridSetup } from '@me-grid';
import { AccStageId, Event, EventContext, EventTypeId } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { ShowEventDialogService } from '@me-shared-parts/ED-editors';
import { Observable, distinctUntilChanged, lastValueFrom, map, take } from 'rxjs';


@Component({
	selector: 'acc-reading-event-view-part',
	templateUrl: './acc-reading-event-view.part.html',
})
export class AccReadingEventViewPart extends DestroyablePart implements OnInit {

	rows$: Observable<Event[]>;

	public gridSetup = this.setupGrid(false);
	private accessAtStage$ = this.accAreaService.getAccessAtStage(AccStageId.Reading);
	eventContext: EventContext;
	eventExists = false;
	canWrite = false;
	readonlyAccessAtStage = false;

	constructor(
		private accAreaService: AccAreaService,
		private ds: DataService,
		private util: UtilityService,
		private pageService: AccPreAcceleratorPageService,
		private showEventDialogService: ShowEventDialogService) {
		super();
	}

	async ngOnInit(): Promise<void> {
		super.initDestroyable();

		super.subscribe([this.accAreaService.access$, this.accessAtStage$], async ([access, accessAtStage]) => {
			this.canWrite = access.root == 'Write';
			this.readonlyAccessAtStage = accessAtStage.access != 'Write';
		});

		const acc = await lastValueFrom(this.accAreaService.accelerator.acc$.pipe(take(1)));
		this.eventContext = {
			eventTypeId: EventTypeId.AccReading,
			languageId: acc.siteProgram.program.languageId,
			accId: acc.accId,
			siteId: acc.siteId,
			siteProgramId: acc.siteProgramId,
		};

		this.rows$ = this.accAreaService.accelerator.events$
			.pipe(
				map(events => this.buildEvents(events)),
				distinctUntilChanged((e1, e2) => this.util.values.areSame(e1, e2))
			);
	}


	buildEvents(events: readonly Event[]): Event[] {

		if (!events) return [];

		const event = events.find(e => e.eventTypeId == EventTypeId.AccReading);

		this.gridSetup = this.setupGrid(this.canWrite && !event);
		this.eventExists = event ? true : false;
		return event ? [event] : [];
	}


	private setupGrid(canWrite: boolean): GridSetup<Event> {

		return {
			experience: 'EVENT',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: 'Event',
			rowPluralName: 'Events',
			rowKey: 'eventId',
			stateKey: 'acc-reading-event-view',
			canAdd: canWrite,
			canRefresh: false,
			canDownload: true,
		};
	}


	async gridActionHandler(action: { actionKey: string, rows: Event[] }) {

		const row = action.rows[0];
		if (action.actionKey == ADDROW_GRID_ACTION_KEY) {
			if (this.eventExists || !this.canWrite) return;
			if (this.readonlyAccessAtStage) {
				await this.ds.dialogService.showMessage(`Cannot add event as the accelerator stage is not in 'Reading' stage.`, 300, 170);
			}
			else await this.showEventDialogService.add({ ...this.eventContext });
		}
	}

}