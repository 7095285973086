import { AppAreaApplyResponse, AppAreaFuncResponse, AppAreaIdentifier } from "@me-interfaces";
import { AppSubArea } from "@me-services/core/area";
import { DataService } from "@me-services/core/data";
import { FuncService } from "@me-services/core/func";
import { UtilityService } from "@me-services/core/utility";

export class UserSubAreaProgram extends AppSubArea {

	constructor(
		private func: FuncService,
		ds: DataService,
		util: UtilityService,
		getId: () => AppAreaIdentifier<string>,
		applyResponse: (response: AppAreaFuncResponse) => Promise<AppAreaApplyResponse>,
	) {
		super(ds, util, getId, applyResponse);
	}


	async addAgreementSignature(parameters: { agreementVersionId: number }) {
		return await this.call(this.func.areas.user.program.addAgreementSignature, parameters);
	}

}