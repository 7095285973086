import { AppAreaFuncResponse, AppAreaIdentifier } from "@me-interfaces";
import { DataService } from "../data";
import { UtilityService } from "../utility";


export abstract class AppSubArea {

	constructor(
		private ds: DataService,
		private util: UtilityService,
		private getId: (() => AppAreaIdentifier<string>) | (() => AppAreaIdentifier<number>),
		private applyResponse: (response: AppAreaFuncResponse) => Promise<{ success: boolean, insertId?: number }>,
	) {
	}


	/**
	 * Call an action that internally calls checkAndDo and returns an AppAreaFuncResponse.
	 * That response will be automatically applied.
	 */
	protected async call<PARMS>(
		subFunc: (
			payload: {
				identifier: AppAreaIdentifier<string | number>,
				parameters: PARMS,
				singletonsCacheUTC: number,
			}
		) => Promise<AppAreaFuncResponse>,
		parameters: PARMS,
	) {

		return await this.util.queueRunner(subFunc.name, async () => {
			const identifier = this.getId();
			const singletonsCacheUTC = this.ds.singletonsAsOfUTC;
			const response = await subFunc({ identifier, parameters, singletonsCacheUTC });
			return await this.applyResponse(response);
		});

	}


	/**
	 * Call an action that interally calls checkAndDo but with the returnNothing parameter set to 'RETURN-NOTHING'.
	 * Instead of the normal process, the action will its own response which will pass through here.
	 */
	protected async callWithoutStandardResponse<PARMS, RESPONSE>(
		subFunc: (
			payload: {
				identifier: AppAreaIdentifier<string | number>,
				parameters: PARMS,
				singletonsCacheUTC: number,
			}
		) => Promise<RESPONSE>,
		parameters: PARMS,
	) {

		return await this.util.queueRunner(subFunc.name, async () => {
			const identifier = this.getId();
			const singletonsCacheUTC = this.ds.singletonsAsOfUTC;
			const response = await subFunc({ identifier, parameters, singletonsCacheUTC });
			return response;
		});

	}

}