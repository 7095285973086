export const enum ApplicationParticipantStatus {

	/**
	 * The person is in the applicationParticipantPending table and has not yet agreed to be a participant.
	 */
	Invited = 'Invited',

	/**
	 * The person is in the applicationParticipant table because they have agreed to be a participant. 
	 * However, either person.demographicsGiven is not 'Y' OR applicationParticipant.isCompleted is not 'Y'.
	 */
	Incomplete = 'Incomplete',

	/**
	 * The person is in the applicationParticipant table because they have yet agreed to be a participant.
	 * Also, person.demographicsGiven equals 'Y' AND applicationParticipant.isCompleted equals 'Y'.
	 */
	Complete = 'Complete',
}