import { Directive, ElementRef, EventEmitter, OnInit, Output } from "@angular/core";
import { DestroyablePart } from "@me-access-parts";
import { delay, fromEvent, merge, Observable, race, timer } from "rxjs";
import { filter, mapTo, switchMap, takeUntil, tap } from "rxjs/operators";

const LONG_PRESS_TIMEOUT = 1000;


/**
 * Directive that indicates if the user held the mouse/touch down for awhile before
 * releasing and triggering a click event. The longPress event will always be raised
 * right away with false. If the user holds it down long enough then another longPress
 * event will be raised with true. The last raised value should be remembered and then
 * handled the click event can choose what to do based on that value.
 */
@Directive({
	selector: "[longPress]"
})
export class LongPressDirective extends DestroyablePart implements OnInit {

	static instances = 0;
	instanceId: number;

	currentValue: boolean = undefined;
	@Output() longPress = new EventEmitter<boolean>();

	constructor(private elementRef: ElementRef) {
		super();
		this.instanceId = ++LongPressDirective.instances;
	}


	ngOnInit() {

		super.initDestroyable();

		//
		// Mouse events are filtered to only use the primary (usually left) button
		//
		const mouseDown$ = fromEvent<MouseEvent>(this.elementRef.nativeElement, "mousedown").pipe(filter(event => event.button == 0));
		const mouseUp$ = fromEvent<MouseEvent>(window, "mouseup").pipe(filter(event => event.button == 0));

		const touchStart$ = fromEvent(this.elementRef.nativeElement, 'touchstart');
		const touchEnd$ = fromEvent(this.elementRef.nativeElement, 'touchend');

		const pressStart$ = merge(mouseDown$, touchStart$).pipe(takeUntil(this.destroyed$));
		const pressStop$ = merge(mouseUp$, touchEnd$).pipe(takeUntil(this.destroyed$));


		const press$: Observable<boolean> = pressStart$
			.pipe(
				tap(() => this.emit(false)),
				switchMap(() => {
					return race(
						timer(LONG_PRESS_TIMEOUT).pipe(mapTo(true)),
						pressStop$.pipe(mapTo(false), delay(100)),
					);
				}),
			);

		press$.subscribe(longPress => {
			this.emit(longPress);
		});

	}

	emit(value: boolean) {
		if (value !== this.currentValue) {
			console.log(`longPress.emit(${value}) - #${this.instanceId}`);
			this.currentValue = value;
			this.longPress.emit(value);
		}
	}
}