<app-area-access [areaService]='accAreaService' accessKey="assessments">

	<div class='acc-mm-assessment-view-part'>
		<me-droplist style='display:inline-block' *ngIf="orderList" [items]='orderList'
			[selectedItem]='orderSelectedItem' (selectionChange)="changeOrder($event)">
		</me-droplist>

		<mat-accordion>
			<mat-expansion-panel *ngFor='let person of people$|async' #parent>
				<mat-expansion-panel-header>
					<mat-panel-title>
						<i *ngIf='person.rollups.assessmentsGivenPercent === 100' class='fas fa-check-square mr-3'
							title="All assessments are given"></i>
						<i *ngIf='person.rollups.assessmentsGivenPercent < 100' class='far fa-square mr-3'
							title="All assessments are not given"></i>
						<div class='large-cell'>
							<div>{{person.name}}</div>
							<div><small *ngIf="person.companyName"> {{person.companyName}}</small></div>
						</div>
						<ng-container>
							<ratings-four title='Ratings Given' [ratings]='person.rollups.assessmentsGiven'>
							</ratings-four>
						</ng-container>
						<ng-container>
							<ratings-four class='mobile-hidden' title='Ratings Received'
								[ratings]='person.rollups.assessmentsRecvd'></ratings-four>
						</ng-container>
					</mat-panel-title>
				</mat-expansion-panel-header>

				<div class="mat-expansion-body" *ngIf='parent.expanded'>
					<table style='width:100%' class="pro-table pro-table-without-border">
						<tr>
							<th>Name</th>
							<th>Given</th>
							<th>Received</th>
						</tr>
						<tr *ngFor="let assessment of person.assessments">
							<td>
								<span>{{assessment.name}}</span>
								<small *ngIf="assessment.companyName">{{assessment.companyName}}</small>
							</td>
							<td>
								<rating-four *ngIf='assessment.given' title='Rating Given' [rating]='assessment.given'>
								</rating-four>
							</td>
							<td>
								<rating-four *ngIf='assessment.received' title='Rating Received'
									[rating]='assessment.received'>
								</rating-four>
							</td>
						</tr>
					</table>
				</div>
			</mat-expansion-panel>
		</mat-accordion>
	</div>
</app-area-access>