import { DbConceptName, PicStageId, Site } from "@me-interfaces";
import { DataService } from "../../../../data.service";
import { ExplorerBox, ExplorerBoxClass } from "../../../explorer-box/explorer-box";


export async function getBoxesForPitchContests(ds: DataService, site: Site): Promise<ExplorerBox[]> {

	const boxes: ExplorerBox[] = [];

	const pitchContestsBySiteId = await ds.admin.pitchContest.getBySiteIds([site.siteId]);

	const pitchContests = pitchContestsBySiteId[site.siteId]
		.filter(pic => pic.picStageId != PicStageId.Complete)
		.sort((a, b) => b.applStartUTC - a.applStartUTC);

	const picIds = pitchContests.map(pic => pic.picId);
	const applicationsByPicId = await ds.admin.application.getByPicIds(picIds);

	for (const pitchContest of pitchContests) {

		const picId = pitchContest.picId;
		const applicationCount = (applicationsByPicId[picId] || []).length;

		const box: ExplorerBox = {
			explorerBoxClass: ExplorerBoxClass.PitchContest,
			header: `${pitchContest.siteProgram.program.language.name} - ${pitchContest.stage.name}`,
			buttons: [
				{ explorer: { explorableId: { conceptName: DbConceptName.PitchContest, id: pitchContest.picId }, navTooltip: `Pitch Contest: ${pitchContest.name}` } },
				{ explorer: { nameOverride: `PM: ${pitchContest.managerName}`, explorableId: { conceptName: DbConceptName.Person, id: pitchContest.managerId }, navTooltip: `PM: ${pitchContest.managerName}` } },
			],
			notes: `${applicationCount} Applications`,
		};

		boxes.push(box);
	}

	return boxes;
}