import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { getIconClass } from '../icon';
import { getIconBarSelections } from './get-icon-bar-selections';
import { IconBarIcon } from './icon-bar-icon';


export type IconBarSelections = { array: string[], map: { [key: string]: boolean } };

interface WrappedIcon extends IconBarIcon {
	iconClass: string,
}


@Component({
	selector: 'me-icon-bar',
	templateUrl: './SHR-CMN_icon-bar.part.html',
	styleUrls: ['./SHR-CMN_icon-bar.part.scss'],
})
export class IconBarPart implements OnChanges {

	@Input() icons: IconBarIcon[] = [];
	@Input() orientation: 'Horizontal' | 'Vertical' = 'Horizontal';
	@Output() selectionsChange = new EventEmitter<IconBarSelections>();

	wrappedIcons: WrappedIcon[] = [];
	public allSelected = false;
	public noneSelected = false;


	areAllIconsSelected() {
		return !this.wrappedIcons.find(icon => icon.selected == false);
	}

	areNoIconsSelected() {
		return !this.wrappedIcons.find(icon => icon.selected == true);
	}


	ngOnChanges(changes: SimpleChanges): void {

		if (changes.icons && this.icons) {

			this.wrappedIcons = this.icons.map(icon => {
				return {
					...icon,
					iconClass: this.getIconClass(icon),
					disabled: icon.disabled ? true : null,
				};
			});

			this.allSelected = this.areAllIconsSelected();
			this.noneSelected = this.areNoIconsSelected();
			// this.selectionsChange.emit(getIconBarSelections(this.wrappedIcons));
		}
	}


	private getIconClass(icon: IconBarIcon) {
		const iconStyle = icon.selected ? 'fa-light' : 'fa-light';
		const iconClass = getIconClass(icon.icon, iconStyle);
		return `${iconClass} fa-fw`;
	}


	onSelectedChange(icon: WrappedIcon, value: boolean) {
		icon.selected = value;
		icon.iconClass = this.getIconClass(icon);

		this.allSelected = this.areAllIconsSelected();
		this.noneSelected = this.areNoIconsSelected();
		this.selectionsChange.emit(getIconBarSelections(this.wrappedIcons));
	}


	onLongPress(pressedIcon: WrappedIcon, longPress: boolean) {

		//
		// If longPress then unselect all of the icons including
		// the one that was longPressed because it will get toggled
		// back on with onSelectedChange()
		//
		if (longPress) {

			for (const icon of this.wrappedIcons) {
				icon.selected = false;
				icon.iconClass = this.getIconClass(icon);
			}

			this.allSelected = this.areAllIconsSelected();
			this.noneSelected = this.areNoIconsSelected();
			this.selectionsChange.emit(getIconBarSelections(this.wrappedIcons));
		}
	}

	
	public selectOnlyOne(key: string) {

		for (const icon of this.wrappedIcons) {
			icon.selected = icon.key == key && !icon.disabled;
		}

		this.allSelected = false;
		this.selectionsChange.emit(getIconBarSelections(this.wrappedIcons));
	}


	public unselect(key: string) {
		const icon = this.wrappedIcons.find(i => i.key == key);
		if (icon) {
			icon.selected = false;
			this.allSelected = false;
			this.selectionsChange.emit(getIconBarSelections(this.wrappedIcons));

		}
	}


	toggleAll() {
		const value = !this.allSelected;

		for (const icon of this.wrappedIcons) {
			icon.selected = value;
			icon.iconClass = this.getIconClass(icon);
		}

		this.allSelected = this.areAllIconsSelected();
		this.noneSelected = this.areNoIconsSelected();
		this.selectionsChange.emit(getIconBarSelections(this.wrappedIcons));
	}


	getWidthExtent() {
		if (this.orientation == 'Horizontal') return '100%';
		else return '48px';
	}


	getHeightExtent() {
		if (this.orientation == 'Vertical') return '100%';
		else return '48px';
	}

}