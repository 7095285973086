export interface TechAllPerson {
	personId: number,
	firstName: string,
	lastName: string,
	phone: string | undefined,
	userTags: string[],
	email: string,
	emails: string[],
}

export interface TechAllPeople {
	emails: {
		emailId: number,
		email: string,
		personId: number,
	}[],
	people: TechAllPerson[]
}