<app-area-access [areaService]='accAreaService' accessKey="assessments">
	<div class='acc-interviewing-assessment-view-part' *ngIf="data">
		<div class="assessment-selection">
			<div>
				<div>Order</div>
				<me-droplist style='display:inline-block' *ngIf="orderList" [items]='orderList'
					[selectedItem]='orderSelectedItem' (selectionChange)="changeOrder($event)">
				</me-droplist>
			</div>
			<div>
				<div>Event</div>
				<kendo-dropdownlist [data]='data.interviewEvents' textField='numWithDate' valueField='eventId'
					[defaultItem]="defaultEvent" [value]='eventIdToFilterBy' [valuePrimitive]="true"
					(valueChange)='onSelectionChange($event)'>
				</kendo-dropdownlist>
			</div>
		</div>
		<assessment-results [data]='data.assessments' [order]='order'></assessment-results>
	</div>
</app-area-access>