import { PitchContest } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";


export async function getPics(util: UtilityService, ds: DataService) {

	const picsBySiteId: Record<number, PitchContest[]> = {};

	const pics = await ds.admin.pitchContest.getAllPackagesAsArray();

	for (const pic of pics) {

		const siteId = pic.siteProgram.siteId;

		util.record.insertElement<PitchContest>(picsBySiteId, siteId, pic);
	}

	return { picsBySiteId };
}