import { DbConceptName, Event, WebLinkTypeId } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { UrlService } from "@me-services/ui/url";
import { convertEventToMutable } from "@me-shared-parts/ED-editors/shared-editors/event/convert-event-to-mutable";
import { DatesProcessor } from "@me-shared-parts/ED-editors/shared-editors/event/event-dialog/dates-processor";
import { LinkOrExplorable } from "../../link-or-explorerable";

export async function getButtons(ds: DataService, event: Event, util: UtilityService, urlService: UrlService): Promise<LinkOrExplorable[]> {

	const buttons: LinkOrExplorable[] = [];

	//
	// Acc and Pic
	//
	if (event.accId) {
		const accelerator = await ds.admin.accelerator.getOnePackage(event.accId);
		buttons.push({ explorer: { explorableId: { conceptName: DbConceptName.Site, id: accelerator.siteProgram.siteId }, navTooltip: `Site: ${accelerator.siteProgram.site.name}` } },);
		buttons.push({ explorer: { explorableId: { conceptName: DbConceptName.Accelerator, id: event.accId }, navTooltip: `Accelerator: ${accelerator.longName}` } },);
	}
	if (event.picId) {
		const pitchContest = await ds.admin.pitchContest.getOnePackage(event.picId);
		buttons.push({ explorer: { explorableId: { conceptName: DbConceptName.Site, id: pitchContest.siteProgram.siteId }, navTooltip: `Site: ${pitchContest.siteProgram.site.name}` } },);
		buttons.push({ explorer: { explorableId: { conceptName: DbConceptName.PitchContest, id: event.picId }, navTooltip: `Pitch Contest: ${pitchContest.longName}` } },);
	}

	//
	// Venue and link
	//

	if (event.venueId) buttons.push({ explorer: { explorableId: { conceptName: DbConceptName.Venue, id: event.venueId }, navTooltip: 'Link', } });

	if (!event.venueId && event.link) buttons.push({ link: { linkType: 'webLink', url: event.link, webLinkTypeId: WebLinkTypeId.Website, overrideText: 'Online Meeting Link' } });

	if (event.venueId && event.link) buttons.push({ link: { linkType: 'webLink', url: event.link, webLinkTypeId: WebLinkTypeId.Website, overrideText: 'Web Link' } });

	return buttons;
}