<nav *ngIf='user' class="nav me-navbar fixed-top">

	<dev-banner></dev-banner>

	<!-- Logo -->

	<button class='search-btn' mat-button routerLink="/access/my/dashboard" [title]='openAppDashboard'
		style='margin-left:15px;margin-right:5px;'>
		<img src='assets/img/logo/eforall_96x30.png' style='position: relative; top: -1px;' *ngIf='logo==="eforall"'>
		<img src='assets/img/logo/eparatodos_133x30.png' style='position: relative; top: -1px;'
			*ngIf='logo==="eparatodos"'>
	</button>


	<!-- Hamburger Menu Toggle -->

	<button class='search-btn' mat-button (click)="menu.toggleCollapsed()" title='Toggle Menu'>
		<i class='far fa-bars transition'></i>
	</button>


	<ng-container *ngIf='user.isCrmUser || user._profileExtracts.accessFlags.canReadContactsDb'>
		<!-- Search button -->
		<button class='search-btn' mat-button (click)="search()" title='Search'>
			<i class='fad fa-search transition'></i>
		</button>


		<!-- Explore button -->
		<button class='search-btn' mat-button (click)="explore()" title='Explore'>
			<i class='fad fa-square-e transition'></i>
		</button>


		<!-- Add Person -->
		<button class='search-btn' mat-button routerLink="/access/contacts/new-person" title='Add Person'>
			<i class='fad fa-user-plus transition'></i>
		</button>

	</ng-container>
	<!-- Jump Menu -->

	<mat-menu #jumpMenu="matMenu" [overlapTrigger]='false'>

		<hr style='background-color: rgba(0,0,0,0.1); margin:0.5em 0.5em 3px 0.5em;'>
		<me-h4 style='margin-left:10px;font-size:0.9em;opacity:0.7;'>Production Environment</me-h4>
		<hr style='background-color: rgba(0,0,0,0.1); margin:3px 0.5em;'>

		<a mat-menu-item href="http://eforall.app{{router.url}}" target='eforall.app'>
			<i class='fal fa-shield-check' style="margin-right: 0.5em;"></i>eforall.app</a>
		<a mat-menu-item href="http://eparatodos.app{{router.url}}" target='eparatodos.app'>
			<i class='fal fa-shield-check' style="margin-right: 0.5em;"></i>eparatodos.app</a>

		<hr style='background-color: rgba(0,0,0,0.1); margin:3px 0.5em;'>
		<me-h4 style='margin-left:10px;font-size:0.9em;opacity:0.7;'>Development Environment</me-h4>
		<hr style='background-color: rgba(0,0,0,0.1); margin:3px 0.5em;'>

		<a mat-menu-item href="http://dev.eforall.app{{router.url}}" target='dev.eforall.app'>
			<i class='fal fa-wrench' style="margin-right: 0.5em;"></i>dev.eforall.app</a>
		<a mat-menu-item href="http://dev.eparatodos.app{{router.url}}" target='dev.eparatodos.app'>
			<i class='fal fa-wrench' style="margin-right: 0.5em;"></i>dev.eparatodos.app</a>
	</mat-menu>

	<button class='search-btn' *ngIf='user.isCrmUser' mat-button [matMenuTriggerFor]="jumpMenu" title='Jump to...'>
		<i class='fad fa-arrows-alt-h transition'></i>
	</button>


	<!-- Alerts Bell -->

	<mat-menu #alertMenu="matMenu" [overlapTrigger]='false'>

		<hr style='background-color: rgba(0,0,0,0.1); margin:0.5em 0.5em 3px 0.5em;'>
		<me-h4 style='margin-left:10px;font-size:0.9em;opacity:0.7;'>Alerts</me-h4>
		<hr style='background-color: rgba(0,0,0,0.1); margin:3px 0.5em;'>

		<button *ngFor='let alert of alerts' mat-menu-item (click)='handleAlert(alert)'>
			<me-label *ngIf='alert.label' [key]='alert.label'></me-label>
			<ng-container *ngIf='!alert.label'>{{alert.text}}</ng-container>
		</button>
	</mat-menu>
	<button class='search-btn' *ngIf='alerts && alerts.length' mat-button [matMenuTriggerFor]="alertMenu"
		title='Alerts'>
		<i class='fad fa-bell'></i>
	</button>


	<!-- User Menu -->

	<ul class="nav right-section">
		<li>
			<div class="dropdown float-right user-menu">
				<a class="dropdown-toggle user-link" href="#" role="button" id="userDropdownMenuLink"
					data-bs-toggle="dropdown" aria-expanded="false">
					<img src="assets/img/users/avatar.png" alt="user-img" class="img-circle user-img">
					<span class="d-none d-sm-inline-block">{{user.firstName}} {{user.lastName}}</span>
				</a>

				<ul class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdownMenuLink">
					<li class="user-info">
						<img routerLink="/access/my/profile" src="assets/img/users/avatar.png" alt="user-img"
							class="img-circle user-img" style="cursor:pointer;">
						<p>
							{{user.firstName}} {{user.lastName}}
							<small
								*ngIf="user._personExtracts && user._personExtracts.positions.length">{{user._personExtracts.positions[0].position.title}}</small>
							<small *ngIf="user._personExtracts && user._personExtracts.positions.length">
								{{user._personExtracts.positions[0].company ?
								user._personExtracts.positions[0].company._name : 'UNKNOWN' }}</small>
						</p>
					</li>

					<a class="dropdown-item" routerLink="/access/my/profile"> <i class="fad fa-user"></i>
						<me-label key='Profile'></me-label>
					</a>
					<a *ngIf="eforallConfig" class="dropdown-item" routerLink="/access/my/sites"> <i
							class="fad fa-map-marker-alt"></i>
						<me-label key='Communities'></me-label>
					</a>


					<div class="dropdown-divider"></div>

					<a class="dropdown-item" (click)="rebuildCache()" href="/">
						<i class="fad fa-retweet"></i>
						Rebuild Cache
					</a>

					<div class="dropdown-divider"></div>

					<a class="dropdown-item" (click)="auth.me.signout()" href="/signout">
						<i class="fad fa-power-off"></i>
						<me-label key='Log out'></me-label>
					</a>
				</ul>
			</div>
		</li>
	</ul>
</nav>