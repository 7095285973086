<div class="stage-viewer-part">
	<div class="container" [class.accelerator]='program==="accelerator"' [class.pitch]='program==="pitchContest"'>
		<div *ngFor='let stage of stages' class="box" title='{{stage.name}}' [class.completed]='stage.id<stageId'
			[class.active]='stage.id===stageId'>
			<ng-container *ngIf='stage.id!==stageId'>
				<ng-container *ngIf='stage.id !== stages[stages.length-1].id'> &gt; </ng-container>
				<ng-container *ngIf='stage.id === stages[stages.length-1].id'> ✓ </ng-container>
			</ng-container>
			<ng-container *ngIf='stage.id===stageId'>{{stage.name}}</ng-container>
		</div>
	</div>
</div>