import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { DbcUser, DbsWebLink, PublicCompanyForDialog, WebLinkTypeId } from '@me-interfaces';
import { DialogContext } from '@me-services/ui/dialog';
import { LabelsService } from '@me-services/ui/labels';
import { getIconClass, getIconContext, getIconForIndustry, getIconForWebLinkType, Icon } from '@me-shared-parts/UI-common';

@Component({
	selector: 'public-company-dialog',
	templateUrl: './SHR-CM_public-company-dialog.dialog.html',
	styleUrls: ['./SHR-CM_public-company-dialog.dialog.scss']
})
export class PublicCompanyDialog extends DestroyablePart implements OnInit {

	company: PublicCompanyForDialog;
	header = 'Company';

	user: DbcUser;
	smsLink: string;
	companyPhone: { phone: string, phoneTypeId?: number };
	showAddress: boolean;
	icon = "far fa-building";
	smsIcon = getIconContext(Icon.action_sms, undefined, 'always', 'Text Message');

	inlineLabels = this.labels.trackInlineLabels(this, [
		'Name',
		'Phone',
		'Address'
	]);

	constructor(
		@Inject(MAT_DIALOG_DATA) public dialog: DialogContext<{ company: PublicCompanyForDialog, showAddress: boolean }>,
		public dialogRef: MatDialogRef<PublicCompanyDialog>,
		public labels: LabelsService
	) {
		super();
	}


	ngOnInit() {
		super.initDestroyable();

		if (this.dialog.data) {
			const { company, showAddress } = this.dialog.data;
			this.company = company;
			this.showAddress = showAddress;
		}

		this.header = this.company?._name || 'No Company Record found';

		if (this.company) {
			this.companyPhone = { phone: this.company?.phone, phoneTypeId: this.company?.phoneTypeId };

			this.icon = getIconClass(getIconForIndustry(this.company.industryId));
		}

		if (this.company.phone) {
			this.smsLink = `sms:${this.company.phone}`;
		}
	}

	/**
	 * Determine the button icon and text based on the weblink type
	 */
	getWebLinkIcon(webLink: DbsWebLink) {
		const icon = getIconForWebLinkType(webLink.webLinkTypeId || WebLinkTypeId.Website)
		return getIconContext(icon, undefined, 'always');
	}

}