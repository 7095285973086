import { ApplicationStatusId, Dba, DbConceptName, Immutable } from '@me-interfaces';


interface MutableDbaApplicationStatusLog extends Dba {

	readonly _concept: DbConceptName.ApplicationStatusLog,

	applicationStatusLogId: number,
	applicationId: number,
	applicationStatusId: ApplicationStatusId,
	personId: number,
	changedUTC: number,
}


export interface DbaApplicationStatusLog extends Immutable<MutableDbaApplicationStatusLog> { }