import { Field, FieldContext, FieldNumericFormat } from '@me-interfaces';

/**
 * Not all properties on a field are required. The base field 
 * part calls this to set the defaults of any missing properties.
 * It will also caclulate the key from the context.
 * @param field 
 */
export function applyDefaultFieldProperties(field: Field) {

	field.labelIsQuestion = !!field.labelIsQuestion;

	field.saveEmptyStringAsNull = !!(field.saveEmptyStringAsNull ?? true);

	field.saveZeroAsNull = !!field.saveZeroAsNull;

	field.required = !!(field.required ?? true);

	field.maxLength = Math.max(1, +(field.maxLength || 100));

	field.minLength = Math.min(100, +(field.minLength || 0));

	field.key = field.key || createContextKey(field.context);

	field.otherValueKey = createContextKey(field.otherValueContext);

	field.minValue = field.minValue ?? 0;

	field.maxValue = field.maxValue ?? 100;

	field.numericFormat = field.numericFormat ?? FieldNumericFormat.Decimal;

	field.decimalLength = field.decimalLength ?? 0;
}


function createContextKey(context: FieldContext): string {

	if (!context) return undefined;

	let key = `${context.target}|${context.id1}`;
	if (context.id2) key += `|${context.id2}`;
	return key;
}