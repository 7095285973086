import { NationalDashboardPageService } from '@ADMIN-area';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { CompanyCols, GridColumnType, GridSetup, SELECTION_GRID_ACTION_KEY } from '@me-grid';
import { AppAreaIdentifier } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { GridPart } from '@me-shared-parts/UI-common';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';


interface BusinessRow {
	companyId: number,
	accId: number,
	siteCode: string,
	siteId: number,
	accName: string,
	alumni: string,
	language: string,
	updatedUTC: number,
}

@Component({
	selector: 'shared-acc-teams-view-part',
	templateUrl: './acc-teams-view.part.html',
})
export class SharedAccTeamsViewPart extends DestroyablePart implements OnInit {

	@Input() identifier: AppAreaIdentifier<number>;
	@ViewChild(GridPart) meGrid: GridPart<BusinessRow>;

	public gridSetup = this.setupGrid();
	public rows$: Observable<BusinessRow[]> = of([]);


	constructor(
		private util: UtilityService,
		public ds: DataService,
		public pageService: NationalDashboardPageService
	) {
		super();
	}


	ngOnInit(): void {

		if (!this.identifier) throw new Error(`The identifer attribute was not set for <shared-acc-teams-view-part>`);

		super.initDestroyable();

		this.rows$ = this.ds.admin.singletonsAsOfUTC$.pipe(mergeMap(utc => this.buildRows(this.ds)));
	}


	async buildRows(ds: DataService): Promise<BusinessRow[]> {

		const applications = await ds.admin.application.getAllPackagesAsArray();
		const accTeams = await ds.admin.accTeam.getAllPackagesAsArray();

		if (!applications || !accTeams) return [];
		const allAccApplications = applications.filter(a => a.accId);

		const allAlumniAccTeamsMap = this.util.array.toMap(accTeams.filter(team => !team.droppedOutWeek || (team.droppedOutWeek && team.droppedOutWeek > 13)), team => team.applicationId);


		const rows: BusinessRow[] = allAccApplications.map(application => ({
			companyId: application.companyId,
			accId: application.accId,
			siteCode: application.siteProgram.site.code,
			siteId: application.siteProgram.siteId,
			accName: application.accelerator.name,
			alumni: allAlumniAccTeamsMap[application.applicationId] ? 'Yes' : 'No',
			language: application.siteProgram.program.language.name,
			updatedUTC: application.updatedUTC,
		}));

		return rows;
	}



	private setupGrid(): GridSetup<BusinessRow> {

		return {
			experience: 'COMPANY',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Business",
			rowPluralName: "Businesses",
			rowKey: "companyId",
			stateKey: "national-acc-businesses",
			canAdd: false,
			canRefresh: false,
			canDownload: false,
			columnsToAdd: [
				{ field: "accId", header: "accId", width: 60, type: GridColumnType.number, hidden: true },
				{ field: "siteId", header: "siteId", width: 60, type: GridColumnType.number, hidden: true },
				{ field: "siteCode", header: "Site", width: 100, type: GridColumnType.text },
				{ field: "accName", header: "Program", width: 150, type: GridColumnType.text, hidden: false },
				{ field: "alumni", header: "Alumni", width: 100, type: GridColumnType.text, hidden: false },
				{ field: "language", header: "Language", width: 100, type: GridColumnType.text, hidden: false },
				{ field: CompanyCols.status, header: 'Status', width: 100, type: GridColumnType.text, hidden: false },

			],
			initialState: {
				sort: [{ field: CompanyCols.name, dir: 'asc' }],
			},
		};
	}



	async gridActionHandler(action: { actionKey: string, rows: BusinessRow[] }) {

		if (action.actionKey == SELECTION_GRID_ACTION_KEY) {
			// Nothing to do with a simple row selection
		}
	}

}