<div class='acc-curriculum-tab-part'>
	<div class='acc-curriculum-tab-part'>
		<me-droplist *ngIf="listOfSessions$ | async as listOfSessions" class="views-droplist" [items]='listOfSessions' [filterable]='false' [navigation]='true'
			sortBy='customSort' [groupSortValues]='{"Sessions":1, "Topics":2, "Specialists":3 }'
			[selectedItem]="lastItem" style='display:inline-block' (selectionChange)='onSelection($event)'>
		</me-droplist>
	</div>

	<div *ngIf="surveyCounts">
		<table style="width:100%;" class='pro-table pro-table-without-border pro-table-with-thin-rows'>
			<tr>
				<th>Session Info</th>
				<th>Value</th>
			</tr>
			<tr>
				<td>Attendees Present</td>
				<td class="counts-cell">{{surveyCounts.attendees}}</td>
			</tr>
			<tr>
				<td>Surveys Complete</td>
				<td class="counts-cell">{{surveyCounts.surveysCompleted}}</td>
			</tr>
			<tr>
				<td>Accessibility Score</td>
				<td class="counts-cell">{{surveyCounts.accessibilityScore}}</td>
			</tr>
			<tr>
				<td>Communication Score</td>
				<td class="counts-cell">{{surveyCounts.communicationScore}}</td>
			</tr>
			<tr>
				<td>Organization Score</td>
				<td class="counts-cell"> {{surveyCounts.organizationScore}}</td>
			</tr>
			<tr>
				<td>Comments</td>
				<td class="counts-cell">
					<button style="min-width: 35px; min-height: 15px;"
						(click)="showDialog(surveyCounts.comments , 'Session: ' + surveyCounts.date)"
						class="text-btn text-btn-not-inline">{{surveyCounts.comments.length}}</button>
				</td>
			</tr>
			<tr>
		</table>
		<br>
		<!-- Check if 'surveyCounts.topics' has any topics; if not, display 'No topic surveys' -->
		<ng-container *ngIf="surveyCounts?.topics?.length; else noTopicSurveys">
			<div *ngFor="let topic of surveyCounts.topics">
				<me-h4>Topic: {{topic.name}}</me-h4>
				<table style="width:100%;" class='pro-table pro-table-without-border pro-table-with-thin-rows'>
					<tr>
						<th>Topic Info</th>
						<th>Value</th>
					</tr>
					<tr>
						<td>Relevance Score</td>
						<td class="counts-cell"> {{topic.relevanceScore}}</td>
					</tr>
					<tr>
						<td>Need Score</td>
						<td class="counts-cell"> {{topic.needScore}}</td>
					</tr>
					<tr>
						<td>Understanding Score</td>
						<td class="counts-cell"> {{topic.understandingScore}}</td>
					</tr>
					<tr>
						<td>Duration Score</td>
						<td class="counts-cell"> {{topic.durationScore}}</td>
					</tr>
					<tr>
						<td>Comments</td>
						<td class="counts-cell">
							<button style="min-width: 35px; min-height: 15px;"
								(click)="showDialog(topic.comments,'Topic: ' + topic.name)"
								class="text-btn text-btn-not-inline">{{topic.comments.length}}</button>
						</td>
					</tr>
				</table>
				<br>
				<!-- Check if 'topic.specialists' has any specialists; if not, display 'No specialist surveys' -->
				<ng-container *ngIf="topic.specialists?.length; else noSpecialistSurveys">
					<div *ngFor="let specialist of topic.specialists">
						<me-h4>{{topic.name}} Specialist: {{specialist.name}}</me-h4>
						<table style="width:100%;" class='pro-table pro-table-without-border pro-table-with-thin-rows'>
							<tr>
								<th>Specialist Info</th>
								<th>Value</th>
							</tr>
							<tr>
								<td>Effectiveness Score</td>
								<td class="counts-cell"> {{specialist.effectivenessScore}}</td>
							</tr>
							<tr>
							<tr>
								<td>Knowledge Score</td>
								<td class="counts-cell"> {{specialist.knowledgeScore}}</td>
							</tr>
							<tr>
							<tr>
								<td>Professionalism Score</td>
								<td class="counts-cell"> {{specialist.professionalismScore}}</td>
							</tr>
							<tr>
							<tr>
								<td>Comments</td>
								<td class="counts-cell">
									<button style="min-width: 35px; min-height: 15px;"
										(click)="showDialog(specialist.comments, topic.name +' Specialist: ' + specialist.name)"
										class="text-btn text-btn-not-inline">
										{{specialist.comments.length}}</button>
								</td>
							</tr>
						</table>
						<br>
					</div>
				</ng-container>
				<ng-template #noSpecialistSurveys>
					<i>No specialist surveys.</i>
				</ng-template>
			</div>
		</ng-container>
		<ng-template #noTopicSurveys>
			<i>No topic surveys.</i>
		</ng-template>

	</div>
</div>
