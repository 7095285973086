import { Icon } from "@me-shared-parts/UI-common";
import { IconBarIcon } from "@me-shared-parts/UI-common/icon-bar";

export const VIEW_DETAILS = 'details';
export const VIEW_PEOPLE = 'people';
export const VIEW_PROGRAMMING = 'programming';
export const VIEW_NOTES = 'notes';
export const VIEW_AWARDS = 'awards';

export const views: IconBarIcon[] = [
	{ key: VIEW_DETAILS, icon: Icon.action_more, tooltip: 'Company Details', selected: true },
	{ key: VIEW_NOTES, icon: Icon.notes_some, tooltip: 'Notes', selected: true },
	{ key: VIEW_PEOPLE, icon: Icon.concept_position, tooltip: 'Positions', selected: true },
	{ key: VIEW_PROGRAMMING, icon: Icon.view_programming, tooltip: 'Programming', selected: true },
	{ key: VIEW_AWARDS, icon: Icon.concept_award, tooltip: 'Awards', selected: true },
];