export interface AppSession {
	appSessionId: number,
	elapsed: number,
	lastActive: number,
	browser: string | undefined,
	browserVersion: string | undefined,
	renderer: string | undefined,
	device: string | undefined,
	os: string | undefined,
	osVersion: string | undefined,
	clientOffsetUTC: number,
}