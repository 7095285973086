<message-line *ngIf="!data.length">There are no assessments yet.</message-line>
<mat-menu #configMenu='matMenu'>
	<div class='separator'>Sort by</div>

	<button mat-menu-item [class]='getMenuButtonClass("sortBy", "assessments")' (click)='sort("assessments");'>
		<i [class]='getSortClass("assessments")'></i>Assessments</button>
	<button mat-menu-item [class]='getMenuButtonClass("sortBy", "questions")' (click)='sort("questions");'>
		<i [class]='getSortClass("questions")'></i>Question Ratings</button>
	<button mat-menu-item [class]='getMenuButtonClass("sortBy", "name")' (click)='sort("name");'>
		<i [class]='getSortClass("name")'></i>Name</button>
	<button mat-menu-item [class]='getMenuButtonClass("sortBy", "scores")' (click)='sort("scores");'>
		<i [class]='getSortClass("scores")'></i>Scores</button>

	<div class='separator' style='border-top: solid 1px #DDD;'>Summarize with</div>
	<button mat-menu-item [class]='getMenuButtonClass("aggregateBy", "assessments")' (click)='aggregateBy="assessments"'
		*ngIf='hasAssessments'>
		<i [class]='getAggregateClass("assessments")'></i>Assessments</button>
	<button mat-menu-item [class]='getMenuButtonClass("aggregateBy", "questions")' (click)='aggregateBy="questions"'>
		<i [class]='getAggregateClass("questions")'></i>Question Ratings</button>
	<button mat-menu-item [class]='getMenuButtonClass("aggregateBy", "grades")' (click)='aggregateBy="grades"'>
		<i [class]='getAggregateClass("grades")'></i>Letter Grades</button>
	<button mat-menu-item [class]='getMenuButtonClass("aggregateBy", "scores")' (click)='aggregateBy="scores"'>
		<i [class]='getAggregateClass("scores")'></i>Scores</button>
</mat-menu>

<div class="toolbar" *ngIf='data.length'>
	<h5>{{header}}</h5>
	<button class='btn' [matMenuTriggerFor]='configMenu'><i class="fas fa-sort"></i></button>
</div>

<mat-accordion>
	<mat-expansion-panel *ngFor='let level1 of levels' #parent1>
		<mat-expansion-panel-header>
			<mat-panel-title>
				<assessment-level-title [level]='level1'></assessment-level-title>
				<ng-container *ngIf='aggregateBy==="assessments"'>
					<ratings-four [ratings]='level1.assessments' [title]='level1.tooltip' *ngIf='!level1.question'>
					</ratings-four>
				</ng-container>
				<ng-container *ngIf='shouldShowRatingsFive(level1)'>
					<ng-container *ngIf='aggregateBy==="questions"'>
						<ratings-five [ratings]='level1.ratings' class='level1' [title]='level1.tooltip'></ratings-five>
					</ng-container>
					<ng-container *ngIf='aggregateBy==="grades"'>
						<span class='grade'>{{grade(level1)}}</span>
					</ng-container>
					<ng-container *ngIf='aggregateBy==="scores"'>
						<span class='grade' [title]='level1.tooltip'>{{level1.score.toFixed(1)}}</span>
					</ng-container>
				</ng-container>
			</mat-panel-title>
		</mat-expansion-panel-header>

		<div *ngIf='level1.question' class='question'>{{level1.question.text}}</div>

		<div class="mat-expansion-body" *ngIf='parent1.expanded'>
			<mat-accordion>
				<mat-expansion-panel *ngFor='let level2 of level1.children' #parent2>
					<mat-expansion-panel-header>
						<mat-panel-title>
							<assessment-level-title [level]='level2'></assessment-level-title>
							<ng-container *ngIf='aggregateBy==="assessments"'>
								<ratings-four [ratings]='level2.assessments' [title]='level2.tooltip'
									*ngIf='level1.question'></ratings-four>
								<rating-four [rating]='level2.assessments' [title]='level2.tooltip'
									*ngIf='!level1.question && !level2.question'></rating-four>
							</ng-container>
							<ng-container *ngIf='shouldShowRatingsFive(level2)'>
								<ng-container *ngIf='aggregateBy==="questions"'>
									<ratings-five [ratings]='level2.ratings' class='level2' [title]='level2.tooltip'>
									</ratings-five>
								</ng-container>
								<ng-container *ngIf='aggregateBy==="grades"'>
									<span class='grade'>{{grade(level2)}}</span>
								</ng-container>
								<ng-container *ngIf='aggregateBy==="scores"'>
									<span class='grade' [title]='level2.tooltip'>{{level2.score.toFixed(1)}}</span>
								</ng-container>
							</ng-container>
						</mat-panel-title>
					</mat-expansion-panel-header>

					<div *ngIf='level2.question' class='question'>{{level2.question.text}}</div>

					<div class="mat-expansion-body" *ngIf='parent2.expanded'>
						<mat-accordion>
							<mat-expansion-panel *ngFor='let level3 of level2.children'>
								<mat-expansion-panel-header>
									<mat-panel-title>
										<assessment-level-title [level]='level3'></assessment-level-title>
										<rating-five [rating]='+level3.answer.answer'
											*ngIf='(aggregateBy!=="assessments" || level3.question) && level3.answer.question.questionTypeId===4'>
										</rating-five>
										<rating-four [rating]='level3.assessments' [title]='level3.tooltip'
											*ngIf='aggregateBy==="assessments" && !level3.question'></rating-four>
									</mat-panel-title>
								</mat-expansion-panel-header>
								<div *ngIf='level3.question' class='question'>{{level3.question.text}}</div>
								<readonly-question [answer]='level3.answer' [showQuestion]='false'></readonly-question>
							</mat-expansion-panel>
						</mat-accordion>
					</div>
				</mat-expansion-panel>

			</mat-accordion>
		</div>

	</mat-expansion-panel>

</mat-accordion>