import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DbsPerson, Old_MinimalContact, PersonWithSiteTags, TagName } from '@me-interfaces';
import { DdSite } from '@me-services/core/old-dd';
import { FuncService } from '@me-services/core/func';
import { UtilityService } from '@me-services/core/utility';
import { Icon, getIconContext } from '@me-shared-parts/UI-common';
import { DataService } from '@me-services/core/data';


export interface EnableablePerson extends Old_MinimalContact {
	enabled: boolean;
	message?: string;
}
interface SelectedPerson extends EnableablePerson {
	selected: boolean; 	// person is in the selectedPeople array
	tagged: boolean;	// person is in the taggedPeople array
}

@Component({
	selector: 'person-selector-by-approved-role',
	templateUrl: './SHR-CMN_person-selector-by-approved-role.part.html',
	styleUrls: ['SHR-CMN_person-selector-by-approved-role.part.scss'],
})
export class PersonSelectorByApprovedRolePart implements OnInit, OnChanges {

	@Input() site: DdSite;
	@Input() tag: TagName;
	@Input() selectedPeople: EnableablePerson[] = [];
	@Output() selectionChange = new EventEmitter();

	selectPlaceholder = 'Select People';
	plus = false;

	personToAdd: DbsPerson = undefined;
	addingPerson = false;

	selectionHasChanged = false;

	taggedPeople: PersonWithSiteTags[] = [];
	people: SelectedPerson[] = [];
	selectedPersonIds: number[] = [];
	triggerText: string;
	public searchIcon = getIconContext(Icon.action_search, undefined, 'never', 'Search Person');

	constructor(
		private func: FuncService,
		private ds: DataService,
		private util: UtilityService,
	) {
	}

	async ngOnInit() {
		const tag = this.tag;
		this.taggedPeople = await this.func.admin.site.getPeopleWithTags({ siteCode: this.site.code, tag });
		this.peopleChanged();
	}

	async ngOnChanges() {
		const tag = this.util.text.titleCase(this.tag);
		this.selectPlaceholder = `Select ${tag.split('-')[0]}s`;
		// this.addPlaceholder = `Add a ${tag.split('-')[0]}`;
		this.peopleChanged();
	}

	async searchPerson() {
		this.personToAdd = undefined;
		const conceptAndId = await this.ds.searcher.selectPerson();
		if (conceptAndId) {
			this.personToAdd = await this.ds.admin.person.getOne(conceptAndId.id);
		}
	}

	peopleChanged() {
		this.taggedPeople;
		this.selectedPeople;

		const people: SelectedPerson[] = this.taggedPeople.map(p => {
			return Object.assign(
				{ selected: false, tagged: true, enabled: true },
				{
					personId: p.personId,
					firstName: p.firstName,
					middleInit: p.middleInit,
					lastName: p.lastName,
					fullName: p.fullName,
					phone: p.phone,
					email: p.email,
					hasAvatar: p.hasAvatar,
					noteCounts: {
						total: p.noteCounts.total,
						redFlags: p.noteCounts.redFlags,
					}
				});
		});

		const personById = people.reduce((value, person) => {
			value[person.personId] = person;
			return value;
		}, {});

		for (const selectedPerson of this.selectedPeople) {
			const person = personById[selectedPerson.personId];

			if (person) {
				person.selected = true;
				person.enabled = selectedPerson.enabled;
				person.message = selectedPerson.message;
			}
			else people.push(Object.assign({ selected: true, tagged: false, }, selectedPerson));
		}

		this.people = people.sort((p1, p2) => {
			if (p1.firstName !== p2.firstName) return p1.firstName > p2.firstName ? 1 : -1;
			if (p1.lastName !== p2.lastName) return p1.lastName > p2.lastName ? 1 : -1;
			return p2.personId - p1.personId;
		});

		this.selectedPersonIds = this.people.filter(p => p.selected).map(p => p.personId);
		this.updateTriggerText();
	}

	updateTriggerText() {
		this.triggerText = `${this.selectedPersonIds.length} of ${this.people.length} selected.`;
	}

	selectionChanged(e) {
		this.selectionHasChanged = true;
		this.selectedPersonIds = e.value;
		const m = this.selectedPersonIds.reduce((v, personId) => {
			v[personId] = true;
			return v;
		}, {});

		for (const person of this.people) {
			person.selected = !!m[person.personId];
		}

		this.updateTriggerText();
	}

	async addPerson() {
		this.addingPerson = true;

		const personId = this.personToAdd.personId;

		await this.func.admin.contactsDb.contact.addTag({ personId, tagFullName: `${this.site.code.toLowerCase()}: ${this.tag}` });

		const currentPerson = this.people.find(p => p.personId == personId);
		if (currentPerson) {
			currentPerson.selected = true;
		}
		else {
			this.people.push({
				email: this.personToAdd._email,
				firstName: this.personToAdd.firstName,
				lastName: this.personToAdd.lastName,
				fullName: this.personToAdd._name,
				hasAvatar: false,
				middleInit: this.personToAdd.middleInit,
				personId: this.personToAdd.personId,
				phone: {
					number: this.personToAdd.phone,
					canText: false,
				},
				noteCounts: {
					redFlags: 0,
					total: 0,
				}
				, selected: true, tagged: true, enabled: true,
			});
		}


		//
		// Also add to the selectedPersonIds list which sets the checkmarks in the dropdown
		//
		if (!this.selectedPersonIds.includes(personId)) this.selectedPersonIds.push(personId);

		//
		// Raising the event usually causes the parent component to reload data which would
		// rebind the person data and trigger ngOnChanges
		//
		this.selectionChange.emit(this.people);

		this.personToAdd = undefined;
		this.plus = false;
		this.addingPerson = false;
	}

	openChanged(opened) {
		if (!opened && this.selectionHasChanged) {
			this.selectionChange.emit(this.people);
		}
		this.selectionHasChanged = false;
	}

	showPlus(e) {
		this.plus = e.source.checked;
	}
}
