import { SiteAreaData, SiteAreaService } from '@SITE-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AppAreaIdentifier, AppAreaIdentifierAndAccess, CaptionedLayout, DbcSiteIntern, DbsPerson, DbsSiteProgram, LanguageId, Site, SiteAreaAccess } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { StaffMemberService } from '@me-services/old-services-and-wrappers/staff-member';
import { DialogService } from '@me-services/ui/dialog';
import { Observable, combineLatest } from 'rxjs';
import { distinctUntilChanged, mergeMap } from 'rxjs/operators';


interface OfferedSiteProgram {
	siteProgramId: number,
	siteProgram: DbsSiteProgram,
	manager: DbsPerson,
	altManager?: DbsPerson,
	internIdsWithAccess: number[],
}
interface ProgramLangList {
	language: string,
	programs: ProgramWithInterns[],
}
interface ProgramWithInterns {
	siteProgram: {
		siteProgramId: number,
		isActive: boolean,
	},
	program: {
		programId: number,
		name: string,
		languageId: number,
		programType: string,
	},
	manager: CaptionedLayout,
	altManager: CaptionedLayout,
	numInternsAssigned: number,
	interns: {
		siteIntern: DbcSiteIntern,
		person: DbsPerson,
		isIntern: boolean,
	}[],
}


@Component({
	selector: 'site-config-programs-part',
	templateUrl: './config-programs.part.html',
	styleUrls: ['./config-programs.part.scss'],
})
export class ConfigProgramsPart extends DestroyablePart implements OnInit {

	public readonly LanguageId_English = LanguageId.English;
	site: Site;
	readonly: boolean;
	offeredPrograms: OfferedSiteProgram[];

	currentlyExpandedProgramId = -1;
	languageList$: Observable<ProgramLangList[]>;
	eforallStaff: CaptionedLayout[];
	accordionDisableAnimation: boolean;

	constructor(
		private dialogService: DialogService,
		private siteAreaService: SiteAreaService,
		private staffService: StaffMemberService,
		private util: UtilityService,
		private ds: DataService,
	) { super(); }

	async ngOnInit() {

		super.initDestroyable();
		this.accordionDisableAnimation = true;

		const staffMembers = await this.staffService.getCrmUsers();
		this.eforallStaff = staffMembers.map(person => ({
			text: person._name,
			caption: person._email,
			person,
		}));

		this.languageList$ = combineLatest([
			this.ds.admin.singletonsAsOfUTC$,
			this.siteAreaService.accessAndId$,
			this.siteAreaService.data$,
		]).pipe(
			mergeMap(async data => await this.buildView(data[0], data[1], data[2])),
			distinctUntilChanged((e1, e2) => {
				const a = this.util.values.areSame(e1, e2);
				return a;
			})
		);

		setTimeout(() => {
			this.accordionDisableAnimation = false;
		}, 2000);

	}



	async buildView(singletonsAsOfUTC: number, accessAndId: AppAreaIdentifierAndAccess<AppAreaIdentifier<number>, SiteAreaAccess>, data: SiteAreaData) {
		this.readonly = accessAndId?.access?.sub['config'] != 'Write';

		if (!data) return undefined;

		this.site = data.site;
		const sitePrograms = await this.ds.admin.siteProgram.getManyPackagesAsMap([...data.offeredSitePrograms.map(pgm => pgm.siteProgramId)]);

		const offeredSitePrograms = data.offeredSitePrograms.map(pgm => {
			return {
				...pgm,
				siteProgram: sitePrograms[pgm.siteProgramId],
			};
		});


		const langList: ProgramLangList[] = [];
		const programs = await this.ds.admin.program.getAllPackagesAsArray();

		for (const program of programs) {

			const matchFound = langList.find(l => l.language == program.language.name);

			let manager: { caption: string, person: DbsPerson, text: string, };
			let altManager: { caption: string, person: DbsPerson, text: string, };
			let siteProgram: { siteProgramId: number, isActive: boolean, };
			const internPeople = await this.ds.admin.person.getManyAsMap(data.interns.map(intern => intern.siteIntern.personId));
			let spInterns: { siteIntern: DbcSiteIntern, person: DbsPerson, isIntern: boolean }[] = data.interns.map(intern => ({ siteIntern: intern.siteIntern, person: internPeople[intern.siteIntern.personId], isIntern: false }));

			const programOffered = offeredSitePrograms.find(pgm => pgm.siteProgram && pgm.siteProgram.programId == program.programId);

			if (programOffered) {
				manager = programOffered.siteProgram.managerId ? {
					caption: '',
					person: programOffered.siteProgram.manager,
					text: '',
				} : undefined;

				altManager = programOffered.siteProgram.altManagerId ? {
					caption: '',
					person: programOffered.siteProgram.altManager,
					text: '',
				} : undefined;

				siteProgram = {
					siteProgramId: programOffered.siteProgram.siteProgramId,
					isActive: programOffered.siteProgram.isActive,
				};

				spInterns = spInterns.map(intern => {
					intern.isIntern = programOffered.internIdsWithAccess.find(personId => personId == intern.person.personId) ? true : false;
					return intern;
				});
			}

			const programWithInterns: ProgramWithInterns = {
				siteProgram,
				program: {
					programId: program.programId,
					name: program.name,
					languageId: program.languageId,
					programType: program.programType.name,
				},
				manager,
				altManager,
				interns: spInterns,
				numInternsAssigned: spInterns.reduce((a, i) => {
					if (i.isIntern) a++;
					return a;
				}, 0),
			};

			if (matchFound) {
				matchFound.programs.push(programWithInterns);
			}
			else {
				langList.push({
					language: program.language.name,
					programs: [programWithInterns],
				});
			}
		}
		return langList.sort((a, b) => a.language > b.language ? 1 : -1);

	}

	onExpand(programsWithInterns: ProgramWithInterns) {
		this.currentlyExpandedProgramId = programsWithInterns.program.programId;
	}

	async setProgramOffered(programWithInterns: ProgramWithInterns, e, type: 'checkbox' | 'switch') {
		let enableProgram: boolean;
		let message = `Do you want to remove this program from your site? No data will be lost.`;
		if (type == 'switch') {
			enableProgram = e;
			message = `Do you want to stop accepting applications for this program from this site? No data will be lost. You can always view all the existing applications.`
		}
		else enableProgram = e.checked;

		const internMsg = programWithInterns.numInternsAssigned >= 1 ? `\n The ${programWithInterns.numInternsAssigned} intern${programWithInterns.numInternsAssigned > 1 ? 's' : ''} with access will be removed automatically.` : '';

		if (!enableProgram) {
			const yes = await this.dialogService.confirm(`${message}${internMsg}`);
			if (yes) await this.siteAreaService.config.actions.setProgramIsOffered({ programId: programWithInterns.program.programId, enableProgram });
		}

		if (enableProgram) {
			await this.siteAreaService.config.actions.setProgramIsOffered({ programId: programWithInterns.program.programId, enableProgram });
		}
	}

	async setManager(manager: CaptionedLayout, programWithInterns: ProgramWithInterns) {
		let previousManagerId: number;
		if (programWithInterns.manager) previousManagerId = programWithInterns.manager.person.personId;

		await this.siteAreaService.config.actions.setProgramManager({
			siteProgramId: programWithInterns.siteProgram.siteProgramId,
			managerId: manager.person.personId,
			previousManagerId,
		});
	}

	async setAltManager(altManager: CaptionedLayout, programWithInterns: ProgramWithInterns) {
		let previousAltManagerId: number;
		if (programWithInterns.altManager) previousAltManagerId = programWithInterns.altManager.person.personId;

		await this.siteAreaService.config.actions.setAltProgramManager({
			siteProgramId: programWithInterns.siteProgram.siteProgramId,
			altManagerId: altManager.person.personId,
			previousAltManagerId,
		});
	}

	async removeAltManager(programWithInterns: ProgramWithInterns) {
		let previousAltManagerId: number;
		if (programWithInterns.altManager) previousAltManagerId = programWithInterns.altManager.person.personId;

		await this.siteAreaService.config.actions.setAltProgramManager({
			siteProgramId: programWithInterns.siteProgram.siteProgramId,
			altManagerId: undefined,
			previousAltManagerId,
		});
	}

	async addProgramIntern(e, programWithInterns: ProgramWithInterns, intern: { siteIntern: DbcSiteIntern, person: DbsPerson, isIntern: boolean }) {
		if (!e.checked) {
			await this.siteAreaService.config.actions.removeProgramIntern({
				siteInternId: intern.siteIntern.siteInternId,
				siteProgramId: programWithInterns.siteProgram.siteProgramId,
				personId: intern.person.personId,
			});
		}
		else if (e.checked) {
			if (intern && programWithInterns && programWithInterns.siteProgram.siteProgramId) {
				await this.siteAreaService.config.actions.addProgramIntern({
					siteInternId: intern.siteIntern.siteInternId,
					siteProgramId: programWithInterns.siteProgram.siteProgramId,
					personId: intern.person.personId,
				});
			}
		}
	}
}