<div class="explorer-note-box-part {{box.explorerBoxClass}}">

	<div class="explorer-box-header">{{ box.header }}</div>

	<div class="explorer-box-buttons">
		<ng-container *ngFor="let button of box.buttons">
			<explorer-button *ngIf="button.explorer" [button]="button.explorer"></explorer-button>
		</ng-container>
	</div>
	<div class="explorer-note-content">
		<h4>{{box.subject}}</h4> - {{box.note}}
	</div>
</div>