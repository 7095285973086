import { RegionAreaService, RegionPageService } from '@REGION-area';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { CompanyCols, GridColumnType, GridSetup, SELECTION_GRID_ACTION_KEY } from '@me-grid';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { GridPart } from '@me-shared-parts/UI-common';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';


interface BusinessRow {
	companyId: number,
	programs: string,
	updatedUTC: number,
}


@Component({
	selector: 'region-businesses-view-part',
	templateUrl: './businesses-view.part.html',
})
export class RegionBusinessesViewPart extends DestroyablePart implements OnInit {

	public gridSetup = this.setupGrid();

	public rows$: Observable<BusinessRow[]> = of([]);

	@ViewChild(GridPart) meGrid: GridPart<BusinessRow>;

	constructor(
		private util: UtilityService,
		public regionAreaService: RegionAreaService,
		public ds: DataService,
		public pageService: RegionPageService
	) {
		super();
	}

	ngOnInit(): void {

		super.initDestroyable();

		this.rows$ = this.ds.admin.singletonsAsOfUTC$.pipe(mergeMap(data => this.buildRows(data)));
	}

	async buildRows(singletonsAsOfUTC: number) {

		const identifier = this.regionAreaService.getId();
		const regionId = identifier.id;

		const sites = (await this.ds.admin.site.getByRegionIds([regionId]))[regionId];

		const siteIds = sites.map(site => site.siteId);

		const applications = (await this.ds.admin.application.getAllPackagesAsArray()).filter(appl => (appl.accId || appl.picId) && appl.siteProgram.siteId && siteIds.includes(appl.siteProgram.siteId));

		const companies: {
			companyId: number,
			programs: string[],
			applicationUpdatedUTC: number,
		}[] = [];

		for (const application of applications) {
			const company = companies.find(company => company.companyId == application.companyId);
			if (!company) {
				companies.push({
					companyId: application.companyId,
					programs: [application.accId ? `${application.siteProgram.site.code}-${application.accelerator.name} Accelerator` : `${application.siteProgram.site.code}-${application.pitchContest.name} Pitch Contest`],
					applicationUpdatedUTC: application.updatedUTC,
				});
			}
			else {
				company.programs.push(application.accId ? `${application.siteProgram.site.code}-${application.accelerator.name} Accelerator` : `${application.siteProgram.site.code}-${application.pitchContest.name} Pitch Contest`);
			}
		}



		const rows: BusinessRow[] = companies.map(company => ({
			companyId: company.companyId,
			programs: company.programs.join(', '),
			updatedUTC: company.applicationUpdatedUTC,
		}));

		return rows;
	}



	private setupGrid(): GridSetup<BusinessRow> {

		return {
			experience: 'COMPANY',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Business",
			rowPluralName: "Businesses",
			rowKey: "companyId",
			stateKey: "region-businesses",
			canAdd: false,
			canRefresh: false,
			canDownload: false,
			columnsToAdd: [
				{ field: "programs", header: "Program Names", width: 300, type: GridColumnType.text, hidden: false },
				{ field: CompanyCols.status, header: 'Status', width: 100, type: GridColumnType.text, hidden: false },

			],
			initialState: {
				sort: [{ field: CompanyCols.name, dir: 'asc' }],
			},
		};
	}



	async gridActionHandler(action: { actionKey: string, rows: BusinessRow[] }) {

		if (action.actionKey == SELECTION_GRID_ACTION_KEY) {
			// Nothing to do with a simple row selection
		}
	}

}