import { DbsAccelerator, DbsAccInterviewer, DbsAccJudge, DbsAccReader, DbsAccSessionSpecialistSurveyResponse, DbsAccSessionSurveyResponse, DbsAccSessionTopicSurveyResponse, DbsAccTeam, DbsAccTeamMember, DbsAgreementSignature, DbsAgreementType, DbsAgreementVersion, DbsApplication, DbsApplicationParticipant, DbsAward, DbsCompany, DbsCouncilMember, DbsEmail, DbsEntityNote, DbsEvent, DbsPerson, DbsPersonTag, DbsPicJudge, DbsPicReader, DbsPicTeam, DbsPicTeamMember, DbsPitchContest, DbsPosition, DbsProgram, DbsRegion, DbsRelationship, DbsSite, DbsSiteProgram, DbsSpecialist, DbsTag, DbsTagPrefix, DbsVenue, DbsWebLink } from "@me-interfaces";
import { DexieService } from "@me-services/core/dexie";
import { UtilityService } from "@me-services/core/utility";
import { SingletonManagers } from '../interfaces';
import { SingletonsManager } from "../singletons-manager";


export function initSingletonManagers(
	dexie: DexieService,
	util: UtilityService,
): SingletonManagers {

	return {
		dbsCompany: new SingletonsManager<DbsCompany>(dexie, util, 'companies', 'companyId'),
		dbsPerson: new SingletonsManager<DbsPerson>(dexie, util, 'people', 'personId'),
		dbsAccelerator: new SingletonsManager<DbsAccelerator>(dexie, util, 'accelerators', 'accId'),
		dbsAccInterviewer: new SingletonsManager<DbsAccInterviewer>(dexie, util, 'accInterviewers', 'accInterviewerId'),
		dbsAccJudge: new SingletonsManager<DbsAccJudge>(dexie, util, 'accJudges', 'accJudgeId'),
		dbsAccReader: new SingletonsManager<DbsAccReader>(dexie, util, 'accReaders', 'accReaderId'),
		dbsAccSessionSurveyResponse: new SingletonsManager<DbsAccSessionSurveyResponse>(dexie, util, 'accSessionSurveyResponses', 'accSessionSurveyResponseId'),
		dbsAccSessionTopicSurveyResponse: new SingletonsManager<DbsAccSessionTopicSurveyResponse>(dexie, util, 'accSessionTopicSurveyResponses', 'accSessionTopicSurveyResponseId'),
		dbsAccSessionSpecialistSurveyResponse: new SingletonsManager<DbsAccSessionSpecialistSurveyResponse>(dexie, util, 'accSessionSpecialistSurveyResponses', 'accSessionSpecialistSurveyResponseId'),
		dbsAccTeam: new SingletonsManager<DbsAccTeam>(dexie, util, 'accTeams', 'accTeamId'),
		dbsAccTeamMember: new SingletonsManager<DbsAccTeamMember>(dexie, util, 'accTeamMembers', 'accTeamMemberId'),
		dbsAgreementSignature: new SingletonsManager<DbsAgreementSignature>(dexie, util, 'agreementSignatures', 'agreementSignatureId'),
		dbsAgreementType: new SingletonsManager<DbsAgreementType>(dexie, util, 'agreementTypes', 'agreementTypeId'),
		dbsAgreementVersion: new SingletonsManager<DbsAgreementVersion>(dexie, util, 'agreementVersions', 'agreementVersionId'),
		dbsApplication: new SingletonsManager<DbsApplication>(dexie, util, 'applications', 'applicationId'),
		dbsApplicationParticipant: new SingletonsManager<DbsApplicationParticipant>(dexie, util, 'applicationParticipants', 'applicationParticipantId'),
		dbsAward: new SingletonsManager<DbsAward>(dexie, util, 'awards', 'awardId'),
		dbsCouncilMember: new SingletonsManager<DbsCouncilMember>(dexie, util, 'councilMembers', 'councilMemberId'),
		dbsEmail: new SingletonsManager<DbsEmail>(dexie, util, 'emails', 'emailId'),
		dbsEntityNote: new SingletonsManager<DbsEntityNote>(dexie, util, 'entityNotes', 'noteId'),
		dbsEvent: new SingletonsManager<DbsEvent>(dexie, util, 'events', 'eventId'),
		dbsPersonTag: new SingletonsManager<DbsPersonTag>(dexie, util, 'personTags', 'personTagId'),
		dbsPicJudge: new SingletonsManager<DbsPicJudge>(dexie, util, 'picJudges', 'picJudgeId'),
		dbsPicReader: new SingletonsManager<DbsPicReader>(dexie, util, 'picReaders', 'picReaderId'),
		dbsPicTeam: new SingletonsManager<DbsPicTeam>(dexie, util, 'picTeams', 'picTeamId'),
		dbsPicTeamMember: new SingletonsManager<DbsPicTeamMember>(dexie, util, 'picTeamMembers', 'picTeamMemberId'),
		dbsPitchContest: new SingletonsManager<DbsPitchContest>(dexie, util, 'pitchContests', 'picId'),
		dbsPosition: new SingletonsManager<DbsPosition>(dexie, util, 'positions', 'positionId'),
		dbsProgram: new SingletonsManager<DbsProgram>(dexie, util, 'programs', 'programId'),
		dbsRegion: new SingletonsManager<DbsRegion>(dexie, util, 'regions', 'regionId'),
		dbsRelationship: new SingletonsManager<DbsRelationship>(dexie, util, 'relationships', 'personPersonId'),
		dbsSiteProgram: new SingletonsManager<DbsSiteProgram>(dexie, util, 'sitePrograms', 'siteProgramId'),
		dbsSite: new SingletonsManager<DbsSite>(dexie, util, 'sites', 'siteId'),
		dbsSpecialist: new SingletonsManager<DbsSpecialist>(dexie, util, 'specialists', 'specialistId'),
		dbsTag: new SingletonsManager<DbsTag>(dexie, util, 'tags', 'tagId'),
		dbsTagPrefix: new SingletonsManager<DbsTagPrefix>(dexie, util, 'tagPrefixes', 'tagPrefixId'),
		dbsVenue: new SingletonsManager<DbsVenue>(dexie, util, 'venues', 'venueId'),
		dbsWebLink: new SingletonsManager<DbsWebLink>(dexie, util, 'webLinks', 'webLinkId'),
	};
}