<me-dialog-frame [header]='headerText' [actions]='actions' [dialogRef]='dialogRef' [icon]='icon' alertType='editor'>

	<div class='acc-stage-editor-dialog'>
		<table style='width:100%' class='pro-table pro-table-no-header pro-table-with-labels pro-table-without-border'>
			<tr>
				<td>Current Stage</td>
				<td>{{orgAccStage.name}}</td>
			</tr>
			<tr>
				<td>Stage</td>
				<td>
					<me-droplist *ngIf="accStageItems" [items]='accStageItems' [selectedItem]='selectedAccStageItem'
						(selectionChange)="onDroplistChange($event)" sortBy='customSort'
						[readonly]='readonly'></me-droplist>
				</td>
			</tr>
		</table>
		<!-- <div *ngIf="saveAction.enabled">
			More info about applications in wrong state
		</div> -->
	</div>
</me-dialog-frame>