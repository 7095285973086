import { AccApplicationContext, AccAreaService, AccTeamDroppedOutWeekEditorDialog, AccTeamPageService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DestroyablePart } from '@me-access-parts';
import { AccAreaAccess, AccTeam, Accelerator, AppAreaIdentifierWithSiteId, Award, DbConceptName, DbsCompany } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { DialogAction, DialogService } from '@me-services/ui/dialog';
import { DroplistItem, Icon, PageTabsLayout, getIconContext, getIconContextForIndustry } from '@me-shared-parts/UI-common';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { mergeMap } from 'rxjs/operators';


interface TeamRow {
	acc: Accelerator,
	accTeam: AccTeam,
	company: DbsCompany,
	accTeamList: DroplistItem<never>[],
	accTeamSelectedItem: DroplistItem<never>,
	awardsCount: number,
	applicationText: string | undefined,
}


@Component({
	selector: 'acc-team-page',
	templateUrl: './acc-team.page.html',
	styleUrls: ['./acc-team.page.scss'],
})
export class AccTeamPage extends DestroyablePart implements OnInit {

	readonly = false;
	private accTeamId$ = new BehaviorSubject<number>(undefined);
	accTeamId: number;

	icons = {
		appl: getIconContext(Icon.concept_application, undefined, 'lt-xl'),
		x: getIconContext(Icon.status_x, undefined, 'lt-sm', "Drop Out Week"),
		check: getIconContext(Icon.status_check, undefined, 'lt-sm', "Not Dropped Out"),
		company: getIconContext(Icon.concept_company, undefined, 'lt-xl'),
		accTeam: getIconContext(Icon.dialog_explore, undefined, 'lt-xl', 'Explore'),
	}

	accTeamData$ = combineLatest([
		this.accAreaService.teams.teams$,
		this.accAreaService.applications.applications$,
		this.accAreaService.accelerator.acc$,
		this.accTeamId$,
	]).pipe(mergeMap(data => {
		const [accTeams, applications, acc, accTeamId] = data;
		return this.buildAccTeamRow(accTeams, applications, acc, accTeamId);
	}));

	constructor(
		private dialogService: DialogService,
		private router: Router,
		public accAreaService: AccAreaService,
		private route: ActivatedRoute,
		private pageService: AccTeamPageService,
		private ds: DataService,
	) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();

		this.accAreaService.subscribeAccess(this.destroyed$, this.accessChanged);
	}

	accessChanged = async (value: { access: AccAreaAccess, id: AppAreaIdentifierWithSiteId<number> }) => {
		if (!value) return;

		const { access, id } = value;
		if (access) this.readonly = this.readonly = value?.access?.root != 'Write';

		if (id) {
			this.accTeamId = id.subPath?.id;
			if (this.accTeamId != this.accTeamId$.value) {
				this.accTeamId$.next(this.accTeamId);
			}
		}
	}

	async buildAccTeamRow(
		allAccTeams: readonly AccTeam[],
		allApplications: readonly AccApplicationContext[],
		acc: Accelerator,
		accTeamId: number,
	): Promise<TeamRow> {

		if (!allAccTeams || !allApplications || !accTeamId) return undefined;

		const accTeam = allAccTeams.find(team => team.accTeamId === accTeamId);
		this.icons.accTeam.text = `${accTeam.name} Team`;
		const application = allApplications.find(app => app.application.applicationId === accTeam.applicationId);
		const company = accTeam.application.company;
		const accTeamAwards: readonly Award[] = (await this.ds.admin.award.getByAccTeamIds([accTeam.accTeamId]))[accTeam.accTeamId];


		const accTeamList = allAccTeams
			.map<DroplistItem>(accTeam => ({ uniqueId: accTeam.accTeamId, groupName: `${accTeam.application.applicationStatusId}`, customSort: `${accTeam.application.company._name}`, iconContext: getIconContextForIndustry(accTeam.application.company.industryId, undefined, undefined, accTeam.application.company._name) }));

		const accTeamSelectedItem = accTeamList.find(a => a.uniqueId === accTeamId);

		return {
			acc,
			accTeam,
			company,
			accTeamList,
			accTeamSelectedItem,
			awardsCount: accTeamAwards.length,
			applicationText: application.ideaText,
		};

	}


	onAccTeamChange(e: DroplistItem) {
		const selectedId = e?.uniqueId;
		if (selectedId) {
			this.accTeamId$.next(selectedId);
			this.accTeamId = selectedId;
			this.router.navigate(['../', selectedId], { relativeTo: this.route });
		}
	}


	onTeamNotesChange(accTeamId: number, notes: string) {
		this.accAreaService.teams.actions.setNotes({ accTeamId, notes });
	}


	async toggleDroppedOut(acc: Accelerator, accTeamId: number, droppedOutWeek: number | undefined) {
		await this.openDroppedOutWeekDialog(acc, accTeamId, droppedOutWeek);
	}


	async openDroppedOutWeekDialog(acc: Accelerator, accTeamId: number, droppedOutWeek: number | undefined) {
		const action: DialogAction<number> = await this.dialogService.showCustom(
			AccTeamDroppedOutWeekEditorDialog,
			{
				data: {
					readonly: this.readonly,
					droppedOutWeek,
					acc,
				},
			},
			350, 200
		);

		const id = action?.id;
		if (id == 'save') {
			this.accAreaService.teams.actions.setDroppedOutWeek({ accTeamId, droppedOutWeek: action.callbackResult });
		}
	}


	onLayoutChange(layout: PageTabsLayout) {
		this.pageService.changeLayout(layout);
	}


	async inspectApplication(applicationId: number) {
		if (!applicationId) return;
		await this.ds.explorer.explore(DbConceptName.Application, applicationId);
	}

	async inspectCompany(companyId: number) {
		if (!companyId) return;
		await this.ds.explorer.explore(DbConceptName.Company, companyId);
	}

	async inspectAccTeam(accTeamId: number) {
		if (!accTeamId) return;
		await this.ds.explorer.explore(DbConceptName.AccTeam, accTeamId);
	}
}
