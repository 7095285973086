import { SuffixId } from '@me-interfaces';
import { DdSuffixRow } from './dd-suffix-row';
import { DdObject } from '../dd-object';
import { OldDomainDataService } from '../domain-data.service';

/**
 * @deprecated Use the DataService instead
 */
export class DdSuffix extends DdObject<DdSuffixRow> implements DdSuffixRow {

	constructor(dd: OldDomainDataService, data: DdSuffixRow) {
		super(data);
	}

	public get suffixId(): SuffixId {
		return this._data.suffixId;
	}

	public get name(): string {
		return this._data.name;
	}
}