import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@me-environment';
import { DbcUser, ImmutableArray, LanguageId, UserAlert } from '@me-interfaces';
import { OldUserService } from '@me-services/old-services-and-wrappers/user';
import { AuthService } from '@me-services/core/auth';
import { DataService } from '@me-services/core/data';
import { BlurService } from '@me-services/ui/blur';
import { DialogService } from '@me-services/ui/dialog';
import { LabelsService } from '@me-services/ui/labels';
import { MenuService } from '@me-services/ui/menu';
import { UrlService } from '@me-services/ui/url';
import { takeUntil } from 'rxjs/operators';
import { DestroyablePart } from '../ACS_destroyable.part';
import { AddCompanyInstructionsDialog } from './ACS_add-company-instructions.dialog';


@Component({
	selector: 'me-navbar',
	templateUrl: './ACS_navbar.part.html',
	styleUrls: ['./ACS_navbar.part.scss']
})
export class NavbarPart extends DestroyablePart implements OnInit {
	env: string;
	logo: 'eforall' | 'eparatodos' = 'eforall';
	language: 'English' | 'Spanish';
	isSpanish = false;
	user: DbcUser;
	alerts: ImmutableArray<UserAlert> = [];
	openAppDashboard = 'Open App Dashboard';


	constructor(
		urlService: UrlService,
		public router: Router,
		public auth: AuthService,
		private userService: OldUserService,
		public menu: MenuService,
		public labels: LabelsService,
		private dialogService: DialogService,
		public blurService: BlurService,
		private ds: DataService,
	) {

		super();
		this.env = environment.name;
		this.language = this.labels.languageId == LanguageId.Spanish ? 'Spanish' : 'English';
		this.logo = urlService.logo;
	}


	async ngOnInit() {
		super.initDestroyable();

		this.userService.user$
			.pipe(takeUntil(this.destroyed$))
			.subscribe(user => {
				this.user = user;
				this.alerts = user.alerts;
			});

		this.openAppDashboard = await this.labels.get({ key: "Open App Dashboard" });
	}


	async addCompany() {
		await this.dialogService.showCustom(AddCompanyInstructionsDialog, undefined, 400, 300);
	}


	/**
	 * Navigate to the alerts routerLink and then remove the alert from the list.
	 * @param alert
	 */
	handleAlert(alert: UserAlert) {
		this.router.navigate([alert.routerLink]);
		setTimeout(() => {
			this.alerts = this.alerts.filter(a => a.routerLink !== alert.routerLink);
		}, 1000);
	}


	async rebuildCache() {

		//
		// Replace the database with an empty one
		//
		await this.ds.resetDatabase();


		location.reload();
	}

	public async search() {
		const conceptAndId = await this.ds.searcher.select();

		if (conceptAndId) {
			await this.ds.explorer.explore(conceptAndId.conceptName, conceptAndId.id);
		}
	}

	public async explore(){
		await this.ds.explorer.exploreLast();
	}
}