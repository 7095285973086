import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogContext } from '@me-services/ui/dialog';

@Component({
	selector: 'add-company-instructions-dialog',
	templateUrl: './ACS_add-company-instructions.dialog.html',
	styleUrls: ['./ACS_add-company-instructions.dialog.scss']
})
export class AddCompanyInstructionsDialog {

	constructor(
		public dialogRef: MatDialogRef<AddCompanyInstructionsDialog>,
		@Inject(MAT_DIALOG_DATA) dialog: DialogContext<unknown>) {
	}

	onNoClick(): void {
		this.dialogRef.close();
	}
}
