
import { DdPicStageRow } from './dd-pic-stage-row';
import { DdPicStage } from './dd-pic-stage';
import { DdObjects } from '../dd-objects';
import { OldDomainDataService } from '..';

/**
 * @deprecated Use the DataService instead
 */
export class DdPicStages extends DdObjects<DdPicStageRow, DdPicStage> {

	constructor(private dd: OldDomainDataService) {
		super('picStageId');
	}

	async loadData(hash: string, data: DdPicStageRow[]) {
		const objects = data.map(d => new DdPicStage(this.dd, d));
		this.loadObjects(hash, objects);
	}
}