/**
 * @deprecated Use Accelerator from the DataService instead
 */
export interface DdAccRow {
	accId: number,
	accStageId: number,
	siteProgramId: number,
	directorId: number,
	managerId: number,
	name: string,
	longName: string,
	startUTC: number,
	createdUTC: number,
	applStartUTC: number,
	applDuration: number,
}