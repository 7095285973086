import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import {
	ApplicationDeleteDialog,
	ConfirmationDialog,
	MessageDialog,
	NotesDialog,
	EditTextDialog,
} from '@me-shared-dialogs';
import {
	AddressFieldDialog,
	AddressFieldPart,
	AddressLinkPart,
	AgreementDialog,
	AgreementSignaturePart,
	AgreementTextPart,
	AppAreaAccessPart,
	AppAreaReloadButtonPart,
	AppAreaSectionAccessPart,
	AppPicturePipe,
	AvatarPart,
	BlurPart,
	ButtonCardsPart,
	ButtonContainerPart,
	CaptionedLayoutPart,
	CaptionedLayoutSelectPart,
	Counter,
	CountsTablePart,
	DashboardCardPart,
	DashboardReturnPart,
	DateFieldPart,
	DialogFramePart,
	DownloadButtonPart, DroplistPart,
	FieldChromePart,
	FieldGridPart,
	FieldGridReadonlyPart,
	GridCellPart, GridDownloadButtonPart, GridDownloadDialog, GridFilterDialog, GridMenuPart,
	GridPart,
	H2Part,
	H3Part,
	HelpPart,
	IconBarPart,
	IconPart,
	IconSimplePart,
	LabelPart,
	LinkButtonPart,
	ListboxPart,
	LogoPart,
	LongPressDirective,
	MessageBoxPart,
	MessageLinePart,
	MultiSelectPart,
	NavButtonCardsPage,
	NoAccessPart,
	NumericFieldPart,
	PageTab1Directive,
	PageTab2Directive,
	PageTab3Directive,
	PageTab4Directive,
	PageTab5Directive,
	PageTab6Directive,
	PageTab7Directive,
	PageTabsPart,
	PageTagPart,
	PhoneFieldPart,
	PhoneLinkPart,
	ProfilePicturePipe,
	ProgressAnimate,
	ProgressColorBarPart,
	RatingPart,
	RatingsPart,
	ReadonlyAccessPart, ReadonlyFieldPart, ResponsiveRow2Part,
	ResponsiveRow3Part, SaveChangesMessagePart,
	SearchPipe,
	SelectableViewContentPart,
	SelectableViewHeaderPart,
	SelectableViewPart,
	SelectableViewSelectorsPart,
	SelectFieldPart,
	SlantedHeaderPart,
	SpinnerButtonPart,
	TextAreaDialogFieldDialog,
	TextAreaDialogFieldPart,
	TextareaFieldPart,
	TextDialog,
	TextDialogButtonPart,
	TextFieldPart,
	ToolbarPart
} from '@me-shared-parts/UI-common';

import { AppZipSelectorWidget } from '../../widgets';


import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule, MultiSelectModule } from '@progress/kendo-angular-dropdowns';
import { FilterModule } from '@progress/kendo-angular-filter';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { PopupModule } from '@progress/kendo-angular-popup';
import { TooltipsModule } from '@progress/kendo-angular-tooltip';
import { MarkdownModule } from 'ngx-markdown';



@NgModule({
	imports: [
		CommonModule,
		ButtonsModule,
		DateInputsModule,
		DropDownsModule,
		FilterModule,
		MultiSelectModule,
		FlexLayoutModule,
		FormsModule,
		GridModule,
		InputsModule,
		LabelModule,
		LayoutModule,
		MarkdownModule.forChild(),
		MatAutocompleteModule,
		MatButtonToggleModule,
		MatCardModule,
		MatCheckboxModule,
		MatDatepickerModule,
		MatDialogModule,
		MatExpansionModule,
		MatInputModule,
		MatListModule,
		MatMenuModule,
		MatRadioModule,
		MatSelectModule,
		MatTableModule,
		MatTooltipModule,
		MatTreeModule,
		PopupModule,
		ReactiveFormsModule,
		RouterModule,
		TooltipsModule,
	],
	declarations: [
		//
		// Components
		//
		AddressLinkPart,
		AddressFieldPart,
		AgreementSignaturePart,
		AgreementTextPart,
		AppAreaAccessPart,
		AppAreaReloadButtonPart,
		AppAreaSectionAccessPart,
		AvatarPart,
		BlurPart,
		ButtonCardsPart,
		ButtonContainerPart,
		CaptionedLayoutPart,
		CaptionedLayoutSelectPart,
		CountsTablePart,
		DashboardCardPart,
		DashboardReturnPart,
		DateFieldPart,
		DialogFramePart,
		DownloadButtonPart,
		DroplistPart,
		FieldChromePart,
		FieldGridPart,
		FieldGridReadonlyPart,
		GridCellPart,
		GridMenuPart,
		GridDownloadButtonPart,
		GridPart,
		H2Part,
		H3Part,
		HelpPart,
		IconBarPart,
		IconPart,
		IconSimplePart,
		LabelPart,
		LinkButtonPart,
		ListboxPart,
		LogoPart,
		MessageBoxPart,
		MessageDialog,
		MessageLinePart,
		MultiSelectPart,
		NavButtonCardsPage,
		NoAccessPart,
		NumericFieldPart,
		PageTabsPart,
		PageTagPart,
		PhoneFieldPart,
		PhoneLinkPart,
		ProgressColorBarPart,
		RatingPart,
		RatingsPart,
		ReadonlyAccessPart,
		ResponsiveRow2Part,
		ResponsiveRow3Part,
		ReadonlyFieldPart,
		SaveChangesMessagePart,
		SelectableViewContentPart,
		SelectableViewHeaderPart,
		SelectableViewPart,
		SelectableViewSelectorsPart,
		SelectFieldPart,
		SlantedHeaderPart,
		SpinnerButtonPart,
		TextAreaDialogFieldPart,
		TextareaFieldPart,
		TextFieldPart,
		ToolbarPart,
		AppZipSelectorWidget,

		//
		// Directives
		//
		Counter,
		ProgressAnimate,
		LongPressDirective,
		PageTab1Directive,
		PageTab2Directive,
		PageTab3Directive,
		PageTab4Directive,
		PageTab5Directive,
		PageTab6Directive,
		PageTab7Directive,

		//
		// Pipes
		//
		AppPicturePipe,
		ProfilePicturePipe,
		SearchPipe,

		//
		// Dialogs
		//
		AddressFieldDialog,
		AgreementDialog,
		ApplicationDeleteDialog,
		ConfirmationDialog,
		GridDownloadDialog,
		GridFilterDialog,
		NotesDialog,
		EditTextDialog,
		TextDialogButtonPart,
		TextDialog,
		TextAreaDialogFieldDialog,
	],
	exports: [
		//
		// Components
		//
		AddressFieldPart,
		AgreementSignaturePart,
		AgreementTextPart,
		AddressLinkPart,
		AppAreaAccessPart,
		AppAreaReloadButtonPart,
		AppAreaSectionAccessPart,
		AvatarPart,
		BlurPart,
		ButtonCardsPart,
		ButtonContainerPart,
		CaptionedLayoutPart,
		CaptionedLayoutSelectPart,
		CountsTablePart,
		DashboardCardPart,
		DashboardReturnPart,
		DateFieldPart,
		DialogFramePart,
		DownloadButtonPart,
		DroplistPart,
		FieldChromePart,
		FieldGridPart,
		FieldGridReadonlyPart,
		GridPart,
		GridCellPart,
		GridMenuPart,
		GridDownloadButtonPart,
		H2Part,
		H3Part,
		HelpPart,
		IconBarPart,
		IconPart,
		IconSimplePart,
		LabelPart,
		LinkButtonPart,
		ListboxPart,
		LogoPart,
		MessageBoxPart,
		MessageDialog,
		MessageLinePart,
		MultiSelectPart,
		NavButtonCardsPage,
		NoAccessPart,
		NumericFieldPart,
		ToolbarPart,
		PageTabsPart,
		PageTagPart,
		PhoneFieldPart,
		PhoneLinkPart,
		ProgressColorBarPart,
		ReadonlyAccessPart,
		ResponsiveRow2Part,
		ResponsiveRow3Part,
		ReadonlyFieldPart,
		SaveChangesMessagePart,
		SelectableViewContentPart,
		SelectableViewHeaderPart,
		SelectableViewPart,
		SelectableViewSelectorsPart,
		SelectFieldPart,
		SlantedHeaderPart,
		SpinnerButtonPart,
		TextAreaDialogFieldPart,
		TextareaFieldPart,
		TextFieldPart,
		AppZipSelectorWidget,

		//
		// Directives
		//
		Counter,
		ProgressAnimate,
		LongPressDirective,
		PageTab1Directive,
		PageTab2Directive,
		PageTab3Directive,
		PageTab4Directive,
		PageTab5Directive,
		PageTab6Directive,
		PageTab7Directive,

		//
		// Pipes
		//
		AppPicturePipe,
		ProfilePicturePipe,
		SearchPipe,

		//
		// Dialogs
		//
		AddressFieldDialog,
		AgreementDialog,
		ApplicationDeleteDialog,
		ConfirmationDialog,
		GridDownloadDialog,
		GridFilterDialog,
		NotesDialog,
		EditTextDialog,
		TextDialogButtonPart,
		TextDialog,
		TextAreaDialogFieldDialog,
	],
})

export class SharedCommonModule { }