/**
 * @deprecated Use Site from the DataService instead
 */
export interface DdSiteRow {
	siteId: number,
	directorId: number,
	altDirectorId: number,
	name: string,
	code: string,
	stateCode: string,
	baseName: string,
	code6: string,
	sortableName: string,
	createdUTC: number,
	zipId: number,
	mcGroupNameEN: string,
	mcGroupNameES: string,
	isActive: boolean,
	eforallOrgEn: string,
	eforallOrgEs: string
	hidden: boolean,
}