<div class='pic-team-explorer-details-part' *ngIf="picTeam">

	<div id="container" class="explorer-details-container">

		<table class='pro-table pro-table-no-header pro-table-with-labels'>
			<tr>
				<td> Pic Team Id</td>
				<td> {{picTeam.picTeamId}}</td>
			</tr>

			<tr>
				<td>Pitch Contest</td>
				<td>
					<explorer-button [button]="{ explorableId: { conceptName: 'pitchContest', id: picTeam.picId }}">
					</explorer-button>
				</td>
			</tr>

			<tr>
				<td>Application</td>
				<td>
					<explorer-button
						[button]="{ explorableId: { conceptName: 'application', id: picTeam.applicationId }}">
					</explorer-button>
				</td>
			</tr>

			<tr *ngIf="picTeam.notes">
				<td>Notes</td>
				<td>
					{{picTeam.notes}}
				</td>
			</tr>

			<tr>
				<td>Last Edited By</td>
				<td>
					<explorer-button
						[button]="{ explorableId: { conceptName: 'person', id: picTeam.updatedByPersonId }}">
					</explorer-button>
					<div class="small-nowrap-text">
						on {{util.date.formatUTC(picTeam.updatedUTC, 'MMM D, YYYY', 'H:MM AM EST',
						urlService.languageId)}}
					</div>
				</td>
			</tr>

		</table>

		<div class="explorer-details-right">
		</div>

	</div>
</div>