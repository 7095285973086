<div class="page-tabs-part" [style.column-gap.px]='gap' [style.grid-template-columns]='gridTemplateColumns'>

	<div [style.height.px]='contentHeight'>

		<div *ngIf="tab1ShouldExpand$ | async" class="tab-expander-button">
			<button (click)='toggleForce()'><i class='toggle-not-hovered fa-light fa-left-right'></i><i
					class='toggle-hovered fa-solid fa-left-right'></i></button>
		</div>

		<kendo-tabstrip [keepTabContent]='true' class='page-tabs-tabstrip' [style.height.px]='contentHeight'
			(tabSelect)="onTabSelect('left', $event)">

			<kendo-tabstrip-tab [title]='tabNames[0]' [selected]="(leftTabIndex$ | async) === 0">
				<ng-template kendoTabContent>
					<ng-container *ngTemplateOutlet="pageTab1?.tpl || defaultTemplate"></ng-container>
				</ng-template>
			</kendo-tabstrip-tab>


			<ng-container *ngIf='(tab1Expanded$ | async) === false'>

				<kendo-tabstrip-tab *ngFor='let tab of extraTabs; let i=index' [title]='tab.name'
					[selected]="(leftTabIndex$ | async) === i + 1">
					<ng-template kendoTabContent>
						<ng-container *ngTemplateOutlet="tab.template || defaultTemplate"></ng-container>
					</ng-template>
				</kendo-tabstrip-tab>

			</ng-container>

		</kendo-tabstrip>
	</div>

	<kendo-tabstrip *ngIf='tab1Expanded$ | async' [keepTabContent]='true' class='page-tabs-tabstrip'
		[style.height.px]='contentHeight' (tabSelect)="onTabSelect('right', $event)">

			<kendo-tabstrip-tab *ngFor='let tab of extraTabs; let i=index' [title]='tab.name'
				[selected]="(rightTabIndex$ | async) === i + 1">
				<ng-template kendoTabContent>
					<ng-container *ngTemplateOutlet="tab.template || defaultTemplate"></ng-container>
				</ng-template>

			</kendo-tabstrip-tab>

		</kendo-tabstrip>


	<ng-template #defaultTemplate>
		<i style='display:block; margin-top:2em; color: crimson;'>Template not provided</i>
		<pre>{{tabNames | json}}</pre>
		<br>
		left: {{ leftSelectedTab$ | async }}
		<br>
		right: {{ rightSelectedTab$ | async }}
	</ng-template>

</div>