export type PersonAssociationRole =
	// Pic
	'picApplicant' |
	'picApplicationParticipant' |
	'picReader' |
	'picJudge' |
	'picTeamMember' |
	'picWinner' |
	// Acc
	'accApplicant' |
	'accApplicationParticipant' |
	'accReader' |
	'accInterviewer' |
	'accJudge' |
	'accCoach' |
	'accMentor' |
	'accEntrepreneur';

/**
 * Used only on the Associations page
 * TODO: refactor
 */
export interface PersonAssociation {
	role: PersonAssociationRole,
	assocName: string,
	assocId: number, //e.g. accId or picId
	assocDetailId?: number, //e.g. accTeamId or picApplicationId
	dateUTC: number,
	siteCode: string,
	status?: string,
	companyNames?: string[],
}