import { DecidingRoleId } from '@me-interfaces';
import { DdObject } from '../dd-object';
import { OldDomainDataService } from '../domain-data.service';
import { DdDecidingRoleRow } from './dd-deciding-role-row';

/**
 * @deprecated Use the DataService instead
 */
export class DdDecidingRole extends DdObject<DdDecidingRoleRow> implements DdDecidingRoleRow {

	constructor(dd: OldDomainDataService, data: DdDecidingRoleRow) {
		super(data);
	}

	public get decidingRoleId(): DecidingRoleId {
		return this._data.decidingRoleId;
	}

	public get name(): string {
		return this._data.name;
	}

}