/**
 * Describes the kind of value of a field. It is used
 * by the UI to determine the input mechanism.
 */
export const enum FieldKind {
	Text = 'Text',			// string
	Textarea = 'Textarea',	// string
	TextareaDialog = 'Textarea Dialog',	// string
	Select = 'Select',		// string | number
	Numeric = 'Numeric',	// number
	Phone = 'Phone',  		// FieldKindPhone = { phoneNumber: number, phoneType: PhoneType }
	Address = 'Address', 	// FieldKindAddress = { inUs: N/Y, address?: string, zipId?: number, cityAndState?: string }
	Date = 'Date', 			// number (UTC like UNIX_TIMESTAMP)

	// Mask = 'Mask',
	// Rating5
}