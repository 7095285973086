
import { DbcZip, ProgramTypeId, SimplePerson } from '@me-interfaces';
import { DdTagPrefix } from '..';
import { DdObject } from '../dd-object';
import { OldDomainDataService } from '../domain-data.service';
import { DdSiteRow } from './dd-site-row';
import { SiteTags } from './site-tags';


/**
 * @deprecated Use Site from the DataService instead
 */
export class DdSite extends DdObject<DdSiteRow> implements DdSiteRow {

	constructor(private dd: OldDomainDataService, data: DdSiteRow) {
		super(data);
	}

	public get siteId(): number {
		return this._data.siteId;
	}

	public get hidden(): boolean {
		return this._data.hidden;
	}

	public get directorId(): number {
		return this._data.directorId;
	}

	public setDirectorId(newDirectorId: number) {
		this._data.directorId = newDirectorId;
	}

	public set directorIdInCache(directorId: number) {
		this._data.directorId = directorId;
	}

	public getDirector(): SimplePerson {
		return this.dd.getCachedPerson(this.directorId);
	}

	public get altDirectorId(): number {
		return this._data.altDirectorId;
	}

	public setAltDirectorId(newAltDirectorId: number) {
		this._data.altDirectorId = newAltDirectorId;
	}

	public getAltDirector(): SimplePerson {
		return this.dd.getCachedPerson(this.altDirectorId);
	}

	public get name(): string {
		return this._data.name;
	}

	public get code(): string {
		return this._data.code;
	}

	public get stateCode(): string {
		return this._data.stateCode;
	}

	public get baseName(): string {
		return this._data.baseName;
	}

	public get sortableName(): string {
		return this._data.sortableName;
	}

	public get code6(): string {
		return this._data.code6;
	}

	public get createdUTC(): number {
		return this._data.createdUTC;
	}

	public get zipId(): number {
		return this._data.zipId;
	}

	public get zip(): DbcZip {
		return this.dd.getCachedZip(this.zipId);
	}

	public get mcGroupNameEN(): string {
		return this._data.mcGroupNameEN;
	}

	public get mcGroupNameES(): string {
		return this._data.mcGroupNameES;
	}

	public get isActive(): boolean {
		return this._data.isActive;
	}

	public get eforallOrgEn(): string {
		return this._data.eforallOrgEn;
	}

	public get eforallOrgEs(): string {
		return this._data.eforallOrgEs;
	}

	public get tagPrefix(): DdTagPrefix {
		return this.dd.tagPrefixes.getBySiteId(this.siteId);
	}

	private _tags: SiteTags;

	public get tags() {
		if (this._tags) return this._tags;
		else return this._tags = new SiteTags(this);
	}

	public get sitePrograms() {
		return this.dd.sitePrograms.objects.filter(sp => sp.siteId == this.siteId);
	}

	public get pics() {
		return this.dd.pics.objects.filter(pic => {
			if (pic.siteProgram.siteId == this.siteId && pic.siteProgram.program.programTypeId == ProgramTypeId.PitchContest) {
				return pic;
			}
		});
	}

	public get accs() {
		return this.dd.accs.objects.filter(acc => {
			if (acc.siteProgram.siteId == this.siteId && acc.siteProgram.program.programTypeId == ProgramTypeId.Accelerator) {
				return acc;
			}
		});
	}

}