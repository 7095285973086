<mat-accordion [id]='accordionId'>
	<mat-expansion-panel #panel *ngFor='let answer of answers; let i = index' [expanded]="stepper.step === (stepper.baseSteps + i)"
	 class='mat-expansion-panel panel{{i}}' (opened)="stepper.set(stepper.baseSteps + i); scrollTo(i);">
		<mat-expansion-panel-header>
			<mat-panel-title [class.mat-error]='formGroup.controls[answer.q].touched && formGroup.controls[answer.q].errors'>
				<i *ngIf='formGroup.controls[answer.q].value !== ""' class='fas fa-check-square checkbox'></i>
				<i *ngIf='formGroup.controls[answer.q].value === ""' class='far fa-square checkbox' [class.is-optional]='isOptional(answer)'></i>
				{{ answer.question.internalLabel }}
				<b style='font-size: 1.2em;' *ngIf='answer.question.isRequired'>*</b>
			</mat-panel-title>
		</mat-expansion-panel-header>

		<editable-question [panel]='panel' [answer]='answer' [formGroup]='formGroup' [controlName]='answer.q' [control]='formGroup.controls[answer.q]'
		 (ratingChanged)='ratingChanged($event)'></editable-question>

		<mat-action-row>
			<button *ngIf='stepper.step > 0' (click)="stepper.prev()"><me-label key='Previous'></me-label></button>
			<button *ngIf='stepper.step < stepper.steps - 1' (click)="stepper.next()"><me-label key='Next'></me-label></button>
			<button *ngIf='stepper.step === stepper.steps - 1' (click)="stepper.set(-1)"><me-label key='Done'></me-label></button>
		</mat-action-row>

	</mat-expansion-panel>
</mat-accordion>