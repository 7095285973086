/**
 * @deprecated Use the DataService instead
 */
export interface DdSiteProgramRow {
	siteProgramId: number,
	siteId: number,
	programId: number,
	managerId: number,
	isActive: boolean,
	altManagerId: number,
}