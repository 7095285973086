import { MenuItem } from '@me-interfaces';

export const techAdminMenu: MenuItem = {

	label: 'Tech Admin',
	routerLink: '/access/admin/tech',
	icon: 'fas fa-cogs',
	separator: true,

	subMenu: [
		{
			label: 'Overview',
			routerLink: '/access/admin/tech/overview',
			icon: 'fas fa-cog',
		},
		{
			label: 'Program Editor',
			routerLink: '/access/admin/tech/program-editor',
			icon: 'fas fa-cog',
		},
		{
			label: 'Schedule Template',
			routerLink: '/access/admin/tech/schedule-template',
			icon: 'fas fa-cog',
		},
		{
			label: 'Time Zone',
			routerLink: '/access/admin/tech/time-zone',
			icon: 'fas fa-clock',
		},
		{
			label: 'Missing Demographics',
			routerLink: '/access/admin/tech/missing-demographics',
			icon: 'fas fa-cog',
		},
		{
			label: 'Database',
			icon: 'fas fa-database',

			subMenu: [
				{
					label: 'Tables',
					routerLink: '/access/admin/tech/database/tables',
					icon: 'fas fa-layer-group',
				},
				{
					label: 'Bulk Changes',
					routerLink: '/access/admin/tech/database/bulk-changes',
					icon: 'fas fa-sync',
				},
				{
					label: 'Industry Editor',
					routerLink: '/access/admin/tech/database/industry-editor',
					icon: 'fas fa-industry-alt',
				},
				{
					label: 'Import Mailchimp',
					routerLink: '/access/admin/tech/database/import-mailchimp',
					icon: 'fab fa-mailchimp',
				},
			]
		},
		{
			label: 'Mailchimp',
			routerLink: '/access/admin/tech/mailchimp',
			icon: 'fab fa-mailchimp',
		},
		{
			label: 'Examples',
			icon: 'fas fa-hammer',

			subMenu: [
				{
					label: 'Assorted',
					routerLink: '/access/admin/tech/examples/assorted',
					icon: 'fas fa-tools',
				},
				{
					label: 'Responsive Page Tabs',
					routerLink: '/access/admin/tech/examples/responsive-page-tabs',
					icon: 'fas fa-paint-roller',
				},
			]
		},
	]
};