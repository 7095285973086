<me-dialog-frame [header]='headerText' [actions]='actions' [dialogRef]='dialogRef' [icon]='icon' alertType='editor'>

	<div class='acc-reader-max-reads-editor-dialog'>
		<table style='width:100%' class='pro-table pro-table-no-header pro-table-with-labels pro-table-without-border'>
			<tr>
				<td>Current Max Reads</td>
				<td *ngIf="orgMaxReads">{{orgMaxReads}}</td>
				<td *ngIf="!orgMaxReads"><i>No max reads set.</i></td>
			</tr>
			<tr>
				<td>Max Reads</td>
				<td>
					<input kendoTextBox type="number"  [value]='newMaxReads' [min]="1" (input)='onInput($event)' placeholder='Eg. 2'
						[style.width.%]='100' [disabled]='readonly'/>
				</td>
			</tr>
		</table>
	</div>
</me-dialog-frame>