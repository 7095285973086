import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, AbstractControl, Validators } from '@angular/forms';
import { AppSessionService } from '@me-services/core/app-session';
import { Subscription } from 'rxjs';
import { LabelsService } from '@me-services/ui/labels';

@Component({
	selector: 'session-enter-code-page',
	templateUrl: './session-enter-code.page.html',
	styleUrls: ['./session-enter-code.page.scss']
})
export class SessionEnterCodePage implements OnInit, OnDestroy {

	form: FormGroup;
	private subscription: Subscription;

	email = '';
	canResend = false;
	working = false;
	submitting = false;
	resending = false;
	canceling = false;

	constructor(private appSession: AppSessionService, private fb: FormBuilder, private labels:LabelsService) { }

	ngOnInit() {
		this.createForm();

		this.subscription = this.appSession.status$.subscribe(appSessionState => {
			if (appSessionState.state == "WRONG_CODE") {
				this.form.controls.code.setErrors({ wrongCode: true })
			}
			this.email = appSessionState.pendingCodeSentToThisEmail;
			const secs = appSessionState.pendingCodeSecsUntilCanRetry;
			this.canResend = secs <= 0;
			if (secs > 0) setTimeout(() => { this.canResend = true; }, (secs + 1) * 1000);

			this.working = false;
			this.submitting = false;
			this.resending = false;
			this.canceling = false;
		});
	}

	ngOnDestroy() {
		this.subscription.unsubscribe();
	}

	private createForm() {
		this.form = this.fb.group({
			code: ['', [Validators.required, Validators.maxLength(5), Validators.pattern(/^[A-Za-z]{5}$/)]],
		});
	}

	public fixCaseOfValue(ctrl: AbstractControl) {
		const value = ctrl.value.trim().toUpperCase();
		if (value !== ctrl.value) ctrl.patchValue(value);
	}

	public submit(): void {
		if (this.form.invalid) return;
		this.working = true;
		this.submitting = true;
		const code = this.form.controls.code.value.toUpperCase();
		this.appSession.assignPerson(code);
	}

	public resend(): void {
		this.working = true;
		this.resending = true;
		this.appSession.resendCode(this.labels.languageId);
	}

	public cancel(): void {
		this.working = true;
		this.canceling = true;
		this.appSession.removeCode();
	}
}