import { AccAreaService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { Answer, AssessmentsData, DbaAccReading } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { DroplistItem, Icon, getIconContext } from '@me-shared-parts/UI-common';
import { Observable, mergeMap } from 'rxjs';


@Component({
	selector: 'acc-reading-assessments-view-part',
	templateUrl: './acc-reading-assessments-view.part.html',
})
export class AccReadingAssessmentsViewPart implements OnInit {

	orderSelectedItem: DroplistItem<string>;
	data$: Observable<AssessmentsData[]>;
	order = 'cpq';
	orderList: DroplistItem<string>[] = [
		{ uniqueId: 1, data: 'pcq', iconContext: getIconContext(Icon.droplist_pickOne, undefined, undefined, 'Reader, Applicant, Question') },
		{ uniqueId: 2, data: 'pqc', iconContext: getIconContext(Icon.droplist_pickOne, undefined, undefined, 'Reader, Question, Applicant') },
		{ uniqueId: 3, data: 'cqp', iconContext: getIconContext(Icon.droplist_pickOne, undefined, undefined, 'Applicant, Question, Reader') },
		{ uniqueId: 4, data: 'cpq', iconContext: getIconContext(Icon.droplist_pickOne, undefined, undefined, 'Applicant, Reader, Question') },
		{ uniqueId: 5, data: 'qpc', iconContext: getIconContext(Icon.droplist_pickOne, undefined, undefined, 'Question, Reader, Applicant') },
		{ uniqueId: 6, data: 'qcp', iconContext: getIconContext(Icon.droplist_pickOne, undefined, undefined, 'Question, Applicant, Reader') },
	];


	constructor(
		public accAreaService: AccAreaService,
		private ds: DataService,
	) {
	}

	ngOnInit(): void {
		const i = 0;
		this.orderSelectedItem = this.orderList.find(order => order.data == this.order);

		this.data$ = this.accAreaService.reading.assessments$.pipe(mergeMap(data => this.buildAssessmentData(data)));
	}

	async buildAssessmentData(readerAssessments: {
		reading: DbaAccReading,
		answers: readonly Answer[],
	}[],) {

		if (!readerAssessments.length) return [];

		const accReaders = await this.ds.admin.accReader.getManyPackagesAsMap(readerAssessments.map(r => r.reading.accReaderId));
		const applications = await this.ds.admin.application.getManyPackagesAsMap(readerAssessments.map(r => r.reading.applicationId));

		return readerAssessments.reduce((a: AssessmentsData[], ra) => {
			const rating = {
				answers: ra.answers,
				company: applications[ra.reading.applicationId].company,
				rating: ra.reading.rating,
			};
			const assessmentData = a.find(data => data.person.personId == accReaders[ra.reading.accReaderId].personId);
			if (assessmentData) {
				assessmentData.ratings.push(rating);
			}
			else a.push({
				person: accReaders[ra.reading.accReaderId].person.asSingleton,
				ratings: [rating],
			});
			return a;
		}, []);
	}

	changeOrder(e: DroplistItem) {
		this.orderSelectedItem = e;
		this.order = e.data;
	}

}