import { AccStageErrorCategory, getAccStageErrors } from '@ACC-area';
import { ProgramPageService } from '@ADMIN-area';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AccCols, GridColumnType, GridSetup } from '@me-grid';
import { AccStageId, AccTeam, Accelerator, Application, Award, Event } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { GridPart } from '@me-shared-parts/UI-common';
import { Observable, of, switchMap } from 'rxjs';

interface AccProblemRow {
	index: number,
	accId: number,
	updatedUTC: number,
	problemAccStageId: AccStageId,
	level: 'Critical' | 'High',
	problem: string,
	because: string,
	category: AccStageErrorCategory,
}

@Component({
	selector: 'program-acc-problems-view-part',
	templateUrl: './acc-problems-view.part.html',
})

export class AccProblemsViewPart extends DestroyablePart implements OnInit {

	public gridSetup = this.setupGrid();
	public rows$: Observable<AccProblemRow[]> = of([]);
	@ViewChild(GridPart) meGrid: GridPart<AccProblemRow>;

	constructor(
		public ds: DataService,
		public util: UtilityService,
		public pageService: ProgramPageService
	) {
		super();
	}


	ngOnInit(): void {
		super.initDestroyable();
		this.rows$ = this.ds.admin.singletonsAsOfUTC$.pipe(
			switchMap(async utc => {
				const accelerators = await this.ds.admin.accelerator.getAllPackagesAsArray();
				return this.buildRows(accelerators);
			})
		);
	}

	/**
	* For each accelerator, call getProblems and convert the problems into an array of AccProblemRow
	*/
	async buildRows(accelerators: readonly Accelerator[]): Promise<AccProblemRow[]> {


		const nonCanceledAccelerators = accelerators.filter(acc => acc.canceled == false);
		const accIds = nonCanceledAccelerators.map(acc => acc.accId);


		//
		// Get related data for ALL of the accelerators, for quick lookup in the map function below
		//
		const applicationsByAccId = await this.ds.admin.application.getByAccIds(accIds);
		const teamsByAccId = await this.ds.admin.accTeam.getByAccIds(accIds);
		const eventsByAccId = await this.ds.admin.event.getByAccIds(accIds);
		const awardsByAccId = await this.ds.admin.award.getByAccIds(accIds);


		const problemsArray: AccProblemRow[] = [];


		for (const acc of nonCanceledAccelerators) {

			const applications = applicationsByAccId[acc.accId];
			const teams = teamsByAccId[acc.accId];
			const events = eventsByAccId[acc.accId];
			const awards = awardsByAccId[acc.accId];


			const problems = this.getProblems(this.util, acc, applications, teams, events, awards);

			if (problems.length > 0) problemsArray.push(...problems);

		}


		//
		// Sort them by stage id (chronological) and then by problem text (alphabetical)
		//
		problemsArray.sort((a, b) => {
			if (a.problemAccStageId !== b.problemAccStageId) return b.problemAccStageId - a.problemAccStageId;
			else return a.problem > b.problem ? -1 : 1;
		});


		//
		// Inserting a unique index identifier to service the row id
		//
		for (let i = 0; i < problemsArray.length; i++) {
			problemsArray[i].index = i + 1;
		}

		return problemsArray;
	}


	getProblems(
		util: UtilityService,
		acc: Accelerator,
		applications: readonly Application[],
		teams: readonly AccTeam[],
		events: readonly Event[],
		awards: readonly Award[]
	): AccProblemRow[] {

		const problems: AccProblemRow[] = [];
		const accStageId = acc.accStageId;
		const stageErrors = getAccStageErrors(util, acc, applications, teams, events, awards);
		const stageById = this.ds.domain.accStage.getAllAsMap();


		for (const error of stageErrors) {

			let timing = '';

			if (error.logic == 'Before' && accStageId < error.accStageId) timing = 'Before';
			if (error.logic == 'At-Or-Before' && accStageId <= error.accStageId) timing = 'At or before';
			if (error.logic == 'At-Or-Beyond' && accStageId >= error.accStageId) timing = 'At or beyond';
			if (error.logic == 'Beyond' && accStageId > error.accStageId) timing = 'Beyond';

			if (timing.length) {
				problems.push({
					index: 0,
					accId: acc.accId,
					updatedUTC: acc.updatedUTC,
					problemAccStageId: error.accStageId,
					level: error.level,
					problem: `${error.message}`,
					because: `${timing} ${stageById[error.accStageId].name}`,
					category: error.category,
				});
			}

		}

		return problems;
	}


	private setupGrid(): GridSetup<AccProblemRow> {

		return {
			experience: 'ACC',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Problem",
			rowPluralName: "Problems",
			rowKey: "index",
			stateKey: "programs-accelerator-problems",
			canAdd: false,
			canRefresh: false,
			canDownload: false,
			columnsToAdd: [
				{ field: "category", header: "Problem Type", width: 150, type: GridColumnType.text, hidden: true },
				{ field: "level", header: "Severity", width: 70, type: GridColumnType.text },
				{ field: "problem", header: "Problem", width: 400, type: GridColumnType.text },
				{ field: "because", header: "Because", width: 240, type: GridColumnType.text },
			],
			columnsToAlter: [
				{ field: AccCols.language, hidden: true },
				{ field: AccCols.stage, hidden: true },
				{ field: AccCols.siteName, hidden: true },
			],
			initialState: {
				sort: [{ field: AccCols.startUTC, dir: 'desc' }],
				group: [{ field: AccCols.siteName, dir: 'asc' }],
			},
		};
	}


}
