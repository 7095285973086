import { DbConceptName, Dbd, Immutable } from '@me-interfaces';


interface MutableDbdAccLanguageSeason extends Dbd {

	readonly _concept: DbConceptName.AccLanguageSeason,

	accLanguageSeasonId: number,
	name: string,
	longName: string,
	languageId: number,
	accSeasonId: number,
	order: number,
}


export interface DbdAccLanguageSeason extends Immutable<MutableDbdAccLanguageSeason> { }