import { Icon } from "@me-shared-parts/UI-common";
import { IconBarIcon } from "@me-shared-parts/UI-common/icon-bar";

export const VIEW_DETAILS = 'details';
export const VIEW_QUESTIONS = 'questions';
export const VIEW_PARTICIPANTS = 'participants';
export const VIEW_RELATED_NOTES = 'related notes';
export const VIEW_RELATED_APPLICATIONS = 'related applications';

export const views: IconBarIcon[] = [
	{ key: VIEW_DETAILS, icon: Icon.action_more, tooltip: 'Application Details', selected: true },
	{ key: VIEW_QUESTIONS, icon: Icon.concept_application, tooltip: 'Questions', selected: true },
	{ key: VIEW_PARTICIPANTS, icon: Icon.concept_person, tooltip: 'Participants', selected: true },
	{ key: VIEW_RELATED_NOTES, icon: Icon.notes_some, tooltip: 'Related Notes', selected: true },
	{ key: VIEW_RELATED_APPLICATIONS, icon: Icon.concept_application, tooltip: 'Related Applications', selected: true },
];