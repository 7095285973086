export interface ReportYearDetails {
	accTeamId: number,
	year: number,
	incomePercent: number,
	incomePay: number,
	salesRevenue: number,
	salesPayroll: number,
	stakeholdersCustomers: number,
	stakeholdersSuppliers: number,
	stakeholdersFunders: number,
	stakeholdersPartners: number,
	stakeholdersDistributors: number,
	stakeholdersVolunteers: number,
	stakeholdersOther: number,
	capitalSavings: number,
	capitalFamily: number,
	capitalLoans: number,
	capitalEquity: number,
	capitalCreditCard: number,
	capitalDonations: number,
	capitalTotal: number,
	jobsFull: number,
	jobsPart: number,
	lastUpdateUTC: number,
}