import { AppAreaIdentifier } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { calculateIndicators } from "./calculate-indicators";
import { getDashboardDataIds } from "./get-dashboard-data-ids";
import { getEmptyDashboardData } from "./get-empty-dashboard-data";
import { DashboardData } from "./interfaces";


/**
 * Determine all the data ids for rows displayed in dashboard grids and calculate
 * all the indicator values that are displayed in the main dashboard view.
 */
export async function getDashboardData(
	ds: DataService,
	util: UtilityService,
	identifier: AppAreaIdentifier<number>,
): Promise<DashboardData> {


	const areaName = identifier?.areaName;
	if (!['Admin', 'Region', 'Site'].includes(areaName)) return undefined;


	//
	// Get all the arrays of ids that feed the grids
	//
	const objects = await getDashboardDataIds(ds, util, identifier, getEmptyDashboardData(areaName));


	//
	// Determine all the counts and values that are displayed op the main dashboards view
	//
	await calculateIndicators(areaName, ds, util, objects);



	return objects.data;

}