import { Dbc } from "@me-interfaces";


export interface DbcSiteVolunteerApplication extends Dbc {
	volunteerApplicationId: number,
	siteId: number,
	closedByPersonId?: number,
	closedUTC?: number,
	approveMentor?: 'Y' | 'P' | 'N',
	approveReader?: 'Y' | 'P' | 'N',
	approveInterviewer?: 'Y' | 'P' | 'N',
	approveClassSpeaker?: 'Y' | 'P' | 'N',
	approveOfficeHours?: 'Y' | 'P' | 'N',
	approveWorkshops?: 'Y' | 'P' | 'N',
	approveEventhelper?: 'Y' | 'P' | 'N',
}