import { DbsAward, DbsEvent, DbsPerson, Event, EventAndHost, EventAttendedBy, EventTypeId, Site } from "@me-interfaces";
import { DexieService } from "@me-services/core/dexie";
import { UtilityService } from "@me-services/core/utility";
import { getIconDefaultText, getIconForEventType } from "@me-shared-parts/UI-common";
import { Observable } from "rxjs";
import { distinctUntilChanged, map, mergeMap } from "rxjs/operators";
import { DomainDataManagers } from "../interfaces/domain-data-managers";
import { PackageManager } from "../package-manager";
import { SingletonsManager } from "../singletons-manager";
import { AcceleratorPackageManager } from "./accelerator";
import { PitchContestPackageManager } from "./pitch-contest";
import { SitePackageManager } from "./site";
import { VenuePackageManager } from "./venue";


export class EventPackageManager extends PackageManager<DbsEvent, Event> {

	constructor(
		singletonsAsOfUTC$: Observable<number>,
		util: UtilityService,
		sm: SingletonsManager<DbsEvent>,
		private domain: DomainDataManagers,
		private person: SingletonsManager<DbsPerson>,
		private venue: VenuePackageManager,
		private site: SitePackageManager,
		private accelerator: AcceleratorPackageManager,
		private pitchContest: PitchContestPackageManager,
		private notifySingletonsChanged: () => Promise<void>,
		private dexie: DexieService,
	) {
		super(singletonsAsOfUTC$, util, sm)
	}


	/**
	 * Convert an array of DbcEvent to an array of Event
	 */
	protected async _createPackages(dbsEvents: DbsEvent[]): Promise<Event[]> {

		//
		// Get all the related objects
		//
		const personIds: number[] = [];
		const venueIds: number[] = [];
		const siteIds: number[] = [];
		const accIds: number[] = [];
		const picIds: number[] = [];

		for (const dbsEvent of dbsEvents) {
			personIds.push(dbsEvent.hostPersonId);
			personIds.push(dbsEvent.createdByPersonId);
			personIds.push(dbsEvent.updatedByPersonId);

			if (dbsEvent.venueId) venueIds.push(dbsEvent.venueId);
			if (dbsEvent.siteId) siteIds.push(dbsEvent.siteId);
			if (dbsEvent.accId) accIds.push(dbsEvent.accId);
			if (dbsEvent.picId) picIds.push(dbsEvent.picId);
		}

		const personMap = await this.person.getManyAsMap(personIds);
		const venueMap = await this.venue.getManyPackagesAsMap(venueIds);
		const siteMap = await this.site.getManyPackagesAsMap(siteIds);
		const accMap = await this.accelerator.getManyPackagesAsMap(accIds);
		const picMap = await this.pitchContest.getManyPackagesAsMap(picIds);

		//
		// Package 'em up
		//
		const events: Event[] = dbsEvents.map(dbsEvent => {

			const hostPerson = personMap[dbsEvent.hostPersonId];
			const createdByPerson = personMap[dbsEvent.createdByPersonId];
			const updatedByPerson = personMap[dbsEvent.updatedByPersonId];
			const venue = venueMap[dbsEvent.venueId];
			const site = siteMap[dbsEvent.siteId] || undefined;
			const acc = accMap[dbsEvent.accId] || undefined;
			const pic = picMap[dbsEvent.picId] || undefined;
			const programName = acc?.name || pic?.name || undefined

			return {
				...dbsEvent,
				id: dbsEvent.eventId,
				name: dbsEvent.name ?? this.util.date.formatUTC(dbsEvent.startUTC, 'MMM D, YYYY (DOW)', 'H:MM AM EST', dbsEvent.languageId),
				explorerName: this.getExplorerName(dbsEvent, site, programName),
				hostPerson,
				createdByPersonName: createdByPerson?._name || `Person #${dbsEvent.createdByPersonId}`,
				updatedByPersonName: updatedByPerson?._name || `Person #${dbsEvent.updatedByPersonId}`,
				venue,
				appUrl: this.createAppUrl(dbsEvent, site),
				hasRedFlag: false,
			};
		});

		return events;
	}

	private getExplorerName(event: DbsEvent, site?: Site, programName?: string): string {
		const eventDate = this.util.date.formatUTC(event.startUTC, 'MMM D, YYYY', 'No Time', event.languageId);
		const siteCode = site?.code || undefined;
		const eventType = getIconDefaultText(getIconForEventType(event.eventTypeId));
		if (siteCode) {
			const program = programName ? `${siteCode} ${programName}` : siteCode;
			return `${eventDate} - ${eventType} for ${program}`;
		}
		return `${eventDate} - ${eventType}`;
	}


	private createAppUrl(event: DbsEvent, site?: Site): string {
		const eventTypeId = event.eventTypeId;
		const accId = event.accId || undefined;
		const picId = event.picId || undefined;
		const siteCode = site?.code || undefined;
		const siteId = site?.siteId || undefined;

		let url = null;
		if (siteCode && accId) {
			if (eventTypeId == EventTypeId.AccReading) url = `/access/admin/national/sites/${siteId}/accelerators/${accId}/pre-accelerator?tab=1&view=5`;
			else if (eventTypeId == EventTypeId.AccInterviewing) url = `/access/admin/national/sites/${siteId}/accelerators/${accId}/pre-accelerator?tab=1&view=10`;
			else if (eventTypeId == EventTypeId.AccMentorMatching) url = `/access/admin/national/sites/${siteId}/accelerators/${accId}/cohort?tab=1&view=4`;
			else if (eventTypeId == EventTypeId.AccFinalPitch) url = `/access/admin/national/sites/${siteId}/accelerators/${accId}/cohort?tab=1&view=12`;
			else if (eventTypeId == EventTypeId.AccShowcase) url = `/access/admin/national/sites/${siteId}/accelerators/${accId}/cohort?tab=1&view=18`;
			else if (eventTypeId == EventTypeId.AccQuarterliesQ2) url = `/access/admin/national/sites/${siteId}/accelerators/${accId}/cohort?tab=1&view=19`;
			else if (eventTypeId == EventTypeId.AccQuarterliesQ3) url = `/access/admin/national/sites/${siteId}/accelerators/${accId}/cohort?tab=1&view=20`;
			else if (eventTypeId == EventTypeId.AccQuarterliesQ4) url = `/access/admin/national/sites/${siteId}/accelerators/${accId}/cohort?tab=1&view=21`;
		}
		else if (siteCode && picId) {
			if (eventTypeId == EventTypeId.PicReading) url = `/access/admin/communities/${siteCode.toLowerCase()}/programs/pitch-contests/${picId}/events/reading/event`;
			else if (eventTypeId == EventTypeId.PicContest) url = `/access/admin/communities/${siteCode.toLowerCase()}/programs/pitch-contests/${picId}/events/contest/event`;
		}

		return url;
	}


	/**
	 * Listen to any changes to an event and emit only when it changes
	 */
	public observeEvent(eventId: number) {

		return this.singletonsAsOfUTC$
			.pipe(
				mergeMap(async utc => await this.getOnePackage(eventId)),
				distinctUntilChanged((e1, e2) => this.util.values.areSame(e1, e2))
			);
	}



	/**
	 * Remove a single event and associated awards from the singletons map
	 */
	public async removeEvent(eventId: number) {

		//
		// Remove awards first
		//
		const db = await this.dexie.getSingletonsDb();
		const awardsByEventId = await db.getArraysByForeignIdsFromCache<DbsAward>('awards', 'eventId', [eventId]);
		const awards = awardsByEventId[eventId];
		const awardIds = awards.map(award => award.awardId);

		db.applyDelta('awards', { asOfUTC: 0, updated: [], deletedIds: awardIds });


		//
		// Remove the event
		//
		db.applyDelta('events', { asOfUTC: 0, updated: [], deletedIds: [eventId] });


		//
		// Notify
		//
		await this.notifySingletonsChanged();
	}



	/**
	 * Filter to only include events that have currently started but not yet completed 
	 */
	getActiveEvents(eventsAndHosts: EventAndHost[]): EventAndHost[] {

		const now = Date.now() / 1000;

		const activeEvents = eventsAndHosts.filter(eventAndHost => {
			const e = eventAndHost.event;
			if (now < Math.min(e.startUTC, e.toolStartUTC ?? Number.MAX_SAFE_INTEGER)) return false;	// before it started
			if (now > Math.max(e.endUTC, e.toolEndUTC ?? 0)) return false;	// after it ended
			return true;
		});

		return activeEvents;
	}


	/**
	 * Listen to all event and emit only when any changes
	 */
	private observeAllEvents() {
		return this.singletonsAsOfUTC$
			.pipe(
				mergeMap(async utc => {

					const people = await this.person.getAllAsMap();
					const events = await this.getAllPackagesAsArray();

					const eventsAndHosts: EventAndHost[] = events.map(event => {
						const host = event?.hostPersonId ? <DbsPerson>people[event.hostPersonId] : undefined;
						return { event, host };
					});

					return eventsAndHosts;
				}),
			);
	}


	/**
	 * Listen to all active event and emit only when any changes
	 */
	public observeAllActiveEvents() {
		return this.observeAllEvents()
			.pipe(
				map(eventsAndHosts => this.getActiveEvents(eventsAndHosts)),
			);
	}


	/**
	 * Listen to all events return those that are upcoming
	 */
	public observeUpcomingEvents() {
		return this.observeAllEvents()
			.pipe(
				map(eventsAndHosts => {
					const now = Date.now() / 1000;

					return eventsAndHosts.filter(eventAndHost => {
						const event = eventAndHost.event;
						const maxUTC = Math.max(event.startUTC, event.endUTC, event.toolEndUTC ?? 0, event.toolStartUTC ?? 0);
						return maxUTC > now;
					});
				}),
				distinctUntilChanged((e1, e2) => this.util.values.areSame(e1, e2))
			);
	}


	/**
	 * Listen to all events return those that are for a site
	 */
	public observeSiteEvents(siteId: number, activeOnly = false) {
		return this.observeAllEvents()
			.pipe(
				map(eventsAndHosts => activeOnly ? this.getActiveEvents(eventsAndHosts) : eventsAndHosts),
				map(eventsAndHosts => eventsAndHosts
					.filter(eventAndHost => siteId && eventAndHost.event.siteId == siteId)
				),
				distinctUntilChanged((e1, e2) => this.util.values.areSame(e1, e2))
			);
	}


	/**
	 * Listen to all events return those that are for an acc
	 */
	public observeAccEvents(accId: number, activeOnly = false) {
		return this.observeAllEvents()
			.pipe(
				map(eventsAndHosts => activeOnly ? this.getActiveEvents(eventsAndHosts) : eventsAndHosts),
				map(eventsAndHosts => eventsAndHosts
					.filter(eventAndHost => eventAndHost.event.accId == accId)
				),
				distinctUntilChanged((e1, e2) => this.util.values.areSame(e1, e2))
			);
	}


	/**
	 * Listen to all events return those that are for a pic
	 */
	public observePicEvents(picId: number, activeOnly = false) {
		return this.observeAllEvents()
			.pipe(
				map(eventsAndHosts => activeOnly ? this.getActiveEvents(eventsAndHosts) : eventsAndHosts),
				map(eventsAndHosts => eventsAndHosts
					.filter(eventAndHost => eventAndHost.event.picId == picId)
				),
				distinctUntilChanged((e1, e2) => this.util.values.areSame(e1, e2))
			);
	}

	
	/**
	 * Listen to all events return those that are program events
	 */
	public observeProgramEvents(activeOnly = false) {
		return this.observeAllEvents()
			.pipe(
				map(eventsAndHosts => activeOnly ? this.getActiveEvents(eventsAndHosts) : eventsAndHosts),
				map(eventsAndHosts => eventsAndHosts
					.filter(eventAndHost => this.domain.eventType.getOne(eventAndHost.event.eventTypeId).attendedBy != EventAttendedBy.Person)
				),
				distinctUntilChanged((e1, e2) => this.util.values.areSame(e1, e2))
			);
	}


	/**
	 * Listen to all events return those that are person events
	 */
	public observePersonEvents(activeOnly = false) {
		return this.observeAllEvents()
			.pipe(
				map(eventsAndHosts => activeOnly ? this.getActiveEvents(eventsAndHosts) : eventsAndHosts),
				map(eventsAndHosts => eventsAndHosts
					.filter(eventAndHost => this.domain.eventType.getOne(eventAndHost.event.eventTypeId).attendedBy == EventAttendedBy.Person)
				),
				distinctUntilChanged((e1, e2) => this.util.values.areSame(e1, e2))
			);
	}


	/**
	 * Listen to all events return those that are for a venue
	 */
	public observeVenueEvents(venueId: number, activeOnly = false) {
		return this.observeAllEvents()
			.pipe(
				map(eventsAndHosts => activeOnly ? this.getActiveEvents(eventsAndHosts) : eventsAndHosts),
				map(eventsAndHosts => eventsAndHosts
					.filter(eventAndHost => eventAndHost.event.venueId == venueId)
				),
				distinctUntilChanged((e1, e2) => this.util.values.areSame(e1, e2))
			);
	}


	/**
	 * Get all events for each siteId provided
	 */
	public async getBySiteIds(siteIds: number[]): Promise<Readonly<Record<number, ReadonlyArray<Event>>>> {

		return await this.getPackagesAsArraysByForeignIds('siteId', siteIds);
	}


	/**
	 * Get all events for each accId provided
	 */
	public async getByAccIds(accIds: number[]): Promise<Record<number, ReadonlyArray<Event>>> {

		return await this.getPackagesAsArraysByForeignIds('accId', accIds);
	}


	/**
	 * Get all events for each picId provided
	 */
	public async getByPicIds(picIds: number[]): Promise<Record<number, ReadonlyArray<Event>>> {

		return await this.getPackagesAsArraysByForeignIds('picId', picIds);
	}

	/**
	 * Get all events for each venueId provided
	 */
	public async getByVenueIds(venueIds: number[]): Promise<Record<number, ReadonlyArray<Event>>> {

		return await this.getPackagesAsArraysByForeignIds('venueId', venueIds);
	}
}