<div class='acc-matching-team-part' [class.dropped-out]="team?.droppedOutWeek">

	<div class="team-box-header">Team<b>{{getDroppedOutWeek()}}</b></div>

	<div class="team-box-body">

		<explorer-button [button]="accTeamButton"></explorer-button>

		<span class="mentor-type">Coach</span>

		<div class="mentor-and-ratings" *ngIf="coaches.length">

			<ng-container *ngFor="let coach of coaches">
				<explorer-button [button]="coach.button"></explorer-button>
				<acc-matching-rating-part [rating]="getRating(coach.m2t, coach.name, 'gave')">
				</acc-matching-rating-part>
				<acc-matching-rating-part [rating]="getRating(coach.t2m, coach.name, 'received')">
				</acc-matching-rating-part>
			</ng-container>

		</div>

		<div class="missing-mentors" *ngIf="!coaches.length">
			No Coach
		</div>

		<span class="mentor-type">Other Mentors</span>

		<div class="mentor-and-ratings" *ngIf="mentors.length">

			<ng-container *ngFor="let mentor of mentors">
				<explorer-button [button]="mentor.button"></explorer-button>
				<acc-matching-rating-part [rating]="getRating(mentor.m2t, mentor.name, 'gave')">
				</acc-matching-rating-part>
				<acc-matching-rating-part [rating]="getRating(mentor.t2m, mentor.name, 'received')">
				</acc-matching-rating-part>
			</ng-container>

		</div>

		<div class="missing-mentors" *ngIf="!mentors.length">
			No Other Mentors
		</div>

	</div>
</div>