import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { UtilityService } from '@me-services/core/utility';
import { environment } from '@me-environment';
import firebase from 'firebase/compat/app';
import { auth as firebaseAuth } from 'firebaseui';

@Component({
	selector: 'access-login-buttons',
	templateUrl: './login-buttons.part.html',
	styleUrls: ['./login-buttons.part.scss']
})
export class AccessLoginButtonsPart implements AfterViewInit, OnDestroy {

	private ui: firebaseAuth.AuthUI;

	constructor(private util: UtilityService) {
	}

	async ngAfterViewInit() {
		if (this.ui) await this.ui.delete();

		await this.setupPersistence();
		await this.initFirebaseUI();
	}

	async ngOnDestroy() {
		if (this.ui) await this.ui.delete();
	}


	/**
	 * Make it so the browser remembers the currently logged in person
	 */
	private async setupPersistence() {
		const auth = firebase.auth();

		if (auth) {
			await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
		}
	}


	private async initFirebaseUI() {

		this.util.log.techMessage(`LoginButtons - initFirebaseUI()`);

		const signInFlow = environment.name == 'LOCALHOST'? 'popup' : 'redirect';

		// Need to cast this because the Callback type does not have signInSuccess anymore because
		// firebaseui will be moving to signInSuccessWithAuthResult. However, that currently doesn't
		// have a types authResult.  Since signInSuccess still works, using that and casting with 'any'
		const uiConfig: firebaseAuth.Config = {
			callbacks: {
				signInSuccessWithAuthResult: (authResult: firebase.auth.UserCredential, redirectUrl?: string) => {
					// let user = authResult.user;
					// this.util.log.techMessage(`LoginButtons - signInSuccessWithAuthResult()`, user);
					// this.auth.me.firebaseUser$.next(user);
					return false; //do not perform a manual redirect!
				}
			},
			credentialHelper: firebaseAuth.CredentialHelper.NONE,
			signInFlow,
			signInSuccessUrl: '/access/my/dashboard',
			signInOptions: [
				{
					provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
					customParameters: {
						prompt: 'select_account' //without this user does not even have to choose used account
					},
					scopes: []
				},
				firebase.auth.EmailAuthProvider.PROVIDER_ID
			],
			tosUrl: 'https://eforall.org/terms-of-use'
		};


		if (this.ui) this.ui.reset();
		else this.ui = new firebaseAuth.AuthUI(firebase.auth());

		this.ui.start('#firebaseui-auth-container', uiConfig);  // Waits until the DOM is loaded.
	}
}