import { Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import { CompanyProfileWrapper, PersonWrapper } from '@me-services/old-services-and-wrappers/entity';
import { OldDomainDataService } from '@me-services/core/old-dd';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { ConstantsService } from '@me-services/core/constants';
import { Router } from '@angular/router';

@Component({
	selector: 'entity-address-editor',
	templateUrl: './SHR-ED_address-editor.part.html',
	styleUrls: ['SHR-ED_address-editor.part.scss'],
})
export class EntityAddressEditorPart implements OnInit {

	@Input() personWrapper?: PersonWrapper;
	@Input() companyProfileWrapper?: CompanyProfileWrapper;


	dataWrapper: PersonWrapper | CompanyProfileWrapper;
	form: FormGroup;
	zip = { city: undefined, code: undefined, latitude: undefined, longitude: undefined };

	updating = false;

	constructor(
		private dd: OldDomainDataService,
		private fb: FormBuilder,
		public constants: ConstantsService,
		private cd: ChangeDetectorRef,
		public router: Router,
	) { }

	async ngOnInit() {
		if ((!this.personWrapper && !this.companyProfileWrapper) || (this.personWrapper && this.companyProfileWrapper)) {
			throw new Error('Data Unavailable');
		}

		if (this.personWrapper) this.dataWrapper = this.personWrapper;
		if (this.companyProfileWrapper) this.dataWrapper = this.companyProfileWrapper;

		this.createForm();
	}

	private createForm() {
		this.form = this.fb.group({
			inUS: [true],
			address: [''],
			zipCode: ['', Validators.pattern(this.constants.ZIP_REGEX),
				this.constants.validators.validateZip(this.zip, this.cd)],
		});

		this.resetForm();
	}

	public resetForm() {
		const values = {
			inUS: this.dataWrapper.inUS,
			address: this.dataWrapper.address || '',
			zipCode: this.dataWrapper.zipId && this.dataWrapper._entityExtracts.zip ? this.dataWrapper._entityExtracts.zip.code || '' : '',
		};

		this.form.reset(values);

		Object.keys(this.form.controls).forEach(key => {
			const ctrl: AbstractControl = this.form.get(key);
			ctrl.markAsTouched();
		});

		this.form.updateValueAndValidity();
	}


	toggleCheckbox(box: string) {
		let ctrl: AbstractControl = undefined;

		if (box == 'US') ctrl = this.form.controls.inUS;

		const checked = !ctrl.value;

		ctrl.patchValue(checked);
		ctrl.markAsDirty();
	}

	public async save() {
		this.updating = true;
		const values = this.form.value;
		await this.dataWrapper.updateAddress(values.zipCode, values.inUS, values.address);
		this.resetForm();
		this.updating = false;
	}

	public disableSave() {
		const v = this.form.value;
		// If in US then check if there is a zip code too
		if (v.inUS && !v.zipCode) return true;

		return this.hasNoChanges();
	}

	hasNoChanges() {
		const v = this.form.value;
		let z;
		if (v.zipCode) z = parseInt(v.zipCode, 10);

		if (v.inUS && v.inUS == this.dataWrapper.inUS && v.address == (this.dataWrapper.address || '') && z == this.dataWrapper.zipId) return true;
		if (!v.inUS && v.inUS == this.dataWrapper.inUS && v.address == (this.dataWrapper.address || '')) return true;

		return false;
	}

}
