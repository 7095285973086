import { Accelerator, Event, DbConceptName, Dbs, Immutable } from '@me-interfaces';


interface MutableDbsAccSessionSurveyResponse extends Dbs {

	readonly _concept: DbConceptName.AccSessionSurveyResponse,

	accSessionSurveyResponseId: number,
	accSessionAttendeeId: number,
	accId: number,
	eventId: number,
	accessibility: number,
	communication: number,
	organization: number,
	other: string,
	createdUTC: number,
	updatedUTC: number,
	updatedByPersonId: number,
}


interface MutableAccSessionSurveyResponse extends MutableDbsAccSessionSurveyResponse {
	accelerator: Accelerator,
	event: Event,
}


export interface DbsAccSessionSurveyResponse extends Immutable<MutableDbsAccSessionSurveyResponse> { }
export interface AccSessionSurveyResponse extends Immutable<MutableAccSessionSurveyResponse> { }