<div class='counts-table-part'>
	<ng-container *ngIf='data'>

		<table class='pro-table pro-table-without-border pro-table-with-thin-rows'>
			<tr>
				<th>{{ data.header }}</th>
				<th>Count</th>
				<th>Contact</th>
			</tr>
			<tr *ngFor="let row of data.rows">
				<td>{{row.label}}</td>
				<td>
					<button class="text-btn text-btn-not-inline counts-table-count-button"
						(click)="countClick.emit(row)" [title]="getTitle(row)" [showOn]="getTitleShowOn(row)"
						kendoTooltip position="right">
						<span style="padding: 0, 0.5em">{{row.count}}</span>
					</button>
				</td>
				<td>
					<button class="text-btn text-btn-not-inline" (click)="openContactor(row.personIds)"
						*ngIf="row.personIds.length">
						<span class='contact-button-content'>
							<i class='far fa-envelope'></i> <i class='far fa-comment-lines'></i>
						</span>
					</button>
				</td>
			</tr>
		</table>
	</ng-container>
</div>