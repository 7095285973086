<div class='acc-team-explorer-details-part' *ngIf="accTeam">

	<div id="container" class="explorer-details-container">

		<table class='pro-table pro-table-no-header pro-table-with-labels'>
			<tr>
				<td> Acc Team Id</td>
				<td> {{accTeam.accTeamId}}</td>
			</tr>

			<tr>
				<td>Accelerator</td>
				<td>
					<explorer-button [button]="{ explorableId: { conceptName: 'accelerator', id: accTeam.accId }}">
					</explorer-button>
				</td>
			</tr>

			<tr>
				<td>Application</td>
				<td>
					<explorer-button
						[button]="{ explorableId: { conceptName: 'application', id: accTeam.applicationId }}">
					</explorer-button>
				</td>
			</tr>

			<tr *ngIf="accTeam.notes">
				<td>Notes</td>
				<td>
					{{accTeam.notes}}
				</td>
			</tr>

			<tr *ngIf="accTeam.droppedOutWeek">
				<td>Dropped Out Week</td>
				<td>{{accTeam.droppedOutWeek}}</td>
			</tr>

			<tr>
				<td>Last Edited By</td>
				<td>
					<explorer-button
						[button]="{ explorableId: { conceptName: 'person', id: accTeam.updatedByPersonId }}">
					</explorer-button>
					<div class="small-nowrap-text">
						on {{util.date.formatUTC(accTeam.updatedUTC, 'MMM D, YYYY', 'H:MM AM EST',
						urlService.languageId)}}
					</div>
				</td>
			</tr>
		</table>

		<div class="explorer-details-right">
		</div>

	</div>
</div>