import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { ADDROW_GRID_ACTION_KEY, GridColumnType, GridSetup } from '@me-grid';
import { DbsEvent, EventContext } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { EventDialogResult, ShowEventDialogService } from '@me-shared-parts/ED-editors';


@Component({
	selector: 'events-grid',
	template: `<me-grid [setup]='gridSetup' [rows]='rows' (gridAction)='gridActionHandler($event)'></me-grid>`,
})
export class EventsGridPart extends DestroyablePart implements OnInit, OnChanges {
	@Input() events: DbsEvent[];
	@Input() stateKey: string;
	@Input() eventContext: EventContext;
	@Input() onlyOneEvent = false;
	@Output() eventChanged = new EventEmitter<EventDialogResult>();


	public gridSetup = this.setupGrid();

	public rows: { eventId: number }[];

	constructor(
		private ds: DataService,
		private util: UtilityService,
		private showEventDialogService: ShowEventDialogService) {
		super()
	}

	ngOnInit() {
		super.initDestroyable();
	}

	async ngOnChanges() {
		if (!this.events) throw new Error('Missing required attribute: events');

		if (this.events) this.buildRows();
	}


	async buildRows() {
		this.rows = this.events.map(e => ({ eventId: e.eventId }));

		if (this.onlyOneEvent && this.rows.length) this.gridSetup.canAdd = false;
	}

	private setupGrid(): GridSetup<DbsEvent> {

		return {
			experience: 'EVENT',
			size: {
				fitTo: 'INLINE',
				height: 250,
				shrinkBy: 0,
			},
			rowSingularName: 'Event',
			rowPluralName: 'Events',
			rowKey: 'eventId',
			stateKey: this.stateKey,
			canAdd: true,
			canRefresh: false,
			groupArea: 'showWithoutInstructions',
			canDownload: true,
			columnsToAdd: [
				{ field: 'EVENT_eventId', header: 'Id', width: 90, type: GridColumnType.number, hidden: false },
				{ field: 'EVENT_endUTC', header: 'End', width: 150, type: GridColumnType.dateAndTimeUtc, hidden: false },
			],
		};
	}


	async gridActionHandler(action: { actionKey: string, rows: DbsEvent[] }) {
		if (action.actionKey == ADDROW_GRID_ACTION_KEY) {
			if (this.onlyOneEvent && this.rows.length) return;
			const result = await this.showEventDialogService.showAdd({ ...this.eventContext });
			if (result?.action == 'ADDED') {
				if (this.onlyOneEvent) this.gridSetup.canAdd = false;
				this.eventChanged.emit(result);
			}
		}
	}

}