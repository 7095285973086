import { AwardKindId } from '@me-interfaces';
import { DdObject } from '../dd-object';
import { OldDomainDataService } from '../domain-data.service';
import { DdAwardKindRow } from './dd-award-kind-row';

/**
 * @deprecated Use the DataService instead
 */
export class DdAwardKind extends DdObject<DdAwardKindRow> implements DdAwardKindRow {

	constructor(dd: OldDomainDataService, data: DdAwardKindRow) {
		super(data);
	}

	public get awardKindId(): AwardKindId {
		return this._data.awardKindId;
	}

	public get name(): string {
		return this._data.name;
	}

}