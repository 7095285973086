<div class='venue-explorer-details-part' *ngIf="venue">

	<div id="container" class="explorer-details-container">

		<table class='pro-table pro-table-no-header pro-table-with-labels'>
			<tr>
				<td> Venue Id</td>
				<td> {{venue.venueId}}</td>
			</tr>

			<tr>
				<td> Official Name</td>
				<td> {{venue.officialName}}</td>
			</tr>

			<tr>
				<td> Displayed Name</td>
				<td> {{venue.displayedName}}</td>
			</tr>

			<tr>
				<td> Is Active</td>
				<td> {{venue.isActive}}</td>
			</tr>

			<tr>
				<td> Address</td>
				<td>
					<link-button
						[link]="{ linkType: 'address', street: venue.address, zipId: venue.zipId}"></link-button>
				</td>
			</tr>

			<tr *ngIf="venue.publicNotes">
				<td> Public Notes</td>
				<td> {{venue.publicNotes}}</td>
			</tr>

			<tr *ngIf="venue.internalNotes">
				<td> Internal Notes</td>
				<td> {{venue.internalNotes}}</td>
			</tr>

			<tr>
				<td>Created By</td>
				<td>
					<explorer-button [button]="{ explorableId: { conceptName: 'person', id: venue.createdByPersonId }}">
					</explorer-button>
					<div class="small-nowrap-text">
						on {{util.date.formatUTC(venue.createdUTC, 'MMM D, YYYY', 'H:MM AM EST',
						urlService.languageId)}}
					</div>
				</td>
			</tr>

			<tr>
				<td>Last Edited By</td>
				<td>
					<explorer-button [button]="{ explorableId: { conceptName: 'person', id: venue.updatedByPersonId }}">
					</explorer-button>
					<div class="small-nowrap-text">
						on {{util.date.formatUTC(venue.updatedUTC, 'MMM D, YYYY', 'H:MM AM EST',
						urlService.languageId)}}
					</div>
				</td>
			</tr>

		</table>

		<div class="explorer-details-right">
		</div>

	</div>
</div>