import { SharedDashboardsService } from './shared-dashboard.service';
import { Component, Input } from '@angular/core';
import { PageTabsLayout } from '@me-shared-parts/UI-common';
import { Observable } from 'rxjs';


@Component({
	selector: 'shared-dashboard-views-part',
	templateUrl: './shared-dashboard-views.part.html',
})
export class SharedDashboardViewsPart {

	@Input() layout$: Observable<PageTabsLayout>;
	
	constructor(public sharedViewsService: SharedDashboardsService) {
	}

}
