import { DdObject } from '../dd-object';
import { OldDomainDataService } from '../domain-data.service';
import { DdAwardNameDecidingRoleRow } from './dd-award-name_deciding-role-row';

/**
 * @deprecated Use the DataService instead
 */
export class DdAwardNameDecidingRole extends DdObject<DdAwardNameDecidingRoleRow> implements DdAwardNameDecidingRoleRow {

	constructor(dd: OldDomainDataService, data: DdAwardNameDecidingRoleRow) {
		super(data);
	}

	public get awardNameDecidingRoleId(): number {
		return this._data.awardNameDecidingRoleId;
	}

	public get awardNameId(): number {
		return this._data.awardNameId;
	}

	public get decidingRoleId(): number {
		return this._data.decidingRoleId;
	}
}