import { AccProgramResults, CommonProgramResults, PicProgramResults } from './program-results';


export function renderAccResults(results: AccProgramResults): string {
	const r = renderCommonResults(results, 'Cohort');
	//TODO: Render non common results
	return r.join(' | ');
}


export function renderPicResults(results: PicProgramResults): string {
	const r = renderCommonResults(results, 'Contest');
	//TODO: Render non common results
	return r.join(' | ');
}


function addNamedResult(name: string, count: number) {
	if (count > 1) return `${name}(${count})`;
	else return name;
}


function renderCommonResults(results: CommonProgramResults, promotedName: 'Cohort' | 'Contest'): string[] {

	const r: string[] = [];
	
	if (results.pending) r.push(addNamedResult('Pending', results.pending));
	if (results.abandoned) r.push(addNamedResult('Abandoned', results.pending));
	if (results.withdrawn) r.push(addNamedResult('Withdrawn', results.pending));
	if (results.rejected) r.push(addNamedResult('Rejected', results.pending));
	if (results.deferred) r.push(addNamedResult('Deferred', results.pending));
	if (results.copiedForward) r.push(addNamedResult('CopiedForward', results.pending));
	if (results.promoted) r.push(addNamedResult(promotedName, results.pending));
	
	return r;
}