import { Component, Input, OnChanges } from '@angular/core';
import { DbsEvent, DbsPerson } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { DdVenueRow, OldDomainDataService } from '@me-services/core/old-dd';
import { UtilityService } from '@me-services/core/utility';
import { convertEventToMutable } from '../../convert-event-to-mutable';
import { DatesProcessor } from '../dates-processor';

@Component({
	selector: 'event-dialog-view',
	templateUrl: './SHR-ED_view-event.part.html',
	styleUrls: ['./SHR-ED_view-event.part.scss']
})
export class EventDialogViewPart implements OnChanges {

	@Input() eventContextRows: { label: string, value: string, site?: string }[] = [];
	@Input() event: DbsEvent;
	@Input() toolIconMessage = '';

	dates: DatesProcessor;
	venue: DdVenueRow;
	host: DbsPerson;


	constructor(private dd: OldDomainDataService, private util: UtilityService, private ds: DataService) {
	}


	async ngOnChanges() {
		this.venue = this.event?.venueId ? this.dd.venues.getById(this.event.venueId) : undefined;
		this.dates = this.event ? new DatesProcessor(convertEventToMutable(this.event), this.util) : undefined;
		this.host = this.event.hostPersonId ? await this.ds.admin.person.getOne(this.event.hostPersonId) : undefined;
	}


	toolIconClick() {
		window.alert(this.toolIconMessage);
	}

}