import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MainSelector } from '../selector';

@Component({
	selector: 'selectable-view-selectors',
	templateUrl: './SHR-CMN_selectable-view-selectors.part.html',
	styleUrls: ['SHR-CMN_selectable-view-selectors.part.scss'],
})
export class SelectableViewSelectorsPart implements OnChanges {

	@Input() selectors: MainSelector[];
	@Input() selectorListTitle: string;
	@Input() initialSelectorKey?: string;
	@Output() selectorKeyChanged = new EventEmitter<string>(); // The key of the selector that was clicked

	displaySubMenu: { [index: string]: boolean } = {};
	displayChildMenu: { [index: string]: boolean } = {};

	ngOnChanges() {

		if (!this.initialSelectorKey) {
			this.updateKey(this.selectors[0]);
		}
		else {
			for (const selector of this.selectors) {
				if (selector.key == this.initialSelectorKey) this.updateKey(selector);
				else if (selector.children) {
					const child = selector.children.find(child => child.key == this.initialSelectorKey);
					if (child) this.updateKey(selector, child.key);
				}
			}
		}
	}

	updateKey(mainMenu: MainSelector, childMenuKey: string = undefined) {
		Object.keys(this.displaySubMenu).forEach(key => this.displaySubMenu[key] = false);
		Object.keys(this.displayChildMenu).filter(key => key != mainMenu.key).map(key => { this.displayChildMenu[key] = false; });

		if (childMenuKey) {
			this.displaySubMenu[mainMenu.key] = true;
			this.displaySubMenu[childMenuKey] = true;
			this.displayChildMenu[mainMenu.key] = true;
			this.selectorKeyChanged.emit(childMenuKey);
		}
		else if (mainMenu.children && mainMenu.children.length) {
			this.displaySubMenu[mainMenu.key] = true;
			this.displayChildMenu[mainMenu.key] = !this.displayChildMenu[mainMenu.key];
			mainMenu.children = mainMenu.children.sort((a, b) => a.label > b.label ? 1 : -1);
			this.selectorKeyChanged.emit(mainMenu.key);
		}
		else {
			this.displaySubMenu[mainMenu.key] = true;
			this.selectorKeyChanged.emit(mainMenu.key);
		}
	}
}
