import { PhoneTypeId } from '@me-interfaces';
import { DdPhoneTypeRow } from './dd-phone-type-row';
import { DdObject } from '../dd-object';
import { OldDomainDataService } from '../domain-data.service';

/**
 * @deprecated Use the DataService instead
 */
export class DdPhoneType extends DdObject<DdPhoneTypeRow> implements DdPhoneTypeRow {

	constructor(dd: OldDomainDataService, data: DdPhoneTypeRow) {
		super(data);
	}

	public get phoneTypeId(): PhoneTypeId {
		return this._data.phoneTypeId;
	}

	public get name(): string {
		return this._data.name;
	}
}