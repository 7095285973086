import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { DbcAccTeamGoal } from '@me-interfaces';
import { OldUserService } from '@me-services/old-services-and-wrappers/user';
import { DialogService } from '@me-services/ui/dialog';
import { LabelsService } from '@me-services/ui/labels';
import { Message, SendMessageEvent, User } from "@progress/kendo-angular-conversational-ui";
import { QUARTER_NAMES } from '../../acc-goals-interfaces';
@Component({
	selector: 'acc-goals-view-part',
	templateUrl: './SHR-PG_acc-goals-view.part.html',
	styleUrls: ['SHR-PG_acc-goals-view.part.scss'],
})
export class AccGoalsViewPart extends DestroyablePart implements OnInit, OnChanges {
	@Input() selectedGoal: DbcAccTeamGoal;
	@Input() comments: Message[];
	@Input() canAddComment: boolean;
	@Output() addComment = new EventEmitter<{ comment: string, accTeamGoalId: number }>();

	QUARTER_NAMES = QUARTER_NAMES;

	inlineLabels = this.labels.trackInlineLabels(this, [
		'Q1',
		'Q2',
		'Q3',
		'Q4',
		'Send',
		'Add a comment...',
		'Goal created by',
		'Goal updated by',
	]);

	user: User;


	constructor(
		private labels: LabelsService,
		private userService: OldUserService,
		private dialogService: DialogService,
	) {
		super();
	}

	ngOnInit() {
		super.initDestroyable();
	}

	async ngOnChanges() {
		if (!this.selectedGoal) throw new Error('Missing required attribute: selectedGoal');
		const user = await this.userService.getUser();
		if (user) this.user = {
			id: user.personId,
			name: user._name,
		};
	}


	public async sendMessage(e: SendMessageEvent) {
		if (!this.canAddComment) {
			await this.dialogService.showMessage(`You do not have access to add comments.`);
			return;
		}
		this.addComment.emit({ comment: e.message.text, accTeamGoalId: this.selectedGoal.accTeamGoalId });
	}
}
