<me-dialog-frame [header]='header' [actions]='actions' [dialogRef]='dialogRef' [icon]='icon' alertType='editor'>
	<div class="topic-material-dialog" *ngIf="topic">

		<div class="topic-name" [class.topic-active]="topic.enabled" [class.topic-in-active]="!topic.enabled">
			<me-h2>{{topic.longNameLabel}}<span *ngIf="!topic.enabled"> (In-Active)</span></me-h2>
		</div>
		
		<div class="no-material" *ngIf="!data.length">
			This topic does not have any course materials.
		</div>

		<table class="pro-table pro-table-no-header pro-table-with-labels pro-table-without-border">
			<tr *ngFor="let d of data">
				<td>{{d.id}}</td>
				<td><me-icon [icon]="d.icon"></me-icon></td>
				<td><a [href]="d.url" target="_blank"><div>{{d.name}}</div></a></td>
			</tr>
		</table>

	</div>
</me-dialog-frame>