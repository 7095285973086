import { Dbc } from "@me-interfaces";


export interface DbcPicApplication extends Dbc {
	applicationId: number,
	picId: number,
	personId: number,
	applicationStatusId: number,
	homeAddress: string,
	homeZipId: number,
	isUnemployed: string,
	companyId: number,
	isNonprofit: boolean,
	ideaName: string,
	answersId: number,
	notes: string,
}