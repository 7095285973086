<div class='pitch-contest-explorer-details-part' *ngIf="pitchContest">

	<div id="container" class="explorer-details-container">

		<table class='pro-table pro-table-no-header pro-table-with-labels'>
			<tr>
				<td> Pitch Contest Id</td>
				<td> {{pitchContest.picId}}</td>
			</tr>

			<tr>
				<td> Name</td>
				<td> {{pitchContest.name}}</td>
			</tr>

			<tr>
				<td> Long Name</td>
				<td> {{pitchContest.longName}}</td>
			</tr>

			<tr>
				<td> Stage</td>
				<td> {{pitchContest.stage.name}}</td>
			</tr>

			<tr>
				<td> Application Start Date</td>
				<td>
					{{util.date.formatUTC(pitchContest.applStartUTC, 'MMM D, YYYY', 'No Time', urlService.languageId)}}
				</td>
			</tr>

			<tr>
				<td>Created On</td>
				<td>
					{{util.date.formatUTC(pitchContest.createdUTC, 'MMM D, YYYY', 'H:MM AM EST',
					urlService.languageId)}}
				</td>
			</tr>

			<tr>
				<td>Last Edited By</td>
				<td>
					<explorer-button
						[button]="{ explorableId: { conceptName: 'person', id: pitchContest.updatedByPersonId }}">
					</explorer-button>
					<div class="small-nowrap-text">
						on {{util.date.formatUTC(pitchContest.updatedUTC, 'MMM D, YYYY', 'H:MM AM EST',
						urlService.languageId)}}
					</div>
				</td>
			</tr>

		</table>

		<div class="explorer-details-right">
		</div>

	</div>
</div>