<div class='acc-match-counts-part'>
	<ng-container *ngIf='countsData'>
		<table class='pro-table pro-table-without-border pro-table-with-thin-rows'>
			<tr>
				<th>Item</th>
				<th>Count</th>
				<th>Contact</th>
			</tr>
			<tr>
				<td>All Matchable Mentors</td>
				<td class="counts-cell">
					<button class="text-btn text-btn-not-inline" (click)="setViewAndFilterGrid('All')">
						<span class='grid-filter-button-content'>
							{{countsData.uniqueMentorsCount}}
						</span>
					</button>
				</td>
				<td>
					<button class="text-btn text-btn-not-inline" (click)="openContactor(countsData.mentorPersonIds)">
						<span class='contact-button-content'>
							<i class='far fa-envelope'></i> <i class='far fa-comment-lines'></i>
						</span>
					</button>
				</td>
			</tr>
			<tr>
				<td>Mentors that Can Coach</td>
				<td class="counts-cell">
					<button class="text-btn text-btn-not-inline" (click)="setViewAndFilterGrid('Can Coach')">
						<span class='grid-filter-button-content'>
							{{countsData.canCoachCount}}
						</span>
					</button>
				</td>
				<td>
					<button *ngIf="countsData.canCoachCount > 0" class="text-btn text-btn-not-inline"
						(click)="openContactor(countsData.canCoachPersonIds)">
						<span class='contact-button-content'>
							<i class='far fa-envelope'></i> <i class='far fa-comment-lines'></i>
						</span>
					</button>
				</td>
			</tr>
			<tr>
				<td>Mentors with Multi Use</td>
				<td class="counts-cell">
					<button class="text-btn text-btn-not-inline" (click)="setViewAndFilterGrid('Multi Use')">
						<span class='grid-filter-button-content'>
							{{countsData.multiUseCount}}
						</span>
					</button>
				</td>
				<td>
					<button *ngIf="countsData.multiUseCount > 0" class="text-btn text-btn-not-inline"
						(click)="openContactor(countsData.multiUseMentorPersonIds)">
						<span class='contact-button-content'>
							<i class='far fa-envelope'></i> <i class='far fa-comment-lines'></i>
						</span>
					</button>
				</td>
			</tr>
			<tr>
				<td>Total Mentor Uses</td>
				<td class="counts-cell">{{countsData.useCount}}</td>
				<td></td>
			</tr>
		</table>
	</ng-container>
</div>