import { DbsPerson, DbsTag, Tag, TagName } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { Observable } from "rxjs";
import { DomainDataManagers } from "../interfaces/domain-data-managers";
import { PackageManager } from "../package-manager";
import { SingletonsManager } from "../singletons-manager";
import { SitePackageManager } from "./site";
import { TagPrefixPackageManager } from "./tag-prefix";


export class TagPackageManager extends PackageManager<DbsTag, Tag> {


	constructor(
		singletonsAsOfUTC$: Observable<number>,
		util: UtilityService,
		sm: SingletonsManager<DbsTag>,
		private domain: DomainDataManagers,
		private person: SingletonsManager<DbsPerson>,
		private tagPrefix: TagPrefixPackageManager,
		private site: SitePackageManager,
	) {
		super(singletonsAsOfUTC$, util, sm);
	}


	/**
	 * Convert an array of DbcTag to an array of TAG
	 */
	protected async _createPackages(dbsTags: DbsTag[]): Promise<Tag[]> {

		//
		//  Get all the related objects
		//
		const personIds: number[] = [];
		const tagPrefixIds: number[] = [];

		for (const tag of dbsTags) {
			personIds.push(tag.updatedByPersonId);
			tagPrefixIds.push(tag.tagPrefixId);
		}

		const personMap = await this.person.getManyAsMap(personIds);
		const tagPrefixMap = await this.tagPrefix.getManyPackagesAsMap(tagPrefixIds);
		const allSitesMap = await this.site.getAllPackagesAsMap();


		//
		// Package 'em up
		//
		const tags: Tag[] = dbsTags.map(tag => {

			const updatedByPerson = personMap[tag.updatedByPersonId];
			const tagPrefix = tagPrefixMap[tag.tagPrefixId];
			const site = allSitesMap[tagPrefix.siteId];
			const fullName = `${tagPrefix.prefix}:${tag.name}`;

			return {
				...tag,
				tagPrefix,
				updatedByPersonName: updatedByPerson?._name || `Person #${tag.updatedByPersonId}`,
				site,
				fullName,
			};
		});

		return tags;
	}


	public async getTagsForSiteByRole(siteId: number, role: 'reader' | 'judge' | 'mentor' | 'interviewer' | 'eventhelper' | 'coach') {
		const tags = await this.getAllPackagesAsArray();

		const siteTags = tags.filter(tag => tag.site && (tag.site.siteId == siteId));
		return siteTags.filter(tag => tag.name.split('-')[0] == role);

	}


	public async getTagForSite(siteId: number) {
		const tags = await this.getAllPackagesAsArray();
		return tags.filter(tag => tag.site && (tag.site.siteId == siteId));

	}

}