<div class="grid-cell me-grid-cell-part" [class.grid-cell-numeric-right]='isNumeric'
	[class.grid-cell-icon-center]='style.textStyle==="Hidden"' [class.grid-cell-error]='style.textStyle==="Error"'
	[class.grid-cell-warning]='style.textStyle==="Warning"' [class.grid-cell-highlight]='style.textStyle==="Highlight"'
	[class.grid-cell-success]='style.textStyle==="Success"' [attr.title]='tooltip'
	[class.strikethrough-cell]='!!strikethrough'>

	<i *ngIf='!!style.icon' class='grid-cell-icon {{style.icon}}' [class.red-icon]='style.iconColor==="Red"'
		[class.green-icon]='style.iconColor==="Green"' [class.blue-icon]='style.iconColor==="Blue"'
		[attr.title]='value'></i>

	<ng-container *ngIf='style.textStyle!=="Hidden"'>
		<ng-content></ng-content>
	</ng-container>

</div>