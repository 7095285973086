<div class='application-explorer-questions-part'>
	<table style="width:100%" class='pro-table pro-table-with-thin-rows'>
		<tr>
			<th>Tell us about your idea</th>
			<th>How did you find out about EforAll and our program?</th>
		</tr>
		<tr *ngIf="!applicationQuestions">
			<td style='width:50%'>
				<button class="text-btn" (click)="retrieve()">Click to See</button>
			</td>
			<td>
				<button class="text-btn" (click)="retrieve()">Click to See</button>
			</td>
		</tr>
		<tr *ngIf="applicationQuestions">
			<td style='width:50%'>{{applicationQuestions.ideaAnswer}}</td>
			<td>{{applicationQuestions.hearAnswer}}</td>
		</tr>
	</table>
</div>