import { DbConceptName, Dbd, Immutable, PhoneTypeId } from '@me-interfaces';


interface MutableDbdPhoneType extends Dbd {

	readonly _concept: DbConceptName.PhoneType,

	phoneTypeId: PhoneTypeId,
	name: string,
}


export interface DbdPhoneType extends Immutable<MutableDbdPhoneType> { }