import { Injectable } from '@angular/core';
import { PageSpinnerService } from '@me-services/ui/page-spinner';
import { Dexie } from 'dexie';
import { lastValueFrom, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { CHANGE_LOG } from '../../../admin/admin-area/pages/whats-new-page/release-changelog';
import { FuncService } from '../func';
import { UtilityService } from '../utility';
import { AREA_DATA_DB_KEY, DexieAreaDataDb } from './dexie-area-data-db';
import { DexieDomainDataDb } from './dexie-domain-data-db';
import { DexieSingletonsDb, SINGLETONS_DATA_DB_KEY } from './dexie-singletons-db';

/**
 * LocalStorage Key for storing the UTC (seconds!) of the cache files that were last downloaded.
 */
export const SINGLETONS_CACHE_FILES_UTC = 'SingletonsCacheFilesUTC';

/**
 * LocalStorage key for storing the UTC (seconds!) of the last updated set of singletons read. This keeps ratcheting up.
 */
export const SINGLETONS_AS_OF_UTC = 'SingletonsAsOfUTC';

@Injectable({
	providedIn: 'root'
})
/**
 * Service that exposes an IndexedDB database. This should be used for storing large
 * amounts of structured data. LocalStorage should still be used for small amounts of
 * textual data.
 * 
 * NOTE: Keep in mind that the data is stored by domain. So stored data will be
 * different with eforall.app, eparatodos.app
 *  
 * NOTE: This is not SQL. Don't declare all columns like SQL.  Also, it is critical
 * to use versioning and upgrading if breaking changes are made to schema.
 * See https://dexie.org/docs/API-Reference#quick-reference
 */
export class DexieService {

	private readonly areaDataDb$ = new ReplaySubject<DexieAreaDataDb>(1);
	private readonly domainDb$ = new ReplaySubject<DexieDomainDataDb>(1);
	private readonly singletonsDb$ = new ReplaySubject<DexieSingletonsDb>(1);


	constructor(
		private func: FuncService,
		private util: UtilityService,
		private pageSpinner: PageSpinnerService,
	) {
		this.util.log.techMessage(`DEXIE SERVICE CONSTRUCTED`);
		this.startDatabases();
	}


	/**
	 * Create Dexie storage for each of the three database areas (domain, areas, singletons)
	 */
	private async startDatabases(resetFirst = false) {

		this.util.log.techMessage(`DEXIE - startDatabases`);

		this.domainDb$.next(new DexieDomainDataDb(this.pageSpinner));

		if (await this.checkForUrlResetParameter()) resetFirst = true;
		if (await this.checkForNewRelease()) resetFirst = true;

		if (resetFirst) {
			//
			// Remove the last cache file key which will force a reload of the cache files 
			//
			localStorage.removeItem(SINGLETONS_CACHE_FILES_UTC);
			localStorage.removeItem(SINGLETONS_AS_OF_UTC);

			await Dexie.delete(AREA_DATA_DB_KEY);
			await Dexie.delete(SINGLETONS_DATA_DB_KEY);
		}


		this.areaDataDb$.next(new DexieAreaDataDb(this.pageSpinner));
		this.singletonsDb$.next(new DexieSingletonsDb(this.func, this.util, this.pageSpinner));

	}



	/**
	 * Force a reset of the state database if the url ends with ?reset
	 */
	private checkForUrlResetParameter(): boolean {

		this.util.log.techMessage(`DEXIE - checkForUrlResetParameter`);

		const href = window.location?.href.toLowerCase();

		if (href.includes('?reset')) {
			window.location.assign(href.split('?')[0]);
			return true;
		}

		return false;
	}


	/**
	 * Force a reset of the state database if the name of the release has changed
	 */
	private checkForNewRelease(): boolean {

		this.util.log.techMessage(`DEXIE - checkForNewRelease`);

		const EFORALL_APP_RELEASE = 'EFORALL_APP_RELEASE';
		const release = CHANGE_LOG[0].name;

		if (localStorage.getItem(EFORALL_APP_RELEASE) !== release) {

			localStorage.setItem(EFORALL_APP_RELEASE, release);
			return true;
		}

		return false;
	}


	public async resetDatabase() {
		await this.startDatabases(true);
	}


	public async getAreaDataDb(): Promise<DexieAreaDataDb> {
		return await lastValueFrom(this.areaDataDb$.pipe(take(1)));
	}


	public async getDomainDataDb(): Promise<DexieDomainDataDb> {
		return await lastValueFrom(this.domainDb$.pipe(take(1)));
	}


	public async getSingletonsDb(): Promise<DexieSingletonsDb> {
		return await lastValueFrom(this.singletonsDb$.pipe(take(1)));
	}

}