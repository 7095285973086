import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Field, FieldTool, FieldValues, UpdatedField } from '@me-interfaces';
import { UtilityService } from '@me-services/core/utility';
import { FieldFormState } from '../field-form-state';




@Component({
	selector: 'field-grid',
	templateUrl: './SHR-UI_field-grid.part.html',
	styles: [`
		.field-grid-part {
			display: grid;
			column-gap: 25px;
			grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
		}

		.field-grid-part > * {
			align-self: end;
		}
	`],
})

export class FieldGridPart implements OnInit, OnChanges {

	//
	// We make a copy of the input the first time it is set but not subsequent times.
	// This way, the field parts won't rebind  with new instances of the same properties.
	//
	_fields: Field[] = [];
	_values: FieldValues = {};
	@Input() fields: Field[];
	@Input() values: FieldValues;
	@Input() tool: FieldTool;
	@Input() forceValidation = false;
	@Input() state: FieldFormState;
	@Output() fieldUpdate = new EventEmitter<UpdatedField>();


	constructor(private util: UtilityService) { }


	async ngOnInit() {
		if (!this.fields) throw new Error('Missing required attribute: fields');
		if (!this.values) throw new Error('Missing required attribute: values');
		if (!this.tool) throw new Error('Missing required attribute: tool');
		if (!this.state) throw new Error('Missing required attribute: state');
	}


	async ngOnChanges(changes: SimpleChanges) {

		if (changes.fields) {

			const fields: Field[] = changes.fields.currentValue;

			if (changes.fields.isFirstChange() || !this.util.values.areSame(fields, this._fields)) {
				// if (this.state.numTextAreaFields) {
				// 	this.util.log.errorMessage(`The fields @Input was changed but the state @Input wasn't`);
				// 	debugger;
				// }
				this._fields = fields;
			}
		}

		if (changes.values) {

			const values: FieldValues = changes.values.currentValue;

			if (changes.values.isFirstChange()) this._values = values;

			else for (const fieldKey in values) {
				if (!this.util.values.areSame(this._values[fieldKey], values[fieldKey])) {
					this._values[fieldKey] = values[fieldKey];
				}
			}
		}
	}


	public getValue(field: Field) {
		return this._values[field.key];
	}


	public getOtherValue(field: Field) {
		return this._values[field.otherValueKey];
	}


	public onFieldUpdate(field: Field, value: string | number) {
		this._values[field.key] = value;
		this.fieldUpdate.emit({ field, value });
	}

}