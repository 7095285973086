import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { ApplicationFieldsParticipant, ApplicationParticipantPendingEditableFields, ApplicationParticipantStatus, DB_LENGTH } from '@me-interfaces';
import { UtilityService } from '@me-services/core/utility';
import { DialogAction, DialogContext, DialogService } from '@me-services/ui/dialog';
import { LabelsService } from '@me-services/ui/labels';

@Component({
	selector: 'application-participant-dialog',
	templateUrl: './SHR-PG_application-participants.dialog.html',
	styleUrls: ['./SHR-PG_application-participants.dialog.scss']
})
export class ApplicationParticipantDialog extends DestroyablePart implements OnInit {

	//
	// Configuration for the input fields 
	//
	values = {
		firstName: {
			readonly: true,
			value: '',
			changed: false,
			pending: '',
			min: DB_LENGTH.person.firstName[0],
			max: DB_LENGTH.person.firstName[1],
			valid: false,
		},
		lastName: {
			readonly: true,
			value: '',
			changed: false,
			pending: '',
			min: DB_LENGTH.person.lastName[0],
			max: DB_LENGTH.person.lastName[1],
			valid: false,
		},
		title: {
			readonly: false,
			value: '',
			changed: false,
			pending: '',
			min: DB_LENGTH.position.title[0],
			max: DB_LENGTH.position.title[1],
			valid: false,
		},
		email: {
			readonly: true,
			value: '',
			changed: false,
			pending: '',
			min: DB_LENGTH.email.email[0],
			max: DB_LENGTH.email.email[1],
			valid: false,
		},
	};


	//
	// Translated labels for the HTML
	//
	inlineLabels = this.labels.trackInlineLabels(this, [
		'Participant',
		'First Name',
		'Last Name',
		'Title',
		'Email',
		'Up to XXXX characters',
	]);

	//
	// The action buttons
	//
	addAction: DialogAction = {
		id: 'add',
		enabled: true,
		visible: false,
		label: ':Add',
		linkType: 'actionClick-event',
		willCloseDialog: true,
	};

	updateAction: DialogAction = {
		id: 'update',
		enabled: true,
		visible: false,
		label: ':Update',
		linkType: 'actionClick-event',
		willCloseDialog: true,
	};

	cancelAction: DialogAction = {
		id: 'close',
		enabled: true,
		visible: true,
		label: ':Cancel',
		linkType: "no-action",
		willCloseDialog: true,
	};

	actions: DialogAction[] = [this.addAction, this.updateAction, this.cancelAction];
	icon = 'fa-user-plus';
	headerText = 'Participant';


	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<ApplicationFieldsParticipant>,
		public dialogRef: MatDialogRef<ApplicationParticipantDialog>,
		private dialogService: DialogService,
		private labels: LabelsService,
		private util: UtilityService,
	) {
		super();
	}


	async ngOnInit() {
		super.initDestroyable();

		const getLabel = await this.labels.getLabel();
		const participantLabel = getLabel({ key: 'Participant' });

		//
		// Payload will be an ApplicationFieldsParticipant if updating or undefined if adding
		//
		const p = this.dialog.data;

		if (p) {
			this.icon = 'fa-user-edit';
			this.addAction.visible = false;
			this.updateAction.visible = true;
			this.headerText = `${getLabel({ key: 'Update' })} ${participantLabel}`;
		}
		else {
			this.icon = 'fa-user-plus';
			this.addAction.visible = true;
			this.updateAction.visible = false;
			this.headerText = `${getLabel({ key: 'Add' })} ${participantLabel}`;
		}

		//
		// Initialize with the values passed in 
		//
		this.values.firstName.value = p?.firstName ?? '';
		this.values.lastName.value = p?.lastName ?? '';
		this.values.title.value = p?.title ?? '';
		this.values.email.value = p?.email ?? '';

		//
		// Set the pending values, which will also check their validity
		//
		this.setPendingValue('firstName', this.values.firstName.value);
		this.setPendingValue('lastName', this.values.lastName.value);
		this.setPendingValue('title', this.values.title.value);
		this.setPendingValue('email', this.values.email.value);

		//
		// Title can always be edited, but the rest can only be edited when adding or status==invited
		//
		const addingOrInvited = ((p?.status ?? ApplicationParticipantStatus.Invited) == ApplicationParticipantStatus.Invited);
		this.values.firstName.readonly = !addingOrInvited;
		this.values.lastName.readonly = !addingOrInvited;
		this.values.email.readonly = false;
		this.values.email.readonly = !addingOrInvited;


		this.checkIfCanSave();

	}


	/**
	 * Record the pending value and check if it is valid (within the length range)
	 */
	setPendingValue(field: 'firstName' | 'lastName' | 'title' | 'email', text: string) {

		//
		// Cleanup the text
		//
		text = (text ?? '').trim();

		if (field == 'email') text = this.util.text.fixEmail(text) ?? '';
		else {
			//
			// Titles can be three-letter, uppercase (e.g. CEO) but anything else has the case fixed
			//
			if (field !== 'title' || text.length !== 3 || text !== text.toUpperCase())
				text = this.util.text.fixCase(text);
		}

		const values = this.values[field];
		values.pending = text;
		values.valid = text && text.length >= values.min && text.length <= values.max;

		this.checkIfCanSave();
	}


	/**
	 * This function is called as the user is typing/pasting/deleting into a field
	 */
	onInput(field: 'firstName' | 'lastName' | 'title' | 'email', e) {

		const text = (e.target.value ?? '').trim();
		this.setPendingValue(field, text);
		this.checkIfCanSave();
	}


	/**
	 * When losing focus and a pending values was added, that pending value may have been
	 * cleaned (e.g. fixCase) so we update the value with the pending value so it is bound
	 * back into the input field and shown to the user. 
	 */
	onBlur(field: 'firstName' | 'lastName' | 'title' | 'email') {

		if (this.values[field].value !== this.values[field].pending) {

			this.values[field].value = this.values[field].pending;
			this.values[field].changed = true;
			this.checkIfCanSave();
		}
	}


	/**
	 * Look at each value and determine if the save/update button should be enabled
	 */
	checkIfCanSave() {

		const allValid =
			this.values.firstName.valid &&
			this.values.lastName.valid &&
			this.values.title.valid &&
			this.values.email.valid;

		// Determine if any field has changed or has a pending change
		const somethingChanged =
			this.values.firstName.changed ||
			this.values.firstName.value !== this.values.firstName.pending ||
			this.values.lastName.changed ||
			this.values.lastName.value !== this.values.lastName.pending ||
			this.values.title.changed ||
			this.values.title.value !== this.values.title.pending ||
			this.values.email.changed ||
			this.values.email.value !== this.values.email.pending;


		this.addAction.enabled = this.updateAction.enabled = allValid && somethingChanged;

	}


	actionClick(action: DialogAction) {

		if (action.id == 'close') return;

		const value: ApplicationParticipantPendingEditableFields = {
			firstName: this.values.firstName.pending,
			lastName: this.values.lastName.pending,
			title: this.values.title.pending,
			email: this.values.email.pending,
		};

		this.dialogService.activity.emit({
			instanceIdentifier: this.dialog.instanceIdentifier,
			dialogClass: ApplicationParticipantDialog.name,
			activity: 'Action',
			action,
			value,
		});
	}


	getPlaceholder(field: 'firstName' | 'lastName' | 'title' | 'email') {

		const max = this.values[field].max;
		const c = this.inlineLabels['Up to XXXX characters'].split('XXXX');
		return `${c[0]} ${max} ${c[1]}`;

	}
}