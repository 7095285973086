import { AccAreaService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AccStageId, ApplicationStatusId } from '@me-interfaces';
import { Icon, IconContext, getIconClass } from '@me-shared-parts/UI-common';


export interface StatusIcon {
	icon: IconContext;
	link?: string;
}


@Component({
	selector: 'acc-accept-checks-part',
	templateUrl: './acc-accept-checks.part.html',
})
export class AccAcceptChecksPart extends DestroyablePart implements OnInit {

	accStageId = AccStageId.Accepting;

	applicationsStatusIcon: StatusIcon;


	constructor(public accAreaService: AccAreaService) {
		super();
	}


	ngOnInit(): void {
		super.initDestroyable();

		super.subscribe([this.accAreaService.accelerator.acc$, this.accAreaService.applications.applications$], async ([acc, apps]) => {
			this.setApplicationsStatusIcon(apps?.filter(a => a.application.applicationStatusId >= ApplicationStatusId.AcceptSubmitted).length);
		});
	}


	setApplicationsStatusIcon(submittedAppCount: number) {
		const icon: Icon = submittedAppCount > 14 ? Icon.status_success : Icon.status_x;
		const link = '';

		this.applicationsStatusIcon = {
			icon: {
				icon,
				iconClass: getIconClass(icon),
				text: `${submittedAppCount} Application${submittedAppCount != 1 ? 's' : ''} are Submitted or Beyond`,
			},
			link: submittedAppCount > 14 ? undefined : link,
		};
	}

}
