import { ApplicationAccess, ApplicationStatusId, FieldTool, LanguageId, ProgramTypeId } from "@me-interfaces";

/**
 * Basic information about an application.
 * This interface is FRONT FACING so do not put anything sensitive here.
 */
export interface ApplicationOverview {

	applicationId: number,
	applicationStatusId: ApplicationStatusId,
	/** The personId of the applicant (the person that created the application) */
	personId: number,
	applicantFullName: string,
	siteId: number,
	siteName: string,
	languageId: LanguageId,
	programId: number,
	programTypeId: ProgramTypeId,
	programInstanceName: string,
	/** Full name of the program manager */
	programManager: string,
	/** Email address of the program manager */
	pmEmail: string,
	/** Full name of the alt program manager */
	altProgramManager: string,
	/** Email address of the alt program manager */
	altPmEmail: string,
	/** Email address of the site director */
	edEmail: string,
	/** Email address of the alt site director */
	altEdEmail: string,

	acceptingApplications: boolean,
	accId?: number,
	picId?: number,

	tool: FieldTool,

	/** Details about what the current user can see and do with this application */
	access?: ApplicationAccess,
}
