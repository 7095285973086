<div class="me-grid-part" #meGridPart>

	<div class="me-grid-buttons" *ngIf="grid.groupable">
		<div [class.page-grid-buttons-content]="removePageMargins">
			<div>

				<button class="btn" [class.btn-green]='!rows.length' *ngIf='setup.canAdd' (click)='add()'
					title='{{ text.add }} {{ setup.rowSingularName }}'><i class='far fa-plus'></i>
					{{ text.add }}</button>

				<button class="btn" (click)='changeFilter()' title='{{ text.filter }}'><i
						[class]='filterClass$ | async'></i></button>

				<button class="btn" *ngIf='setup.canDownload && rows.length' (click)='download()'
					title='{{ text.download }}'><i class='far fa-download'></i></button>

				<button class="btn" *ngIf='setup.canRefresh' (click)='refresh()' title='{{ text.refresh }}'><i
						class='far fa-sync-alt' [class.fa-spin]='ps.value$ | async'></i></button>

				<kendo-dropdownbutton [data]="experience.actions" buttonClass='btn btn-green'
					[iconClass]='experience.iconClass' (open)='onActionMenuOpen()'
					(itemClick)='onActionItemClick($event)' [disabled]='!hasActions' title='{{ text.actions }}'>
					<ng-template kendoDropDownButtonItemTemplate let-dataItem>
						<div class='me-grid-action' [class.me-grid-action-not-enabled]='!dataItem.enabled'
							[class.me-grid-menu-separated]='dataItem.separated'>
							<i class="{{ getIconClass(dataItem.icon) }} fa-fw"></i>
							<span style='padding: 0 0.5em'>{{ (labelsService.getLabel$ | async)(dataItem.label)
								}}</span>
						</div>
					</ng-template>
				</kendo-dropdownbutton>

			</div>
			<div (click)="countsClicked($event)"><small>
					{{counts}}
					<ng-container *ngIf='rows && rows.length === 1'>{{ setup.rowSingularName }}</ng-container>
					<ng-container *ngIf='rows && rows.length !== 1'>{{ setup.rowPluralName }}</ng-container>
				</small></div>
		</div>
	</div>

	<kendo-grid #theGrid class='me-grid' [class.page-grid-content]="removePageMargins" [style.border]="'none'"
		[columnMenu]='true' [filterable]="'menu'" [navigable]='true' [pageable]='true' [resizable]='true'
		[scrollable]="'virtual'" [groupable]="grid.groupable" [reorderable]='true'
		[selectable]='grid.selectableSettings' [sortable]="true" [filter]='grid.state.filter' [group]='grid.state.group'
		[pageSize]='grid.state.take' [skip]='grid.state.skip' [sort]='grid.state.sort'
		[kendoGridSelectBy]="setup.rowKey" [kendoGridGroupBinding]="rows$ | async" (cellClick)="grid.cellClick($event)"
		(dataStateChange)='grid.dataStateChange($event)' (pageChange)="grid.pageChange($event)"
		(selectionChange)="grid.selectionChange($event)" [(selectedKeys)]="grid.selectedRowIds" [height]='gridHeight'
		[rowHeight]='24' [style.width]='gridWidth' [style.margin-top]="gridMarginTop"
		[style.margin-right]="gridMarginRight" [style.margin-bottom]="gridMarginBottom"
		[style.margin-left]="gridMarginLeft" [loading]='loading$ | async'>

		<kendo-grid-checkbox-column *ngIf="showCheckboxes" [showSelectAll]="true" [columnMenu]="false" [width]="30">
		</kendo-grid-checkbox-column>

		<kendo-grid-column *ngFor="let col of experience.columns" [field]="col.field" [title]="getHeader(col)"
			[width]="col.width" [filterable]="!!col.filterType" [filter]="col.filter || 'text'" [hidden]='col.hidden'
			[columnMenu]="!col.noColumnMenu" [sortable]="col.type !== 'time' && col.type !== 'timeUtc'">

			<ng-template kendoGridCellTemplate let-row>

				<me-grid-cell [column]='col' [row]='row' [ngSwitch]='col.type'>

					<ng-container *ngSwitchCase="'gauge'">
						<div class="me-grid-me-icon">
							<i *ngIf="row[col.field] < col.gaugeThresholds[0]"
								class="far fa-gauge-simple-min red-icon"></i>
							<i *ngIf="col.gaugeThresholds[0] <= row[col.field] && row[col.field] < col.gaugeThresholds[1]"
								class="far fa-gauge-simple-low light-red-icon"></i>
							<i *ngIf="col.gaugeThresholds[1] <= row[col.field] && row[col.field] < col.gaugeThresholds[2]"
								class="far fa-gauge-simple yellow-icon"></i>
							<i *ngIf="col.gaugeThresholds[2] <= row[col.field] && row[col.field] < col.gaugeThresholds[3]"
								class="far fa-gauge-simple-high light-green-icon"></i>
							<i *ngIf="row[col.field] >= col.gaugeThresholds[3]"
								class="far fa-gauge-simple-max green-icon"></i>
						</div>
					</ng-container>

					<ng-container *ngSwitchCase="'progressBar'">
						<div class="progress-colored-bar" *ngIf="row[col.field] !== null">
							<progress-color-bar [percent]='row[col.field]' [thresholds]='col.progressBarThresholds'
								height="8"></progress-color-bar>
						</div>
					</ng-container>

					<ng-container *ngSwitchCase="'meIcon'">
						<div class="me-grid-me-icon">
							<me-icon [icon]='row[col.field]'></me-icon>
						</div>
					</ng-container>

					<ng-container *ngSwitchCase="'iconContext'">
						<div class="me-grid-me-icon">
							<me-icon [icon]='row[col.field]'></me-icon>
						</div>
					</ng-container>

					<ng-container *ngSwitchCase="'redFlag'">
						<div class="me-grid-me-icon">
							<me-icon *ngIf="row[col.field] === true" [icon]="redFlagIcon"></me-icon>
							<me-icon *ngIf="row[col.field] === false" [icon]="noRedFlagIcon" style="opacity: 0.4">
							</me-icon>
						</div>
					</ng-container>

					<ng-container *ngSwitchCase="'entityNotes'">
						<div class="me-grid-me-icon">
							<me-icon *ngIf="row[col.field] === null" [icon]="redFlagIcon"></me-icon>
							<me-icon *ngIf="row[col.field] === 0" [icon]="noNotesIcon" style="opacity: 0.4"></me-icon>
							<me-icon *ngIf="row[col.field] > 0" [icon]="someNotesIcon" style="opacity: 0.8"></me-icon>
						</div>
					</ng-container>

					<ng-container *ngSwitchCase="'notes'">
						<div class="me-grid-me-icon">
							<me-icon *ngIf="!row[col.field]" [icon]="noNotesIcon" style="opacity: 0.4"></me-icon>
							<me-icon *ngIf="!!row[col.field]" [icon]="someNotesIcon" style="opacity: 0.8"></me-icon>
						</div>
					</ng-container>

					<ng-container *ngSwitchCase="'rating'">
						<div class="me-grid-me-rating">
							<grid-cell-rating [rating]="row[col.field]"></grid-cell-rating>
						</div>
					</ng-container>

					<ng-container *ngSwitchCase="'ratings'">
						<div class="me-grid-me-ratings">
							<grid-cell-ratings [ratings]="renderCell(row, col)"></grid-cell-ratings>
						</div>
					</ng-container>

					<ng-container *ngSwitchDefault>{{ renderCell(row, col) }}</ng-container>

				</me-grid-cell>

			</ng-template>

			<ng-template kendoGridHeaderTemplate let-column>
				<div [title]="getHeaderTooltip(column)" kendoTooltip position="top"
					style="text-overflow: ellipsis; overflow: hidden; white-space: nowrap;">{{ column.title }}</div>
			</ng-template>

			<ng-template kendoGridGroupHeaderTemplate let-group="group" let-aggregates="aggregates">
				{{ getColumnName(group) }} <strong>{{ formatGroupValue(group) }}</strong> - {{ getGroupCount(group) }}
			</ng-template>

			<ng-template kendoGridFilterMenuTemplate let-filter let-column="column" let-filterService="filterService">

				<kendo-grid-string-filter-menu *ngIf="col.filterType==='text'" [column]="column" [filter]="filter"
					[filterService]="filterService">
				</kendo-grid-string-filter-menu>

				<kendo-grid-numeric-filter-menu *ngIf="col.filterType==='numeric'" [column]="column" [filter]="filter"
					[filterService]="filterService">
				</kendo-grid-numeric-filter-menu>

				<kendo-grid-date-filter-menu *ngIf="col.filterType==='date'" [column]="column" [filter]="filter"
					[filterService]="filterService">
				</kendo-grid-date-filter-menu>

				<kendo-grid-boolean-filter-menu *ngIf="col.filterType==='boolean'" [column]="column" [filter]="filter"
					[filterService]="filterService">
				</kendo-grid-boolean-filter-menu>

			</ng-template>

		</kendo-grid-column>

		<ng-template kendoGridNoRecordsTemplate>

			<ng-container *ngIf="grid.hasFilters">
				<div style='margin: 1em 0;'>
					<button class="btn" (click)='grid.clearFilters()'>Clear Filters</button>
				</div>
			</ng-container>

			<ng-container *ngIf="!grid.hasFilters">
				<div style='margin: 2em 0; font-style: italic;' *ngIf='!setup.canAdd'>
					0 {{setup.rowPluralName}}
				</div>
				<div style='margin: 1em 0;' *ngIf='setup.canAdd'>
					<button class="btn" (click)='add()'>{{ text.add }} {{ setup.rowSingularName }}</button>
				</div>
			</ng-container>

		</ng-template>

	</kendo-grid>

	<me-grid-menu [grid]="grid" [actions]="experience.actions" [experience]="experience">
	</me-grid-menu>
</div>