
import { DdQuestionsTypeRow } from './dd-questions-type-row';
import { DdQuestionsType } from './dd-questions-type';
import { DdObjects } from '../dd-objects';
import { OldDomainDataService } from '..';

/**
 * @deprecated Use the DataService instead
 */
export class DdQuestionsTypes extends DdObjects<DdQuestionsTypeRow, DdQuestionsType> {

	constructor(private dd: OldDomainDataService) {
		super('questionsTypeId');
	}

	async loadData(hash: string, data: DdQuestionsTypeRow[]) {
		const objects = data.map(d => new DdQuestionsType(this.dd, d));
		this.loadObjects(hash, objects);
	}
}