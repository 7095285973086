import { Injectable } from '@angular/core';
import { DbcPublicCompany } from '@me-interfaces';
import { DialogAction, DialogData, DialogService } from '@me-services/ui/dialog';
import { PublicCompanyDialog } from './SHR-CM_public-company-dialog.dialog';


@Injectable({ providedIn: 'root' })
/**
 * Provides utilities for launching company extract dialog
 */
export class ShowPublicCompanyDialogService {

	constructor(private dialogService: DialogService) {

	}


	/**
	 * Launches the extract dialog for assigned DbcPublicCompany
	 */
	public async show(company: DbcPublicCompany, showAddress: boolean): Promise<any | undefined> {

		const data: DialogData<any> = { data: { company, showAddress } };
		const action: DialogAction<any> = await this.dialogService.showCustom(PublicCompanyDialog, data, 600, 250);

		return undefined;
	}

}