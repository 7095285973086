import { AccAreaService, AccPreAcceleratorPageService, AccPreAcceleratorTabId as TabId, AccPreAcceleratorViewId as ViewId } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { DroplistItem, Icon, getIconContext } from '@me-shared-parts/UI-common';
import { map, takeUntil } from 'rxjs/operators';


@Component({
	selector: 'acc-pre-accelerator-views-part',
	templateUrl: './acc-pre-accelerator-views.part.html',
})
export class AccPreAcceleratorViewsPart extends DestroyablePart implements OnInit {

	readonly: boolean;

	viewDroplistItems: DroplistItem<never, ViewId>[] = [

		{ uniqueId: ViewId.ApplyingApplications, groupName: 'Accepting', iconContext: getIconContext(Icon.view_applications), customSort: '1-Appl-1' },
		{ uniqueId: ViewId.ApplyingDeferrals, groupName: 'Accepting', iconContext: getIconContext(Icon.view_applications, undefined, 'never', 'Deferred Applications'), customSort: '1-Appl-2' },

		{ uniqueId: ViewId.ReadingEvent, groupName: 'Reading', iconContext: getIconContext(Icon.view_events, undefined, undefined, 'Event'), customSort: '1-Appl-4' },
		{ uniqueId: ViewId.ReadingReaders, groupName: 'Reading', iconContext: getIconContext(Icon.view_readers), customSort: '1-Appl-5' },
		{ uniqueId: ViewId.ReadingApplications, groupName: 'Reading', iconContext: getIconContext(Icon.view_applications), customSort: '1-Appl-6' },
		{ uniqueId: ViewId.ReadingAssessments, groupName: 'Reading', iconContext: getIconContext(Icon.view_assessments), customSort: '1-Appl-7' },
		{ uniqueId: ViewId.ReadingEditor, groupName: 'Reading', iconContext: getIconContext(Icon.view_editor, undefined, undefined, 'Assessment Editor'), customSort: '1-Appl-8' },
		{ uniqueId: ViewId.ReadingFeedback, groupName: 'Reading', iconContext: getIconContext(Icon.view_feedback), customSort: '1-Appl-9' },

		{ uniqueId: ViewId.InterviewingEvents, groupName: 'Interviewing', iconContext: getIconContext(Icon.view_events), customSort: '1-Appl-10' },
		{ uniqueId: ViewId.InterviewingInterviewers, groupName: 'Interviewing', iconContext: getIconContext(Icon.view_interviewers), customSort: '1-Appl-11' },
		{ uniqueId: ViewId.InterviewingApplications, groupName: 'Interviewing', iconContext: getIconContext(Icon.view_applications), customSort: '1-Appl-12' },
		{ uniqueId: ViewId.InterviewingAssessments, groupName: 'Interviewing', iconContext: getIconContext(Icon.view_assessments), customSort: '1-Appl-13' },
		{ uniqueId: ViewId.InterviewingEditor, groupName: 'Interviewing', iconContext: getIconContext(Icon.view_editor, undefined, undefined, 'Assessment Editor'), customSort: '1-Appl-14' },
		{ uniqueId: ViewId.InterviewingFeedback, groupName: 'Interviewing', iconContext: getIconContext(Icon.view_feedback), customSort: '1-Appl-15' },

		{ uniqueId: ViewId.SelectingApplications, groupName: 'Selecting', iconContext: getIconContext(Icon.view_applications), customSort: '1-Appl-16' },
		{ uniqueId: ViewId.SelectingTeams, groupName: 'Selecting', iconContext: getIconContext(Icon.view_teams), customSort: '1-Appl-17' },

		{ uniqueId: ViewId.MMEvents, groupName: 'Mentor Matching', iconContext: getIconContext(Icon.view_events), customSort: '2-Appl-1', },
		{ uniqueId: ViewId.MMMentors, groupName: 'Mentor Matching', iconContext: getIconContext(Icon.view_mentors, undefined, undefined, 'Matchable Mentors'), customSort: '2-Appl-2', },
		{ uniqueId: ViewId.MMTeams, groupName: 'Mentor Matching', iconContext: getIconContext(Icon.view_teams), customSort: '2-Appl-3', },
		{ uniqueId: ViewId.MMAssessments, groupName: 'Mentor Matching', iconContext: getIconContext(Icon.view_assessments), customSort: '2-Appl-4', },
		{ uniqueId: ViewId.MMEditor, groupName: 'Mentor Matching', iconContext: getIconContext(Icon.view_editor, undefined, undefined, 'Assessment Editor'), customSort: '2-Appl-5' },
		{ uniqueId: ViewId.MMScheduleMap, groupName: 'Mentor Matching', iconContext: getIconContext(Icon.view_mm_heatmap), customSort: '2-Appl-6', },
		{ uniqueId: ViewId.MMScheduleMapEditor, groupName: 'Mentor Matching', iconContext: getIconContext(Icon.view_editor, undefined, undefined, 'Schedule Editor'), customSort: '2-Appl-7' },
		{ uniqueId: ViewId.MMMatchingHints, groupName: 'Mentor Matching', iconContext: getIconContext(Icon.view_hints, undefined, undefined, 'Matching Hints'), customSort: '2-Appl-8' },
		{ uniqueId: ViewId.MMMatchingTool, groupName: 'Mentor Matching', iconContext: getIconContext(Icon.view_mm_matcher), customSort: '2-Appl-9' },
	];


	selectedViewItem$ = this.pageService.selectedView$.pipe(
		map(viewId => this.viewDroplistItems.find(item => item.uniqueId == viewId)),
	);

	height: number;


	constructor(
		private pageService: AccPreAcceleratorPageService,
		private accAreaService: AccAreaService,
	) {
		super();
	}


	async ngOnInit() {
		super.initDestroyable();
		super.subscribe([this.accAreaService.access$], async ([access]) => {
			this.readonly = access?.root != 'Write';
		});

		this.pageService.initializeUrl();
		this.pageService.layout$
			.pipe(takeUntil(this.destroyed$))
			.subscribe(layout => {
				this.height = layout.tabContentHeight - layout.tab1ViewDroplistHeight;
			});
	}


	onViewDroplistChange(selectedView: DroplistItem) {
		let tabId: TabId = TabId.Apply;

		if (selectedView.groupName == 'Applying') tabId = TabId.Apply;
		else if (selectedView.groupName == 'Reading') tabId = TabId.Read;
		else if (selectedView.groupName == 'Interviewing') tabId = TabId.Interview;
		else if (selectedView.groupName == 'Selecting') tabId = TabId.Select;
		else if (selectedView.groupName == 'Mentor Matching') tabId = TabId.Match;

		this.pageService.selectTabAndView(tabId, selectedView.uniqueId, true);
	}


	showView(view: string, viewItem: DroplistItem<never, ViewId>) {

		if (!viewItem?.uniqueId) return false;
		const viewId = viewItem.uniqueId;

		if (viewId == ViewId.ApplyingApplications && view == 'ApplyApplications') return true;
		if (viewId == ViewId.ApplyingDeferrals && view == 'ApplyDeferrals') return true;

		if (viewId == ViewId.ReadingEvent && view == 'ReadingEvent') return true;
		if (viewId == ViewId.ReadingReaders && view == 'ReadingReaders') return true;
		if (viewId == ViewId.ReadingApplications && view == 'ReadingApplications') return true;
		if (viewId == ViewId.ReadingAssessments && view == 'ReadingAssessments') return true;
		if (viewId == ViewId.ReadingEditor && view == 'ReadingEditor') return true;
		if (viewId == ViewId.ReadingFeedback && view == 'ReadingFeedback') return true;

		if (viewId == ViewId.InterviewingEvents && view == 'InterviewingEvents') return true;
		if (viewId == ViewId.InterviewingInterviewers && view == 'InterviewingInterviewers') return true;
		if (viewId == ViewId.InterviewingApplications && view == 'InterviewingApplications') return true;
		if (viewId == ViewId.InterviewingAssessments && view == 'InterviewingAssessments') return true;
		if (viewId == ViewId.InterviewingEditor && view == 'InterviewingEditor') return true;
		if (viewId == ViewId.InterviewingFeedback && view == 'InterviewingFeedback') return true;

		if (viewId == ViewId.SelectingApplications && view == 'SelectingApplications') return true;
		if (viewId == ViewId.SelectingTeams && view == 'SelectingTeams') return true;

		if (viewId == ViewId.MMEvents && view == 'MMEvents') return true;
		if (viewId == ViewId.MMMentors && view == 'MMMentors') return true;
		if (viewId == ViewId.MMTeams && view == 'MMTeams') return true;
		if (viewId == ViewId.MMAssessments && view == 'MMAssessments') return true;
		if (viewId == ViewId.MMEditor && view == 'MMEditor') return true;
		if (viewId == ViewId.MMScheduleMap && view == 'MMScheduleMap') return true;
		if (viewId == ViewId.MMScheduleMapEditor && view == 'MMScheduleMapEditor') return true;
		if (viewId == ViewId.MMMatchingHints && view == 'MMMatchingHints') return true;
		if (viewId == ViewId.MMMatchingTool && view == 'MMMatchingTool') return true;

		return false;
	}


	/**
	 * Defines whether the div for the tab1 content should automatically add a verical scrollbar as needed.
	 * This should be set to 'initial' for any views that show grids because the grids add a negative
	 * margin which would force an unwanted scrollbar.
	 */
	getOverflowY(viewItem: DroplistItem<never, ViewId>): 'initial' | 'auto' {

		if (!viewItem?.uniqueId) return 'auto';
		const viewId = viewItem.uniqueId;

		if (viewId == ViewId.ReadingAssessments) return 'auto';
		if (viewId == ViewId.ReadingEditor) return 'auto';
		if (viewId == ViewId.ReadingFeedback) return 'auto';
		if (viewId == ViewId.InterviewingAssessments) return 'auto';
		if (viewId == ViewId.InterviewingEditor) return 'auto';
		if (viewId == ViewId.InterviewingFeedback) return 'auto';
		if (viewId == ViewId.MMAssessments) return 'auto';
		if (viewId == ViewId.MMEditor) return 'auto';
		if (viewId == ViewId.MMScheduleMap) return 'auto';
		if (viewId == ViewId.MMScheduleMapEditor) return 'auto';
		if (viewId == ViewId.MMMatchingTool) return 'auto';

		//
		// The remaining views have grids so we return 'initial'
		//
		if (viewId == ViewId.ApplyingApplications) return 'initial';
		if (viewId == ViewId.ApplyingDeferrals) return 'initial';
		if (viewId == ViewId.ReadingEvent) return 'initial';
		if (viewId == ViewId.ReadingReaders) return 'initial';
		if (viewId == ViewId.ReadingApplications) return 'initial';
		if (viewId == ViewId.InterviewingEvents) return 'initial';
		if (viewId == ViewId.InterviewingInterviewers) return 'initial';
		if (viewId == ViewId.InterviewingApplications) return 'initial';
		if (viewId == ViewId.SelectingApplications) return 'initial';
		if (viewId == ViewId.SelectingTeams) return 'initial';
		if (viewId == ViewId.MMEvents) return 'initial';
		if (viewId == ViewId.MMMentors) return 'initial';
		if (viewId == ViewId.MMTeams) return 'initial';
		if (viewId == ViewId.MMMatchingHints) return 'initial';

		return 'auto';
	}
}