import { Accelerator } from "@me-interfaces";
import { DataService } from "@me-services/core/data";


/**
 * Get all accelerators for a set of siteProgramIds
 */
export async function getAccs(ds: DataService, siteProgramIds: number[]): Promise<{ accs: ReadonlyArray<Accelerator>, accIds: Readonly<number[]> }> {

	if (siteProgramIds.length == 0) return { accs: [], accIds: [] };

	const accs = (await ds.admin.accelerator.getAllPackagesAsArray()).filter(a => siteProgramIds.includes(a.siteProgramId));
	const accIds = accs.map(a => a.accId);

	return { accs, accIds };
}