import { ImmutableArray } from "@me-interfaces";
import { Observable, Subject } from "rxjs";
import { DdObject } from './dd-object';

/**
 * @deprecated Use the DataService instead
 */
export abstract class DdObjects<DATA_TYPE, OBJECT_TYPE extends DdObject<DATA_TYPE>> {

	protected _hash = '';
	protected _objects: OBJECT_TYPE[] = [];
	protected _map: { [index: number]: OBJECT_TYPE } = {};

	private _loaded = new Subject<string>();
	public readonly loaded$: Observable<string> = this._loaded;


	constructor(private idField: string) {
	}


	public abstract loadData(hash: string, data: DATA_TYPE[]): Promise<void>;


	protected loadObjects(hash: string, objects: OBJECT_TYPE[]): void {

		if (this._hash == hash) return;

		const map: { [index: number]: OBJECT_TYPE } = objects.reduce((a, d) => {
			a[d[this.idField]] = d;
			return a;
		}, {});

		this._hash = hash;
		this._objects = objects;
		this._map = map;

		if (hash) this._loaded.next(hash);
	}


	public getById(id: number): OBJECT_TYPE {
		return this._map[id];
	}


	public getByIds(ids: ImmutableArray<number>): OBJECT_TYPE[] {
		return ids.map(id => this._map[id]);
	}


	public get objects(): OBJECT_TYPE[] {
		return [...this._objects];
	}


	public get data(): DATA_TYPE[] {
		return [...this._objects.map(o => o.data)];
	}

}