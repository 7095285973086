import { PendingSearchMatch, SearchableData, SearchMatchLevel, SearchRegExps, TextToMatch } from "../interfaces";
import { checkTexts } from "./check-texts";


export async function findApplicationMatches(
	data: SearchableData,
	re: SearchRegExps,
): Promise<PendingSearchMatch[]> {


	const matches: PendingSearchMatch[] = [];


	for (const application of data.applications) {

		const texts: TextToMatch[] = [
			{ text: application.name, field: 'Name' },
			{ text: application.companyName, field: 'Idea Name' },
			{ text: application.company.longName, field: 'Company Name' },
			{ text: application.company.shortName, field: 'Company Short Name' },
		];

		const match = checkTexts(application, texts, re);

		if (match.level > SearchMatchLevel.None) matches.push(match);

	}

	return matches;
}