
<me-dialog-frame  header="Survey Comments" alertType='editor' [dialogRef]='dialogRef' [actions]="actions">
	<div>{{label}}</div>
	<table style="width: 100%; margin: 0px;" class='pro-table'>
		<tr>
			<th>Entrepreneur</th>
			<th>Comment</th>
		</tr>
		<tr *ngFor="let comment of comments">
			<td> {{comment.entrepreneurName}}</td>
			<td> {{comment.comment}}</td>
		</tr>
	</table>

</me-dialog-frame>
