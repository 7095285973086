import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { getAccAlumni } from "./get-acc-alumni";
import { getAccAwards } from "./get-acc-awards";
import { getAccs } from "./get-accs";
import { getPicAwards } from "./get-pic-awards";
import { getPicParticipants } from "./get-pic-participants";
import { getPics } from "./get-pics";

export interface SiteCounts {
	siteId: number,
	regionId: number,
	staff: number,
	accs: number,
	accAlumni: number,
	pics: number,
	picParticipants: number,
	accAwardees: number,
	accAwards: number,
	picAwardees: number,
	picAwards: number,
}

/**
 * For each siteId, map an object with counts suitable for displaying in grid columns 
 */
export async function getSiteCounts(util: UtilityService, ds: DataService): Promise<Record<number, SiteCounts>> {

	const sites = await ds.admin.site.getAllAsArray();
	const accTeams = await ds.admin.accTeam.getAllPackagesAsArray();
	const picTeams = await ds.admin.picTeam.getAllPackagesAsArray();
	const awards = await ds.admin.award.getAllAsArray();

	const { accsBySiteId } = await getAccs(util, ds);
	const { picsBySiteId } = await getPics(util, ds);
	const { alumniBySiteId } = await getAccAlumni(sites, accsBySiteId, accTeams);
	const { participantsBySiteId } = await getPicParticipants(sites, picsBySiteId, picTeams);

	const { accAwardeesBySiteId, accAwardsBySiteId } = await getAccAwards(util, sites, awards, accsBySiteId, accTeams);
	const { picAwardeesBySiteId, picAwardsBySiteId } = await getPicAwards(util, sites, awards, picsBySiteId, picTeams);

	const counts: Record<number, SiteCounts> = {};

	for (const site of sites) {

		const siteId = site.siteId;

		counts[siteId] = {
			siteId,
			regionId: site.regionId,
			staff: 0,
			accs: accsBySiteId[siteId]?.length ?? 0,
			pics: picsBySiteId[siteId]?.length ?? 0,
			accAlumni: alumniBySiteId[siteId] ?? 0,
			picParticipants: participantsBySiteId[siteId] ?? 0,
			accAwardees: accAwardeesBySiteId[siteId] ?? 0,
			accAwards: accAwardsBySiteId[siteId] ?? 0,
			picAwardees: picAwardeesBySiteId[siteId] ?? 0,
			picAwards: picAwardsBySiteId[siteId] ?? 0,
		};
	}

	console.log(counts);
	return counts;
}

