import { AdminAreaService, ProgramPageService } from '@ADMIN-area';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { GridSetup, PersonCols } from '@me-grid';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { GridPart } from '@me-shared-parts/UI-common';
import { GridColumnConfig, buildColumn } from '@me-shared-parts/UI-common/grid/columns';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';


interface AgreementSignatureRow {
	agreementSignatureId: number,
	agreementVersionId: number,
	agreementTypeId: number,
	agreement: string,
	agreementName: string,
	personId: number,
	updatedUTC: number,
}

@Component({
	selector: 'program-agreement-signatures-view-part',
	templateUrl: './agreement-signatures-view.part.html',
	styleUrls: ['./agreement-signatures-view.part.scss'],
})
export class ProgramAgreementSignaturesViewPart extends DestroyablePart implements OnInit {

	public gridSetup = this.setupGrid();

	public rows$: Observable<AgreementSignatureRow[]> = of([]);

	@ViewChild(GridPart) meGrid: GridPart<AgreementSignatureRow>;

	constructor(
		private util: UtilityService,
		public adminAreaService: AdminAreaService,
		public ds: DataService,
		public pageService: ProgramPageService
	) {
		super();
	}

	ngOnInit(): void {

		super.initDestroyable();

		this.rows$ = this.ds.admin.singletonsAsOfUTC$.pipe(mergeMap(data => this.buildRows(data)));
	}


	async buildRows(
		singletonsAsOfUTC: number
	): Promise<AgreementSignatureRow[]> {

		const agreementSignatures = await this.ds.admin.agreementSignature.getAllAsArray();
		const agreementVersionMap = await this.ds.admin.agreementVersion.getAllAsMap();
		const agreementTypesMap = await this.ds.admin.agreementType.getAllAsMap();


		const rows: AgreementSignatureRow[] = agreementSignatures.map(signature => {

			const agreementVersion = agreementVersionMap[signature.agreementVersionId];
			const agreementTypeId = agreementVersion.agreementTypeId;
			const agreementType = agreementTypesMap[agreementTypeId];

			return {
				agreementSignatureId: signature.agreementSignatureId,
				agreementVersionId: signature.agreementVersionId,
				agreementTypeId: agreementTypeId,
				agreement: `${agreementType.nameEN} v${agreementVersion.version}`,
				agreementName: agreementType.nameEN,
				personId: signature.personId,
				updatedUTC: signature.updatedUTC,
			}
		});


		return rows;
	}



	private setupGrid(): GridSetup<AgreementSignatureRow> {

		return {
			experience: 'PERSON',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Signee",
			rowPluralName: "Signees",
			rowKey: "agreementSignatureId",
			stateKey: "program-agreement-signatures",
			canAdd: false,
			canRefresh: false,
			canDownload: false,
			columnsToAdd: [
				buildColumn('agreementSignatureId', GridColumnConfig.id, { hidden: true, header: 'agreementSignatureId' }),
				buildColumn('agreementVersionId', GridColumnConfig.id, { hidden: true, header: 'agreementVersionId' }),
				buildColumn('agreementTypeId', GridColumnConfig.id, { hidden: true, header: 'agreementTypeId' }),
				buildColumn('agreementName', GridColumnConfig.text, { header: 'Agreement Name', width: 200, hidden: true }),
				buildColumn('agreement', GridColumnConfig.text, { header: 'Agreement', width: 180 }),
				buildColumn('updatedUTC', GridColumnConfig.updatedUtc, { header: 'Date Signed', hidden: true }),
			],
			columnsToAlter: [
				{ field: PersonCols.name, header: 'Name of Signee' },
				{ field: PersonCols.notes, header: 'Person Notes' },
			]
		};
	}

}