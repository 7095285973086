import { PicAreaRawData } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { PicAreaData } from "./pic-area-data";

//
// transform the raw data returned from the action function to
// data that can be used by the Angular app.
//
export async function mapToData(rawData: PicAreaRawData, util: UtilityService, ds: DataService): Promise<PicAreaData> {

	return {
		pic: rawData.pitch,

		contestJudgementCountsByPicTeamId: rawData.contestJudgementCounts.reduce((map, counts) => {
			map[counts.picTeamId] = counts;
			return map;
		}, {}),
	};
}