import { PendingSearchMatch, SearchableData, SearchMatchLevel, SearchRegExps, TextToMatch } from "../interfaces";
import { checkTexts } from "./check-texts";


export async function findAccTeamMatches(
	data: SearchableData,
	re: SearchRegExps,
): Promise<PendingSearchMatch[]> {


	const matches: PendingSearchMatch[] = [];


	for (const accTeam of data.accTeams) {

		const texts: TextToMatch[] = [
			{ text: accTeam.name, field: 'Name' },
			{ text: accTeam.application.company.longName, field: 'Company Name', fullMatchIsExact: true },
			{ text: accTeam.application.company.shortName, field: 'Company Short Name', fullMatchIsExact: true },
		];

		const match = checkTexts(accTeam, texts, re);

		if (match.level > SearchMatchLevel.None) matches.push(match);
	}

	return matches;
}