<div class="note-dialog">

	<me-dialog-frame header='Notes' [actions]='actions' [dialogRef]='dialogRef' icon='fa-sticky-note' alertType='editor'>

		<div class="me-field">
			<div class="note-dialog-content">
				<textarea kendoTextBox [value]='notes.value' (input)='onInput($event)' (blur)='onBlur()'
					placeholder='Enter notes here' [style.width.%]='100' [disabled]='readonly'>
				</textarea>
			</div>
		</div>

	</me-dialog-frame>
</div>