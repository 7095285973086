<me-dialog-frame [header]='headerText' [showToolbar]='false' [dialogRef]='dialogRef' [icon]='icon' alertType='editor'
	[actions]="actions" (actionClick)="onActionClick($event)" (closed)="onClosed()">


	<div>
		Scenarios found: {{ scenariosFound$ | async }}
	</div>

	<div *ngIf="bestScenario$ | async; let scenario">

		<ng-container *ngIf="scenario">

			<h3>Best Scenario <small>(#{{scenario.id}})</small></h3>

			<div>Lowest Team Score: {{scenario.lowestTeamScore}} - Scenario Score: {{scenario.scenarioScore}} - Most Uses of a Meeting: {{scenario.mostUsesOfAnyOneMeetingTime}}</div>
			<div>Venture Gave: {{ scenario.t2m | json }} - Mentors Game: {{ scenario.m2t | json }}</div>

			<table class='pro-table pro-table-without-border pro-table-with-thin-rows' style="width:100%">
				<tr>
					<th>Meeting</th>
					<th>Venture</th>
					<th>Venture Gave</th>
					<th>Mentors</th>
					<th>Mentors Gave</th>
				</tr>
				<tr *ngFor="let t of scenario.teams">
					<td>{{ t.meeting }}</td>
					<td>{{ t.team.name }}</td>
					<td>{{ t.t2m | json }}</td>
					<td>{{ getMentorNames(t.mentors) }}</td>
					<td>{{ t.m2t | json }}</td>

				</tr>
			</table>

		</ng-container>

	</div>
	
</me-dialog-frame>