
import { DdTagRow } from './dd-tag-row';
import { DdTag } from './dd-tag';
import { DdObjects } from '../dd-objects';
import { OldDomainDataService } from '..';

/**
 * @deprecated Use the DataService instead
 */
export class DdTags extends DdObjects<DdTagRow, DdTag> {

	constructor(private dd: OldDomainDataService) {
		super('tagId');
	}

	async loadData(hash: string, data: DdTagRow[]) {
		const objects = data.map(d => new DdTag(this.dd, d));
		this.loadObjects(hash, objects);
	}

	public getByPrefix(prefix: string) {
		return this._objects.filter(tagPrefix => tagPrefix.prefix == prefix);
	}

	public getByTagName(tagName: string) {
		return this._objects.filter(tag => tag.name == tagName);
	}
}