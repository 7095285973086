import { environment } from '@me-environment';
import { SeverityLevel } from '@sentry/angular-ivy';
import * as Sentry from "@sentry/browser";
import { ElapsedTimer } from './elapsed-timer';


const TECH_LOGGING_KEY = 'TechLogging';



/**
 * Log information to the console and to Sentry as a breadcrumb.
 * Use techMessage for messages that should be hidden from general users.
 */
const info = (msg: string, extraData?: { extra: unknown, extraLogging: 'ExtraToConsole' | 'ExtraToConsoleAndSentry' }, category = 'info') => {

	console.info(msg, extraData?.extra);

	const breadcrumb = {
		type: 'info',
		message: msg,
		level: 'info' as SeverityLevel,
		category,
		data: getBreadcrumbData(new Error(msg), true, extraData?.extra, extraData?.extraLogging),
	};
	if (!breadcrumb.data) delete breadcrumb.data;

	if (environment.name !== 'LOCALHOST') Sentry.addBreadcrumb(breadcrumb);
}


/**
 * Log a warning to the console and to Sentry as a breadcrumb.
 * Use techMessage for messages that should be hidden from general users.
 */
const warning = (msg: string, extraData?: { extra: unknown, extraLogging: 'ExtraToConsole' | 'ExtraToConsoleAndSentry' }, category = 'warning') => {

	console.warn(msg, extraData?.extra);

	const breadcrumb = {
		type: 'info',
		message: msg,
		level: 'warning' as SeverityLevel,
		category,
		data: getBreadcrumbData(new Error(msg), true, extraData?.extra, extraData?.extraLogging),
	};
	if (!breadcrumb.data) delete breadcrumb.data;

	if (environment.name !== 'LOCALHOST') Sentry.addBreadcrumb(breadcrumb);

}

/**
 * Tech messages are only displayed in the browser console if enableTechMessages() had been called prior.
 * Once tech messages are enabled, they remain enabled indefinitely (remembered in localStorage). 
 * Upon signing in, the UserAreaService automatically calls enableTechMessages() if the user is a Tech Admin.
 * 
 * The message value is always logged to Sentry.
 */
const techMessage = (msg: string, extraData?: { extra: unknown, extraLogging: 'ExtraToConsole' | 'ExtraToConsoleAndSentry' }, category: 'debug' | 'func' = 'debug') => {

	const techLogging = localStorage.getItem(TECH_LOGGING_KEY) == 'true';

	if (techLogging) console.debug(msg, extraData?.extra);

	const breadcrumb = {
		type: 'debug',
		message: msg,
		level: 'info' as SeverityLevel,
		category,
		data: getBreadcrumbData(new Error(msg), category !== 'debug', extraData?.extra, extraData?.extraLogging),
	};
	if (!breadcrumb.data) delete breadcrumb.data;

	if (environment.name !== 'LOCALHOST') Sentry.addBreadcrumb(breadcrumb);

}


/**
 * Tech messages are only displayed in the browser console if enableTechMessages() had been called prior.
 * Once tech messages are enabled, they remain enabled indefinitely (remembered in localStorage). 
 * Upon signing in, the UserAreaService automatically calls enableTechMessages() if the user is a Tech Admin.
 */
const enableTechMessages = () => {
	localStorage.setItem(TECH_LOGGING_KEY, 'true');
}


function buildErrorLogger(level: 'ERROR' | 'FATAL') {

	return (msg: string, err: Error, extra?: unknown) => {

		console.error(msg, err, extra);

		if (environment.name !== 'LOCALHOST') {

			Sentry.addBreadcrumb({
				type: 'error',
				message: msg,
				level: level == 'ERROR' ? 'error' : 'fatal',
				category: 'error',
				data: {
					data: extra,
					stack: getStack(err),
				},
			});

			Sentry.setTag("e4a_error", "yes");
			if (level == 'FATAL') Sentry.setTag("e4a_error_fatal", "yes");

			Sentry.captureException(err);
		}
	}
}

const errorLogger = buildErrorLogger('ERROR');

export const utilLogging = {
	enableTechMessages,
	error: errorLogger,
	errorMessage: (errMsg: string, extra?: unknown) => { errorLogger(errMsg, new Error(errMsg), extra); },
	fatalError: buildErrorLogger('FATAL'),
	info,
	techMessage,
	warning,
	createElapsedTimer: (name: string, detail?: unknown) => { return new ElapsedTimer(name, detail); },
};


/**
 * Build data to be added to the breadcrumbs but only include the extra data if explicitly requested.
 */
function getBreadcrumbData(err: Error, includeStackTrace: boolean, extra: unknown, extraLogging: 'ExtraToConsole' | 'ExtraToConsoleAndSentry') {

	const includeExtra = extra && extraLogging == 'ExtraToConsoleAndSentry';

	if (includeExtra && includeStackTrace) {
		return { extra, stack: getStack(err) };
	}

	else if (includeExtra) {
		return { extra };
	}

	else if (includeStackTrace) {
		return { stack: getStack(err) };
	}

	return undefined;

}


/**
 * Get a stack trace to add to breadcrumbs.
 */
function getStack(e: Error): string[] {

	const f = (e.stack ?? '')
		.split('\n')
		.filter(frame => {
			//
			// Filter out known angular 12 stack frames
			//
			if (frame.includes('at __awaiter ')) return false;
			if (frame.includes('at drainMicroTaskQueue ')) return false;
			if (frame.includes('at BehaviorSubject.')) return false;
			if (frame.includes('at Generator.')) return false;
			if (frame.includes('at getNodeInjectable ')) return false;
			if (frame.includes('at getOrCreateInjectable ')) return false;
			if (frame.includes('at invokeTask ')) return false;
			if (frame.includes('at lookupTokenUsingModuleInjector ')) return false;
			if (frame.includes('at Module.ɵɵdirectiveInject ')) return false;
			if (frame.includes('at new ZoneAwarePromise ')) return false;
			if (frame.includes('at NgModuleRef.')) return false;
			if (frame.includes('at NodeInjectorFactory.')) return false;
			if (frame.includes('at Object.onInvoke')) return false;
			if (frame.includes('at OperatorSubscriber.')) return false;
			if (frame.includes('at R3Injector.')) return false;
			if (frame.includes('at SafeSubscriber.')) return false;
			if (frame.includes('at XMLHttpRequest.globalZoneAwareCallback ')) return false;
			if (frame.includes('at XMLHttpRequest.sentryWrapped ')) return false;
			if (frame.includes('at Zone.')) return false;
			if (frame.includes('at ZoneDelegate.')) return false;
			if (frame.includes('at ZoneTask.')) return false;
			if (frame.includes('.globalZoneAwareCallback ')) return false;
			return true;
		})
		.map(frame => {
			frame = frame.split('https://eforall.app').join('');
			frame = frame.split('https://eparatodos.app').join('');
			frame = frame.split('https://dev.eforall.app').join('');
			frame = frame.split('https://dev.eparatodos.app').join('');

			return frame;
		});

	f.shift();							// remove 'error'
	if (f.length > 20) f.length = 20;	// Twenty is enough
	return f;
}