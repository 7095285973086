import { DbsEvent } from '@me-interfaces';
import { DataService } from '@me-services/core/data';

export async function getEventContextRows(ds: DataService, event: DbsEvent): Promise<{ label: string, value: string, link: string, site?: string }[]> {

	if (!event.siteId) return [];

	const rows: { label: string, value: string, link: string, site?: string }[] = [];
	const site = await ds.admin.site.getOnePackage(event.siteId);
	const siteId = site.siteId;
	const siteCode = site.code.toLowerCase();


	//
	// Get DdAcc name if needed
	//
	if (event.accId) {

		const acc = await ds.admin.accelerator.getOnePackage(event.accId);

		rows.push({
			label: 'Accelerator',
			value: acc.name + ' Accelerator',
			link: `/access/admin/natiional/sites/${siteId}/accelerators/${event.accId}/overview?tab=1&view=3`,
			site: site.name,
		});
	}


	//
	// Get DdPic name if needed
	//
	if (event.picId) {

		const pic = await ds.admin.pitchContest.getOnePackage(event.picId);

		rows.push({
			label: 'Pitch Contest',
			value: pic.name,
			link: `/access/admin/communities/${siteCode}/programs/pitch-contests/${event.picId}/events`,
			site: site.name,
		});
	}


	return rows;
}