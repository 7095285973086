import { Accelerator, AccReader, DbConceptName, Person, PicReader, PitchContest } from "@me-interfaces";
import { DataService } from "../../../../data.service";
import { ExplorerBox, ExplorerBoxClass } from "../../../explorer-box/explorer-box";

interface ReaderData {
	applStartUTC: number,
	siteName: string,
	siteId: number,
	siteCode: string,
	readApplicationIds: number[],
	accelerator?: Accelerator,
	pitchContest?: PitchContest
}


export async function getBoxesForReading(ds: DataService, person: Person): Promise<ExplorerBox[]> {

	const boxes: ExplorerBox[] = [];

	const accReadersByPersonId = await ds.admin.accReader.getByPersonIds([person.personId])
	const accReaders = accReadersByPersonId[person.personId].filter(reader => reader.readApplicationIds.length > 0);

	const picReadersByPersonId = await ds.admin.picReader.getByPersonIds([person.personId]);
	const picReaders = picReadersByPersonId[person.personId].filter(reader => reader.readApplicationIds.length > 0);

	const readers: ReaderData[] = getReaderData([...accReaders, ...picReaders]);


	for (const reader of readers) {

		const box: ExplorerBox = {
			explorerBoxClass: ExplorerBoxClass.Reading,
			header: 'Reader',
			buttons: [
				{ explorer: { explorableId: { conceptName: DbConceptName.Site, id: reader.siteId }, navTooltip: `Site: ${reader.siteName}` } },
			],
			notes: `Applications Read: ${reader.readApplicationIds.length}`,
		};

		if (reader.accelerator) box.buttons.push(
			{ explorer: { nameOverride: `${reader.accelerator.name} Accelerator`, explorableId: { conceptName: DbConceptName.Accelerator, id: reader.accelerator.accId }, navTooltip: `Accelerator: ${reader.siteCode} ${reader.accelerator.name}` } },
		);

		if (reader.pitchContest) box.buttons.push(
			{ explorer: { nameOverride: `${reader.pitchContest.name} Pitch Contest`, explorableId: { conceptName: DbConceptName.PitchContest, id: reader.pitchContest.picId }, navTooltip: `Pitch Contest: ${reader.siteCode} ${reader.pitchContest.name}` } },
		);

		boxes.push(box);
	}

	return boxes;


	/**
	 * Get sorted desc array of all different program types readings
	 * @param readers 
	 * @returns 
	 */
	function getReaderData(readers: (AccReader | PicReader)[]) {
		const data: ReaderData[] = [];
		for (const reader of readers) {
			if (reader._concept == DbConceptName.AccReader) {
				const site = reader.accelerator.siteProgram.site;
				data.push({
					applStartUTC: reader.accelerator.applStartUTC,
					siteName: site.name,
					siteId: site.siteId,
					siteCode: site.code,
					readApplicationIds: [...reader.readApplicationIds],
					accelerator: reader.accelerator,
				});
			}
			else if (reader._concept == DbConceptName.PicReader) {
				const site = reader.pitchContest.siteProgram.site;
				data.push({
					applStartUTC: reader.pitchContest.applStartUTC,
					siteName: site.name,
					siteId: site.siteId,
					siteCode: site.code,
					readApplicationIds: [...reader.readApplicationIds],
					pitchContest: reader.pitchContest,
				});
			}


		}
		return data.sort((a, b) => b.applStartUTC - a.applStartUTC);
	}
}