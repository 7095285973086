import { Component, Input, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AppAreaAccess, AppAreaAccessValue, AppAreaIdentifier } from '@me-interfaces';
import { AppAreaService } from '@me-services/core/area';

@Component({
	selector: 'app-area-access',
	templateUrl: './app-area-access.part.html',
	styleUrls: ['app-area-access.part.scss'],
})
export class AppAreaAccessPart extends DestroyablePart implements OnInit {

	@Input() areaService: AppAreaService<AppAreaIdentifier<number | string>, AppAreaAccess, unknown, unknown>;
	@Input() public accessKey = 'root';
	@Input() public showReadonlyMessage = false;

	public loading = true;
	public access: AppAreaAccessValue;


	ngOnInit() {

		if (!this.areaService) throw new Error(`Missing required attribute: areaService`);
		if (!this.accessKey) throw new Error(`Missing required attribute: accessKey`);

		super.initDestroyable();

		super.subscribe([
			this.areaService.loading$,
			this.areaService.accessAndId$
		], async ([loading, accessAndId]) => {
			
			
			if (!accessAndId) return;
			
			const { access } = accessAndId;
			if (access) {
				if (this.accessKey == 'root') this.access = access.root;
				else this.access = access.sub[this.accessKey];
				
				this.loading = loading;
			}
		});

	}

}