<div class='acc-application-view-part' *ngIf="viewDroplistItems.length">

	<ng-container *ngIf='selectedViewItem$ | async; let selectedViewItem'>

		<me-droplist class="views-droplist" [items]='viewDroplistItems' [selectedItem]='selectedViewItem'
			(selectionChange)='onViewDroplistChange($event)' [filterable]='false' [navigation]='true'
			sortBy='customSort' [groupSortValues]='{"Accepting":1, "Reading":2, "Interviewing":3, "Selecting": 4, "Mentor Matching": 5}'
			style='display:inline-block'>
		</me-droplist>

		<div [style.overflow-y]="getOverflowY(selectedViewItem)" [style.height.px]='height'>

			<!-- Applying -->
			<acc-accepting-applications-view-part *ngIf="showView('ApplyApplications', selectedViewItem)">
			</acc-accepting-applications-view-part>

			<acc-accepting-deferrals-view-part *ngIf="showView('ApplyDeferrals', selectedViewItem)">
			</acc-accepting-deferrals-view-part>


			<!-- Reading -->
			<acc-reading-event-view-part *ngIf="showView('ReadingEvent', selectedViewItem)">
			</acc-reading-event-view-part>

			<acc-reading-readers-view-part *ngIf="showView('ReadingReaders', selectedViewItem)">
			</acc-reading-readers-view-part>

			<acc-reading-applications-view-part *ngIf="showView('ReadingApplications', selectedViewItem)">
			</acc-reading-applications-view-part>

			<acc-reading-assessments-view-part *ngIf="showView('ReadingAssessments', selectedViewItem)">
			</acc-reading-assessments-view-part>

			<acc-reading-editor-view-part *ngIf="showView('ReadingEditor', selectedViewItem)">
			</acc-reading-editor-view-part>

			<acc-reading-feedback-view-part *ngIf="showView('ReadingFeedback', selectedViewItem)">
			</acc-reading-feedback-view-part>


			<!-- Interviewing -->
			<acc-interviewing-events-view-part *ngIf="showView('InterviewingEvents', selectedViewItem)">
			</acc-interviewing-events-view-part>

			<acc-interviewing-interviewers-view-part *ngIf="showView('InterviewingInterviewers', selectedViewItem)">
			</acc-interviewing-interviewers-view-part>

			<acc-interviewing-applications-view-part *ngIf="showView('InterviewingApplications', selectedViewItem)">
			</acc-interviewing-applications-view-part>

			<acc-interviewing-assessments-view-part *ngIf="showView('InterviewingAssessments', selectedViewItem)">
			</acc-interviewing-assessments-view-part>


			<acc-interviewing-editor-view-part *ngIf="showView('InterviewingEditor', selectedViewItem)">
			</acc-interviewing-editor-view-part>


			<acc-interviewing-feedback-view-part *ngIf="showView('InterviewingFeedback', selectedViewItem)">
			</acc-interviewing-feedback-view-part>


			<!-- Selecting -->
			<acc-selecting-applications-view-part *ngIf="showView('SelectingApplications', selectedViewItem)">
			</acc-selecting-applications-view-part>

			<acc-selecting-teams-view-part *ngIf="showView('SelectingTeams', selectedViewItem)">
			</acc-selecting-teams-view-part>


			<!-- MM -->
			<acc-mm-events-view-part *ngIf="showView('MMEvents', selectedViewItem)">
			</acc-mm-events-view-part>


			<acc-mm-mentors-view-part *ngIf="showView('MMMentors', selectedViewItem)">
			</acc-mm-mentors-view-part>


			<acc-mm-teams-view-part *ngIf="showView('MMTeams', selectedViewItem)">
			</acc-mm-teams-view-part>


			<acc-mm-assessments-view-part *ngIf="showView('MMAssessments', selectedViewItem)">
			</acc-mm-assessments-view-part>


			<acc-mm-editor-view-part *ngIf="showView('MMEditor', selectedViewItem)">
			</acc-mm-editor-view-part>

			<acc-mm-schedule-heatmap-view-part *ngIf="showView('MMScheduleMap', selectedViewItem)">
			</acc-mm-schedule-heatmap-view-part>

			<acc-mm-schedule-editor-view-part *ngIf="showView('MMScheduleMapEditor', selectedViewItem)">
			</acc-mm-schedule-editor-view-part>

			<acc-matching-hints-view-part *ngIf="showView('MMMatchingHints', selectedViewItem)">
			</acc-matching-hints-view-part>

			<acc-matching-tool-view-part *ngIf="showView('MMMatchingTool', selectedViewItem)">
			</acc-matching-tool-view-part>

		</div>
	</ng-container>
</div>