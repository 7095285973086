import { DbConceptName, Dbd, Immutable, PicStageId } from '@me-interfaces';


interface MutableDbdPicStage extends Dbd {

	readonly _concept: DbConceptName.PicStage,

	picStageId: PicStageId,
	name: string,
}


export interface DbdPicStage extends Immutable<MutableDbdPicStage> { }