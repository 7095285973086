import { DbConceptName, DbsEntity } from "@me-interfaces";
import { OldDomainDataService } from '@me-services/core/old-dd';
import { FuncService } from "@me-services/core/func";

/** @deprecated DO NOT USE THIS!*/
export abstract class EntityWrapper implements DbsEntity {

	constructor(
		protected readonly entity: DbsEntity,
		protected func: FuncService,
		protected dd: OldDomainDataService
	) {
	}


	//
	// DbcEntity Interface
	//
	readonly _concept: DbConceptName.Person | DbConceptName.Company;
	
	public get _dt() { return this.entity._dt; }
	public get entityId() { return this.entity.entityId; }
	public get entityType() { return this.entity.entityType; }
	public get photoUTC() { return this.entity.photoUTC; }
	public get address() { return this.entity.address; }
	public get zipId() { return this.entity.zipId; }
	public get inUS() { return this.entity.inUS; }
	public get _entityExtracts() { return this.entity._entityExtracts; }
	public get _name() { return this.entity._name; }

	public get updatedUTC() { return this.entity.updatedUTC; }
	public get updatedByPersonId() { return this.entity.updatedByPersonId; }




	//
	// Helpers
	//
	// public readonly online = new EntityOnline(this.entity, this.func, this.dd);

}