import { EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { ACC_APPLICATION_STATUS, DbaApplicationDemographics, DbConceptName } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { Icon } from "@me-shared-parts/UI-common";
import { ApplicationEditorService } from '../../../../admin/editor-services/application/application-editor.service';
import { ApplicationCols } from "../columns";
import { EXPLORE_GRID_ACTION_KEY, GRID_EXPERIENCE, GridColumnType, GridSetup, NOTES_GRID_ACTION_KEY, OPEN_GRID_ACTION_KEY } from "../interfaces";
import { GridExperience } from "./grid-experience";


/**
 * Standard experience for a grid of applications
 */
export class ApplicationGridExperience<ROW> extends GridExperience<ROW> {

	public static readonly experience: GRID_EXPERIENCE = 'APPLICATION';

	constructor(
		private ds: DataService,
		private util: UtilityService,
		private setup: GridSetup<ROW>,
		gridAction: EventEmitter<{ actionKey: string; rows: ROW[]; }>,
		private router: Router,
		private applicationEditorService: ApplicationEditorService,
	) {
		super(
			util, setup,
			[
				{ field: ApplicationCols.applicationId, header: 'applicationId', width: 90, type: GridColumnType.number, hidden: true, noColumnMenu: true },
			],
			[
				{ field: ApplicationCols.redFlag, header: "Flag", width: 40, type: GridColumnType.redFlag, noColumnMenu: true },
				{ field: ApplicationCols.notes, header: "Notes", headerTooltip: 'Application Notes', width: 50, type: GridColumnType.notes, noColumnMenu: true },
				{ field: ApplicationCols.companyName, header: "Business", width: 225, type: GridColumnType.text },
			],
			[
				{ field: ApplicationCols.status, header: "Application Status", width: 175, type: GridColumnType.text, hidden: true },
				{ field: ApplicationCols.applicantName, header: "Applicant", width: 200, type: GridColumnType.text, hidden: true },
				{ field: ApplicationCols.applicantEmail, header: "Applicant Email", width: 200, type: GridColumnType.text, hidden: true },
				{ field: ApplicationCols.applicantPhone, header: "Applicant Phone", width: 140, type: GridColumnType.phone, hidden: true },
				{ field: ApplicationCols.participantNames, header: "Partcipants", width: 225, type: GridColumnType.text, hidden: true },

				{ field: ApplicationCols.companyCityAndState, header: "Location", width: 150, type: GridColumnType.text, hidden: true },
				{ field: ApplicationCols.zipCode, header: "Zip Code", width: 95, type: GridColumnType.text, hidden: true },
				{ field: ApplicationCols.companyType, header: "Org Type", width: 215, type: GridColumnType.text, hidden: true },
				{ field: ApplicationCols.industry, header: "Industry", width: 235, type: GridColumnType.text, hidden: true },
				{ field: ApplicationCols.offerings, header: "Offerings", width: 100, type: GridColumnType.text, hidden: true },

				{ field: ApplicationCols.createdDate, header: "Created", width: 90, type: GridColumnType.text, hidden: true },

				...(
					setup.showDemographics ? [
						{ field: ApplicationCols.female, header: 'Female', width: 80, type: GridColumnType.number, hidden: true, noDownload: true },
						{ field: ApplicationCols.notMale, header: 'Not Male', width: 80, type: GridColumnType.number, hidden: true, noDownload: true },
						{ field: ApplicationCols.bipoc, header: 'Bipoc', width: 80, type: GridColumnType.number, hidden: true, noDownload: true },
						{ field: ApplicationCols.asian, header: 'Asian', width: 80, type: GridColumnType.number, hidden: true, noDownload: true },
						{ field: ApplicationCols.black, header: 'Black', width: 80, type: GridColumnType.number, hidden: true, noDownload: true },
						{ field: ApplicationCols.indigenous, header: 'Indigenous', width: 80, type: GridColumnType.number, hidden: true, noDownload: true },
						{ field: ApplicationCols.pacificIslander, header: 'Pacific Islander', width: 80, type: GridColumnType.number, hidden: true, noDownload: true },
						{ field: ApplicationCols.otherRace, header: 'Other Race', width: 80, type: GridColumnType.number, hidden: true, noDownload: true },
						{ field: ApplicationCols.white, header: 'White', width: 80, type: GridColumnType.number, hidden: true, noDownload: true },
						{ field: ApplicationCols.hispanic, header: 'Hispanic', width: 80, type: GridColumnType.number, hidden: true, noDownload: true },
						{ field: ApplicationCols.immigrant, header: 'Immigrant', width: 80, type: GridColumnType.number, hidden: true, noDownload: true },
						{ field: ApplicationCols.veteran, header: 'Veteran', width: 80, type: GridColumnType.number, hidden: true, noDownload: true },
						{ field: ApplicationCols.spanish, header: 'Spanish', width: 80, type: GridColumnType.number, hidden: true, noDownload: true },
						{ field: ApplicationCols.english, header: 'English', width: 80, type: GridColumnType.number, hidden: true, noDownload: true },
						{ field: ApplicationCols.deceased, header: 'Deceased', width: 80, type: GridColumnType.number, hidden: true, noDownload: true },
					] : []
				),
			],
			[
				{ key: EXPLORE_GRID_ACTION_KEY, icon: Icon.dialog_explore, label: `Explore ${setup.rowSingularName}` },
				{ key: OPEN_GRID_ACTION_KEY, icon: Icon.action_open, label: `Open ${setup.rowSingularName}` },
				{ key: NOTES_GRID_ACTION_KEY, icon: Icon.action_editNotes, label: `Edit Application Notes` },
			],
			gridAction,
			Icon.concept_application,
		);


	}


	public async applyBaseValues(rows: ROW[]) {
		const applicationIds = rows.map(row => {
			if (!row['applicationId']) {

				console.error('No applicationId');
			}
			else return row['applicationId'];
		}
		);

		const applicationMap = await this.ds.admin.application.getManyPackagesAsMap(applicationIds);

		for (const row of rows) {
			const applicationId = row['applicationId'];
			const application = applicationMap[applicationId];
			const demographics: DbaApplicationDemographics = row['demographics'];

			row[ApplicationCols.applicationId] = application.applicationId;
			row[ApplicationCols.notes] = application.notes;
			row[ApplicationCols.redFlag] = application.hasRedFlag;
			row[ApplicationCols.companyName] = application.company._name;
			row[ApplicationCols.status] = `${application.applicationStatusId} - ${ACC_APPLICATION_STATUS[application.applicationStatusId]}`;
			row[ApplicationCols.applicantName] = application.person._name;
			row[ApplicationCols.applicantEmail] = application.person._email;
			row[ApplicationCols.applicantPhone] = application.person.phone;
			row[ApplicationCols.participantNames] = application.applicationParticipants.map(p => p.person._name).join(', ');

			row[ApplicationCols.companyCityAndState] = this.ds.getCityAndState(application.company.zipId || application.person.zipId);
			row[ApplicationCols.industry] = this.ds.getIndustryName(application.company.industryId);
			row[ApplicationCols.companyType] = this.ds.getCompanyTypeName(application.companyTypeId);
			row[ApplicationCols.offerings] = application.company.offerings;
			row[ApplicationCols.zipCode] = this.ds.getZipCode(application.company.zipId || application.person.zipId);

			row[ApplicationCols.createdDate] = this.util.date.formatUTC(application.createdUTC, 'MMM D, YYYY', 'No Time', this.ds.languageId);

			if (this.setup.showDemographics && demographics) {
				row[ApplicationCols.female] = demographics?.female;
				row[ApplicationCols.notMale] = demographics?.notMale;
				row[ApplicationCols.bipoc] = demographics?.bipoc;
				row[ApplicationCols.asian] = demographics?.asian;
				row[ApplicationCols.black] = demographics?.black;
				row[ApplicationCols.indigenous] = demographics?.nativeAmerican;
				row[ApplicationCols.pacificIslander] = demographics?.pacificIslander;
				row[ApplicationCols.otherRace] = demographics?.otherRace;
				row[ApplicationCols.white] = demographics?.white;
				row[ApplicationCols.hispanic] = demographics?.hispanic;
				row[ApplicationCols.immigrant] = demographics?.immigrant;
				row[ApplicationCols.veteran] = demographics?.veteran;
				row[ApplicationCols.spanish] = demographics?.spanish;
				row[ApplicationCols.english] = demographics?.english;
				row[ApplicationCols.deceased] = demographics?.deceased;
			}


			row['siteCode'] = application.siteProgram.site.code;
			row['siteId'] = application.siteProgram.site.siteId;
			row['accId'] = application.accId;
			row['picId'] = application.picId;
		}
	}


	public sortRows(rows: ROW[]) {
		rows.sort((row1: ROW, row2: ROW) => {
			const val1 = row1[ApplicationCols.companyName];
			const val2 = row2[ApplicationCols.companyName];
			return val1 < val2 ? -1 : 1;
		});
	}


	public async explore(row: ROW) {
		await this.ds.explorer.explore(DbConceptName.Application, row[ApplicationCols.applicationId]);
	}


	public async open(row: ROW) {
		const applicationId = row[ApplicationCols.applicationId];

		if (applicationId) {
			if (row['accId']) this.router.navigate([`access/admin/national/sites/${row['siteId']}/accelerators/${row['accId']}/pre-accelerator/${applicationId}`]);
			if (row['picId']) this.router.navigate([`access/admin/communities/${row['siteCode'].toLowerCase()}/programs/pitch-contests/${row['picId']}/applications/${applicationId}`]);
		}
	}


	public async editNotes(row: ROW) {
		await this.applicationEditorService.launchNoteDialog(row[ApplicationCols.applicationId], row[ApplicationCols.notes], 'Application Notes', false);
	}
}