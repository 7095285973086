import { CompanyTypeId, IndustryId } from '@me-interfaces';
import { CompanyOfferings } from '../db-enums/company-offerings'
import { CompanyStatus } from '../db-enums/company-status'

export interface Old_CompanyDetails {
	companyName: string,
	companyId: number,
	companyTypeId: CompanyTypeId,
	companyTypeName: string,
	shortName: string,
	industryId: IndustryId,
	industryName: string,
	offerings: CompanyOfferings,
	status: CompanyStatus,
}