import { Component, Input, OnChanges } from '@angular/core';
import { UtilityService } from '@me-services/core/utility';


@Component({
	selector: 'phone-link',
	templateUrl: './SHR-UI_phone-link.part.html',
	styleUrls: ['SHR-UI_phone-link.part.scss'],
})
export class PhoneLinkPart implements OnChanges {

	@Input() phone: {
		phone: string,
		phoneTypeId?: number	// Optional phoneTypeId: To expand link options to include sms if canText
	};
	@Input() extension = '';

	formattedNumber: string;
	link: string;

	constructor(private util: UtilityService) {}

	ngOnChanges() {
		if (!this.phone.phone) this.formattedNumber = undefined;
		else {
			this.extension = this.extension ? this.extension.trim() : '';
			if (this.extension.length > 0) {
				this.link = `tel:${this.phone.phone},${this.extension}`;
				this.formattedNumber = `${this.util.text.formatPhone(this.phone.phone)} ext.${this.extension}`;
			}
			else {
				this.link = `tel:${this.phone.phone}`;
				this.formattedNumber = this.util.text.formatPhone(this.phone.phone);
			}
		}
	}

}