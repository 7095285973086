import { escapeRegex } from "./escape-regex";

/**
 * Create a RegExp object to search text for the existence of given text.
 */
export function createRegex(
	text: string,
	placement: 'full' | 'starts-with' | 'ends-with' | 'contains' = 'contains',
	caseSensitive = false,
	global = false,
) {

	let pattern = escapeRegex(text);
	if (placement == 'full' || placement == 'starts-with') pattern = '^' + pattern;
	if (placement == 'full' || placement == 'ends-with') pattern = pattern + '$';

	let flags = '';
	if (global) flags = flags + 'g';
	if (!caseSensitive) flags = flags + 'i';

	return new RegExp(pattern, flags);
}