import { Component, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { Address, DbcUser, DbsEvent, DbsPerson, EventTypeId } from '@me-interfaces';
import { OldUserService } from '@me-services/old-services-and-wrappers/user';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { getEventContextRows } from '../event-dialog/get-event-context-rows';


interface EventTable extends DbsEvent {
	start: string,
	end: string,
	toolStart?: string,
	toolEnd?: string,
	address: Address,
	venueName: string,
	eventContextRows: { label: string, value: string, site?: string, link: string }[]
}

@Component({
	selector: 'event-table',
	templateUrl: './SHR-ED_event-table.part.html',
	styleUrls: ['./SHR-ED_event-table.part.scss']
})
export class EventTablePart implements OnChanges {

	@Input() event: DbsEvent;
	@Input() showHeader = true;
	@Input() showSiteProgram = true;
	@Input() publicFacing = false;

	eventTable: EventTable;
	user: DbcUser;
	host: DbsPerson;

	constructor(
		private ds: DataService,
		private userService: OldUserService,
		private router: Router,
		private util: UtilityService,
	) {
	}

	async ngOnChanges() {
		if (!this.event) throw new Error(`Event Table part missing required attribute: event`);
		this.user = await this.userService.getUser();

		const venue = this.event.venueId ? await this.ds.admin.venue.getOnePackage(this.event.venueId) : undefined;
		this.host = this.event.hostPersonId ? await this.ds.admin.person.getOne(this.event.hostPersonId) : undefined;

		this.eventTable = {
			...this.event,
			venueName: venue ? venue.displayedName : 'Online',
			address: venue ? {
				address: venue.address,
				zip: venue.zip
			} : undefined,
			end: this.util.date.formatUTC(this.event.endUTC * 1000, 'MMM D, YYYY (DOW)', 'H:MM AM EST', this.ds.languageId),
			start: this.util.date.formatUTC(this.event.startUTC * 1000, 'MMM D, YYYY (DOW)', 'H:MM AM EST', this.ds.languageId),
			toolEnd: this.event.toolEndUTC ? this.util.date.formatUTC(this.event.toolEndUTC * 1000, 'MMM D, YYYY (DOW)', 'H:MM AM EST', this.ds.languageId) : '',
			toolStart: this.event.toolStartUTC ? this.util.date.formatUTC(this.event.toolStartUTC * 1000, 'MMM D, YYYY (DOW)', 'H:MM AM EST', this.ds.languageId) : '',
			eventContextRows: this.showSiteProgram ? await getEventContextRows(this.ds, this.event) : undefined,
		};
	}


	navigateToLink(row: { label: string, value: string, site?: string, link: string }) {
		if (this.user.isCrmUser && row.link) this.router.navigate([row.link]);
	}

}