import { DbsAward, DbsEntityNote, DbsPerson, DbsPicTeam, DbsPicTeamMember, NoteCategoryId, PicTeam, PitchContest } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { Observable } from "rxjs";
import { DomainDataManagers } from "../interfaces/domain-data-managers";
import { PackageManager } from "../package-manager";
import { SingletonsManager } from "../singletons-manager";
import { ApplicationPackageManager } from "./application";
import { CompanyPackageManager } from "./company";
import { PitchContestPackageManager } from "./pitch-contest";


export class PicTeamPackageManager extends PackageManager<DbsPicTeam, PicTeam> {


	constructor(
		singletonsAsOfUTC$: Observable<number>,
		util: UtilityService,
		sm: SingletonsManager<DbsPicTeam>,
		private domain: DomainDataManagers,
		private person: SingletonsManager<DbsPerson>,
		private company: CompanyPackageManager,
		private application: ApplicationPackageManager,
		private pitchContest: PitchContestPackageManager,
		private picTeamMember: SingletonsManager<DbsPicTeamMember>,
		private award: SingletonsManager<DbsAward>,
		private note: SingletonsManager<DbsEntityNote>,
	) {
		super(singletonsAsOfUTC$, util, sm);
	}

	/**
	 * Convert an array of DbcAccTeam to an array of AccTeam
	 */
	protected async _createPackages(dbsPicTeams: DbsPicTeam[]): Promise<PicTeam[]> {

		//
		// Get all the related objects
		//
		const picIds: number[] = [];
		const applicationIds: number[] = [];
		const personIds: number[] = [];
		const picTeamMembers = await this.picTeamMember.getAllAsArray();
		const picTeamMembersPersonIds = picTeamMembers.map(member => member.personId);
		const entityIds: number[] = [];


		for (const picTeam of dbsPicTeams) {
			picIds.push(picTeam.picId);
			applicationIds.push(picTeam.applicationId);
			personIds.push(picTeam.updatedByPersonId);
		}

		const personMap = await this.person.getManyAsMap([...personIds, ...picTeamMembersPersonIds]);
		const picMap = await this.pitchContest.getManyPackagesAsMap(picIds);
		const applicationMap = await this.application.getManyPackagesAsMap(applicationIds);
		const companiesMap = await this.company.getManyPackagesAsMap(
			(await this.application.getManyAsArray(applicationIds)).map(a => a.companyId)
		);
		const awardsArray = await this.award.getAllAsArray();
		const allNotes = await this.note.getAllAsArray();

		for (const company in companiesMap) {
			entityIds.push(companiesMap[company].entityId);
		}

		for (const personId of picTeamMembersPersonIds) {
			entityIds.push(personMap[personId].entityId);
		}

		const redFlagNoteEntityIds = allNotes
			.filter(n => n.noteCategoryId == NoteCategoryId.RedFlag)
			.filter(n => entityIds.includes(n.entityId))
			.map(n => n.entityId);

		//
		// Package 'em up
		//
		const picTeams: PicTeam[] = dbsPicTeams.map(picTeam => {

			const pitchContest = picMap[picTeam.picId];
			const application = applicationMap[picTeam.applicationId];
			const company = companiesMap[application.companyId];
			const updatedByPerson = personMap[picTeam.updatedByPersonId];
			const members = picTeamMembers.filter(member => member.picTeamId == picTeam.picTeamId).map(m => ({ member: m, person: personMap[m.personId] }));
			const awards = awardsArray.filter(award => award.picTeamId && award.picTeamId == picTeam.picTeamId).map(award => ({ awardId: award.awardId, value: award.value }));
			const awardedValue = awards.reduce((a, award) => { a += award.value; return a; }, 0);
			const companyAndTeamEntityIds = [...members.map(member => member.person.entityId), company.entityId];
			let hasRedFlag = false;

			for (const entityId of companyAndTeamEntityIds) {
				if (redFlagNoteEntityIds.includes(entityId)) {
					hasRedFlag = true;
					break;
				}
			}

			return {
				...picTeam,
				id: picTeam.picTeamId,
				name: application.company._name,
				explorerName: application.company._name,
				pitchContest,
				application,
				company,
				updatedByPersonName: updatedByPerson?._name || `Person #${picTeam.updatedByPersonId}`,
				members,
				awards,
				awardedValue,
				appUrl: this.createAppUrl(picTeam, pitchContest),
				hasRedFlag,
			};
		});

		return picTeams;
	}

	private createAppUrl(picTeam: DbsPicTeam, pic: PitchContest): string {
		const picTeamId = picTeam.picTeamId;
		const picId = picTeam.picId;
		const siteCode = pic.siteProgram.site.code;
		return `/access/admin/communities/${siteCode.toLowerCase()}/programs/pitch-contests/${picId}/participants/${picTeamId}`;
	}


	/**
	 * Get all teams for each picId provided
	 */
	public async getByPicIds(picIds: readonly number[]): Promise<Readonly<Record<number, ReadonlyArray<PicTeam>>>> {

		return await this.getPackagesAsArraysByForeignIds('picId', picIds);

	}


	/**
	 * Get all teams for each applicationId provided
	 */
	public async getByApplicationIds(applicationIds: readonly number[]): Promise<Readonly<Record<number, ReadonlyArray<PicTeam>>>> {

		return await this.getPackagesAsArraysByForeignIds('applicationId', applicationIds);
	}

}