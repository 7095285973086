import { Component, Input, OnChanges } from '@angular/core';
import { ApplicationStatusLog } from '@me-interfaces';


@Component({
	selector: 'application-status-logs',
	templateUrl: './SHR-PG_application-status-logs.part.html',
	styleUrls: ['./SHR-PG_application-status-logs.part.scss'],
})
export class ApplicationStatusLogsPart implements OnChanges {
	@Input() type: 'acc' | 'pic';
	@Input() statusLogs: ApplicationStatusLog[];

	logs: ApplicationStatusLog[];

	async ngOnChanges() {
		if (!this.statusLogs) throw new Error('Missing required attribute: statusLogs');
		this.logs = [...this.statusLogs].sort((a, b) => a.changedUTC > b.changedUTC ? 1 : -1);
	}
}
