import { DbConceptName, DbcZip, Dbs, ExplorablePackage, Immutable } from '@me-interfaces';


interface MutableDbsVenue extends Dbs {

	readonly _concept: DbConceptName.Venue,

	venueId: number,
	officialName: string,
	displayedName: string,
	address: string,
	zipId: number,
	placeId: string,
	latitude: number,
	longitude: number,
	website: string,
	isActive: boolean,
	publicNotes: string,
	internalNotes: string,
	createdUTC: number,
	createdByPersonId: number,
	updatedByPersonId: number,
	updatedUTC: number,
}


interface MutableVenue extends MutableDbsVenue {
	createdByPersonName: string,
	updatedByPersonName: string,
	zip: DbcZip,
}


export interface DbsVenue extends Immutable<MutableDbsVenue> { }
export interface Venue extends ExplorablePackage<DbConceptName.Venue>, Immutable<MutableVenue> { }