import { DbConceptName, Dbd, Immutable, QuestionTypeId } from '@me-interfaces';


interface MutableDbdQuestionType extends Dbd {

	readonly _concept: DbConceptName.QuestionType,

	questionTypeId: QuestionTypeId,
	name: string,
}


export interface DbdQuestionType extends Immutable<MutableDbdQuestionType> { }