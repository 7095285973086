import { Injectable } from '@angular/core';
import { DbsEvent, GooglePlace, VenueChanges } from '@me-interfaces';
import { DdVenue, OldDomainDataService } from '@me-services/core/old-dd';
import { FuncService } from '@me-services/core/func';


@Injectable({ providedIn: 'root' })
export class VenueService {

	constructor(
		private func: FuncService,
		private dd: OldDomainDataService,
	) {
	}


	/**
	 * Update a venue in the database and refresh the domain data cache. 
	 */
	public async update(venueId: number, changes: VenueChanges): Promise<DdVenue> {

		if (await this.func.venue.update({ venueId, changes })) {
			await this.dd.fetchLatestData();
		}

		return this.dd.venues.getById(venueId);
	}


	/**
	 * Use the results from the Google Maps API to create a new venue. 
	 */
	public async add(place: GooglePlace): Promise<DdVenue> {

		const response = await this.func.venue.add({ place });
		if (response.result == 'added') await this.dd.fetchLatestData();

		return this.dd.venues.getById(response.venueId);
	}


	/**
	 * Delete an event from the database, and refresh the
	 * domain data cache.
	 */
	public async delete(venueId: number): Promise<void> {
		await this.func.venue.delete({ venueId });
		await this.dd.fetchLatestData();
	}


	/**
	 * Return the full list of events that use this venue. The UI will
	 * allow the user to delete a venue only if it has no events.
	 */
	public async getEvents(venueId: number): Promise<DbsEvent[]> {

		return await this.func.venue.getEvents({ venueId });
	}

}