
/**
 * Optional flags that may be exported in an action and
 * used by the shimmer to alter how the action will be run.
 */
export interface FanServerFlags {
	
	/**
	 * This action can be run even if there is not a person signed in with an appSession.
	 */
	allowNoSession?: boolean,

}