import { AccMatchingMentor, AccMatchingTeam, DbsEvent } from '@me-interfaces';

export interface AccMatchingMegaData {
	events: DbsEvent[],
	mentors: AccMatchingMentor[],
	teams: AccMatchingTeam[],
	assessments: {
		accTeamId: number,
		personId: number,
		m2t: number,
		t2m: number,
	}[],
	meetingTimes: [
		number,
		number,
		number,
		number,
		number,
		number,
		number,
	],
	hasAssignments: boolean,
	matchingHints?: string,
	mentorsMap: { [personId: number]: AccMatchingMentor },
	teamsMap: { [accTeamId: number]: AccMatchingTeam },
	assessmentsMap: { [key: string]: [number | undefined, number | undefined] }
}