import { RegionAreaService, RegionPageService } from '@REGION-area';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { GridSetup, SELECTION_GRID_ACTION_KEY } from '@me-grid';
import { Accelerator } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { GridPart } from '@me-shared-parts/UI-common';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';


interface AcceleratorRow {
	accId: number,
	siteCode: string,
	siteId: number,
	updatedUTC: number,
}


@Component({
	selector: 'region-accelerators-view-part',
	templateUrl: './accelerators-view.part.html',
})
export class RegionAcceleratorsViewPart extends DestroyablePart implements OnInit {

	public gridSetup = this.setupGrid();

	public rows$: Observable<AcceleratorRow[]> = of([]);

	@ViewChild(GridPart) meGrid: GridPart<AcceleratorRow>;

	constructor(
		private util: UtilityService,
		public regionAreaService: RegionAreaService,
		public ds: DataService,
		public pageService: RegionPageService
	) {
		super();
	}

	ngOnInit(): void {

		super.initDestroyable();

		this.rows$ = this.rows$ = this.ds.admin.singletonsAsOfUTC$.pipe(mergeMap(data => this.buildRows(data)));
	}

	async buildRows(singletonsAsOfUTC: number) {

		const identifier = this.regionAreaService.getId();
		const regionId = identifier.id;
		const sites = (await this.ds.admin.site.getByRegionIds([regionId]))[regionId];

		const siteIds = sites.map(site => site.siteId);

		const acceleratorsBySiteId = await this.ds.admin.accelerator.getBySiteIds(siteIds);

		const accelerators: Accelerator[] = [];
		for (const siteId in acceleratorsBySiteId) {
			accelerators.push(...acceleratorsBySiteId[siteId]);
		}


		const rows: AcceleratorRow[] = accelerators.map(a => ({
			accId: a.accId,
			siteCode: a.siteProgram.site.code,
			siteId: a.siteProgram.siteId,
			updatedUTC: a.updatedUTC,
		}));
		return rows;
	}



	private setupGrid(): GridSetup<AcceleratorRow> {

		return {
			experience: 'ACC',
			size: {
				fitTo: 'PAGE-TABS-MAIN-TAB',
				heightMultiplier: 1,
				shrinkBy: 0,
				layout$: this.pageService.layout$,
				viewSelector: true,
			},
			rowSingularName: "Accelerator",
			rowPluralName: "Accelerators",
			rowKey: "accId",
			stateKey: "region-all-accelerator",
			canAdd: false,
			canRefresh: false,
			canDownload: false,
			initialState: {
				sort: [{ field: 'ACC_startUTC', dir: 'desc' }],
			},
		};
	}



	async gridActionHandler(action: { actionKey: string, rows: AcceleratorRow[] }) {

		if (action.actionKey == SELECTION_GRID_ACTION_KEY) {
			// Nothing to do with a simple row selection
		}
	}

}