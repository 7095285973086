import { Accelerator, AccJudge, DbsAccJudge } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { Observable } from "rxjs";
import { DomainDataManagers } from "../interfaces/domain-data-managers";
import { PackageManager } from "../package-manager";
import { SingletonsManager } from "../singletons-manager";
import { AcceleratorPackageManager } from "./accelerator";
import { PersonPackageManager } from "./person";

export class AccJudgePackageManager extends PackageManager<DbsAccJudge, AccJudge> {

	constructor(
		singletonsAsOfUTC$: Observable<number>,
		util: UtilityService,
		sm: SingletonsManager<DbsAccJudge>,
		private domain: DomainDataManagers,
		private person: PersonPackageManager,
		private accelerator: AcceleratorPackageManager,
	) {
		super(singletonsAsOfUTC$, util, sm);
	}


	protected async _createPackages(dbsAccJudges: DbsAccJudge[]): Promise<AccJudge[]> {

		//
		// Get all the people and companies
		//
		const personIds: number[] = [];
		const acceleratorIds: number[] = [];

		for (const accJudge of dbsAccJudges) {
			personIds.push(accJudge.personId);
			personIds.push(accJudge.updatedByPersonId);
			acceleratorIds.push(accJudge.accId);
		}

		const personMap = await this.person.getManyPackagesAsMap(personIds);
		const acceleratorMap = await this.accelerator.getManyPackagesAsMap(acceleratorIds);


		//
		// Package 'em up
		//
		const accJudges: AccJudge[] = dbsAccJudges.map(dbsAccJudge => {

			const accelerator = acceleratorMap[dbsAccJudge.accId];
			const person = personMap[dbsAccJudge.personId];
			const updatedByPerson = personMap[dbsAccJudge.updatedByPersonId];


			return {
				...dbsAccJudge,
				accelerator,
				person,
				updatedByPersonName: updatedByPerson?.name || `Person #${dbsAccJudge.updatedByPersonId}`,
				appUrl: this.createAppUrl(accelerator),
			};
		});

		return accJudges;
	}


	private createAppUrl(acc: Accelerator): string {
		const siteId = acc.siteId;
		const accId = acc.accId;
		return `/access/admin/national/sites/${siteId}/accelerators/${accId}/cohort?tab=1&view=13`;
	}

	/**
	 * Get all acc judges for each personId provided
	 */
	public async getByPersonIds(personIds: readonly number[]): Promise<Record<number, ReadonlyArray<AccJudge>>> {

		return await this.getPackagesAsArraysByForeignIds('personId', personIds);
	}


	/**
	 * Get all acc judges for each accId provided
	 */
	public async getByAccIds(accIds: readonly number[]): Promise<Record<number, ReadonlyArray<AccJudge>>> {

		return await this.getPackagesAsArraysByForeignIds('accId', accIds);
	}
}