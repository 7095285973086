import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { DataService } from '@me-services/core/data';
import { StaffOverviewPageService } from '../../staff-overview-page.service';

@Component({
	selector: 'staff-counts-tab-part',
	templateUrl: './staff-counts-tab.part.html',
	styleUrls: ['./staff-counts-tab.part.scss'],
})
export class StaffCountsTabPart extends DestroyablePart implements OnInit {


	constructor(
		public ds: DataService,
		public pageService: StaffOverviewPageService,
	) {
		super();
	}

	ngOnInit(): void {
		super.initDestroyable();

	}

}
