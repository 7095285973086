<div class="choose-venue-part">

	<div *ngIf='options?.length' class='venues-found'>
		<me-listbox [value]='selectedOption?.value' [options]='options' (valueChange)='valueChange($event)'>
		</me-listbox>
		<div *ngIf='selectedVenue'>

			<kendo-tabstrip (tabSelect)='onVenueTabSelect'>
				<kendo-tabstrip-tab title='Venue' [selected]='true'>
					<ng-template kendoTabContent>
						<table
							class='pro-table pro-table-with-thin-rows pro-table-no-header pro-table-with-labels pro-table-without-border'>
							<tr>
								<td> Display Name </td>
								<td> {{selectedVenue.displayedName}} </td>
							</tr>
							<tr>
								<td> Google </td>
								<td> <a href='{{getMapUrl(selectedVenue)}}'
										target='_blank'>{{selectedVenue.officialName}}</a>
								</td>
							</tr>
							<tr>
								<td> Address </td>
								<td> {{selectedVenue.address}} </td>
							</tr>
							<tr>
								<td> Locality </td>
								<td> {{selectedVenue.zipId}} </td>
							</tr>
							<tr>
								<td> Distance </td>
								<td> {{selectedVenue.distance.miles}} miles from
									{{selectedVenue.distance.zip.cityAndState}}
									{{selectedVenue.distance.zip.code}}</td>
							</tr>
							<tr>
								<td> Website </td>
								<td> <a href='{{selectedVenue.website}}' target='_blank'>{{selectedVenue.website}}</a>
								</td>
							</tr>
							<tr>
								<td> Public Notes </td>
								<td> {{selectedVenue.publicNotes}} </td>
							</tr>
							<tr>
								<td> Internal Notes </td>
								<td> {{selectedVenue.internalNotes}} </td>
							</tr>
						</table>
					</ng-template>
				</kendo-tabstrip-tab>

				<kendo-tabstrip-tab title='Edit'>
					<ng-template kendoTabContent>
						<edit-venue-part [venue]="selectedVenue" (venueChanged)="onVenueChange($event)">
						</edit-venue-part>
					</ng-template>
				</kendo-tabstrip-tab>

				<kendo-tabstrip-tab title='Events'>
					<ng-template kendoTabContent>
						<events-venue-part [venue]="selectedVenue" (venueDeleted)="onVenueDelete($event)">
						</events-venue-part>
					</ng-template>
				</kendo-tabstrip-tab>
			</kendo-tabstrip>

		</div>
		<div *ngIf='!selectedVenue' class='choose-instruction'>
			<me-icon [icon]="{ icon: 'far fa-arrow-left', text: 'Choose a Venue' }"></me-icon>
		</div>
	</div>

	<div *ngIf='!(options?.length)' class='venues-not-found'>
		There are no defined venues within 50 miles of this zip code.
	</div>
</div>