import { DbsPerson } from "@me-interfaces";
import { OldDomainDataService } from '@me-services/core/old-dd';
import { FuncService } from "@me-services/core/func";

/** @deprecated DO NOT USE THIS!*/
export class PersonEmail {

	constructor(private readonly person: DbsPerson, private func: FuncService, private dd: OldDomainDataService) {
	}

	public async add(email: string) {
		const person: DbsPerson = await this.func.person.email.add({ entityId: this.person.entityId, personId: this.person.personId, email });

		//@ts-ignore TS2540
		this.person = Object.assign(this.person, person);
	}


	public async remove(emailId: number) {
		const person: DbsPerson = await this.func.person.email.remove({ entityId: this.person.entityId, affectedPersonId: this.person.personId, emailId });

		//@ts-ignore TS2540
		this.person = Object.assign(this.person, person);

	}


	public async setPrimary(emailId: number) {
		const person: DbsPerson = await this.func.person.email.setPrimary({ affectedPersonId: this.person.personId, emailId });

		//@ts-ignore TS2540
		this.person = Object.assign(this.person, person);
	}

}