import { AccSessionTopicSurveyResponse, DbsAccSessionTopicSurveyResponse } from "@me-interfaces";
import { UtilityService } from "@me-services/core/utility";
import { Observable } from "rxjs";
import { DomainDataManagers } from "../interfaces/domain-data-managers";
import { PackageManager } from "../package-manager";
import { SingletonsManager } from "../singletons-manager";
import { AcceleratorPackageManager } from "./accelerator";
import { EventPackageManager } from "./event";


export class AccSessionTopicSurveyResponsePackageManager extends PackageManager<DbsAccSessionTopicSurveyResponse, AccSessionTopicSurveyResponse>{

	constructor(
		singletonsAsOfUTC$: Observable<number>,
		util: UtilityService,
		sm: SingletonsManager<DbsAccSessionTopicSurveyResponse>,
		private accelerator: AcceleratorPackageManager,
		private event: EventPackageManager,
		private domain: DomainDataManagers,
	) {
		super(singletonsAsOfUTC$, util, sm);
	}


	/**
	 * Convert singletons to packages
	 */
	protected async _createPackages(dbsAccSessionTopicSurveyResponses: DbsAccSessionTopicSurveyResponse[]): Promise<AccSessionTopicSurveyResponse[]> {
		const accIds = dbsAccSessionTopicSurveyResponses.map(survey => survey.accId);
		const accMap = await this.accelerator.getManyPackagesAsMap(accIds);

		const eventIds = dbsAccSessionTopicSurveyResponses.map(survey => survey.eventId);
		const eventMap = await this.event.getManyPackagesAsMap(eventIds);

		const topicMap = await this.domain.topic.getAllAsMap();



		//
		// Package 'em up
		//
		const accSessionTopicSurveyResponses: AccSessionTopicSurveyResponse[] = dbsAccSessionTopicSurveyResponses.map(accSessionTopicSurveyResponse => {

			return {
				...accSessionTopicSurveyResponse,
				accelerator: accMap[accSessionTopicSurveyResponse.accId],
				event: eventMap[accSessionTopicSurveyResponse.eventId],
				topic: topicMap[accSessionTopicSurveyResponse.topicId],
			};
		});

		return accSessionTopicSurveyResponses;
	}


	/**
	 * Get all acc session topic survey responses for each accId provided
	 */
	public async getByAccIds(accIds: readonly number[]): Promise<Record<number, ReadonlyArray<AccSessionTopicSurveyResponse>>> {

		return await this.getPackagesAsArraysByForeignIds('accId', accIds);
	}

}