import { DbConceptName, DbdLanguage, DbdProgramType, Dbs, DbsPerson, Immutable } from "@me-interfaces";


interface MutableDbsProgram extends Dbs {

	readonly _concept: DbConceptName.Program,

	readonly programId: number,
	readonly programTypeId: number,
	readonly name: string,
	readonly directorId: number,
	readonly languageId: number,
	readonly applicantQuestionsId: number,
	readonly readerQuestionsId: number,
	readonly interviewQuestionsId: number,
	readonly judgeQuestionsId: number,
	readonly createdUTC: number,
	readonly updatedUTC: number,
	readonly updatedByPersonId: number,
}


interface MutableProgram extends MutableDbsProgram {
	directorName: string,
	updatedByPersonName: string,
	language: DbdLanguage,
	programType: DbdProgramType,
}


export interface DbsProgram extends Immutable<MutableDbsProgram> { }
export interface Program extends Immutable<MutableProgram> { }