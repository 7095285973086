import { DbConceptName, Dbs, Immutable } from '@me-interfaces';


interface MutableDbsPosition extends Dbs {

	readonly _concept: DbConceptName.Position,

	positionId: number,
	personId: number,
	companyId: number,
	title: string,
	isAdmin: boolean,
	isFounder: boolean,
	phone: string,
	phoneTypeId: number,
	extension: string,
	createdUTC: number,
	updatedUTC: number,
	updatedByPersonId: number,
}


export interface DbsPosition extends Immutable<MutableDbsPosition> { }