import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { DdAcc, OldDomainDataService } from '@me-services/core/old-dd';
import { FuncService } from '@me-services/core/func';
import { UtilityService } from '@me-services/core/utility';
import { AccWapper } from './acc-wapper';

/** @deprecated DO NOT USE THIS!*/
@Injectable({ providedIn: 'root' })
export class OldAccService {

	constructor(
		private func: FuncService,
		private util: UtilityService,
		private dd: OldDomainDataService,) {
	}

	public getAccIdFromUrl(url?: string): number {

		let accId: number = undefined;
		if (!url) url = window.location.pathname.toLowerCase();

		if (url.startsWith('/access/sites/')) {
			if (url.includes('/accelerators/')) {
				const parts = url.split('/');
				if (parts.length >= 5) accId = +parts[5];
			}
		}

		if (url.startsWith('/access/admin/communities/')) {
			if (url.includes('/programs/accelerators/')) {
				const parts = url.split('/');
				if (parts.length >= 8) accId = +parts[7];
			}
		}

		if (!accId) {
			this.util.log.errorMessage(`accService.getAccIdFromUrl() called for path: ${url} which doesn't have an accId`);
		}

		return accId;
	}

	public getDdAccFromRoute(route: ActivatedRouteSnapshot): DdAcc {

		const url = this.util.router.routeToUrl(route);
		return this.getDdAccFromUrl(url);
	}

	public getDdAccFromUrl(url?: string): DdAcc {

		let acc: DdAcc = undefined;
		const accId = this.getAccIdFromUrl(url);

		if (accId) acc = this.dd.accs.getById(accId);

		return acc;
	}

	public getAccIdFromRoute(route: ActivatedRouteSnapshot): number {

		const url = this.util.router.routeToUrl(route);
		return this.getAccIdFromUrl(url);
	}

	public wrapAcc(acc: DdAcc): AccWapper {
		return new AccWapper(acc, this.func, this.dd);
	}
}