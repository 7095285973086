<div class='acc-select-tab-part'>

	<acc-stage-summary [accStageId]="accStageId"></acc-stage-summary>

	<section class="tab-section">

	</section>

	<section class="tab-section">
		<acc-application-counts-part [accStageId]="accStageId"
			[pageService]="pageService"></acc-application-counts-part>
	</section>

</div>