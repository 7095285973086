import { BehaviorSubject, distinctUntilChanged, lastValueFrom, map, ReplaySubject, take } from "rxjs";
import { PageTabsLayout } from "./SHR-CMN_page-tabs-layout";


export abstract class PageTabsLayoutPageService<TAB extends number, VIEW extends number> {

	private _layout$ = new ReplaySubject<PageTabsLayout>(1);
	public layout$ = this._layout$.asObservable();
	
	public collapsedChange$ = this._layout$.pipe(
		map(layout => !layout.tab1Expanded),
		distinctUntilChanged(),
	)

	private state: { tab: TAB, view: VIEW };

	private _selectedTab$ = new BehaviorSubject<TAB>(this.initialTab);
	public selectedTab$ = this._selectedTab$.pipe(distinctUntilChanged());

	private _selectedView$ = new BehaviorSubject<VIEW>(this.initialView);
	public selectedView$ = this._selectedView$.pipe(distinctUntilChanged());


	constructor(
		/** Pass the name of the class that extends PageTabsLayoutPageService */
		private pageServiceClassName: string,
		private initialTab: TAB,
		private initialView: VIEW,
	) {

		this.state = { tab: initialTab, view: initialView };

		window.addEventListener('popstate', event => {
			this.updateState(event.state[pageServiceClassName]);
		});


		//
		// If the page tabs transition to collapsed then the first tab should be selected
		//
		this.collapsedChange$.subscribe(collapsed => {
			if (collapsed && this._selectedTab$.value !== 1) {
				this.selectTab(<TAB>1);
			}
		})

	}


	/**
	 * This function should be called in from the page's ngOnInit() function. It ensures the last state values
	 * are reinstated and handles query parameters if passed on the url query string. (e.g. ?tab=2&view=3)
	 */
	initializeUrl() {

		const state = this.state;

		//
		// If there are query params (e.g. ?tab=2&view=3) then we remove
		// them from the href and use the values to set the state. 
		//
		const href = location.href.split('?');

		if (href[1]) {
			const parms = href[1].toLowerCase().split('&');

			for (const parm of parms) {
				const parmParts = parm.split('=');
				const value = +parmParts[1];

				if (parmParts[0] == 'tab') state.tab = <TAB>value || this.initialTab;
				if (parmParts[0] == 'view') state.view = <VIEW>value || this.initialView;
			}
		}

		const historyState = {};
		historyState[this.pageServiceClassName] = state;

		history.replaceState(historyState, '', href[0]);
		this.updateState(state);
	}


	public selectTab(tabId: TAB) {
		this.updateUrl(tabId, this._selectedView$.value);
	}


	public selectView(viewId: VIEW) {
		this.updateUrl(this._selectedTab$.value, viewId);
	}


	/**
	 * Updates the tab and view.
	 * @param changeTabOnlyIfTab1Expanded If true then the tab will not change if the first tab is standalone.
	 */
	public async selectTabAndView(tabId: TAB, viewId: VIEW, changeTabOnlyIfTab1Expanded = false) {

		if (changeTabOnlyIfTab1Expanded) {
			const layout = await lastValueFrom(this.layout$.pipe(take(1)));
			if (!layout.tab1Expanded) tabId = this.state.tab;
		}

		this.updateUrl(tabId, viewId);
	}


	private updateUrl(tab: TAB, view: VIEW) {

		console.log(`updateUrl(${tab}, ${view});`);

		if (this.state.tab == tab && this.state.view == view) return;

		const state = { tab, view };

		const historyState = {};
		historyState[this.pageServiceClassName] = state;

		history.pushState(historyState, '', location.href);
		this.updateState(state);
	}


	private updateState(state: { tab: TAB, view: VIEW }) {
		if (!state) return;
		this.state = state;
		this._selectedView$.next(state.view);
		this._selectedTab$.next(state.tab);
	}


	public changeLayout(layout: PageTabsLayout) {
		this._layout$.next(layout);
	}
}