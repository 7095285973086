import { DbsPerson, DbsCompany, Answer, DbsEvent } from '@me-interfaces'

export interface AssessmentsData {
	person: DbsPerson,
	ratings: {
		company: DbsCompany,
		rating?: number,
		answers: readonly Answer[],
		event?: DbsEvent,
	}[]
}