import { MenuItem } from '@me-interfaces';


export const adminToolsMenu: MenuItem = {

	label: 'Tools',
	routerLink: '/access/tools',
	icon: 'fas fa-wrench',

	subMenu: [
		{
			label: 'Person Activity',
			routerLink: '/access/contacts/sessions',
			icon: 'fas fa-user-clock',
		},
		{
			label: 'Notes Finder',
			routerLink: '/access/contacts/notes-finder',
			icon: 'fas fa-sticky-note',
		},
	]
}