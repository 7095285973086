<field-chrome [basePart]='basePart' (fieldClick)='textAreaField.focus()'>

	<div class='input-wrapper'>

		<textarea #textAreaField [placeholder]='text.placeholder' kendoTextArea [autoSize]="false" [value]='currentValue'
			(focus)='setFocused(true)' (blur)='setFocused(false)' [attr.maxlength]='field.maxLength'
			[attr.minLength]='field.minLength' [disabled]='status === "saving"' [class.has-error]='!!errorText'
			(input)='textChange($event)' [class.has-error]='!!basePart.errorText' [style.min-width]="'100%'">
		</textarea>

	</div>

</field-chrome>