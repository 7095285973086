export const enum AwardNameId {
	Place1Award = 1,
	Place2Award = 2,
	Place3Award = 3,
	Place4Award = 4,
	Place5Award= 5,
	AudienceChoiceAward = 6,
	AwardFromSponsor = 7,
	QuarterlyQ2Award = 8,
	QuarterlyQ3Award = 9,
	QuarterlyQ4Award = 10,
	ChristinaHamiltonAward = 11,
	HectorCondeAward = 12,
	PaulaBuxbaumAward = 13,
}