export const environment = {
	appCode: "ME",
	name: 'ME-DEV',
	functionUrl: "https://us-central1-me-dev-9a029.cloudfunctions.net/",
	firebaseConfig: {
		apiKey: "AIzaSyDX2y3GsUaxHdLdhaoklGWgn3vv2OlB9nQ",
		authDomain: "me-dev-9a029.firebaseapp.com",
		databaseURL: "https://me-dev-9a029.firebaseio.com",
		projectId: "me-dev-9a029",
		storageBucket: "me-dev-9a029.appspot.com",
		messagingSenderId: "887043483297",
		appId: "1:887043483297:web:d317fd77004f19399032e0",
		measurementId: "G-JLLJ1D7PP0"
	},
	// highlightConfig: {
	// 	projectId: '4d73kq0g',
	// 	environment: 'Dev',
	// },
	google_tag_manager_container_id: {
		eforAll: 'GTM-NVDJKV6',
		eforever: 'GTM-NVDJKV6',
	},
};
