import { ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { LabelsService } from '@me-services/ui/labels';
import { LayoutDimensions, LayoutService } from '@me-services/ui/layout';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_SM, BREAKPOINT_XL } from '@me-services/ui/layout/layout-constants';
import { takeUntil } from 'rxjs/operators';
import { Icon } from './icon';
import { getIconClass, getIconContext, IconContext } from './index';


@Component({
	selector: 'me-icon',
	templateUrl: './SHR-CMN_icon.part.html',
	styleUrls: ['./SHR-CMN_icon.part.scss'],
})
export class IconPart extends DestroyablePart implements OnInit, OnChanges {

	@Input() icon: IconContext;

	redFlagIconClass = getIconClass(Icon.notes_redFlag);

	/**
	 * Determines if the icon should be displayed
	 * with a red slash line.
	 */
	iconSlashed = false;


	/**
	* Determines whether the part should make room
	* to display text to the right of the icon.
	*/
	public hidingText = false;


	constructor(
		public labelsService: LabelsService,
		public layoutService: LayoutService,
		private cd: ChangeDetectorRef,
	) {
		super();
	}


	ngOnInit() {
		super.initDestroyable();

		this.layoutService.dimensions$
			.pipe(takeUntil(this.destroyed$))
			.subscribe(this.checkForHideText.bind(this));
	}


	emptyIcon = false;

	async ngOnChanges() {
		if (!this.icon) {
			console.error(`Missing required attribute: icon. Setting to Icon.empty`);
			this.icon = getIconContext(Icon.empty);
			this.emptyIcon = true;
		}
		else if (this.emptyIcon) {
			console.error(`The missing icon has since been changed to ${this.icon.iconClass}. Must be an ngOnChanges timing issue.`);
			this.emptyIcon = false;
		}

		this.iconSlashed = this.checkIfIconSlashed(this.icon);
		this.checkForHideText(this.layoutService.dimensions$.value);
		this.cd.detectChanges();
	}


	/**
	 * Returns true for Icons that should be rendered with a red strikethrough
	 */
	checkIfIconSlashed(ctx: IconContext) {
		return [
			// Icon.application_canceled,
			// Icon.application_readRejected,
			// Icon.application_interviewRejected,
			// Icon.application_programRejected,
		].includes(ctx.icon);
	}

	checkForHideText(d: LayoutDimensions) {

		if (!d || !this.icon) {
			this.hidingText = false;
		}
		else {
			const hideText = this.icon.hideText || 'never';

			this.hidingText =
				!this.icon.text ||
				(hideText == 'always') ||
				(hideText == 'lt-xl' && d.windowWidth < BREAKPOINT_XL) ||
				(hideText == 'lt-lg' && d.windowWidth < BREAKPOINT_LG) ||
				(hideText == 'lt-md' && d.windowWidth < BREAKPOINT_MD) ||
				(hideText == 'lt-sm' && d.windowWidth < BREAKPOINT_SM);
		}
	}

}