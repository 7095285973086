import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { RouterModule } from '@angular/router';
import { SharedCompanyModule } from '@me-shared-parts/CM-company/CM.module';
import {
	AccApplicationStatusSelectorPart,
	AccGoalsAddDialog,
	AccGoalsEditPart,
	AccGoalsPart,
	AccGoalsTeamSelectorPart,
	AccGoalsViewPart,
	AccQuarterlyControllerPart,
	AccStageMsgPart,
	ActionsMenuPart,
	AlumPromoterPart,
	ApplicationDisplayPart,
	ApplicationEditorPart,
	ApplicationEditorSectionTitlePart,
	ApplicationInstructionsPart,
	ApplicationNotesButtonPart,
	ApplicationParticipantDialog,
	ApplicationParticipantsPart,
	ApplicationStatusLogsPart,
	ApplicationStatusPart,
	ApplicationViewerMessagePart,
	ApplicationViewerPart,
	ApplyLangJumpPart,
	AssessmentLevelTitlePart,
	AssessmentResultsPart,
	AwardDisplayDialog,
	AwardEditPart,
	ContactActionsMenuPart,
	DemographicsIndicatorPart,
	EditableQuestionPart,
	EditableQuestionsPart,
	EventAwardsPart,
	EventsGridPart,
	EventsListPart,
	FeedbackManagerPart,
	FeedbackManagerV1Part,
	FollowUpDateDialog,
	LanguageSelectorMenuPart,
	NoteDialog,
	OverlapRollupsPart,
	PositivityRollupsPart,
	ProgramLangJumpPart,
	QuarterlyGoalsPart,
	QuestionStepper, RatingFivePart, RatingFourPart, RatingLevelTitlePart,
	RatingResultsPart, RatingsFivePart, RatingsFourPart,
	ReadonlyQuestionPart, ReadonlyQuestionsPart,
	ScheduleSelectorPart,
	SiteProgramLanguageSelectorPart,
	StageStatusPart,
	StageViewerPart,
} from '@me-shared-parts/PG-program';
import { SharedPersonModule } from '@me-shared-parts/PN-person/PN.module';
import { SharedCommonModule } from '@me-shared-parts/UI-common/UI.module';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { ChatModule } from '@progress/kendo-angular-conversational-ui';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LabelModule } from '@progress/kendo-angular-label';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { ProgressBarModule } from '@progress/kendo-angular-progressbar';
import { MarkdownModule } from 'ngx-markdown';

export { QuestionStepper };


@NgModule({
	imports: [
		CommonModule,
		ButtonsModule,
		DateInputsModule,
		DropDownsModule,
		ChatModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		MatButtonToggleModule,
		MatCardModule,
		MatDatepickerModule,
		MatInputModule,
		MatSelectModule,
		MatCheckboxModule,
		MatRadioModule,
		MatExpansionModule,
		MatAutocompleteModule,
		MatListModule,
		MatMenuModule,
		MatTooltipModule,
		MatTableModule,
		MatTreeModule,
		GridModule,
		InputsModule,
		LabelModule,
		LayoutModule,
		ProgressBarModule,
		FlexLayoutModule,
		MarkdownModule.forChild(),
		SharedCommonModule,
		SharedPersonModule,
		SharedCompanyModule,
	],
	declarations: [
		//
		// Components
		//
		AccApplicationStatusSelectorPart,
		AccGoalsTeamSelectorPart,
		AccGoalsPart,
		AccGoalsAddDialog,
		AccGoalsEditPart,
		AccGoalsViewPart,
		AccQuarterlyControllerPart,
		AccStageMsgPart,
		ActionsMenuPart,
		AlumPromoterPart,
		ApplicationInstructionsPart,
		ApplicationDisplayPart,
		ApplicationEditorPart,
		ApplicationEditorSectionTitlePart,
		ApplicationNotesButtonPart,
		ApplicationParticipantDialog,
		ApplicationParticipantsPart,
		ApplicationViewerMessagePart,
		ApplicationStatusLogsPart,
		ApplicationStatusPart,
		ApplicationViewerPart,
		ApplyLangJumpPart,
		AssessmentLevelTitlePart,
		AssessmentResultsPart,
		AwardDisplayDialog,
		AwardEditPart,
		ContactActionsMenuPart,
		DemographicsIndicatorPart,
		EditableQuestionPart,
		EditableQuestionsPart,
		EventAwardsPart,
		EventsGridPart,
		EventsListPart,
		FeedbackManagerPart,
		FeedbackManagerV1Part,
		FollowUpDateDialog,
		LanguageSelectorMenuPart,
		NoteDialog,
		OverlapRollupsPart,
		PositivityRollupsPart,
		ProgramLangJumpPart,
		QuarterlyGoalsPart,
		RatingFivePart,
		RatingFourPart,
		RatingLevelTitlePart,
		RatingResultsPart,
		RatingsFivePart,
		RatingsFourPart,
		ReadonlyQuestionPart,
		ReadonlyQuestionsPart,
		StageStatusPart,
		ScheduleSelectorPart,
		StageViewerPart,
		SiteProgramLanguageSelectorPart,
	],
	exports: [
		//
		// Components
		//
		AccApplicationStatusSelectorPart,
		AccGoalsTeamSelectorPart,
		AccGoalsPart,
		AccGoalsAddDialog,
		AccGoalsEditPart,
		AccGoalsViewPart,
		AccQuarterlyControllerPart,
		AccStageMsgPart,
		ActionsMenuPart,
		AlumPromoterPart,
		ApplicationInstructionsPart,
		ApplicationDisplayPart,
		ApplicationEditorPart,
		ApplicationEditorSectionTitlePart,
		ApplicationNotesButtonPart,
		ApplicationParticipantDialog,
		ApplicationParticipantsPart,
		ApplicationViewerMessagePart,
		ApplicationStatusLogsPart,
		ApplicationStatusPart,
		ApplicationViewerPart,
		ApplyLangJumpPart,
		AssessmentLevelTitlePart,
		AssessmentResultsPart,
		AwardDisplayDialog,
		AwardEditPart,
		ContactActionsMenuPart,
		DemographicsIndicatorPart,
		EditableQuestionPart,
		EditableQuestionsPart,
		EventAwardsPart,
		EventsGridPart,
		EventsListPart,
		FeedbackManagerPart,
		FeedbackManagerV1Part,
		FollowUpDateDialog,
		LanguageSelectorMenuPart,
		NoteDialog,
		OverlapRollupsPart,
		PositivityRollupsPart,
		ProgramLangJumpPart,
		QuarterlyGoalsPart,
		RatingFivePart,
		RatingFourPart,
		RatingLevelTitlePart,
		RatingResultsPart,
		RatingsFivePart,
		RatingsFourPart,
		ReadonlyQuestionPart,
		ReadonlyQuestionsPart,
		StageStatusPart,
		ScheduleSelectorPart,
		StageViewerPart,
		SiteProgramLanguageSelectorPart,
	],
})

export class SharedProgramModule { }