import { LanguageId } from '@me-interfaces';
import * as moment from 'moment';

const TIME_OPTIONS: {
	hour: "numeric",
	minute: "2-digit",
	hour12: true,
	timeZoneName: "short",
} = {
	hour: "numeric",
	minute: "2-digit",
	hour12: true,
	timeZoneName: "short",
};

const DATE_OPTIONS_MMDDYYYY: {
	year: "numeric",
	month: "numeric" | "2-digit" | "long" | "short" | "narrow",
	day: "numeric",
} = {
	year: "numeric",
	month: "2-digit",
	day: "numeric",
};

const DATE_OPTIONS_MMMDYYYY: {
	year: "numeric",
	month: "numeric" | "2-digit" | "long" | "short" | "narrow",
	day: "numeric",
} = {
	year: "numeric",
	month: 'short',
	day: "numeric",
};

export const DateUtilities = {

	/**
	 * Calculate the time period between 2 dates. Ex. Days, Hours, Minutes.
	 * @param event 
	 */
	calculateTimePeriod: (startUTC: number, endUTC: number, languageId: LanguageId = LanguageId.English) => {
		const MINUTE = 60;
		const HOUR = 60 * MINUTE;
		const DAY = 24 * HOUR;


		const elapsed = endUTC - startUTC;

		const handlePlural = (div: number, unit: 'Day' | 'Día' | 'Hour' | 'Hora' | 'Minute' | 'Minutos'): string => {
			const value: number = Math.round(elapsed / div);
			return value + ' ' + (value == 1 ? unit : unit + 's');
		}

		if (elapsed >= DAY) return handlePlural(DAY, languageId == LanguageId.Spanish ? 'Día' : 'Day');
		else if (elapsed >= HOUR) return handlePlural(HOUR, languageId == LanguageId.Spanish ? 'Hora' : 'Hour');
		else return handlePlural(MINUTE, languageId == LanguageId.Spanish ? 'Minutos' : 'Minute');

	},


	/**
	 * Return JS Date from utc
	 */
	getDate: (utc: number) => {
		return new Date(utc * 1000);
	},


	/**
	 * Return utc from JS Date
	 */
	getUTC: (date: Date) => {
		return date.getTime() / 1000;
	},


	/**
	 * Calculate the days from the UTC till now. Ex. a year ago, 9 months ago etc.
	 */
	getDaysSince: (utc: number): string => {
		moment.locale('en');
		return moment(utc * 1000).fromNow(true);
	},

	/**
	 *  Given the day and time sting check the correct format and return concatnated string
	 * @param day -  YYYY-MM-DD
	 * @param time - HH:MM
	 * @returns `YYYY-MM-DD HH:MM:00 GMT`
	 */
	getDateString: (day: string, time: string) => {
		//
		// Check if the day string is not the standard 'YYYY-MM-DD'
		//
		if (day.length !== 10 || day[4] != '-' || day[7] != '-') throw new Error(`Date format is not supported.`);
		if (time.length !== 5 || day[2] != ':') throw new Error(`Time format is not supported.`);


		// Convert to date and time string i.e. YYYY-MM-DD HH:MM:00 GMT
		return `${day} ${time}:00 GMT`;
	},


	/**
	 * Given the UTC return a string formatted 'YYYY-MM-DD'
	 * @param utc 
	 * @returns 
	 */
	getYYYYMMDDFromUTC: (utc: number): string => {
		const date = new Date(utc * 1000);
		let month = '' + (date.getMonth() + 1);
		let day = '' + date.getDate();
		const year = date.getFullYear();

		if (month.length < 2)
			month = '0' + month;
		if (day.length < 2)
			day = '0' + day;

		return [year, month, day].join('-');
	},



	/**
 * Based on date and time strings return out the milliseconds for the timeZone.
 * @param day YYYY-MM-DD string
 * @param time HH:MM string
 * @param intlDateTimeFormat TimeZone specific Date with GMT offset
 * @returns 
 */
	getSpecificTimeAtTimeZone: (day: string, time: string, timeZoneName: string): number => {

		const intlDateTimeFormat = new Intl.DateTimeFormat('en-US', { timeZoneName: 'shortOffset', timeZone: timeZoneName });
		// Convert to date and time string i.e. YYYY-MM-DD HH:MM:00 GMT
		const dateStr = DateUtilities.getDateString(day, time);

		const parsedDate = Date.parse(dateStr);


		//
		// Use the Intl.DateTimeFormat to get the correct GMT offset for the site timeZone
		//
		const gmt = intlDateTimeFormat.format(parsedDate).split('GMT');


		//
		// If for some reason the offset is not GMT throw an error.
		//
		if (gmt.length !== 2) throw new Error(`The current time format does not include GMT offset.`)

		const dateStrWithGMTOffset = dateStr + gmt[1];


		//
		// Return the date in MS to store in the database
		//
		return Date.parse(dateStrWithGMTOffset) / 1000;

	},

	/**
	 * Given the current utc from our database in ms, return the date and time string based on the format options.
	 */
	formatUTC: (
		/** A database UTC that does not include ms*/
		utc: number,
		/** May render differently for different locales */
		dateFormat: 'No Date' | 'MM/DD/YYYY' | 'MMM D, YYYY' | 'MMM D, YYYY (DOW)',
		/** May render differently for different locales */
		timeFormat: 'No Time' | 'H:MM AM EST',

		languageId: LanguageId,
		/** Leave blank to use the users timezone */
		timeZone?: string,
	): string => {

		if (!utc) return 'No UTC value';

		const date = new Date(utc * 1000);

		// Show both date and time
		if (dateFormat != 'No Date' && timeFormat != 'No Time') {
			return DateUtilities.formatDateAndTime(date, dateFormat, languageId, timeZone);
		}
		//Show only time
		else if (dateFormat == 'No Date' && timeFormat == 'H:MM AM EST') {
			return DateUtilities.formatTime(date, languageId, timeZone);
		}
		// Show only date
		else if (dateFormat !== 'No Date' && timeFormat == 'No Time') {
			return DateUtilities.formatDate(date, dateFormat, languageId, timeZone);
		}


		return 'Invalid parameters';

	},

	/**
	 * Given the current Date, return the date string based on the format.
	 */
	formatDate: (
		date: Date,
		/** May render differently for different locales */
		dateFormat: 'MM/DD/YYYY' | 'MMM D, YYYY' | 'MMM D, YYYY (DOW)',
		languageId: LanguageId,
		timeZone?: string,
	) => {

		if (!date) return 'No Date value';

		const locale = languageId == LanguageId.Spanish ? 'es-US' : 'en-US';

		const dateOptions = dateFormat == 'MM/DD/YYYY' ? DATE_OPTIONS_MMDDYYYY : DATE_OPTIONS_MMMDYYYY;

		const options: Intl.DateTimeFormatOptions = { ...dateOptions, timeZone };

		const formattedDateAndTime = new Intl.DateTimeFormat(locale, options).format(date);

		if (dateFormat == 'MMM D, YYYY (DOW)') {
			const weekday = new Intl.DateTimeFormat(locale, { weekday: 'short', timeZone }).format(date);
			return `${formattedDateAndTime} (${weekday})`;
		}

		return formattedDateAndTime;
	},

	/**
	 * Given the current Date, return the time string.
	 */
	formatTime: (date: Date, languageId: LanguageId, timeZone?: string) => {
		if (!date) return 'No Date value';

		const locale = languageId == LanguageId.Spanish ? 'es-US' : 'en-US';
		const options: Intl.DateTimeFormatOptions = { ...TIME_OPTIONS, timeZone };

		return new Intl.DateTimeFormat(locale, options).format(date);
	},

	/**
	 * Given the current Date, return the date and time based on the format.
	 */
	formatDateAndTime: (
		date: Date,
		/** May render differently for different locales */
		dateFormat: 'MM/DD/YYYY' | 'MMM D, YYYY' | 'MMM D, YYYY (DOW)',
		languageId: LanguageId,
		timeZone?: string,
	) => {
		if (!date) return 'No Date value';

		const locale = languageId == LanguageId.Spanish ? 'es-US' : 'en-US';

		const dateOptions = dateFormat == 'MM/DD/YYYY' ? DATE_OPTIONS_MMDDYYYY : DATE_OPTIONS_MMMDYYYY;

		const options: Intl.DateTimeFormatOptions = { ...dateOptions, ...TIME_OPTIONS, timeZone };

		const formattedDateAndTime = new Intl.DateTimeFormat(locale, options).format(date);

		if (dateFormat == 'MMM D, YYYY (DOW)') {
			const weekday = new Intl.DateTimeFormat(locale, { weekday: 'short', timeZone }).format(date);
			return `${formattedDateAndTime} (${weekday})`;
		}

		return formattedDateAndTime;
	},

	getTimezone(): string {
		let tz = Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone || 'Unknown';

		const country = 'America/';
		if (tz.startsWith(country)) tz = tz.substr(country.length);

		tz = tz.trim().split('_').join(' ');

		return tz || 'Unknown';
	}
};
