<div class="me-field" (click)='fieldClick.emit()' [class.incomplete-field]='highlightIfIncomplete && (bp.errorText || !bp.completed)'>

	<div *ngIf='bp.questionKey || bp.questionLiteral' class='field-question'>
		<me-label *ngIf='bp.questionKey' [key]='bp.questionKey'></me-label>
		<div *ngIf='bp.questionLiteral'>{{bp.questionLiteral}}</div>
	</div>

	<kendo-label [text]="bp.text.label">
		<span class='optional-field' *ngIf='!bp.field.required'>({{ bp.text.optional }})</span>
	</kendo-label>


	<ng-content></ng-content>


	<div class='field-indicators'>
		<div class='error-indicator'>

			<div *ngIf='bp.errorText'>
				<i class='fas fa-exclamation-triangle'></i>
				{{bp.errorText}}
			</div>

		</div>
		<div class='status-indicators'>

			<div *ngIf='bp.status==="pending"' class="field-pending">{{ bp.text.statusPending }}</div>
			<div *ngIf='bp.status==="saving"' class="field-saving"><i class='far fa-sync-alt fa-spin'></i>
				{{ bp.text.statusSaving }}</div>
			<div *ngIf='bp.status==="saved"' class="field-saved">{{ bp.text.statusSaved }}</div>
			<div *ngIf='bp.status==="no-auth"' class="field-no-auth">{{ bp.text.statusNoAuth }}</div>
			<div *ngIf='bp.status==="changed"' class="field-changed">{{ bp.text.statusChanged }}</div>

		</div>
	</div>
</div>