import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { DbcUser, DbsWebLink, PublicPersonForDialog, WebLinkTypeId } from '@me-interfaces';
import { OldUserService } from '@me-services/old-services-and-wrappers/user';
import { DialogContext } from '@me-services/ui/dialog';
import { LabelsService } from '@me-services/ui/labels';
import { getIconContext, getIconForWebLinkType, Icon } from '@me-shared-parts/UI-common';

@Component({
	selector: 'public-person-dialog',
	templateUrl: './SHR-PN_public-person-dialog.dialog.html',
	styleUrls: ['./SHR-PN_public-person-dialog.dialog.scss']
})
export class PublicPersonDialog extends DestroyablePart implements OnInit {

	person: PublicPersonForDialog;
	header = 'Person';
	user: DbcUser;
	emailLink: string;
	personPhone: { phone: string, phoneTypeId?: number };
	emailIcon = getIconContext(Icon.action_email, undefined, 'always');

	inlineLabels = this.labels.trackInlineLabels(this, [
		'Name',
		'Phone',
		'Address'
	]);

	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{ person: PublicPersonForDialog }>,
		public dialogRef: MatDialogRef<PublicPersonDialog>,
		private labels: LabelsService,
		private userService: OldUserService,
	) {
		super();
	}


	async ngOnInit() {
		super.initDestroyable();

		if (this.dialog.data) {
			this.person = this.dialog.data.person;
		}

		this.user = await this.userService.getUser();
		this.header = this.person?._name || 'No Person Record found';

		if (this.person) {
			this.personPhone = { phone: this.person?.phone, phoneTypeId: this.person?.phoneTypeId };

			if (this.person.email) this.emailLink = `mailTo:${this.person.email}`;
		}
	}

	/**
	 * Determine the button icon and text based on the weblink type
	 */
	getWebLinkIcon(webLink: DbsWebLink) {
		return getIconContext(getIconForWebLinkType(webLink.webLinkTypeId || WebLinkTypeId.Website), undefined, 'always');
	}
}