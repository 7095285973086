<div class="dialog-frame-part">
	<h6 mat-dialog-title class='highlight-section' [class.info]='alertType === "info"'
		[class.error]='alertType === "error"' [class.warning]='alertType === "warning"'
		[class.success]='alertType === "success"' [class.editor]='alertType === "editor"'>
		<i class='fas fa {{icon}}'></i>
		<span fxFlex fxGrow='2' class='dialog-title-text'>{{header}}</span>
		<button class="alert-close" (click)="closeClick()">
			<i class="fas fa-times"></i>
		</button>
	</h6>

	<div class='dialog-toolbar-container'>
		<me-toolbar *ngIf='showToolbar' icon='Dialog'>
			<ng-content select='[toolbar]'></ng-content>
		</me-toolbar>
	</div>

	<div mat-dialog-content [ngClass]='{ "dialog-has-toolbar": showToolbar }'>
		<ng-content></ng-content>
	</div>

	<div *ngIf='actions?.length' mat-dialog-actions class='highlight-section' style='padding: 0 0 2px 0;'>
		<button-container>
			<ng-container *ngFor='let action of actions'>
				<ng-container *ngIf='action.visible'>
					<a *ngIf='action.linkType === "href" && action.download' class='btn {{action.cssClass}}'
						[class.disabled]='handlingClick || !action.enabled' (click)='click(action)' [href]='action.url'
						[download]='action.download + ".tsv"' target="_blank">

						<ng-container *ngIf='action.label.startsWith'>
							<me-label *ngIf="action.label.startsWith(':')" key='{{action.label.slice(1)}}'></me-label>
							<ng-container *ngIf="!action.label.startsWith(':')">{{action.label}}</ng-container>
						</ng-container>
						<ng-container *ngIf='!action.label.startsWith'>
							<me-label [key]='action.label'></me-label>
						</ng-container>

					</a>

					<a *ngIf='action.linkType === "href" && !action.download' class='btn {{action.cssClass}}'
						[class.disabled]='handlingClick || !action.enabled' (click)='click(action)' [href]='action.url'
						target="_blank">

						<ng-container *ngIf='action.label.startsWith'>
							<me-label *ngIf="action.label.startsWith(':')" key='{{action.label.slice(1)}}'></me-label>
							<ng-container *ngIf="!action.label.startsWith(':')">{{action.label}}</ng-container>
						</ng-container>
						<ng-container *ngIf='!action.label.startsWith'>
							<me-label [key]='action.label'></me-label>
						</ng-container>

					</a>

					<button *ngIf='action.linkType !== "href"' class='btn {{action.cssClass}}' (click)='click(action)'
						[disabled]='handlingClick || !action.enabled'>

						<ng-container *ngIf='action.label.startsWith'>
							<me-label *ngIf="action.label.startsWith(':')" key='{{action.label.slice(1)}}'></me-label>
							<ng-container *ngIf="!action.label.startsWith(':')">{{action.label}}</ng-container>
						</ng-container>
						<ng-container *ngIf='!action.label.startsWith'>
							<me-label [key]='action.label'></me-label>
						</ng-container>

					</button>

				</ng-container>
			</ng-container>
		</button-container>
	</div>
</div>

<div class="dialog-frame-page-spinner">
	<div class="page-spinner">
		<i class='fal fa-spin fa-spinner-third fa-fw'></i>
		<div class='page-spinner-desc'><span>{{ pageSpinner.desc$ | async }}</span></div>
	</div>
</div>