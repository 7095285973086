import { DbcSiteIntern, SiteVolunteerApplication } from '@me-interfaces';

export interface SiteAreaRawData {

	siteId: number,


	offeredSitePrograms: {
		siteProgramId: number,
		internIdsWithAccess: number[],
	}[],

	interns: {
		siteIntern: DbcSiteIntern,
		siteProgramId: number[]
	}[],


	volunteerApplications: SiteVolunteerApplication[],

	hearReport: {
		applicantPersonId: number,
		applicationId: number,
		companyId: number,
		accId?: number,
		picId?: number,
		answer: string,
	}[],

}