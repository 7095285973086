import { Component, Input, OnInit } from '@angular/core';
import { DbcCompanyProfile } from '@me-interfaces';
import { OldUserService } from '@me-services/old-services-and-wrappers/user';
import { SelectEvent } from '@progress/kendo-angular-layout';


export const enum COMPANY_EDITOR_KEYS {
	PROFILE = 1,
	// TAGS = "TAGS",
	NOTES = 2,
	CONNECTIONS = 3,
}

@Component({
	selector: 'company-editors',
	templateUrl: './SHR-ED_company-editors.part.html',
	styleUrls: ['SHR-ED_company-editors.part.scss'],
})
export class CompanyEditorsPart implements OnInit {
	@Input() profile: DbcCompanyProfile;
	@Input() editorKey: COMPANY_EDITOR_KEYS = COMPANY_EDITOR_KEYS.PROFILE;
	@Input() selectorKey?: string;
	@Input() inDialog?: boolean = false;

	tabStripClass = 'tabs-only';
	popoutUrl = '';

	selectors = {
		profile: undefined,
		notes: undefined,
		tags: undefined,
		connections: undefined,
	}

	constructor(private userService: OldUserService) {
	}

	async ngOnInit() {
		if (this.selectorKey) {
			if (this.editorKey == COMPANY_EDITOR_KEYS.PROFILE) this.selectors.profile = this.selectorKey;
			else if (this.editorKey == COMPANY_EDITOR_KEYS.NOTES) this.selectors.notes = this.selectorKey;
			else if (this.editorKey == COMPANY_EDITOR_KEYS.CONNECTIONS) this.selectors.connections = this.selectorKey;
		}

		if (this.inDialog) this.tabStripClass = `${this.tabStripClass} dialog`;

		this.popoutUrl = `http://localhost:4200/access/contacts/companies/${this.profile.companyId}/editors/profile`;
	}

	onTabSelect(e: SelectEvent) {
		this.editorKey = e.index;
	}
}
