<div class="acc-application-transfer-dialog">
	<me-dialog-frame header='Application Transfer' [actions]='actions' [dialogRef]='dialogRef' icon='fa-calendar-edit'
		alertType='editor'>

		<div class="me-field">
			<div class="acc-application-transfer-dialog-content">
				<p *ngIf='applicationStatusId === ApplicationStatusId_TeamPromoted'>This application has already been
					promoted to a cohort within
					this site. It can no longer be transferred.</p>

				<ng-container *ngIf='applicationStatusId !== ApplicationStatusId_TeamPromoted'>
					<p>
						The application can only be transferred to an accelerator of the same language at a different site.
					</p>
					<div style="margin-top: 2em">{{language}} Accelerators:</div>
					<div>
						<me-droplist [items]='items' [selectedItem]='selectedItem'
							(selectionChange)='valueChange($event)' [readonly]='readonly'>
						</me-droplist>
					</div>
				</ng-container>
			</div>
		</div>
	</me-dialog-frame>
</div>