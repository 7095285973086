import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DbsPerson, TagRole } from '@me-interfaces';
import { OldEntityService } from '@me-services/old-services-and-wrappers/entity';
import { DdTag, OldDomainDataService } from '@me-services/core/old-dd';
import { UtilityService } from '@me-services/core/utility';

export interface RoleTags {
	role: TagRole,
	values: [boolean, boolean, boolean, boolean, boolean, boolean] // nothing, consider, approved, notApproved, current, past
}

@Component({
	selector: 'site-tag-editor',
	templateUrl: './SHR-CMN_site-tag-editor.part.html',
	styleUrls: ['SHR-CMN_site-tag-editor.part.scss'],
})
export class SiteTagEditorPart implements OnInit {

	@Input() person: DbsPerson;
	@Input() siteTags: DdTag[];
	@Input() canEdit: boolean;
	@Output() tagsChanged = new EventEmitter<DbsPerson>();

	sitePrefixId: number;
	siteCode: string;
	roleTags: RoleTags[];

	tags: { [index: number]: DdTag };
	autoTags: DdTag[] = [];

	followerTag: DdTag;
	leadershipCurrentTag: DdTag;
	mailingListEN: DdTag;
	mailingListES: DdTag;

	tagIdByRole: { [index: string]: number } = {};
	manualTagsForRole: { [index: string]: { consider?: DdTag, approved?: DdTag, 'not-approved'?: DdTag } } = {};

	personTags: DdTag[] = [];

	constructor(
		private dd: OldDomainDataService,
		private entityService: OldEntityService,
		private util: UtilityService,
	) { }

	ngOnInit() {

		if (this.siteTags.length) {

			this.sitePrefixId = this.siteTags[0].tagPrefixId;
			this.siteCode = this.siteTags[0].prefix.toUpperCase();

			this.personTags = this.dd.tags.getByIds(this.person._personExtracts.tagIds);
			this.roleTags = this.initializeRoleTags(this.personTags.filter(t => t.tagPrefixId == this.sitePrefixId));


			this.autoTags = this.personTags
				.filter(tag => tag.isAutoTag && tag.tagPrefixId == this.sitePrefixId);

			this.tags = this.siteTags.reduce((a, t: DdTag) => {
				a[t.tagId] = t;
				return a;
			}, {});

			this.followerTag = this.siteTags.find(t => t.name == 'follower');
			this.mailingListEN = this.siteTags.find(t => t.name == 'mailing-list-en');
			this.mailingListES = this.siteTags.find(t => t.name == 'mailing-list-es');
			this.leadershipCurrentTag = this.siteTags.find(t => t.name == 'leadership-current');

		}
		this.prepareManualTags(this.siteTags.filter(tag => !tag.isAutoTag));
	}

	prepareManualTags(allManualTags: DdTag[]) {
		this.manualTagsForRole = {};

		this.setManualTagIds();

		//
		// Create dropdown list values for each manual tag role
		//
		for (const tag of allManualTags) {
			const tagParts = tag.name.split('-');
			const role = tag.name.startsWith('mailing') ? tag.name : tagParts.shift();
			const value = tagParts.join('-');

			this.manualTagsForRole[role] = this.manualTagsForRole[role] || {};
			this.manualTagsForRole[role][value] = tag;
		}
	}

	setManualTagIds() {
		const personsManualTags = this.personTags.filter(tag => !tag.isAutoTag && tag.tagPrefixId == this.sitePrefixId);
		this.tagIdByRole = {};

		for (const tag of personsManualTags) {
			const role: string = tag.name.startsWith('mailing') ? tag.name : tag.name.split('-')[0];
			this.tagIdByRole[role] = tag.tagId;
		}
	}

	async updateTag(tagId: number | undefined, role: string) {

		if (!this.canEdit) return;

		// If no tagId given then use the tagId currently set for this role so that it will be toggled off
		if (!tagId) {
			tagId = this.tagIdByRole[role];
			this.tagIdByRole[role] = undefined;

			// Removing the tag so remove the old one if there is one
			if (tagId) await this.entityService.wrapPerson(this.person).tags.remove(tagId);

			else {
				this.util.log.errorMessage(`No tagId for role: ${role}`);
				return;
			}
		}
		else {
			// Adding the tag to the contact's tags. Remove a prior one of this role if there is one
			const lastTagId = this.tagIdByRole[role];
			if (lastTagId) await this.entityService.wrapPerson(this.person).tags.remove(lastTagId);

			//add the new tag
			await this.entityService.wrapPerson(this.person).tags.add(tagId);
			this.tagIdByRole[role] = tagId;
		}
		this.tagsChanged.emit(this.person); // after the call, using the actual tags that came back from the db
	}

	initializeRoleTags(siteTags: DdTag[]): RoleTags[] {
		const roleTags: RoleTags[] = [
			{ role: 'Reader', values: [false, false, false, false, false, false] },
			{ role: 'Interviewer', values: [false, false, false, false, false, false] },
			{ role: 'Cohort', values: [false, false, false, false, false, false] },
			{ role: 'Mentor', values: [false, false, false, false, false, false] },
			{ role: 'Coach', values: [false, false, false, false, false, false] },
			{ role: 'Judge', values: [false, false, false, false, false, false] },
			{ role: 'Specialist', values: [false, false, false, false, false, false] },
			{ role: 'EventHelper', values: [false, false, false, false, false, false] },
			{ role: 'Leadership', values: [false, false, false, false, false, false] },
			//{ role: 'Coworker', values: [false, false, false, false, false, false] }
		];

		for (const tag of siteTags) {
			const parts = tag.name.split('-');
			const role = parts.shift();
			const value = parts.join('-');

			const rt = roleTags.find(rt => rt.role.toLowerCase() == role);
			if (rt) {
				if (value == 'consider') rt.values[1] = true;
				if (value == 'approved') rt.values[2] = true;
				if (value == 'not-approved') rt.values[3] = true;
				if (value == 'current') rt.values[4] = true;
				if (value == 'past') rt.values[5] = true;
				if (value == 'alumni') rt.values[5] = true;
			}
		}

		for (const rt of roleTags) {
			rt.values[0] = !(rt.values[1] || rt.values[2] || rt.values[3]);
		}

		return roleTags;
	}

	canShowTag(role: TagRole, value: string): boolean {
		if (role == 'Cohort' && value == 'approved') return false;
		if (role == 'Cohort' && value == 'not-approved') return false;
		if (role == 'Leadership' && value == 'approved') return false;
		if (role == 'Leadership' && value == 'not-approved') return false;
		return true;
	}

	radioChange(e) {
		const role = e.source.name.toLowerCase();
		const value = e.source.value.toLowerCase();
		const tags = this.manualTagsForRole[role];
		const tag = tags[value];
		const tagId: number = tag ? tag.tagId : undefined;
		this.updateTag(tagId, role)
	}

	isFollower() {
		if (!this.followerTag) return false;
		return !!this.person._personExtracts.tagIds.find(t => t == this.followerTag.tagId);
	}

	isLeadershipCurrent() {
		if (!this.leadershipCurrentTag) return false;
		return !!this.person._personExtracts.tagIds.find(t => t == this.leadershipCurrentTag.tagId);
	}

	isOnMailingListEN() {
		if (!this.mailingListEN) return false;
		return !!this.person._personExtracts.tagIds.find(t => t == this.mailingListEN.tagId);
	}

	isOnMailingListES() {
		if (!this.mailingListES) return false;
		return !!this.person._personExtracts.tagIds.find(t => t == this.mailingListES.tagId);
	}
}