import { LabelKey } from "@me-interfaces";

export interface SurveyQuestionField {
	questionLabel: string | LabelKey,
	key: string,
	required: boolean,
	typeAndValue: NpsTypeValue | ParaTypeValue,
}

export interface NpsTypeValue {
	value: number,
	type: 'Nps',
}

export interface ParaTypeValue {
	value: string,
	type: 'Paragraph',
}


export interface SurveyField {
	headerLabel: string | LabelKey,
	backgroundColor: string,
	iconClass: string, // TODO: this should be iconContext instead. Move iconcontext interface to be in src/interface
	yesOrNoQuestionLabel?: string | LabelKey,
	enabled: boolean,
	id: number,
	fields: SurveyQuestionField[],
	displayTextLabel?: string,
	subs?: SurveyField[],
}