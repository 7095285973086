import { AccAreaService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AssessmentsData, Event, EventTypeId } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { DroplistItem, Icon, getIconContext } from '@me-shared-parts/UI-common';


@Component({
	selector: 'acc-interviewing-assessments-view-part',
	templateUrl: './acc-interviewing-assessments-view.part.html',
	styleUrls: ['./acc-interviewing-assessments-view.part.scss'],
})
export class AccInterviewingAssessmentsViewPart extends DestroyablePart implements OnInit {

	orderSelectedItem: DroplistItem<string>;
	private _data: {
		assessments: AssessmentsData[],
		interviewEvents: { eventId: number, numWithDate: string, event: Event }[];
	};

	public data: {
		assessments: AssessmentsData[],
		interviewEvents: { eventId: number, numWithDate: string, event: Event }[];
	};
	// data$: Observable<AssessmentsData[]>;

	order = 'cpq';
	orderList: DroplistItem<string>[] = [
		{ uniqueId: 1, data: 'pcq', iconContext: getIconContext(Icon.droplist_pickOne, undefined, undefined, 'Interviewer, Applicant, Question') },
		{ uniqueId: 2, data: 'pqc', iconContext: getIconContext(Icon.droplist_pickOne, undefined, undefined, 'Interviewer, Question, Applicant') },
		{ uniqueId: 3, data: 'cqp', iconContext: getIconContext(Icon.droplist_pickOne, undefined, undefined, 'Applicant, Question, Interviewer') },
		{ uniqueId: 4, data: 'cpq', iconContext: getIconContext(Icon.droplist_pickOne, undefined, undefined, 'Applicant, Interviewer, Question') },
		{ uniqueId: 5, data: 'qpc', iconContext: getIconContext(Icon.droplist_pickOne, undefined, undefined, 'Question, Interviewer, Applicant') },
		{ uniqueId: 6, data: 'qcp', iconContext: getIconContext(Icon.droplist_pickOne, undefined, undefined, 'Question, Applicant, Interviewer') },
	];

	public defaultEvent: { eventId: number, numWithDate: string, event: Event } = {
		eventId: undefined,
		numWithDate: 'All Events',
		event: undefined,
	};

	eventIdToFilterBy: number;

	constructor(
		public accAreaService: AccAreaService,
		private ds: DataService,
	) {
		super();
	}

	ngOnInit(): void {
		super.initDestroyable();
		this.orderSelectedItem = this.orderList.find(order => order.data == this.order);

		super.subscribe([
			this.accAreaService.interviewing.assessments$,
			this.accAreaService.accelerator.events$,
			this.ds.admin.singletonsAsOfUTC$,
		], async ([interviewAssessments, events]) => {
			if (!events || !interviewAssessments) return;

			const accInterviewers = await this.ds.admin.accInterviewer.getManyPackagesAsMap(interviewAssessments.map(r => r.interview.accInterviewerId));
			const applications = await this.ds.admin.application.getManyPackagesAsMap(interviewAssessments.map(r => r.interview.applicationId));

			const eventsWithNum = await this.accAreaService.getEventsWithNumAndDate(EventTypeId.AccInterviewing, events);
			const interviewEvents = eventsWithNum.map(eventWithNum => ({ event: eventWithNum.event, eventId: eventWithNum.event.eventId, numWithDate: eventWithNum.numWithDate }));

			this._data = {
				interviewEvents,
				assessments: interviewAssessments.reduce((a: AssessmentsData[], ia) => {
					const interviewEventId = applications[ia.interview.applicationId].eventId;
					const rating = {
						answers: ia.answers,
						company: applications[ia.interview.applicationId].company,
						rating: ia.interview.rating,
						event: eventsWithNum.find(event => interviewEventId && interviewEventId == event.event.eventId)?.event,
					};
					const assessmentData = a.find(data => data.person.personId == accInterviewers[ia.interview.accInterviewerId].personId);
					if (assessmentData) {
						assessmentData.ratings.push(rating);
					}
					else a.push({
						person: accInterviewers[ia.interview.accInterviewerId].person.asSingleton,
						ratings: [rating],
					});
					return a;
				}, []),
			};
			this.filterDataByEventId();
		});
	}

	changeOrder(e: DroplistItem) {
		this.orderSelectedItem = e;
		this.order = e.data;
	}

	onSelectionChange(e: number | undefined) {
		this.eventIdToFilterBy = e;
		this.filterDataByEventId();
	}

	private filterDataByEventId() {
		if (this.eventIdToFilterBy) {
			this.data.assessments = this._data.assessments.reduce((a: AssessmentsData[], assessment) => {

				const ratings = assessment.ratings.filter(rating => rating.event.eventId == this.eventIdToFilterBy);
				if (ratings.length) {
					a.push({
						person: assessment.person,
						ratings,
					});
				}
				return a;
			}, []);
		}
		else {
			this.data = { ...this._data };
		}
	}
}