import { DbsPerson } from "@me-interfaces";
import { OldDomainDataService } from '@me-services/core/old-dd';
import { FuncService } from "@me-services/core/func";

/** @deprecated DO NOT USE THIS!*/
export class PersonPositions {

	constructor(private readonly person: DbsPerson, private func: FuncService, private dd: OldDomainDataService) {
	}

}