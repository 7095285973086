import { createRegex } from "./create-regex";
import { escapeRegex } from "./escape-regex";

export const RegexUtilities = {
  /**
   * Escape any old text so that all of the characters are used a literals.
   */
  escape: (text: string) => escapeRegex(text),


  /**
   * Create a RegExp object to search text for the existence of given text.
   */
  create: (
    text: string,
    placement: 'full' | 'starts-with' | 'ends-with' | 'contains' = 'contains',
    caseSensitive = false,
  ) => createRegex(text, placement, caseSensitive),

}