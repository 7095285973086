export { SiteTagsOverviewPart } from '../PN-person/site-tags-overview/SHR-CMN_site-tags-overview.part';
export { OldTagPart } from './old-tag/SHR-CMN_old-tag.part';
export { PersonBadgePart } from './person-badge/SHR-CMN_person-badge.part';
export { PersonExtractButtonPart } from './person-extract-button/SHR-PN_person-extract-button.part';
export { PersonExtractDialog } from './person-extract-dialog/SHR-PN_person-extract-dialog.dialog';
export { ShowPersonExtractDialogService } from './person-extract-dialog/SHR-PN_show-person-extract-dialog.service';
export { PersonExtractAssociationsPart } from './person-extract-dialog/associations/SHR-PN_person-extract-associations.part';
export { PersonExtractBannerPart } from './person-extract-dialog/banner/SHR-PN_person-extract-banner.part';
export { PersonExtractHighlightsPart } from './person-extract-dialog/highlights/SHR-PN_person-extract-highlights.part';
export { PersonExtractInfoPart } from './person-extract-dialog/info/SHR-PN_person-extract-info.part';
export { PersonExtractPositionsPart } from './person-extract-dialog/positions/SHR-PN_person-extract-positions.part';
export { PersonExtractRelationshipsPart } from './person-extract-dialog/relationships/SHR-PN_person-extract-relationships.part';
export { PersonExtractLinkPart } from './person-extract-link/SHR-PN_person-extract-link.part';
export { PersonPositionsPart } from './person-positions/SHR-CMN_person-positions.part';
export { PersonRolesPart } from './person-roles/SHR-PN_person-roles.part';
export { EnableablePerson, PersonSelectorByApprovedRolePart } from './person-selector-by-approved-role/SHR-CMN_person-selector-by-approved-role.part';
export { PersonTablePart } from './person-table/SHR-PN_person-table.part';
export { PersonTagEditorPart } from './person-tag-editor/SHR-CMN_person-tag-editor.part';
export { TagsEditorDialog } from './person-tag-editor/dialog/SHR-CMN_tags-editor.dialog';
export { TagEditorData } from './person-tag-editor/dialog/tag-editor-data';
export { SimpleTagEditorPart } from './person-tag-editor/simple-tag-editor/SHR-CMN_simple-tag-editor.part';
export { SiteTagEditorPart } from './person-tag-editor/site-tag-editor/SHR-CMN_site-tag-editor.part';
export { PersonTagsOverviewPart } from './person-tags-overview/SHR-CMN_person-tags-overview.part';
export { PublicPersonButtonPart } from './public-person-button/SHR-PN_public-person-button.part';
export { PublicPersonDialog } from './public-person-dialog/SHR-PN_public-person-dialog.dialog';
export { PublicPersonLinkPart } from './public-person-link/SHR-PN_public-person-link.part';
export { TagPart } from './tag/SHR-CMN_tag.part';
export { TitledPersonGridPart } from './titled-person-grid/SHR-CMN_titled-person-grid.part';

