export interface UserAgent {
	appCode: 'ME' | 'YO',
	userAgent: string,
	browser: string,
	browserVersion: string,
	engine: string,
	device: string,
	os: string,
	osVersion: string,
	clientOffsetUTC: number,
}