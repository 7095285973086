import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DestroyablePart } from '@me-access-parts';
import { Person, SiteVolunteerApplication } from '@me-interfaces';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';
import { getIconClass, Icon } from '@me-shared-parts/UI-common';
import { SiteAreaService } from '@SITE-area';


@Component({
	selector: 'site-volunteer-application-edit-dialog',
	templateUrl: './volunteer-application-edit.dialog.html',
})
export class SiteVolunteerApplicationEditDialog extends DestroyablePart implements OnInit {


	actions: DialogAction[] = [];
	icon = getIconClass(Icon.action_edit);

	header = 'Edit Volunteer Application';
	isClosed: boolean;
	volunteerApplicationId: number;

	readonly: boolean;


	constructor(
		@Inject(MAT_DIALOG_DATA) private dialog: DialogContext<{
			siteVolunteerApplication: SiteVolunteerApplication,
			applicant: Person,
		}>,
		public dialogRef: MatDialogRef<SiteVolunteerApplicationEditDialog>,
		private siteAreaService: SiteAreaService,
	) {
		super();
	}


	async ngOnInit() {
		super.initDestroyable();

		super.subscribe([
			this.siteAreaService.accessAndId$,
		], async ([accessAndId]) => {
			this.readonly = accessAndId?.access?.root != 'Write';
		});

		const { siteVolunteerApplication } = this.dialog.data;

		this.isClosed = !!siteVolunteerApplication.closedByPersonId;
		this.volunteerApplicationId = siteVolunteerApplication.application.volunteerApplicationId;
	}

	close(): void {
		this.dialogRef.close();
	}
}