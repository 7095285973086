import { DbConceptName, Dbd, Immutable } from '@me-interfaces';


interface MutableDbdTopicMaterial extends Dbd {

	readonly _concept: DbConceptName.TopicMaterial,
	readonly topicMaterialId: number,
	readonly topicId: number,
	readonly languageId: number,
	readonly name: string,
	readonly topicMaterialTypeId: number,
	readonly displayOrder: number,
	readonly url: string,
	readonly isWorksheet: boolean,
	readonly isStaffOnly: boolean,
	readonly code: string,
}


export interface DbdTopicMaterial extends Immutable<MutableDbdTopicMaterial> { }