import { DbConceptName, Dbs, ExplorablePackage, Immutable } from "@me-interfaces";


interface MutableDbsRegion extends Dbs {

	readonly _concept: DbConceptName.Region,

	readonly regionId: number,
	readonly name: string,
	readonly directorId: number,
	readonly updatedUTC: number,
	readonly updatedByPersonId: number,
}


interface MutableRegion extends MutableDbsRegion {
	directorName: string,
	updatedByPersonName: string,
}


export interface DbsRegion extends Immutable<MutableDbsRegion> { }
export interface Region extends ExplorablePackage<DbConceptName.Region>, Immutable<MutableRegion> { }
