import { ACC_APPLICATION_STATUS, ApplicationStatusId, ApplicationStatusLog, DbsPerson, PIC_APPLICATION_STATUS } from '@me-interfaces';
import { getIconContext, getIconForApplicationStatus, IconContext } from '@me-shared-parts/UI-common';
import { renderDate } from '@me-shared-parts/UI-common/fields/date/render-date';

export interface ApplicationStatusData {
	icon: IconContext,
	applicationStatusId: ApplicationStatusId,
	person: DbsPerson,
	date: string,
}


export function getApplicationLog(log: ApplicationStatusLog, type: 'acc' | 'pic'): ApplicationStatusData {
	if (!log) return;

	const STATUS = type == 'pic' ? PIC_APPLICATION_STATUS: ACC_APPLICATION_STATUS;
	const applicationIcon = getIconForApplicationStatus(log.applicationStatusId);
	const icon: IconContext = getIconContext(applicationIcon, undefined, 'never', STATUS[log.applicationStatusId]);

	return {
		icon,
		date: renderDate(log.changedUTC),
		person: log.person,
		applicationStatusId: log.applicationStatusId,
	};
}