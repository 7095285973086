import { AccAreaService, AccPreAcceleratorPageService } from '@ACC-area';
import { Component, OnInit } from '@angular/core';
import { DestroyablePart } from '@me-access-parts';
import { AccStageId } from '@me-interfaces';
import { AccPreAcceleratorStageId } from '../../acc-application-status-helpers';


@Component({
	selector: 'acc-select-tab-part',
	templateUrl: './acc-select-tab.part.html',
})
export class AccSelectTabPart extends DestroyablePart implements OnInit {

	accStageId: AccPreAcceleratorStageId = AccStageId.SelectingCohort;

	constructor(
		public accAreaService: AccAreaService,
		public pageService: AccPreAcceleratorPageService,
	) {
		super();
	}

	ngOnInit(): void {
		super.initDestroyable();
	}

}
