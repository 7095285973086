import { DbsSite, PicTeam, PitchContest } from "@me-interfaces";

export function getPicParticipants(
	sites: readonly DbsSite[],
	picsBySiteId: Record<number, PitchContest[]>,
	picTeams: readonly PicTeam[],
) {

	const participantsBySiteId: Record<number, number> = {};

	for (const site of sites) {

		const siteId = site.siteId;
		const pics = picsBySiteId[siteId] ?? [];

		const picIds = pics
			// Should we filter to only after the event?
			.map(pic => pic.picId);

		const teams = picTeams
			.filter(t => picIds.includes(t.picId));

		participantsBySiteId[siteId] = teams.length;
	}

	return { participantsBySiteId };
}