import { DbsCompany, PersonAtCompany, CompanyOfPerson, PositionRoles, DbsPosition, DbsPerson } from '@me-interfaces';

export interface Old_Position {
	positionId: number,
	person: {
		personId: number,
		fullName: string,
		avatarChangedUTC?: number,
		cityStateZip?: string,
		companies: CompanyOfPerson[]
	},
	company: {
		companyId: number,
		name: string,
		logoChangedUTC?: number,
		cityStateZip?: string,
		people: PersonAtCompany[]
	},
	roles: PositionRoles,
	phone?: {
		number: string,
		extension: string,
	},
	title: string,
}

export interface Position {
	positionId: number,
	position: DbsPosition,
	person: DbsPerson,
	company: DbsCompany,
	isDefaultPosition: boolean,
	isPrimaryContact: boolean,
}