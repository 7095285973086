import { EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { DbConceptName } from "@me-interfaces";
import { DataService } from "@me-services/core/data";
import { UtilityService } from "@me-services/core/utility";
import { Icon } from "@me-shared-parts/UI-common/icon";
import { GridColumnConfig, PersonCols, buildColumn } from "../columns";
import { EXPLORE_GRID_ACTION_KEY, GRID_EXPERIENCE, GridSetup, OPEN_GRID_ACTION_KEY } from "../interfaces";
import { GridExperience } from "./grid-experience";

const LANG_EN_ID = 37;
const LANG_ES_ID = 38;
/**
 * Standard experience for a grid of people
 */
export class PersonGridExperience<ROW> extends GridExperience<ROW> {

	public static readonly experience: GRID_EXPERIENCE = 'PERSON';

	constructor(
		private ds: DataService,
		util: UtilityService,
		setup: GridSetup<ROW>,
		gridAction: EventEmitter<{ actionKey: string; rows: ROW[]; }>,
		private router: Router,
	) {
		super(
			util, setup,
			[
				buildColumn(PersonCols.personId, GridColumnConfig.personId, { hidden: true }),
			],
			[
				buildColumn(PersonCols.notes, GridColumnConfig.notesIcon, { noColumnMenu: true }),
				buildColumn(PersonCols.stateCode, GridColumnConfig.stateCode, { hidden: true }),
				buildColumn(PersonCols.name, GridColumnConfig.personFullName),
				buildColumn(PersonCols.location, GridColumnConfig.location, { hidden: true }),
				buildColumn(PersonCols.zipCode, GridColumnConfig.zipCode, { hidden: true }),
			],
			[
				buildColumn(PersonCols.phone, GridColumnConfig.phone, { hidden: true }),
				buildColumn(PersonCols.email, GridColumnConfig.email, { hidden: true }),
				buildColumn(PersonCols.firstName, GridColumnConfig.personFirstName, { hidden: true }),
				buildColumn(PersonCols.lastName, GridColumnConfig.personLastName, { hidden: true }),
				buildColumn(PersonCols.language, GridColumnConfig.languageCodes, { hidden: true }),
				buildColumn(PersonCols.applications, GridColumnConfig.applicationsCount, { hidden: true }),
				buildColumn(PersonCols.awards, GridColumnConfig.awardsDollars, { hidden: true }),
			],
			[
				{ key: EXPLORE_GRID_ACTION_KEY, icon: Icon.dialog_explore, label: `Explore ${setup.rowSingularName}` },
				{ key: OPEN_GRID_ACTION_KEY, icon: Icon.dialog_explore, label: `Open ${setup.rowSingularName}` },
				// { key: NOTES_GRID_ACTION_KEY, icon: Icon.action_editNotes, label: `Edit Person Notes` },
			],
			gridAction,
			Icon.concept_person,
		);
	}


	public async applyBaseValues(rows: ROW[]) {

		const personMap = await this.ds.admin.person.getManyPackagesAsMap(rows.map(row => row['personId']));
		const zipsMap = this.ds.domain.zip.getAllAsMap();

		for (const row of rows) {

			const personId = row['personId'];
			const person = personMap[personId];

			const zip = zipsMap[person.asSingleton.zipId];
			const location = zip?.cityAndState ?? '';
			const state = zip?.state ?? '';
			const zipId = zip?.zipId ?? null;


			const langs = [];
			if (person.tags.find(tag => tag.tagId == LANG_EN_ID)) langs.push('EN');
			if (person.tags.find(tag => tag.tagId == LANG_ES_ID)) langs.push('ES');

			const dbsPerson = person.asSingleton;

			row[PersonCols.personId] = dbsPerson.personId;
			row[PersonCols.notes] = person.hasRedFlag ? null : person.noteIds.length;
			row[PersonCols.name] = person.fullName;
			row[PersonCols.firstName] = dbsPerson.firstName;
			row[PersonCols.lastName] = dbsPerson.lastName;
			row[PersonCols.stateCode] = state;
			row[PersonCols.location] = location;
			row[PersonCols.zipCode] = zipId;
			row[PersonCols.email] = dbsPerson._email;
			row[PersonCols.phone] = dbsPerson.phone;
			row[PersonCols.applications] = person.accApplicationIds.length + person.picApplicationIds.length;
			row[PersonCols.awards] = person.awardedValue;
			row[PersonCols.language] = langs.join(', ');

		}

		return;
	}


	public sortRows(rows: ROW[]) {
		rows.sort((row1: ROW, row2: ROW) => {
			const val1 = row1[PersonCols.name];
			const val2 = row2[PersonCols.name];
			return val1 < val2 ? -1 : 1;
		});
	}


	public async explore(row: ROW) {
		await this.ds.explorer.explore(DbConceptName.Person, row[PersonCols.personId]);
	}


	public async open(row: ROW) {
		await this.router.navigate([`access/contacts/people/${row[PersonCols.personId]}/overview`]);
	}


	public async editNotes(row: ROW) {
		// Notes dialog not implemented
	}
}