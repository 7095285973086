import { DbaCourseSection, UserAreaRawData } from "@me-interfaces";
import { DataService } from '@me-services/core/data';
import { UtilityService } from "@me-services/core/utility";
import { UserAreaData } from "./user-area-data";

//
// transform the raw data returned from the action function to
// data that can be used by the Angular app.
//
export async function mapToData(rawData: UserAreaRawData, util: UtilityService, ds: DataService): Promise<UserAreaData> {

	if (!rawData) return {
		personId: undefined,
		program: {
			entrepreneurAccs: [],
			mentorTeams: [],
			applications: [],
			accTeams: [],
			picTeams: [],
			eligiblePrograms: [],
			agreementSignatures: [],
		},
		userAccelerators: [],
		userPitchContests: [],
		userSites: [],
		userAgreementTypes: [],
		learning: {
			courses: [],
		},
		userCompanies: [],
		profile: {
			address: undefined,
			inUS: undefined,
			zipId: undefined,
		}
	};


	const courseSectionsMap = util.array.toArrayMap(rawData.courseSections, (section: DbaCourseSection) => section.courseId);
	const sectionSegmentsMap = util.array.toArrayMap(rawData.courseSegments, (segment) => segment.courseSectionId);



	// TODO: Why is rawdata sometimes coming in as {} instead of undefined?
	const data: UserAreaData = {

		program: {
			entrepreneurAccs: rawData.entrepreneur?.accs ?? [],
			mentorTeams: (rawData.mentor?.teams ?? []).map(team => team.teamGoals),
			applications: rawData.applications,
			eligiblePrograms: rawData.eligiblePrograms,
			accTeams: rawData.accTeams,
			picTeams: rawData.picTeams,
			agreementSignatures: rawData.agreementSignatures,
		},

		learning: {
			courses: rawData.courses.map(course => {
				const sections = courseSectionsMap[course.courseId];

				return {
					course,
					sections: sections.map(section => {
						const segments = sectionSegmentsMap[section.courseSectionId];
						return {
							section,
							segments,
						};
					}),
				}
			}),
		},
		userAccelerators: rawData.userAccelerators,
		userPitchContests: rawData.userPitchContests,
		userSites: rawData.userSites,
		userAgreementTypes: rawData.userAgreementTypes,
		personId: rawData.personId,
		profile: rawData.profile,
		userCompanies: rawData.companies,

	}

	return data;

}