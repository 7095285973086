<div class='person-languages-editor-part'>
	<me-save-changes-message *ngIf='messagedisplay' [hidden]="messagedisplay.disabled"></me-save-changes-message>
	<div class='inner-div-languages-editor'>
		<form [formGroup]="form" novalidate>
			<table class='pro-table pro-table-no-header pro-table-with-labels'>
				<tr>
					<td>
						<span>English</span>
					</td>
					<td>
						<label class="k-form-field right">
							<input type="checkbox" id="english" kendoCheckBox
								[checked]='form.controls.english.value' (change)='toggleCheckbox("english")' />
							<label class="k-checkbox-label" for="english">{{person.firstName}} speaks
								English</label>
						</label>
					</td>
				</tr>
				<tr *ngIf='form.controls.english.value'>
					<td colspan="2">
						<textarea kendoTextArea placeholder="Bio (multiple lines in English)" rows='5'
							formControlName="enBio"
							(blur)='constants.blur.fixCaseOfValue(form.controls.enBio)'></textarea>
						<div *ngIf='form.controls.enBio.errors && form.controls.enBio.errors.maxlength'
							class="k-tooltip-validation">
							{{tooManyCharacters(form.controls.enBio)}}
						</div>
					</td>
				</tr>
				<tr>
					<td>
						<span>Spanish</span>
					</td>
					<td>
						<label class="k-form-field right">
							<input type="checkbox" id="spanish" kendoCheckBox
								[checked]='form.controls.spanish.value' (change)='toggleCheckbox("spanish")' />
							<label class="k-checkbox-label" for="spanish">{{person.firstName}} speaks
								Spanish</label>
						</label>
					</td>
				</tr>
				<tr *ngIf='form.controls.spanish.value'>
					<td colspan="2">
						<textarea kendoTextArea placeholder="Bio (multiple lines in Spanish)" rows='5'
							formControlName="esBio"
							(blur)='constants.blur.fixCaseOfValue(form.controls.esBio)'></textarea>

						<div *ngIf='form.controls.esBio.errors && form.controls.esBio.errors.maxlength'
							class="k-tooltip-validation">
							{{tooManyCharacters(form.controls.esBio)}}
						</div>
					</td>
				</tr>
			</table>
		</form>
		<button-container>
			<spinner-button (safeClick)='resetForm()' [disabled]='!form.dirty || updating'>
				Revert </spinner-button>
			<spinner-button #messagedisplay (safeClick)='save()' [disabled]='form.invalid || !form.dirty || updating'
				[spinning]='updating'>
				Save Changes </spinner-button>
		</button-container>
	</div>
</div>