import { Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { Field, FieldKind, FieldValues } from '@me-interfaces';
import { UtilityService } from '@me-services/core/utility';


@Component({
	selector: 'field-grid-readonly',
	templateUrl: './SHR-UI_field-grid-readonly.part.html',
	styleUrls: ['./SHR-UI_field-grid-readonly.part.scss'],
})

export class FieldGridReadonlyPart implements OnInit, OnChanges {

	//
	// We make a copy of the input the first time it is set but not subsequent times.
	// This way, the field parts won't rebind  with new instances of the same properties.
	//
	_fields: Field[] = [];
	_values: FieldValues = {};
	@Input() fields: Field[];
	@Input() values: FieldValues;

	/**
	 * If set to true then the grid fields will be stacked one on top
	 * of each other, even if some columns are wide.
	 */
	@Input() singleGridColumn = false;

	textareaKind = FieldKind.TextareaDialog;


	constructor(private util: UtilityService) { }


	async ngOnInit() {
		if (!this.fields) throw new Error('Missing required attribute: fields');
		if (!this.values) throw new Error('Missing required attribute: values');
	}


	async ngOnChanges(changes: SimpleChanges) {

		if (changes.fields) {

			const fields: Field[] = changes.fields.currentValue;

			if (changes.fields.isFirstChange() || !this.util.values.areSame(fields, this._fields)) {
				this._fields = fields;
			}
		}

		if (changes.values) {

			const values: FieldValues = changes.values.currentValue;

			if (changes.values.isFirstChange()) this._values = values;

			else for (const fieldKey in values) {
				if (!this.util.values.areSame(this._values[fieldKey], values[fieldKey])) {
					this._values[fieldKey] = values[fieldKey];
				}
			}
		}
	}


	public getValue(field: Field) {
		return this._values[field.key];
	}


	public getOtherValue(field: Field) {
		return this._values[field.otherValueKey];
	}

}