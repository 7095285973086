<div *ngIf='form' class='person-permissions-editor-part'>
	<form [formGroup]="form" novalidate>
		<me-save-changes-message *ngIf='messagedisplay' [hidden]="messagedisplay.disabled"></me-save-changes-message>

		<table class='pro-table pro-table-no-header'>
			<tr>
				<td>
					<mat-checkbox [checked]='form.controls.isCrmUser.value' (change)='toggleCheckbox("C")'
						class='potentialCheckbox' [disabled]='crmDisabled'>Can View/Edit Contacts</mat-checkbox>
				</td>
			</tr>
			<tr>
				<td>
					<mat-checkbox [checked]='form.controls.canEditDemographics.value' (change)='toggleCheckbox("D")'
						class='potentialCheckbox' [disabled]='editDemographicsDisabled'>Can Edit Demographics</mat-checkbox>
				</td>
			</tr>
			<tr>
				<td>
					<mat-checkbox [checked]='form.controls.isEForAllAdmin.value' (change)='toggleCheckbox("EFAADM")'
						class='potentialCheckbox' [disabled]='eForAllAdminDisabled'>Cross Site EforAll Admin</mat-checkbox>
				</td>
			</tr>
			<tr>
				<td>
					<mat-checkbox [checked]='form.controls.isTechAdmin.value' (change)='toggleCheckbox("SYS")'
						class='potentialCheckbox' [disabled]='sysadmDisabled'>Tech Admin</mat-checkbox>
				</td>
			</tr>
		</table>
		<button-container>
			<spinner-button (safeClick)='resetForm()' [disabled]='!form.dirty'>
				Revert
			</spinner-button>
			<spinner-button #messagedisplay (safeClick)='save()' [disabled]='form.invalid || !form.dirty'>
				Save Changes </spinner-button>
		</button-container>
	</form>
</div>