interface QueuedPromise {
	name: string,
	action: () => Promise<unknown>,
	resolve: (value: unknown | PromiseLike<unknown>) => void,
	reject: (reason?: Error) => void
}

/**
 * Sequence multiple promise based activities so they run one at a time
 */
export class PromiseQueue {

	private queue: QueuedPromise[] = [];
	private processing = false;


	public enqueue<T>(name: string, action: () => Promise<T>): Promise<T> {

		return new Promise((resolve, reject) => {

			this.queue.push({
				name,
				action,
				resolve,
				reject,
			});

			this.dequeue();
		});
	}


	public dequeue() {

		if (this.processing) return false;

		const item = this.queue.shift();
		if (!item) return false;


		try {
			this.processing = true;


			item.action()
				.then(result => {
					this.processing = false;
					item.resolve(result);
					this.dequeue();
				})
				.catch(e => {
					this.processing = false;
					item.reject(e);
					this.dequeue();
				})
		} catch (e) {
			this.processing = false;
			item.reject(e);
			this.dequeue();
		}

		return true;
	}
}