export type TagName =
	"admin" |
	"board" |
	"coach-approved" |
	"coach-consider" |
	"coach-current" |
	"coach-not-approved" |
	"coach-past" |
	"cohort-alumni" |
	"cohort-current" |
	"content" |
	"development" |
	"director+" |
	"english" |
	"eventhelper-approved" |
	"eventhelper-consider" |
	"eventhelper-not-approved" |
	"executive" |
	"follower" |
	"intern-current" |
	"intern-current" |
	"interviewer-approved" |
	"interviewer-consider" |
	"interviewer-not-approved" |
	"interviewer-past" |
	"judge-approved" |
	"judge-consider" |
	"judge-not-approved" |
	"judge-past" |
	"leadership-consider" |
	"leadership-current" |
	"mailing-list-en" |
	"mailing-list-es" |
	"marketing" |
	"mentor-approved" |
	"mentor-consider" |
	"mentor-current" |
	"mentor-not-approved" |
	"mentor-past" |
	"program" |
	"reader-approved" |
	"reader-consider" |
	"reader-not-approved" |
	"reader-past" |
	"spanish" |
	"specialist-approved" |
	"specialist-consider" |
	"specialist-not-approved" |
	"staff-current" |
	"staff-current" |
	"technology" |
	"vip";