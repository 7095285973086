import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { IconContext } from '@me-shared-parts/UI-common/icon';
import { getIconContext } from '@me-shared-parts/UI-common/icon/get-icon-context';
import { Icon } from '@me-shared-parts/UI-common/icon/icon';
import { ShowTextDialogService } from '../text-dialog/SHR-CMN_show-text-dialog.service';


@Component({
	selector: 'text-dialog-button',
	templateUrl: './SHR-CMN_text-dialog-button.part.html',
	styleUrls: ['./SHR-CMN_text-dialog-button.part.scss'],
})
export class TextDialogButtonPart implements OnChanges {
	@Input() header: string
	@Input() text: string;
	@Input() readonly: boolean;
	@Output() textChanged: EventEmitter<string> = new EventEmitter();

	icon: IconContext;

	constructor(
		private textDialogService: ShowTextDialogService
	) {
	}

	async ngOnChanges() {
		if (!this.header) throw new Error('Missing required attribute: header');
		if (this.text === undefined) throw new Error('Missing required attribute: text');
		this.determineIcon(this.text);
	}

	async openDialog() {
		const result = await this.textDialogService.show(this.readonly, this.text, this.header);
		if (result) {
			this.textChanged.emit(result);
			this.determineIcon(result);
		}
	}

	private determineIcon(text: string) {
		if ((text || '').trim()) this.icon = getIconContext(Icon.notes_some, 'fa-regular', 'always');
		else this.icon = getIconContext(Icon.notes_none, 'fa-regular', 'always');
	}
}