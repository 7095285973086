import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DbcUser, EntityProfile } from '@me-interfaces';
import { FuncService } from '@me-services/core/func';
import { DialogAction, DialogContext } from '@me-services/ui/dialog';
import { PERSON_EDITOR_KEYS } from '../person-editors/SHR-ED_person-editors.part';
import { EntityEditorData } from './entity-editor-data';


@Component({
	selector: 'entity-editor-dialog',
	templateUrl: './SHR-ED_entity-editor.dialog.html',
	styleUrls: ['./SHR-ED_entity-editor.dialog.scss']
})
export class EntityEditorDialog implements OnInit {

	actions: DialogAction[] = [];
	user: DbcUser;
	entityId: number;
	profile: EntityProfile;
	selectedEditor: PERSON_EDITOR_KEYS = 0;
	initialSelectorKey: string;

	constructor(
		@Inject(MAT_DIALOG_DATA) dialog: DialogContext<EntityEditorData>,
		public dialogRef: MatDialogRef<EntityEditorDialog>,
		private func: FuncService,
	) {

		this.entityId = dialog.data.entityId;
		this.user = dialog.data.user;
		this.selectedEditor = dialog.data.selectedEditor;
		this.initialSelectorKey = dialog.data.initialSelectorKey;
	}

	async ngOnInit() {
		if (this.entityId) this.profile = await this.func.admin.contactsDb.entity.getProfile({ entityId: this.entityId });
		this.switchSelectedEditorByEntity();
	}

	switchSelectedEditorByEntity() {
		if (this.profile.company && this.selectedEditor > 1) {
			--this.selectedEditor;
		}
	}
}
