import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DestroyablePart } from '@me-access-parts';
import { DbConceptName, DbcVolunteerApplication, LanguageId, Person, Site, SiteVolunteerApplication, Tag, TagApprovals, TagName } from '@me-interfaces';
import { DataService } from '@me-services/core/data';
import { UtilityService } from '@me-services/core/utility';
import { getIconContext, Icon, IconContext } from '@me-shared-parts/UI-common';
import { SiteAreaService } from '@SITE-area';


const ADMIN_ROUTE = '/people/volunteers/:volunteerApplicationId';

export interface ViewModel {
	application: DbcVolunteerApplication,
	applicant: Person,
	language: string,
	bioEN: string,
	bioES: string,
	sites: Site[],
	initialTags: TagApprovals,
	tags: TagApprovals,
	displayTags: {
		mentorApproved: Tag,
		mentorConsider: Tag,
		mentorNotApproved: Tag,
		coachApproved: Tag,
		coachConsider: Tag,
		coachNotApproved: Tag,
		readerApproved: Tag,
		readerConsider: Tag,
		readerNotApproved: Tag,
		judgeApproved: Tag,
		judgeConsider: Tag,
		judgeNotApproved: Tag,
		interviewerApproved: Tag,
		interviewerConsider: Tag,
		interviewerNotApproved: Tag,
		specialistApproved: Tag,
		specialistConsider: Tag,
		specialistNotApproved: Tag,
		eventhelperApproved: Tag,
		eventhelperConsider: Tag,
		eventhelperNotApproved: Tag,
	},
	saving: boolean,
	transfering: boolean,
	icons: {
		person: IconContext,
	}
}


@Component({
	selector: 'volunteer-application-open',
	templateUrl: './open-application.part.html',
	styleUrls: ['./open-application.part.scss'],
})
export class VolunteerApplicationOpenPart extends DestroyablePart implements OnInit {
	@Input() volunteerApplicationId: number;
	@Output() closeDialog = new EventEmitter();

	vm: ViewModel;
	readonly: boolean;
	site: Site;
	siteTags: Tag[];

	constructor(
		public router: Router,
		private sanitizer: DomSanitizer,
		private siteAreaService: SiteAreaService,
		private util: UtilityService,
		private ds: DataService,
	) {
		super();
	}

	ngOnInit(): void {
		super.initDestroyable();

		super.subscribe([
			this.siteAreaService.accessAndId$,
			this.siteAreaService.data$,
		], async ([accessAndId, data]) => {
			this.readonly = accessAndId?.access?.root != 'Write';
			if (!data) return;

			this.site = data.site;
			this.siteTags = await this.ds.admin.tag.getTagForSite(this.site.siteId);


			this.updateView(data.volunteerApplications.find(app => app.siteVolunteerApplication.volunteerApplicationId == this.volunteerApplicationId));

		});
	}


	async updateView(model: {
		siteVolunteerApplication: SiteVolunteerApplication,
		applicant: Person,
	}) {
		const langTagIds = model.applicant.tags.filter(tag => tag.tagId == 37 || tag.tagId == 38); // 37 => english; 38 => Spanish;
		const langIds: number[] = langTagIds.length ? langTagIds.map(l => {
			if (l.tagId == 38) return LanguageId.Spanish;
			else return LanguageId.English;
		}) : [];
		const language = langTagIds.length ? langTagIds.map(l => {
			if (l.tagId == 38) return 'Spanish';
			else return 'English';
		}).join(', ') : undefined;


		this.vm = {
			application: model.siteVolunteerApplication.application,
			applicant: model.applicant,
			bioEN: model.applicant.asSingleton.bioEN,
			bioES: model.applicant.asSingleton.bioES,
			language,
			icons: {
				person: getIconContext(Icon.concept_person, undefined, 'never', model.applicant.name),
			},
			displayTags: {
				mentorApproved: this.getDisplayTags(this.site.siteId, 'mentor-approved'),
				mentorConsider: this.getDisplayTags(this.site.siteId, 'mentor-consider'),
				mentorNotApproved: this.getDisplayTags(this.site.siteId, 'mentor-not-approved'),
				coachApproved: this.getDisplayTags(this.site.siteId, 'coach-approved'),
				coachConsider: this.getDisplayTags(this.site.siteId, 'coach-consider'),
				coachNotApproved: this.getDisplayTags(this.site.siteId, 'coach-not-approved'),
				readerApproved: this.getDisplayTags(this.site.siteId, 'reader-approved'),
				readerConsider: this.getDisplayTags(this.site.siteId, 'reader-consider'),
				readerNotApproved: this.getDisplayTags(this.site.siteId, 'reader-not-approved'),
				judgeApproved: this.getDisplayTags(this.site.siteId, 'judge-approved'),
				judgeConsider: this.getDisplayTags(this.site.siteId, 'judge-consider'),
				judgeNotApproved: this.getDisplayTags(this.site.siteId, 'judge-not-approved'),
				interviewerApproved: this.getDisplayTags(this.site.siteId, 'interviewer-approved'),
				interviewerConsider: this.getDisplayTags(this.site.siteId, 'interviewer-consider'),
				interviewerNotApproved: this.getDisplayTags(this.site.siteId, 'interviewer-not-approved'),
				specialistApproved: this.getDisplayTags(this.site.siteId, 'specialist-approved'),
				specialistConsider: this.getDisplayTags(this.site.siteId, 'specialist-consider'),
				specialistNotApproved: this.getDisplayTags(this.site.siteId, 'specialist-not-approved'),
				eventhelperApproved: this.getDisplayTags(this.site.siteId, 'eventhelper-approved'),
				eventhelperConsider: this.getDisplayTags(this.site.siteId, 'eventhelper-consider'),
				eventhelperNotApproved: this.getDisplayTags(this.site.siteId, 'eventhelper-not-approved'),
			},
			saving: false,
			sites: undefined,
			tags: undefined,
			transfering: false,
			initialTags: {
				mentor: undefined,
				reader: undefined,
				interviewer: undefined,
				specialist: undefined,
				eventhelper: undefined,
				judge: undefined,
			},
		};
		//
		// Filter tags to only those we care about
		//
		let tags = this.vm.applicant.tags;
		const tagNames = ['mentor', 'reader', 'specialist', 'interviewer', 'eventhelper', 'judge'];
		for (let t = 0; t < 6; t++) {
			tagNames.push(`${tagNames[t]}-consider`);
			tagNames.push(`${tagNames[t]}-not-approved`);
			tagNames[t] = `${tagNames[t]}-approved`;
		}

		tags = tags.filter(tag => {
			return tag.siteId == this.site.siteId && tagNames.includes(tag.fullName.split(':')[1]);
		});

		//
		// Get transferable sites
		//
		if (this.vm.language) this.vm.sites = (await this.ds.admin.siteProgram.getAllPackagesAsArray()).reduce((a, sp) => {
			if ((langIds.includes(sp.program.languageId)) && sp.siteId != this.site.siteId) {
				if (!a.includes(sp.site)) a.push(sp.site);
			}
			return a;
		}, []).sort((a, b) => a.name > b.name ? 1 : -1);

		//
		// Convert tags array to TagValues
		//
		this.vm.initialTags = tags.reduce(
			(tagValues: TagApprovals, tag) => {
				const name = tag.fullName.split(':')[1];
				const splitTag = name.split('-');
				let value = 'Approved';
				if (splitTag[1] == 'consider') {
					value = 'Consider';
				}
				if (splitTag[1] == 'not') {
					value = 'Not-Approved';
				}

				const tagName = splitTag[0];
				if (tagValues[tagName] !== 'Approved') tagValues[tagName] = value;

				return tagValues;
			},
			this.vm.initialTags);

		this.vm.tags = Object.assign({}, this.vm.initialTags);
	}

	isUnchanged() {
		return JSON.stringify(this.vm.tags) == JSON.stringify(this.vm.initialTags);
	}

	getDisplayTags(siteId: number, tagName: TagName) {
		return this.siteTags.find(tag => tag.name == tagName);
	}

	async save() {
		this.vm.saving = true;
		const applicantPersonId = this.vm.applicant.personId;
		const tags = this.vm.tags;
		await this.siteAreaService.volunteer.actions.addOrRemoveTags({ applicantPersonId, tags });
		this.vm.saving = false;
	}

	revert() {
		this.vm.tags = Object.assign({}, this.vm.initialTags);
	}

	getLang(languageId: number) {
		return languageId == LanguageId.Spanish ? 'Spanish' : 'English';
	}

	getDate(createdUTC: number) {
		return this.util.date.formatUTC(createdUTC, 'MMM D, YYYY', 'No Time', this.ds.languageId);
	}

	getDateInDays(createdUTC: number) {
		return this.util.date.getDaysSince(createdUTC);
	}

	sanitize(url: string) {
		return this.sanitizer.bypassSecurityTrustUrl(url);
	}

	async removeTag(tags: TagApprovals, type: 'mentor' | 'interviewer' | 'reader' | 'specialist' | 'eventhelper' | 'judge') {
		tags[`${type}`] = undefined;
		const applicantPersonId = this.vm.applicant.personId;
		await this.siteAreaService.volunteer.actions.addOrRemoveTags({ applicantPersonId, tags });
	}

	async transfer(e) {
		this.vm.transfering = true;
		await this.siteAreaService.volunteer.actions.transferApplication({ volunteerApplicationId: this.vm.application.volunteerApplicationId, transferSiteId: e });
		this.vm.transfering = false;
		this.closeDialog.emit();

	}

	async closeApplication() {
		this.vm.saving = true;
		await this.siteAreaService.volunteer.actions.closeApplication({ application: this.vm.application, tags: this.vm.tags });
		this.vm.saving = false;
		this.closeDialog.emit();
	}

	async explorePerson() {
		await this.ds.explorer.explore(DbConceptName.Person, this.vm.applicant.personId);
	}
}